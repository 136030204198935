import { useIsOnline } from "@/hooks/shared/useIsOnline";
import { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { FiWifiOff as OfflineIcon, FiWifi as OnlineIcon } from "react-icons/fi";
import { IoClose as CloseIcon } from "react-icons/io5";

export const useOnlineToast = () => {
  const { i18n, t } = useTranslation();
  const isOnline = useIsOnline();
  const toastBannerIdRef = useRef<string | null>(null);

  useEffect(() => {
    if (!isOnline) {
      toast(
        (toastElement) => {
          toastBannerIdRef.current = toastElement.id;
          return (
            <span className="mr-2">
              <Trans i18nKey="onlineToast.message" i18n={i18n} components={{ strong: <strong /> }} />
              <div className="absolute right-1 top-1">
                <CloseIcon
                  className="h-4 w-4 cursor-pointer text-white"
                  onClick={() => {
                    toastBannerIdRef.current = null;
                    toast.dismiss(toastElement.id);
                  }}
                />
              </div>
            </span>
          );
        },
        {
          duration: Infinity,
          style: {
            fontSize: "0.9rem",
            display: "flex",
            alignItems: "center",
            border: "1px solid",
            borderColor: "#333333",
            boxShadow: "0 0 10px #00000014",
            padding: "4px 6px 4px 10px",
            backgroundColor: "#333333",
            color: "#ffffff",
          },
          icon: (
            <div className="h-4 w-4">
              <OfflineIcon className="h-4 w-4 text-white" />
            </div>
          ),
        },
      );
    } else if (toastBannerIdRef.current != null) {
      toast.dismiss(toastBannerIdRef.current);
      toastBannerIdRef.current = null;
      toast.success(t("onlineToast.connexionRestored"), {
        icon: (
          <div className="mt-[0.33rem] h-4 w-4">
            <OnlineIcon className="h-4 w-4 text-sky-600" />
          </div>
        ),
      });
    }
  }, [isOnline]);
};
