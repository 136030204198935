import { ConversationTabComponent } from "@/components/roomPage/tabs/conversation/ConversationTabComponent";
import { useParams } from "react-router-dom";
export const DmConversationComponent = (): JSX.Element => {
  const { conversationId } = useParams<{ id: string; conversationId: string }>();
  return (
    <>
      {conversationId != null && (
        <ConversationTabComponent contextType="dm" participantTranslationKey="general.dmMembersWithCount" conversationId={conversationId} />
      )}
    </>
  );
};
