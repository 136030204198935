import { type IExtendedFile } from "@/api/client";
import { useFileTableItem } from "@/components/roomPage/tabs/files/useFileTableItem";
import { FileIconComponent } from "@/components/shared/files/FileIconComponent";
import { RoomAvatarComponent } from "@/components/shared/room/RoomAvatarComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { format } from "date-fns";
import { type Dispatch, type SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCloudDownload as DownloadIcon } from "react-icons/ai";

interface IFileTableItemComponentProps {
  file: IExtendedFile;
  isInRoom: boolean;
  isSelected: boolean;
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
}

export const FileTableItemComponent = ({ file, isInRoom, isSelected, setSelectedItems }: IFileTableItemComponentProps) => {
  const { t } = useTranslation();
  const { getTypeByExtension, handleCheckboxChange, downloadFile } = useFileTableItem(file, isSelected, setSelectedItems);

  return (
    <>
      <td title={file.name} className={isSelected ? "bg-dark-25 dark:bg-dark-700" : ""}>
        <div className={`my-0.5 flex w-96 items-center py-2 pl-7 pr-2 text-left ${isInRoom ? "px-layout" : ""} text-white hover:text-sky-600`}>
          <input type="checkbox" checked={isSelected} onChange={handleCheckboxChange} className="mr-3" />
          {getTypeByExtension(file.extension) === "image" ? (
            <img src={file.url} className="mr-2 h-5 w-5 rounded border object-cover" alt={file.name} />
          ) : (
            <FileIconComponent extension={file.extension} className="mr-2" />
          )}
          <div className="truncate text-xs font-medium text-zinc-800 dark:text-dark-300">{file.name}</div>
          <TooltipComponent content={t("files.download.tooltip", { count: 1 })} placement="bottom">
            <div className="ml-2 h-4 w-4 cursor-pointer ">
              <DownloadIcon
                className="h-4 w-4"
                onClick={() => {
                  downloadFile(file);
                }}
              />
            </div>
          </TooltipComponent>
        </div>
      </td>
      {!isInRoom && (
        <td className={isSelected ? "bg-dark-25 dark:bg-dark-700" : ""}>
          <div className="flex w-32 flex-row pr-2">
            {file.room != null && (
              <div className="flex max-w-full flex-row items-center rounded-md bg-gray-100 px-2 py-1.5 text-left dark:bg-dark-600">
                <div className="room-image mr-1 aspect-square h-4 w-4 overflow-hidden rounded-md border border-stone-300 transition-all duration-200 ease-in-out dark:border-dark-700">
                  <RoomAvatarComponent
                    roomId={file.room.id}
                    size="xs"
                    className="h-full transition-all duration-200 hover:bg-dark-25 dark:bg-dark-800 dark:hover:bg-dark-700"
                  />
                </div>
                <div className="overflow-hidden text-ellipsis text-xs text-zinc-600 dark:text-dark-100">{file.room.name}</div>
              </div>
            )}
          </div>
        </td>
      )}
      <td className={isSelected ? "bg-dark-25 dark:bg-dark-700" : ""}>
        <div className="flex w-32 flex-row pr-2">
          <div className="max-w-full overflow-hidden text-ellipsis rounded-md bg-gray-100 px-2 py-1.5 text-left text-xs text-sky-600 dark:bg-dark-600 dark:text-dark-100">
            {file.channel != null ? `# ${file.channel.name}` : t("homePage.tabs.files.filters.table.dm")}
          </div>
        </div>
      </td>
      <td className={isSelected ? "bg-dark-25 dark:bg-dark-700" : ""}>
        <div className="flex w-40 flex-row items-center py-2 pr-2">
          <UserAvatarComponent userId={file.uploadedBy.id} size="sm" className="h-5 w-5" />
          <div className="ml-1 overflow-hidden text-ellipsis text-left text-xs text-zinc-800 dark:text-dark-300">
            {file.uploadedBy.firstName} {file.uploadedBy.lastName}
          </div>
        </div>
      </td>
      <td className={isSelected ? "bg-dark-25 dark:bg-dark-700" : ""}>
        <div className="w-32 py-2 text-left text-xs text-zinc-500 dark:text-dark-300">
          {format(new Date(file.createdAt), t("date.shortFormatWithTime"))}
        </div>
      </td>
    </>
  );
};
