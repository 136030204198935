import { type IEmailIdentity } from "@/api/client";
import { useRecipientInput } from "@/components/roomPage/tabs/emails/emailReply/recipientInput/useRecipientInput";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { type FunctionComponent } from "react";
import { RiCloseFill as CloseIcon } from "react-icons/ri";

interface RecipientInputProps {
  defaultValue?: IEmailIdentity[];
  onChange: (newTags: IEmailIdentity[]) => void;
  placeholder?: string;
  id?: string;
  label?: string;
}

export const RecipientInputComponent: FunctionComponent<RecipientInputProps> = ({ defaultValue, onChange, placeholder, id, label }) => {
  const { values, inputValue, handleKeyDown, handleChange, handleRemoveTag, handleBlur } = useRecipientInput(onChange, defaultValue);
  return (
    <label htmlFor={id} className="mb-1 mt-2 flex w-full cursor-text items-start">
      <span className="flex h-7 w-7 items-center text-sm text-zinc-400">{label}</span>
      <div className="mb-1 flex w-full flex-wrap items-start">
        {values.map((value) => (
          <TooltipComponent key={value.email} content={value.email} placement="top" disabled={value.name === "" || value.name == null}>
            <div className="m-1 flex h-5 items-center rounded-lg border bg-zinc-200 pl-2 text-2xs text-sky-600">
              {value.name !== "" ? value.name : value.email}
              <button
                onClick={() => {
                  handleRemoveTag(value);
                }}
                className="ml-1 rounded-full p-1 text-black/50 transition-colors hover:text-black/100"
              >
                <CloseIcon className="h-4 w-4" />
              </button>
            </div>
          </TooltipComponent>
        ))}
        <input
          type="text"
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={placeholder}
          value={inputValue}
          className="h-0 w-0 grow bg-transparent pr-2 text-xs outline-none focus:h-6 focus:w-auto"
          id={id ?? "tag-input"}
        />
      </div>
    </label>
  );
};
