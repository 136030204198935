import { type DeltaContent, type IRoomIdName, type ITask, type IUserId, type TaskPriorityEnum } from "@/api/client";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType, type OverlayCallback } from "@/components/shared/drawer/DrawerTypes";
import { ConfirmModalComponent } from "@/components/shared/modal/ConfirmModalComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { ModalNames } from "@/constants/modalNames";
import { RoomContext } from "@/contexts/RoomContext";
import { TaskTagsContext } from "@/contexts/TaskTagsContextProvider";
import { useCreateTaskMutation } from "@/hooks/mutations/tasks/useCreateTaskMutation";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { type IChannelIdNameRoom } from "@/interfaces/channel";
import { type IRichTextEditorRef } from "@/interfaces/richTextEditor";
import { nameValidation } from "@/utils/formValidations";
import { isDeltaContentEmpty } from "@/utils/utilities";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

interface ICreateTaskForm {
  title: string;
  description: DeltaContent | null;
  priority: TaskPriorityEnum | null;
  roomId: string | null;
  channel: IChannelIdNameRoom | null;
  assignedUsers: IUserId[];
  deadline: string | null;
  taskStatusId: string | null;
  isPrivate: boolean;
}

export const useCreateTaskDrawer = (currentRoomId?: string, fromMessage = false, defaultValues?: Partial<ITask>) => {
  const { t } = useTranslation();
  const editorRef = useRef<IRichTextEditorRef>(null);

  const roomContext = useContext(RoomContext);
  const { tags } = useContext(TaskTagsContext);

  const validationSchema = Yup.object().shape({
    title: nameValidation(t, t("createTaskModal.fields.title.required")).max(90),
    roomId: Yup.string().required(t("createTaskModal.fields.roomId.required")),
    channel: Yup.object({
      id: Yup.string().required(),
      name: Yup.string().required(),
      room: Yup.object({
        id: Yup.string().required(),
        name: Yup.string(),
      }).required(),
    }).required(t("createTaskModal.fields.channelId.required")),
  });

  const { mutate: createTask, isPending } = useCreateTaskMutation(fromMessage);
  const { data: meData } = useMeQuery();
  const { closeDrawer, isOpened, setOverlayCallback } = useContext(DrawerContext);
  const { setTags } = useContext(TaskTagsContext);

  const defaultAssignedUsers = useMemo<IUserId[]>(() => {
    return defaultValues?.assignedUsers ?? (meData != null ? [meData] : []);
  }, [defaultValues?.assignedUsers, meData]);

  const defaultFormValues = useMemo<ICreateTaskForm>(
    () => ({
      title: defaultValues?.title ?? "",
      description: defaultValues?.description ?? null,
      assignedUsers: defaultAssignedUsers,
      priority: defaultValues?.priority ?? null,
      roomId: (defaultValues?.channel as IChannelIdNameRoom)?.room.id ?? roomContext?.roomResponse?.room?.id ?? null,
      channel: (defaultValues?.channel as IChannelIdNameRoom) ?? null,
      deadline: defaultValues?.deadline ?? null,
      taskStatusId: defaultValues?.taskStatus?.id ?? null,
      isPrivate: defaultValues?.isPrivate === true,
    }),
    [roomContext?.roomResponse?.room?.id, defaultValues, defaultAssignedUsers],
  );

  const {
    handleSubmit,
    getValues,
    formState: { errors, isValid, isDirty },
    setValue,
    reset,
    register,
  } = useForm<ICreateTaskForm>({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });

  const showCloseWarning: OverlayCallback = (closeDrawer) => {
    openModal(
      ModalNames.CONFIRM_CLOSE_CREATE_TASK_DRAWER,
      <ConfirmModalComponent
        name={ModalNames.CONFIRM_CLOSE_CREATE_TASK_DRAWER}
        title={t("createTaskModal.closeWarning.title")}
        description={t("createTaskModal.closeWarning.description")}
        action={async () => {
          closeDrawer();
        }}
        actionType="danger"
        actionLabel={t("createTaskModal.closeWarning.button")}
        cancelLabel={t("createTaskModal.closeWarning.cancel")}
      />,
    );
  };

  useEffect(() => {
    if (isDirty) {
      setOverlayCallback(showCloseWarning);
    } else {
      setOverlayCallback(null);
    }
  }, [null, isDirty]);

  useEffect(() => {
    return () => {
      setTags([]);
    };
  }, []);

  const resetFields = useCallback(() => {
    const fields = { ...defaultFormValues };

    if (currentRoomId != null) {
      fields.roomId = currentRoomId;
    } else {
      fields.assignedUsers = defaultAssignedUsers;
    }

    reset(fields);
  }, [defaultFormValues, defaultAssignedUsers, currentRoomId]);

  useEffect(() => {
    if (!isOpened) {
      resetFields();
    }
  }, [isOpened]);

  useEffect(() => {
    resetFields();
  }, [currentRoomId]);

  const onCloseDrawer = () => {
    if (isDirty) {
      showCloseWarning(() => {
        closeDrawer(DrawerType.CREATE_TASK);
      });
    } else {
      closeDrawer(DrawerType.CREATE_TASK);
    }
  };

  const onValid: SubmitHandler<ICreateTaskForm> = (data) => {
    if (data.roomId == null) {
      return;
    }
    if (data.channel == null) {
      return;
    }

    createTask({
      title: data.title,
      priority: data.priority ?? undefined,
      channelId: data.channel.id,
      assignedUsers: data.assignedUsers.map((user) => user.id),
      deadline: data.deadline ?? undefined,
      taskStatusId: data.taskStatusId ?? undefined,
      description: data.description ?? undefined,
      isPrivate: data.isPrivate,
      tagIds: tags.map((tag) => tag.id),
    });
    closeDrawer(DrawerType.CREATE_TASK);
  };

  const onSubmit = handleSubmit(onValid);

  const assignedUsers = getValues("assignedUsers");
  const priority = getValues("priority");
  const taskStatusId = getValues("taskStatusId");
  const roomId = getValues("roomId");
  const channel = getValues("channel");
  const deadline = getValues("deadline");
  const isPrivate = getValues("isPrivate");

  const onPriorityChange = (priority: TaskPriorityEnum | null) => {
    setValue("priority", priority, { shouldValidate: true, shouldDirty: true });
  };

  const onIsPrivateChange = (isPrivate: boolean) => {
    setValue("isPrivate", isPrivate, { shouldValidate: true, shouldDirty: true });
  };

  const onRoomChange =
    currentRoomId != null
      ? undefined
      : (room: IRoomIdName | null) => {
          setValue("roomId", room?.id ?? null, { shouldValidate: true, shouldDirty: true });
          if (room?.id !== roomId) {
            setValue("channel", null, { shouldValidate: true, shouldDirty: true });
          }
        };

  const onChannelChange = (newChannel: IChannelIdNameRoom | null) => {
    if (newChannel != null) {
      setValue("roomId", newChannel.room.id, { shouldValidate: true, shouldDirty: true });
    }

    if (newChannel?.id !== channel?.id) {
      setValue(
        "assignedUsers",
        assignedUsers.filter((user) => user.id === meData?.id),
        { shouldValidate: true, shouldDirty: true },
      );
    }

    setValue("channel", newChannel, { shouldValidate: true, shouldDirty: true });
  };

  const onStatusChange = (taskStatusId: string) => {
    setValue("taskStatusId", taskStatusId, { shouldValidate: true, shouldDirty: true });
  };

  const onAssigneeChange = (assigneeId: string, isSelected: boolean) => {
    const newAsssignedUsers = assignedUsers.filter((user) => user.id !== assigneeId);
    if (isSelected) {
      setValue("assignedUsers", [...newAsssignedUsers, { id: assigneeId }], { shouldValidate: true, shouldDirty: true });
    } else {
      setValue("assignedUsers", newAsssignedUsers, { shouldValidate: true, shouldDirty: true });
    }
  };

  const onDeadlineChange = (deadline: string | null) => {
    setValue("deadline", deadline, { shouldValidate: true, shouldDirty: true });
  };

  const onDescriptionBlur = () => {
    const description = editorRef.current?.getEditorContents();
    if (description != null) {
      setValue("description", description, { shouldValidate: true, shouldDirty: true });
    }
  };

  useEffect(() => {
    if (editorRef.current == null) return;
    const editorContent = editorRef.current.getEditorContents();
    if (defaultValues?.description == null || editorContent == null || !isDeltaContentEmpty(editorContent)) return;

    editorRef.current.setEditorContent(defaultValues.description);
  }, [defaultValues?.description, editorRef]);

  return {
    editorRef,
    onSubmit,
    isValid,
    priority,
    onPriorityChange,
    onStatusChange,
    taskStatusId,
    errors,
    isPending,
    assignedUsers,
    onAssigneeChange,
    onIsPrivateChange,
    isPrivate,
    roomId,
    channel,
    deadline,
    onDeadlineChange,
    onRoomChange,
    onChannelChange,
    onCloseDrawer,
    onDescriptionBlur,
    register,
  };
};
