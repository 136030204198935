import { FileType, type DeltaContent } from "@/api/client";
import { EnhanceByAIModal } from "@/components/roomPage/tabs/conversation/EnhanceByAIModal/EnhanceByAIModal";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { EditorFileComponent } from "@/components/shared/uploader/fileUploader/EditorFileComponent";
import { useFileUploader } from "@/components/shared/uploader/fileUploader/useFileUploader";
import { ModalNames } from "@/constants/modalNames";
import { TEXT_COLORS } from "@/constants/textEditorConstants";
import { useMailEditor } from "@/hooks/emails/useMailEditor";
import { useAudioRecording } from "@/hooks/shared/useAudioRecording";
import { useMessage } from "@/hooks/shared/useMessage";
import "filepond/dist/filepond.min.css";
import { useCallback, useMemo, type Dispatch, type RefObject, type SetStateAction } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import { FilePond } from "react-filepond";
import { useTranslation } from "react-i18next";
import { BsCheckLg as CheckIcon, BsPaperclip as UploadIcon } from "react-icons/bs";
import { HiEmojiHappy, HiOutlineEmojiHappy } from "react-icons/hi";
import { IoMdSend as SendIcon } from "react-icons/io";
import { IoClose as CloseIcon, IoAtOutline, IoCloudUploadSharp, IoText } from "react-icons/io5";
import { TbPencilBolt as AIIcon } from "react-icons/tb";
import { Portal } from "react-portal";
import type ReactQuill from "react-quill";

export const RichTextEditorToolbarComponent = ({
  action,
  isEditing = false,
  editorId,
  isMessagePreview = false,
  isEmpty = true,
  isDirty,
  setIsEmojiPickerOpen,
  isTextFormatOpen,
  setIsTextFormatOpen,
  hideSendButton = false,
  editorRef,
  contextType,
  contextId,
  onInsertMentionCharacter,
  hideFilesUploadButton = false,
}: {
  action: () => void;
  isEditing: boolean;
  isDirty: boolean;
  editorId: string;
  isMessagePreview?: boolean;
  isEmpty?: boolean;
  setIsEmojiPickerOpen: Dispatch<SetStateAction<boolean>>;
  isTextFormatOpen: boolean;
  setIsTextFormatOpen: Dispatch<SetStateAction<boolean>>;
  hideSendButton?: boolean;
  hideFilesUploadButton?: boolean;
  editorRef: RefObject<ReactQuill>;
  contextType: string;
  contextId: string;
  onInsertMentionCharacter: () => void;
}): JSX.Element => {
  const { cancelEdition } = useMessage({});
  const { t } = useTranslation();

  const { isMailEditorButtonActive } = useMailEditor(isEmpty);

  const showEmojis = (): void => {
    setIsEmojiPickerOpen(true);
  };

  const { recorderControls, recordingTime, setRecordingTime } = useAudioRecording();

  const {
    filepondRef,
    filePondServerConfig,
    onErrorHandler,
    files,
    setFiles,
    uploadStatus,
    browseFile,
    onAddFileHandler,
    isUploading,
    onRemoveFileHandler,
    shouldAllowPasteAndDrop,
    conversationElement,
    isFilePondVisible,
    maxFiles,
    onUploadWarningHandler,
  } = useFileUploader(editorRef?.current, contextId);

  const onSubmitEnhanceByAI = (content: DeltaContent) => {
    if (editorRef?.current == null) return;
    editorRef.current.getEditor().setContents(content);
  };

  const openEnhanceByAIModal = async () => {
    const currentText: DeltaContent = await editorRef?.current?.getEditor().getContents();
    openModal(ModalNames.ENHANCE_BY_AI, <EnhanceByAIModal initialText={currentText} onSubmit={onSubmitEnhanceByAI} />);
  };

  const isSendButtonDisabled = useMemo(() => {
    if (contextType === "email") return !isMailEditorButtonActive;
    return (isEmpty && files.length === 0) || (files.length > 0 && isUploading);
  }, [files, isEmpty, isUploading, isMailEditorButtonActive]);

  const isUploadFileButtonDisabled = useMemo(() => files.length >= maxFiles, [files, maxFiles]);
  const addAudioElement = useCallback(
    async (blob: Blob) => {
      if (filepondRef?.current == null) return;
      const file = new File([blob], "enregistrement.weba", { type: "audio/webm" });
      await filepondRef.current.addFile(file, {
        metadata: {
          mediaType: FileType.VoiceNote,
          duration: recordingTime,
        },
      });
      setRecordingTime(0);
    },
    [filepondRef.current, recordingTime],
  );
  return (
    <>
      {!isEditing && !isMessagePreview && (
        <>
          <Portal node={conversationElement}>
            <div
              className={`absolute inset-2 flex flex-col items-center justify-center rounded-lg bg-zinc-400 transition-opacity ${
                isFilePondVisible ? "z-[9000] bg-zinc-400/40 opacity-100" : "-z-10 opacity-0"
              }`}
            >
              <IoCloudUploadSharp className="h-16 w-16 text-sky-600" />
              <span className="font-light text-black">{t("uploader.overlay.label")}</span>
            </div>
          </Portal>

          <FilePond
            dropOnPage={isFilePondVisible}
            dropOnElement={false}
            allowDrop={isFilePondVisible}
            allowPaste={shouldAllowPasteAndDrop}
            className="filepond-uploader hidden"
            ref={filepondRef}
            server={filePondServerConfig}
            onerror={onErrorHandler}
            allowMultiple={true}
            maxFiles={maxFiles}
            onupdatefiles={(files) => {
              setFiles(files.reverse());
            }}
            onaddfile={onAddFileHandler}
            onremovefile={onRemoveFileHandler}
            credits={false}
            onwarning={onUploadWarningHandler}
          />
        </>
      )}
      <div className="ml-[15px] flex flex-wrap">
        {files.map((file) => {
          const isFileUploading = uploadStatus.get(file.filename) ?? true;
          return <EditorFileComponent key={file.id} file={file} isUploading={isFileUploading} filepondRef={filepondRef} />;
        })}
      </div>
      <div id={`toolbar${editorId}`} className={`roger-toolbar ${isMessagePreview ? "preview" : ""}`}>
        <div className={`flex items-center ${!isEditing && isMessagePreview ? "hidden" : "flex"}`}>
          <button className="group/toolbar" onClick={showEmojis}>
            <HiOutlineEmojiHappy className="block group-hover/toolbar:hidden" />
            <HiEmojiHappy className="hidden group-hover/toolbar:block group-hover/toolbar:rotate-45 group-hover/toolbar:text-amber-500" />
          </button>
          <TooltipComponent content={t("roomPage.tabs.room.conversation.message.buttons.link.tooltip")} placement="top">
            <button className="ql-link"></button>
          </TooltipComponent>
          {contextType !== "taskDescription" && contextType !== "email" ? (
            <TooltipComponent content={t("roomPage.tabs.room.conversation.message.buttons.mention.tooltip")} placement="top">
              <button onClick={onInsertMentionCharacter}>
                <IoAtOutline />
              </button>
            </TooltipComponent>
          ) : null}
          <button
            onClick={() => {
              setIsTextFormatOpen(!isTextFormatOpen);
            }}
          >
            <IoText />
          </button>
          {hideFilesUploadButton ? null : (
            <>
              <div className="px-2">|</div>
              <TooltipComponent
                content={
                  isUploadFileButtonDisabled
                    ? t("roomPage.tabs.room.conversation.message.buttons.uploadFile.tooltip.disabled")
                    : t("roomPage.tabs.room.conversation.message.buttons.uploadFile.tooltip.enabled")
                }
                placement="top"
              >
                <div className="workaround-for-tooltip-when-button-is-disabled">
                  <button
                    onClick={browseFile}
                    disabled={isUploadFileButtonDisabled}
                    className={`${isUploadFileButtonDisabled ? "pointer-events-none text-zinc-300" : ""}`}
                  >
                    <UploadIcon />
                  </button>
                </div>
              </TooltipComponent>
            </>
          )}

          <div className="px-2">|</div>
          <TooltipComponent content={t("roomPage.tabs.room.conversation.message.buttons.enhanceByAI.tooltip")} placement="top">
            <div className="workaround-for-tooltip-when-button-is-disabled">
              <button onClick={openEnhanceByAIModal} disabled={isEmpty} className={`${isEmpty ? "pointer-events-none text-zinc-300" : ""}`}>
                <AIIcon />
              </button>
            </div>
          </TooltipComponent>

          {!isEditing && !isMessagePreview && contextType !== "taskDescription" && contextType !== "taskComment" && contextType !== "email" ? (
            <>
              <div className="px-2">|</div>
              <AudioRecorder onRecordingComplete={addAudioElement} showVisualizer={true} recorderControls={recorderControls} />
            </>
          ) : null}

          <section className="ml-auto">
            {isEditing ? (
              <div className="flex">
                <ButtonComponent
                  type="button"
                  status="neutral"
                  onClick={() => {
                    cancelEdition();
                  }}
                  size="xxs"
                  className="mr-2"
                >
                  <div className="h-4 w-4">
                    <CloseIcon />
                  </div>
                </ButtonComponent>
                <ButtonComponent id={`submit${editorId}`} type="button" status="primary" onClick={action} disabled={!isDirty} size="xxs">
                  <div className="h-4 w-4">
                    <CheckIcon />
                  </div>
                </ButtonComponent>
              </div>
            ) : (
              !hideSendButton &&
              (contextType === "email" ? (
                <ButtonComponent id={`submit${editorId}`} type="button" status="primary" onClick={action} size="xs" disabled={isSendButtonDisabled}>
                  {t("roomPage.tabs.mails.buttons.sendMail")}
                </ButtonComponent>
              ) : (
                <button
                  id={`submit${editorId}`}
                  className={`${isSendButtonDisabled ? "pointer-events-none text-zinc-300" : "text-sky-600"}`}
                  disabled={isSendButtonDisabled}
                  onClick={action}
                  title="Send the message"
                >
                  <SendIcon />
                </button>
              ))
            )}
          </section>
          <div className={`${isTextFormatOpen ? "" : "hidden"} top-toolbar absolute top-1`}>
            <span className="ql-formats">
              <button className="ql-bold"></button>
              <button className="ql-italic"></button>
              <button className="ql-underline"></button>
              <select className="ql-color">
                {TEXT_COLORS.map((color) => (
                  <option key={color} value={color}></option>
                ))}
              </select>
            </span>
            <span className="ql-formats">
              <button type="button" className="ql-list" value="ordered"></button>
              <button type="button" className="ql-list" value="bullet"></button>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
