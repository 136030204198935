import { RoomMemberStatus, type IChannelMember, type IDirectMessage } from "@/api/client";
import { ChannelListComponent } from "@/components/roomPage/tabs/conversation/ConversationLists/ChannelListComponent";
import { NavLinkElementComponent } from "@/components/roomPage/tabs/conversation/ConversationLists/conversationListElement/NavLinkElementComponent";
import { DmListComponent } from "@/components/roomPage/tabs/conversation/ConversationLists/DmListComponent";
import { RoundedContainerComponent } from "@/components/shared/layout/RoundedContainerComponent";
import { RoomContext } from "@/contexts/RoomContext";
import { useEmailsBadgeQuery } from "@/hooks/queries/emails/useEmailsBadgeQuery";
import { useEmailAuthorization } from "@/hooks/shared/emails/useEmailAuthorization";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineMailOutline as EmailIcon } from "react-icons/md";

interface IChannelAndConversationListComponentProps {
  channels?: IChannelMember[];
  dms: IDirectMessage[];
  isChannelListOpen?: boolean;
  isOnHomePage?: boolean;
}

export const ConversationListsComponent = ({
  channels,
  dms,
  isChannelListOpen = false,
  isOnHomePage = false,
}: IChannelAndConversationListComponentProps): JSX.Element => {
  const { roomResponse: roomContext } = useContext(RoomContext);
  const { t } = useTranslation();
  const { shouldShowEmails } = useEmailAuthorization();

  const { data: emailBadge } = useEmailsBadgeQuery(roomContext?.room.id);

  return (
    <RoundedContainerComponent
      className={`w-[290px] shrink-0 border-r bg-dark-25 transition-all dark:border-r-dark-700 dark:bg-dark-800 ${
        isChannelListOpen ? "max-md:w-full" : "max-md:w-0"
      }`}
      overflow="overflow-auto"
      hasPadding={false}
    >
      {!isOnHomePage && channels != null && <ChannelListComponent channels={channels} />}
      {!isOnHomePage && shouldShowEmails ? (
        <NavLinkElementComponent
          to={`/dashboard/rooms/${roomContext?.room.id}/emails`}
          label={t("roomPage.tabs.mails.title")}
          badge={emailBadge}
          isHighlighted={false}
          showDisabledBell={false}
          iconElement={<EmailIcon className="gradient-icon stroke ml-1.5 h-full w-full" />}
          className="border-b border-b-zinc-100 pb-2 text-sm font-semibold"
        />
      ) : null}
      {roomContext?.member?.status !== RoomMemberStatus.Archived && <DmListComponent dms={dms} isOnHomePage={isOnHomePage} />}
    </RoundedContainerComponent>
  );
};
