import { useDocumentViewer } from "@/components/shared/documentViewer/useDocumentViewer";
import { useCallback } from "react";

export const usePdfInMessage = () => {
  const { isVisible, setIsVisible } = useDocumentViewer();

  const handleOpenPdfViewer = useCallback(() => {
    setIsVisible(true);
  }, []);

  const handleClosePdfViewer = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  return { handleOpenPdfViewer, handleClosePdfViewer, isVisible };
};
