import { type HTMLAttributes } from "react";
import { type IconType } from "react-icons";
import { BsFillFileEarmarkWordFill, BsFileEarmarkFill as DefaultExtensionIcon } from "react-icons/bs";
import { MdFolderZip } from "react-icons/md";

type SizeType = "md" | "sm";

interface IFileIconProps {
  extension: string;
  size?: SizeType;
  className?: HTMLAttributes<HTMLDivElement>["className"];
}

export const FileIconComponent = ({ extension, size = "md", className }: IFileIconProps) => {
  const IconByExtension: Record<string, { Icon: IconType; color: string; overlayText?: string }> = {
    pdf: { Icon: DefaultExtensionIcon, color: "text-red-500", overlayText: "PDF" },
    doc: { Icon: BsFillFileEarmarkWordFill, color: "text-blue-800" },
    docx: { Icon: BsFillFileEarmarkWordFill, color: "text-blue-800" },
    ppt: { Icon: DefaultExtensionIcon, color: "text-orange-400", overlayText: "PPT" },
    pptx: { Icon: DefaultExtensionIcon, color: "text-orange-400", overlayText: "PPT" },
    xls: { Icon: DefaultExtensionIcon, color: "text-green-700", overlayText: "XLS" },
    xlsx: { Icon: DefaultExtensionIcon, color: "text-green-700", overlayText: "XLS" },
    zip: { Icon: MdFolderZip, color: "text-zinc-400" },
  };

  const Icon = IconByExtension[extension]?.Icon ?? DefaultExtensionIcon;
  const color = IconByExtension[extension]?.color ?? "text-zinc-400";
  const overlayText = IconByExtension[extension]?.overlayText;

  const sizeBySizeType: Record<SizeType, number> = {
    md: 5,
    sm: 4,
  };

  const textClassBySizeType: Record<SizeType, string> = {
    md: "bottom-0 text-[6px] font-medium",
    sm: "bottom-[-2px] text-[5px] font-medium",
  };

  return (
    <div
      className={`w- relative${sizeBySizeType[size]} h-${sizeBySizeType[size]} flex items-center justify-center overflow-hidden ${className ?? ""}`}
    >
      <Icon className={`${color} w-${sizeBySizeType[size]} h-${sizeBySizeType[size]}`} />
      {overlayText != null ? <div className={`absolute text-white ${textClassBySizeType[size]}`}>{overlayText}</div> : null}
    </div>
  );
};
