import { QueryKeys } from "@/constants/queryKeys";
import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { api } from "@/services/HttpService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
export const useDMsBadgeQuery = () => {
  const queryClient = useQueryClient();
  const socket = useContext(SocketContext);

  const query = useQuery({
    queryKey: [QueryKeys.DMS_BADGE],
    queryFn: async () => {
      const { data } = await api.my.getDirectMessagesHomeBadge();
      return data.homeDMBadge;
    },
    retry: 1,
  });

  const onHomeDMBadgeUpdated = ({ homeDMBadge }: { homeDMBadge: boolean }) => {
    queryClient.setQueryData<boolean>([QueryKeys.DMS_BADGE], homeDMBadge);
  };

  useEffect(() => {
    if (socket == null) return;
    socket.on(SocketEvents.HOME_DM_BADGE_UPDATED, onHomeDMBadgeUpdated);

    return () => {
      socket.off(SocketEvents.HOME_DM_BADGE_UPDATED, onHomeDMBadgeUpdated);
    };
  }, [socket]);

  return query;
};
