import React, { type ButtonHTMLAttributes } from "react";
import { RiLoader4Line } from "react-icons/ri";
import "./Button.scss";

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: "button" | "submit" | "reset";
  icon?: JSX.Element;
  iconPosition?: "left" | "right";
  status?: "default" | "neutral" | "primary" | "secondary" | "danger" | "initial" | "dangerSecondary";
  size?: "xxs" | "xs" | "sm" | "md" | "lg" | "auto";
  disabled?: boolean;
  align?: "left" | "center" | "right";
  loading?: boolean;
}

export const ButtonComponent: React.FC<IButtonProps> = ({
  children,
  icon,
  iconPosition = "left",
  status = "default",
  size = "md",
  align = "left",
  loading = false,
  ...props
}) => {
  const statusClasses = {
    default: "bg-gray-200 hover:bg-opacity-70",
    neutral: "dark:bg-dark-800 dark:border-dark-700 dark:hover:bg-dark-700 hover:bg-gray-100 dark:text-dark-200 text-rogerBlue-800 border",
    primary: "bg-sky-600 hover:bg-opacity-80 text-white border border-transparent",
    secondary:
      "bg-neutral-100 hover:bg-gray-200 text-sky-600 hover:text-rogerBlue-800 font-medium border-gray-200 border dark:border-dark-700 dark:bg-dark-800 dark:text-dark-300 dark:hover:bg-dark-700 dark:hover:border-dark-600 dark:hover:text-white",
    danger: "bg-rogerRed-500 hover:bg-opacity-80 text-white",
    dangerSecondary: "text-rogerRed-600",
    initial: "hover:bg-opacity-80",
  };

  const sizeClasses = {
    xxs: `py-0.5 text-xs`,
    xs: `py-1.5 text-xs`,
    sm: `py-1.5 text-xs`,
    md: `py-2.5 text-sm`,
    lg: `py-3 text-base`,
    auto: `p-auto`,
  };

  const alignClasses = {
    left: "justify-start",
    center: "justify-center",
    right: "justify-end",
  };

  const iconSizeClasses = {
    xxs: "w-2 h-2",
    xs: "w-4 h-4",
    sm: "w-4 h-4",
    md: "w-5 h-5",
    lg: "w-6 h-6",
    auto: "w-auto h-auto",
  };

  const contentSizeClasses = {
    xxs: "mx-2 gap-1",
    xs: "mx-3 gap-1",
    sm: "mx-3 gap-1",
    md: "mx-5 gap-2",
    lg: "mx-5 gap-3",
    auto: "",
  };

  const disabledClasses = props.disabled === true ? "opacity-30 cursor-not-allowed hover:bg-opacity-100" : "";

  return (
    <button
      {...props}
      className={`
        roger-button
        duration-400
        group flex whitespace-nowrap rounded-md font-medium
        transition-all
        ${sizeClasses[size]}
        ${statusClasses[status]}
        ${alignClasses[align]}
        ${disabledClasses}
        ${props.className ?? ""}
      `}
    >
      <div className={`flex ${contentSizeClasses[size]} items-center`}>
        {(icon !== undefined || loading) && (
          <i
            className={`${iconSizeClasses[size]}
          ${iconPosition === "right" ? "order-3 -mr-1" : ""} ${iconPosition === "left" ? "order-1 -ml-1" : ""}`}
          >
            {icon !== undefined && !loading && icon}
            {loading && <RiLoader4Line className="animate-spin" />}
          </i>
        )}

        <span className="order-2 block h-full">{children}</span>
      </div>
    </button>
  );
};

ButtonComponent.displayName = "ButtonComponent";
