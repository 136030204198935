type FileType = "text" | "document" | "image" | "audio" | "video" | "code" | "data" | "font" | "archive" | "executable" | "unknown";

export const useFileType = () => {
  const fileExtensions: Record<string, { name: string; type: FileType }> = {
    txt: { name: "Fichier texte", type: "text" },
    pdf: { name: "Portable Document Format", type: "document" },
    jpg: { name: "Joint Photographic Experts Group", type: "image" },
    jpeg: { name: "Joint Photographic Experts Group", type: "image" },
    png: { name: "Portable Network Graphics", type: "image" },
    doc: { name: "Microsoft Word document", type: "document" },
    docx: { name: "Microsoft Word document", type: "document" },
    xls: { name: "Microsoft Excel worksheet", type: "document" },
    xlsx: { name: "Microsoft Excel worksheet", type: "document" },
    ppt: { name: "Microsoft PowerPoint presentation", type: "document" },
    pptx: { name: "Microsoft PowerPoint presentation", type: "document" },
    mp3: { name: "MPEG-1 Audio Layer 3", type: "audio" },
    mp4: { name: "MPEG-4 Part 14", type: "video" },
    avi: { name: "Audio Video Interleave", type: "video" },
    zip: { name: "Fichier archive compressé", type: "archive" },
    rar: { name: "Fichier archive RAR", type: "archive" },
    gif: { name: "Graphics Interchange Format", type: "image" },
    bmp: { name: "Windows Bitmap image", type: "image" },
    svg: { name: "Scalable Vector Graphics", type: "image" },
    css: { name: "Cascading Style Sheet", type: "code" },
    html: { name: "Hypertext Markup Language", type: "code" },
    js: { name: "JavaScript", type: "code" },
    json: { name: "JavaScript Object Notation", type: "data" },
    xml: { name: "Extensible Markup Language", type: "data" },
    md: { name: "Markdown", type: "text" },
    csv: { name: "Comma-Separated Values", type: "data" },
    sql: { name: "Structured Query Language", type: "data" },
    py: { name: "Python script", type: "code" },
    rb: { name: "Ruby script", type: "code" },
    php: { name: "Hypertext Preprocessor", type: "code" },
    java: { name: "Java source code", type: "code" },
    c: { name: "C source code", type: "code" },
    cpp: { name: "C++ source code", type: "code" },
    h: { name: "C header file", type: "code" },
    hpp: { name: "C++ header file", type: "code" },
    ts: { name: "TypeScript source code", type: "code" },
    ogg: { name: "Ogg Vorbis audio", type: "audio" },
    ogv: { name: "Ogg video", type: "video" },
    webm: { name: "WebM video", type: "video" },
    ttf: { name: "TrueType Font", type: "font" },
    otf: { name: "OpenType Font", type: "font" },
    wav: { name: "Waveform Audio Format", type: "audio" },
    aiff: { name: "Audio Interchange File Format", type: "audio" },
    flac: { name: "Free Lossless Audio Codec", type: "audio" },
    wmv: { name: "Windows Media Video", type: "video" },
    mov: { name: "Apple QuickTime Movie", type: "video" },
    m4v: { name: "MPEG-4 Video", type: "video" },
    mpeg: { name: "MPEG video", type: "video" },
    mpkg: { name: "Apple Installer Package", type: "archive" },
    dmg: { name: "Apple Disk Image", type: "archive" },
    exe: { name: "Windows Executable", type: "executable" },
    deb: { name: "Debian package", type: "archive" },
    rpm: { name: "Red Hat Package Manager", type: "archive" },
    gz: { name: "Gzip Archive", type: "archive" },
    tar: { name: "Tarball Archive", type: "archive" },
  };

  const getExtensionsByTypes = (types: string[]) => {
    return Object.keys(fileExtensions).filter((ext) => types.includes(fileExtensions[ext]?.type));
  };

  const getExtensionsByType = (type: string) => {
    return getExtensionsByTypes([type]);
  };

  const getExtensionsWithoutTypes = (types: string[]) => {
    return Object.keys(fileExtensions).filter((ext) => !types.includes(fileExtensions[ext]?.type));
  };

  const getTypeByExtension = (extension: string) => {
    return fileExtensions[extension]?.type ?? "unknown";
  };

  return { getExtensionsByType, getExtensionsByTypes, getExtensionsWithoutTypes, getTypeByExtension };
};
