import { type IRoomIdName, type ITask, type TaskPriorityEnum } from "@/api/client";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { CreateTaskDrawerComponent } from "@/components/shared/tasks/CreateTaskDrawerComponent/CreateTaskDrawerComponent";
import { eventNames } from "@/constants/eventNames";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { useTasksQuery } from "@/hooks/queries/tasks/useTasksQuery";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

export const useHomeTasksContent = () => {
  const { data: meData } = useMeQuery();
  const [filteredPriority, setFilteredPriority] = useState<TaskPriorityEnum | null>(null);
  const [filteredAssigneeId, setFilteredAssigneeId] = useState<string | null>(meData?.id ?? null);
  const { data: tasksData } = useTasksQuery({
    where: {
      assignedToUserId: filteredAssigneeId ?? undefined,
      priority: filteredPriority ?? undefined,
    },
  });
  const { openDrawer } = useContext(DrawerContext);
  const [tasks, setTasks] = useState<ITask[]>([]);

  const [isCreateTaskStatusPopinVisible, setIsCreateTaskStatusPopinVisible] = useState(false);

  const rooms = useMemo<IRoomIdName[]>(() => {
    return tasks
      .map((task) => {
        return task.room;
      })
      .reduce((uniqueRooms: IRoomIdName[], room) => {
        if (room == null) return uniqueRooms;
        if (uniqueRooms.find((uniqueRoom) => uniqueRoom?.id === room.id) != null) return uniqueRooms;
        return [...uniqueRooms, room];
      }, []);
  }, [tasks]);

  useEffect(() => {
    if (tasksData == null) return;
    setTasks(tasksData ?? []);
  }, [tasksData]);

  const onCloseTaskStatusCreationPopin = useCallback(() => {
    setIsCreateTaskStatusPopinVisible(false);
  }, []);

  useEffect(() => {
    if (!isCreateTaskStatusPopinVisible) return;
    window.addEventListener(eventNames.CLOSE_TASK_STATUS_CREATION_POPIN, onCloseTaskStatusCreationPopin);

    return () => {
      if (!isCreateTaskStatusPopinVisible) return;
      window.removeEventListener(eventNames.CLOSE_TASK_STATUS_CREATION_POPIN, onCloseTaskStatusCreationPopin);
    };
  }, [isCreateTaskStatusPopinVisible]);

  const onCreateTask = () => {
    openDrawer({
      drawerType: DrawerType.CREATE_TASK,
      component: <CreateTaskDrawerComponent assigneeId={filteredAssigneeId ?? undefined} />,
    });
  };

  const onFilterPriorityChange = (priority: TaskPriorityEnum | null) => {
    setFilteredPriority(priority);
  };

  const onCreateTaskStatusPopinClose = useCallback(() => {
    setIsCreateTaskStatusPopinVisible(false);
  }, []);

  const onCreateTaskStatusPopinOpen = useCallback(() => {
    setIsCreateTaskStatusPopinVisible(true);
  }, []);

  const onResetFilters = useCallback(() => {
    setFilteredAssigneeId(null);
    setFilteredPriority(null);
  }, []);

  const onUserChange = (userId: string, isAssigned: boolean) => {
    if (isAssigned) {
      setFilteredAssigneeId(userId);
    } else {
      setFilteredAssigneeId(null);
    }
  };

  return {
    tasks,
    onCreateTask,
    filteredPriority,
    filteredAssigneeId,
    onFilterPriorityChange,
    onCreateTaskStatusPopinClose,
    onCreateTaskStatusPopinOpen,
    isCreateTaskStatusPopinVisible,
    rooms,
    onResetFilters,
    onUserChange,
  };
};
