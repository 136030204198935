import { type IQuotaData } from "@/api/client";
import { RemoveRoomsTabComponent } from "@/components/billing/modals/downgrade/tabs/RemoveRoomsTabComponent";
import { RemoveUsersTabComponent } from "@/components/billing/modals/downgrade/tabs/RemoveUsersTabComponent";
import { ModalLayoutComponent, type IModalTab } from "@/components/shared/modal/ModalLayoutComponent";
import { eventNames } from "@/constants/eventNames";
import { ModalNames } from "@/constants/modalNames";
import { QuotaType } from "@/interfaces/billing";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface IDowngradeModalComponentProps {
  quotas: IQuotaData[];
}

export const DowngradeModalComponent = ({ quotas }: IDowngradeModalComponentProps) => {
  const { t } = useTranslation();

  const tabs = useMemo(() => {
    const tabs = [];
    if (quotas.find((quota) => quota.quotaType === QuotaType.MEMBER) != null) {
      tabs.push({
        label: t("billing.modals.downgrade.tabs.member.title"),
        content: <RemoveUsersTabComponent quotas={quotas} />,
        id: QuotaType.MEMBER,
        disabled: false,
      });
    }
    if (quotas.find((quota) => quota.quotaType === QuotaType.ROOM) != null) {
      tabs.push({
        label: t("billing.modals.downgrade.tabs.room.title"),
        content: <RemoveRoomsTabComponent quotas={quotas} />,
        id: QuotaType.ROOM,
        disabled: false,
      });
    }
    return tabs;
  }, [quotas]);

  const [currentTab, setCurrentTab] = useState<IModalTab>(tabs[0]);

  const handleTabChange = useCallback((tab: IModalTab) => {
    setCurrentTab(tab);
  }, []);

  const handleGoToNextTab = useCallback(() => {
    const currentTabIndex = tabs.findIndex((tab) => tab.id === currentTab.id);
    setCurrentTab(tabs[currentTabIndex + 1]);
  }, [currentTab]);

  useEffect(() => {
    window.addEventListener(eventNames.GOTO_NEXT_TAB, handleGoToNextTab);
    return () => {
      window.removeEventListener(eventNames.GOTO_NEXT_TAB, handleGoToNextTab);
    };
  });

  return (
    <ModalLayoutComponent
      key={currentTab.id}
      hideTabs={true}
      tabs={tabs}
      title={currentTab.label}
      size="xl"
      name={ModalNames.DOWNGRADE}
      onTabChange={handleTabChange}
      defaultTab={currentTab.id}
    ></ModalLayoutComponent>
  );
};
