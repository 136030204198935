import { GoogleTargetFeatureEnum, type IEmailThread } from "@/api/client";
import { EmailConnectionContext } from "@/contexts/EmailConnectionContextProvider";
import { RoomContext } from "@/contexts/RoomContext";
import { useGoogleSignIn } from "@/hooks/authentification/useGoogleSignIn";
import { useSendGoogleAuthorizationCodeMutation } from "@/hooks/mutations/google/useSendGoogleAuthorizationCodeMutation";
import { useThreadsQuery } from "@/hooks/queries/emails/useThreadsQuery";
import { useEmailAuthorization } from "@/hooks/shared/emails/useEmailAuthorization";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
const GOOGLE_GMAIL_SCOPE = "https://www.googleapis.com/auth/gmail.modify";

export const useEmailsTab = () => {
  const { mutate: sendAuthorizationCode } = useSendGoogleAuthorizationCodeMutation();
  const { roomResponse: roomContext } = useContext(RoomContext);
  const { isConnected, setIsConnected } = useContext(EmailConnectionContext);
  const navigate = useNavigate();
  const roomId = useMemo(() => {
    return roomContext?.room?.id;
  }, [roomContext?.room?.id]);
  const { shouldShowEmails } = useEmailAuthorization();
  const { threadId } = useParams();

  const [openedThreadId, setOpenedThreadId] = useState<string | undefined>(threadId);

  const {
    data: thread,
    isError,
    isLoading,
    refetch,
    fetchPreviousPage,
  } = useThreadsQuery({
    roomId: roomId ?? "",
    nextCount: 50,
  });

  useEffect(() => {
    if (roomId == null) return;
    if (!shouldShowEmails) {
      navigate(`/dashboard/rooms/${roomId}`);
    }
  }, [shouldShowEmails, roomId]);

  useEffect(() => {
    setOpenedThreadId(threadId);
  }, [threadId]);

  useEffect(() => {
    const lastThreadId = thread?.pages[0]?.data[0]?.id;
    if (lastThreadId == null && isConnected) return;
    if (threadId != null || (lastThreadId == null && !isConnected)) return;
    navigate(`/dashboard/rooms/${roomId}/emails/${lastThreadId}`);
  }, [thread?.pages[0]?.data[0]?.id, roomId, threadId, isConnected]);

  useEffect(() => {
    if (isLoading) return;
    setIsConnected(!isError);
  }, [isError, isLoading]);

  const onSendAuthorizationCode = useCallback(
    (authorizationCode: string, hasRedirectUri: boolean, targetFeature: GoogleTargetFeatureEnum) => {
      sendAuthorizationCode({ code: authorizationCode, hasRedirectUri, targetFeature });
    },
    [sendAuthorizationCode],
  );

  const onGoogleLoginSuccess = useCallback((authorizationCode: string, hasRedirectUri: boolean) => {
    setTimeout(async () => {
      await refetch();
      setIsConnected(true);
    }, 1000);

    onSendAuthorizationCode(authorizationCode, hasRedirectUri, GoogleTargetFeatureEnum.Gmail);
  }, []);

  const { signIn: onGoogleLogin } = useGoogleSignIn({
    onSuccess: (code) => {
      onGoogleLoginSuccess(code, false);
    },
    onAppOpenedWithScheme: (code) => {
      onGoogleLoginSuccess(code, true);
    },
    scope: GOOGLE_GMAIL_SCOPE,
  });

  const flatThreads = useMemo<IEmailThread[]>(() => {
    return thread?.pages.flatMap((page) => page?.data) ?? [];
  }, [thread?.pages]);

  return {
    onGoogleLogin,
    isConnected,
    flatThreads,
    isLoading,
    fetchPreviousPage,
    openedThreadId,
    shouldShowEmails,
  };
};
