import { Quill } from "react-quill";
// @ts-expect-error Seams to be linked to quill
const Embed = Quill.import("blots/embed");
export class EmojiBlot extends Embed {
  // This is what appears in the editor
  static create(value: { shortcodes?: string; native: string }): HTMLElement {
    const node = super.create();
    node.className = "emoji-wrapper";
    node.setAttribute("shortcodes", value.shortcodes);
    node.setAttribute("native", value.native);
    node.setAttribute("id", "emoji");
    node.setAttribute("set", "apple");
    return node;
  }

  // This is what appears in the insert delta
  static value(node: HTMLElement) {
    return {
      shortcodes: node.getAttribute("shortcodes"),
      native: node.getAttribute("native"),
    };
  }
}
// @ts-expect-error Seams to be linked to quill
EmojiBlot.blotName = "emoji";
// @ts-expect-error Seams to be linked to quill
EmojiBlot.className = "ql-emoji";
// @ts-expect-error Seams to be linked to quill
EmojiBlot.tagName = "em-emoji";
