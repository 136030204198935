import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useMyDmsQuery = () => {
  return useQuery({
    queryKey: [QueryKeys.MY_DMS],
    queryFn: async () => {
      const response = await api.my.getDirectMessagesFromHome();
      return response.data.data;
    },
  });
};
