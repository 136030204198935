import { type IPlan } from "@/api/client";

export interface ILimitationError {
  message: string;
  quotaData: {
    quotaType: QuotaType;
    quotaLimit: number;
    currentNumber: number;
  };
  statusCode: number;
}
export enum QuotaType {
  ROOM = "room",
  GUEST = "guest",
  MEMBER = "member",
  STORAGE = "storage",
}
export interface ISubscriptionUpdatedData {
  id: string;
  plan: IPlan;
  status: string;
  isUpgrade: boolean;
}
