import { InviteMemberComponent } from "@/components/shared/members/InviteMemberComponent";
import { useInviteMember } from "@/hooks/queries/shared/useInviteMember";
export const InviteRoomMemberComponent = ({
  email,
  isInvited,
  member,
  roomId,
}: {
  email: string;
  member?: { firstName?: string; lastName?: string; email: string };
  isInvited?: boolean;
  roomId: string;
}) => {
  const { inviteMemberToRoomMutation } = useInviteMember();
  return (
    <InviteMemberComponent
      isInvited={isInvited}
      member={member}
      email={email}
      inviteAction={() => {
        inviteMemberToRoomMutation.mutate({ email, roomId });
      }}
    ></InviteMemberComponent>
  );
};
