/* eslint-disable */
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import React, { ReactNode } from "react";
import { createPortal } from "react-dom";
import styles from "./modal.module.scss";

const ModalTemplate: React.FC<
  React.PropsWithChildren<{
    title?: ReactNode;
    footer?: ReactNode;
    cancelText?: string;
    okText?: string;
    onCancel?: () => void;
    onOk?: () => void;
    width?: number;
    isButtonDisabled?: boolean;
  }>
> = (props) => {
  const { title, children, footer, cancelText = "Cancel", okText = "OK", onCancel, onOk, width = 530, isButtonDisabled = false } = props;

  return (
    <div className={styles.modal}>
      <div className="modal-mask" />
      <div className="modal-warp">
        <div className="modal-content" style={{ width }}>
          <div className="modal-body">{children}</div>
          {typeof footer !== "undefined" ? (
            <div className="modal-footer">
              <ButtonComponent disabled={isButtonDisabled} size={"md"} status={"primary"} onClick={onOk}>
                {okText}
              </ButtonComponent>
            </div>
          ) : (
            footer
          )}
        </div>
      </div>
    </div>
  );
};

const Modal = (props: Parameters<typeof ModalTemplate>[0] & { open: boolean }) => {
  const { open, ...omit } = props;

  return createPortal(open ? ModalTemplate(omit) : null, document.body);
};

export default Modal;
