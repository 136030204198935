import type { ITaskOne, ITaskUpdateResponse, TaskPriorityEnum } from "@/api/client";
import { TaskTagsContext } from "@/contexts/TaskTagsContextProvider";
import { type TaskUpdateBody } from "@/hooks/mutations/tasks/useUpdateTaskMutation";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import type { IRichTextEditorRef } from "@/interfaces/richTextEditor";
import type { UseMutateFunction } from "@tanstack/react-query";
import { useContext, useEffect, useMemo, useRef } from "react";
export const useCommentHeaderComponent = (task: ITaskOne, updateTask: UseMutateFunction<ITaskUpdateResponse, Error, TaskUpdateBody, void>) => {
  const { setTags } = useContext(TaskTagsContext);

  useEffect(() => {
    if (task?.tags == null) return;
    setTags(task.tags);
    return () => {
      setTags([]);
    };
  }, []);

  const taskId = useMemo(() => task?.id, [task]);

  const descriptionEditorRef = useRef<IRichTextEditorRef>(null);
  const { data: me } = useMeQuery();

  const canUpdateIsPrivate = useMemo(() => {
    if (task == null || me == null) {
      return false;
    }
    return task.createdByUser?.id === me.id;
  }, [task, me]);

  const onPriorityChange = (priority: TaskPriorityEnum | null) => {
    if (task == null || task.priority === priority) {
      return;
    }
    updateTask({
      taskId,
      priority,
    });
  };

  const onIsPrivateChange = (isPrivate: boolean) => {
    if (task == null || task.isPrivate === isPrivate) {
      return;
    }
    updateTask({
      taskId,
      isPrivate,
    });
  };

  const onStatusChange = (taskStatusId: string) => {
    if (task == null || task.taskStatus.id === taskStatusId) {
      return;
    }
    updateTask({
      taskId,
      taskStatusId,
    });
  };

  const onDeadlineChange = (deadline: string | null) => {
    if (task == null || task.deadline === deadline) {
      return;
    }
    updateTask({
      taskId,
      deadline,
    });
  };
  const onDescriptionBlur = () => {
    const description = descriptionEditorRef.current?.getEditorContents();
    if (description != null) {
      updateTask({
        taskId: task.id,
        description,
      });
    }
  };

  const creatorFullName = `${task?.createdByUser.firstName ?? ""} ${task?.createdByUser.lastName ?? ""}`;

  return {
    onPriorityChange,
    onIsPrivateChange,
    onStatusChange,
    onDeadlineChange,
    onDescriptionBlur,
    creatorFullName,
    descriptionEditorRef,
    canUpdateIsPrivate,
  };
};
