import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface IReorderTaskStatusMutationParams {
  roomId?: string;
  taskStatusId: string;
  to: number;
}
export const useReorderTaskStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ roomId, taskStatusId, to }: IReorderTaskStatusMutationParams) => {
      if (roomId == null) return;

      await api.rooms.reorderTaskStatus(roomId, taskStatusId, { to });
    },
    onError: (_data, { roomId }) => {
      void queryClient.invalidateQueries({
        queryKey: [QueryKeys.ROOMS, roomId, QueryKeys.TASKS, QueryKeys.TASKS_STATUSES],
      });
    },
  });
};
