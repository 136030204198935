import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateRoomNotificationSettingsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ roomId, isNotificationEnabled }: { roomId: string; isNotificationEnabled: boolean }) => {
      const { data } = await api.my.setNotificationsSettingsByRoom(roomId, {
        enableNotifications: isNotificationEnabled,
      });

      return data;
    },
    onError: (error: Error) => {
      console.error(error);
    },
    onSuccess: (data) => {
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, QueryKeys.NOTIFICATION_SETTINGS, data.id] });
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.ME, QueryKeys.NOTIFICATION_SETTINGS] });
    },
  });
};
