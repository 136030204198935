import { type IFileSearchBody, type IFilesPaginatedResponse } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useInfiniteQuery } from "@tanstack/react-query";

interface IFilesQueryParams {
  name?: string;
  roomId?: string;
  channelId?: string;
  extension?: string;
  date?: string;
}

export const useFilesQuery = (params: IFilesQueryParams) => {
  return useInfiniteQuery<IFilesPaginatedResponse>({
    queryKey: [QueryKeys.FILES, params],
    queryFn: async ({ pageParam = "" }) => {
      const body: IFileSearchBody = {
        count: 20,
        ...params,
      };
      if (pageParam !== "") {
        body.cursor = pageParam as string;
      }

      const response = await api.files.getFilteredPaginatedFiles(body);

      return response.data;
    },
    getNextPageParam: (lastPage: IFilesPaginatedResponse) => {
      return lastPage?.pagination.nextCursor ?? undefined;
    },
    staleTime: 0,
    initialPageParam: "",
  });
};
