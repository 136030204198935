import { EmailThreadContext } from "@/contexts/EmailThreadContextProvider";
import { useContext, useMemo } from "react";

export const useMailEditor = (isEmpty: boolean) => {
  const { to, cc } = useContext(EmailThreadContext);

  const isMailEditorButtonActive = useMemo(() => (!isEmpty && (to?.length ?? 0) > 0) || (cc?.length ?? 0) > 0, [to, cc, isEmpty]);

  return {
    isMailEditorButtonActive,
  };
};
