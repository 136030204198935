import { type FunctionComponent } from "react";
interface IEmojiComponentProps {
  id?: string;
  shortcodes?: string;
  native?: string;
  size?: string;
  fallback?: string;
  set?: string;
  skin?: string;
}

export const EmojiComponent: FunctionComponent<IEmojiComponentProps> = (props) => {
  return <em-emoji {...props} />;
};
