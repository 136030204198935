import { type IEmailIdentity, type IEmailThread } from "@/api/client";
import { useThreadQuery } from "@/hooks/queries/emails/useThreadQuery";
import { createContext, useMemo, useState, type Dispatch, type FunctionComponent, type PropsWithChildren, type SetStateAction } from "react";

interface IEmailThreadContextData {
  setTo: Dispatch<SetStateAction<IEmailIdentity[]>>;
  to?: IEmailIdentity[];
  setCc: Dispatch<SetStateAction<IEmailIdentity[]>>;
  cc?: IEmailIdentity[];
  isReplyOpened: boolean;
  thread: IEmailThread | undefined;
  setReplyToMailId: Dispatch<SetStateAction<string | null>>;
  replyToMailId?: string | null;
}

interface IEmailThreadContextProviderProps extends PropsWithChildren {
  threadId?: string;
}

export const EmailThreadContext = createContext<IEmailThreadContextData>({
  setTo: () => {},
  to: [],
  setCc: () => {},
  cc: [],
  setReplyToMailId: () => {},
  isReplyOpened: false,
  replyToMailId: undefined,
  thread: undefined,
});

export const EmailThreadContextProvider: FunctionComponent<IEmailThreadContextProviderProps> = ({ children, threadId }) => {
  const [to, setTo] = useState<IEmailIdentity[]>([]);
  const [cc, setCc] = useState<IEmailIdentity[]>([]);
  const [replyToMailId, setReplyToMailId] = useState<string | null>(null);
  const isReplyOpened = useMemo(() => replyToMailId != null, [replyToMailId]);

  const { data: thread } = useThreadQuery(threadId);

  const contextValue = useMemo(
    () => ({
      setTo,
      to,
      setCc,
      cc,
      setReplyToMailId,
      replyToMailId,
      thread,
      isReplyOpened,
    }),
    [to, cc, thread, replyToMailId],
  );

  return <EmailThreadContext.Provider value={contextValue}>{children}</EmailThreadContext.Provider>;
};
