import { type IChannel, type IChannelUpdateBody } from "@/api/client";
import { eventNames } from "@/constants/eventNames";
import { QueryKeys } from "@/constants/queryKeys";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useCreateChannelMutation = (roomId: string | undefined) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: me } = useMeQuery();
  return useMutation({
    mutationFn: async (newChannel: IChannelUpdateBody) => {
      if (me == null || roomId == null) return;
      const res = await api.rooms.addChannel(roomId, newChannel, { defaultToastErrorMessage: t("channelModal.tabs.about.create.error") });
      const event = new CustomEvent<IChannel>(eventNames.CHANNEL_CREATED, {
        detail: res.data.channel,
      });

      window.dispatchEvent(event);

      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, roomId, QueryKeys.CHANNELS] });
      return toast.success(t("channelModal.tabs.about.create.success"));
    },
  });
};
