import { type IExtendedFile, type IFilesPaginatedResponse } from "@/api/client";
import { SearchedFileComponent } from "@/components/search/SearchedFileComponent";
import { type InfiniteData, type InfiniteQueryObserverResult } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";

interface ISearchFileResultProps {
  searchResult: IExtendedFile[];
  fetchNextPage: () => Promise<InfiniteQueryObserverResult<InfiniteData<IFilesPaginatedResponse, unknown>, Error>>;
}

export const SearchFileResultComponent = ({ searchResult, fetchNextPage }: ISearchFileResultProps) => {
  const { t } = useTranslation();

  return (
    <div className="grid h-96 grow grid-cols-1">
      {searchResult?.length > 0 ? (
        <Virtuoso
          className="min-h-0"
          style={{ gridColumn: "1 / span 1", gridRow: "1 / span 1" }}
          data={searchResult}
          itemContent={(index: number, file: IExtendedFile) => <SearchedFileComponent key={file.id} file={file} />}
          endReached={async () => await fetchNextPage()}
        />
      ) : (
        <div className="p-4 text-center text-gray-400">{t("header.search.files.noResult")}</div>
      )}
    </div>
  );
};
