import { UserActivityStatus } from "@/api/client";
import { UserStatusBadgeComponent } from "@/components/shared/user/UserStatusBadgeComponent";
import { type FunctionComponent, type MouseEvent } from "react";
import { useTranslation } from "react-i18next";

interface UserStatusComponentProps {
  status: UserActivityStatus;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  isDisabled?: boolean;
}

export const UserStatusComponent: FunctionComponent<UserStatusComponentProps> = ({ status, onClick, isDisabled = false }) => {
  const { t } = useTranslation();

  const statusNameByStatus: Record<UserActivityStatus, string> = {
    [UserActivityStatus.Active]: t("user.activityStatus.active"),
    [UserActivityStatus.Inactive]: t("user.activityStatus.inactive"),
    [UserActivityStatus.Offline]: t("user.activityStatus.offline"),
  };

  return (
    <div
      className={`flex flex-row items-center ${isDisabled ? "cursor-default" : "cursor-pointer hover:opacity-70"}`}
      onClick={isDisabled ? undefined : onClick}
    >
      <UserStatusBadgeComponent status={status} />
      <p className="ml-1 text-xs font-medium text-zinc-500">{statusNameByStatus[status]}</p>
    </div>
  );
};
