import React, { createContext, useMemo, useState, type PropsWithChildren } from "react";

interface BulkInviteContextData {
  mails: string[];
  setMails: (mails: string[]) => void;
  removeMail: (mail: string) => void;
}

export const BulkInviteContext = createContext<BulkInviteContextData>({
  mails: [],
  setMails: () => {},
  removeMail: () => {},
});

export const BulkInviteContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [mails, setMails] = useState<string[]>([]);

  const removeMail = (mail: string) => {
    const newMails = mails.filter((currentMail) => currentMail !== mail);
    setMails(newMails);
  };

  const contextValue: BulkInviteContextData = useMemo(
    () => ({
      mails,
      setMails,
      removeMail,
    }),
    [mails, setMails],
  );

  return <BulkInviteContext.Provider value={contextValue}>{children}</BulkInviteContext.Provider>;
};
