import { FileExtensionPickerPopinContentComponent } from "@/components/shared/files/FileExtensionPickerPopinComponent/FileExtensionPickerPopinContentComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { type TippyProps } from "@tippyjs/react";
import { type FunctionComponent } from "react";

interface IFileExtensionPickerPopinComponentProps {
  children: TippyProps["children"];
  isVisible: boolean;
  onCloseTooltip: () => void;
  onFileExtensionSelected: (fileExtension: string | null) => void;
  hasRemoveFileExtensionOption?: boolean;
  fileExtensions: string[];
}

export const FileExtensionPickerPopinComponent: FunctionComponent<IFileExtensionPickerPopinComponentProps> = ({
  children,
  isVisible,
  onCloseTooltip,
  onFileExtensionSelected,
  hasRemoveFileExtensionOption,
  fileExtensions,
}) => {
  return (
    <TooltipComponent
      placement="bottom-start"
      visible={isVisible}
      onClickOutside={onCloseTooltip}
      arrow={false}
      interactive
      noPadding
      content={
        <FileExtensionPickerPopinContentComponent
          onFileExtensionSelected={onFileExtensionSelected}
          hasRemoveFileExtensionOption={hasRemoveFileExtensionOption}
          isVisible={isVisible}
          fileExtensions={fileExtensions}
        />
      }
    >
      {children}
    </TooltipComponent>
  );
};
