import { ConversationComponent } from "@/components/roomPage/tabs/conversation/ConversationComponent";
import { TypingIndicator } from "@/components/roomPage/tabs/conversation/typingIndicator/TypingIndicatorComponent";
import { RoundedContainerComponent } from "@/components/shared/layout/RoundedContainerComponent";
import { RichTextEditorComponent } from "@/components/shared/richTextEditor/RichTextEditorComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { useTranslation } from "react-i18next";
import { BsExclamationCircle as InformationIcon } from "react-icons/bs";

interface IConversationWithEditorProps {
  contextType: string;
  contextId: string;
  isChannel?: boolean;
  isArchived: boolean;
}

export const ConversationWithEditorComponent = ({ contextType, contextId, isArchived }: IConversationWithEditorProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <RoundedContainerComponent className={`flex-1 overflow-hidden ${contextType !== "message" ? "h-full border-r" : ""}`} hasPadding={false}>
      <div className="relative flex h-full flex-col" id={`conversation-${contextId}`}>
        {contextId !== "0" && contextId != null && (
          <>
            <ConversationComponent
              className="px-layout"
              contextId={contextId}
              contextType={contextType}
              key={`conversation-${contextType}${contextId}`}
            />
            {isArchived ? (
              <div className="flex flex-row items-center justify-center px-layout pb-layout">
                <TooltipComponent content={t("conversation.archived.tooltip")} placement="top">
                  <div className="flex flex-row items-center rounded border border-zinc-200 bg-zinc-100 px-4 py-2 text-sm font-normal text-zinc-400">
                    <InformationIcon className="mr-2 h-4 w-4" />
                    {t("conversation.archived.message")}
                  </div>
                </TooltipComponent>
              </div>
            ) : (
              <div className="text-editor relative px-layout pb-layout">
                <RichTextEditorComponent contextType={contextType} contextId={contextId} key={`editor-${contextType}${contextId}`} />
                {contextType !== "message" && <TypingIndicator />}
              </div>
            )}
          </>
        )}
      </div>
    </RoundedContainerComponent>
  );
};
