import { AddElementTagComponent } from "@/components/shared/tag/AddElementTagComponent";
import { PickUserPopinComponent } from "@/components/shared/user/PickUserPopinComponent/PickUserPopinComponent";
import { EmailTagComponent } from "@/components/shared/user/UserTagList/EmailTagComponent";
import { UserTagComponent } from "@/components/shared/user/UserTagList/UserTagComponent";
import { useUserTagList } from "@/components/shared/user/UserTagList/useUserTagList";
import { type UserWithEmail, type UserWithId } from "@/interfaces/user";
import { type FunctionComponent } from "react";

interface UserTagListComponentProps {
  users: Array<UserWithId | UserWithEmail>;
  addUserTitle: string;
  onRemoveUser: (user: UserWithId | UserWithEmail) => void;
  onAddUser: (user: UserWithId | UserWithEmail) => void;
  conversationId: string;
}

export const UserTagListComponent: FunctionComponent<UserTagListComponentProps> = ({
  users,
  addUserTitle,
  onRemoveUser,
  onAddUser,
  conversationId,
}) => {
  const { isPickUserTooltipVisible, onCloseTooltip, onUserChange, onOpenTooltip, onAddUserWithEmail } = useUserTagList(onAddUser, onRemoveUser);
  return (
    <div className="flex flex-col">
      <PickUserPopinComponent
        conversationId={conversationId}
        isVisible={isPickUserTooltipVisible}
        onCloseTooltip={onCloseTooltip}
        onUserChange={onUserChange}
        onAddUserWithEmail={onAddUserWithEmail}
        selectedUsers={users}
      >
        <div>
          <AddElementTagComponent value={addUserTitle} onAddElement={onOpenTooltip} />
        </div>
      </PickUserPopinComponent>
      {users.map((user) =>
        user.type === "email" ? (
          <EmailTagComponent
            key={user.email}
            email={user.email}
            onRemove={() => {
              onRemoveUser(user);
            }}
          />
        ) : (
          <UserTagComponent
            key={user.id}
            userId={user.id}
            onRemove={() => {
              onRemoveUser(user);
            }}
          />
        ),
      )}
    </div>
  );
};
