import { type IFile } from "@/api/client";
import { usePdfInMessage } from "@/components/roomPage/tabs/conversation/message/PdfInMessageComponent/usePdfInMessage";
import { PdfViewerComponent } from "@/components/shared/documentViewer/pdfViewer/PdfViewerComponent";
import { getRandomColorFromString } from "@/utils/utilities";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

interface IPdfPreviewInMessageComponent {
  file: IFile;
  isInForward?: boolean;
  isInEditor?: boolean;
}

export const PdfInMessageComponent = ({ file, isInForward = false, isInEditor = false }: IPdfPreviewInMessageComponent) => {
  const { handleClosePdfViewer, handleOpenPdfViewer, isVisible } = usePdfInMessage();
  return (
    <>
      <PdfViewerComponent file={file} isVisible={isVisible} onClose={handleClosePdfViewer} />
      <div
        key={file.id}
        className={`flex h-12 items-center justify-center rounded border bg-white px-2 ${!isInEditor ? "cursor-pointer" : ""} ${
          !isInForward ? "mb-1 mr-1" : ""
        }`}
        onClick={handleOpenPdfViewer}
      >
        <div
          className="mr-2 rounded bg-red-600 p-1 text-2xs font-bold text-white"
          style={{
            backgroundColor: getRandomColorFromString(file.extension ?? ""),
          }}
        >
          {file.extension}
        </div>
        <div className="max-w-xs truncate text-xs">{file.name}</div>
      </div>
    </>
  );
};
