import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQueries, useQuery } from "@tanstack/react-query";
const STALE_TIME = 3600000 * 0.5;

const getUserQuery = (userId: string | null | undefined) => ({
  queryKey: [QueryKeys.USERS, userId],
  queryFn: async () => {
    if (userId == null) return undefined;
    const response = await api.users.getOneProfile(userId);
    return response.data.data;
  },
  staleTime: STALE_TIME,
  enabled: userId != null,
  refetchOnWindowFocus: false,
});

export const useUserQuery = (userId: string | null | undefined) => {
  return useQuery(getUserQuery(userId));
};

export const useUsersQuery = (userIds: string[]) => {
  return useQueries({
    queries: userIds.map(getUserQuery),
  });
};
