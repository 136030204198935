import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { PickUserPopinContentComponent } from "@/components/shared/user/PickUserPopinComponent/PickUserPopinContentComponent";
import { type SimpleUser, type UserWithEmail, type UserWithId } from "@/interfaces/user";
import { type TippyProps } from "@tippyjs/react";
import { type FunctionComponent } from "react";

interface IPickUserPopinComponentProps {
  children: TippyProps["children"];
  conversationId: string | null;
  isVisible: boolean;
  onCloseTooltip: () => void;
  onUserChange: (user: SimpleUser, isSelected: boolean) => void;
  onAddUserWithEmail: (email: string) => void;
  selectedUsers: Array<UserWithEmail | UserWithId>;
}

export const PickUserPopinComponent: FunctionComponent<IPickUserPopinComponentProps> = ({
  children,
  conversationId,
  isVisible,
  onCloseTooltip,
  onUserChange,
  onAddUserWithEmail,
  selectedUsers,
}) => {
  return (
    <TooltipComponent
      placement="bottom-start"
      visible={isVisible}
      onClickOutside={onCloseTooltip}
      arrow={false}
      interactive
      noPadding
      content={
        <PickUserPopinContentComponent
          isVisible={isVisible}
          conversationId={conversationId}
          onAddUserWithEmail={onAddUserWithEmail}
          onUserChange={onUserChange}
          selectedUsers={selectedUsers}
        />
      }
    >
      {children}
    </TooltipComponent>
  );
};
