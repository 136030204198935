import { FilesContentComponent } from "@/components/shared/filesContent/FilesContentComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { RoomContext } from "@/contexts/RoomContext";
import { useContext, useMemo } from "react";

export const FilesTabComponent = (): JSX.Element => {
  const roomContext = useContext(RoomContext);

  const room = useMemo(() => {
    return roomContext?.roomResponse?.room;
  }, [roomContext?.roomResponse?.room]);
  if (room == null) return <LoaderComponent />;
  return (
    <section className="flex w-full flex-col pb-layout">
      <FilesContentComponent room={room} />
    </section>
  );
};
