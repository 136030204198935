import { eventNames } from "@/constants/eventNames";
import { type ProgressInfo } from "electron-updater";
import { useCallback, useEffect, useState } from "react";

export const useUpdate = () => {
  const [isChecking, setIsChecking] = useState(false);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [versionInfo, setVersionInfo] = useState<VersionInfo>();
  const [updateError, setUpdateError] = useState<ErrorType>();
  const [progressInfo, setProgressInfo] = useState<Partial<ProgressInfo>>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isButtonClickable, setIsButtonClickable] = useState<boolean>(true);

  const checkForUpdate = useCallback(async (isFromClick: boolean) => {
    setIsChecking(true);
    /**
     * @type {import('electron-updater').UpdateCheckResult | null | { message: string, error: Error }}
     */
    const result = await window.electron.invoke("check-update");

    if ((result.versionInfo == null || result?.update === false || result?.updateInfo.version === APP_VERSION) && !isFromClick) {
      setIsChecking(false);
      return;
    }

    if (result?.update !== false) {
      setProgressInfo({ percent: 0 });
      setIsChecking(false);
      setIsModalOpen(true);
      return "Update available";
    }

    if ((result?.error != null || result?.update === false) && isFromClick) {
      setIsModalOpen(true);
      setUpdateAvailable(false);
      setUpdateError(result?.error);
      return "No update available";
    }
  }, []);

  useEffect(() => {
    window.addEventListener(eventNames.OPEN_UPDATE_MODAL, async () => await checkForUpdate(true));
    return () => {
      window.removeEventListener(eventNames.OPEN_UPDATE_MODAL, async () => await checkForUpdate(true));
    };
  }, [isModalOpen]);
  return {
    checkForUpdate,
    isChecking,
    updateAvailable,
    versionInfo,
    updateError,
    progressInfo,
    isModalOpen,
    isButtonClickable,
    setIsChecking,
    setUpdateAvailable,
    setVersionInfo,
    setUpdateError,
    setProgressInfo,
    setIsModalOpen,
    setIsButtonClickable,
  };
};
