import { type DeltaContent } from "@/api/client";
import { PreferencesContext, usePreferences } from "@/hooks/shared/usePreferences";
import { useCallback, useContext } from "react";
export const useDraft = () => {
  const { setPreference } = usePreferences();
  const { preferences } = useContext(PreferencesContext);

  const saveDraft = useCallback((contextId: string, messageContent: DeltaContent | null, isEmpty: boolean) => {
    if (isEmpty || messageContent == null) {
      setPreference(`draft.${contextId}`, null);
      return;
    }
    setPreference(`draft.${contextId}`, { content: messageContent });
  }, []);

  const getDraft = useCallback((contextId: string) => {
    return preferences[`draft.${contextId}`];
  }, []);

  return { saveDraft, getDraft };
};
