import { type ITaskOne } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useTaskQuery = (taskId: string) => {
  return useQuery<ITaskOne>({
    queryKey: [QueryKeys.TASK, taskId],
    queryFn: async () => {
      const response = await api.tasks.getOne(taskId);

      return response.data.data;
    },
    staleTime: 0,
    retry: 1,
  });
};
