import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useGeneralNotificationSettingsQuery = () => {
  return useQuery({
    queryKey: [QueryKeys.ME, QueryKeys.NOTIFICATION_SETTINGS],
    queryFn: async () => {
      const { data } = await api.my.getNotificationsSettingsByGeneral();

      return data;
    },
    retry: 1,
    staleTime: 12 * 60 * 60 * 1000,
  });
};
