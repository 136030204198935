import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { resetPassword } from "@/services/AuthService";
import { passwordConfirmationValidation, passwordValidation } from "@/utils/formValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm, type SubmitErrorHandler, type SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";

interface IResetPasswordFormInputs {
  password: string;
  passwordConfirmation: string;
}

export const ResetPasswordForm = (): JSX.Element => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    password: passwordValidation(t),
    passwordConfirmation: passwordConfirmationValidation(t, Yup.ref("password")),
  });
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const params = useParams();
  const token = params.token;
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<IResetPasswordFormInputs>({
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const inputs = [
    {
      name: "password",
      label: t("forms.fields.resetPassword"),
      type: "password",
      requiredText: "",
    },
    {
      name: "passwordConfirmation",
      label: t("forms.fields.passwordConfirmation"),
      type: "password",
      requiredText: "",
    },
  ];

  const onSubmit: SubmitHandler<IResetPasswordFormInputs> = async (_data) => {
    await resetPassword(token ?? "", _data.password);
    setIsPasswordReset(true);
  };
  const onError: SubmitErrorHandler<IResetPasswordFormInputs> = (err) => {
    // TODO use global error handler
    console.error(err);
  };
  return (
    <>
      {!isPasswordReset ? (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {/* {JSON.stringify(errors)} */}
          {inputs.map((input) => (
            <TextInputComponent key={`cti-${input.name}`} {...input} register={register} error={errors[input.name as keyof typeof errors]?.message} />
          ))}
          <ButtonComponent type="submit" status="primary" className="w-full" align="center" disabled={!isValid}>
            {t("authentication.resetPassword.save")}
          </ButtonComponent>
        </form>
      ) : (
        <div className="text-center">
          <span className="block text-center text-gray-400">{t("authentication.resetPassword.success")}</span>
          <Link className="text-sm" to="/signin">
            {t("authentication.backSignIn")}
          </Link>
        </div>
      )}
    </>
  );
};
