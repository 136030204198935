import { JitsiContext } from "@/contexts/JitsiContextProvider";
import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";

export const useCreateVideoConferenceMutation = (conversationId: string) => {
  const jitsiContext = useContext(JitsiContext);

  return useMutation({
    mutationFn: async (_shouldJoin: boolean) => {
      const { data } = await api.videoconference.createVideoconference({ conversationId });

      return data;
    },
    onSuccess: (data, shouldJoin) => {
      if (!shouldJoin) return;
      jitsiContext.joinVideoCall(data.jitsiDomain, data.meetingRoomId);
    },
  });
};
