import { useEmailListPopinContent } from "@/components/roomPage/tabs/emails/emailListPopin/useEmailListPopinContent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const EmailListPopinContentComponent: FunctionComponent = () => {
  const { t } = useTranslation();
  const { onGoogleLogout } = useEmailListPopinContent();
  return (
    <nav
      className="overflow-hidden rounded-lg"
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <button
        onClick={onGoogleLogout}
        className="flex w-full cursor-pointer items-start justify-between rounded-b-lg p-2 text-rogerRed-600  hover:bg-rogerRed-600 hover:text-white"
      >
        <span> {t("roomPage.tabs.mails.buttons.googleLogout")}</span>
      </button>
    </nav>
  );
};
