import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export const useCreateOrOpenDmMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async ({ recipients, roomId }: { recipients: string[]; roomId: string | undefined }) => {
      let openDmData;
      try {
        await api.directMessages.createDirectMessage({ recipients }, { defaultToastErrorMessage: null });
      } catch (error) {
        console.warn(error);
      } finally {
        openDmData = await api.directMessages.openDirectMessage({ recipients, openFromRoom: roomId });
        if (roomId !== undefined) {
          await queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, QueryKeys.DMS, roomId] });
          navigate(`/dashboard/rooms/${roomId}/dm/${openDmData.data.data.conversation.id}`);
        } else {
          await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_DMS] });
          navigate(`/dashboard/dms/${openDmData.data.data.conversation.id}`);
        }
      }

      return openDmData.data.data;
    },
  });
};
