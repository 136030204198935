import { eventNames } from "@/constants/eventNames";
import { useCallback, useEffect, useMemo, useState } from "react";
interface IModal {
  name: string;
  component: JSX.Element;
  backdropOpacity: number;
}
export const ModalWrapperComponent = (): JSX.Element => {
  const [modals, setModals] = useState<IModal[]>([]);

  const animationDuration: 100 | 200 | 300 | 1000 = 300;

  const openModal = useCallback(
    (e: CustomEvent<{ shouldCloseOthers: boolean; name: string; backdropOpacity: number; modalComponent: JSX.Element }>): void => {
      if (e.detail.shouldCloseOthers) {
        setModals([]);
      }

      !modals.some((modal: IModal) => modal.name === e.detail.name) &&
        setModals((currentModals: IModal[]) => [
          ...currentModals,
          {
            name: e.detail.name,
            component: e.detail.modalComponent,
            backdropOpacity: e.detail.backdropOpacity ?? 0.5,
          },
        ]);
    },
    [modals],
  );
  const closeModal = useCallback(
    (modalName: string | undefined): void => {
      if (modalName === undefined) {
        setModals([]);
        return;
      }
      const newModals = modals.filter((modal) => modal.name !== modalName);
      setModals(newModals);
    },
    [modals],
  );

  const handleCloseModal = useCallback(
    (e: CustomEvent) => {
      closeModal(e.detail.name);
    },
    [modals],
  );

  const handleOpenModal = useCallback(
    (e: CustomEvent) => {
      openModal(e);
    },
    [modals],
  );

  useEffect(() => {
    window.addEventListener(eventNames.OPEN_MODAL, handleOpenModal as EventListener);
    window.addEventListener(eventNames.CLOSE_MODAL, handleCloseModal as EventListener);

    return () => {
      // we need to remove the event listeners when the component unmounts
      window.removeEventListener(eventNames.OPEN_MODAL, handleOpenModal as EventListener);
      window.removeEventListener(eventNames.CLOSE_MODAL, handleCloseModal as EventListener);
    };
  }, [modals]);

  const isThereAModalOpen = useMemo(() => modals.length > 0, [modals]);

  return (
    <section id="modal-section" className="relative">
      {modals.map((modal: IModal, index: number) => (
        <div
          id={modal.name}
          key={`${modal.name}${index}`}
          className={`duration- fixed left-0 top-0 z-[100] h-full w-full bg-black transition-all${animationDuration} ${
            isThereAModalOpen ? "" : "pointer-events-none opacity-0"
          } 
          `}
          onMouseDown={() => {
            closeModal(modals[modals.length - 1].name);
          }}
          style={{
            backgroundColor: `rgba(0, 0, 0, ${modal.backdropOpacity})`,
          }}
        >
          <section
            className="absolute flex h-full w-full items-center justify-center"
            style={{
              zIndex: 1000 + index,
            }}
          >
            {modal.component}
          </section>
        </div>
      ))}
    </section>
  );
};
