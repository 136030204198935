import { type IRoomMember } from "@/api/client";
import { InviteMemberComponent } from "@/components/shared/members/InviteMemberComponent";
import { useInviteMember } from "@/hooks/queries/shared/useInviteMember";
import { useTranslation } from "react-i18next";
export const InviteChannelMemberComponent = ({
  email,
  isInvited = false,
  member,
  channelId,
  conversationId,
}: {
  email: string;
  member: IRoomMember;
  isInvited?: boolean;
  channelId: string;
  conversationId?: string;
}) => {
  const { inviteMemberToChannelMutation } = useInviteMember();
  const { t } = useTranslation();
  return (
    <InviteMemberComponent
      isInvited={isInvited}
      member={member}
      email={email}
      inviteAction={() => {
        inviteMemberToChannelMutation.mutate({ roomMemberId: member.id, channelId, conversationId });
      }}
      inviteLabel={t("members.add")}
    ></InviteMemberComponent>
  );
};
