import { type IOrganisationMember } from "@/api/client";
import { InviteMemberComponent } from "@/components/shared/members/InviteMemberComponent";
import { useInviteMember } from "@/hooks/queries/shared/useInviteMember";
export const InviteOrganisationMemberComponent = ({
  email,
  isInvited,
  member,
  isInBulk,
}: {
  email: string;
  member?: IOrganisationMember;
  isInvited?: boolean;
  isInBulk?: boolean;
}) => {
  const { inviteMemberToOrganisationMutation } = useInviteMember();
  return (
    <InviteMemberComponent
      isInvited={isInvited}
      member={member}
      email={email}
      inviteAction={() => {
        inviteMemberToOrganisationMutation.mutate({ email });
      }}
      isInBulk={isInBulk}
    ></InviteMemberComponent>
  );
};
