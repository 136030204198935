import { type IChannelConversation, type IConversationMember } from "@/api/client";
import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { type IIsTypingPayload } from "@/interfaces/isTyping";
import { useCallback, useContext } from "react";

export const useSendIsTyping = (contextType: string, conversation?: IChannelConversation, conversationMember?: IConversationMember) => {
  const socket = useContext(SocketContext);
  const sendIsTyping = useCallback(() => {
    if (socket == null || contextType !== "conversation" || conversation == null || conversationMember == null) return;

    const payload: IIsTypingPayload = {
      conversationId: conversation.id,
      conversationMember: {
        memberId: conversationMember.id,
        memberFullName: `${conversationMember.user.firstName ?? ""} ${conversationMember.user.lastName ?? ""}`,
      },
    };

    socket.emit(SocketEvents.USER_IS_TYPING, payload);
  }, [[contextType, conversation, conversationMember]]);

  return { sendIsTyping };
};
