import { eventNames } from "@/constants/eventNames";
import { useSearchParams } from "react-router-dom";

export const useModal = () => {
  const [searchParams] = useSearchParams();
  const openModal = (name: string, component: JSX.Element, shouldCloseOthers = false, backdropOpacity = 0.5): void => {
    // emit custom event openModal
    window.dispatchEvent(
      new CustomEvent(eventNames.OPEN_MODAL, {
        detail: {
          name,
          modalComponent: component,
          backdropOpacity,
          shouldCloseOthers,
        },
      }),
    );
  };

  const closeModal = (name: string): void => {
    window.dispatchEvent(
      new CustomEvent(eventNames.CLOSE_MODAL, {
        detail: {
          name,
        },
      }),
    );
  };

  const getModalURIParams = (): { modal: string | null; tab: string | null } => {
    const modal = searchParams.get("modal");
    const tab = searchParams.get("tab");
    return {
      modal,
      tab,
    };
  };

  return { openModal, closeModal, getModalURIParams };
};
