import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useRoomNotificationSettingsQuery = (roomId: string) => {
  return useQuery({
    queryKey: [QueryKeys.ROOMS, QueryKeys.NOTIFICATION_SETTINGS, roomId],
    queryFn: async () => {
      if (roomId === "") return null;
      const { data } = await api.my.getNotificationsSettingsByRoom(roomId);

      return data;
    },
    retry: 1,
    staleTime: 12 * 60 * 60 * 1000,
    enabled: roomId != null || roomId !== "",
  });
};
