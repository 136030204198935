import { type IMessage } from "@/api/client";
import { ReactionComponent } from "@/components/roomPage/tabs/conversation/ReactionComponent";
import { type MouseEventHandler } from "react";
import { MdOutlineEmojiEmotions as EmojiIcon } from "react-icons/md";
export const ReactionListComponent = ({
  openEmojiPicker,
  message,
  readOnly = false,
}: {
  openEmojiPicker?: MouseEventHandler;
  message: IMessage;
  readOnly?: boolean;
}) => {
  const reactions = message.reactions ?? {};
  return (
    <section className="mx-1 -mt-1 flex select-none items-center last:mb-1">
      {Object.keys(reactions).map((reaction: string) => (
        <ReactionComponent key={reaction} reaction={reaction} count={reactions[reaction].count} message={message} readOnly={readOnly} />
      ))}
      {!readOnly ? (
        <div
          className="reaction m-0.5 flex h-6 w-9 cursor-pointer items-center rounded-xl border border-gray-300/80 bg-white px-0.5 py-1 transition-all hover:bg-sky-100"
          onClick={openEmojiPicker}
        >
          <EmojiIcon className="ml-1 text-zinc-400" />
          <span className="text-semibold ml-auto mr-[0.3rem] text-2xs text-zinc-400">+</span>
        </div>
      ) : null}
    </section>
  );
};
