import { useNotificationsQuery } from "@/hooks/queries/notifications/useNotificationsQuery";
import { useEffect, useMemo } from "react";

export const AppBadgeComponent = () => {
  const { data: notificationsData } = useNotificationsQuery("UNREAD");
  const unreadNotificationsCount = useMemo(() => notificationsData?.pages.flatMap((page) => page.data).length ?? 0, [notificationsData]);

  useEffect(() => {
    window.electron.send("set-badge-count", unreadNotificationsCount);
  }, [unreadNotificationsCount]);

  return <></>;
};
