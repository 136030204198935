/*
  Returns a reordered list based on a array of ids
  @param {order array} order -  The array of ids with the order..
  @param {originalList array} originalList -  The original list of items to be ordered.
  @param {identifier string} identifier -  The identifier to use when comparing items in the list.
  @returns {array} - The ordered list of items.
 */
export const reorderFromIdsOrder = <T>(order: string[], originalList: T[], idAccessor: (item: T) => string): T[] => {
  const output = [];

  // Build an new array based on the order in localStorage
  for (const id of order) {
    const from = originalList.findIndex((element) => idAccessor(element) === id);
    const to = order.indexOf(id);
    if (from !== -1) output[to] = originalList[from];
  }
  // Add any rooms that are not in the local storage array
  for (const element of originalList) {
    if (!output.includes(element)) output.push(element);
  }

  return output;
};

export const defaultIdAccessor = <T extends { id: string }>(item: T) => item.id;
