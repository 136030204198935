import { type ITag } from "@/api/client";
import { ModalLayoutComponent } from "@/components/shared/modal/ModalLayoutComponent";
import { TagEditModalContent } from "@/components/shared/tagInput/tagEditModal/TagEditModalContent";
import { ModalNames } from "@/constants/modalNames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
interface ITagEditModalProp {
  tag?: ITag;
  taskId?: string;
  roomId: string;
  isCreation?: boolean;
}

export const TagEditModal = ({ tag, taskId, roomId, isCreation = false }: ITagEditModalProp): JSX.Element => {
  const { t } = useTranslation();
  const modalTitle = isCreation ? t("editTaskModal.editTagModal.createTag.title") : t("editTaskModal.editTagModal.updateTag.title");
  const tabs = useMemo(() => {
    return [
      {
        label: modalTitle,
        content: <TagEditModalContent tag={tag} roomId={roomId} taskId={taskId} isCreation={isCreation}></TagEditModalContent>,
        id: "default",
        disabled: false,
      },
    ];
  }, []);

  return <ModalLayoutComponent tabs={tabs} title={modalTitle} size="sm" name={ModalNames.TAG_EDIT}></ModalLayoutComponent>;
};
