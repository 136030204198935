import { type IMyChannelUpdateBody } from "@/api/client";
import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const useEditMyChannelMutation = () => {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async ({ channelId, data }: { channelId: string; data: IMyChannelUpdateBody }) => {
      if (channelId == null) return;
      await api.my.updateChannel(channelId, data, { defaultToastErrorMessage: t("channel.update.error") });
    },
  });
};
