import { type FunctionComponent } from "react";
import { BsCheck as CheckIcon } from "react-icons/bs";
import { LuMail as MailIcon } from "react-icons/lu";

interface EmailItemComponentProps {
  email: string;
  onRemove: () => void;
}

export const EmailItemComponent: FunctionComponent<EmailItemComponentProps> = ({ email, onRemove }) => {
  return (
    <div
      className="flex cursor-pointer flex-row items-center justify-between border-b px-2 py-1 transition-all hover:bg-sky-500 hover:text-white"
      onClick={onRemove}
    >
      <div className="mr-2 flex items-center">
        <MailIcon className="ml-0.5 mr-1.5 h-4 w-4" />

        <div className="truncate text-xs dark:text-dark-300">{email}</div>
      </div>

      <CheckIcon className="h-4 w-4" />
    </div>
  );
};
