import { type IInvitation, type IOrganisationMember, type IQuotaData } from "@/api/client";
import { DowngradeFinishedConfirm } from "@/components/billing/modals/downgrade/DowngradeFinishedConfirm";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { InviteOrganisationMemberComponent } from "@/components/shared/members/InviteOrganisationMemberComponent";
import { OrganisationMemberComponent } from "@/components/shared/members/OrganisationMemberComponent";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { closeModal, openModal } from "@/components/shared/modal/ModalService";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { eventNames } from "@/constants/eventNames";
import { ModalNames } from "@/constants/modalNames";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useBulkRemoveOrganisationInvitesMutation } from "@/hooks/mutations/organisations/invites/useBulkRemoveOrganisationInvitesMutation";
import { useBulkRemoveOrganisationMembersMutation } from "@/hooks/mutations/organisations/members/useBulkRemoveOrganisationMembersMutation";
import { useOrganisationActiveMemberQuery } from "@/hooks/queries/organisations/useOrganisationActiveMemberQuery";
import { useOrganisationInvitesQuery } from "@/hooks/queries/organisations/useOrganisationInvitesQuery";
import { useSearchInput } from "@/hooks/shared/useSearchInput";
import { QuotaType } from "@/interfaces/billing";
import { type IModalAction } from "@/interfaces/modal";
import { filterMembersBySearch } from "@/utils/utilities";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
interface IRemoveUsersTabComponentProps {
  quotas: IQuotaData[];
}
export const RemoveUsersTabComponent = ({ quotas }: IRemoveUsersTabComponentProps) => {
  const { t } = useTranslation();
  const me = useContext(AuthenticationContext);
  const { data: activeMembers } = useOrganisationActiveMemberQuery(me?.mainOrganisation?.organisationId);
  const { data: pendingInvites } = useOrganisationInvitesQuery(me?.mainOrganisation?.organisationId);
  const { mutateAsync: removeMembers, isPending: isMembersMutationLoading } = useBulkRemoveOrganisationMembersMutation();
  const { mutateAsync: removeInvites, isPending: isInvitesMutationLoading } = useBulkRemoveOrganisationInvitesMutation();
  const invites = useMemo(() => pendingInvites?.invitations ?? [], [pendingInvites]);

  const [checkedInviteIds, setCheckedInviteIds] = useState<string[]>([]);
  const [checkedMemberIds, setCheckedMemberIds] = useState<string[]>([]);
  const [filteredMembers, setFilteredMembers] = useState<IOrganisationMember[]>([]);
  const [filteredInvites, setFilteredInvites] = useState<IInvitation[]>([]);
  const { search, updateSearch } = useSearchInput();

  useEffect(() => {
    if (activeMembers == null || pendingInvites == null) return;
    setFilteredMembers(filterMembersBySearch(activeMembers, search));
    setFilteredInvites(invites.filter((invite) => invite.email.includes(search)));
  }, [activeMembers, pendingInvites, search]);

  const memberQuota = useMemo(() => {
    return quotas.find((quota) => quota.quotaType === QuotaType.MEMBER);
  }, [quotas]);

  const howManyShouldBeRemoved = useMemo(() => {
    // @ts-expect-error wrong typing
    return memberQuota?.currentNumber - memberQuota?.limit;
  }, [memberQuota]);

  const howManyAreSelected = useMemo(() => {
    return checkedInviteIds.length + checkedMemberIds.length;
  }, [checkedInviteIds, checkedMemberIds]);

  const action = async () => {
    if (checkedMemberIds.length > 0) {
      await removeMembers({
        organisationId: me?.mainOrganisation?.organisationId ?? "",
        membershipIds: checkedMemberIds,
      });
    }

    if (checkedInviteIds.length > 0) {
      await removeInvites({
        organisationId: me?.mainOrganisation?.organisationId ?? "",
        invitationIds: checkedInviteIds,
      });
    }
    if (quotas.length > 1) {
      const event = new CustomEvent(eventNames.GOTO_NEXT_TAB);
      window.dispatchEvent(event);
    } else {
      closeModal(ModalNames.DOWNGRADE);
      openModal(ModalNames.CONFIRM_DOWNGRADE_FINISHED, <DowngradeFinishedConfirm />);
    }
  };

  const actions: IModalAction[] = useMemo(() => {
    return [
      {
        action,
        label: quotas.length > 1 ? t("general.next") : t("billing.modals.downgrade.tabs.member.action"),
        type: "secondary",
        disabled: howManyAreSelected < howManyShouldBeRemoved,
      },
    ];
  }, [howManyAreSelected, howManyShouldBeRemoved]);

  const handleInvitationClick = (inviteId: string) => {
    if (checkedInviteIds.includes(inviteId)) {
      setCheckedInviteIds(checkedInviteIds.filter((id) => id !== inviteId));
    } else {
      setCheckedInviteIds([...checkedInviteIds, inviteId]);
    }
  };

  const handleMemberClick = (memberId: string) => {
    if (checkedMemberIds.includes(memberId)) {
      setCheckedMemberIds(checkedMemberIds.filter((id) => id !== memberId));
    } else {
      setCheckedMemberIds([...checkedMemberIds, memberId]);
    }
  };

  return (
    <ModalTabComponent
      hasPadding={false}
      footer={
        <ModalFooterComponent
          actions={actions}
          info={t("billing.modals.downgrade.tabs.member.info", {
            howManyAreSelected: Math.min(howManyAreSelected, howManyShouldBeRemoved),
            howManyShouldBeRemoved,
          })}
        ></ModalFooterComponent>
      }
    >
      <>
        {isMembersMutationLoading || isInvitesMutationLoading ? <LoaderComponent /> : null}
        <TextInputComponent
          value={search}
          onChange={(e) => {
            updateSearch(e.target.value);
          }}
          placeholder={t("billing.modals.downgrade.tabs.member.searchPlaceholder")}
          className="p-6 pb-0 pt-4"
        />
        <h3 className="mx-6 mb-1 flex items-center text-sm font-medium text-zinc-500">{t("billing.modals.downgrade.tabs.member.invites")}</h3>
        <div className="w-full">
          {filteredInvites?.map((invite) => (
            <div
              className="relative flex w-full cursor-pointer"
              key={invite.id}
              onClick={() => {
                handleInvitationClick(invite.id);
              }}
            >
              <InviteOrganisationMemberComponent email={invite.email} isInvited />
              <input type="checkbox" className="absolute right-6 top-3.5" checked={checkedInviteIds.includes(invite.id)} onChange={() => null} />
            </div>
          ))}
        </div>
        <h3 className="mx-6 mb-1 mt-4 flex items-center text-sm font-medium text-zinc-500">{t("billing.modals.downgrade.tabs.member.members")}</h3>
        <div>
          {filteredMembers?.map((member) => (
            <div
              className="relative flex w-full cursor-pointer hover:bg-gray-100"
              key={member.id}
              onClick={() => {
                handleMemberClick(member.id);
              }}
            >
              <OrganisationMemberComponent member={member} isDisabled />
              <input type="checkbox" className="absolute right-6 top-3.5" checked={checkedMemberIds.includes(member.id)} onChange={() => null} />
            </div>
          ))}
        </div>
      </>
    </ModalTabComponent>
  );
};
