import { TaskPriorityEnum } from "@/api/client";
import { TaskPriorityItemComponent } from "@/components/shared/tasks/TaskPriorityItemComponent";
import { type FunctionComponent } from "react";

interface ITaskPriorityPopinContentComponentProps {
  priority: TaskPriorityEnum | null;
  onPrioritySelected: (priority: TaskPriorityEnum | null) => void;
}

export const TaskPriorityPopinContentComponent: FunctionComponent<ITaskPriorityPopinContentComponentProps> = ({ onPrioritySelected, priority }) => {
  return (
    <div className="flex cursor-pointer flex-col items-start p-2">
      {priority != null ? (
        <>
          <TaskPriorityItemComponent
            priority="remove"
            onClick={() => {
              onPrioritySelected(null);
            }}
          />
          <div className="h-1" />
        </>
      ) : null}
      <TaskPriorityItemComponent
        priority={TaskPriorityEnum.Low}
        onClick={() => {
          onPrioritySelected(TaskPriorityEnum.Low);
        }}
      />
      <div className="h-1" />
      <TaskPriorityItemComponent
        priority={TaskPriorityEnum.Medium}
        onClick={() => {
          onPrioritySelected(TaskPriorityEnum.Medium);
        }}
      />
      <div className="h-1" />
      <TaskPriorityItemComponent
        priority={TaskPriorityEnum.High}
        onClick={() => {
          onPrioritySelected(TaskPriorityEnum.High);
        }}
      />
    </div>
  );
};
