import { ConfirmModalComponent } from "@/components/shared/modal/ConfirmModalComponent";
import { ModalNames } from "@/constants/modalNames";
import { useTranslation } from "react-i18next";

export const DowngradeFinishedConfirm = () => {
  const { t } = useTranslation();
  return (
    <ConfirmModalComponent
      name={ModalNames.CONFIRM_DOWNGRADE_FINISHED}
      title={t(`billing.modals.downgrade.confirmFinished.title`)}
      description={t(`billing.modals.downgrade.confirmFinished.description`)}
      action={null}
      actionType="primary"
      actionLabel={t(`general.ok`)}
      cancelLabel={t(`general.ok`)}
    />
  );
};
