import { type IEmailThreadAllBody, type IEmailThreadAllResponse } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useThreadsQuery = (params: IEmailThreadAllBody) => {
  return useInfiniteQuery<IEmailThreadAllResponse>({
    queryKey: [QueryKeys.EMAIL_THREADS, params.roomId],
    queryFn: async ({ pageParam = "" }) => {
      const body: IEmailThreadAllBody = {
        ...params,
      };
      if (pageParam !== "") {
        body.cursor = pageParam as string;
      }
      const response = await api.emails.getMyThreads(body, {
        defaultToastErrorMessage: null,
      });
      return response.data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextCursor;
    },
    initialPageParam: "",
    enabled: params.roomId !== "",
    retry: 0,
  });
};
