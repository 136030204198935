import { type DeltaContent, type ITask, type MessageConversationChannel } from "@/api/client";
import { ChannelPickerComponent } from "@/components/shared/channel/ChannelPickerComponent/ChannelPickerComponent";
import { DrawerHeaderComponent } from "@/components/shared/drawer/DrawerHeaderComponent";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { ToggleComponent } from "@/components/shared/form/toggle/ToggleComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { RoundedContainerComponent } from "@/components/shared/layout/RoundedContainerComponent";
import { RichTextEditorComponent } from "@/components/shared/richTextEditor/RichTextEditorComponent";
import { RoomPickerComponent } from "@/components/shared/room/RoomPickerComponent/RoomPickerComponent";
import { TagInputComponent } from "@/components/shared/tagInput/TagInputComponent";
import { TagInputTagComponent } from "@/components/shared/tagInput/TagInputTagComponent";
import { useCreateTaskDrawer } from "@/components/shared/tasks/CreateTaskDrawerComponent/useCreateTaskDrawer";
import { TaskAssigneesComponent } from "@/components/shared/tasks/TaskAssigneesComponent/TaskAssigneesComponent";
import { TaskDeadlineComponent } from "@/components/shared/tasks/TaskDeadlineComponent/TaskDeadlineComponent";
import { TaskPriorityComponent } from "@/components/shared/tasks/TaskPriorityComponent/TaskPriorityComponent";
import { TaskStatusComponent } from "@/components/shared/tasks/TaskStatusComponent/TaskStatusComponent";
import { TaskTodosComponent } from "@/components/shared/tasks/TaskTodosComponent/TaskTodosComponent";
import { TaskTagsContext } from "@/contexts/TaskTagsContextProvider";
import { useTaskStatusesQuery } from "@/hooks/queries/tasks/useTaskStatusesQuery";
import { useContext, useMemo, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { FaLock as LockCloseIcon, FaLockOpen as LockOpenIcon } from "react-icons/fa";

interface ICreateTaskDrawerComponentProps {
  assigneeId?: string;
  taskStatusId?: string;
  roomId?: string;
  defaultTaskTitle?: string;
  defaultTaskDescription?: DeltaContent;
  defaultTaskChannel?: MessageConversationChannel;
  fromMessage?: boolean;
  defaultValues?: Partial<ITask>;
}

export const CreateTaskDrawerComponent: FunctionComponent<ICreateTaskDrawerComponentProps> = ({
  assigneeId: currentAssigneeId,
  roomId: currentRoomId,
  defaultValues,
  fromMessage = false,
}) => {
  const { t } = useTranslation();
  const {
    editorRef,
    onSubmit,
    isValid,
    errors,
    isPending,
    onPriorityChange,
    priority,
    onStatusChange,
    taskStatusId,
    assignedUsers,
    onAssigneeChange,
    onIsPrivateChange,
    isPrivate,
    roomId,
    channel,
    deadline,
    onDeadlineChange,
    onRoomChange,
    onChannelChange,
    onCloseDrawer,
    onDescriptionBlur,
    register,
  } = useCreateTaskDrawer(currentRoomId, fromMessage, defaultValues);

  const { data: taskStatusesData } = useTaskStatusesQuery(roomId);

  const selectedStatus = useMemo(() => {
    if (taskStatusesData == null) return;
    if (taskStatusId == null) return taskStatusesData[0];

    return taskStatusesData?.find((taskStatus) => taskStatus.id === taskStatusId);
  }, [taskStatusesData, taskStatusId]);

  const { tags } = useContext(TaskTagsContext);

  return (
    <RoundedContainerComponent className="h-full bg-dark-25 dark:bg-dark-800" hasPadding={false}>
      <section className="h-full overflow-auto">
        <DrawerHeaderComponent title={t("createTaskModal.title")} onCloseDrawer={onCloseDrawer} />
        <form className="flex h-full flex-col items-end justify-start px-6 py-4" onSubmit={onSubmit}>
          <div className="flex w-full flex-col flex-wrap items-start justify-between">
            <div className="mb-4 w-full flex-1">
              <TextInputComponent
                register={register}
                name="title"
                type="text"
                label={t("createTaskModal.fields.title.label")}
                error={errors.title?.message}
                required
                multiline
                maxLength={90}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className="mb-1 flex w-full items-center text-sm font-normal text-zinc-500 dark:text-dark-300">
              {t("createTaskModal.fields.description.label")}
            </div>
            <div className="mb-4 w-full">
              <RichTextEditorComponent
                ref={editorRef}
                contextType="taskDescription"
                placeholder={t("createTaskModal.fields.description.placeholder")}
                contextId={roomId ?? "task-home"}
                onBlur={onDescriptionBlur}
              />
            </div>
            <div className="mb-1 flex w-full items-center text-sm font-normal text-zinc-500 dark:text-dark-300">
              {t("createTaskModal.fields.room.label")}
            </div>
            <div className="mb-4 flex w-full">
              <RoomPickerComponent
                size="lg"
                className="w-full rounded-md border border-zinc-200 bg-zinc-100 px-3 py-1.5"
                room={roomId != null ? { id: roomId } : null}
                onRoomChange={onRoomChange}
              />
            </div>
            <div className="mb-1 flex w-full items-center text-sm font-normal text-zinc-500 dark:text-dark-300">
              {t("createTaskModal.fields.channel.label")}
            </div>
            <div className="mb-4 flex w-full">
              <ChannelPickerComponent
                size="lg"
                className="w-full rounded-md border border-zinc-200 bg-zinc-100 px-3 py-1.5"
                room={roomId != null ? { id: roomId } : null}
                channel={channel}
                onChannelChange={onChannelChange}
              />
            </div>
            <div className="mb-2 h-px w-full bg-zinc-100" />
            <TaskTodosComponent taskId={null} />
            <div className="mb-4 flex w-full flex-row">
              <TaskStatusComponent onStatusChange={onStatusChange} status={selectedStatus} roomId={roomId} />
              <div className="w-4" />
              <TaskPriorityComponent onPriorityChange={onPriorityChange} priority={priority} displayNoPriority />
              <div className="w-4" />
              <TaskDeadlineComponent
                className="rounded-md bg-zinc-200 px-3"
                deadline={deadline}
                onDeadlineChange={onDeadlineChange}
                displayNoDeadline
              />
            </div>

            <div className="flex flex-wrap gap-1">
              <div className="mb-1 flex w-full items-center text-sm font-normal text-zinc-500 dark:text-dark-300">
                {t("editTaskModal.fields.labels.label")}
              </div>
              {tags?.map((tag) => <TagInputTagComponent roomId={roomId ?? ""} key={tag.id} tag={tag} />)}
              <TagInputComponent placeholder={t("editTaskModal.fields.labels.placeholder")} roomId={roomId ?? ""} />
            </div>

            <div className="mb-4 h-px w-full  bg-zinc-100" />
            <div className="mb-4 flex w-full">
              <TaskAssigneesComponent
                size="lg"
                assignedUsers={assignedUsers}
                onUserChange={onAssigneeChange}
                taskId={null}
                roomId={roomId}
                channelId={channel?.id ?? null}
                isDisabled={roomId == null}
              />
            </div>
            {isPending ? <LoaderComponent className="-ml-6" /> : null}
          </div>
          <div className="mb-4 flex w-full items-center justify-start">
            <label className="relative flex w-full cursor-pointer select-none items-start justify-start text-sm text-zinc-500">
              <ToggleComponent
                isEnabled={isPrivate}
                onToggle={(e) => {
                  onIsPrivateChange(e);
                }}
                size="sm"
                activeIcon={<LockCloseIcon className="h-2 w-2" />}
                inactiveIcon={<LockOpenIcon className="h-2 w-2" />}
              />
              <span className="absolute left-8 ml-2 text-xs text-zinc-400">
                {t(`createTaskModal.fields.private.label.${isPrivate ? "private" : "public"}`)}
              </span>
            </label>
          </div>
          <div className="pb-4">
            <ButtonComponent status="primary" type="submit" disabled={!isValid}>
              {t("createTaskModal.submitButton")}
            </ButtonComponent>
          </div>
          {roomId == null ? <div className="my-4 flex w-full flex-row text-xs text-zinc-400">{t("createTaskModal.bottomInfos")}</div> : null}
        </form>
      </section>
    </RoundedContainerComponent>
  );
};
