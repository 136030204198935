interface IMessageSeparatorComponentProps {
  label: string;
  position?: "left" | "center" | "right";
  textColor?: string;
  lineColor?: string;
}
export const MessageSeparatorComponent = ({
  label,
  position = "center",
  textColor = "#4B5563",
  lineColor = "#E4E4E7",
}: IMessageSeparatorComponentProps): JSX.Element => {
  return (
    <section className="pointer-events-none flex h-8 w-full shrink-0 items-center overflow-hidden pt-1.5 group-last/message:pt-2">
      {["right", "center"].includes(position) && (
        <div
          className="h-[1px] w-full"
          style={{
            backgroundColor: lineColor,
          }}
        ></div>
      )}
      <label
        className={`shrink-0 text-xs ${position === "center" ? "mx-4" : position === "left" ? "mr-4" : "ml-4"}`}
        style={{
          color: textColor,
        }}
      >
        {label}
      </label>
      {["left", "center"].includes(position) && (
        <div
          className="h-[1px] w-full"
          style={{
            backgroundColor: lineColor,
          }}
        ></div>
      )}
    </section>
  );
};
