import { EmailConfirm } from "@/components/authentication/EmailConfirm";
import { ForgetPasswordForm } from "@/components/authentication/ForgetPasswordForm";
import { ResetPasswordForm } from "@/components/authentication/ResetPasswordForm";
import { SignInForm } from "@/components/authentication/SignInForm";
import { SignUpFormComponent } from "@/components/authentication/signUp/SignUpFormComponent";
import { SignUpComplete } from "@/components/authentication/SignUpComplete";
import { SignUpError } from "@/components/authentication/SignUpError";
import { DownloadAppComponent } from "@/components/downloadApp/DownloadAppComponent";
import { CalendarTab } from "@/components/homePage/tabs/Calendar/CalendarTab";
import { DmsTabsComponent } from "@/components/homePage/tabs/dmsTab/DmsTabComponent";
import { FilesTab } from "@/components/homePage/tabs/Files/FilesTab";
import { RoomListTab } from "@/components/homePage/tabs/rooms/RoomListTab";
import { TasksTab } from "@/components/homePage/tabs/Tasks/TasksTab";
import { ChannelConversationComponent } from "@/components/roomPage/tabs/conversation/ChannelConversationComponent";
import { DefaultChannelConversationComponent } from "@/components/roomPage/tabs/conversation/DefaultChannelConversation";
import { DmConversationComponent } from "@/components/roomPage/tabs/conversation/DmConversationComponent";
import { EmailsTabComponent } from "@/components/roomPage/tabs/emails/EmailsTabComponent";
import { FilesTabComponent } from "@/components/roomPage/tabs/files/FilesTabComponent";
import { TasksTabComponent } from "@/components/roomPage/tabs/tasks/TasksTabComponent";
import { EmailConnectionContextProvider } from "@/contexts/EmailConnectionContextProvider";
import { LoggedInLayout } from "@/layouts/LoggedInLayout";
import { LoggedOutLayout } from "@/layouts/LoggedOutLayout";
import { AuthenticationPage } from "@/pages/AuthenticationPage";
import { CheatsPage } from "@/pages/CheatsPage";
import { GoogleRedirectPage } from "@/pages/GoogleRedirectPage";
import { GoogleSigninPage } from "@/pages/GoogleSigninPage";
import { HomePage } from "@/pages/HomePage";
import { RoomPage } from "@/pages/RoomPage";
import { createBrowserRouter, createHashRouter, Navigate, type RouteObject } from "react-router-dom";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <LoggedOutLayout />,
    children: [
      {
        path: "google/signin",
        element: <GoogleSigninPage />,
      },
      {
        path: "google/redirect",
        element: <GoogleRedirectPage />,
      },
      {
        path: "download/",
        element: <DownloadAppComponent />,
      },
      {
        element: <AuthenticationPage />,
        children: [
          {
            path: "signin",
            element: <SignInForm />,
          },
          {
            path: "password/forgot",
            element: <ForgetPasswordForm />,
          },
          {
            path: "password/reset/:token",
            element: <ResetPasswordForm />,
          },
          {
            path: "register/email/confirm/:token",
            element: <EmailConfirm />,
          },
          {
            path: "register",
            element: <SignUpFormComponent />,
            children: [
              {
                path: ":token",
              },
            ],
          },
          {
            path: "register/complete",
            element: <SignUpComplete />,
            children: [
              {
                path: "pending",
              },
              {
                path: ":email",
              },
            ],
          },
          {
            path: "register/error",
            element: <SignUpError />,
            children: [
              {
                path: ":organisationName/:error",
              },
            ],
          },
          {
            path: "",
            element: <Navigate to="/signin" replace />,
          },
        ],
      },
      {
        path: "",
        element: <AuthenticationPage />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <LoggedInLayout />,
    children: [
      {
        path: "",
        element: <HomePage />,
        children: [
          {
            path: "cheats",
            element: <CheatsPage />,
          },
          {
            path: "",
            element: <Navigate to="/dashboard/rooms" replace />,
          },
          {
            index: true,
            path: "rooms",
            element: <RoomListTab />,
          },
          {
            path: "files",
            element: <FilesTab />,
          },
          {
            path: "calendar",
            element: <CalendarTab />,
          },
          {
            path: "tasks",
            element: <TasksTab />,
          },
          {
            path: "dms",
            element: <DmsTabsComponent />,
            children: [
              {
                path: ":conversationId",
                element: <DmConversationComponent />,
                children: [
                  {
                    path: "thread/:parentMessageId",
                    element: <DmConversationComponent />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "rooms/:id",
        element: <RoomPage />,
        children: [
          {
            path: "files",
            element: <FilesTabComponent />,
          },
          {
            path: "tasks",
            element: <TasksTabComponent />,
            children: [
              {
                path: ":taskId",
                element: <TasksTabComponent />,
              },
            ],
          },
          {
            path: "emails",
            element: (
              <EmailConnectionContextProvider>
                <EmailsTabComponent />
              </EmailConnectionContextProvider>
            ),
            children: [
              {
                path: ":threadId",
                element: <EmailsTabComponent />,
                children: [
                  {
                    path: "email/:emailId",
                    element: <EmailsTabComponent />,
                  },
                ],
              },
            ],
          },
          {
            path: "channel/:channelId",
            element: <ChannelConversationComponent />,
            children: [
              {
                path: "thread/:parentMessageId",
                element: <ChannelConversationComponent />,
              },
            ],
          },
          {
            path: "dm/:conversationId",
            element: <DmConversationComponent />,
            children: [
              {
                path: "thread/:parentMessageId",
                element: <DmConversationComponent />,
              },
            ],
          },
          {
            path: "",
            element: <DefaultChannelConversationComponent />,
          },
        ],
      },
    ],
  },
];

export const router = window.IS_ELECTRON ? createHashRouter(routes) : createBrowserRouter(routes);
