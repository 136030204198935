import { type DeltaContent, type DeltaOp, type IEmail, type INotification } from "@/api/client";
import { SystemMessageSubtypesEnum } from "@/constants/messages";
import { TASK_NOTIFICATION_TYPES, notificationType } from "@/constants/notification";
import { useNotificationLocation } from "@/hooks/shared/notifications/useNotificationLocation";
import emojisData from "@emoji-mart/data/sets/14/apple.json";
import { useTranslation } from "react-i18next";
type EmojiData = Record<
  string,
  {
    skins: Array<{
      native: string;
    }>;
  }
>;
export const useFormatNotificationContent = () => {
  const { t } = useTranslation();
  const getLocationFromNotification = useNotificationLocation();
  const isListItem = (op: DeltaOp): boolean => {
    return op.attributes?.list === "bullet" || op.attributes?.list === "ordered";
  };

  const formatMention = (op: DeltaOp): string | undefined => {
    if (typeof op.insert === "object" && "mention" in op.insert) {
      return op.insert.mention.value != null ? `@${op.insert.mention.value}` : undefined;
    }
  };

  const formatEmoji = (op: DeltaOp): string | undefined => {
    if (typeof op.insert === "object" && "emoji" in op.insert) {
      const emojiInsert = op.insert;
      /* @ts-expect-error emoji is badly typed */
      return emojiInsert.emoji.native;
    }
  };

  const deltaToString = (content: DeltaContent | undefined): string => {
    const formattedContentParts = content?.ops.map((op) => {
      if (typeof op.insert === "string") {
        if (isListItem(op) || op.insert === "\n") {
          return " ";
        }
        return op.insert;
      }

      if (typeof op.insert === "object") {
        const formattedMention = formatMention(op);
        if (formattedMention != null) return formattedMention;

        const formattedEmoji = formatEmoji(op);
        if (formattedEmoji != null) return formattedEmoji;
      }
      return "";
    });

    return formattedContentParts?.join("").substring(0, 200).replace(/\n$/, "") ?? "";
  };

  const getEmojiByShortName = (shortName: string | null) => {
    if (shortName == null) return "⚠️";
    const parts = shortName.slice(1, -1).split("::");

    const baseEmoji = parts[0];
    const skinTone = parts[1] != null ? Number(parts[1].charAt(parts[1].length - 1)) - 1 : 0;

    const emojis = emojisData.emojis as EmojiData;
    return emojis[baseEmoji]?.skins[skinTone]?.native ?? shortName;
  };

  const formatTaskNotificationContent = (notification: INotification): string => {
    if (notification.task == null) return "";
    return `${notification.task.taskStatus.name} · ${notification.task.title}`;
  };

  const formatReactionNotificationContent = (notification: INotification): string => {
    if (notification.reaction == null) return "";
    return `${getEmojiByShortName(notification.reaction.content)} ${t("notifications.yourMessage")} ${deltaToString(notification.message?.content)}`;
  };

  const formatNotificationContent = (notification: INotification, email?: IEmail): string => {
    if (notification == null) return "";
    let content: string;
    if (TASK_NOTIFICATION_TYPES.includes(notification.type)) {
      content = formatTaskNotificationContent(notification);
    } else if (notification.type === notificationType.REACTION && notification.reaction?.content != null) {
      content = formatReactionNotificationContent(notification);
    } else {
      if (notification.message?.systemData?.subtype === SystemMessageSubtypesEnum.NEW_VIDEOCONFERENCE) {
        return `${t("notifications.new-videoconference")} ${t("systemMessages.newVideoconference.button.label")}`;
      } else if (email != null) {
        content = email.subject ?? "";
      } else {
        content = deltaToString(notification.message?.content);
      }
    }
    return `${formatNotificationTitle(notification, email)}\n${content}`;
  };

  const formatNotificationTitle = (notification: INotification, email?: IEmail): string => {
    const typeString: string = t(`notifications.${notification.type}`);
    const fromString: string = getLocationFromNotification(notification) ?? "";
    const sender = notification.sender?.user ?? notification.sentByUser;
    const emailSender = email?.from;

    const emailSenderString: string = `${emailSender?.name ?? emailSender?.email ?? ""}`;
    const notificationSenderString: string = `${sender?.firstName ?? ""} ${sender?.lastName ?? ""}`;

    const senderString = sender != null ? notificationSenderString : emailSenderString;

    return `${senderString}\n${typeString} ${t("notifications.in")} ${fromString}`;
  };

  return { formatNotificationContent };
};
