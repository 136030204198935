import { SocketEvents } from "@/constants/socketEvents";
import { EmailThreadContext } from "@/contexts/EmailThreadContextProvider";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { useEmailsQuery } from "@/hooks/queries/emails/useEmailsQuery";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { type VirtuosoHandle } from "react-virtuoso";

export const useEmailList = () => {
  const { thread } = useContext(EmailThreadContext);
  const { data: emails } = useEmailsQuery(thread?.id);
  const { emailId: emailIdParam } = useParams();
  const [isPopinVisible, setIsPopinVisible] = useState(false);
  const emailIndex = useMemo(() => {
    if (emails == null || emailIdParam == null) return 0;
    return emails.findIndex((email) => email.id === emailIdParam);
  }, [emails, emailIdParam]);
  const initialTopMostItemIndex = useMemo(() => emailIndex ?? (emails != null ? emails.length - 1 : 0), [emails]);
  const socket = useContext(SocketContext);
  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const handleEnterThread = useCallback(() => {
    if (socket == null || thread == null) return;
    socket.emit(SocketEvents.ENTER_CONTEXT, { emailThreadId: thread.id });
  }, [socket, thread]);

  const handleLeaveThread = useCallback(() => {
    if (socket == null || thread == null) return;
    socket.emit(SocketEvents.LEAVE_CONTEXT, { emailThreadId: thread.id });
  }, [socket, thread]);

  useEffect(() => {
    if (emailIdParam == null || virtuosoRef?.current == null) return;
    virtuosoRef.current?.scrollToIndex({
      index: emailIndex,
      behavior: "auto",
    });
  }, [emailIdParam, virtuosoRef, emailIndex]);

  useEffect(() => {
    handleEnterThread();
    window.addEventListener("blur", handleLeaveThread);
    window.addEventListener("focus", handleEnterThread);

    return () => {
      handleLeaveThread();
      window.removeEventListener("blur", handleLeaveThread);
      window.removeEventListener("focus", handleEnterThread);
    };
  }, [true, socket, thread?.id]);

  return {
    emails,
    thread,
    initialTopMostItemIndex,
    isPopinVisible,
    setIsPopinVisible,
    emailIdParam,
    virtuosoRef,
  };
};
