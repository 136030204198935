import { InputLabelComponent } from "@/components/shared/form/inputLabel/InputLabelComponent";
import { PasswordPopoverComponent } from "@/components/shared/form/textInput/PasswordPopoverComponent";
import "@/components/shared/form/textInput/TextInput.scss";
import { type ITextInputProps } from "@/interfaces/textInput";
import {
  forwardRef,
  useCallback,
  useState,
  type FocusEventHandler,
  type ForwardedRef,
  type InputHTMLAttributes,
  type KeyboardEventHandler,
} from "react";
import { Controller } from "react-hook-form";
import { BiHide as HidePasswordIcon, BiShow as ShowPasswordIcon } from "react-icons/bi";
import PhoneInput from "react-phone-input-2";
import TextareaAutosize from "react-textarea-autosize";

// @ts-expect-error workaround for Phone input not working in minified react. Remove if the package is updated
const ReactPhoneInput = PhoneInput.default ?? PhoneInput;
// end of workaround

export const TextInputComponent = forwardRef(
  <U extends HTMLInputElement>(
    {
      control,
      register,
      label,
      error,
      icon,
      iconPosition,
      info,
      placeholder,
      requiredText = "(required)",
      variant = "default",
      inputSize = "md",
      className = "",
      shouldShowInfo = true,
      prefix,
      lowercase = false,
      multiline: defaultMultiline,
      hasPasswordPopoverOffset,
      inputClassName = "",
      ...props // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }: ITextInputProps<any>,
    ref: ForwardedRef<U>,
  ): JSX.Element => {
    const multiline: boolean = defaultMultiline ?? false;
    const isError = error != null && error !== "" && error !== false;
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [valueState, setValueState] = useState<string>("");
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    // On focus and on blur to show/hide placeholder in the search variant
    // the placeholder is a custom one and not the native one
    // because of the icon and the centered text

    const onFocus: FocusEventHandler<HTMLInputElement> = useCallback((event) => {
      setIsFocused(true);
      if (props.onFocus != null) {
        props.onFocus(event);
      }
    }, []);

    const onBlur: FocusEventHandler<HTMLInputElement> = useCallback((event) => {
      setIsFocused(false);
      if (props.onBlur != null) {
        props.onBlur(event);
      }
    }, []);

    const onKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
      // @ts-expect-error check why we need that line, type says value does not exist
      setValueState(event.target.value);
      if (props.onKeyUp != null) {
        props.onKeyUp(event);
      }
    }, []);

    // Every classes that are added to the input for all the variants

    // Classes for the variants
    const variantClasses: Record<string, string> = {
      globalSearch:
        "bg-white bg-opacity-50 rounded-md text-black text-xs dark:bg-dark-700 dark:text-dark-300 h-[28px] placeholder-black placeholder-opacity-50 outline-0 focus:bg-opacity-60 transition-all",
      default:
        "border border-dark-100 bg-gray-50 dark:bg-dark-800 dark:border-dark-700 dark:text-dark-300 h-full placeholder-zinc-500 dark:placeholder-dark-500",
    };
    // Classes for the size
    const sizeClasses: Record<string, string> = {
      sm: "py-1 px-2 text-sm",
      md: "px-3 py-2",
      lg: "px-4 py-3",
    };

    // Classes for the icon size according to the size
    const iconClasses: Record<string, string> = {
      sm: "px-1.5 py-1.5",
      md: "px-2 py-2.5",
      lg: "px-2 py-2.5",
    };

    // Classes for the start of the input according to size and icon
    const startClasses: Record<string, string> = {
      sm: iconPosition === "right" ? "pr-6" : "pl-6",
      md: iconPosition === "right" ? "pr-8" : "pl-8",
      lg: iconPosition === "right" ? "pr-10" : "pl-10",
    };

    const registerProps = register != null && props.name != null ? register(props.name) : null;

    const styleClasses = `${variantClasses[variant]} ${sizeClasses[inputSize]}`;
    const maxLength = props.maxLength ?? 0;

    const hasPasswordPopover = props.type === "password" && shouldShowInfo && !["passwordConfirmation", "oldPassword"].includes(props.name ?? "");

    return (
      <>
        <div
          className={`roger-input relative mb-5 flex w-full flex-col last:mb-0 ${props.disabled === true ? "opacity-50" : ""}
          ${props.type === "hidden" ? "hidden" : ""}
          ${className ?? ""}
          `}
        >
          {label !== undefined && (
            <span className="flex w-full select-none items-center justify-between">
              <InputLabelComponent
                label={label}
                disabled={props.disabled}
                name={props.name}
                icon={iconPosition === "label" ? icon : undefined}
                info={info}
                required={props.required}
                requiredText={requiredText}
              />
              {hasPasswordPopover ? (
                <PasswordPopoverComponent
                  password={valueState}
                  isInputFocused={isFocused}
                  isError={isError}
                  hasOffset={hasPasswordPopoverOffset}
                ></PasswordPopoverComponent>
              ) : null}
            </span>
          )}
          <div className="relative h-full">
            {/* Phone input */}
            {props.type === "tel" ? (
              <Controller
                control={control}
                name={props.name ?? ""}
                render={({ field }) => {
                  return (
                    <ReactPhoneInput
                      country="fr"
                      id={props.name}
                      {...props}
                      countryCodeEditable={false}
                      dropdownStyle={{
                        userSelect: "none",
                        maxHeight: "100px",
                      }}
                      inputStyle={{
                        height: "37px",
                      }}
                      {...(registerProps ?? {})}
                      {...field}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      onKeyUp={onKeyUp}
                    />
                  );
                }}
              />
            ) : multiline ? (
              <TextareaAutosize
                // @ts-expect-error typed for HTMLInputElement, cut component to prevent that
                ref={ref}
                rows={1}
                placeholder={placeholder}
                className={`
                w-full rounded-md placeholder:text-zinc-500 dark:placeholder:text-dark-500
                ${styleClasses}
                ${isError ? "border-red-500" : ""}
                ${maxLength > 0 ? "pr-10" : ""}
                ${prefix != null ? "pl-6" : ""}
                ${lowercase ? "lowercase" : ""}
                ${icon != null && (iconPosition === "left" || iconPosition === "right") ? startClasses[inputSize] : ""}
                ${props.disabled === true ? "opacity-50" : ""}
                resize-none
                ${inputClassName}
              `}
                id={props.name}
                {...props}
                {...(registerProps ?? {})}
                type={isPasswordVisible && props.type === "password" ? "text" : props.type}
                // @ts-expect-error typed for HTMLInputElement, cut component to prevent that
                onBlur={onBlur}
                // @ts-expect-error typed for HTMLInputElement, cut component to prevent that
                onFocus={onFocus}
                // @ts-expect-error typed for HTMLInputElement, cut component to prevent that
                onKeyUp={onKeyUp}
                minRows={1}
                maxRows={4}
                onChange={(event) => {
                  if (maxLength != null && event.target.value.length > maxLength) {
                    event.preventDefault();
                    event.stopPropagation();
                    return;
                  }
                  void registerProps?.onChange?.(event);
                  // @ts-expect-error typed for HTMLInputElement, cut component to prevent that
                  props.onChange?.(event);
                }}
              />
            ) : (
              <input
                ref={ref as ForwardedRef<HTMLInputElement>}
                placeholder={placeholder}
                className={`
                w-full rounded-md
                ${styleClasses}
                ${isError ? "border-red-500" : ""}
                ${maxLength > 0 ? "pr-10" : ""}
                ${prefix != null ? "pl-6" : ""}
                ${lowercase ? "lowercase" : ""}
                ${icon != null && (iconPosition === "left" || iconPosition === "right") ? startClasses[inputSize] : ""}
                ${props.disabled === true ? "opacity-50" : ""}
                ${inputClassName}
                        
              `}
                id={props.name}
                {...props}
                {...registerProps}
                type={isPasswordVisible && props.type === "password" ? "text" : (props.type as InputHTMLAttributes<HTMLInputElement>["type"])}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyUp={onKeyUp}
              />
            )}

            {/* MaxLength indicator */}
            {maxLength > 0 && (
              <div className="absolute right-1 top-0 flex h-full items-center px-2 text-xs font-bold text-gray-400">
                <span>{maxLength - (valueState.length ?? 0)}</span>
              </div>
            )}

            {/* Prefix (i.e. '#" for the create channel input) */}
            {prefix != null && (
              <div className="absolute left-1 top-px flex h-full items-center px-2 text-zinc-500">
                <span>{prefix}</span>
              </div>
            )}

            {/* Password Indicator */}
            {props.type === "password" && (
              <div
                className="absolute right-1 top-px flex h-full items-center px-2 text-xs font-bold text-gray-400"
                onClick={() => {
                  setIsPasswordVisible(!isPasswordVisible);
                }}
              >
                {!isPasswordVisible ? (
                  <ShowPasswordIcon className="h-4 w-4 cursor-pointer" />
                ) : (
                  <HidePasswordIcon className="h-4 w-4 cursor-pointer" />
                )}
              </div>
            )}

            {/* Icon management */}
            {icon !== undefined && (iconPosition === "left" || iconPosition === "right") && (
              <i
                className={`absolute top-0 ${iconPosition === "left" ? "left-0" : "right-0"} flex h-7 w-7 items-center text-xs text-gray-400 ${
                  iconClasses[inputSize]
                }`}
              >
                {icon}
              </i>
            )}
          </div>
          {/* Errors */}
          {isError && !hasPasswordPopover && typeof error === "string" && !error.includes("required") && !isFocused && (
            <div className="error absolute top-[4.2rem] select-none text-xs text-red-400">{error}</div>
          )}
        </div>
      </>
    );
  },
);

TextInputComponent.displayName = "TextInputComponent";
