import { type IMessage, type IMyPinsResponse, type IPin } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { useQueryClient, type InfiniteData } from "@tanstack/react-query";
export const useEditPinsQueries = () => {
  const queryClient = useQueryClient();
  const queryKey = [QueryKeys.ME, QueryKeys.PINS];

  const addPinToQuery = (message: IMessage) => {
    queryClient.setQueriesData<InfiniteData<IMyPinsResponse>>({ queryKey }, (oldPins) => {
      if (oldPins == null) return oldPins;
      const newPages = oldPins.pages.map((page: IMyPinsResponse, index: number) => {
        if (index === 0) {
          const createdAt = new Date().toISOString();
          const id = `OPTIMISTIC_ID_${message.id}`;
          return { ...page, data: [...page.data, { id, createdAt, message }] };
        }
        return page;
      });
      return { ...oldPins, pages: newPages };
    });
  };

  const replacePinIdInQuery = (pin: IPin) => {
    queryClient.setQueriesData<InfiniteData<IMyPinsResponse>>({ queryKey }, (oldPins) => {
      if (oldPins == null) return oldPins;
      const newPages = oldPins.pages.map((page: IMyPinsResponse) => {
        const newPage = { ...page };
        newPage.data = page.data.map((pinItem: IPin) => {
          if (pinItem.message?.id === pin.id) {
            return pin;
          }
          return pinItem;
        });
        return newPage;
      });
      return { ...oldPins, pages: newPages };
    });
  };

  const deletePinInQuery = (id: string): void => {
    queryClient.setQueriesData<InfiniteData<IMyPinsResponse>>({ queryKey }, (oldPins) => {
      if (oldPins == null) return oldPins;
      const newPages = oldPins.pages.map((page: IMyPinsResponse) => {
        return { ...page, data: page.data.filter((pinItem: IPin) => pinItem.message?.id !== id) };
      });
      return { ...oldPins, pages: newPages };
    });
  };

  return {
    addPinToQuery,
    deletePinInQuery,
    replacePinIdInQuery,
  };
};
