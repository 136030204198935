import { type ITaskStatus } from "@/api/client";
import { ConfirmModalComponent } from "@/components/shared/modal/ConfirmModalComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { eventNames } from "@/constants/eventNames";
import { ModalNames } from "@/constants/modalNames";
import { RoomContext } from "@/contexts/RoomContext";
import { useDeleteTaskStatusMutation } from "@/hooks/mutations/tasks/useDeleteTaskStatusMutation";
import { useRenameTaskStatusMutation } from "@/hooks/mutations/tasks/useRenameTaskStatusMutation";
import { dispatchCustomWindowEvent } from "@/utils/utilities";
import { useCallback, useContext, useMemo, useState, type ChangeEvent, type KeyboardEventHandler } from "react";
import { useTranslation } from "react-i18next";

export const useTaskStatusColumnPopinContent = (status: ITaskStatus) => {
  const { t } = useTranslation();
  const roomContext = useContext(RoomContext);

  const [isRenaming, setIsRenaming] = useState(false);
  const [name, setName] = useState(status.name);

  const { mutate: deleteTaskStatus } = useDeleteTaskStatusMutation();
  const { mutate: renameTaskStatus } = useRenameTaskStatusMutation();

  const params = useMemo(() => {
    if (roomContext?.roomResponse?.room.id == null) return null;
    return {
      roomId: roomContext.roomResponse.room.id,
      taskStatusId: status.id,
    };
  }, [roomContext?.roomResponse?.room.id, status.id]);

  const handleRenameClick = useCallback(() => {
    setIsRenaming(true);
  }, []);

  const handleCancelRename = useCallback(() => {
    setIsRenaming(false);
  }, []);

  const handleRenameConfirm = useCallback(() => {
    if (params == null) return;
    renameTaskStatus({ ...params, name });
    dispatchCustomWindowEvent(eventNames.CLOSE_TASK_STATUS_POPIN);
    setIsRenaming(false);
  }, [params, name]);

  const handleRenameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    [setName],
  );

  const deleteAction = useCallback(async () => {
    if (params == null) return;
    deleteTaskStatus(params);

    dispatchCustomWindowEvent(eventNames.CLOSE_TASK_STATUS_POPIN);
  }, [params]);

  const handleDeleteClick = useCallback(() => {
    openModal(
      ModalNames.CONFIRM_MESSAGE_DELETION,
      <ConfirmModalComponent
        name={ModalNames.CONFIRM_MESSAGE_DELETION}
        title={t("tasks.column.delete.confirm.title")}
        description={t("tasks.column.delete.confirm.description")}
        action={deleteAction}
        actionType="danger"
        actionLabel={t("tasks.column.delete.confirm.action")}
      />,
    );
  }, []);

  const handleRenameKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === "Enter") {
        handleRenameConfirm();
      } else if (event.key === "Escape") {
        handleCancelRename();
      }
    },
    [name],
  );

  return {
    isRenaming,
    name,
    handleRenameClick,
    handleCancelRename,
    handleRenameConfirm,
    handleRenameChange,
    handleDeleteClick,
    params,
    handleRenameKeyDown,
  };
};
