import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useOrganisationActiveMemberQuery = (organisationID: string | undefined) => {
  return useQuery({
    queryKey: [QueryKeys.ORGANISATIONS, organisationID, QueryKeys.MEMBERS],
    queryFn: async () => {
      if (organisationID == null) return;
      const response = await api.organisations.getAllActiveMembers(organisationID);
      return response.data.data;
    },
    enabled: organisationID != null,
  });
};
