import { type DeltaContent } from "@/api/client";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { closeModal } from "@/components/shared/modal/ModalService";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { RichTextEditorComponent } from "@/components/shared/richTextEditor/RichTextEditorComponent";
import { ModalNames } from "@/constants/modalNames";
import { useEnhanceMessageQuery } from "@/hooks/mutations/conversations/useEnhanceMessageQuery";
import { type IModalAction } from "@/interfaces/modal";
import { type DeltaStatic } from "quill";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface EnhanceByAITabProps {
  initialText: DeltaContent;
  onSubmit: (content: DeltaContent) => void;
}

export const EnhanceByAITab = ({ initialText, onSubmit }: EnhanceByAITabProps): JSX.Element => {
  const { t } = useTranslation();

  const { data, isLoading } = useEnhanceMessageQuery(initialText);

  const actions: IModalAction[] = useMemo(
    () => [
      {
        action: () => {
          closeModal(ModalNames.ENHANCE_BY_AI);
        },
        label: t("general.close"),
        type: "secondary",
      },
      {
        action: () => {
          if (data != null) {
            onSubmit(data);
          }
          closeModal(ModalNames.ENHANCE_BY_AI);
        },
        label: t("general.confirm"),
        type: "primary",
        disabled: isLoading,
      },
    ],
    [isLoading, data],
  );

  return (
    <ModalTabComponent footer={<ModalFooterComponent actions={actions} />}>
      <div>
        <div className="mb-2">{t("enhanceByAIModal.subtitle")}</div>
        <div
          className={`message-content relative flex max-w-max flex-col
                      rounded-md border border-zinc-200/60 bg-zinc-100
                      transition-all
                      duration-200 group-hover:border-zinc-300 group-hover:bg-zinc-200 dark:border-dark-700
                      dark:bg-dark-600 dark:group-hover:bg-dark-500 dark:group-hover:text-dark-100
              `}
        >
          <div className="px-2 py-1">
            {!isLoading ? (
              <RichTextEditorComponent
                contextType="messageEnhancedByAIPreview"
                contextId="messageEnhancedByAIPreview"
                isMessagePreview={true}
                defaultValue={data as DeltaStatic}
              />
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>
    </ModalTabComponent>
  );
};
