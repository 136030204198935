import { GoogleTargetFeatureEnum } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { EmailConnectionContext } from "@/contexts/EmailConnectionContextProvider";
import { useGoogleLogoutMutation } from "@/hooks/mutations/google/useGoogleLogoutMutation";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useEmailListPopinContent = () => {
  const { setIsConnected } = useContext(EmailConnectionContext);
  const queryClient = useQueryClient();

  const onGoogleLougoutSuccess = useCallback(() => {
    queryClient.removeQueries({ queryKey: [QueryKeys.EMAIL_THREADS], exact: false });
    queryClient.removeQueries({ queryKey: [QueryKeys.EMAIL], exact: false });
    queryClient.removeQueries({ queryKey: [QueryKeys.EMAIL_THREAD], exact: false });
    toast.success(t("roomPage.tabs.mails.googleLogout.toast"));
    setIsConnected(false);
  }, []);

  const { mutate: googleLogout } = useGoogleLogoutMutation(onGoogleLougoutSuccess);
  const { t } = useTranslation();

  const onGoogleLogout = useCallback(async () => {
    googleLogout({ targetFeature: GoogleTargetFeatureEnum.Gmail });
  }, []);

  return {
    onGoogleLogout,
  };
};
