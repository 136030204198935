import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useRoomQuery = (roomId?: string) => {
  return useQuery({
    queryKey: [QueryKeys.ROOMS, roomId],
    queryFn: async () => {
      if (roomId == null) return;
      const response = await api.rooms.getOne(roomId, { defaultToastErrorMessage: null });
      return response.data;
    },
    enabled: roomId != null,
    staleTime: 12 * 60 * 60 * 1000,
  });
};
