import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { useCallback, useContext, useEffect } from "react";

export const useEnterAndLeaveContext = (contextType: string, contextId: string) => {
  const socket = useContext(SocketContext);

  const handleEnterConversation = useCallback(() => {
    if (socket == null || contextType === "message") return;
    socket.emit(SocketEvents.ENTER_CONTEXT, { conversationId: contextId });
  }, [socket, contextId]);

  const handleLeaveConversation = useCallback(() => {
    if (socket == null || contextType === "message") return;
    socket.emit(SocketEvents.LEAVE_CONTEXT, { conversationId: contextId });
  }, [socket, contextId]);

  useEffect(() => {
    handleEnterConversation();
    window.addEventListener("blur", handleLeaveConversation);
    window.addEventListener("focus", handleEnterConversation);

    return () => {
      handleLeaveConversation();
      window.removeEventListener("blur", handleLeaveConversation);
      window.removeEventListener("focus", handleEnterConversation);
    };
  }, [true, contextId, socket]);
  return {};
};
