import { type IChannel } from "@/api/client";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { ChannelMemberComponent } from "@/components/shared/members/ChannelMemberComponent";
import { InviteChannelMemberComponent } from "@/components/shared/members/InviteChannelMemberComponent";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { closeModal } from "@/components/shared/modal/ModalService";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { ModalNames } from "@/constants/modalNames";
import { defaultRoleSlug } from "@/constants/roles";
import { useChannelActiveMemberQuery } from "@/hooks/queries/channels/useChannelActiveMemberQuery";
import { useChannelQuery } from "@/hooks/queries/channels/useChannelQuery";
import { useRoomActiveMemberQuery } from "@/hooks/queries/rooms/useRoomActiveMemberQuery";
import { useSearchInput } from "@/hooks/shared/useSearchInput";
import { type IFormattedChannel } from "@/interfaces/channel";
import { type IModalAction } from "@/interfaces/modal";
import { filterMembersBySearch, formatChannelMembers } from "@/utils/utilities";
import { format } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
interface IInviteMemberInChannelTabComponentProps {
  roomId: string;
  channel: IChannel;
  isReadOnly?: boolean;
  conversationId?: string;
}
export const InviteMemberInChannelTabComponent = ({
  roomId,
  channel,
  isReadOnly = false,
  conversationId,
}: IInviteMemberInChannelTabComponentProps): JSX.Element => {
  const { search, updateSearch } = useSearchInput();

  const { t } = useTranslation();

  const { data: roomMembers, isSuccess } = useRoomActiveMemberQuery(roomId);
  const { data: channelMembers } = useChannelActiveMemberQuery(channel.id);
  const { data: channelResponse } = useChannelQuery(channel.id);

  const filteredMembers = useMemo(() => {
    const _filteredMembers = (roomMembers ?? []).filter((member) => {
      if (channelMembers == null) {
        return true;
      }

      return !channelMembers.some((channelMember) => channelMember.user.id === member.userId);
    });

    return filterMembersBySearch(_filteredMembers, search);
  }, [roomMembers, search, channelMembers]);

  const actions: IModalAction[] = [
    {
      action: () => {
        closeModal(ModalNames.CHANNEL_MODAL);
      },
      label: t("channelModal.tabs.members.buttons.done"),
      type: "primary",
    },
  ];

  const inputPlaceholder = useMemo(() => {
    if (isReadOnly) {
      return t("channelModal.tabs.members.inputPlaceholderDefaultChannel");
    }
    if (channelResponse?.member.role.slug === defaultRoleSlug.CHANNEL_OWNER) {
      return t("channelModal.tabs.members.inputPlaceholderOrg");
    }

    return t("channelModal.tabs.members.inputPlaceholderOrgMember");
  }, [isReadOnly]);

  return (
    <ModalTabComponent
      hasPadding={false}
      footer={
        <ModalFooterComponent
          actions={actions}
          info={
            isReadOnly
              ? t("channelModal.tabs.about.footerInfoDefaultChannel")
              : t("channelModal.tabs.about.footerInfo", {
                  date: format(new Date(channel?.createdAt ?? ""), t("date.shortFormat")),
                })
          }
        ></ModalFooterComponent>
      }
    >
      <>
        <div className="py-4 pb-0">
          <div className="px-4 pb-4">
            <TextInputComponent
              value={search}
              onChange={(e) => {
                updateSearch(e.target.value);
              }}
              placeholder={inputPlaceholder}
            />
          </div>
        </div>
        <div>
          <h3 className="mx-4 mb-1 flex items-center text-sm font-bold text-zinc-500 dark:text-dark-300">
            {t("channelModal.tabs.members.inChannel")} ({channelMembers?.length})
          </h3>
          <div className="mb-4 flex flex-col">
            {channelMembers != null &&
              channelMembers.length > 0 &&
              filterMembersBySearch(formatChannelMembers(channelMembers), search)?.map((channelMember: IFormattedChannel) => {
                const hasGuests = channelMembers.some((channelMember) => {
                  const roomMember = roomMembers?.find((member) => member.userId === channelMember.user.id);
                  return roomMember?.role?.type != null && roomMember.role.type === "guest";
                });

                const isLast =
                  channelMembers.filter((channelMember) => {
                    const roomMember = roomMembers?.find((member) => member.userId === channelMember.user.id);
                    return roomMember?.role?.type != null && roomMember.role.type !== "guest";
                  }).length === 1;

                return (
                  <ChannelMemberComponent
                    myRole={channelResponse?.member.role}
                    member={channelMember}
                    key={channelMember.email}
                    isDisabled={isReadOnly}
                    isLast={isLast}
                    hasGuests={hasGuests}
                    roomId={roomId}
                    conversationId={conversationId}
                  />
                );
              })}
          </div>
          {filteredMembers.length > 0 && channelResponse?.member.role.slug !== defaultRoleSlug.CHANNEL_USER && (
            <>
              <h3 className="mx-4 mb-1 flex items-center text-sm font-bold text-zinc-500 dark:text-dark-300">
                {t("channelModal.tabs.members.inRoom")} ({filteredMembers.length})
              </h3>
              {isSuccess &&
                roomMembers != null &&
                filteredMembers.map((member) => (
                  <InviteChannelMemberComponent
                    email={member.email}
                    key={member.email}
                    channelId={channel.id}
                    member={member}
                    conversationId={conversationId}
                  />
                ))}
            </>
          )}
        </div>
      </>
    </ModalTabComponent>
  );
};
