import { closeModal } from "@/components/shared/modal/ModalService";
import { useCallback } from "react";
import { RiCloseFill } from "react-icons/ri";

export interface IModalHeaderProps {
  title: JSX.Element | string;
  hasTabs: boolean;
  name: string;
  onClose?: () => void;
}

export const ModalHeaderComponent = ({ title, hasTabs = false, name, onClose }: IModalHeaderProps): JSX.Element => {
  const handleClose = useCallback(() => {
    if (onClose !== undefined) onClose();
    closeModal(name);
  }, [name, onClose]);
  return (
    <header className={`modal-header flex items-center justify-between bg-gray-100 dark:bg-dark-800 ${hasTabs ? "" : "border"}`}>
      <h2 className="modal-title px-6 py-3.5 text-xl font-semibold text-zinc-800 dark:text-dark-300">{title}</h2>

      <button onClick={handleClose} className="opacity-70 transition-opacity hover:opacity-100">
        <RiCloseFill className="mx-4 h-5 w-5 text-gray-500" />
      </button>
    </header>
  );
};
