import { type IMyChannelLeave } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const useLeaveChannelMutation = (roomId: string, channelId?: string) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IMyChannelLeave) => {
      if (channelId == null) return;
      await api.my.leaveChannel(channelId, data, { defaultToastErrorMessage: t("toasts.channel.leave.error") });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, roomId, QueryKeys.CHANNELS] });
      navigate(`/dashboard/rooms/${roomId}`);
      toast.success(t("toasts.channel.leave.success"));
    },
  });
};
