import { eventNames } from "@/constants/eventNames";

export const openModal = (name: string, component: JSX.Element, backdropOpacity = 0.5): void => {
  // emit custom event openModal
  window.dispatchEvent(
    new CustomEvent(eventNames.OPEN_MODAL, {
      detail: {
        name,
        modalComponent: component,
        backdropOpacity,
      },
    }),
  );
};

export const closeModal = (name?: string): void => {
  window.dispatchEvent(
    new CustomEvent(eventNames.CLOSE_MODAL, {
      detail: {
        name,
      },
    }),
  );
};

export const checkIfModalIsOpen = (name: string): boolean => {
  return document.getElementById(name) !== null;
};
