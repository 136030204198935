import { type IEventDatetime } from "@/api/client";
import { addMinutes, format } from "date-fns";

export const convertDateToRFC3339 = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const adjustedTime = addMinutes(date, offset);

  return format(adjustedTime, "yyyy-MM-dd'T'HH:mm:ssXXX");
};

export const getDateByEventDateTime = (eventDateTime: IEventDatetime) => {
  if (eventDateTime.date != null) {
    return new Date(eventDateTime.date);
  }

  if (eventDateTime.dateTime != null) {
    return new Date(eventDateTime.dateTime);
  }

  return new Date();
};
