// App layout with outlet

import { useUtm } from "@/hooks/utm/useUtm";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

export const LoggedOutLayout = (): JSX.Element => {
  const location = useLocation();
  const { getUtmParamsFromURL } = useUtm();
  useEffect(() => {
    getUtmParamsFromURL();
  }, [location.search]);
  return (
    <div className="bg-gradient-header flex h-screen flex-col dark:from-zinc-800 dark:to-zinc-800">
      <div className="grow">
        <Outlet />
      </div>
    </div>
  );
};
