import {
  type ApiNotAcceptableException,
  type IBadRequestError,
  type IForbiddenError,
  type INotFoundError,
  type IPlan,
  type IUnauthorizedError,
} from "@/api/client";
import { DowngradeConfirm } from "@/components/billing/modals/downgrade/DowngradeConfirm";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { ModalNames } from "@/constants/modalNames";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useSubscriptionUrlMutation } from "@/hooks/mutations/billing/useSubscriptionUrlMutation";
import { usePlansQuery } from "@/hooks/queries/billing/usePlanQuery";
import { type AxiosError } from "axios";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineInfoCircle as InfoIcon } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";

interface IPlanComponentProps {
  plan?: IPlan;
  planKeys: Array<keyof IPlan>;
}

export const PlanComponent = ({ plan, planKeys }: IPlanComponentProps) => {
  const me = useContext(AuthenticationContext);
  const { data: plans } = usePlansQuery();
  const myOrganisationType = useMemo(() => me?.mainOrganisation?.organisationType ?? "personal", [me]);
  const currentPlan = useMemo(() => {
    if (me == null || plans == null) return null;
    return plans.find((plan) => plan.id === me?.mainOrganisation?.organisationSubscription?.plan?.id);
  }, [me]);
  const { mutateAsync: getSubscriptionUrl, isPending } = useSubscriptionUrlMutation();
  const { t } = useTranslation();
  const handleSelectPlan = useCallback(async () => {
    if (plan == null) return;
    try {
      const response = await getSubscriptionUrl(plan.id);
      window.open(response.url, "_blank");
    } catch (error) {
      if ((error as AxiosError).isAxiosError) {
        const axiosError = error as AxiosError<IBadRequestError | IUnauthorizedError | IForbiddenError | INotFoundError | ApiNotAcceptableException>;
        if (axiosError.response?.status === 403) {
          // @ts-expect-error TODO quotaData is not defined on the response type
          const quotaData = axiosError.response.data.quotaData;
          openModal(ModalNames.CONFIRM_DOWNGRADE, <DowngradeConfirm quotas={quotaData} />);
        }
      }
    }
  }, [plan]);

  const isCurrentPlan = useMemo(() => {
    if (plan == null) return false;
    return currentPlan?.id === plan?.id;
  }, [currentPlan, plan]);

  const isDowngrade = useMemo(() => {
    if (plan?.priceEurosDecimal == null || currentPlan?.priceEurosDecimal == null) return false;
    return currentPlan.priceEurosDecimal > plan.priceEurosDecimal;
  }, [currentPlan, plan]);

  const buttonText = useMemo(() => {
    if (isPending) return t("general.loading");
    if (currentPlan?.priceEurosDecimal == null || plan?.priceEurosDecimal == null) return "";
    if (isDowngrade) {
      return t("billing.modals.plans.downgrade");
    } else if (!isDowngrade && currentPlan.priceEurosDecimal !== plan.priceEurosDecimal) {
      return t("billing.modals.plans.upgrade");
    }
    return t(`billing.modals.plans.current`);
  }, [currentPlan, plan, isPending, isDowngrade]);
  return (
    <article className={`rounded-lg bg-white py-2 ${isCurrentPlan ? "bg-zinc-100" : ""}`}>
      <header className={`${plan == null ? "" : "px-2"} mb-6 flex h-24 flex-col`}>
        <div className={`mb-1 flex flex-col items-start rounded-lg ${plan == null ? "opacity-0" : ""}`}>
          <h2 className={`pt-2 text-base font-semibold capitalize ${plan == null ? "opacity-0" : ""}`}>
            {plan?.name == null ? "&nbsp;" : t(`billing.modals.plans.${plan.name}.${myOrganisationType}.title`)}
          </h2>
          <p className="py-1 text-sm text-zinc-400">
            <span className="text-zinc-600">
              {(plan?.priceEurosDecimal ?? 0) / 100}
              {plan?.name != null && t(`general.currency`)}
            </span>
            {plan?.name != null && t(`billing.modals.plans.${plan.name}.${myOrganisationType}.price`)}
          </p>
          <p className="text-2xs text-zinc-400">{plan?.name != null && t(`billing.modals.plans.taxes`)}</p>
        </div>
        {plan != null ? (
          <ButtonComponent
            size="sm"
            status={isCurrentPlan || isDowngrade ? "secondary" : "primary"}
            className={`w-full items-center justify-center ${isCurrentPlan ? " pointer-events-none border-zinc-300 text-[#222222]" : ""}`}
            onClick={handleSelectPlan}
          >
            {buttonText}
          </ButtonComponent>
        ) : (
          <h2 className="py-1 text-sm font-semibold text-zinc-600">{t("billing.modals.plans.limitations.title")}</h2>
        )}
      </header>
      <section className="relative border-t">
        {planKeys.map((key) => {
          const value =
            plan == null ? (
              t(`billing.modals.plans.limitations.${key}.name`)
            ) : plan[key] == null ? (
              <span className="flex items-center text-sm">
                <FaCheck className="my-1 mr-2 h-4 w-4 text-sky-600" /> {t(`billing.modals.plans.unlimited`)}
              </span>
            ) : (
              <>{plan[key] != null && t(`billing.modals.plans.limitations.${key}.value`, { limit: plan[key] })}</>
            );
          return (
            <p
              key={key}
              className={`flex h-10 items-center border-b text-sm ${plan == null ? "text-zinc-600" : isCurrentPlan ? "px-2 text-zinc-500" : "px-2"}`}
            >
              <span>{value}</span>
              {plan == null ? (
                <TooltipComponent content={t(`billing.modals.plans.limitations.${key}.tooltip`)}>
                  <button type="button">
                    <InfoIcon className="ml-1 mt-0.5 h-3 w-3 text-zinc-600" />
                  </button>
                </TooltipComponent>
              ) : null}
            </p>
          );
        })}
      </section>
    </article>
  );
};
