import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useChannelQuery = (channelId?: string) => {
  return useQuery({
    queryKey: [QueryKeys.CHANNELS, channelId],
    queryFn: async () => {
      if (channelId == null) return;
      const response = await api.channels.getOne(channelId);
      return response.data;
    },
    enabled: channelId != null,
  });
};
