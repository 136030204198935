import { confirmEmail } from "@/services/AuthService";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

export const EmailConfirm = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const token = params.token;
  const [content, setContent] = useState(<></>);
  const confirm = useCallback((): void => {
    confirmEmail(token ?? "").then(
      (response) => {
        if (response.redirect === "wait-for-admin-approval") {
          navigate("/register/complete/pending");
        }
        setContent(
          <div className="text-center">
            <p>{t("authentication.emailConfirmation.success")}</p>
            <Link className="text-sm" to="/signin">
              {t("authentication.backSignIn")}
            </Link>
          </div>,
        );
        window.setTimeout(() => {
          navigate(`/signin`);
        }, 5000);
      },
      () => {
        setContent(<div className="text-center">{t("authentication.emailConfirmation.failed")}</div>);
      },
    );
  }, [token]);

  useEffect(() => {
    confirm();
  }, [token]);
  return <>{content}</>;
};
