export enum defaultRoleSlug {
  ORGANISATION_OWNER = "default-organisation-owner",
  ORGANISATION_ADMIN = "default-organisation-admin",
  ORGANISATION_USER = "default-organisation-user",
  ROOM_OWNER = "default-room-owner",
  ROOM_ADMIN = "default-room-admin",
  ROOM_USER = "default-room-user",
  CHANNEL_OWNER = "default-channel-owner",
  CHANNEL_ADMIN = "default-channel-admin",
  CHANNEL_USER = "default-channel-user",
}
