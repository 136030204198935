import { type IInvitation } from "@/api/client";
import { useAdminOrganisationRoomMemberTab } from "@/components/homePage/modals/room/tabs/useAdminOrganisationRoomMemberTab";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { PendingInvitationComponent } from "@/components/shared/invitation/PendingInvitationComponent";
import { InviteOrganisationMemberComponent } from "@/components/shared/members/InviteOrganisationMemberComponent";
import { OrganisationMemberComponent } from "@/components/shared/members/OrganisationMemberComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { BulkInviteContext } from "@/contexts/BulkInviteContextProvider";
import { useBulkInvite } from "@/hooks/shared/bulkInvite/useBulkInvite";
import { useContext, type FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";

export const AdminOrganisationRoomMemberTab: FunctionComponent = () => {
  const { search, updateSearch, handleBatchInvite, invitations, filteredMembers, mailsAlreadyInvited } = useAdminOrganisationRoomMemberTab();
  const { mails } = useContext(BulkInviteContext);
  const { handlePaste } = useBulkInvite(mailsAlreadyInvited);
  const { t, i18n } = useTranslation();

  return (
    <ModalTabComponent hasPadding={false}>
      <>
        <div className="mb-4 px-6 py-4 pb-0">
          <TextInputComponent
            value={search}
            onChange={(e) => {
              updateSearch(e.target.value);
            }}
            onPaste={(e) => {
              handlePaste(e);
              updateSearch("");
            }}
            placeholder={t("roomModal.members.organisation.admin.searchPlaceholder")}
          />
        </div>
        <div>
          {invitations.length === 0 && search !== "" && <InviteOrganisationMemberComponent email={search.trim()} />}
          {mails.length > 0 && (
            <>
              <h3 className="mb-1 mt-2 flex w-full items-center px-6 text-sm font-medium text-zinc-500 dark:text-dark-300 ">
                <Trans
                  i18nKey="roomModal.members.organisation.pastedInvites.subtitle"
                  i18n={i18n}
                  values={{ count: mails.length }}
                  components={{ active: <span className="ml-1 text-sky-600" /> }}
                />

                <ButtonComponent
                  status="secondary"
                  size="sm"
                  className="ml-auto border-0 font-[600] dark:bg-dark-700 dark:text-dark-300 dark:hover:bg-dark-600"
                  onClick={async () => {
                    await handleBatchInvite(mails);
                  }}
                >
                  {t("roomModal.members.organisation.pastedInvites.inviteButton", { count: mails.length })}
                </ButtonComponent>
              </h3>
              {mails.map((email) => (
                <InviteOrganisationMemberComponent key={email} email={email} isInBulk />
              ))}
            </>
          )}
          <h3 className="mb-1 mt-2 flex items-center px-6 text-sm font-medium text-zinc-500 dark:text-dark-300 ">
            <Trans
              i18nKey="roomModal.members.organisation.invites.subtitle"
              i18n={i18n}
              values={{ count: invitations.length }}
              components={{ active: <span className="ml-1 text-sky-600" /> }}
            />
          </h3>
          <div className="mb-4">
            {invitations.map((invitation: IInvitation) => {
              return <PendingInvitationComponent invitation={invitation} key={invitation.id} />;
            })}
          </div>
          <h3 className="mx-6 mb-1 mt-2 flex items-center text-sm font-medium text-zinc-500">
            <Trans
              i18nKey="roomModal.members.organisation.members.subtitle"
              i18n={i18n}
              values={{ count: filteredMembers.length }}
              components={{ active: <span className="ml-1 text-sky-600" /> }}
            />
          </h3>

          <div className="mb-4">{filteredMembers?.map((member) => <OrganisationMemberComponent member={member} key={member.userId} />)}</div>
        </div>
      </>
    </ModalTabComponent>
  );
};
