import type { IEmailThread, IEmailThreadAllResponse } from "@/api/client";
import { ThreadPreviewComponent } from "@/components/roomPage/tabs/emails/ThreadPreviewComponent";
import { type FetchPreviousPageOptions, type InfiniteData, type InfiniteQueryObserverResult } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { Virtuoso } from "react-virtuoso";

interface IThreadListComponentProps {
  threads: IEmailThread[];
  openedThreadId?: string;
  fetchPreviousPage: (
    options?: FetchPreviousPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<IEmailThreadAllResponse, unknown>, Error>>;
}
export const ThreadListComponent: FunctionComponent<IThreadListComponentProps> = ({ threads, fetchPreviousPage, openedThreadId }) => {
  return (
    <div className="relative h-full w-1/2 p-layout pb-0 pr-1">
      <Virtuoso<IEmailThread>
        data={threads}
        itemContent={(_index: number, thread: IEmailThread) => (
          <ThreadPreviewComponent key={thread.id} threadId={thread.id} isOpened={thread.id === openedThreadId} />
        )}
        endReached={async () => {
          await fetchPreviousPage();
        }}
      />
    </div>
  );
};
