import { type ITask, type ITaskAddBody } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { useNavigateToTask } from "@/hooks/tasks/useNavigateToTask";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { toast } from "react-hot-toast";
import { Trans } from "react-i18next";

export const useCreateTaskMutation = (isFromMessage = false) => {
  const queryClient = useQueryClient();
  const { navigateToTask } = useNavigateToTask();

  const handleSuccessToastClick = useCallback((createdTask: ITask) => {
    navigateToTask(createdTask);
    toast.dismiss();
  }, []);

  return useMutation({
    mutationFn: async (params: ITaskAddBody) => {
      const { data } = await api.tasks.add(params);

      return data;
    },
    onSuccess: (createdTask) => {
      void queryClient.invalidateQueries({
        exact: false,
        queryKey: [QueryKeys.TASKS],
      });
      if (!isFromMessage || createdTask.data.channel?.room == null) return;

      toast.success(
        <button
          className="text-left"
          onClick={() => {
            handleSuccessToastClick(createdTask.data);
          }}
        >
          <Trans i18nKey="createTaskModal.toast.successFromMessage" />
        </button>,
      );
    },
  });
};
