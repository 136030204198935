import { type SimpleUser, type UserWithEmail, type UserWithId } from "@/interfaces/user";
import { useState } from "react";

export const useUserTagList = (onAddUser: (user: UserWithId | UserWithEmail) => void, onRemoveUser: (user: UserWithId | UserWithEmail) => void) => {
  const [isPickUserTooltipVisible, setIsPickUserTooltipVisible] = useState<boolean>(false);

  const onOpenTooltip = () => {
    setIsPickUserTooltipVisible(true);
  };
  const onCloseTooltip = () => {
    setIsPickUserTooltipVisible(false);
  };

  const onAddUserWithEmail = (email: string) => {
    onAddUser({ email, type: "email" });
  };

  const onUserChange = (user: SimpleUser, isSelected: boolean) => {
    if (isSelected) {
      onAddUser({ id: user.id, type: "userId" });
    } else {
      if (user.email === user.id) {
        onRemoveUser({ email: user.email, type: "email" });
      } else {
        onRemoveUser({ id: user.id, type: "userId" });
      }
    }
  };

  return { isPickUserTooltipVisible, onCloseTooltip, onUserChange, onOpenTooltip, onAddUserWithEmail };
};
