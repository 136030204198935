import RogerLogo from "@/assets/images/logo-roger.svg?react";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

export const AuthenticationPage = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const routePrefix = "";
  const { t } = useTranslation();
  const goToHome = useCallback((): void => {
    navigate(routePrefix);
  }, []);

  // if the member is already logged in, redirect to the home page
  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      navigate("/dashboard");
    }
  }, []);

  const footerLink = {
    [`${routePrefix}/signin`]: (
      <span className="text-white dark:text-dark-400">
        {t("authentication.notAMember")}{" "}
        <Link className="font-semibold text-white opacity-80 hover:opacity-100" to={`${routePrefix}/register`}>
          {t("authentication.createAnAccount")}
        </Link>
      </span>
    ),
    [`${routePrefix}/register`]: (
      <span className="text-white dark:text-dark-400">
        {t("authentication.alreadyMember")}{" "}
        <Link className="font-semibold text-white opacity-80 hover:opacity-100" to={`${routePrefix}/signin`}>
          {t("authentication.signIn.signIn")}
        </Link>
      </span>
    ),
    [`${routePrefix}/password/forgot`]: (
      <>
        <Link className="text-white hover:opacity-80" to={`${routePrefix}/signin`}>
          {t("general.back")}
        </Link>
      </>
    ),
    [`${routePrefix}/password/reset`]: (
      <>
        <Link className="text-white hover:opacity-80" to={`${routePrefix}/signin`}>
          {t("authentication.backSignIn")}
        </Link>
      </>
    ),
  };

  const footerContent = footerLink[location.pathname as keyof typeof footerLink];

  return (
    <div className="flex h-screen flex-col items-center justify-center ">
      <div className="w-10/12 sm:w-6/12 lg:w-4/12 xl:w-3/12">
        <h1 className="mb-8 flex cursor-pointer items-center justify-center" onClick={goToHome}>
          <RogerLogo className="mr-4 h-12 w-12" />
          <span className="pb-2.5 font-[rubik] text-7xl text-black dark:text-white">roger</span>
        </h1>

        <div className="m-auto w-full rounded-md border border-gray-300 bg-white p-7 dark:border-dark-700 dark:bg-dark-900">
          <Outlet />
        </div>
        <div className="mt-5 flex justify-center">
          <p className="text-xs text-gray-600">{footerContent}</p>
        </div>
      </div>
    </div>
  );
};
