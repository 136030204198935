import RogerLogo from "@/assets/images/logo-roger.svg?react";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export const GoogleRedirectPage = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const openElectron = () => {
    const code = searchParams.get("code");

    const url = `${import.meta.env.VITE_APP_URL_SCHEME}://google-signin?authorization_code=${code}`;
    window.open(url, "_blank", "noreferrer");
  };

  useEffect(() => {
    openElectron();
  }, []);

  return (
    <div className="flex h-screen flex-col items-center justify-center ">
      <div className="w-10/12 sm:w-6/12 lg:w-4/12 xl:w-3/12">
        <h1 className="mb-8 flex cursor-pointer flex-col items-center justify-center">
          <RogerLogo className="mr-4 h-12 w-12" />
          <span className="pb-2.5 font-[rubik] text-7xl text-black dark:text-white">roger</span>
          <span className="my-4 font-[rubik] text-lg text-white ">{t("google.redirect.description")}</span>
          <ButtonComponent status="primary" type="button" onClick={openElectron}>
            {t("google.redirect.button")}
          </ButtonComponent>
        </h1>
      </div>
    </div>
  );
};
