import { type ITodo } from "@/api/client";
import { useAddTodoMutation } from "@/hooks/mutations/tasks/todos/useAddTodoMutation";
import { useRemoveTodoMutation } from "@/hooks/mutations/tasks/todos/useRemoveTodoMutation";
import { useUpdateTodoMutation } from "@/hooks/mutations/tasks/todos/useUpdateTodoMutation";
import { useMemo, useState } from "react";

export const useTaskTodos = (taskId: string | null) => {
  const [isAddingTodo, setIsAddingTodo] = useState<boolean>(false);
  const [todos, setTodos] = useState<ITodo[]>([]);

  const { mutate: addTodo } = useAddTodoMutation(taskId ?? "");
  const { mutate: removeTodo } = useRemoveTodoMutation(taskId ?? "");
  const { mutate: updateTodo } = useUpdateTodoMutation(taskId ?? "");

  const onAddElement = () => {
    setIsAddingTodo(true);
  };

  const onSubmitNewTodo = (value: string) => {
    setIsAddingTodo(false);

    if (value.length > 0) {
      if (taskId != null) {
        addTodo({
          value,
          optimisticId: `[OPTIMISTIC] ${new Date().toString()}`,
        });
      } else {
        setTodos((prev) => [
          ...prev,
          {
            id: `[OPTIMISTIC] ${new Date().toString()}`,
            value,
            isChecked: false,
            createdAt: new Date().toISOString(),
          },
        ]);
      }
    }
  };

  const onTodoRemove = (todo: ITodo) => {
    if (taskId != null) {
      removeTodo(todo.id);
    } else {
      setTodos((prev) => prev.filter((t) => t.id !== todo.id));
    }
  };

  const onToggleTodo = (todo: ITodo) => {
    if (taskId != null) {
      updateTodo({
        todoId: todo.id,
        isChecked: !todo.isChecked,
      });
    } else {
      setTodos((prev) =>
        prev.map((t) => {
          if (t.id === todo.id) {
            return {
              ...t,
              isChecked: !t.isChecked,
            };
          }

          return t;
        }),
      );
    }
  };

  const todosWithCount = useMemo(
    () => ({
      todos,
      totalCount: todos.length,
      checkedCount: todos.reduce((acc, todo) => acc + (todo.isChecked ? 1 : 0), 0),
    }),
    [todos],
  );

  return { isAddingTodo, onAddElement, onSubmitNewTodo, onTodoRemove, onToggleTodo, todosWithCount };
};
