import { type IRoom, type IRoomMember } from "@/api/client";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { RoomMemberComponent } from "@/components/shared/members/RoomMemberComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { useRoomActiveMemberQuery } from "@/hooks/queries/rooms/useRoomActiveMemberQuery";
import { useSearchInput } from "@/hooks/shared/useSearchInput";
import { filterMembersBySearch } from "@/utils/utilities";
import { useMemo, type FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";

interface UserProjectRoomMemberTabProps {
  room: IRoom;
  member: IRoomMember;
}

export const UserProjectRoomMemberTab: FunctionComponent<UserProjectRoomMemberTabProps> = ({ room, member }) => {
  const { search, updateSearch } = useSearchInput();
  const { t, i18n } = useTranslation();

  const { data: members } = useRoomActiveMemberQuery(room.id);

  const filteredMembers = useMemo(() => {
    return filterMembersBySearch(members, search);
  }, [search, members]);

  return (
    <ModalTabComponent hasPadding={false}>
      <>
        <div className="mb-6 py-4 pb-0">
          <div className="px-6">
            <TextInputComponent
              value={search}
              onChange={(e) => {
                updateSearch(e.target.value);
              }}
              placeholder={t("createRoomModal.tabs.members.inputPlaceholderMember")}
            />
          </div>
        </div>

        {members != null ? (
          <h3 className="mx-6 mb-1 mt-4 flex items-center text-sm font-medium text-zinc-500 dark:text-dark-300">
            <Trans
              i18nKey="createRoomModal.tabs.members.inRoom"
              i18n={i18n}
              values={{ count: filteredMembers.length }}
              components={{ active: <span className="ml-1 text-sky-600" /> }}
            />
          </h3>
        ) : null}
        {members != null &&
          filteredMembers.map((roomMember) => (
            <RoomMemberComponent member={roomMember} key={roomMember.userId} isLast={members.length === 1} myRole={member.role} />
          ))}
      </>
    </ModalTabComponent>
  );
};
