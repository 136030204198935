import { PortalInPlaceComponent } from "@/components/shared/layout/PortalInPlaceComponent";
import { Popover, Transition } from "@headlessui/react";
import { Fragment, useRef, type HTMLAttributes } from "react";

interface IThePopoverProps extends HTMLAttributes<HTMLDivElement> {
  children: JSX.Element[] | JSX.Element;
  footer?: JSX.Element;
  hover?: boolean;
  button?: JSX.Element;
  align?: "left" | "right" | "center";
  isNavigable?: boolean;
}

export const ThePopoverComponent = ({
  children,
  footer,
  hover = false,
  button,
  align = "center",
  isNavigable = true,
  ...props
}: IThePopoverProps): JSX.Element => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const timeoutDuration = 200;
  let timeout: number;

  const closePopover = () => {
    return buttonRef?.current?.dispatchEvent(
      new KeyboardEvent("keydown", {
        key: "Escape",
        bubbles: true,
        cancelable: true,
      }),
    );
  };

  const onMouseEnter = (open: boolean) => {
    clearTimeout(timeout);
    if (open || !hover) return;
    return buttonRef.current?.click();
  };

  const onMouseLeave = (open: boolean): void => {
    if (!open || !hover) return;

    timeout = window.setTimeout(() => closePopover(), timeoutDuration);
  };

  return (
    <Popover className="popover relative flex">
      {({ open }) => {
        return (
          <>
            <div onMouseLeave={onMouseLeave.bind(null, open)}>
              <Popover.Button
                ref={buttonRef}
                className={`${
                  open ? "" : "text-opacity-90"
                } group inline-flex items-center rounded-md text-sm font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75
                  `}
                onMouseEnter={onMouseEnter.bind(null, open)}
                onMouseLeave={onMouseLeave.bind(null, open)}
                tabIndex={isNavigable ? 0 : -1}
              >
                {button}
              </Popover.Button>
              <PortalInPlaceComponent>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel
                    className={`absolute z-50 mt-0${align === "center" ? "-translate-x-1/2" : ""} ${align === "right" ? "right-0" : ""}
                    ${align === "left" ? "left-0" : ""}
                    ${props.className ?? ""}
                    `}
                  >
                    <div
                      className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 dark:border dark:border-dark-700"
                      onMouseEnter={onMouseEnter.bind(null, open)}
                      onMouseLeave={onMouseLeave.bind(null, open)}
                    >
                      <div className="relative min-w-max bg-white dark:bg-dark-900">{children}</div>
                      {footer != null && <div className="bg-gray-50 p-4 dark:bg-dark-800">{footer}</div>}
                    </div>
                  </Popover.Panel>
                </Transition>
              </PortalInPlaceComponent>
            </div>
          </>
        );
      }}
    </Popover>
  );
};
