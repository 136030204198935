import { type IFile } from "@/api/client";
import { ImageViewerComponent } from "@/components/shared/documentViewer/ImageViewerComponent";
import { type FunctionComponent } from "react";
import { Portal } from "react-portal";

interface ImagePreviewComponentProps {
  file: IFile;
}

export const ImagePreviewComponent: FunctionComponent<ImagePreviewComponentProps> = ({ file }) => {
  return (
    <Portal>
      <div className="h-screen w-screen">
        <ImageViewerComponent file={file} />
      </div>
    </Portal>
  );
};
