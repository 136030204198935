import { UserActivityStatus } from "@/api/client";
import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { useUserQuery } from "@/hooks/queries/shared/user/useUserQuery";
import { useContext, useEffect, useState } from "react";

export const useUpdateUserActivityStatus = () => {
  const socket = useContext(SocketContext);

  const { data: meData } = useMeQuery();
  const { data } = useUserQuery(meData?.id ?? null);

  const userActivity = data?.activityStatus ?? UserActivityStatus.Offline;

  const [isFocused, setIsFocused] = useState<boolean>(true);

  const onWindowFocus = () => {
    setIsFocused(true);
  };

  const onWindowBlur = () => {
    setIsFocused(false);
  };

  const emitActiveEvent = () => {
    if (socket == null) {
      return;
    }
    socket.emit(SocketEvents.USER_IS_ACTIVE, { deviceType: "web" });
  };

  useEffect(() => {
    if (userActivity === UserActivityStatus.Offline) {
      return;
    }

    let interval: NodeJS.Timeout | null = null;
    if (isFocused) {
      emitActiveEvent();

      interval = setInterval(
        () => {
          emitActiveEvent();
        },
        1000 * 60 * 5,
      );
    } else {
      if (interval !== null) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
    };
  }, [isFocused, userActivity]);

  useEffect(() => {
    if (userActivity === UserActivityStatus.Offline) {
      return;
    }

    window.addEventListener("focus", onWindowFocus);
    window.addEventListener("blur", onWindowBlur);

    return () => {
      window.removeEventListener("focus", onWindowFocus);
      window.removeEventListener("blur", onWindowBlur);
    };
  }, [userActivity]);
};
