import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { type IModalAction } from "@/interfaces/modal";

export interface IModalFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  actions?: IModalAction[];
  info?: string | JSX.Element;
}

export const ModalFooterComponent = ({ actions, info, ...props }: IModalFooterProps): JSX.Element => {
  return (
    <footer
      className={`mt-auto flex min-h-[72px] select-none items-center justify-end border-t bg-white px-6 py-4 dark:border-t-dark-700 dark:bg-dark-800 ${
        props.className ?? ""
      }`}
    >
      {info !== undefined && <span className="mr-auto w-full text-sm text-zinc-400">{info}</span>}
      {actions?.map(
        (action) =>
          action.hidden !== true && (
            <ButtonComponent
              key={action.label}
              type="button"
              status={action.type}
              onClick={action.disabled === false || action.disabled == null ? (action.action as () => void) : () => {}}
              size="md"
              className="ml-2 self-start"
              disabled={action.disabled}
            >
              {action.label}
            </ButtonComponent>
          ),
      )}
    </footer>
  );
};
