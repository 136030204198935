import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { CreateTaskDrawerComponent } from "@/components/shared/tasks/CreateTaskDrawerComponent/CreateTaskDrawerComponent";
import { type Task } from "@/interfaces/task";
import { useCallback, useContext, type MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { HiMiniDocumentDuplicate as DuplicateIcon } from "react-icons/hi2";

export const useTaskItemContextMenu = (task: Task) => {
  const { openDrawer } = useContext(DrawerContext);
  const { t } = useTranslation();
  const openTaskDrawer = useCallback(() => {
    openDrawer({
      drawerType: DrawerType.CREATE_TASK,
      component: <CreateTaskDrawerComponent defaultValues={task} />,
    });
  }, [task]);

  const onDuplicate: MouseEventHandler<HTMLDivElement> = useCallback((event) => {
    event.stopPropagation();
    openTaskDrawer();
  }, []);

  const menuItems = [
    {
      title: t("tasks.duplicate.label"),
      icon: <DuplicateIcon />,
      onClick: onDuplicate,
    },
  ];
  return { menuItems };
};
