import { type ITask, type ITaskStatus } from "@/api/client";
import { DraggableTaskItemComponent } from "@/components/roomPage/tabs/tasks/DraggableTaskItemComponent";
import { useTaskStatusColumn } from "@/components/roomPage/tabs/tasks/useTaskStatusColumn";
import { TaskStatusColumnPopinComponent } from "@/components/shared/tasks/TaskStatusColumnPopinComponent/TaskStatusColumnPopinComponent";
import { Trans, useTranslation } from "react-i18next";
import { FaCog, FaPlus } from "react-icons/fa";
import { MdDragIndicator as DragIcon } from "react-icons/md";
import { Virtuoso } from "react-virtuoso";
interface ITaskStatusColumnComponentProps {
  tasks: ITask[];
  status: ITaskStatus;
  isOverlay?: boolean;
  isDragging?: boolean;
}

export const TaskStatusColumnComponent = ({ tasks, status, isOverlay = false, isDragging = false }: ITaskStatusColumnComponentProps) => {
  const { t } = useTranslation();
  const {
    attributes,
    listeners,
    setNodeRef,
    style,
    shouldDelayOverlay,
    shouldDisplayOverlay,
    tasksToDisplay,
    isPopinVisible,
    setIsPopinVisible,
    popinKey,
    handleCreateTaskClick,
  } = useTaskStatusColumn(tasks, status);

  return (
    <div
      ref={setNodeRef}
      className={`relative flex h-full flex-col px-4 pt-4 ${isOverlay ? "rounded-xl border bg-white" : ""}`}
      style={{
        minWidth: 365,
        maxWidth: 365,
        ...style,
      }}
    >
      {isDragging ? <div className="h-full w-full rounded-xl border border-dashed border-zinc-400 bg-zinc-50 p-2 pb-0"></div> : null}

      <div className={`mb-4 flex items-center ${isDragging ? "opacity-0" : ""}`}>
        <DragIcon {...attributes} {...listeners} className="h-4 w-4 cursor-move text-zinc-500 outline-0" />
        <h2 className="text-sm font-semibold text-zinc-700">
          <span>{status.name} </span>
          <span className="text-sm font-normal text-zinc-500">{tasksToDisplay.length > 0 ? `· ${tasksToDisplay.length}` : ""}</span>
        </h2>
        <div className="ml-auto flex">
          <TaskStatusColumnPopinComponent
            status={status}
            isVisible={isPopinVisible}
            onCloseTooltip={() => {
              setIsPopinVisible(false);
            }}
            key={popinKey}
          >
            <button
              onClick={() => {
                setIsPopinVisible(!isPopinVisible);
              }}
              className="text-zinc-500 transition-all hover:text-zinc-600"
            >
              <FaCog className="h-3 w-3" />
            </button>
          </TaskStatusColumnPopinComponent>
          <button onClick={handleCreateTaskClick} className="ml-2 text-zinc-500 transition-all hover:text-zinc-600">
            <FaPlus className="h-3 w-3" />
          </button>
        </div>
      </div>

      <Virtuoso<ITask>
        className={`h-full ${isDragging ? "hidden" : ""}`}
        data={tasksToDisplay}
        itemContent={(_, task) => <DraggableTaskItemComponent key={task.id} task={task} />}
      />
      <div
        className={`absolute inset-x-2 bottom-2 top-11 ${shouldDelayOverlay ? "delay-100" : ""} rounded-lg transition-opacity ${
          shouldDisplayOverlay ? "z-10 bg-zinc-300/20" : "-z-10 opacity-0"
        }`}
      >
        <div className="flex h-full w-full flex-col content-center justify-evenly">
          <div />
          <div />
          <div className="mx-8 flex flex-col content-center justify-center rounded-lg border border-zinc-400 bg-gray-300 p-4">
            <h2 className="mb-1 text-center text-sm font-medium text-zinc-600">{t("tasks.dragOverlay.title")}</h2>
            <h3 className="text-center text-xs font-normal text-zinc-500">
              <Trans i18nKey="tasks.dragOverlay.order.createdAt" />
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
