import type { IEmail } from "@/api/client";
import { EmailRecipientsSectionComponent } from "@/components/roomPage/tabs/emails/EmailRecipientsSectionComponent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface IEmailRecipientsComponentProps {
  email: IEmail;
}

export const EmailRecipientsComponent: FunctionComponent<IEmailRecipientsComponentProps> = ({ email }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-2">
        <strong>{t("roomPage.tabs.mails.recipientsInfo.from")}</strong>
        <div className="font whitespace-nowrap font-normal">{email.from.raw}</div>
      </div>

      <EmailRecipientsSectionComponent recipients={email.to} label={t("roomPage.tabs.mails.recipientsInfo.to")} />
      <EmailRecipientsSectionComponent recipients={email.cc} label={t("roomPage.tabs.mails.recipientsInfo.cc")} />
    </div>
  );
};
