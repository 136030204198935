import { useCallback, useState, type MouseEvent } from "react";

export const useContextualMenu = (): {
  open: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  close: () => void;
  isOpen: boolean;
  contextualMenuPosition: { x: number; y: number };
} => {
  const [isOpen, setIsOpen] = useState(false);
  const [contextualMenuPosition, setContextualMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const close = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);
  const open = useCallback(
    (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setContextualMenuPosition({
        x: e.clientX,
        y: e.clientY,
      });
      setIsOpen(true);
    },
    [isOpen],
  );

  return { isOpen, open, close, contextualMenuPosition };
};
