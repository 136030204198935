import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { useJitsiFrame } from "@/components/videoCall/useJitsiFrame";
import { JitsiContext } from "@/contexts/JitsiContextProvider";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiSolidArrowToBottom, BiSolidArrowToTop } from "react-icons/bi";
import { MdCallEnd } from "react-icons/md";
import { TbArrowsMaximize, TbArrowsMinimize } from "react-icons/tb";
import { VscChromeMinimize } from "react-icons/vsc";

export const JitsiFrameComponent = () => {
  const { t } = useTranslation();
  const jitsiContext = useContext(JitsiContext);

  const jitsiDomain = jitsiContext.domain;
  const { isFullScreen, isVideoShown, setIsVideoShown, setIsFullScreen, setIsHidden, roomName, isHidden, isOnTop, setIsOnTop } = jitsiContext;
  const { onApiReady, configOverwrite, interfaceConfigOverwrite, userInfo, getIFrameRef } = useJitsiFrame();

  if (!isVideoShown) return null;
  return (
    <div
      className={`absolute z-[9000] flex select-none transition ${isHidden ? "hidden" : ""} ${
        isFullScreen ? "h-full w-full items-center justify-center bg-black/50" : "right-5"
      }`}
      style={{
        top: isFullScreen || !isOnTop ? "auto" : "85px",
        bottom: isFullScreen || isOnTop ? "auto" : "120px",
      }}
      key={`jitsi-frame-${roomName}`}
    >
      <div
        className={`relative aspect-video ${isFullScreen ? " w-11/12" : ""}`}
        style={{ maxHeight: "calc(100vh - 180px)", width: isFullScreen ? "" : "calc(36rem - 190px)" }}
      >
        <nav className={`z-90 absolute -top-8 left-0 flex flex-row ${isFullScreen ? "" : "bg-white/80"}`}>
          {!isFullScreen ? (
            <TooltipComponent
              placement="bottom"
              content={isOnTop ? t("videoCalls.frame.buttons.moveToBottom") : t("videoCalls.frame.buttons.moveToTop")}
            >
              <button
                onClick={() => {
                  setIsOnTop(!isOnTop);
                }}
                className="ml-1 h-7 w-7 rounded-md bg-zinc-200 p-1 transition hover:bg-zinc-300"
              >
                {isOnTop ? <BiSolidArrowToBottom className="w-full" /> : <BiSolidArrowToTop className="w-full" />}
              </button>
            </TooltipComponent>
          ) : null}
          <TooltipComponent placement="bottom" content={t("videoCalls.frame.buttons.minimize")}>
            <button
              onClick={() => {
                setIsHidden(true);
              }}
              className="ml-1 h-7 w-7 rounded-md bg-zinc-200 p-1 transition hover:bg-zinc-300"
            >
              <VscChromeMinimize className="w-full" />
            </button>
          </TooltipComponent>
          <TooltipComponent
            placement="bottom"
            content={isFullScreen ? t("videoCalls.frame.buttons.reduce") : t("videoCalls.frame.buttons.fullscreen")}
          >
            <button
              type="button"
              onClick={() => {
                setIsFullScreen(!isFullScreen);
              }}
              className="ml-1 h-7 w-7 rounded-md bg-zinc-200 p-1 transition hover:bg-zinc-300"
            >
              {isFullScreen ? <TbArrowsMinimize className="w-full" /> : <TbArrowsMaximize className="w-full" />}
            </button>
          </TooltipComponent>
          <TooltipComponent placement="bottom" content={t("videoCalls.frame.buttons.endCall")}>
            <button
              onClick={() => {
                setIsFullScreen(false);
                setIsVideoShown(false);
              }}
              className="ml-1 h-7 w-7 rounded-md bg-red-600 p-1 transition hover:bg-red-700"
            >
              <MdCallEnd className="w-full text-white" />
            </button>
          </TooltipComponent>
        </nav>
        <div className="h-full w-full overflow-hidden rounded-lg border border-zinc-500 shadow-sm-centered">
          <JitsiMeeting
            domain={jitsiDomain}
            roomName={roomName}
            configOverwrite={configOverwrite}
            interfaceConfigOverwrite={interfaceConfigOverwrite}
            userInfo={userInfo}
            onApiReady={onApiReady}
            getIFrameRef={getIFrameRef}
          />
        </div>
      </div>
    </div>
  );
};
