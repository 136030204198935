import { useUpdateRoomNotificationSettingsMutation } from "@/hooks/mutations/notificationSettings/useUpdateRoomNotificationSettingsMutation";
import { useRoomNotificationSettingsQuery } from "@/hooks/queries/notificationSettings/useRoomNotificationSettingsQuery";
import { useCallback, useMemo } from "react";

export const useRoomNotificationSettings = (roomId: string) => {
  const { data: roomNotificationSettings, isLoading } = useRoomNotificationSettingsQuery(roomId);
  const { mutate } = useUpdateRoomNotificationSettingsMutation();

  const isNotificationEnabled = useMemo(() => {
    if (roomId == null || roomNotificationSettings == null) return true;
    return roomNotificationSettings.roomNotificationSettings.enableNotifications;
  }, [roomNotificationSettings]);

  const onToggle = useCallback(
    (_isNotificationEnabled: boolean) => {
      mutate({ roomId, isNotificationEnabled: _isNotificationEnabled });
    },
    [roomId],
  );

  return { onToggle, isNotificationEnabled, isLoading };
};
