import { type IDirectMessage } from "@/api/client";
import { DmModalComponent } from "@/components/roomPage/modals/dm/DmModalComponent";
import { useConversationListElement } from "@/components/roomPage/tabs/conversation/ConversationLists/conversationListElement/useConversationListElement";
import { openModal } from "@/components/shared/modal/ModalService";
import { eventNames } from "@/constants/eventNames";
import { RoomContext } from "@/contexts/RoomContext";
import { useDMUpdate } from "@/hooks/shared/dm/useDMUpdate";
import { useCallback, useContext, useMemo, useState } from "react";

export const useDmList = () => {
  const roomContext = useContext(RoomContext);
  const room = useMemo(() => roomContext?.roomResponse?.room, [roomContext]);
  const { getConversationInfo } = useConversationListElement("dm");
  const [dms, setDms] = useState<{ oneOnOne: IDirectMessage[]; group: IDirectMessage[] }>({ oneOnOne: [], group: [] });

  const closeChannelList = useCallback((): void => {
    const customEvent = new CustomEvent(eventNames.TOGGLE_CHANNEL_LIST, {
      detail: false,
    });

    window.dispatchEvent(customEvent);
  }, []);

  const addDm = useCallback(
    (event: Event): void => {
      event.preventDefault();
      openModal("dmModal", <DmModalComponent roomId={room?.id} />);
    },
    [room],
  );

  // separate conversation with just one member from the rest if scope is dm
  const getDmsWithOneMember = useCallback((conversations: IDirectMessage[]) => {
    if (conversations == null || conversations.length === 0) return [];
    return conversations.filter((conversation) => getConversationInfo(conversation).count === 1);
  }, []);

  const getDmsWithMoreThanOneMember = useCallback((conversations: IDirectMessage[]) => {
    if (conversations == null || conversations.length === 0) return [];
    return conversations.filter((conversation) => getConversationInfo(conversation).count > 1);
  }, []);

  useDMUpdate(room?.id);

  return {
    closeChannelList,
    addDm,
    getDmsWithOneMember,
    getDmsWithMoreThanOneMember,
    dms,
    setDms,
  };
};
