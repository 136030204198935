import { type IRoomMember } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect } from "react";

export const useRoomList = () => {
  const socket = useContext(SocketContext);
  const queryClient = useQueryClient();
  const handleRoomsUpdated = useCallback((newRooms: IRoomMember[]) => {
    queryClient.setQueryData([QueryKeys.MY_ROOMS], () => newRooms);
  }, []);

  useEffect(() => {
    if (socket == null) return;
    socket.on(SocketEvents.ROOMS_UPDATED, handleRoomsUpdated);
    return () => {
      socket.off(SocketEvents.ROOMS_UPDATED, handleRoomsUpdated);
    };
  }, [socket]);
};
