import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { PickUsersPopinContentComponent } from "@/components/shared/user/PickUsersPopinComponent/PickUsersPopinContentComponent";
import { type TippyProps } from "@tippyjs/react";
import { type FunctionComponent } from "react";

interface IPickUsersPopinComponentProps {
  children: TippyProps["children"];
  roomId: string | null;
  channelId: string | null;
  isVisible: boolean;
  onCloseTooltip: () => void;
  selectedUserIds: string[];
  onUserSelectionChange: (userId: string, isSelected: boolean) => void;
}

export const PickUsersPopinComponent: FunctionComponent<IPickUsersPopinComponentProps> = ({
  children,
  roomId,
  channelId,
  isVisible,
  onCloseTooltip,
  onUserSelectionChange,
  selectedUserIds,
}) => {
  return (
    <TooltipComponent
      placement="bottom-start"
      visible={isVisible}
      onClickOutside={onCloseTooltip}
      arrow={false}
      interactive
      noPadding
      content={
        <PickUsersPopinContentComponent
          isVisible={isVisible}
          roomId={roomId}
          channelId={channelId}
          onUserSelectionChange={onUserSelectionChange}
          selectedUserIds={selectedUserIds}
        />
      }
    >
      {children}
    </TooltipComponent>
  );
};
