import { type ICalendarEvent, type IEventDatetime } from "@/api/client";
import {
  type DragAndDropTimelineCalendarPropsType,
  type EventTimelineEvent,
  type TimelineCalendarPropsType,
} from "@/components/shared/calendar/EventTimelineTypes";
import { getDateByEventDateTime } from "@/utils/date";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import { enUS, fr } from "date-fns/locale";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { useCallback, useMemo } from "react";
import { dateFnsLocalizer } from "react-big-calendar";

const locales = { "fr-FR": fr, "en-US": enUS };

export const useEventTimeline = ({
  end,
  events,
  newEventSummary,
  start,
  onEventDateChange,
}: {
  events: ICalendarEvent[];
  newEventSummary: string;
  start: IEventDatetime;
  end: IEventDatetime | null;
  onEventDateChange: (start: Date, end: Date | null, isAllDay?: boolean) => void;
}) => {
  const newEvent: EventTimelineEvent = useMemo(() => {
    return {
      allDay: end === null,
      isDraggable: true,
      id: "new",
      title: newEventSummary,
      start: getDateByEventDateTime(start),
      end: end != null ? getDateByEventDateTime(end) : getDateByEventDateTime(start),
    };
  }, [start, end, newEventSummary]);
  const defaultDate = useMemo(() => new Date(), []);

  const currentEvents = useMemo(() => {
    return events.map<EventTimelineEvent>((event, index) => {
      const start = event.start?.date ?? event.start?.dateTime ?? "";
      const end = event.end?.date ?? event.end?.dateTime ?? "";
      return {
        id: index.toString(),
        title: event.summary ?? "",
        allDay: event.start?.date != null,
        start: new Date(start),
        end: new Date(end),
        isDraggable: false,
      };
    });
  }, [events]);

  const allEvents = useMemo(() => (newEvent != null ? [...currentEvents, newEvent] : currentEvents), [currentEvents, newEvent]);

  const eventPropGetter: TimelineCalendarPropsType["eventPropGetter"] = useCallback(
    (event: Parameters<NonNullable<TimelineCalendarPropsType["eventPropGetter"]>>[0]) => {
      return {
        className: event.isDraggable ? "bg-sky-600" : "bg-zinc-400 cursor-default",
      };
    },
    [],
  );

  const localizer = useMemo(
    () =>
      dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales,
      }),
    [format, parse, startOfWeek, getDay, locales],
  );

  const onEventMoved: DragAndDropTimelineCalendarPropsType["onEventDrop"] = useCallback(
    ({ event, start, end, isAllDay = false }: Parameters<NonNullable<DragAndDropTimelineCalendarPropsType["onEventDrop"]>>[0]) => {
      if (event.id !== "new") {
        return;
      }
      onEventDateChange(new Date(start), new Date(end), isAllDay);
    },
    [],
  );

  const onEventResize: DragAndDropTimelineCalendarPropsType["onEventResize"] = useCallback(
    ({ start, end }: Parameters<NonNullable<DragAndDropTimelineCalendarPropsType["onEventResize"]>>[0]) => {
      onEventDateChange(new Date(start), new Date(end));
    },
    [],
  );

  const onSelectSlot: TimelineCalendarPropsType["onSelectSlot"] = useCallback(
    ({ start, end }: Parameters<NonNullable<TimelineCalendarPropsType["onSelectSlot"]>>[0]) => {
      onEventDateChange(new Date(start), new Date(end));
    },
    [],
  );

  const formats: TimelineCalendarPropsType["formats"] = useMemo(
    () => ({
      timeGutterFormat: (date, culture, localizer) => localizer?.format(date, "HH:mm", culture) ?? "",
      eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
        `${localizer?.format(start, "HH:mm", culture) ?? ""} - ${localizer?.format(end, "HH:mm", culture) ?? ""}`,
    }),
    [],
  );

  const draggableAccessor = (event: EventTimelineEvent) => event.isDraggable;

  return { defaultDate, eventPropGetter, allEvents, formats, onSelectSlot, onEventMoved, onEventResize, localizer, draggableAccessor };
};
