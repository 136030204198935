import { Quill } from "react-quill";

// @ts-expect-error Seams to be linked to quill
const Link = Quill.import("formats/link");
// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = ["http", "https", "mailto", "tel", "radar", "rdar", "smb", "sms"];

export class CustomLinkSanitizer extends Link {
  static sanitize(url: string) {
    // Run default sanitize method from Quill
    const sanitizedUrl: string = super.sanitize(url);

    // Not whitelisted URL based on protocol so, let's return `blank`
    if (sanitizedUrl == null || sanitizedUrl === "about:blank") return sanitizedUrl;

    // Verify if the URL already have a whitelisted protocol
    // @ts-expect-error Seams to be linked to quill
    const hasWhitelistedProtocol: boolean = this.PROTOCOL_WHITELIST.some((protocol: string) => {
      return sanitizedUrl.startsWith(protocol);
    });

    if (hasWhitelistedProtocol) return sanitizedUrl;

    // if not, then append only 'http' to not be a relative URL
    return `http://${sanitizedUrl}`;
  }
}
