import { type IDirectMessage, type IMessage, type MessageConversationDirectMessage } from "@/api/client";
import { eventNames } from "@/constants/eventNames";
import { QueryKeys } from "@/constants/queryKeys";
import { SocketEvents } from "@/constants/socketEvents";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { useEditMessageQueries } from "@/hooks/shared/useEditMessageQueries";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect } from "react";

export const useConversationSockets = () => {
  const socket = useContext(SocketContext);
  const me = useContext(AuthenticationContext);
  const { editMessageInQuery } = useEditMessageQueries();
  const queryClient = useQueryClient();

  const updateDirectMessageQuery = (oldData: IDirectMessage[] | undefined, directMessage: MessageConversationDirectMessage) => {
    if (oldData == null) {
      return oldData;
    }

    const matchingIndex = oldData.findIndex((obj) => obj.id === directMessage.id);

    if (matchingIndex !== -1) {
      const newData = [...oldData];
      const oldDM = newData[matchingIndex];
      newData.splice(matchingIndex, 1);
      newData.unshift({ ...oldDM, ...directMessage });

      return newData;
    }

    return oldData;
  };

  const putDirectMessageOnTop = (message: IMessage) => {
    if (message.conversation?.directMessage == null) {
      return;
    }
    const { directMessage } = message.conversation;
    queryClient.setQueriesData<IDirectMessage[]>(
      {
        queryKey: [QueryKeys.ROOMS, QueryKeys.DMS],
        exact: false,
      },
      (oldData) => updateDirectMessageQuery(oldData, directMessage),
    );

    queryClient.setQueryData<IDirectMessage[]>([QueryKeys.MY_DMS], (oldData) => updateDirectMessageQuery(oldData, directMessage));
  };

  const handleNewMessage = useCallback(
    async (data: IMessage) => {
      if (data.conversation?.directMessage != null) {
        putDirectMessageOnTop(data);
      }
      if (data.conversation?.id == null || me == null || (me.id === data.sender?.user.id && data.type === "default")) {
        return;
      }

      const event = new CustomEvent(eventNames.NEW_MESSAGE, {
        detail: {
          message: data,
        },
      });

      window.dispatchEvent(event);
    },
    [me],
  );

  const handleEditMessage = useCallback(async (data: IMessage) => {
    if (data.conversation == null) return;
    editMessageInQuery(data.conversation.id, data);
  }, []);

  const handlePinMessage = useCallback(async (data: IMessage) => {
    if (data.conversation == null) return;
    editMessageInQuery(data.conversation.id, data);
  }, []);

  const handleUnpinMessage = useCallback(async (data: IMessage) => {
    if (data.conversation == null) return;
    editMessageInQuery(data.conversation.id, data);
  }, []);

  useEffect(() => {
    if (socket == null) return;
    socket.on(SocketEvents.NEW_MESSAGE_SENT, handleNewMessage);
    socket.on(SocketEvents.MESSAGE_UPDATED, handleEditMessage);
    socket.on(SocketEvents.MESSAGE_PINNED, handlePinMessage);
    socket.on(SocketEvents.MESSAGE_UNPINNED, handleUnpinMessage);
    return () => {
      socket.off(SocketEvents.NEW_MESSAGE_SENT, handleNewMessage);
      socket.off(SocketEvents.MESSAGE_UPDATED, handleEditMessage);
      socket.off(SocketEvents.MESSAGE_PINNED, handlePinMessage);
      socket.off(SocketEvents.MESSAGE_UNPINNED, handleUnpinMessage);
    };
  }, [socket, queryClient]);
};
