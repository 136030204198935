import { type IEmailThread } from "@/api/client";
import { RoomContext } from "@/contexts/RoomContext";
import { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const useThreadPreview = (thread?: IEmailThread) => {
  const roomContext = useContext(RoomContext);

  const roomId = useMemo(() => {
    return roomContext?.roomResponse?.room?.id;
  }, [roomContext?.roomResponse?.room?.id]);

  const navigate = useNavigate();

  const senderName = useMemo((): string => {
    if (thread?.lastEmail?.from == null) return "";
    return thread.lastEmail?.from.name != null && thread.lastEmail?.from.name !== "" ? thread.lastEmail?.from.name : thread.lastEmail?.from.email;
  }, [thread]);

  const onThreadClick = useCallback(() => {
    if (roomId == null || thread == null) return;
    navigate(`/dashboard/rooms/${roomId}/emails/${thread.id}`);
  }, [roomId, thread]);

  return {
    onThreadClick,
    senderName,
  };
};
