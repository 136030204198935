import { type IEmail } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useEmailQuery = (emailId?: string) => {
  return useQuery<IEmail | undefined>({
    queryKey: [QueryKeys.EMAIL, emailId],
    queryFn: async () => {
      if (emailId == null) return;
      const response = await api.emails.getOneEmail(emailId, {
        defaultToastErrorMessage: null,
      });
      return response.data.data;
    },
    retry: 0,
    enabled: emailId != null,
  });
};
