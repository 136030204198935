// organize-imports-ignore
import { App } from "@/App";
import "@/style.scss";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "@/assets/styles/tooltipTheme.scss";
import "@/assets/styles/mentions.scss";
import "@/utils/i18n";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom/client";
import Hotjar from "@hotjar/browser";
import { loadUsetifulScript } from "@/services/UsetifulService";
import { loadGtagScript } from "@/services/GtagService";

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN as string,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  environment: import.meta.env.VITE_APP_ENV as string,
  tracesSampleRate: 1.0,
  release: `core-front-ts@${APP_VERSION}`,
  debug: import.meta.env.VITE_APP_ENV !== "production",
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.VITE_APP_ENV !== "local",
});

Hotjar.init(import.meta.env.VITE_APP_HOTJAR_SITE_ID, 6, {
  debug: import.meta.env.VITE_APP_ENV === "local",
});

loadUsetifulScript();
loadGtagScript();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
