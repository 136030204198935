import { useEffect, useRef } from "react";

export const useMessageActionsMenuAnimation = () => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const menu = menuRef.current;
    if (menu != null) {
      setTimeout(() => {
        menu.classList.remove("opacity-0");
        menu.classList.add("opacity-100");
        menu.classList.remove("top-1");
        menu.classList.add("top-0");
      }, 0);
    }
  }, []);

  return {
    menuRef,
  };
};
