import { RoleType } from "@/api/client";
import { AdminOrganisationRoomMemberTab } from "@/components/homePage/modals/room/tabs/AdminOrganisationRoomMemberTab";
import { AdminProjectRoomMemberTab } from "@/components/homePage/modals/room/tabs/AdminProjectRoomMemberTab";
import { UserOrganisationRoomMemberTab } from "@/components/homePage/modals/room/tabs/UserOrganisationRoomMemberTab";
import { UserProjectRoomMemberTab } from "@/components/homePage/modals/room/tabs/UserProjectRoomMemberTab";
import { roomType } from "@/constants/room";
import { useRoomQuery } from "@/hooks/queries/rooms/useRoomQuery";

export const RoomMemberTab = ({ roomId }: { roomId: string }): JSX.Element => {
  const { data: roomResponse } = useRoomQuery(roomId);

  if (roomResponse == null) return <></>;

  if (roomResponse.room.type === roomType.ORGANISATION) {
    if (roomResponse.member.role.type === RoleType.Admin || roomResponse.member.role.type === RoleType.Owner) {
      return <AdminOrganisationRoomMemberTab />;
    } else {
      return <UserOrganisationRoomMemberTab />;
    }
  } else {
    if (roomResponse.member.role.type === RoleType.Admin || roomResponse.member.role.type === RoleType.Owner) {
      return <AdminProjectRoomMemberTab member={roomResponse.member} room={roomResponse.room} />;
    } else {
      return <UserProjectRoomMemberTab member={roomResponse.member} room={roomResponse.room} />;
    }
  }
};
