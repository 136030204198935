import { type ITaskStatus, type ITaskStatusCreateBody } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
export const useCreateTaskStatusMutation = (roomId?: string) => {
  const queryClient = useQueryClient();

  const optimisticId = useMemo(() => `optimistic-${uuidv4()}`, []);
  return useMutation({
    mutationFn: async (params: ITaskStatusCreateBody) => {
      if (roomId == null) return null;

      const { data } = await api.rooms.createTaskStatus(roomId, params);
      return data;
    },
    onMutate: async (params: ITaskStatusCreateBody) => {
      const optimisticTaskStatus: ITaskStatus = {
        id: optimisticId,
        name: params.name,
        position: "0",
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      queryClient.setQueryData<ITaskStatus[]>([QueryKeys.ROOMS, roomId, QueryKeys.TASKS, QueryKeys.TASKS_STATUSES], (old) => {
        if (old == null) return [];
        return [...old, optimisticTaskStatus];
      });
    },
    onSuccess: (data) => {
      if (data == null || roomId == null) return;

      queryClient.setQueryData<ITaskStatus[]>([QueryKeys.ROOMS, roomId, QueryKeys.TASKS, QueryKeys.TASKS_STATUSES], (old) => {
        if (old == null) return [];
        return old.map((taskStatus) => (taskStatus.id === optimisticId ? data.data : taskStatus));
      });
    },
    onError: () => {
      void queryClient.invalidateQueries({
        queryKey: [QueryKeys.ROOMS, roomId, QueryKeys.TASKS, QueryKeys.TASKS_STATUSES],
      });
    },
  });
};
