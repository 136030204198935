import { type ITodoAddBody } from "@/api/client";
import { useUpdateTodoInCache } from "@/hooks/mutations/tasks/todos/useUpdateTodoInCache";
import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";

interface TodoAddBody extends ITodoAddBody {
  optimisticId: string;
}

export const useAddTodoMutation = (taskId: string) => {
  const { addOptimisticTodoInCache, addTodoInCache } = useUpdateTodoInCache();

  const mutation = useMutation({
    mutationFn: async (params: TodoAddBody) => {
      const { data } = await api.tasks.addTodo(taskId, params);

      return data.data;
    },
    onMutate: (params) => {
      addOptimisticTodoInCache(taskId, params.optimisticId, params);
    },
    onSuccess: (data, params) => {
      addTodoInCache(taskId, data, params.optimisticId);
    },
  });

  return mutation;
};
