import { SubscriptionStatus } from "@/api/client";
import { PlanName } from "@/constants/planName";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useValidateFreePlanMutation } from "@/hooks/mutations/billing/useValidateFreePlanMutation";
import { useCallback, useContext } from "react";

export const useValidateFreePlan = () => {
  const me = useContext(AuthenticationContext);
  const { mutate } = useValidateFreePlanMutation();
  const validateFreePlan = useCallback(() => {
    if (me?.mainOrganisation?.organisationSubscription?.id == null) return;
    const isFreePlan = me?.mainOrganisation?.organisationSubscription?.plan?.name === PlanName.FREE;
    const isPlanValidated = me?.mainOrganisation?.organisationSubscription?.status !== SubscriptionStatus.ToBeValidated;
    if (!isFreePlan || isPlanValidated) return;
    mutate(me.mainOrganisation.organisationSubscription.id);
  }, [me]);
  return { validateFreePlan };
};
