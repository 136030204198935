import { type ITask } from "@/api/client";
import { TaskItemComponent } from "@/components/roomPage/tabs/tasks/TaskItemComponent";
import { DraggableType } from "@/constants/task";
import { useDraggable } from "@dnd-kit/core";

interface IDraggableTaskItemComponentProps {
  task: ITask;
}

export const DraggableTaskItemComponent = ({ task }: IDraggableTaskItemComponentProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: task.id,
    data: { type: DraggableType.TASK },
  });

  return (
    <div ref={setNodeRef} {...listeners} {...attributes}>
      <TaskItemComponent task={task} isActive={transform != null} />
    </div>
  );
};
