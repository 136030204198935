import { MessageSeparatorComponent } from "@/components/roomPage/tabs/conversation/message/MessageSeparatorComponent";
import { format, isThisYear, isToday, isYesterday } from "date-fns";
import { useTranslation } from "react-i18next";

export const MessageDateSeparatorComponent = ({ date }: { date: string }): JSX.Element => {
  const { t } = useTranslation();
  const _date = new Date(date);

  return (
    <MessageSeparatorComponent
      label={
        isThisYear(_date)
          ? isYesterday(_date)
            ? t("date.yesterday")
            : isToday(_date)
            ? t("date.today")
            : format(new Date(date), t("date.fullDateFormat"))
          : format(new Date(date), t("date.fullDateFormatWithYear"))
      }
    />
  );
};
