import { type IRoomMember } from "@/api/client";
import { eventNames } from "@/constants/eventNames";
import { QueryKeys } from "@/constants/queryKeys";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { debounce } from "@/utils/utilities";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useState } from "react";

interface IMentionHoveredEvent extends Event {
  event: { target: Element };
  value: { id: string };
}

export const useMention = (roomId: string) => {
  const [isMentionHovered, setIsMentionHovered] = useState(false);
  const [userOfMentionHovered, setUserOfMentionHovered] = useState<IRoomMember>();
  const [popinPosition, setPopinPosition] = useState({ left: 0, top: 0 });
  const queryClient = useQueryClient();

  const members = queryClient.getQueryData<IRoomMember[]>([QueryKeys.ROOMS, roomId, QueryKeys.MEMBERS]);
  const me = useContext(AuthenticationContext);

  const delayedSetIsMentionHovered = useCallback(
    debounce((newValue: boolean) => {
      setIsMentionHovered(newValue);
    }, 500),
    [],
  );

  const mouseLeaveHandler = useCallback(async () => {
    delayedSetIsMentionHovered(false);
  }, []);

  const mentionHoveredHandler = (event: IMentionHoveredEvent) => {
    const mention = event.event.target.closest(".mention");
    if (members == null || me == null) return;
    if (mention == null) return;
    const userId = event.value.id;
    /* @ts-expect-error Error in client interface IRoomMember */
    setUserOfMentionHovered(members.find((member) => member.user.id === userId));
    setPopinPosition(mention.getBoundingClientRect());
    delayedSetIsMentionHovered(true);

    mention.addEventListener("mouseleave", mouseLeaveHandler);
  };
  useEffect(() => {
    window.addEventListener(eventNames.MENTION_HOVERED, (event) => {
      mentionHoveredHandler(event as IMentionHoveredEvent);
    });
    return () => {
      window.removeEventListener(eventNames.MENTION_HOVERED, (event) => {
        mentionHoveredHandler(event as IMentionHoveredEvent);
      });
    };
  }, [members]);

  return { isMentionHovered, delayedSetIsMentionHovered, userOfMentionHovered, popinPosition, me };
};
