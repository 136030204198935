import { RoomMemberStatus } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";
export const useMyRoomsQuery = () => {
  return useQuery({
    queryKey: [QueryKeys.MY_ROOMS],
    queryFn: async () => {
      const active = await api.my.getRooms();
      const archived = await api.my.getRooms({
        status: RoomMemberStatus.Archived,
      });
      return [...active.data.data, ...archived.data.data];
    },
    staleTime: 1000 * 60,
  });
};
