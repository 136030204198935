import { type IRoom, type IRoomMember } from "@/api/client";
import { useAboutTab } from "@/components/homePage/modals/room/tabs/AboutTab/useAboutTab";
import { TextAreaComponent } from "@/components/shared/form/textArea/TextAreaComponent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { PictureUploaderComponent } from "@/components/shared/uploader/pictureUploader/PictureUploaderComponent";
import { useTranslation } from "react-i18next";

interface AboutTabProps {
  room?: IRoom | undefined;
  member?: IRoomMember;
  isSuccess: boolean;
}

export const AboutTab = ({ room, member, isSuccess }: AboutTabProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    canEditName,
    tempPicture,
    actions,
    onSubmitForm,
    register,
    isCreateRoomPending,
    canUpdateRoom,
    errors,
    customRoomName,
    isDescriptionDisabled,
    footerInfos,
  } = useAboutTab({
    isSuccess,
    member,
    room,
  });

  return (
    <ModalTabComponent key={room?.id} footer={<ModalFooterComponent actions={actions} info={footerInfos}></ModalFooterComponent>}>
      <form onSubmit={onSubmitForm} className="relative flex w-full flex-col">
        {isCreateRoomPending ? (
          <div className="-ml-6 w-full">
            <LoaderComponent />
          </div>
        ) : null}
        <div className="flex w-full">
          <div className="mb-5 mr-5 flex flex-col">
            <label className="mb-1 flex items-center text-[13px] font-normal text-zinc-500">{t("createRoomModal.tabs.about.fields.roomIcon")}</label>
            <PictureUploaderComponent
              context="room"
              contextId={room?.id}
              pictureUrl={room?.roomPicture?.url ?? tempPicture ?? undefined}
              canUpdate={canUpdateRoom}
            />
          </div>
          <div className="flex w-full flex-col justify-start">
            <div>
              <TextInputComponent
                register={register}
                disabled={!canEditName}
                name="name"
                label={
                  <>
                    {t("createRoomModal.tabs.about.fields.name")}{" "}
                    {!canEditName && room != null && (
                      <span className="ml-1 text-[13px] font-medium text-zinc-500">({t("createRoomModal.tabs.about.cannotUpdate")})</span>
                    )}
                  </>
                }
                error={errors.name?.message ?? ""}
              />
            </div>

            {room?.name != null && customRoomName != null && (
              <span className="mt-1 text-[13px] text-zinc-500 opacity-50">
                {t("createRoomModal.tabs.about.originalName")} {room?.name}
              </span>
            )}
          </div>
        </div>
        <div>
          <TextAreaComponent
            disabled={isDescriptionDisabled}
            register={register}
            name="description"
            error={errors.description?.message ?? ""}
            className={`${isDescriptionDisabled ? "opacity-50" : ""}`}
            label={
              <>
                {t("createRoomModal.tabs.about.fields.description")}{" "}
                {isDescriptionDisabled && (
                  <span className="ml-1 text-[13px] font-medium text-zinc-500 opacity-50">aaa ({t("createRoomModal.tabs.about.cannotUpdate")})</span>
                )}
              </>
            }
          />
        </div>
      </form>
    </ModalTabComponent>
  );
};
