import { type IMessage } from "@/api/client";
import { SystemMessageSubtypesEnum } from "@/constants/messages";

export type SystemMessageSubtypes = NonNullable<IMessage["subtype"]>;

export const usersSystemMessageSubtypes: SystemMessageSubtypes[] = [
  SystemMessageSubtypesEnum.USERS_JOIN_ROOM,
  SystemMessageSubtypesEnum.USERS_LEAVE_ROOM,
  SystemMessageSubtypesEnum.USERS_JOIN_CHANNEL,
  SystemMessageSubtypesEnum.USERS_LEAVE_CHANNEL,
];

export const allowedSystemMessageSubtypes: SystemMessageSubtypes[] = [SystemMessageSubtypesEnum.NEW_VIDEOCONFERENCE, ...usersSystemMessageSubtypes];
