import { type ITodoUpdateBody } from "@/api/client";
import { useUpdateTodoInCache } from "@/hooks/mutations/tasks/todos/useUpdateTodoInCache";
import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";

interface UpdateTodoBody extends ITodoUpdateBody {
  todoId: string;
}

export const useUpdateTodoMutation = (taskId: string) => {
  const { updateTodoInCache } = useUpdateTodoInCache();

  const mutation = useMutation({
    mutationFn: async ({ todoId, ...params }: UpdateTodoBody) => {
      await api.tasks.updateTodo(taskId, todoId, params);
    },
    onMutate: (params) => {
      updateTodoInCache(taskId, params.todoId, params);
    },
  });

  return mutation;
};
