import { type IRoomIdName } from "@/api/client";
import { useMyRoomsChannelsQuery } from "@/hooks/queries/channels/useMyRoomsChannelsQuery";
import { useSearchChannelsQuery } from "@/hooks/queries/channels/useSearchChannelsQuery";
import { type IChannelIdNameRoom } from "@/interfaces/channel";
import { useEffect, useMemo, useRef, useState } from "react";
import removeAccents from "remove-accents";

export const useChannelPickerPopinContent = (room: IRoomIdName | null, isVisible: boolean) => {
  const [search, setSearch] = useState<string>("");
  const ref = useRef<HTMLInputElement>(null);
  const { data: roomChannelsData, isLoading } = useMyRoomsChannelsQuery(room?.id ?? undefined, "confirmed");
  const { data: searchChannelsData } = useSearchChannelsQuery(search, room == null);

  useEffect(() => {
    if (isVisible) {
      ref.current?.focus();
    }
  }, [isVisible]);

  const channels: IChannelIdNameRoom[] = useMemo(() => {
    if (room == null) {
      return (searchChannelsData ?? []).map((channel) => ({
        ...channel,
        name: channel.name ?? "",
      }));
    }
    return (
      roomChannelsData?.map((channelMember) => ({
        id: channelMember.channel.id,
        name: channelMember.channel.name,
        room,
      })) ?? []
    )
      .filter((channel) => {
        return removeAccents(channel.name.toLowerCase()).includes(removeAccents(search.toLowerCase()));
      })
      .sort((a: IChannelIdNameRoom, b: IChannelIdNameRoom) => {
        const localCompare = a.name.localeCompare(b.name);

        if (localCompare === 0) {
          return (a.room.name ?? "").localeCompare(b.room.name ?? "");
        }

        return localCompare;
      });
  }, [roomChannelsData, searchChannelsData, search, room]);

  const onSearchChange = (value: string) => {
    setSearch(value);
  };

  return {
    channels,
    isLoading,
    search,
    onSearchChange,
    ref,
  };
};
