import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useMeQuery = () => {
  const STALE_TIME = 15000;
  return useQuery({
    queryKey: [QueryKeys.ME],
    queryFn: async () => {
      // Will be changed when the backend will send specific error types
      const response = await api.me.getMe({ defaultToastErrorMessage: null });
      return response.data;
    },
    staleTime: STALE_TIME,
    refetchOnWindowFocus: true,
  });
};
