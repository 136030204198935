import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useBatchRoomInviteMutation = (roomId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ emails }: { emails: string[] }) => {
      return await api.rooms.inviteMembers(roomId, {
        emails,
      });
    },
    onSuccess: (data) => {
      toast.success(t("createRoomModal.tabs.members.admin.invites.batch.success", { count: data.data.successes.length }));
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, roomId, QueryKeys.PENDING] });
    },
  });
};
