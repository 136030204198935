import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useRoomActiveMemberQuery = (roomId: string | undefined, enabled?: boolean) => {
  return useQuery({
    queryKey: [QueryKeys.ROOMS, roomId, QueryKeys.MEMBERS],
    queryFn: async () => {
      if (roomId == null) return [];
      const response = await api.rooms.getAllActiveMembers(roomId);
      return response.data.data;
    },
    enabled,
  });
};
