import RogerLogo from "@/assets/images/logo-roger.svg?react";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { useGoogleSignIn } from "@/hooks/authentification/useGoogleSignIn";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

export const GoogleSigninPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const scope = searchParams.get("scope") ?? "";

  const { signIn } = useGoogleSignIn({
    onSuccess: (code) => {
      navigate(`/google/redirect?code=${code}&scope=${scope}`);
    },
    uxMode: "redirect",
    redirectUri: import.meta.env.VITE_APP_GOOGLE_REDIRECT_URL,
    scope,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      signIn();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="flex h-screen flex-col items-center justify-center ">
      <div className="w-10/12 sm:w-6/12 lg:w-4/12 xl:w-3/12">
        <h1 className="mb-8 flex cursor-pointer flex-col items-center justify-center">
          <RogerLogo className="mr-4 h-12 w-12" />
          <span className="pb-2.5 font-[rubik] text-7xl text-black dark:text-white">roger</span>
          <span className="my-4 font-[rubik] text-lg text-white ">{t("google.signin.description")}</span>
          <ButtonComponent status="primary" type="button" onClick={signIn}>
            {t("google.signin.button")}
          </ButtonComponent>
        </h1>
      </div>
    </div>
  );
};
