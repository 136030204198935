import { type IRoomMembersPendingResponse } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useRoomInvitesQuery = (roomId: string | null) => {
  return useQuery<IRoomMembersPendingResponse>({
    queryKey: [QueryKeys.ROOMS, roomId, QueryKeys.PENDING],
    queryFn: async () => {
      if (roomId == null) return { invitations: [], requests: [] };
      const response = await api.rooms.getAllPendingMembers(roomId);
      return response.data;
    },
  });
};
