import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useOrganisationInvitesQuery = (organisationID: string | undefined) => {
  return useQuery({
    queryKey: [QueryKeys.ORGANISATIONS, organisationID, QueryKeys.PENDING],
    queryFn: async () => {
      if (organisationID == null) return { invitations: [] };
      const response = await api.organisations.getAllPendingMembers(organisationID);
      return response.data;
    },
  });
};
