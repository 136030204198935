import { ConversationMemberStatus } from "@/api/client";
import DMsIcon from "@/assets/images/iconsax/ChatBubbles-Linear-32px.svg?react";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { RoundedContainerComponent } from "@/components/shared/layout/RoundedContainerComponent";
import { UserInfoComponent } from "@/components/shared/user/UserInfoComponent";
import { ConversationContext } from "@/contexts/ConversationContext";
import { useCreateOrOpenDmMutation } from "@/hooks/mutations/conversations/useCreateOrOpenDmMutation";
import { useUserQuery } from "@/hooks/queries/shared/user/useUserQuery";
import { useCallback, useContext, type HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";

interface IProfilePopinProps extends HTMLAttributes<HTMLDivElement> {
  userId: string;
  roomId?: string;
}

export const ProfilePopinComponent = ({ userId, roomId, ...props }: IProfilePopinProps): JSX.Element => {
  const { data: userData } = useUserQuery(userId);
  const { t } = useTranslation();
  const { mutateAsync: createOrOpenDmMutation } = useCreateOrOpenDmMutation();
  const conversationContext = useContext(ConversationContext);
  const goToConversation = useCallback(async () => {
    const recipients = [userId];
    await createOrOpenDmMutation({ recipients, roomId });
  }, [roomId, userId]);
  if (userData == null || conversationContext?.member?.status === ConversationMemberStatus.Archived) return <></>;
  return (
    <Portal node={document.getElementById("global-wrapper")}>
      <div {...props}>
        <RoundedContainerComponent
          hasPadding={false}
          onClick={goToConversation}
          className="rounded-md shadow-md-centered dark:border dark:border-dark-700"
        >
          <UserInfoComponent user={userData} showDetails></UserInfoComponent>
          {userData.isCollaborator ? (
            <div className="px-3 pb-3">
              <ButtonComponent
                icon={<DMsIcon className="gradient-icon stroke h-4 w-4 stroke-2" />}
                status="secondary"
                size="sm"
                align="center"
                className="mt-4 flex w-full "
                onClick={goToConversation}
              >
                {t("profilePopin.openConversation")}
              </ButtonComponent>
            </div>
          ) : (
            <div className="pb-3" />
          )}
        </RoundedContainerComponent>
      </div>
    </Portal>
  );
};
