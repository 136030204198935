import { useBadge } from "@/hooks/shared/useBadge";
import { type HTMLAttributes } from "react";
import { BsBellSlash } from "react-icons/bs";
import { NavLink } from "react-router-dom";

interface INavLinkElementComponentProps extends HTMLAttributes<HTMLDivElement> {
  to: string;
  badge?: number;
  isHighlighted?: boolean;
  isDragging?: boolean;
  closeList?: (event: Event) => void;
  label: string;
  showDisabledBell?: boolean;
  iconElement?: JSX.Element;
}

export const NavLinkElementComponent = ({
  closeList = () => {},
  isHighlighted = false,
  badge = 0,
  isDragging = false,
  label,
  showDisabledBell = false,
  to,
  iconElement,
  ...props
}: INavLinkElementComponentProps): JSX.Element => {
  const { getComputedBadgeNumber } = useBadge();

  return (
    <>
      <div
        className={`group/channel-list relative flex cursor-pointer items-center justify-start dark:hover:bg-dark-800 ${
          isHighlighted ? "font-bold" : ""
        }${isDragging ? "pointer-events-none" : ""} ${props.className ?? ""}`}
      >
        <NavLink
          className={({ isActive }) => {
            return `dark:text-dark-300 no-underline truncate h-full w-full py-2 pl-2 pr-8  mx-2 my-[1px] rounded-lg flex items-center relative ${
              isActive
                ? "active bg-sky-600 text-white"
                : "inactive text-zinc-800  group-hover/channel-list:bg-zinc-100 group-hover/channel-list:text-black"
            }`;
          }}
          onClick={(event) => {
            closeList(event.nativeEvent);
          }}
          to={to}
        >
          {/* Make a component for that */}
          {badge != null && badge > 0 ? (
            <div className="translate absolute right-1 top-1/2 z-50 mr-2 inline-flex -translate-y-1/2 items-center justify-center rounded-full px-2 py-1 text-xs font-bold leading-none [.active_&]:bg-white [.active_&]:text-sky-600  [.inactive_&]:bg-sky-600 [.inactive_&]:text-white">
              {getComputedBadgeNumber(badge)}
            </div>
          ) : null}
          <div className="relative flex w-7 shrink-0">
            <span className="-ml-1 mr-2">{iconElement}</span>
          </div>
          <span className="mr-2 truncate">{label}</span>
          {showDisabledBell && (
            <div className="ml-2 flex h-3 w-3 items-center">
              <BsBellSlash />
            </div>
          )}
        </NavLink>
      </div>
    </>
  );
};
