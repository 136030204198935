import { type IDirectMessage } from "@/api/client";
import { ConversationListComponent } from "@/components/roomPage/tabs/conversation/ConversationLists/ConversationListComponent";
import { useDmList } from "@/components/roomPage/tabs/conversation/ConversationLists/useDmList";
import { useTranslation } from "react-i18next";

interface IChannelListComponentProps {
  dms: IDirectMessage[];
  isOnHomePage: boolean;
}

export const DmListComponent = ({ dms, isOnHomePage }: IChannelListComponentProps): JSX.Element => {
  const { addDm, closeChannelList } = useDmList();
  const { t } = useTranslation();
  return (
    <ConversationListComponent
      title={t("general.dms", { count: dms.length })}
      conversations={dms}
      addConversation={addDm}
      closeList={closeChannelList}
      scope="dm"
      isOnHomePage={isOnHomePage}
    />
  );
};
