import { type IApiRoomGetOne, type IChannelMember } from "@/api/client";
import { createContext } from "react";

interface IRoomContextValue {
  roomResponse: IApiRoomGetOne | null;
  channels: IChannelMember[] | null;
  channelStatusSelected: string;
}

const defaultContextValue = { roomResponse: null, channels: null, channelStatusSelected: "" };

const RoomContext = createContext<IRoomContextValue>(defaultContextValue);

export { RoomContext };
