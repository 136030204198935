import { ListOfTranslatedKeysComponent } from "@/components/shared/i18n/ListOfTranslatedKeysComponent";
import { RiUserSettingsFill } from "react-icons/ri";
import { useParams } from "react-router-dom";

export const SignUpError = (): JSX.Element => {
  const params = useParams();
  const organisationName = params.organisationName ?? "";
  const error = params.error ?? "";
  return (
    <div className="flex w-full flex-col items-center">
      <RiUserSettingsFill className="-mt-2 h-14 w-14 text-rogerBlue-500" />
      <div className="mt-4 text-sm text-zinc-500">
        <ListOfTranslatedKeysComponent keyString={`authentication.signUpError.${error}`} showBullet={false} params={{ organisationName }} />
      </div>
    </div>
  );
};
