import { useEffect, useState } from "react";

export function useIsFullScreen() {
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (!window.IS_ELECTRON) return;
    const enterFullScreen = () => {
      setIsFullScreen(true);
    };

    const exitFullScreen = () => {
      setIsFullScreen(false);
    };

    window.electron.on("entered-full-screen", enterFullScreen);
    window.electron.on("left-full-screen", exitFullScreen);

    // Cleanup function
    return () => {
      window.electron.removeListener("entered-full-screen", enterFullScreen);
      window.electron.removeListener("left-full-screen", exitFullScreen);
    };
  }, []);

  return { isFullScreen };
}
