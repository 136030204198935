import { type GoogleTargetFeatureEnum } from "@/api/client";
import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";

export const useGoogleLogoutMutation = (onSuccess?: () => void) => {
  return useMutation({
    mutationFn: async ({ targetFeature }: { targetFeature: GoogleTargetFeatureEnum }) => {
      await api.google.googleLogout({
        targetFeature,
      });
    },
    onSuccess,
  });
};
