import { type IEmail } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useEmailsQuery = (threadId?: string) => {
  return useQuery<IEmail[]>({
    queryKey: [QueryKeys.EMAIL_THREAD, QueryKeys.EMAILS, threadId],
    queryFn: async () => {
      if (threadId == null) return [];
      const response = await api.emails.getOneThreadEmails(threadId, {
        defaultToastErrorMessage: null,
      });
      return response.data.data;
    },
    retry: 0,
    enabled: threadId != null,
  });
};
