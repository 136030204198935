import type { ITag, TagColorEnum } from "@/api/client";
import { closeModal, openModal } from "@/components/shared/modal/ModalService";
import { TagDeletionConfirm } from "@/components/shared/tagInput/tagEditModal/TagDeletionConfirm";
import { ModalNames } from "@/constants/modalNames";
import { TAGS_COLORS, TAGS_DEFAULT_COLOR } from "@/constants/task";
import { useCreateTagMutation } from "@/hooks/mutations/tasks/tags/useCreateTagMutation";
import { useDeleteTagMutation } from "@/hooks/mutations/tasks/tags/useDeleteTagMutation";
import { useUpdateTagMutation } from "@/hooks/mutations/tasks/tags/useUpdateTagMutation";
import { type IModalAction } from "@/interfaces/modal";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
interface IColor {
  name: TagColorEnum;
  backgroundColor: string;
  color: string;
}
export const useTagInputModalContent = (isCreation = false, roomId: string, tag?: ITag, taskId?: string) => {
  const { t } = useTranslation();
  const colors: IColor[] = Object.entries(TAGS_COLORS).map<IColor>(([name, color]) => ({ name: name as TagColorEnum, ...color }));
  const [selectedColor, setSelectedColor] = useState<TagColorEnum>(tag?.color ?? TAGS_DEFAULT_COLOR);
  const [labelName, setLabelName] = useState<string>(tag?.name ?? "");
  const { mutate: createTag } = useCreateTagMutation();
  const { mutate: updateTag } = useUpdateTagMutation(tag?.id ?? "", roomId ?? "", taskId);
  const { mutateAsync: deleteTag } = useDeleteTagMutation(tag?.id ?? "", roomId ?? "", taskId);

  const modalActions: IModalAction[] = useMemo(
    () => [
      {
        action: async () => {
          if (tag?.id == null || roomId == null || taskId == null) return;
          openModal(ModalNames.CONFIRM_TAG_DELETION, <TagDeletionConfirm tagId={tag.id} roomId={roomId} taskId={taskId} />);
        },
        label: t("editTaskModal.editTagModal.deleteTag.button"),
        type: "dangerSecondary",
        disabled: false,
      },
      {
        action: () => {
          if (isCreation) createTag({ name: labelName, color: selectedColor, roomId });
          else updateTag({ name: labelName, color: selectedColor });
          closeModal(ModalNames.TAG_EDIT);
        },
        label: isCreation ? t("editTaskModal.editTagModal.createTag.button") : t("editTaskModal.editTagModal.updateTag.button"),
        type: "primary",
        disabled: false,
      },
    ],
    [isCreation, labelName, selectedColor, roomId],
  );

  return {
    colors,
    selectedColor,
    setSelectedColor,
    labelName,
    setLabelName,
    createTag,
    updateTag,
    deleteTag,
    modalActions,
  };
};
