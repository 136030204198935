import {
  type IChannelMember,
  type IConversationNotificationSettings,
  type INotificationSettingsGetByRoomResponse,
  type IProfile,
  type IUser,
  type UserActivityStatus,
} from "@/api/client";
import { SubscriptionUpdatedModalConfirm } from "@/components/billing/modals/subscriptionUpdated/SubscriptionUpdatedModalConfirm";
import { useConversationSockets } from "@/components/roomPage/tabs/conversation/useConversationSockets";
import { openModal } from "@/components/shared/modal/ModalService";
import { ModalNames } from "@/constants/modalNames";
import { QueryKeys } from "@/constants/queryKeys";
import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { useEmailsSocket } from "@/hooks/emails/useEmailsSocket";
import { useOrganisationSocket } from "@/hooks/organisation/useOrganisationSocket";
import { useRoomsSocket } from "@/hooks/rooms/useRoomsSocket";
import { useSettingsSocket } from "@/hooks/settings/useSettingsSocket";
import { useUpdateUserActivityStatus } from "@/hooks/shared/useUpdateUserActivityStatus";
import { useTasksSocket } from "@/hooks/tasks/useTasksSocket";
import { type ISubscriptionUpdatedData } from "@/interfaces/billing";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, type PropsWithChildren } from "react";

export const GlobalSockets = ({ children }: PropsWithChildren): JSX.Element => {
  const socket = useContext(SocketContext);
  const queryClient = useQueryClient();

  useUpdateUserActivityStatus();
  useConversationSockets();
  useTasksSocket();
  useSettingsSocket();
  useEmailsSocket();
  useRoomsSocket();
  useOrganisationSocket();

  const onConversationNotificationSettingsUpdated = ({ id, notificationSettings }: IConversationNotificationSettings) => {
    queryClient.setQueriesData<INotificationSettingsGetByRoomResponse>(
      { queryKey: [QueryKeys.ROOMS, QueryKeys.NOTIFICATION_SETTINGS], exact: false },
      (oldData) => {
        if (oldData == null) {
          return oldData;
        }

        return {
          ...oldData,
          conversationNotificationSettings: oldData.conversationNotificationSettings.map((conversationSettings) =>
            conversationSettings.id === id
              ? {
                  ...conversationSettings,
                  notificationSettings: {
                    ...conversationSettings.notificationSettings,
                    ...notificationSettings,
                  },
                }
              : conversationSettings,
          ),
        };
      },
    );
  };

  const onSubscriptionUpdated = (data: ISubscriptionUpdatedData) => {
    openModal(ModalNames.SUBSCRIPTION_UPDATED, <SubscriptionUpdatedModalConfirm data={data} />);
  };

  const onUserUpdated = ({ user }: { user: { id: string; activityStatus: UserActivityStatus } }) => {
    queryClient.setQueryData<IProfile>([QueryKeys.USERS, user.id], (userProfile) => {
      if (userProfile == null) {
        return userProfile;
      }

      return { ...userProfile, activityStatus: user.activityStatus };
    });
    queryClient.setQueryData<IUser>([QueryKeys.ME], (userProfile) => {
      if (userProfile?.id === user.id) {
        return { ...userProfile, activityStatus: user.activityStatus };
      }

      return userProfile;
    });
  };

  const onChannelsUpdated = useCallback(
    ({ channelMembers, room: { id } }: { channelMembers: IChannelMember[]; room: { id: string } }) => {
      queryClient.setQueryData<IChannelMember[]>([QueryKeys.ROOMS, id, QueryKeys.CHANNELS, QueryKeys.CONFIRMED], channelMembers);
    },
    [queryClient],
  );

  useEffect(() => {
    if (socket == null) return;
    socket.on(SocketEvents.CONVERSATION_NOTIFICATION_SETTINGS_UPDATED, onConversationNotificationSettingsUpdated);
    socket.on(SocketEvents.SUBSCRIPTION_UPDATED, onSubscriptionUpdated);
    socket.on(SocketEvents.USER_UPDATE, onUserUpdated);
    socket.on(SocketEvents.CHANNELS_UPDATED, onChannelsUpdated);
    return () => {
      socket.off(SocketEvents.CONVERSATION_NOTIFICATION_SETTINGS_UPDATED, onConversationNotificationSettingsUpdated);
      socket.off(SocketEvents.SUBSCRIPTION_UPDATED, onSubscriptionUpdated);
      socket.off(SocketEvents.USER_UPDATE, onUserUpdated);
      socket.off(SocketEvents.CHANNELS_UPDATED, onChannelsUpdated);
    };
  }, [socket]);
  return <>{children}</>;
};
