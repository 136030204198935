import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { useAssigneUserToTaskPopinContent } from "@/components/shared/tasks/AssigneUserToTaskPopinComponent/useAssigneUserToTaskPopinContent";
import { UserItemComponent } from "@/components/shared/user/UserItemComponent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BsCheck as CheckIcon } from "react-icons/bs";

interface IAssigneUserToTaskPopinContentComponentProps {
  taskId: string;
  roomId: string | null;
  channelId: string | null;
  isVisible: boolean;
}

export const AssigneUserToTaskPopinContentComponent: FunctionComponent<IAssigneUserToTaskPopinContentComponentProps> = ({
  taskId,
  roomId,
  channelId,
  isVisible,
}) => {
  const { t } = useTranslation();
  const { users, isLoading, onSearchChange, ref, search, onAssigneeChange, selectedUserIds } = useAssigneUserToTaskPopinContent(
    taskId,
    roomId,
    channelId,
    isVisible,
  );

  return isLoading ? (
    <LoaderComponent />
  ) : (
    <div className="w-52 cursor-pointer" data-no-dnd>
      <div className="w-full p-2">
        <TextInputComponent
          ref={ref}
          value={search}
          onChange={(e) => {
            onSearchChange(e.target.value);
          }}
          placeholder={t("tasks.assignee.search.placeholder")}
        />
      </div>
      <div className="max-h-32 w-full overflow-scroll">
        {users.map((user) => (
          <UserItemComponent
            key={user.id}
            user={user}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onAssigneeChange(user);
            }}
            RightIcon={selectedUserIds.includes(user.id) ? <CheckIcon className="h-4 w-4" /> : undefined}
          />
        ))}
      </div>
    </div>
  );
};
