import "@/App.css";

import GradientSvg from "@/assets/images/gradient.svg?react";
import { UpdateComponent } from "@/components/electron/update/UpdateComponent";
import { useEmojiMart } from "@/hooks/shared/useEmojiMart";
import { useErrorInterceptor } from "@/hooks/shared/useErrorInterceptor";
import { useOnlineToast } from "@/hooks/shared/useOnlineToast";
import { router } from "@/routes";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { setDefaultOptions } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import { Suspense } from "react";
import { ToastBar, Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { BsExclamationCircleFill as ErrorIcon, BsCheckCircleFill as SuccessIcon } from "react-icons/bs";
import { RouterProvider } from "react-router-dom";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60, // 60 minutes
      gcTime: 1000 * 60 * 60, // 60 minutes
    },
  },
});
// Needed to handle full height issue in safari iOS. Maybe find a better solution and a better place to put this.
// https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
// The css variable is used in the style.scss file

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};
window.addEventListener("resize", appHeight);
appHeight();

export function App(): JSX.Element {
  const locales = { "fr-FR": fr, "en-US": enUS };
  const { i18n } = useTranslation();
  const { emojiMartInit } = useEmojiMart();

  setDefaultOptions({ locale: locales[i18n.language as keyof typeof locales] });

  useErrorInterceptor();
  useOnlineToast();
  emojiMartInit();
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_APP_GOOGLE_CLIENT_ID}>
        <Suspense fallback={<></>}>
          <div id="global-wrapper">
            <RouterProvider router={router} />
            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
            <Toaster
              position="top-right"
              toastOptions={{
                style: {
                  fontSize: "0.9rem",
                  display: "flex",
                  alignItems: "flex-start",
                  border: "1px solid",
                  borderColor: "#e4e4e7",
                  boxShadow: "0 0 10px #00000014",
                  padding: "4px 6px 4px 10px",
                },

                success: {
                  icon: (
                    <div className="mt-[0.33rem] h-4 w-4">
                      <SuccessIcon className="h-4 w-4 text-green-500" />
                    </div>
                  ),
                },
                error: {
                  icon: (
                    <div className="mt-[0.33rem] h-4 w-4">
                      <ErrorIcon className="h-4 w-4 text-red-400" />
                    </div>
                  ),
                },
              }}
            >
              {/* @ts-expect-error style is badly typed */}
              {(toast) => <ToastBar toast={{ ...toast, ariaProps: { style: { margin: "5px 8px" } } }} />}
            </Toaster>
          </div>
          {/* Required to have SVG gradient stroke and fill on icons */}
          <GradientSvg />
        </Suspense>
      </GoogleOAuthProvider>
      {window.IS_ELECTRON && <UpdateComponent />}
    </QueryClientProvider>
  );
}
