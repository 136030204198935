import { EMAIL_REGEXP } from "@/constants/regex";
import { BulkInviteContext } from "@/contexts/BulkInviteContextProvider";
import { useCallback, useContext } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useBulkInvite = (mailsAlreadyInvited: string[]) => {
  const { mails: pastedMails, setMails: setPastedMails } = useContext(BulkInviteContext);
  const { t } = useTranslation();

  const parseStringForMails = useCallback(
    (text: string) => {
      const extractedEmails: string[] = text.match(EMAIL_REGEXP) ?? [];
      const uniqueEmails: string[] = extractedEmails.filter((email, index, self) => self.indexOf(email) === index);
      return uniqueEmails?.filter((email) => !mailsAlreadyInvited.includes(email));
    },
    [mailsAlreadyInvited],
  );

  /* @ts-expect-error seems to have a typing error with clipboardEvent */
  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    if (e.clipboardData == null) return;
    const parsedMails = parseStringForMails(e.clipboardData.getData("text"));
    if (parsedMails != null) {
      const newMailsWithoutDuplicates = parsedMails.filter((newMail) => !pastedMails.includes(newMail));
      const output = [...pastedMails, ...newMailsWithoutDuplicates];
      if (output.length > pastedMails.length) {
        toast.success(`${t("roomModal.members.organisation.admin.invites.pasted.success", { count: output.length - pastedMails.length })}`);
      } else {
        toast(t("roomModal.members.organisation.admin.invites.pasted.duplicates"));
      }
      setPastedMails([...pastedMails, ...newMailsWithoutDuplicates]);
    }
    e.preventDefault();
  };

  return {
    setPastedMails,
    handlePaste,
    pastedMails,
  };
};
