import { MouseSensor as LibMouseSensor } from "@dnd-kit/core";
import type { MouseEvent } from "react";

const shouldHandleEvent = (element: HTMLElement | null) => {
  let cur = element;

  while (cur != null) {
    if (cur.dataset?.noDnd != null) {
      return false;
    }
    cur = cur.parentElement;
  }

  return true;
};

export class MouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: "onMouseDown" as const,
      handler: ({ nativeEvent: event }: MouseEvent) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}
