import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { OrganisationMemberComponent } from "@/components/shared/members/OrganisationMemberComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useOrganisationActiveMemberQuery } from "@/hooks/queries/organisations/useOrganisationActiveMemberQuery";
import { useSearchInput } from "@/hooks/shared/useSearchInput";
import { filterMembersBySearch } from "@/utils/utilities";
import { useContext, useMemo, type FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";

export const UserOrganisationRoomMemberTab: FunctionComponent = () => {
  const { search, updateSearch } = useSearchInput();
  const me = useContext(AuthenticationContext);
  const { t, i18n } = useTranslation();

  const { data: members } = useOrganisationActiveMemberQuery(me?.mainOrganisation?.organisationId);

  const filteredMembers = useMemo(() => {
    return filterMembersBySearch(members, search);
  }, [members, search]);

  return (
    <ModalTabComponent hasPadding={false}>
      <>
        <div className="mb-4 px-6 py-4 pb-0">
          <TextInputComponent
            value={search}
            onChange={(e) => {
              updateSearch(e.target.value);
            }}
            placeholder={t("roomModal.members.organisation.user.searchPlaceholder")}
          />
        </div>
        <div>
          <h3 className="mx-6 mb-1 mt-2 flex items-center text-sm font-medium text-zinc-500">
            <Trans
              i18nKey="roomModal.members.organisation.members.subtitle"
              i18n={i18n}
              values={{ count: filteredMembers.length }}
              components={{ active: <span className="ml-1 text-sky-600" /> }}
            />
          </h3>

          <div className="mb-4">{filteredMembers?.map((member) => <OrganisationMemberComponent member={member} key={member.userId} />)}</div>
        </div>
      </>
    </ModalTabComponent>
  );
};
