import { RoleType } from "@/api/client";
import { PlansTabComponent } from "@/components/billing/modals/selectPlanModal/tabs/PlansTabComponent";
import { AboutTab } from "@/components/homePage/modals/room/tabs/AboutTab/AboutTab";
import { OrganisationSettingsTabComponent } from "@/components/homePage/modals/room/tabs/OrganisationSettingTab/OrganisationSettingsTabComponent";
import { RoomMemberTab } from "@/components/homePage/modals/room/tabs/RoomMemberTab";
import { RoomNotificationSettingsTabComponent } from "@/components/homePage/modals/room/tabs/RoomNotificationSettingsTabComponent";
import { ModalLayoutComponent } from "@/components/shared/modal/ModalLayoutComponent";
import { eventNames } from "@/constants/eventNames";
import { ModalNames } from "@/constants/modalNames";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { BulkInviteContextProvider } from "@/contexts/BulkInviteContextProvider";
import { useMyOrganisationQuery } from "@/hooks/queries/organisations/useMyOrganisationQuery";
import { useRoomQuery } from "@/hooks/queries/rooms/useRoomQuery";
import { PreferencesContext } from "@/hooks/shared/usePreferences";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type RoomModalDefaultTabs = "about" | "members" | "settings" | "plans";

interface ICreateRoomModalProps {
  title?: string;
  roomId?: string;
  defaultTab?: RoomModalDefaultTabs;
  isOrganisationRoom?: boolean;
}

export const RoomModal = ({ title, roomId = undefined, defaultTab = "about", isOrganisationRoom = false }: ICreateRoomModalProps): JSX.Element => {
  const { t } = useTranslation();

  const me = useContext(AuthenticationContext);

  const [currentRoomId, setCurrentRoomId] = useState<string | undefined>(undefined);
  useEffect(() => {
    setCurrentRoomId(roomId);
  }, [roomId]);

  const [activeTab, setActiveTab] = useState<RoomModalDefaultTabs>(defaultTab);
  const { data: roomResponse, isSuccess } = useRoomQuery(currentRoomId);
  const { data: myOrganisation } = useMyOrganisationQuery();

  const room = useMemo(() => {
    if (isOrganisationRoom && myOrganisation?.organisationalRoom != null) {
      return myOrganisation.organisationalRoom;
    }
    return roomResponse?.room;
  }, [roomResponse, isOrganisationRoom, myOrganisation]);

  const member = useMemo(() => roomResponse?.member, [roomResponse]);

  const { preferences } = useContext(PreferencesContext);
  const customRoomName: string = useMemo(() => preferences?.[`room.${currentRoomId ?? ""}`]?.name ?? room?.name, [preferences, currentRoomId, room]);

  const tabs = useMemo(() => {
    const defaultTabs = [
      {
        label: t("createRoomModal.tabs.members.label"),
        content: room?.id != null ? <RoomMemberTab roomId={room.id} /> : <></>,
        id: "members",
        disabled: room?.id == null,
      },
    ];

    if (roomResponse?.room?.isHidden === false || roomId == null) {
      defaultTabs.unshift({
        label: t("createRoomModal.tabs.about.label"),
        content: <AboutTab room={room} member={member} isSuccess={isSuccess} />,
        id: "about",
        disabled: false,
      });
      defaultTabs.push({
        label: t("createRoomModal.tabs.settings.label"),
        content: room?.id != null ? <RoomNotificationSettingsTabComponent roomId={room.id} /> : <></>,
        id: "settings",
        disabled: room?.id == null,
      });
    }

    if (
      room?.id != null &&
      me?.mainOrganisation?.role?.type === RoleType.Owner &&
      me.mainOrganisation.organisationSubscription != null &&
      isOrganisationRoom
    ) {
      const plansTab = {
        label: t("createRoomModal.tabs.plans.label"),
        content: <PlansTabComponent isAutoOpening={false} />,
        id: "plans",
        disabled: false,
      };
      defaultTabs.push(plansTab);
    }

    if (me?.mainOrganisation?.role?.type === RoleType.Owner && isOrganisationRoom) {
      const settingsTab = {
        label: t("createRoomModal.tabs.organisationSettings.label"),
        content: room?.id != null ? <OrganisationSettingsTabComponent organisationId={me?.mainOrganisation?.organisationId} /> : <></>,
        id: "organisation-settings",
        disabled: false,
      };
      defaultTabs.push(settingsTab);
    }

    return defaultTabs;
  }, [room, me, isOrganisationRoom, roomResponse]);

  const modalTitle: string | JSX.Element = useMemo(() => {
    if (isOrganisationRoom) {
      return (
        <>
          <span>{customRoomName}</span>
          <span className="ml-1 text-sm font-semibold text-zinc-400">· {t("createRoomModal.tabs.about.organisationLabel")}</span>
        </>
      );
    }

    if (roomId == null && title != null) {
      return title;
    }

    return customRoomName;
  }, [room, customRoomName, title]);

  const onRoomCreated = (e: CustomEvent): void => {
    setCurrentRoomId(e.detail.id);
    setActiveTab("members");
  };

  useEffect(() => {
    window.addEventListener(eventNames.ROOM_CREATED, onRoomCreated as EventListener);
    return () => {
      window.removeEventListener(eventNames.ROOM_CREATED, onRoomCreated as EventListener);
    };
  }, [true, roomId]);

  return (
    <BulkInviteContextProvider>
      <ModalLayoutComponent
        key={`${customRoomName}${room?.name ?? ""}${member?.role.id ?? ""}`}
        tabs={tabs}
        title={modalTitle}
        size="md"
        name={ModalNames.CREATE_ROOM}
        defaultTab={activeTab}
      />
    </BulkInviteContextProvider>
  );
};
