import "@/components/shared/layout/DotLoaderComponent.scss";

export const DotLoaderComponent = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={`loader ${className}`} {...props}>
      <span className="loader__dot">.</span>
      <span className="loader__dot">.</span>
      <span className="loader__dot">.</span>
    </div>
  );
};
