import { type IMessage } from "@/api/client";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const useMessageNavigation = () => {
  const navigate = useNavigate();
  const { closeAllDrawers } = useContext(DrawerContext);

  const navigateToChannel = (message: IMessage) => {
    const channel = message.conversation?.channel;
    const isThread = message.nesting === "child";
    if (channel?.room == null) return;
    if (isThread) {
      const parentId = message?.parent?.id;
      if (parentId == null) return;

      closeAllDrawers();
      navigate(`/dashboard/rooms/${channel.room.id}/channel/${channel.id}/thread/${parentId}?messageId=${parentId}`);
      return;
    }

    navigate(`/dashboard/rooms/${channel.room.id}/channel/${channel.id}?messageId=${message?.id}`);
  };

  const navigateToDm = (message: IMessage) => {
    if (message.conversation == null) return;
    const sentFromRoomId = message?.sentFromRoom?.id;
    const isThread = message.nesting === "child";
    const conversationId = message.conversation.id;

    if (sentFromRoomId == null && !isThread) {
      navigate(`/dashboard/dms/${conversationId}?messageId=${message?.id}`);
      return;
    }
    if (sentFromRoomId != null && !isThread) {
      navigate(`/dashboard/rooms/${sentFromRoomId}/dm/${conversationId}?messageId=${message?.id}`);
      return;
    }

    const parentId = message?.parent?.id;
    if (parentId == null) return;
    if (sentFromRoomId != null) {
      navigate(`/dashboard/rooms/${sentFromRoomId}/dm/${conversationId}/thread/${parentId}?messageId=${parentId}`);
      return;
    }
    navigate(`/dashboard/dms/${conversationId}/thread/${parentId}?messageId=${message?.id}`);
  };

  const navigateToContext = async (message: IMessage) => {
    if (message.conversation?.type === "channel") {
      navigateToChannel(message);
    }

    if (message.conversation?.type === "conversation-dm") {
      navigateToDm(message);
    }
  };
  return { navigateToContext };
};
