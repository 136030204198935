import { RoomMemberStatus, type IRoomIdName } from "@/api/client";
import { useMyRoomsQuery } from "@/hooks/queries/rooms/useMyRoomsQuery";
import { useEffect, useMemo, useRef, useState } from "react";
import removeAccents from "remove-accents";

export const useRoomPickerPopinContent = (isVisible: boolean) => {
  const [search, setSearch] = useState<string>("");
  const ref = useRef<HTMLInputElement>(null);
  const { data: roomsData, isLoading } = useMyRoomsQuery();

  useEffect(() => {
    if (isVisible) {
      ref.current?.focus();
    }
  }, [isVisible]);

  const rooms: IRoomIdName[] = useMemo(() => {
    const onlyActiveRooms = roomsData?.filter((roomMember) => roomMember.status === RoomMemberStatus.Confirmed);

    return (
      onlyActiveRooms?.map((roomMember) => ({
        id: roomMember.room.id,
        name: roomMember.room.name,
      })) ?? []
    ).filter((room) => {
      return removeAccents(room.name.toLowerCase()).includes(removeAccents(search.toLowerCase()));
    });
  }, [roomsData, search]);

  const onSearchChange = (value: string) => {
    setSearch(value);
  };

  return {
    rooms,
    isLoading,
    search,
    onSearchChange,
    ref,
  };
};
