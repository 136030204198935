import { useConversationEmojiPicker } from "@/components/roomPage/tabs/conversation/ConversationLists/ConversationEmojiPicker/useConversationEmojiPicker";
import { EmojiComponent } from "@/components/shared/emojis/EmojiComponent";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { PreferencesContext } from "@/hooks/shared/usePreferences";
import emojis from "@emoji-mart/data/sets/14/apple.json";
import Picker from "@emoji-mart/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
interface IConversationEmojiPickerComponentProps {
  emoji?: string;
  canEdit: boolean;
  channelId: string;
}

export const ConversationEmojiPickerComponent = ({ emoji, canEdit, channelId }: IConversationEmojiPickerComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { preferences } = useContext(PreferencesContext);
  const {
    isEmojiPickerOpen,
    handleEmojiButtonClick,
    handleEmojiSelect,
    handleRemoveEmoji,
    handleClosePicker,
    containerRef,
    topPosition,
    leftPosition,
  } = useConversationEmojiPicker(channelId, canEdit);
  return (
    <>
      <TooltipComponent content={t("channel.emojiPicker.tootltip")} placement="bottom" disabled={!canEdit}>
        <button
          ref={containerRef}
          type="button"
          onClick={handleEmojiButtonClick}
          className={`ml-2 ${canEdit ? "transition-all  hover:opacity-50 " : ""}`}
        >
          {emoji != null ? <EmojiComponent shortcodes={emoji} id="emoji" set="apple" size="1rem" /> : <div className="w-4">#</div>}
        </button>
      </TooltipComponent>
      {isEmojiPickerOpen ? (
        <Portal>
          <div className="absolute left-0 top-0 z-50">
            <div
              className="fle absolute bottom-14 flex-col"
              style={{
                top: topPosition,
                left: leftPosition,
              }}
            >
              {emoji != null ? (
                <ButtonComponent status="danger" size="sm" onClick={handleRemoveEmoji} className="mb-2 ml-auto">
                  {t("channel.emojiPicker.remove")}
                </ButtonComponent>
              ) : null}

              <Picker
                data={emojis}
                onEmojiSelect={handleEmojiSelect}
                theme={preferences.darkMode === true ? "dark" : "light"}
                set="apple"
                skinTonePosition="auto"
              />
            </div>
            <div className="h-screen w-screen bg-black/50" onClick={handleClosePicker}></div>
          </div>
        </Portal>
      ) : null}
    </>
  );
};
