import { type Thread } from "@/api/client";
import { useMessageThread } from "@/components/roomPage/tabs/conversation/message/useMessageThread";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { useTranslation } from "react-i18next";
import { HiChevronRight as ChevronRightIcon } from "react-icons/hi";

interface IMessageThreadInfoComponentProps {
  thread: Thread;
  messageId: string;
  hasReaction: boolean;
}

export const MessageThreadInfoComponent = ({ thread, messageId, hasReaction }: IMessageThreadInfoComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { openThread } = useMessageThread();
  return (
    <section className={` ${!hasReaction ? "border-t border-black/5" : ""}`}>
      <div
        className="group/thread m-1 flex w-fit cursor-pointer items-center rounded border border-transparent p-0.5 transition-all hover:border-zinc-400"
        onClick={() => {
          openThread(messageId);
        }}
      >
        {thread?.participants?.list.map((participant) => (
          <div key={participant.id} className="mr-1">
            <UserAvatarComponent
              size="sm"
              userId={participant.user.id}
              userName={`${participant.user.firstName ?? ""} ${participant.user.lastName ?? ""}`}
              className="w-5"
              hideStatusBadge
            />
          </div>
        ))}
        <div className="whitespace-nowrap text-xs text-sky-600 group-hover/thread:underline">
          {t("roomPage.tabs.room.conversation.thread.reply", { count: thread.messages.count })}
        </div>
        <ChevronRightIcon className="ml-auto h-4 w-4 opacity-0 transition-opacity group-hover/thread:opacity-100" />
      </div>
    </section>
  );
};
