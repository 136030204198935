import { useFileExtensionPicker } from "@/components/shared/files/FileExtensionPickerComponent/useFileExtensionPicker";
import { FileExtensionPickerPopinComponent } from "@/components/shared/files/FileExtensionPickerPopinComponent/FileExtensionPickerPopinComponent";
import { FileIconComponent } from "@/components/shared/files/FileIconComponent";
import { type FunctionComponent, type HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { BsFillCaretDownFill as SelectorIcon } from "react-icons/bs";

interface IFileExtensionPickerComponentProps {
  fileExtension: string | null;
  onFileExtensionChange?: (fileExtension: string | null) => void;
  className?: HTMLAttributes<HTMLDivElement>["className"];
  size?: "lg" | "md" | "sm";
  isSelector?: boolean;
  fileExtensions: string[];
}

export const FileExtensionPickerComponent: FunctionComponent<IFileExtensionPickerComponentProps> = ({
  fileExtension,
  onFileExtensionChange,
  className,
  size = "md",
  isSelector = false,
  fileExtensions,
}) => {
  const { isFileExtensionTooltipVisible, onCloseTooltip, onFileExtensionClick, onFileExtensionSelected } = useFileExtensionPicker(
    fileExtension,
    onFileExtensionChange,
  );
  const { t } = useTranslation();

  return (
    <FileExtensionPickerPopinComponent
      isVisible={isFileExtensionTooltipVisible}
      onCloseTooltip={onCloseTooltip}
      onFileExtensionSelected={onFileExtensionSelected}
      hasRemoveFileExtensionOption={isSelector && fileExtension != null}
      fileExtensions={fileExtensions}
    >
      <div
        className={`flex flex-row items-center justify-between ${onFileExtensionChange != null ? "cursor-pointer" : ""} ${
          isSelector ? "rounded-md border border-zinc-200 bg-zinc-100 px-2 py-1 hover:opacity-75" : ""
        } ${className ?? ""}`}
        onClick={onFileExtensionClick}
      >
        <div className="flex flex-row items-center">
          {fileExtension != null ? <FileIconComponent extension={fileExtension} size="sm" /> : null}
          <div
            className={`${size === "lg" ? "ml-2" : "ml-1"} font-normal ${size === "lg" || size === "sm" ? "text-sm" : "text-xs"} ${
              isSelector ? "text-zinc-700" : "text-zinc-500"
            }`}
          >
            {fileExtension != null
              ? `.${fileExtension}`
              : isSelector
              ? t("fileExtension.picker.selectFileExtension")
              : t("fileExtension.picker.noFileExtension")}
          </div>
        </div>
        {onFileExtensionChange != null ? (
          isSelector ? (
            <SelectorIcon className="ml-1 h-3 w-3 text-zinc-700" />
          ) : (
            <div className="text-xs font-semibold text-sky-600">{t("fileExtension.picker.pick")}</div>
          )
        ) : null}
      </div>
    </FileExtensionPickerPopinComponent>
  );
};
