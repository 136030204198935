import { type IApiConversationGetOne, type IConversationMember } from "@/api/client";
import { createContext } from "react";

export interface IConversationContext extends Partial<IApiConversationGetOne> {
  members?: IConversationMember[];
}

const ConversationContext = createContext<IConversationContext>({});

export { ConversationContext };
