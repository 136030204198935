import { type ITaskStatus } from "@/api/client";
import { useState, type MouseEventHandler } from "react";

export const useTaskStatus = (status: ITaskStatus | undefined, onStatusChange: (taskStatusId: string) => void) => {
  const [isStatusTooltipVisible, setIsStatusTooltipVisible] = useState<boolean>(false);

  const onStatusClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsStatusTooltipVisible((isVisible) => !isVisible);
  };

  const onCloseTooltip = () => {
    setIsStatusTooltipVisible(false);
  };

  const onStatusSelected = (selectedStatus: string) => {
    if (selectedStatus !== status?.id) {
      onStatusChange(selectedStatus);
    }
    onCloseTooltip();
  };

  return { isStatusTooltipVisible, onStatusClick, onCloseTooltip, onStatusSelected };
};
