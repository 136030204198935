import { useWindowDimensions } from "@/hooks/shared/useWindowDimensions";
import { useEffect, useMemo, useRef, useState } from "react";
import { Portal } from "react-portal";

export const PortalInPlaceComponent = ({ children }: { children: string | JSX.Element }): JSX.Element => {
  const positionContainer = useRef<HTMLDivElement>(null);

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const dimensions = useWindowDimensions();

  useEffect(() => {
    setIsMounted(true);
  }, [positionContainer]);

  const style = useMemo(() => {
    return {
      top: positionContainer.current?.getBoundingClientRect().top,
      left: positionContainer.current?.getBoundingClientRect().left,
      width: positionContainer.current?.getBoundingClientRect().width,
      height: positionContainer.current?.getBoundingClientRect().height,
    };
  }, [isMounted, dimensions]);

  return (
    <>
      <div ref={positionContainer}></div>
      <Portal>
        <div className="fixed z-[2500]" style={style}>
          {children}
        </div>
      </Portal>
    </>
  );
};
