import { Api } from "@/api/client";

export const getAuthorizationToken = (): string | boolean => {
  return typeof localStorage.getItem("token") === "string" ? `Bearer ${localStorage.getItem("token") ?? ""}` : false;
};

export const api = new Api({
  baseURL: import.meta.env.VITE_APP_API_URL,
  secure: true,
  securityWorker: (token: string | null) => ({
    headers: {
      Authorization: token,
    },
  }),
});

api.setSecurityData(getAuthorizationToken() as string);
