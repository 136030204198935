interface ISectionHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  hasPadding?: boolean;
  children?: React.ReactNode;
}

export const SectionHeaderComponent = ({
  title = undefined,
  children = undefined,
  className = undefined,
  hasPadding = true,
  ...props
}: ISectionHeaderProps): JSX.Element => {
  return (
    <header
      className={`flex h-14 w-full shrink-0 items-center justify-between border-b bg-dark-25 dark:border-b-dark-700 dark:bg-dark-800
      ${hasPadding ? "p-layout" : ""}
      ${className ?? ""}`}
      {...props}
    >
      <div className="left-section flex h-full w-full items-center">
        {title != null && <h2 className="font-medium text-zinc-700 dark:text-dark-500">{title}</h2>}
        {children}
      </div>
    </header>
  );
};
