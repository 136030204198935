import { type UserActivityStatus } from "@/api/client";
import { ThePopoverComponent } from "@/components/shared/layout/ThePopoverComponent";
import { UserStatusComponent } from "@/components/shared/user/UserStatusComponent";
import { UserStatusPopinContentComponent } from "@/components/shared/user/UserStatusPopinComponent/UserStatusPopinContentComponent";
import { type FunctionComponent } from "react";

interface IUserStatusPopinComponentProps {
  onStatusSelected: (status: UserActivityStatus) => void;
  status: UserActivityStatus;
}

export const UserStatusPopinComponent: FunctionComponent<IUserStatusPopinComponentProps> = ({ onStatusSelected, status }) => {
  return (
    <ThePopoverComponent className="right-[-9px] top-5" align="right" button={<UserStatusComponent status={status} />}>
      <UserStatusPopinContentComponent status={status} onStatusSelected={onStatusSelected} />
    </ThePopoverComponent>
  );
};
