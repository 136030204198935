import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface IUseBulkRemoveOrganisationActiveRooms {
  organisationId: string;
  roomIds: string[];
}

export const useBulkRemoveOrganisationActiveRooms = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organisationId, roomIds }: IUseBulkRemoveOrganisationActiveRooms) => {
      const response = await api.organisations.achiveManyRooms(organisationId, { roomIds });
      return response.data;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_ROOMS] });
    },
  });
};
