import { type ITag, type ITagSearchBody } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useTagsQuery = (params: ITagSearchBody) => {
  return useQuery<ITag[]>({
    queryKey: [QueryKeys.ROOMS, params.roomId, QueryKeys.TAGS],
    queryFn: async () => {
      if (params.roomId === "") return [];
      const response = await api.tags.searchTags(params);
      return response.data.data;
    },
    staleTime: 500,
    enabled: params.roomId !== "",
  });
};
