import { ConfirmModalComponent } from "@/components/shared/modal/ConfirmModalComponent";
import { ModalNames } from "@/constants/modalNames";
import { type ISubscriptionUpdatedData } from "@/interfaces/billing";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

export const SubscriptionUpdatedModalConfirm = ({ data }: { data: ISubscriptionUpdatedData }) => {
  const { t } = useTranslation();
  const { plan, isUpgrade } = data;

  const keyPrefix = useMemo(() => `billing.modals.subscriptionUpdated.${isUpgrade ? "upgrade" : "downgrade"}`, [isUpgrade]);

  const title = useMemo(() => t(`${keyPrefix}.title`, { plan: plan.name }), [plan, isUpgrade]);

  const description = useMemo(
    () => (
      <div className="flex flex-col items-center">
        <span className="mb-3 text-4xl">{t(`${keyPrefix}.emoji`)}</span>
        <span>
          <Trans i18nKey={`${keyPrefix}.description`} values={{ plan: plan.name }} />
        </span>
      </div>
    ),
    [plan, isUpgrade],
  );

  return (
    <ConfirmModalComponent
      name={ModalNames.SUBSCRIPTION_UPDATED}
      title={title}
      description={description}
      action={null}
      actionType="primary"
      actionLabel=""
      cancelLabel={t("general.ok")}
    />
  );
};
