import { type IMessage, type IMessageForward } from "@/api/client";
import { FileInMessageComponent } from "@/components/roomPage/tabs/conversation/message/FileInMessageComponent";
import { MessageHeaderComponent } from "@/components/roomPage/tabs/conversation/message/MessageHeaderComponent";
import { RichTextEditorComponent } from "@/components/shared/richTextEditor/RichTextEditorComponent";
import { isDeltaContentEmpty, orderById } from "@/utils/utilities";
import { type DeltaStatic } from "quill";
import { useCallback, useMemo, type HTMLAttributes } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface IForwardMessageComponentProps extends HTMLAttributes<HTMLDivElement> {
  forward: IMessageForward;
  showForward?: boolean;
}

export const ForwardedMessageComponent = ({ forward, showForward = false, ...props }: IForwardMessageComponentProps) => {
  const orderedFilesById = orderById(forward?.files);
  const navigate = useNavigate();
  const currentRoute = useLocation();
  const newRoute = currentRoute.pathname + `?messageId=${forward.originalMessage.id}`;
  const isContentEmpty = useMemo(() => isDeltaContentEmpty(forward.content), [forward.content]);
  const handleClick = useCallback(() => {
    if (forward.forwardType === "reply") {
      navigate(newRoute, { replace: true, preventScrollReset: true });
    }
  }, [newRoute, navigate, currentRoute]);

  return (
    <article
      className={`forwarded-message ${props.className ?? ""} ${forward.forwardType === "reply" ? "cursor-pointer" : ""}`}
      onClick={handleClick}
    >
      <div className="flex flex-col flex-wrap items-start justify-start rounded-md border border-l-2 border-l-sky-600 bg-white p-2">
        <MessageHeaderComponent message={forward.originalMessage as IMessage} hideProfilePicture isForwarded showForward={showForward} />
        <div className={`flex w-full flex-col items-start overflow-x-scroll ${isContentEmpty ? "" : " mb-2"}`}>
          {orderedFilesById != null && orderedFilesById.length > 0 && (
            <div className="flex">
              {orderedFilesById.map((file) => (
                <div key={file.id} className="mr-1">
                  <FileInMessageComponent key={file.id} file={file} isInForward />
                </div>
              ))}
            </div>
          )}
        </div>
        {!isContentEmpty && (
          <RichTextEditorComponent
            contextType="forwardedMessage"
            contextId={forward.originalMessage.id}
            isMessagePreview={true}
            defaultValue={forward.content as DeltaStatic}
            messageId={forward.originalMessage.id}
          />
        )}
      </div>
    </article>
  );
};
