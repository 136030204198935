import { useTaskDeadline } from "@/components/shared/tasks/TaskDeadlineComponent/useTaskDeadline";
import { type FunctionComponent, type HTMLAttributes, type PropsWithChildren } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { TbCalendarTime as DeadlineIcon } from "react-icons/tb";

interface ITaskDeadlineComponentProps {
  onDeadlineChange: (deadline: string | null) => void;
  deadline: string | null;
  displayNoDeadline?: boolean;
  className?: HTMLAttributes<HTMLDivElement>["className"];
}

export const TaskDeadlineComponent: FunctionComponent<ITaskDeadlineComponentProps> = ({
  onDeadlineChange,
  deadline,
  displayNoDeadline = false,
  className,
}) => {
  const { t } = useTranslation();
  const { formattedDeadline, date, isInPast, onCloseCalendarPicker, onOpenCalendarPicker, ref, onDateChange } = useTaskDeadline(
    onDeadlineChange,
    deadline,
  );

  const ModalContainer: FunctionComponent<PropsWithChildren<{ className: string }>> = ({ className, children }) => {
    return (
      <div data-no-dnd={true}>
        <CalendarContainer className={className}>
          <div style={{ position: "relative" }}>{children}</div>
          <div className="flex w-full items-center px-4 pb-2">
            <div
              className="w-full rounded-md border border-gray-400 px-4 py-2 hover:bg-gray-200 dark:border-dark-600"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onDateChange(null);
              }}
            >
              {t("tasks.deadline.modal.reset")}
            </div>
          </div>
        </CalendarContainer>
      </div>
    );
  };

  if (deadline === null && !displayNoDeadline) {
    return null;
  }

  return (
    <div
      className={`flex cursor-pointer flex-row items-center ${isInPast ? " text-rogerRed-500" : "text-zinc-500"} ${
        isInPast ? " hover:text-rogerRed-500/75" : "hover:text-zinc-500/75"
      } ${className ?? ""}`}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        onOpenCalendarPicker();
      }}
    >
      <DeadlineIcon className="mr-1 text-base" />
      <DatePicker
        ref={ref}
        wrapperClassName="w-0"
        selected={date}
        onClickOutside={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onCloseCalendarPicker();
        }}
        customInput={<div className="hidden" />}
        calendarStartDay={1}
        onChange={(date, event) => {
          event?.stopPropagation();
          event?.preventDefault();
          onDateChange(date);
        }}
        calendarContainer={ModalContainer}
      />
      <div className="text-xs">{deadline === null ? t("tasks.deadline.empty") : formattedDeadline}</div>
    </div>
  );
};
