import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useChannelActiveMemberQuery = (channelId: string | null | undefined, enabled?: boolean) => {
  return useQuery({
    queryKey: [QueryKeys.CHANNELS, channelId, QueryKeys.MEMBERS],
    queryFn: async () => {
      if (channelId == null) return [];
      const response = await api.channels.getAllActiveMembers(channelId);
      return response.data.data;
    },
    enabled,
  });
};
