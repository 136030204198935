import { type TextareaHTMLAttributes } from "react";
import { type FieldPath, type FieldValues, type UseFormRegister } from "react-hook-form";

interface TextareaProps<T extends FieldValues> extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: JSX.Element | string;
  register: UseFormRegister<T>;
  error?: string;
  name: FieldPath<T>;
}

// TODO merge with TextInputComponent ?
export const TextAreaComponent = <T extends FieldValues>({ label, register, error, className, ...props }: TextareaProps<T>): JSX.Element => {
  return (
    <div>
      <label htmlFor={props.name} className="mb-1 flex items-center text-[13px] font-normal text-zinc-500 dark:text-dark-300">
        {label}
      </label>
      <textarea
        id={props.name}
        {...props}
        {...register(props.name)}
        className={`h-20 w-full resize-none rounded border border-dark-100 bg-gray-50 p-2 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 dark:border-dark-700 dark:bg-dark-800 dark:text-dark-300 ${
          className ?? ""
        }`}
      />
      {error != null && error !== "" ? <p>{error}</p> : null}
    </div>
  );
};
// the
