import { RoleType, RoomMemberStatus } from "@/api/client";
import { roomType } from "@/constants/room";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useMyOrganisationQuery } from "@/hooks/queries/organisations/useMyOrganisationQuery";
import { useMyRoomsQuery } from "@/hooks/queries/rooms/useMyRoomsQuery";
import { PreferencesContext } from "@/hooks/shared/usePreferences";
import { useContext, useMemo } from "react";

export const useRoomListTab = () => {
  const { data: rooms, isSuccess, isLoading, isFetching } = useMyRoomsQuery();
  const me = useContext(AuthenticationContext);
  const { data: myOrganisation } = useMyOrganisationQuery(me?.mainOrganisation?.id != null);
  const { preferences } = useContext(PreferencesContext);

  // TODO: Maybe check with the back if we can get the domain in the me query
  const domain = useMemo(() => me?.email?.split("@")[1], [me]);

  const organisationRooms = useMemo(() => {
    if (rooms == null) return [];
    if (myOrganisation?.organisationalRoom?.isHidden === true) return [];
    return rooms?.filter((roomItem) => roomItem.room.type === roomType.ORGANISATION);
  }, [rooms, myOrganisation?.organisationalRoom?.isHidden]);

  const projectRooms = useMemo(() => {
    if (rooms == null || preferences == null) return [];

    return rooms?.filter(
      (roomItem) =>
        roomItem.room.type === roomType.PROJECT &&
        roomItem.status === (preferences.showArchivedRooms === true ? RoomMemberStatus.Archived : RoomMemberStatus.Confirmed),
    );
  }, [rooms, preferences.showArchivedRooms]);

  const canCreateRoom = useMemo(() => {
    if (myOrganisation == null || me?.mainOrganisation?.role == null) return false;
    const allowedRoles = [RoleType.Owner, RoleType.Admin];
    return myOrganisation.canCreateRoom || allowedRoles.includes(me.mainOrganisation.role.type);
  }, [myOrganisation, me]);

  return {
    rooms,
    isLoading,
    isFetching,
    isSuccess,
    organisationRooms,
    projectRooms,
    domain,
    canCreateRoom,
  };
};
