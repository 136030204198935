import { UserActivityStatus, type IOrganisationMember, type IProfile } from "@/api/client";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { UserStatusComponent } from "@/components/shared/user/UserStatusComponent";
import { UserStatusPopinComponent } from "@/components/shared/user/UserStatusPopinComponent/UserStatusPopinComponent";
import { useMeMutations } from "@/hooks/mutations/me/useMeMutations";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { useUserQuery } from "@/hooks/queries/shared/user/useUserQuery";
import parsePhoneNumber from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { BsFillBriefcaseFill as PositionIcon } from "react-icons/bs";
import { CgOrganisation as OrganisationIcon } from "react-icons/cg";
import { FaUser as UserIcon } from "react-icons/fa";
import { RiPhoneFill as PhoneIcon } from "react-icons/ri";
interface IProfileWithMainOrganisation extends IProfile {
  mainOrganisation?: IOrganisationMember;
}

const newActivityStatusByCurrentActivityStatus: Record<UserActivityStatus, UserActivityStatus> = {
  [UserActivityStatus.Active]: UserActivityStatus.Offline,
  [UserActivityStatus.Inactive]: UserActivityStatus.Offline,
  [UserActivityStatus.Offline]: UserActivityStatus.Active,
};

export const UserInfoComponent = ({
  user,
  showDetails = false,
}: {
  user: Partial<IProfileWithMainOrganisation>;
  showDetails?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const { data: me } = useMeQuery();
  const { data: userInfos } = useUserQuery(user?.id);

  const {
    updateMeMutation: { mutate: updateUser },
  } = useMeMutations();

  if (user == null || userInfos == null) return <>Loading</>;

  const onStatusSelected = () => {
    const currentActivityStatus = userInfos.activityStatus;
    if (currentActivityStatus == null) {
      return;
    }

    updateUser({
      activityStatus: newActivityStatusByCurrentActivityStatus[currentActivityStatus],
    });
  };

  return (
    <>
      <div className={`flex ${showDetails ? "px-3 pt-3" : "p-0"}`}>
        <UserAvatarComponent
          key={user.profilePicture?.url}
          userName={`${user.firstName ?? ""} ${user.lastName ?? ""}`}
          userId={user.id}
          className="mr-3 h-16"
          size="lg"
        />
        <div>
          <div className="mb-1 flex flex-row items-center">
            <h3 className="text-sm font-semibold text-zinc-700 dark:text-dark-300">{`${user.firstName ?? ""} ${user.lastName ?? ""}`}</h3>
            {user?.activityStatus != null ? (
              <div className="ml-2 rounded border border-zinc-200 bg-zinc-100 px-2 py-0.5">
                {me?.id === user.id ? (
                  <UserStatusPopinComponent onStatusSelected={onStatusSelected} status={user?.activityStatus}></UserStatusPopinComponent>
                ) : (
                  <UserStatusComponent status={user.activityStatus} isDisabled />
                )}
              </div>
            ) : null}
          </div>
          <p className="mb-1 text-xs text-zinc-500 dark:text-dark-300">{user.email}</p>
          {!showDetails && (
            <p className="flex items-center text-xs text-zinc-500 dark:text-dark-200">
              {user.mainOrganisation == null ? (
                <>
                  <UserIcon className="-mt-0.5 mr-1 h-3 w-3" />
                  {t("header.profileDropdown.guestAccount")}
                </>
              ) : user.mainOrganisation.organisationType === "personal" ? (
                <>
                  <UserIcon className="-mt-0.5 mr-1 h-3 w-3" />
                  {t("header.profileDropdown.personalAccount")}
                </>
              ) : (
                <>
                  {" "}
                  <OrganisationIcon className="-mt-0.5 mr-1 h-4 w-4" />
                  {user.mainOrganisation.organisationName ?? user.mainOrganisation.organisationSlug} (
                  {t(`defaultRoles.${user?.mainOrganisation?.role?.slug ?? ""}`)})
                </>
              )}
            </p>
          )}
        </div>
      </div>
      {showDetails && (user.position != null || user.phone != null) && (
        <div className="my-4 border-t px-3 pt-4 dark:border-t-dark-700">
          <p className="flex items-center text-xs text-zinc-500 dark:text-dark-200">
            <PositionIcon className="mr-2 h-4 w-4" />
            {user.position}
          </p>
          <p className="flex items-center text-xs text-zinc-500 dark:text-dark-200">
            <PhoneIcon className="mr-2 h-4 w-4" />
            {parsePhoneNumber(`+${user.phone ?? ""}`)?.formatInternational()}
          </p>
        </div>
      )}
    </>
  );
};
