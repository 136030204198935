import { type IEmail, type IEmailThread, type INotification, type MessageConversationChannel } from "@/api/client";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { notificationType } from "@/constants/notification";
import { useUpdateNotificationReadStatusMutation } from "@/hooks/mutations/notifications/useUpdateNotificationReadStatusMutation";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const useNotificationNavigation = () => {
  const navigate = useNavigate();
  const { closeAllDrawers } = useContext(DrawerContext);
  const { mutate: updateNotificationStatus } = useUpdateNotificationReadStatusMutation();
  const navigateToChannel = (channel: MessageConversationChannel, isThread: boolean, notification: INotification) => {
    if (channel?.room == null) return;
    if (isThread) {
      const parentId = notification.message?.parent?.id;
      if (parentId == null) return;

      closeAllDrawers();
      navigate(`/dashboard/rooms/${channel.room.id}/channel/${channel.id}/thread/${parentId}?messageId=${notification.message?.id ?? ""}`);
      return;
    }

    let navigateToMessageParam = "";
    if (notification.isRead || notification.type !== "channel-message") {
      navigateToMessageParam = notification.message?.id != null ? `?messageId=${notification.message?.id}` : "";
    }

    navigate(`/dashboard/rooms/${channel.room.id}/channel/${channel.id}${navigateToMessageParam}`);
  };

  const navigateToDm = (isThread: boolean, conversationId: string, notification: INotification) => {
    let navigateToMessageParam = "";
    if (notification.isRead || notification.type !== "direct-message") {
      navigateToMessageParam = notification.message?.id != null ? `?messageId=${notification.message?.id}` : "";
    }

    const sentFromRoomId = notification.message?.sentFromRoom?.id;
    if (sentFromRoomId == null && !isThread) {
      navigate(`/dashboard/dms/${conversationId}${navigateToMessageParam}`);
      return;
    }
    if (sentFromRoomId != null && !isThread) {
      navigate(`/dashboard/rooms/${sentFromRoomId}/dm/${conversationId}${navigateToMessageParam}`);
      return;
    }

    const parentId = notification.message?.parent?.id;
    if (parentId == null) return;
    if (sentFromRoomId != null) {
      navigate(`/dashboard/rooms/${sentFromRoomId}/dm/${conversationId}/thread/${parentId}?messageId=${parentId}`);
      return;
    }
    navigate(`/dashboard/dms/${conversationId}/thread/${parentId}${navigateToMessageParam}`);
  };

  const navigateToTask = (notification: INotification) => {
    if (notification.task?.channel?.room == null) return;
    closeAllDrawers();
    if (notification.message != null) {
      navigate(`/dashboard/rooms/${notification.task.channel.room.id}/tasks/${notification.task.id}?messageId=${notification.message.id}`);
    } else {
      navigate(`/dashboard/rooms/${notification.task.channel.room.id}/tasks/${notification.task.id}`);
    }
    updateNotificationStatus({ notificationId: notification.id, isRead: true });
  };

  const navigateToEmail = (notification: INotification, emailThreadId: string, emailId: string) => {
    closeAllDrawers();
    navigate(`/dashboard/rooms/${notification.room?.id}/emails/${emailThreadId}/email/${emailId}`);
    updateNotificationStatus({ notificationId: notification.id, isRead: true });
  };

  const navigateToContext = async (notification: INotification, emailThread?: IEmailThread, email?: IEmail) => {
    if (notification.type === notificationType.NEW_EMAIL && emailThread != null && email != null) {
      navigateToEmail(notification, emailThread.id, email.id);
      return;
    }

    if (notification.task != null) {
      navigateToTask(notification);
      return;
    }
    if (notification.message?.conversation == null) return;
    const isThread = notification.message.nesting === "child" || notification.type === "thread-reply";
    const conversationId = notification.message?.conversation?.id;
    const { channel } = notification.message.conversation;
    const { directMessage } = notification.message.conversation;

    if (channel != null) {
      navigateToChannel(channel, isThread, notification);
    }
    if (directMessage != null) {
      navigateToDm(isThread, conversationId, notification);
    }
    if (!notification.isRead) {
      try {
        updateNotificationStatus({ notificationId: notification.id, isRead: true });
      } catch (error) {
        console.error(error);
      }
    }
  };
  return { navigateToContext };
};
