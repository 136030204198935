import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";

export const CheatsPage = (): JSX.Element => {
  const cleanStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  const changeLanguage = (lang: string) => {
    localStorage.setItem("i18nextLng", lang);
    window.location.reload();
  };

  return (
    <div className="flex h-full flex-col items-center justify-center p-layout">
      <div className="bg-gradient flex w-fit flex-col items-center rounded-xl p-2">
        <ButtonComponent className="mb-2 w-full" onClick={cleanStorage}>
          Clear storage
        </ButtonComponent>
        <ButtonComponent
          className="mb-2 w-full"
          onClick={() => {
            changeLanguage("fr-FR");
          }}
        >
          Change language to FR
        </ButtonComponent>
        <ButtonComponent
          className="mb-2 w-full"
          onClick={() => {
            changeLanguage("en-US");
          }}
        >
          Change language to EN
        </ButtonComponent>
        <div className="font-semibold text-white">{APP_VERSION}</div>
      </div>
    </div>
  );
};
