import { type IDirectMessage } from "@/api/client";
import { useConversationListElement } from "@/components/roomPage/tabs/conversation/ConversationLists/conversationListElement/useConversationListElement";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { DotLoaderComponent } from "@/components/shared/layout/DotLoaderComponent";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { TypingUsersContext } from "@/contexts/TypingUsersContextProvider";
import { useBadge } from "@/hooks/shared/useBadge";
import { useContext, useMemo } from "react";
import { BsBellSlash } from "react-icons/bs";
import { IoClose as CloseDMIcon } from "react-icons/io5";
import { NavLink } from "react-router-dom";

interface IConversationListElementComponentProps {
  conversation: IDirectMessage;
  closeList: (event: Event) => void;
  isHighlighted?: boolean;
  badge?: number;
}

export const DmListElementComponent = ({
  conversation,
  closeList,
  isHighlighted = false,
  badge = 0,
}: IConversationListElementComponentProps): JSX.Element => {
  const { getConversationInfo, handleCloseDm, routeToDestination, shouldShowDisabledBell, key } = useConversationListElement("dm", conversation);
  const { getComputedBadgeNumber } = useBadge();

  const me = useContext(AuthenticationContext);
  const typingUsersByConversations = useContext(TypingUsersContext);

  const isSomeoneTyping = useMemo(() => {
    const conversationId = conversation.conversation.id;
    return typingUsersByConversations?.[conversationId] != null && typingUsersByConversations[conversationId].length > 0;
  }, [typingUsersByConversations]);

  return (
    <>
      <div
        key={key}
        className={`group/channel-list relative flex cursor-pointer items-center justify-start dark:hover:bg-dark-800 ${
          isHighlighted ? "font-bold" : ""
        }`}
      >
        <NavLink
          className={({ isActive }) => {
            return `dark:text-dark-300 no-underline truncate text-[13px] h-full w-full py-2 pl-2 pr-8  mx-2 my-[1px] rounded-lg flex items-center relative ${
              isActive
                ? "active bg-sky-600 text-white"
                : "inactive text-zinc-800  group-hover/channel-list:bg-zinc-100 group-hover/channel-list:text-black"
            }`;
          }}
          onClick={(event) => {
            closeList(event.nativeEvent);
          }}
          to={routeToDestination}
        >
          {/* Make a component for that */}
          {!isSomeoneTyping && badge != null && badge > 0 ? (
            <div className="translate absolute right-1 top-1/2 z-50 mr-2 inline-flex -translate-y-1/2 items-center justify-center rounded-full px-2 py-1 text-xs font-bold leading-none [.active_&]:bg-white [.active_&]:text-sky-600  [.inactive_&]:bg-sky-600 [.inactive_&]:text-white">
              {getComputedBadgeNumber(badge)}
            </div>
          ) : null}
          {isSomeoneTyping ? (
            <div className="translate absolute right-2 top-1/2 z-50 mr-2 flex h-3 w-3 -translate-y-1/2 items-center justify-center px-2 py-1 pb-4 [.active_&]:text-white [.inactive_&]:text-sky-600">
              <DotLoaderComponent className="text-2xl" />
            </div>
          ) : null}
          <div className="relative shrink-0">
            <UserAvatarComponent
              key={getConversationInfo(conversation).id}
              userId={
                conversation.conversation?.members.length === 2
                  ? conversation.conversation.members.find(({ user }) => user.id !== me?.id)?.user.id
                  : undefined
              }
              userName={
                conversation.conversation.members.length === 2
                  ? getConversationInfo(conversation).name
                  : getConversationInfo(conversation).nameWithCount
              }
              size="md"
              className="mr-2 h-6 w-6"
            />
          </div>
          <span className="mr-2 truncate">{getConversationInfo(conversation).name}</span>
          {!isSomeoneTyping ? (
            <ButtonComponent
              onClick={handleCloseDm(conversation.id)}
              status="neutral"
              size="xs"
              className={`absolute border-0 font-[600] opacity-0 group-hover/channel-list:opacity-100 ${
                badge > 0 ? "hidden" : "right-1"
              } hover:bg-transparent`}
            >
              <div className="h-4 w-4 opacity-50 transition-opacity hover:opacity-100">
                <CloseDMIcon />
              </div>
            </ButtonComponent>
          ) : null}
          {shouldShowDisabledBell && (
            <div className="ml-2 flex h-3 w-3 items-center">
              <BsBellSlash />
            </div>
          )}
        </NavLink>
      </div>
    </>
  );
};
