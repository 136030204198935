import { type IConversationMessagesAllSearchBody, type IConversationMessagesAllSearchResponse } from "@/api/client";
import { ConversationContext } from "@/contexts/ConversationContext";
import { api } from "@/services/HttpService";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useContext } from "react";

export const useMessagesQuery = (queryKey: string[], contextType?: string, contextId?: string, cursor?: string | null) => {
  const conversationContext = useContext(ConversationContext);
  const PAGE_SIZE = 50;
  interface IPageParam {
    pageParam?: {
      cursor?: string;
      isPreviousPage?: boolean;
    };
  }

  return useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }: IPageParam) => {
      let body: IConversationMessagesAllSearchBody = {};
      if (cursor != null) {
        body = {
          cursor,
          nextCount: PAGE_SIZE / 2,
          previousCount: PAGE_SIZE / 2,
        };
      }
      if (contextType === "message") {
        body.parentMessageId = contextId;
      }
      if (pageParam != null) {
        body = { ...body, ...pageParam };
      }
      const response = await api.conversations.getMessages(
        conversationContext?.conversation?.id != null ? conversationContext.conversation.id : "",
        body,
      );
      return response.data;
    },
    getPreviousPageParam: (firstPage: IConversationMessagesAllSearchResponse) => {
      return firstPage.pagination.previousCursor != null
        ? { cursor: firstPage.pagination.previousCursor, nextCount: 0, previousCount: PAGE_SIZE }
        : null;
    },
    getNextPageParam: (lastPage: IConversationMessagesAllSearchResponse) => {
      return lastPage.pagination.nextCursor != null ? { cursor: lastPage.pagination.nextCursor, nextCount: PAGE_SIZE, previousCount: 0 } : null;
    },
    staleTime: 0,
    initialPageParam: undefined,
  });
};
