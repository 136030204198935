import { useFileTableHeaderContextActions } from "@/components/roomPage/tabs/files/useFileTableHeaderContextActions";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { useTranslation } from "react-i18next";
import { AiOutlineCloudDownload as DownloadIcon } from "react-icons/ai";
import { IoIosShareAlt as ShareIcon } from "react-icons/io";
interface IFileTableHeaderContextActionsComponentProps {
  selectedItems: string[];
}
export const FileTableHeaderContextActionsComponent = ({ selectedItems }: IFileTableHeaderContextActionsComponentProps) => {
  const { t } = useTranslation();
  const { handleDownloadClick, LIMIT_FOR_SHARING } = useFileTableHeaderContextActions();

  if (selectedItems.length === 0) return null;

  return (
    <div className="flex">
      <span className={`ml-2 text-xs ${selectedItems.length === 0 ? "hidden" : ""}`}>
        | {t("files.selectedLabel", { count: selectedItems.length })}{" "}
      </span>
      <div className="ml-2 flex max-h-full items-center">
        <div className="absolute flex gap-1">
          {/* FLAG TO DISABLING SHARING ! Will be removed and implemented when back is ready */}
          {/* eslint-disable-next-line no-constant-condition */}
          {selectedItems.length <= LIMIT_FOR_SHARING && false ? (
            <TooltipComponent content={t("files.share.tooltip", { count: selectedItems.length })} placement="bottom">
              <button className="h-6 w-6 rounded-md bg-zinc-200 p-1 text-sky-600 transition hover:bg-zinc-300">
                <ShareIcon className="m-auto h-3 w-3" />
              </button>
            </TooltipComponent>
          ) : null}
          <TooltipComponent content={t("files.download.tooltip", { count: selectedItems.length })} placement="bottom">
            <button
              onClick={() => {
                handleDownloadClick(selectedItems);
              }}
              className="h-6 w-6 rounded-md bg-zinc-200 p-1 text-sky-600 transition hover:bg-zinc-300"
            >
              <DownloadIcon className="m-auto h-3 w-3" />
            </button>
          </TooltipComponent>
        </div>
      </div>
    </div>
  );
};
