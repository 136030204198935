import { CreateCalendarEventFormComponent } from "@/components/roomPage/modals/createCalendarEvent/tabs/CreateCalendarEventForm/CreateCalendarEventFormComponent";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DRAWER_HEADER_HEIGHT, DrawerHeaderComponent } from "@/components/shared/drawer/DrawerHeaderComponent";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { GLOBAL_HEADER_HEIGHT } from "@/components/shared/layout/GlobalHeaderComponent";
import { RoundedContainerComponent } from "@/components/shared/layout/RoundedContainerComponent";
import { useWindowDimensions } from "@/hooks/shared/useWindowDimensions";
import { useContext, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface CreateCalendarEventDrawerProps {
  conversationId: string;
}

export const CreateCalendarEventDrawerComponent: FunctionComponent<CreateCalendarEventDrawerProps> = ({ conversationId }) => {
  const { t } = useTranslation();

  const { height } = useWindowDimensions();

  const { closeDrawer } = useContext(DrawerContext);

  const onCloseDrawer = () => {
    closeDrawer(DrawerType.CREATE_CALENDAR_EVENT);
  };

  return (
    <RoundedContainerComponent className="bg-dark-25 dark:bg-dark-800" hasPadding={false}>
      <section>
        <DrawerHeaderComponent title={t("createCalendarEventDrawer.title")} onCloseDrawer={onCloseDrawer} />

        <div
          className="overflow-auto"
          style={{
            height: height - DRAWER_HEADER_HEIGHT - GLOBAL_HEADER_HEIGHT,
          }}
        >
          <CreateCalendarEventFormComponent conversationId={conversationId} />
        </div>
      </section>
    </RoundedContainerComponent>
  );
};
