import { MAX_MEMBERS_TYPING } from "@/constants/typingIndicatorConstants";
import { ConversationContext } from "@/contexts/ConversationContext";
import { TypingUsersContext } from "@/contexts/TypingUsersContextProvider";
import { addCommasSeparator } from "@/utils/utilities";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useTypingIndicator = () => {
  const { t } = useTranslation();

  const conversationContext = useContext(ConversationContext);
  const typingUsersByConversations = useContext(TypingUsersContext);

  const conversation = useMemo(() => conversationContext?.conversation, [conversationContext]);

  const conversationTypingMembers = useMemo(() => {
    if (conversation == null) return [];

    return typingUsersByConversations?.[conversation.id] ?? [];
  }, [conversation, typingUsersByConversations]);

  // computing the typing message to display
  const typingMessage = useMemo(() => {
    if (conversationTypingMembers.length === 0) return "";
    const uniqueMembersTyping = conversationTypingMembers.filter(
      (member, index, self) => self.findIndex((m) => m.memberId === member.memberId) === index,
    );

    let message = t("roomPage.tabs.room.conversation.isTyping", {
      names: addCommasSeparator(
        uniqueMembersTyping.map((member) => member.memberFullName),
        t("general.and"),
      ),
      count: uniqueMembersTyping.length,
    });

    if (uniqueMembersTyping.length > MAX_MEMBERS_TYPING) {
      message = t("roomPage.tabs.room.conversation.isTypingMany", {
        names: addCommasSeparator(uniqueMembersTyping.map((member) => member.memberFullName)),
        count: uniqueMembersTyping.length - MAX_MEMBERS_TYPING,
      });
    }
    return message;
  }, [conversationTypingMembers]);

  return { typingMessage };
};
