import { ConversationMemberStatus } from "@/api/client";
import { ConversationWithEditorComponent } from "@/components/shared/conversation/ConversationWithEditorComponent";
import { SectionHeaderComponent } from "@/components/shared/layout/SectionHeaderComponent";
import { eventNames } from "@/constants/eventNames";
import { ConversationContext } from "@/contexts/ConversationContext";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { RiCloseFill as CloseThreadIcon } from "react-icons/ri";

export const ThreadComponent = ({ threadId }: { threadId: string | null }): JSX.Element => {
  const { t } = useTranslation();
  const conversationContext = useContext(ConversationContext);
  const closeThread = useCallback(() => {
    const closeThreadEvent = new CustomEvent(eventNames.CLOSE_THREAD, {
      detail: {
        threadId,
      },
    });
    window.dispatchEvent(closeThreadEvent);
  }, [threadId]);

  return (
    <div className={`flex flex-col overflow-auto bg-white transition-all dark:bg-dark-900 ${threadId != null ? "w-1/2" : " w-0"}`}>
      <SectionHeaderComponent className="h-12 bg-[white] ">
        <h2 className="flex w-full items-center text-[15px] font-semibold text-zinc-700 dark:text-dark-300">
          <p className="hidden text-xs md:block">{t("roomPage.tabs.room.conversation.thread.title")}</p>
          <button className="ml-auto" onClick={closeThread}>
            <CloseThreadIcon className="h-4 w-4 " />
          </button>
        </h2>
      </SectionHeaderComponent>
      {threadId != null && (
        <ConversationWithEditorComponent
          isArchived={conversationContext.member?.status === ConversationMemberStatus.Archived}
          contextType="message"
          contextId={threadId}
        />
      )}
    </div>
  );
};
