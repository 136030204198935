import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const usePlansQuery = () => {
  return useQuery({
    queryKey: [QueryKeys.BILLING, QueryKeys.PLANS],
    queryFn: async () => {
      const response = await api.plans.findMany();
      return response.data.data.sort((a, b) => (a.priceEurosDecimal ?? 0) - (b.priceEurosDecimal ?? 0));
    },
    staleTime: 1000 * 60 * 60,
  });
};
