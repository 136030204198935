import { AssigneUserToTaskPopinContentComponent } from "@/components/shared/tasks/AssigneUserToTaskPopinComponent/AssigneUserToTaskPopinContentComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { type TippyProps } from "@tippyjs/react";
import { type FunctionComponent } from "react";

interface IAssigneUserToTaskPopinComponentProps {
  children: TippyProps["children"];
  taskId: string;
  roomId: string | null;
  channelId: string | null;
  isVisible: boolean;
  onCloseTooltip: () => void;
}

export const AssigneUserToTaskPopinComponent: FunctionComponent<IAssigneUserToTaskPopinComponentProps> = ({
  children,
  taskId,
  roomId,
  channelId,
  isVisible,
  onCloseTooltip,
}) => {
  return (
    <TooltipComponent
      placement="bottom-start"
      visible={isVisible}
      onClickOutside={onCloseTooltip}
      arrow={false}
      interactive
      noPadding
      content={<AssigneUserToTaskPopinContentComponent isVisible={isVisible} taskId={taskId} roomId={roomId} channelId={channelId} />}
    >
      {children}
    </TooltipComponent>
  );
};
