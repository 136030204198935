import { eventNames } from "@/constants/eventNames";
import { useCallback, useEffect, useState } from "react";

export const useSearchInput = () => {
  const [search, setSearch] = useState<string>("");

  const resetSearch = () => {
    updateSearch("");
  };

  useEffect(() => {
    window.addEventListener(eventNames.RESET_SEARCH, resetSearch);
    return () => {
      window.removeEventListener(eventNames.RESET_SEARCH, resetSearch);
    };
  }, []);

  const updateSearch = useCallback((value: string) => {
    setSearch(value);
  }, []);

  return { search, updateSearch };
};
