import { type ITaskStatus } from "@/api/client";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { type FunctionComponent } from "react";

interface ITaskStatusItemComponentProps {
  status: ITaskStatus;
  displayNoStatus?: boolean;
  onClick?: () => void;
  showTooltip?: boolean;
}

export const TaskStatusItemComponent: FunctionComponent<ITaskStatusItemComponentProps> = ({ status, onClick, showTooltip = false }) => {
  return (
    <TooltipComponent content={status.name} placement="top" disabled={!showTooltip}>
      <div className="mb-1 overflow-hidden truncate rounded-md bg-zinc-200 px-3 py-1 last:mb-0" onClick={onClick}>
        <div className=" truncate text-xs text-zinc-500">{status.name}</div>
      </div>
    </TooltipComponent>
  );
};
