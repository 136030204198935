import { TagComponent } from "@/components/shared/tag/TagComponent";
import { useCallback, type FunctionComponent } from "react";
import { IoMdMail as MailIcon } from "react-icons/io";

interface EmailTagComponentProps {
  email: string;
  onRemove: () => void;
}

export const EmailTagComponent: FunctionComponent<EmailTagComponentProps> = ({ email, onRemove }) => {
  const IconComponent = useCallback(() => <MailIcon className="h-6 w-6 text-zinc-400" />, []);
  return <TagComponent IconComponent={IconComponent} value={email} onRemove={onRemove} />;
};
