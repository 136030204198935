import { Disclosure } from "@headlessui/react";
import { IoCaretDown } from "react-icons/io5";

interface IDropDownProps {
  title: JSX.Element | string;
  children: JSX.Element;
  defaultOpen?: boolean;
}

export const DropDownComponent = ({ children, title, defaultOpen = false }: IDropDownProps): JSX.Element => {
  return (
    <div className="w-full">
      <div className="w-full rounded-lg">
        <Disclosure defaultOpen={defaultOpen}>
          {({ open }) => (
            <>
              <nav className="text-md flex w-full items-center rounded-t-lg px-layout pb-2 pt-3 text-left font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                <Disclosure.Button className="">
                  <IoCaretDown className={`${open ? "" : "-rotate-90"} mr-2 h-4 w-4 text-zinc-500`} />
                </Disclosure.Button>
                <div className="mr-auto w-full">{title}</div>
              </nav>
              <Disclosure.Panel className="text-sm text-gray-500">{children}</Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};
