export const ModalTabComponent = ({
  children,
  footer,
  hasPadding = true,
}: {
  children: JSX.Element | string;
  footer?: JSX.Element;
  hasPadding?: boolean;
}): JSX.Element => {
  return (
    <div className="flex h-full flex-1 flex-col">
      <div
        className={`${hasPadding ? "px-6 py-4" : ""} relative h-full w-full overflow-auto`}
        style={{
          transform: "scale(1)",
        }}
      >
        {children}
      </div>
      {footer ?? footer}
    </div>
  );
};
