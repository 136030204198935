import { type ICalendarEvent, type IEventDatetime } from "@/api/client";
import { type EventTimelineEvent } from "@/components/shared/calendar/EventTimelineTypes";
import { useEventTimeline } from "@/components/shared/calendar/useEventTimeline";
import { type FunctionComponent } from "react";
import { Calendar, Views } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import "react-big-calendar/lib/sass/styles.scss";
import "./EventTimelineStyle.scss";

import { format } from "date-fns";

interface EventTimelineComponentProps {
  selectedDay: Date;
  events: ICalendarEvent[];
  newEventSummary: string;
  start: IEventDatetime;
  end: IEventDatetime | null;
  onEventDateChange: (start: Date, end: Date | null, isAllDay?: boolean) => void;
}

const DragAndDropCalendar = withDragAndDrop<EventTimelineEvent, EventTimelineEvent>(Calendar);

export const EventTimelineComponent: FunctionComponent<EventTimelineComponentProps> = ({
  events,
  newEventSummary,
  selectedDay,
  start,
  end,
  onEventDateChange,
}) => {
  const { allEvents, defaultDate, eventPropGetter, formats, localizer, onEventMoved, onEventResize, onSelectSlot, draggableAccessor } =
    useEventTimeline({ events, newEventSummary, start, end, onEventDateChange });

  return (
    <div className="relative h-72 w-full">
      <DragAndDropCalendar
        formats={formats}
        defaultDate={defaultDate}
        date={selectedDay}
        defaultView={Views.DAY}
        events={allEvents}
        localizer={localizer}
        onEventDrop={onEventMoved}
        onEventResize={onEventResize}
        resizableAccessor={draggableAccessor}
        timeslots={4}
        step={15}
        popup
        resizable
        toolbar={false}
        eventPropGetter={eventPropGetter}
        onSelectSlot={onSelectSlot}
        selectable
        scrollToTime={defaultDate}
        components={{
          // @ts-expect-error react-big-calendar types are not set
          timeGutterWrapper: ({ children }) => {
            return <div className="text-xs">{children}</div>;
          },
        }}
      />
      <div className="absolute left-2 top-3 text-xs font-normal text-sky-600">
        {format(new Date(start.date ?? start.dateTime ?? new Date()), "dd/MM")}
      </div>
    </div>
  );
};
