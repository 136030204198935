import { SimpleBadge } from "@/components/shared/badges/SimpleBadge";
import { type HTMLAttributes } from "react";

interface SimpleBadgeProps extends HTMLAttributes<HTMLSpanElement> {
  count?: number;
  size?: "sm" | "lg";
}
export const NumberBadge = ({ count, size = "lg" }: SimpleBadgeProps) => {
  const sizeClass = size === "sm" ? "h-3" : "h-4";
  const rightClass = size === "sm" ? "-right-[5px]" : "-right-1";
  const topClass = size === "sm" ? "-top-[3px]" : "-top-1";
  const maxCount = size === "sm" ? 9 : 99;
  const fontSizeClass = size === "sm" ? "text-[8px]" : "text-2xs";
  const paddingClass = size === "sm" ? "px-[4.04px]" : "px-1.5";
  return count == null ? (
    <></>
  ) : count === 0 ? (
    <SimpleBadge topClass={topClass} rightClass={rightClass} sizeClass={sizeClass} />
  ) : (
    <span
      className={`${sizeClass} ${rightClass} ${topClass} ${fontSizeClass} ${paddingClass} absolute z-50 inline-flex items-center justify-center rounded-full bg-red-600 font-bold leading-none text-red-100`}
    >
      {count < maxCount ? count : `${maxCount}+`}
    </span>
  );
};
