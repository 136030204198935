import { ConversationListsComponent } from "@/components/roomPage/tabs/conversation/ConversationLists/ConversationListsComponent";
import { useMyDmsQuery } from "@/hooks/queries/dm/useMyDmsQuery";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

export const DmsTabsComponent = (): JSX.Element => {
  const { data: dms } = useMyDmsQuery();
  const params: { conversationId?: string } = useParams();
  const navigate = useNavigate();

  // if no params.conversationId, redirect to first conversation
  useEffect(() => {
    if (dms == null || dms.length === 0) return;
    if (params?.conversationId == null) navigate(`/dashboard/dms/${dms[0].conversation.id}`);
  }, [params, dms]);

  return (
    <section className="flex h-full flex-1 overflow-auto">
      <ConversationListsComponent dms={dms ?? []} isOnHomePage />
      <div className="flex h-full w-full flex-1 flex-col overflow-auto bg-white dark:bg-dark-900">
        <Outlet />
      </div>
    </section>
  );
};
