import { GoogleTargetFeatureEnum } from "@/api/client";
import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";

export const useSendGoogleAuthorizationCodeMutation = (onSuccess?: () => void) => {
  return useMutation({
    mutationFn: async ({
      code,
      hasRedirectUri,
      targetFeature = GoogleTargetFeatureEnum.Gmail,
    }: {
      code: string;
      hasRedirectUri: boolean;
      targetFeature?: GoogleTargetFeatureEnum;
    }) => {
      await api.google.googleLogin({
        code,
        hasRedirectUri,
        isDev: import.meta.env.VITE_APP_ENV === "local",
        targetFeature,
      });
    },
    onSuccess,
  });
};
