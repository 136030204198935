import { useCallback, useState } from "react";

export const useEmailHistory = () => {
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);

  const handleHistoryClick = useCallback(() => {
    setIsHistoryVisible((prev) => !prev);
  }, []);

  return {
    isHistoryVisible,
    handleHistoryClick,
  };
};
