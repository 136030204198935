import { type ITask, type TaskPriorityEnum } from "@/api/client";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { EditTaskDrawerComponent } from "@/components/shared/tasks/EditTaskDrawerComponent/EditTaskDrawerComponent";
import { RoomContext } from "@/contexts/RoomContext";
import { useUpdateTaskMutation } from "@/hooks/mutations/tasks/useUpdateTaskMutation";
import { useCallback, useContext, useState, type MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";

export const useTaskItem = (task: ITask) => {
  const { openDrawer } = useContext(DrawerContext);
  const { mutate: updateTask } = useUpdateTaskMutation();
  const navigate = useNavigate();
  const roomContext = useContext(RoomContext);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const onTaskClick = useCallback(() => {
    if (roomContext?.roomResponse?.room?.id == null) {
      openDrawer({
        drawerType: DrawerType.EDIT_TASK,
        component: <EditTaskDrawerComponent taskId={task.id} />,
      });
    } else {
      if (task.channel?.room == null) return;
      navigate(`/dashboard/rooms/${task.channel.room.id}/tasks/${task.id}`);
    }
  }, [task.id, task.channel?.room?.id, roomContext]);

  const onPriorityChange = (priority: TaskPriorityEnum | null) => {
    updateTask({
      taskId: task.id,
      priority,
    });
  };

  const onDeadlineChange = (deadline: string | null) => {
    updateTask({
      taskId: task.id,
      deadline,
    });
  };

  const onContextMenuButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.stopPropagation();
    setIsContextMenuOpen(!isContextMenuOpen);
  }, []);

  const onContextMenuClickOutside = useCallback(() => {
    setIsContextMenuOpen(false);
  }, []);

  return {
    onTaskClick,
    onPriorityChange,
    onDeadlineChange,
    onContextMenuButtonClick,
    isContextMenuOpen,
    setIsContextMenuOpen,
    onContextMenuClickOutside,
  };
};
