import { createContext, useCallback, useState, type FunctionComponent, type PropsWithChildren } from "react";
interface IJitsiParticipant {
  displayName: string;
  id: string;
}
interface JitsiContextData {
  roomName: string;
  domain: string;
  isFullScreen: boolean;
  isVideoShown: boolean;
  isHidden: boolean;
  isOnTop: boolean;
  participants: IJitsiParticipant[];
  setRoomName: (roomName: string) => void;
  setIsFullScreen: (isFullScreen: boolean) => void;
  setIsHidden: (isHidden: boolean) => void;
  setIsVideoShown: (isVideoShown: boolean) => void;
  setParticipants: (participants: IJitsiParticipant[]) => void;
  joinVideoCall: (domain: string, roomName: string) => void;
  setDomain: (domain: string) => void;
  setIsOnTop: (isOnTop: boolean) => void;
}

export const JitsiContext = createContext<JitsiContextData>({
  roomName: "",
  domain: "",
  isFullScreen: false,
  isVideoShown: false,
  isHidden: false,
  participants: [],
  isOnTop: true,
  setDomain: () => {},
  joinVideoCall: () => {},
  setParticipants: () => {},
  setRoomName: () => {},
  setIsFullScreen: () => {},
  setIsVideoShown: () => {},
  setIsHidden: () => {},
  setIsOnTop: () => {},
});

export const JitsiContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [roomName, setRoomName] = useState<string>("");
  const [domain, setDomain] = useState<string>("");
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [isVideoShown, setIsVideoShown] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [participants, setParticipants] = useState<IJitsiParticipant[]>([]);
  const [isOnTop, setIsOnTop] = useState(true);

  const joinVideoCall = useCallback((domain: string, roomName: string) => {
    setIsVideoShown(true);
    setRoomName(roomName);
    setDomain(domain);
  }, []);

  const contextValue: JitsiContextData = {
    domain,
    roomName,
    isFullScreen,
    isVideoShown,
    isHidden,
    participants,
    joinVideoCall,
    isOnTop,
    setIsOnTop,
    setDomain,
    setRoomName,
    setIsFullScreen,
    setIsVideoShown,
    setIsHidden,
    setParticipants,
  };

  return <JitsiContext.Provider value={contextValue}>{children}</JitsiContext.Provider>;
};
