import { EmailThreadContext } from "@/contexts/EmailThreadContextProvider";
import { useCallback, useContext } from "react";

export const useEmailReply = () => {
  const { to, cc, setTo, setCc, isReplyOpened, setReplyToMailId } = useContext(EmailThreadContext);
  const handleCloseReplyButtonClick = useCallback(() => {
    setReplyToMailId(null);
    setTo([]);
    setCc([]);
  }, []);
  return {
    to,
    cc,
    setTo,
    setCc,
    isReplyOpened,
    handleCloseReplyButtonClick,
  };
};
