import { type IChannelMember, type IConversationMember, type IDirectMessage } from "@/api/client";
import { useConversationUtilities } from "@/components/roomPage/tabs/conversation/ConversationLists/useConversationUtilities";
import { QueryKeys } from "@/constants/queryKeys";
import { RoomContext } from "@/contexts/RoomContext";
import { useChannelNotificationSettings } from "@/hooks/shared/notificationSettings/useChannelNotificationSettings";
import { useDmNotificationSettings } from "@/hooks/shared/notificationSettings/useDmNotificationSettings";
import { useRoomNotificationSettings } from "@/hooks/shared/notificationSettings/useRoomNotificationSettings";
import { api } from "@/services/HttpService";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useMemo, type MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export const useConversationListElement = (scope: string, conversation?: IDirectMessage | IChannelMember) => {
  const { computeConversationTitleFromMemberList } = useConversationUtilities();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: roomIdParam } = useParams();
  const roomContext = useContext(RoomContext);
  const room = useMemo(() => roomContext?.roomResponse?.room, [roomContext]);
  const roomId = useMemo(() => roomContext?.roomResponse?.room?.id ?? roomIdParam ?? "", [roomContext, roomIdParam]);
  const conversationId = useMemo(() => {
    if (scope === "channel") return (conversation as IChannelMember).channel.conversation.id;
    return (conversation as IDirectMessage)?.conversation.id;
  }, [conversation]);

  const { isNotificationEnabled } = useChannelNotificationSettings(roomId, conversationId);
  const { isNotificationEnabled: isDmNotificationEnabled } = useDmNotificationSettings(conversationId);
  const { isNotificationEnabled: isRoomNotificationEnabled } = useRoomNotificationSettings(roomId);

  const shouldShowDisabledBell = useMemo(() => {
    if (scope === "channel") {
      return !isNotificationEnabled || !isRoomNotificationEnabled;
    } else if (scope === "dm") {
      return !isDmNotificationEnabled;
    }
    return false;
  }, [conversationId, isNotificationEnabled, isRoomNotificationEnabled, isDmNotificationEnabled]);

  const key = useMemo(() => {
    if (conversation == null) return "";
    if (scope === "channel") {
      const _conversation = conversation as IChannelMember;
      return `${_conversation.id}${_conversation.channel.name}${_conversation.emoji}`;
    } else {
      return conversation.id;
    }
  }, [conversation]);

  const getConversationInfo = useCallback(
    (item: IDirectMessage | IChannelMember) => {
      if (scope === "channel") {
        return { id: (item as IChannelMember).channel?.id, name: (item as IChannelMember).channel?.name, count: 0, nameWithCount: "" };
      } else if (scope === "dm") {
        const members = (item as IDirectMessage).conversation.members;
        const name = computeConversationTitleFromMemberList(members);
        return { id: (item as IDirectMessage).conversation.id, name, count: members.length - 1, nameWithCount: `${members.length - 1} ${name}` };
      }
      return { id: "", name: "", count: 0, nameWithCount: "" };
    },
    [scope],
  );

  const routeToDestination = useMemo(() => {
    if (conversation == null) return "";
    if (room != null) return `/dashboard/rooms/${room.id}/${scope}/${getConversationInfo(conversation).id}`;
    return `/dashboard/dms/${getConversationInfo(conversation).id}`;
  }, [room, conversation]);

  const handleCloseDm = (idDm: string): MouseEventHandler<HTMLButtonElement> => {
    return async (event) => {
      event.preventDefault();
      const data = {
        closeFromRoom: room?.id,
      };
      await api.directMessages.closeDirectMessage(idDm, room?.id !== undefined ? data : {}, {
        defaultToastErrorMessage: t("dms.toasts.close.error"),
      });
      if (room?.id != null) {
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, QueryKeys.DMS, room.id] });
        navigate(`/dashboard/rooms/${room.id}`);
      } else {
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_DMS] });
        navigate(`/dashboard/dms`);
      }
    };
  };

  const getUserAvatarUrl = useCallback(
    (userId?: string) => {
      if (room == null) return "";
      return queryClient
        .getQueryData<IConversationMember[]>([QueryKeys.ROOMS, room.id, QueryKeys.MEMBERS])
        ?.find((member) => member.user.id === userId)?.user.profilePicture?.url;
    },
    [room],
  );

  return { getConversationInfo, handleCloseDm, routeToDestination, getUserAvatarUrl, shouldShowDisabledBell, key };
};
