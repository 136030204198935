import { type IMessage } from "@/api/client";
import { useUsersSystemMessage } from "@/components/roomPage/tabs/conversation/message/UsersSystemMessage/useUsersSystemMessage";
import { type FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";

interface UsersSystemMessageProps {
  type: NonNullable<IMessage["subtype"]>;
  userIds: string[];
}

export const UsersSystemMessage: FunctionComponent<UsersSystemMessageProps> = ({ userIds, type }) => {
  const { i18n } = useTranslation();
  const { commaSeparatedUsers, isLoading } = useUsersSystemMessage(userIds);

  if (isLoading) return null;

  return (
    <div className="flex flex-row text-xs text-gray-400">
      <Trans
        i18nKey={`systemMessages.users.${type}`}
        i18n={i18n}
        values={{ users: commaSeparatedUsers, count: userIds.length }}
        components={{ bold: <span className="mr-1 font-semibold" /> }}
      />
    </div>
  );
};
