import { TagColorEnum } from "@/api/client";

export enum DraggableType {
  TASK = "task",
  TASK_STATUS = "taskStatus",
}

export const TAGS_COLORS = {
  [TagColorEnum.Purple]: {
    backgroundColor: "#BA68C8",
    color: "#FFFFFF",
  },
  [TagColorEnum.Blue]: {
    backgroundColor: "#64B5F6",
    color: "#FFFFFF",
  },
  [TagColorEnum.Green]: {
    backgroundColor: "#81C784",
    color: "#FFFFFF",
  },
  [TagColorEnum.Yellow]: {
    backgroundColor: "#FFF176",
    color: "#000000",
  },
  [TagColorEnum.Orange]: {
    backgroundColor: "#FFB74D",
    color: "#FFFFFF",
  },
  [TagColorEnum.Red]: {
    backgroundColor: "#E57373",
    color: "#FFFFFF",
  },
  [TagColorEnum.Pink]: {
    backgroundColor: "#F48FB1",
    color: "#000000",
  },
  [TagColorEnum.Grey]: {
    backgroundColor: "#BDBDBD",
    color: "#FFFFFF",
  },
  [TagColorEnum.Black]: {
    backgroundColor: "#616161",
    color: "#FFFFFF",
  },
};

export const TAGS_DEFAULT_COLOR = TagColorEnum.Black;
