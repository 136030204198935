import { ImageComponent } from "@/components/shared/image/ImageComponent";
import { UserStatusBadgeComponent } from "@/components/shared/user/UserStatusBadgeComponent";
import { useUserQuery } from "@/hooks/queries/shared/user/useUserQuery";
import { type HTMLAttributes } from "react";

interface UserAvatarProps extends HTMLAttributes<HTMLDivElement> {
  userName?: string;
  userId?: string;
  size?: "xs" | "sm" | "md" | "lg";
  hideStatusBadge?: boolean;
}

export const UserAvatarComponent = ({ userName, size = "md", userId, hideStatusBadge = false, ...props }: UserAvatarProps): JSX.Element => {
  const classSizeMap = {
    xs: "rounded-[4px]",
    sm: "rounded-[4px]",
    md: "rounded-md",
    lg: "rounded-md",
  };
  const { data: user } = useUserQuery(userId);

  return (
    <figure {...props} className={`relative  ${props.className ?? ""}`} style={props.style}>
      <ImageComponent
        key={user?.profilePicture?.url}
        initial
        isUserImage
        className={`absolute aspect-square h-full overflow-hidden border border-gray-200 dark:border-dark-700 ${classSizeMap[size]}`}
        src={user?.profilePicture?.url ?? ""}
        alt={user != null ? `${user?.firstName ?? ""} ${user?.lastName ?? ""}` : userName ?? ""}
        size={size}
      />
      {hideStatusBadge ? null : <UserStatusBadgeComponent status={user?.activityStatus ?? null} isAbsolute />}
    </figure>
  );
};
