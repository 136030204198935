import { type ITask, type ITaskOne } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDeleteTagOnTaskMutation = (taskId?: string, roomId?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (tagId: string) => {
      const response = await api.tasks.deleteTaskTag(taskId ?? "", tagId);
      return response.data;
    },
    onMutate: (tagId: string) => {
      queryClient.setQueryData<ITaskOne>([QueryKeys.TASK, taskId], (oldData) => {
        if (oldData?.tags == null) return oldData;

        return {
          ...oldData,
          tags: oldData.tags.filter((tag) => tag.id !== tagId),
        };
      });

      queryClient.setQueriesData<ITask[]>(
        {
          exact: false,
          queryKey: [QueryKeys.TASKS],
        },
        (oldData) => {
          if (oldData == null) return oldData;

          return oldData.map((task) => {
            if (task.id !== taskId) return task;

            return {
              ...task,
              tags: task?.tags?.filter((tag) => tag.id !== tagId),
            };
          });
        },
      );
    },
  });
};
