import { type IChannelMember } from "@/api/client";
import { useEditChannelOrderMutation } from "@/hooks/mutations/channel/useEditChannelOrderMutation";
import { MouseSensor, useSensor, useSensors, type DragEndEvent } from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy } from "@dnd-kit/sortable";
import { useCallback, useState } from "react";

export const useChannelListReorder = (conversations: IChannelMember[]) => {
  const [isDragging, setIsDragging] = useState(false);
  const [orderedConversations, setOrderedConversations] = useState(conversations);

  const { mutate: reorderChannel } = useEditChannelOrderMutation();

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const sensors = useSensors(mouseSensor);

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, [isDragging]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over == null || active == null) {
        setIsDragging(false);
        return;
      }
      reorderChannel({ channelId: active.data.current?.channelId, to: over.data.current?.sortable.index });
      const newOrderedConversations = arrayMove(orderedConversations, active.data?.current?.sortable.index, over.data?.current?.sortable.index);
      setOrderedConversations(newOrderedConversations);

      setIsDragging(false);
    },
    [isDragging, orderedConversations, conversations],
  );

  return { handleDragStart, handleDragEnd, isDragging, setIsDragging, orderedConversations, setOrderedConversations, sensors, rectSortingStrategy };
};
