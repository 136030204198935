import { type ITag, type ITaskOne } from "@/api/client";
import { closeModal } from "@/components/shared/modal/ModalService";
import { ModalNames } from "@/constants/modalNames";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDeleteTagMutation = (tagId: string, roomId: string, taskId?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      await api.tags.deleteOne(tagId);
    },
    onMutate: async () => {
      queryClient.setQueryData<ITag[]>([QueryKeys.ROOMS, roomId, QueryKeys.TAGS], (oldTags) => {
        if (oldTags == null) return oldTags;
        return oldTags.filter(({ id }) => id !== tagId);
      });

      if (taskId == null) return;
      queryClient.setQueryData<ITaskOne>([QueryKeys.TASK, taskId], (oldTags) => {
        if (oldTags == null) return oldTags;

        return {
          ...oldTags,
          tags: oldTags.tags?.filter((tag) => tag.id !== tagId),
        };
      });
    },
    onSuccess: () => {
      closeModal(ModalNames.TAG_EDIT);
    },
  });
};
