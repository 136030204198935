import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateConversationNotificationSettingsMutation = (roomId: string | null) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ conversationId, isNotificationEnabled }: { conversationId: string; isNotificationEnabled: boolean }) => {
      const { data } = await api.my.setNotificationSettings(conversationId, {
        enableNotifications: isNotificationEnabled,
      });

      return data;
    },
    onError: (error: Error) => {
      console.error(error);
    },
    onSuccess: () => {
      if (roomId != null) {
        void queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, QueryKeys.NOTIFICATION_SETTINGS, roomId] });
      }
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.ME, QueryKeys.NOTIFICATION_SETTINGS] });
    },
  });
};
