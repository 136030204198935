import { type IRoomIdName } from "@/api/client";
import { RoomAvatarComponent } from "@/components/shared/room/RoomAvatarComponent";
import { useMobile } from "@/hooks/shared/useMobile";
import { type FunctionComponent } from "react";

interface IRoomItemComponentProps {
  room: IRoomIdName;
  onClick?: () => void;
}

export const RoomItemComponent: FunctionComponent<IRoomItemComponentProps> = ({ room, onClick }) => {
  const { isIos } = useMobile();

  const name = room.name ?? "";

  return (
    <div className="flex flex-row items-center px-2 py-1" onClick={onClick}>
      <div className="room-image aspect-square h-5 w-5 overflow-hidden rounded-md border border-stone-300 transition-all duration-200 ease-in-out dark:border-dark-700">
        <RoomAvatarComponent
          roomId={room.id}
          size="xs"
          className={`h-full transition-all duration-200 hover:bg-dark-25 dark:bg-dark-800 dark:hover:bg-dark-700 ${isIos ? "-mt-1" : ""}`}
        />
      </div>
      <div className="ml-1 text-xs font-normal text-zinc-500">{name}</div>
    </div>
  );
};
