import { createContext, useEffect, useState } from "react";
import { io, type Socket } from "socket.io-client";

export const SocketContext = createContext<Socket | null>(null);
export const SocketContextProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const _socket = io(import.meta.env.VITE_APP_SOCKET_URL, {
      transports: ["websocket"],
      query: { accessToken: localStorage.getItem("token") },
    });
    setSocket(_socket);

    const handleFocus = () => {
      if (!_socket.connected) {
        _socket.connect();
      }
    };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
      _socket.close();
    };
  }, []);
  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
