import { type IRoomMember } from "@/api/client";
import { PreferencesContext, usePreferences } from "@/hooks/shared/usePreferences";
import { defaultIdAccessor, reorderFromIdsOrder } from "@/utils/settings";
import { DndContext, MouseSensor, useSensor, useSensors, type DragEndEvent } from "@dnd-kit/core";
import { SortableContext, arrayMove, rectSortingStrategy } from "@dnd-kit/sortable";
import { useCallback, useContext, useEffect, useState } from "react";

export const useRoomListReorder = (rooms: IRoomMember[]) => {
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(mouseSensor);
  const { setPreference } = usePreferences();
  const { preferences } = useContext(PreferencesContext);

  const [orderedRooms, setOrderedRooms] = useState<IRoomMember[]>(rooms);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    // reorder rooms according to the local storage array of Ids
    const roomOrder = preferences?.[`roomOrder.${rooms[0]?.room.type}`] ?? [];
    setOrderedRooms(reorderFromIdsOrder(roomOrder, rooms, defaultIdAccessor));
  }, [rooms, preferences]);

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, [isDragging]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over == null || active == null) {
        setIsDragging(false);
        return;
      }
      const newOrderedRooms = arrayMove(orderedRooms, active.data?.current?.sortable.index, over.data?.current?.sortable.index);
      setOrderedRooms(newOrderedRooms);
      const reorderedRoomsIds = newOrderedRooms.map((room) => room.id);

      setPreference(`roomOrder.${rooms[0]?.room.type}`, reorderedRoomsIds);
      setIsDragging(false);
    },
    [isDragging, orderedRooms, rooms],
  );
  return { sensors, handleDragStart, handleDragEnd, orderedRooms, isDragging, DndContext, SortableContext, rectSortingStrategy };
};
