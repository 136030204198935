// organize-imports-ignore
import { AiOutlineDownload as DownloadIcon, AiOutlineZoomIn as ZoomInIcon, AiOutlineZoomOut as ZoomOutIcon } from "react-icons/ai";
import {
  FaForward as NextPageIcon,
  FaForwardFast as LastPageIcon,
  FaBackward as PreviousPageIcon,
  FaBackwardFast as FirstPageIcon,
} from "react-icons/fa6";
import { type IFile } from "@/api/client";
import { useDownload } from "@/hooks/shared/useDownload";
import { type Dispatch, type MouseEventHandler, type SetStateAction, useCallback, useMemo } from "react";
import { IoClose as CloseIcon } from "react-icons/io5";
import { eventNames } from "@/constants/eventNames";

interface IPdfPreviewControlPanelComponent {
  file: IFile;
  currentPage: number;
  totalPages: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  scale: number;
  setScale: (scale: number) => void;
  hidePageNavigation: boolean;
}

export const PdfViewerControlPanelComponent = ({
  file,
  currentPage,
  totalPages,
  setCurrentPage,
  scale,
  setScale,
  hidePageNavigation,
}: IPdfPreviewControlPanelComponent) => {
  const MAX_SCALE = 2.0;
  const MIN_SCALE = 0.6;
  const SCALE_STEP = 0.2;
  const ICON_SIZE = 20;

  const { download } = useDownload();

  const isMinZoom = useMemo(() => scale < MIN_SCALE, [scale]);
  const isMaxZoom = useMemo(() => scale >= MAX_SCALE, [scale]);

  const isFirstPage = useMemo(() => currentPage === 1, [currentPage]);
  const isLastPage = useMemo(() => currentPage === totalPages, [currentPage, totalPages]);

  const firstPageClass = useMemo(() => (isFirstPage ? "pointer-events-none opacity-30" : "cursor-pointer"), [isFirstPage]);
  const lastPageClass = useMemo(() => (isLastPage ? "pointer-events-none opacity-30" : "cursor-pointer"), [isLastPage]);

  const goToFirstPage = useCallback(() => {
    setCurrentPage(1);
  }, []);

  const goToPreviousPage = useCallback(() => {
    setCurrentPage((previousPage) => previousPage - 1);
  }, []);

  const goToNextPage = useCallback(() => {
    setCurrentPage((previousPage) => previousPage + 1);
  }, []);

  const goToLastPage = useCallback(() => {
    setCurrentPage(totalPages);
  }, [totalPages, setCurrentPage]);

  const handleDownload = useCallback<MouseEventHandler<HTMLButtonElement>>((_event) => {
    download(file.url);
  }, []);

  const handleClose = () => {
    const event = new CustomEvent(eventNames.CLOSE_DOCUMENT_VIEWER);
    window.dispatchEvent(event);
  };

  const zoomOutClass = isMinZoom ? "pointer-events-none opacity-30" : "cursor-pointer opacity-80";
  const zoomInClass = isMaxZoom ? "pointer-events-none opacity-30" : "cursor-pointer opacity-80";

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - SCALE_STEP);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + SCALE_STEP);
  };

  return (
    <div className="control-panel mb-3 ml-auto flex items-center justify-between rounded-bl-xl bg-zinc-600 p-3 text-white">
      {!hidePageNavigation ? (
        <div className="page-nav flex items-center justify-center border-r border-r-white/20">
          <button className={`mx-2 transition-all hover:opacity-100 ${firstPageClass}`} type="button" onClick={goToFirstPage}>
            <FirstPageIcon size={ICON_SIZE} />
          </button>
          <button className={`mr-2 transition-all hover:opacity-100 ${firstPageClass}`} type="button" onClick={goToPreviousPage}>
            <PreviousPageIcon size={ICON_SIZE} />
          </button>
          <span className="text-center  text-xs">
            {currentPage}/{totalPages}
          </span>
          <button className={`ml-2 transition-all hover:opacity-100 ${lastPageClass}`} type="button" onClick={goToNextPage}>
            <NextPageIcon size={ICON_SIZE} />
          </button>
          <button className={`mx-2 transition-all hover:opacity-100 ${lastPageClass}`} type="button" onClick={goToLastPage}>
            <LastPageIcon size={ICON_SIZE} />
          </button>
        </div>
      ) : null}

      <div className="zoom-nav mx-1 flex h-full items-center justify-center border-r border-r-white/20 px-2">
        <button className="opacity-80 transition-all hover:opacity-100" type="button" onClick={zoomOut}>
          <ZoomOutIcon size={ICON_SIZE} className={zoomOutClass} />
        </button>
        <span className="w-10 text-center text-xs">{(scale * 100).toFixed()}%</span>
        <button className="opacity-80 transition-all hover:opacity-100" type="button" onClick={zoomIn}>
          <ZoomInIcon size={ICON_SIZE} className={zoomInClass} />
        </button>
      </div>
      <button className="mx-2 opacity-80 transition-all hover:opacity-100" type="button" onClick={handleDownload}>
        <DownloadIcon size={ICON_SIZE} />
      </button>
      <button className="bg-transparent text-white opacity-80 transition-all hover:opacity-100" onClick={handleClose}>
        <CloseIcon size={ICON_SIZE} />
      </button>
    </div>
  );
};
