import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useChannelQuery } from "@/hooks/queries/channels/useChannelQuery";

import { SettingLineComponent } from "@/components/shared/settings/SettingLineComponent";
import { useChannelNotificationSettings } from "@/hooks/shared/notificationSettings/useChannelNotificationSettings";
import { format } from "date-fns";

interface IChannelNotificationSettingsTabComponentProps {
  channelId?: string;
  roomId: string;
}
export const ChannelNotificationSettingsTabComponent = ({
  channelId = undefined,
  roomId,
}: IChannelNotificationSettingsTabComponentProps): JSX.Element => {
  const { t } = useTranslation();

  const { data: channelResponse } = useChannelQuery(channelId);

  const channel = useMemo(() => channelResponse?.channel, [channelResponse]);
  const { onToggle, isNotificationEnabled } = useChannelNotificationSettings(roomId, channelResponse?.channel?.conversation?.id);

  return (
    <ModalTabComponent
      key={channelId}
      footer={
        <>
          <ModalFooterComponent
            actions={[]}
            info={t("channelModal.tabs.about.footerInfo", {
              date: format(new Date(channel?.createdAt ?? ""), t("date.shortFormat")),
            })}
          ></ModalFooterComponent>
        </>
      }
    >
      <SettingLineComponent
        onToggle={onToggle}
        isEnabled={isNotificationEnabled}
        title={t("channelModal.tabs.settings.enableNotifications")}
        subtitle={t("channelModal.tabs.settings.enableNotificationsDescription")}
      />
    </ModalTabComponent>
  );
};
