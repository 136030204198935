import { GoogleTargetFeatureEnum } from "@/api/client";
import { CreateCalendarEventDrawerComponent } from "@/components/roomPage/modals/createCalendarEvent/CreateCalendarEventDrawerComponent";
import { AddCalendarAccountModal } from "@/components/shared/calendar/modals/AddCalendarAccountModal";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { closeModal, openModal } from "@/components/shared/modal/ModalService";
import { ModalNames } from "@/constants/modalNames";
import { QueryKeys } from "@/constants/queryKeys";
import { useGoogleSignIn } from "@/hooks/authentification/useGoogleSignIn";
import { useGetGoogleCalendarEventsQuery } from "@/hooks/mutations/google/useGetGoogleCalendarEventsQuery";
import { useSendGoogleAuthorizationCodeMutation } from "@/hooks/mutations/google/useSendGoogleAuthorizationCodeMutation";
import { convertDateToRFC3339 } from "@/utils/date";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useMemo } from "react";

const GOOGLE_CALENDAR_SCOPE = "https://www.googleapis.com/auth/calendar.events";

export const useCreateCalendarEvent = (conversationId: string) => {
  const queryClient = useQueryClient();
  const { openDrawer } = useContext(DrawerContext);
  const nowDateWhenOpeningPage = useMemo(() => convertDateToRFC3339(new Date()), [conversationId]);
  const { isError, isLoading: isCheckingIfUserHasLinkedGoogleAccount } = useGetGoogleCalendarEventsQuery(nowDateWhenOpeningPage);

  const onOpenCreateCalendarEventDrawer = useCallback(() => {
    void queryClient.invalidateQueries({
      exact: false,
      queryKey: [QueryKeys.GOOGLE_CALENDAR_EVENTS],
    });
    openDrawer({
      drawerType: DrawerType.CREATE_CALENDAR_EVENT,
      component: <CreateCalendarEventDrawerComponent conversationId={conversationId} />,
    });
  }, [conversationId, openDrawer]);

  const { mutate: sendAuthorizationCode } = useSendGoogleAuthorizationCodeMutation(onOpenCreateCalendarEventDrawer);

  const onSendAuthorizationCode = useCallback(
    (authorizationCode: string, hasRedirectUri: boolean, targetFeature: GoogleTargetFeatureEnum) => {
      sendAuthorizationCode({ code: authorizationCode, hasRedirectUri, targetFeature });
    },
    [sendAuthorizationCode],
  );

  const { signIn: onGoogleLogin } = useGoogleSignIn({
    onSuccess: (code) => {
      onSendAuthorizationCode(code, false, GoogleTargetFeatureEnum.Calendar);
    },
    onAppOpenedWithScheme: (code) => {
      onSendAuthorizationCode(code, true, GoogleTargetFeatureEnum.Calendar);
    },
    scope: GOOGLE_CALENDAR_SCOPE,
  });

  const onOpenAddCalendarAccountModal = useCallback(() => {
    openModal(ModalNames.ADD_CALENDAR_ACCOUNT, <AddCalendarAccountModal conversationId={conversationId} />);
  }, [conversationId]);

  const onCreateCalendarEvent = useCallback(() => {
    if (isCheckingIfUserHasLinkedGoogleAccount) {
      return;
    }
    if (isError) {
      onOpenAddCalendarAccountModal();
    } else {
      onOpenCreateCalendarEventDrawer();
    }
  }, [conversationId, isError, isCheckingIfUserHasLinkedGoogleAccount]);

  const onLinkGoogleCalendar = () => {
    onGoogleLogin();
    if (!window.IS_ELECTRON) {
      closeModal(ModalNames.ADD_CALENDAR_ACCOUNT);
    }
  };

  return { onCreateCalendarEvent, onLinkGoogleCalendar };
};
