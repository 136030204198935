import { useMemo } from "react";

export const useMobile = (): { isIos: boolean } => {
  const isIos = useMemo<boolean>(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }, []);

  return { isIos };
};
