import { RoomMemberStatus, type ConversationMemberStatus, type IChannelMember } from "@/api/client";
import { ConversationListComponent } from "@/components/roomPage/tabs/conversation/ConversationLists/ConversationListComponent";
import { useChannelList } from "@/components/roomPage/tabs/conversation/ConversationLists/useChannelList";
import { CustomSelectComponent } from "@/components/shared/form/select/CustomSelectComponent";
import { RoomContext } from "@/contexts/RoomContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

interface IChannelListComponentProps {
  channels: IChannelMember[];
}

export const ChannelListComponent = ({ channels }: IChannelListComponentProps): JSX.Element => {
  const { addChannel, closeChannelList, handleSelectChannelsStatus, customSelectStyles, conversationStatusOptions } = useChannelList();
  const roomContext = useContext(RoomContext);
  const { t } = useTranslation();
  return (
    <>
      <ConversationListComponent
        title={
          roomContext?.roomResponse?.member.status === RoomMemberStatus.Archived ? (
            t("general.archivedChannel", { count: channels.length })
          ) : (
            <CustomSelectComponent<ConversationMemberStatus>
              onChange={(option) => {
                if (option?.value == null) {
                  return;
                }
                handleSelectChannelsStatus(option.value);
              }}
              options={conversationStatusOptions}
              customStyles={customSelectStyles}
              dropdownIndicatorStyle="vanilla"
              isSearchable={false}
              placeholder={t("general.loading")}
              value={conversationStatusOptions.find((option) => {
                return roomContext?.channelStatusSelected === option.value;
              })}
            ></CustomSelectComponent>
          )
        }
        isOnHomePage={false}
        conversations={channels}
        addConversation={addChannel}
        closeList={closeChannelList}
        scope="channel"
      />
    </>
  );
};
