import { DmTabComponent } from "@/components/roomPage/modals/dm/tabs/DmTabComponent";
import { ModalLayoutComponent } from "@/components/shared/modal/ModalLayoutComponent";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
interface IDmModalProps {
  roomId: string | undefined;
}

export const DmModalComponent = ({ roomId }: IDmModalProps): JSX.Element => {
  const { t } = useTranslation();
  const tabs = useMemo(
    () => [
      {
        label: t("channelModal.tabs.about.label"),
        content: <DmTabComponent roomId={roomId} />,

        id: "dm",
        disabled: false,
      },
    ],
    [roomId],
  );

  return <ModalLayoutComponent key={roomId ?? 0} tabs={tabs} title={t("dms.modal.title")} size="md" name="dmModal" defaultTab="dm" />;
};
