import { type ITaskStatus } from "@/api/client";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { useTaskStatusColumnPopinContent } from "@/components/shared/tasks/TaskStatusColumnPopinComponent/useTaskStatusColumnPopinContent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CgCheck, CgClose } from "react-icons/cg";
import { FaEdit, FaTrash } from "react-icons/fa";

interface ITaskStatusColumnPopinContentComponent {
  status: ITaskStatus;
}

export const TaskStatusColumnPopinContentComponent: FunctionComponent<ITaskStatusColumnPopinContentComponent> = ({ status }) => {
  const { t } = useTranslation();

  const { isRenaming, handleRenameClick, handleCancelRename, handleRenameConfirm, handleRenameChange, handleDeleteClick, handleRenameKeyDown } =
    useTaskStatusColumnPopinContent(status);

  return (
    <nav
      className="w-48 rounded-lg"
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={`mb-1 flex w-full cursor-pointer flex-col items-start rounded-t-lg p-2 ${!isRenaming ? "hover:bg-zinc-100" : ""}`}>
        <button className="flex w-full justify-between" onClick={handleRenameClick}>
          <span>{t("tasks.column.rename.label")}</span>
          <FaEdit className="h-4 w-4" />
        </button>
        {isRenaming ? (
          <div className="mt-2 flex w-full items-center">
            <TextInputComponent
              autoFocus
              className="h-4.5 mb-[0]"
              defaultValue={status.name}
              onChange={handleRenameChange}
              onKeyDown={handleRenameKeyDown}
            />
            <button onClick={handleRenameConfirm}>
              <CgCheck className="h-6 w-6" />
            </button>
            <button onClick={handleCancelRename}>
              <CgClose className="h-4 w-4" />
            </button>
          </div>
        ) : null}
      </div>
      <button
        onClick={handleDeleteClick}
        className="flex w-full cursor-pointer items-start justify-between rounded-b-lg p-2 text-rogerRed-600  hover:bg-rogerRed-600 hover:text-white"
      >
        <span>{t("tasks.column.delete.label")}</span>
        <FaTrash className="h-4 w-4" />
      </button>
    </nav>
  );
};
