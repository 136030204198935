import { UserActivityStatus } from "@/api/client";
import { UserStatusComponent } from "@/components/shared/user/UserStatusComponent";
import { type FunctionComponent } from "react";

interface IUserStatusPopinContentComponentProps {
  status: UserActivityStatus;
  onStatusSelected: (status: UserActivityStatus) => void;
}

export const UserStatusPopinContentComponent: FunctionComponent<IUserStatusPopinContentComponentProps> = ({ onStatusSelected, status }) => {
  return (
    <div className="flex cursor-pointer flex-col items-start px-1.5 py-1">
      <UserStatusComponent
        status={UserActivityStatus.Active}
        isDisabled={status === UserActivityStatus.Active || status === UserActivityStatus.Inactive}
        onClick={
          status === UserActivityStatus.Active || status === UserActivityStatus.Inactive
            ? undefined
            : () => {
                onStatusSelected(UserActivityStatus.Active);
              }
        }
      />
      <div className="h-1" />
      <UserStatusComponent
        status={UserActivityStatus.Offline}
        isDisabled={status === UserActivityStatus.Offline}
        onClick={
          status === UserActivityStatus.Offline
            ? undefined
            : () => {
                onStatusSelected(UserActivityStatus.Offline);
              }
        }
      />
    </div>
  );
};
