import { useChannelActiveMemberQuery } from "@/hooks/queries/channels/useChannelActiveMemberQuery";
import { useMyCollaboratorsQuery } from "@/hooks/queries/collaborators/useMyCollaboratorsQuery";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { useRoomActiveMemberQuery } from "@/hooks/queries/rooms/useRoomActiveMemberQuery";
import { type SimpleUser } from "@/interfaces/user";
import { useEffect, useMemo, useRef, useState } from "react";
import removeAccents from "remove-accents";

const sortMembersByName = (a: SimpleUser, b: SimpleUser) => {
  const aFullName = `${a.firstName ?? ""} ${a.lastName ?? ""}`;
  const bFullName = `${b.firstName ?? ""} ${b.lastName ?? ""}`;
  return aFullName.localeCompare(bFullName);
};

export const usePickUsersPopinContent = (roomId: string | null, channelId: string | null, isVisible: boolean) => {
  const { data: meData } = useMeQuery();
  const { data: roomActiveMembers, isLoading: isRoomActiveMembersQueryLoading } = useRoomActiveMemberQuery(roomId ?? undefined, isVisible);
  const { data: channelActiveMembers, isLoading: isChannelActiveMembersQueryLoading } = useChannelActiveMemberQuery(channelId, isVisible);
  const { data: collaborators, isLoading: isCollaboratorsQueryLoading } = useMyCollaboratorsQuery("", roomId == null && !isVisible);
  const [search, setSearch] = useState<string>("");
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isVisible) {
      ref.current?.focus();
    }
  }, [isVisible]);

  const onSearchChange = (value: string) => {
    setSearch(value);
  };

  const users: SimpleUser[] = useMemo(() => {
    let resultUsers: SimpleUser[] = [];

    if (channelId != null) {
      resultUsers = (channelActiveMembers ?? []).map<SimpleUser>((channelMember) => ({
        ...channelMember.user,
      }));
    } else if (roomId != null) {
      resultUsers = (roomActiveMembers ?? []).map<SimpleUser>((roomMember) => ({
        ...roomMember,
        id: roomMember.userId,
      }));
    } else {
      if (meData != null) {
        resultUsers = [meData, ...(collaborators ?? [])];
      }
    }

    const result = resultUsers.filter((user) => {
      const fullName = `${user.firstName ?? ""} ${user.lastName ?? ""} ${user.email}`;
      return removeAccents(fullName.toLowerCase()).includes(removeAccents(search.toLowerCase()));
    });

    return result.sort(sortMembersByName);
  }, [roomActiveMembers, collaborators, search, channelActiveMembers, collaborators, roomId, channelId, meData]);

  const isLoading =
    (channelId != null && isChannelActiveMembersQueryLoading) ||
    (roomId != null && isRoomActiveMembersQueryLoading) ||
    (roomId == null && isCollaboratorsQueryLoading);

  return {
    users,
    isLoading,
    search,
    onSearchChange,
    ref,
  };
};
