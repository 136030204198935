import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";

export const useSubscriptionUrlMutation = () => {
  return useMutation({
    mutationFn: async (planId: string) => {
      const response = await api.pages.getSubscriptionConfirmationPage(planId, {
        defaultToastErrorMessage: null,
      });
      return response.data.data;
    },
  });
};
