import { closeModal } from "@/components/shared/modal/ModalService";
import { ModalNames } from "@/constants/modalNames";
import { useGoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useGoogleSignIn = ({
  onSuccess,
  onAppOpenedWithScheme,
  uxMode,
  scope,
  redirectUri,
}: {
  onSuccess: (code: string) => void;
  onAppOpenedWithScheme?: (code: string) => void;
  uxMode?: "popup" | "redirect";
  scope?: string;
  redirectUri?: string;
}) => {
  const { t } = useTranslation();
  const openGooglePopup = useGoogleLogin({
    onSuccess: (result) => {
      onSuccess(result.code);
    },
    onError: (error) => {
      toast.error(t("google.signin.error.unknown"));
      console.error(error);
    },
    flow: "auth-code",
    scope,
    ux_mode: uxMode ?? "popup",
    redirect_uri: redirectUri,
  });

  const signIn = () => {
    if (window.IS_ELECTRON) {
      const url = `${import.meta.env.VITE_APP_GOOGLE_ELECTRON_SIGNIN_URL}${scope != null ? `?scope=${scope}` : ""}`;
      window.open(url, "_blank", "noreferrer");
    } else {
      openGooglePopup();
    }
  };

  const onUrlSchemeOpened = (data: { url: string }) => {
    if (onAppOpenedWithScheme == null) {
      return;
    }
    closeModal(ModalNames.ADD_CALENDAR_ACCOUNT);
    if (data.url.includes(`${import.meta.env.VITE_APP_URL_SCHEME}://`)) {
      const parameters = data.url.split(`${import.meta.env.VITE_APP_URL_SCHEME}://`);
      if (parameters.length > 1 && parameters[1].length > 0) {
        const authorizationCode = parameters[1].split("authorization_code=")[1];
        onAppOpenedWithScheme(authorizationCode);
      } else {
        toast.error(t("google.signin.error.urlParametersInvalid"));
      }
    } else {
      toast.error(t("google.signin.error.urlSchemeInvalid"));
    }
  };

  useEffect(() => {
    if (!window.IS_ELECTRON || onAppOpenedWithScheme == null) {
      return;
    }

    window.electron.on("open-url", onUrlSchemeOpened);

    return () => {
      window.electron.removeAllListeners("open-url");
    };
  }, []);

  return { signIn };
};
