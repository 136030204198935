import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { usePickUsersPopinContent } from "@/components/shared/user/PickUsersPopinComponent/usePickUsersPopinContent";
import { UserItemComponent } from "@/components/shared/user/UserItemComponent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BsCheck as CheckIcon } from "react-icons/bs";

interface IPickUsersPopinContentComponentProps {
  roomId: string | null;
  channelId: string | null;
  isVisible: boolean;
  selectedUserIds: string[];
  onUserSelectionChange: (userId: string, isSelected: boolean) => void;
}

export const PickUsersPopinContentComponent: FunctionComponent<IPickUsersPopinContentComponentProps> = ({
  roomId,
  channelId,
  isVisible,
  selectedUserIds,
  onUserSelectionChange,
}) => {
  const { t } = useTranslation();
  const { users, isLoading, onSearchChange, ref, search } = usePickUsersPopinContent(roomId, channelId, isVisible);

  return isLoading ? (
    <LoaderComponent />
  ) : (
    <div className="w-52 cursor-pointer" data-no-dnd>
      <div className="w-full p-2">
        <TextInputComponent
          ref={ref}
          value={search}
          onChange={(e) => {
            onSearchChange(e.target.value);
          }}
          placeholder={t("tasks.assignee.search.placeholder")}
        />
      </div>
      <div className="max-h-32 w-full overflow-scroll">
        {users.map((user) => {
          const isSelected = selectedUserIds.includes(user.id);
          return (
            <UserItemComponent
              key={user.id}
              user={user}
              onClick={() => {
                onUserSelectionChange(user.id, !isSelected);
              }}
              RightIcon={isSelected ? <CheckIcon className="h-4 w-4" /> : null}
            />
          );
        })}
      </div>
    </div>
  );
};
