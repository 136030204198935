import { useEmailHistory } from "@/components/roomPage/tabs/emails/emailHistory/useEmailHistory";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BsThreeDots } from "react-icons/bs";

interface IEmailHistoryComponentProps {
  history?: string;
}

export const EmailHistoryComponent: FunctionComponent<IEmailHistoryComponentProps> = ({ history }) => {
  const { t } = useTranslation();
  const { isHistoryVisible, handleHistoryClick } = useEmailHistory();
  if (history == null || history === "") return null;
  return (
    <div className="border-t px-3 py-2 text-zinc-400">
      {isHistoryVisible ? (
        <>
          <button
            className="mb-4 rounded-lg bg-zinc-200 px-2 text-2xs text-black opacity-70 transition-colors hover:opacity-100"
            onClick={handleHistoryClick}
          >
            {t("roomPage.tabs.mails.buttons.hideHistory")}
          </button>
          <div className="whitespace-pre-wrap">{history}</div>
        </>
      ) : (
        <button className="rounded-lg bg-zinc-200 px-1 text-black opacity-70 transition-colors hover:opacity-100" onClick={handleHistoryClick}>
          <BsThreeDots className="h-3 w-3" />
        </button>
      )}
    </div>
  );
};
