import { TaskStatusItemComponent } from "@/components/shared/tasks/TaskStatusItemComponent";
import { RoomContext } from "@/contexts/RoomContext";
import { useTaskStatusesQuery } from "@/hooks/queries/tasks/useTaskStatusesQuery";
import { useContext, useMemo, type FunctionComponent } from "react";

interface ITaskStatusPopinContentComponentProps {
  onStatusSelected: (status: string) => void;
  roomId?: string | null;
}

export const TaskStatusPopinContentComponent: FunctionComponent<ITaskStatusPopinContentComponentProps> = ({ onStatusSelected, roomId }) => {
  const roomContext = useContext(RoomContext);

  const currentRoomId = useMemo(() => {
    return roomId ?? roomContext?.roomResponse?.room?.id;
  }, [roomContext?.roomResponse?.room?.id]);

  const { data: taskStatusesData } = useTaskStatusesQuery(currentRoomId);

  return (
    <div className="flex cursor-pointer flex-col items-start p-2" key={JSON.stringify(taskStatusesData)}>
      {taskStatusesData?.map((taskStatus) => (
        <TaskStatusItemComponent
          key={taskStatus.id}
          status={taskStatus}
          onClick={() => {
            onStatusSelected(taskStatus.id);
          }}
        />
      ))}
    </div>
  );
};
