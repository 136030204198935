import RogerLogo from "@/assets/images/logo-roger.svg?react";
import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const DownloadAppComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const detectOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.includes("win")) {
      return "windows";
    } else if (userAgent.includes("mac")) {
      return "mac";
    }

    return "unknown";
  };

  const platform = detectOS();
  const releaseBucket: string = import.meta.env.VITE_APP_RELEASE_BUCKET_URL;
  const ymlFile = `${releaseBucket}/${platform === "mac" ? "latest-mac" : "latest"}.yml`;

  const getUrlFromYml = (yml: string, platform: string) => {
    const extension = platform === "mac" ? "dmg" : "exe";
    const lines = yml.split("\n");

    let url = "";
    for (const line of lines) {
      if (line.trim().startsWith("- url:") && line.trim().endsWith(`.${extension}`)) {
        url = line.trim().replace("- url:", "").trim();
        break;
      }
    }
    return url;
  };

  useEffect(() => {
    axios
      .get(ymlFile)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("La requête n'a pas abouti");
        }
        return response.data;
      })
      .then((yamlContent) => {
        window.location.href = `${releaseBucket}/${getUrlFromYml(yamlContent, platform)}`;
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération du fichier distant :", error);
      });
  }, []);
  return (
    <>
      <div className="flex h-screen flex-col items-center justify-center ">
        <div className="w-10/12 sm:w-6/12 lg:w-4/12 xl:w-3/12">
          <h1 className="mb-8 flex cursor-pointer flex-col items-center justify-center">
            <RogerLogo className="mr-4 h-12 w-12" />
            <span className="pb-2.5 font-[rubik] text-7xl text-black dark:text-white">roger</span>
            <span className="mt-4 font-[rubik] text-lg text-white ">{t("download.description")}</span>
          </h1>
        </div>
      </div>
    </>
  );
};
