import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";

interface IUpdateNotificationReadStatusMutation {
  notificationId: string;
  isRead: boolean;
}

export const useUpdateNotificationReadStatusMutation = () => {
  return useMutation({
    mutationFn: async ({ notificationId, isRead }: IUpdateNotificationReadStatusMutation) => {
      return await api.notifications.updateNotificationReadStatus(notificationId, { isRead });
    },
  });
};
