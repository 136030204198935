import { type IAuthUpdateMyPassword } from "@/api/client";
import GoogleLogo from "@/assets/images/logo-google.svg?react";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { SettingLineComponent } from "@/components/shared/settings/SettingLineComponent";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useMeMutations } from "@/hooks/mutations/me/useMeMutations";
import { PreferencesContext, usePreferences } from "@/hooks/shared/usePreferences";
import { EMAIL_PROVIDER, GOOGLE_PROVIDER, type UserProviderType } from "@/interfaces/user";
import { passwordValidation } from "@/utils/formValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { useContext, useEffect, useMemo } from "react";
import { useForm, type SubmitErrorHandler, type SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const EditAccountTab = (): JSX.Element => {
  const { setPreference } = usePreferences();
  const { preferences: preferencesContext } = useContext(PreferencesContext);
  const me = useContext(AuthenticationContext);

  const authProvider: UserProviderType | null = (me?.provider ?? null) as UserProviderType | null;
  const { updatePasswordMutation } = useMeMutations();
  const { t } = useTranslation();
  const inputs = useMemo(
    () => [
      {
        name: "oldPassword",
        label: t("forms.fields.oldPassword"),
        type: "password",
        defaultValue: "",
      },
      {
        name: "password",
        label: t("forms.fields.newPassword"),
        type: "password",
        defaultValue: "",
      },
    ],
    [],
  );

  const validationSchema = Yup.object().shape({
    password: passwordValidation(t),
    oldPassword: passwordValidation(t),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitted },
    reset,
  } = useForm<IAuthUpdateMyPassword>({
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  // reset form on submit
  useEffect(() => {
    if (isSubmitted) {
      reset();
    }
  }, [isSubmitted]);

  const onSubmit: SubmitHandler<IAuthUpdateMyPassword> = (data) => {
    updatePasswordMutation.mutate(data);
  };
  const onError: SubmitErrorHandler<IAuthUpdateMyPassword> = (err) => {
    console.error(err);
  };

  return (
    <>
      {me != null && preferencesContext != null && (
        <ModalTabComponent
          footer={
            <ModalFooterComponent
              info={t("header.editProfile.modal.tabs.profile.accountCreatedOn", { date: format(new Date(me.createdAt), t("date.shortFormat")) })}
            />
          }
        >
          <>
            <div className="mb-2 text-sm font-medium text-zinc-500 dark:text-dark-300">
              {t("header.editProfile.modal.tabs.account.linkedAccounts")}
            </div>
            <div className="mb-2 flex items-start">
              {authProvider === GOOGLE_PROVIDER ? (
                <div className="flex flex-row items-center rounded border bg-zinc-100 px-4 py-2">
                  <GoogleLogo className="h-5 w-5" />
                  <div className="ml-2 text-sm font-medium text-zinc-500 dark:text-dark-300">Google</div>
                </div>
              ) : null}
              {authProvider === EMAIL_PROVIDER ? (
                <div className="flex flex-row items-center">
                  <div className="text-sm font-normal text-zinc-400 dark:text-dark-300">{t("header.editProfile.modal.tabs.account.emailLinked")}</div>
                </div>
              ) : null}
            </div>

            <div className="mb-2 text-sm font-medium text-zinc-500 dark:text-dark-300">
              {t("header.editProfile.modal.tabs.account.changePassword.title")}{" "}
              {authProvider !== EMAIL_PROVIDER ? (
                <span className="text-xs font-normal text-zinc-300 dark:text-dark-200">
                  {t("header.editProfile.modal.tabs.account.changePassword.ssoConnected")}
                </span>
              ) : null}
            </div>
            <form className="mb-2 flex flex-col items-end justify-start" onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="flex w-full flex-wrap items-start justify-between">
                {inputs.map((input) => (
                  <div key={`cti-${input.name}`} className="mb-4 mr-2 flex-1 last:mr-0">
                    <TextInputComponent
                      register={register}
                      {...input}
                      error={errors[input.name as keyof typeof errors]?.message}
                      hasPasswordPopoverOffset
                      disabled={authProvider !== EMAIL_PROVIDER}
                    />
                  </div>
                ))}
                {(updatePasswordMutation.isPending ?? false) && <LoaderComponent className="-ml-6" />}
              </div>
              <ButtonComponent status="primary" type="submit" disabled={!isValid || authProvider !== EMAIL_PROVIDER}>
                {t("header.editProfile.modal.tabs.account.savePassword")}
              </ButtonComponent>
            </form>

            {import.meta.env.VITE_APP_ENV !== "production" ? (
              <div className="mt-4 flex w-full items-center dark:text-dark-300">
                <SettingLineComponent
                  isEnabled={preferencesContext.darkMode}
                  onToggle={(e) => {
                    setPreference("darkMode", e);
                  }}
                  title={t("header.editProfile.modal.tabs.account.darkMode")}
                />
              </div>
            ) : null}
          </>
        </ModalTabComponent>
      )}
    </>
  );
};
