import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { useTranslation } from "react-i18next";

import { SettingLineComponent } from "@/components/shared/settings/SettingLineComponent";
import { useRoomNotificationSettings } from "@/hooks/shared/notificationSettings/useRoomNotificationSettings";

interface IRoomNotificationSettingsTabComponentProps {
  roomId: string;
}
export const RoomNotificationSettingsTabComponent = ({ roomId }: IRoomNotificationSettingsTabComponentProps): JSX.Element => {
  const { t } = useTranslation();

  const { onToggle, isNotificationEnabled } = useRoomNotificationSettings(roomId);

  return (
    <ModalTabComponent key={roomId}>
      <SettingLineComponent
        onToggle={onToggle}
        isEnabled={isNotificationEnabled}
        title={t("createRoomModal.tabs.settings.enableNotifications")}
        subtitle={t("createRoomModal.tabs.settings.enableNotificationsDescription")}
      />
    </ModalTabComponent>
  );
};
