import { type IRoom, type IRoomAdd, type IRoomMember, type IUpdateRoom } from "@/api/client";
import { eventNames } from "@/constants/eventNames";
import { defaultRoleSlug } from "@/constants/roles";
import { roomType } from "@/constants/room";
import { useCreateRoomMutation } from "@/hooks/mutations/rooms/useCreateRoomMutation";
import { useRoomMutation } from "@/hooks/mutations/rooms/useRoomMutation";
import { PreferencesContext, usePreferences } from "@/hooks/shared/usePreferences";
import { type IModalAction } from "@/interfaces/modal";
import { canPerform } from "@/utils/utilities";
import { format } from "date-fns";
import { useContext, useEffect, useMemo, useState } from "react";
import { useForm, type SubmitErrorHandler, type SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IRoomPictureUpdatedEvent extends Event {
  roomPicture: {
    id: string;
  };
  file: string;
}

export const useAboutTab = ({ isSuccess, member, room }: { room?: IRoom | undefined; member?: IRoomMember; isSuccess: boolean }) => {
  const { t } = useTranslation();
  const { setPreference } = usePreferences();
  const { preferences } = useContext(PreferencesContext);
  const [customRoomName, setCustomRoomName] = useState<string | undefined>(preferences?.[`room.${room?.id ?? ""}`]?.name);
  const [tempPicture, setTempPicture] = useState<string | null>(null);

  const canUpdateRoom = useMemo(() => {
    if (member == null) return true;
    if (member?.role?.abilities == null) return false;
    return canPerform(member.role.abilities, "room.update");
  }, [member]);

  const canEditName = useMemo(() => {
    return canUpdateRoom || room?.type === roomType.PROJECT || room == null;
  }, [canUpdateRoom, room]);

  const { mutate: createRoom, isPending: isCreateRoomPending } = useCreateRoomMutation();
  const { mutate: editRoom } = useRoomMutation(room?.id, room, canUpdateRoom);

  const roomName = useMemo(() => room?.name ?? "", [room]);

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    register,
    reset,
    setValue,
  } = useForm<IUpdateRoom | IRoomAdd>({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: {
      name: roomName,
      description: room?.description ?? "",
      roomPicture: room?.roomPicture ?? {},
    },
  });

  useEffect(() => {
    if (room == null) return;
    let editedRoom = { ...room };
    setCustomRoomName(preferences?.[`room.${room.id}`]?.name);
    if (customRoomName != null) {
      editedRoom = { ...room, ...{ name: customRoomName } };
    }
    reset(editedRoom);
  }, [room, preferences]);

  const roomPictureUpdatedHandler = (event: CustomEvent<IRoomPictureUpdatedEvent>) => {
    setValue("roomPicture", event.detail.roomPicture);
    setTempPicture(event.detail.file);
  };

  useEffect(() => {
    window.addEventListener(eventNames.ROOM_PICTURE_UPDATED, roomPictureUpdatedHandler as EventListener);

    return () => {
      window.removeEventListener(eventNames.ROOM_PICTURE_UPDATED, roomPictureUpdatedHandler as EventListener);
    };
  }, []);

  const onSubmit: SubmitHandler<IUpdateRoom | IRoomAdd> = (data) => {
    if (room?.id == null) {
      const newRoom = data as IRoomAdd;
      createRoom(newRoom);
    } else {
      const editedRoom = data as IUpdateRoom;
      editRoom(editedRoom, {
        onSuccess: () => {
          const { name } = editedRoom;
          if (name !== room.name && room.type !== roomType.ORGANISATION && member?.role?.slug !== defaultRoleSlug.ROOM_OWNER) {
            setPreference(`room.${room.id}`, { name });
          }
          reset({ ...room, ...{ name } });
        },
      });
    }
  };

  const onError: SubmitErrorHandler<IUpdateRoom | IRoomAdd> = (err) => {
    console.error(err);
  };

  const onSubmitForm = handleSubmit(onSubmit, onError);

  const actions: IModalAction[] = useMemo(() => {
    return [
      {
        action: onSubmitForm,
        label: room?.id != null && isSuccess ? t("createRoomModal.tabs.about.buttons.update") : t("createRoomModal.tabs.about.buttons.create"),
        type: "primary",
        disabled: !isValid || !isDirty,
      },
    ];
  }, [isSuccess, isValid, isDirty, room, onSubmitForm]);

  const isDescriptionDisabled = !canUpdateRoom && room != null;

  const footerInfos =
    room != null
      ? t("createRoomModal.tabs.about.footerInfo", {
          date: format(new Date(room?.createdAt ?? ""), t("date.shortFormat")),
        })
      : t("createRoomModal.tabs.about.footerInfoNoRoom");

  return {
    tempPicture,
    canEditName,
    actions,
    onSubmitForm,
    register,
    canUpdateRoom,
    errors,
    customRoomName,
    isDescriptionDisabled,
    footerInfos,
    isCreateRoomPending,
  };
};
