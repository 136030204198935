import { type IDirectMessage } from "@/api/client";
import { useConversationUtilities } from "@/components/roomPage/tabs/conversation/ConversationLists/useConversationUtilities";
import { SectionHeaderComponent } from "@/components/shared/layout/SectionHeaderComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { eventNames } from "@/constants/eventNames";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useCreateVideoConferenceMutation } from "@/hooks/mutations/videoConference/useCreateVideoConferenceMutation";
import { useDmNotificationSettings } from "@/hooks/shared/notificationSettings/useDmNotificationSettings";
import { useCreateCalendarEvent } from "@/hooks/shared/useCreateCalendarEvent";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading as LoadingIcon } from "react-icons/ai";
import { BiCog as SettingsIcon } from "react-icons/bi";
import { BsFillArchiveFill as ArchiveIcon, BsBell, BsBellSlash } from "react-icons/bs";
import { FiVideo as VideoIcon } from "react-icons/fi";
import { LuCalendarPlus as CreateCalendarEventIcon } from "react-icons/lu";
import { MdOutlineArrowBackIos as MenuIcon } from "react-icons/md";

interface IConversationHeaderComponentProps {
  actionOnParticipants: () => void;
  actionOnTitle: () => void;
  title?: string;
  namePrefix?: string;
  participantTitle: string;
  contextType: "dm" | "channel";
  conversationId: string;
  participants?: IDirectMessage["conversation"]["members"];
  isArchived: boolean;
}

export const ConversationHeaderComponent = ({
  actionOnParticipants,
  actionOnTitle,
  title,
  namePrefix,
  participantTitle,
  contextType,
  conversationId,
  participants,
  isArchived,
}: IConversationHeaderComponentProps): JSX.Element => {
  const openChannelList = useCallback((): void => {
    const customEvent = new CustomEvent(eventNames.TOGGLE_CHANNEL_LIST, {
      detail: true,
    });

    window.dispatchEvent(customEvent);
  }, []);
  const { computeConversationTitleFromMemberList } = useConversationUtilities();

  const me = useContext(AuthenticationContext);
  const name = computeConversationTitleFromMemberList(participants ?? []);
  const isGroup = (participants ?? []).length > 2;
  const computedName = isGroup ? `${(participants ?? []).length - 1} ${name}` : title ?? "";

  const { isNotificationEnabled, onToggle } = useDmNotificationSettings(conversationId);

  const { t } = useTranslation();
  const { onCreateCalendarEvent } = useCreateCalendarEvent(conversationId);

  const { mutate: createVideoConference, isPending } = useCreateVideoConferenceMutation(conversationId);
  const handleVideoCallClick = async () => {
    createVideoConference(true);
  };

  return (
    <SectionHeaderComponent className={`flex h-12 bg-[white] ${isArchived ? "pointer-events-none" : ""}`}>
      <h2 className="flex items-center text-[15px] dark:text-dark-300">
        <button onClick={openChannelList} className="hidden max-sm:block">
          <MenuIcon className="mr-2 h-6 w-6 rounded-lg bg-zinc-200 px-1 py-0.5" />
        </button>
        <div
          className={`tab-menu-item group relative flex items-center rounded-md border border-transparent px-0.5 py-[0.3rem] text-sm font-semibold text-zinc-800 transition-all duration-300 ${
            contextType === "dm" ? "" : "hover:border hover:border-neutral-200 hover:bg-zinc-100 dark:hover:bg-dark-600 dark:hover:text-dark-200"
          }`}
          onClick={actionOnTitle}
        >
          {contextType === "dm" && (
            <figure className="mr-2 w-[30px] shrink-0">
              <UserAvatarComponent
                key={participants?.find(({ user }) => user.id !== me?.id)?.user.id}
                userId={!isGroup ? participants?.find(({ user }) => user.id !== me?.id)?.user.id : undefined}
                userName={computedName}
                size="md"
              />
            </figure>
          )}
          <TooltipComponent content={`${title}`} placement="bottom" disabled={contextType !== "dm"}>
            <div className="line-clamp-1">
              {namePrefix} {title}
            </div>
          </TooltipComponent>
          {contextType === "channel" && !isArchived && <SettingsIcon className="gradient-icon fill ml-1 h-4 w-4 text-zinc-500" />}
          {isArchived && <ArchiveIcon className="gradient-icon fill ml-2 h-3 w-3 text-zinc-500" />}
        </div>
        {contextType === "channel" && !isArchived && (
          <>
            <span className="mx-1 font-light text-zinc-200">|</span>
            <p className="hidden cursor-pointer text-xs text-zinc-500 hover:underline md:block" onClick={actionOnParticipants}>
              {participantTitle}
            </p>
          </>
        )}
      </h2>
      {contextType === "dm" && (
        <button
          onClick={() => {
            onToggle(!isNotificationEnabled);
          }}
        >
          <TooltipComponent
            content={isNotificationEnabled ? t("dms.notificationSettings.disableNotifications") : t("dms.notificationSettings.enableNotifications")}
          >
            <div className="ml-1">
              {isNotificationEnabled ? <BsBell className="h-3.5 w-3.5" /> : <BsBellSlash className="mt-[-2px] h-3.5 w-3.5" />}
            </div>
          </TooltipComponent>
        </button>
      )}
      {!isArchived && (
        <div className="ml-auto flex shrink-0 flex-row items-center">
          <TooltipComponent content={t("calendarEvents.tooltip")} className="hidden">
            <button
              className="group/calendar-event ml-2 h-9 rounded-lg border border-zinc-300 bg-zinc-200 p-2 opacity-80 transition-all hover:opacity-100"
              onClick={onCreateCalendarEvent}
            >
              <div className="flex h-full flex-row items-center text-zinc-600 transition-all group-hover/calendar-event:text-sky-600">
                <CreateCalendarEventIcon className="h-full" />
                <span className="ml-1 text-sm font-normal">{t("calendarEvents.create")}</span>
              </div>
            </button>
          </TooltipComponent>
          <TooltipComponent content={t("videoCalls.tooltip")}>
            <button
              className={`${
                isPending ? "pointer-events-none" : ""
              } group/call ml-2 h-9 w-9 rounded-lg border border-zinc-300 bg-zinc-200 p-2 opacity-80 transition-all hover:opacity-100`}
              onClick={handleVideoCallClick}
            >
              {isPending ? (
                <LoadingIcon className="h-full w-full animate-spin text-zinc-600" />
              ) : (
                <VideoIcon className="h-full w-full text-zinc-600 transition-all group-hover/call:text-sky-600" />
              )}
            </button>
          </TooltipComponent>
        </div>
      )}
    </SectionHeaderComponent>
  );
};
