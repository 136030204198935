import { type INotification } from "@/api/client";
import RemoveTaskIcon from "@/assets/images/customIcons/removeTask.svg?react";
import DMsIcon from "@/assets/images/iconsax/ChatBubbles-Linear-32px.svg?react";
import { EmojiComponent } from "@/components/shared/emojis/EmojiComponent";
import { notificationType } from "@/constants/notification";
import { useUpdateNotificationReadStatusMutation } from "@/hooks/mutations/notifications/useUpdateNotificationReadStatusMutation";
import { useEmailQuery } from "@/hooks/queries/emails/useEmailQuery";
import { useThreadQuery } from "@/hooks/queries/emails/useThreadQuery";
import { useNotificationLocation } from "@/hooks/shared/notifications/useNotificationLocation";
import { useMemo, type MouseEventHandler } from "react";
import { BsCalendar3 as MeetingIcon } from "react-icons/bs";
import { CgHashtag as ChannelIcon } from "react-icons/cg";
import { FaRegHourglass as TaskDueTomorrowIcon } from "react-icons/fa6";
import { GoMention as MentionIcon } from "react-icons/go";
import { IoIosShareAlt as ArrowIcon } from "react-icons/io";
import { MdOutlineMailOutline as EmailIcon, MdAddTask as TaskIcon, MdTaskAlt as UpdateTaskIcon } from "react-icons/md";
export const useNotification = (notification: INotification) => {
  const { mutate: updateNotificationReadStatus } = useUpdateNotificationReadStatusMutation();
  const { data: emailThread } = useThreadQuery(notification.emailThreadId);
  const { data: email } = useEmailQuery(notification.emailId);
  const getLocationFromNotification = useNotificationLocation();

  const from = useMemo(() => getLocationFromNotification(notification), [notification]);

  const notificationIcons = {
    [notificationType.REACTION]: <EmojiComponent shortcodes={notification?.reaction?.content} id="emoji" set="apple" size="1.2rem" />,
    [notificationType.MENTION]: <MentionIcon className="h-5 w-5" />,
    [notificationType.MEETING]: <MeetingIcon className="h-5 w-5" />,
    [notificationType.DIRECT_MESSAGE]: <DMsIcon className="gradient-icon stroke h-5 w-5 stroke-2" />,
    [notificationType.THREAD_REPLY]: <ArrowIcon className="gradient-icon h-5 w-5 -scale-x-100" />,
    [notificationType.CHANNEL_MESSAGE]: <ChannelIcon className="h-5 w-5" />,
    [notificationType.TASK_ASSIGNED]: <TaskIcon className="h-5 w-5" />,
    [notificationType.TASK_UNASSIGNED]: <RemoveTaskIcon className="h-5 w-5" />,
    [notificationType.TASK_STATUS_UPDATED]: <UpdateTaskIcon className="h-5 w-5" />,
    [notificationType.TASK_DEADLINE_TOMORROW]: <TaskDueTomorrowIcon className="h-5 w-5" />,
    [notificationType.NEW_EMAIL]: <EmailIcon className="h-5 w-5" />,
  };

  const NotificationIcon: JSX.Element = useMemo(() => {
    if (notification == null) return <></>;
    return notificationIcons[notification.type as keyof typeof notificationIcons];
  }, [notification]);

  const sender = useMemo(() => {
    if (notification == null) return;
    return notification.sender?.user ?? notification.sentByUser;
  }, [notification]);

  const emailSender = useMemo(() => {
    if (emailThread?.lastEmail == null) return null;
    return emailThread.lastEmail.from;
  }, [emailThread]);

  const userName = useMemo(() => {
    if (sender == null && emailSender == null) return "";
    if (sender != null) return `${sender.firstName} ${sender.lastName}`;
    if (emailSender != null) return emailSender.name === "" ? emailSender.email : emailSender.name;
  }, [sender, emailSender]);

  const onUpdateNotificationReadStatus: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    updateNotificationReadStatus({ isRead: !notification.isRead, notificationId: notification.id });
  };

  return { from, NotificationIcon, sender, onUpdateNotificationReadStatus, emailThread, userName, email };
};
