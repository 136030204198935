import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";

interface IBulkRemoveOrganisationMembersMutation {
  organisationId: string;
  membershipIds: string[];
}

export const useBulkRemoveOrganisationMembersMutation = () => {
  return useMutation({
    mutationFn: async ({ organisationId, membershipIds }: IBulkRemoveOrganisationMembersMutation) => {
      const response = await api.organisations.archiveManyMembers(organisationId, { membershipIds });
      return response.data;
    },
  });
};
