import { type IRoomIdName, type ITask } from "@/api/client";
import { TaskItemComponent } from "@/components/roomPage/tabs/tasks/TaskItemComponent";
import { useTaskRoomColumn } from "@/components/roomPage/tabs/tasks/useTaskRoomColumn";
import { FaPlus } from "react-icons/fa";
import { Virtuoso } from "react-virtuoso";
interface ITaskRoomColumnComponentProps {
  tasks: ITask[];
  room: IRoomIdName;
  isOverlay?: boolean;
  isDragging?: boolean;
}

export const TaskRoomColumnComponent = ({ tasks, room, isOverlay = false, isDragging = false }: ITaskRoomColumnComponentProps) => {
  const { tasksToDisplay, handleCreateTaskClick } = useTaskRoomColumn(tasks, room);

  return (
    <div className="relative flex h-full flex-col px-4 pt-4" style={{ minWidth: 365, maxWidth: 365 }}>
      <div className="mb-4 flex items-center">
        <h2 className="text-sm font-semibold text-zinc-700">
          <span>{room.name} </span>
          <span className="text-sm font-normal text-zinc-500">{tasksToDisplay.length > 0 ? `· ${tasksToDisplay.length}` : ""}</span>
        </h2>
        <button onClick={handleCreateTaskClick} className="ml-auto text-zinc-500 transition-all hover:text-zinc-600">
          <FaPlus className="h-3 w-3" />
        </button>
      </div>

      <Virtuoso<ITask>
        className="h-full"
        data={tasksToDisplay}
        itemContent={(_, task) => <TaskItemComponent task={task} key={task.id} isActive={false} isInHome />}
      />
    </div>
  );
};
