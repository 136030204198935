import { useUpdateGeneralNotificationSettingsMutation } from "@/hooks/mutations/notificationSettings/useUpdateGeneralNotificationSettingsMutation";
import { useGeneralNotificationSettingsQuery } from "@/hooks/queries/notificationSettings/useGeneralNotificationSettingsQuery";
import { useCallback, useMemo } from "react";

export const useGeneralNotificationSettings = () => {
  const { data: generalNotificationSettings, isLoading } = useGeneralNotificationSettingsQuery();
  const { mutate } = useUpdateGeneralNotificationSettingsMutation();

  const isNotificationEnabled = useMemo(
    () => generalNotificationSettings?.generalNotificationSettings.enableNotifications ?? false,
    [generalNotificationSettings],
  );

  const isChannelMessagesEnabled = generalNotificationSettings?.generalNotificationSettings.channelMessage ?? false;
  const isMentionsEnabled = generalNotificationSettings?.generalNotificationSettings.mention ?? false;
  const isThreadsEnabled = generalNotificationSettings?.generalNotificationSettings.threadReply ?? false;
  const isReactionsEnabled = generalNotificationSettings?.generalNotificationSettings.reaction ?? false;

  const getIsNotificationSettingsEnabledForConversation = useCallback(
    (conversationId: string) => {
      if (conversationId == null || generalNotificationSettings == null) return true;
      const conversationNotificationSetting = generalNotificationSettings.conversationNotificationSettings.find(
        (notificationSetting) => notificationSetting.id === conversationId,
      );
      if (conversationNotificationSetting == null) return true;
      return conversationNotificationSetting.notificationSettings.enableNotifications;
    },
    [generalNotificationSettings],
  );

  const onToggleGeneralNotifications = (isEnabled: boolean) => {
    mutate({
      enableNotifications: isEnabled,
    });
  };

  const onToggleChannelMessages = (isEnabled: boolean) => {
    mutate({
      channelMessage: isEnabled,
    });
  };

  const onToggleMentions = (isEnabled: boolean) => {
    mutate({
      mention: isEnabled,
    });
  };

  const onToggleThreads = (isEnabled: boolean) => {
    mutate({
      threadReply: isEnabled,
    });
  };

  const onToggleReactions = (isEnabled: boolean) => {
    mutate({
      reaction: isEnabled,
    });
  };

  return {
    isNotificationEnabled,
    isChannelMessagesEnabled,
    isMentionsEnabled,
    isThreadsEnabled,
    isReactionsEnabled,
    onToggleGeneralNotifications,
    onToggleChannelMessages,
    onToggleMentions,
    onToggleThreads,
    onToggleReactions,
    isLoading,
    getIsNotificationSettingsEnabledForConversation,
  };
};
