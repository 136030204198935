import { useCreateCalendarEventForm } from "@/components/roomPage/modals/createCalendarEvent/tabs/CreateCalendarEventForm/useCreateCalendarEventForm";
import { EventTimelineComponent } from "@/components/shared/calendar/EventTimelineComponent";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { TextAreaComponent } from "@/components/shared/form/textArea/TextAreaComponent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { UserTagListComponent } from "@/components/shared/user/UserTagList/UserTagListComponent";
import { type FunctionComponent } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

interface CreateCalendarEventFormProps {
  conversationId: string;
}

export const CreateCalendarEventFormComponent: FunctionComponent<CreateCalendarEventFormProps> = ({ conversationId }) => {
  const { t } = useTranslation();
  const {
    isValid,
    onSubmitForm,
    register,
    isLoading,
    errors,
    users,
    onRemoveUser,
    onAddUser,
    summary,
    onSummaryChange,
    googleCalendarEvents,
    selectedDay,
    onDayChange,
    start,
    end,
    onEventDateChange,
  } = useCreateCalendarEventForm(conversationId);

  return (
    <form onSubmit={onSubmitForm} className="flex w-full flex-col px-6 py-4">
      {isLoading ? (
        <div className="-ml-6 w-full">
          <LoaderComponent />
        </div>
      ) : null}
      <div className="flex w-full">
        <div className="mb-4 flex w-full flex-col justify-start">
          <TextInputComponent
            required
            name="summary"
            label={t("createCalendarEventDrawer.fields.summary")}
            error={errors.summary?.message ?? ""}
            onChange={onSummaryChange}
            value={summary}
          />
        </div>
      </div>
      <div className="mb-4">
        <TextAreaComponent
          register={register}
          name="description"
          error={errors.description?.message ?? ""}
          label={t("createCalendarEventDrawer.fields.description")}
        />
      </div>
      <span className="mb-1 w-full text-sm font-normal text-zinc-500 dark:text-dark-300">{t("createCalendarEventDrawer.fields.dates")}</span>
      <div className="mb-2 flex w-full">
        <DatePicker
          inline
          wrapperClassName="w-0"
          selected={selectedDay}
          customInput={<div className="hidden" />}
          calendarStartDay={1}
          onChange={(date, event) => {
            event?.stopPropagation();
            event?.preventDefault();
            onDayChange(date ?? new Date());
          }}
        />
      </div>
      <EventTimelineComponent
        newEventSummary={summary}
        events={googleCalendarEvents ?? []}
        selectedDay={selectedDay}
        start={start}
        end={end}
        onEventDateChange={onEventDateChange}
      />
      <span className="mb-1 mt-4 w-full text-sm font-normal text-zinc-500 dark:text-dark-300">{t("createCalendarEventDrawer.fields.invitees")}</span>
      <div className="mb-4 w-full">
        <UserTagListComponent
          conversationId={conversationId}
          users={users}
          onAddUser={onAddUser}
          onRemoveUser={onRemoveUser}
          addUserTitle={t("createCalendarEventDrawer.users.add")}
        />
      </div>
      <div>
        <ButtonComponent status="primary" type="submit" disabled={!isValid}>
          {t("createCalendarEventDrawer.submitButton")}
        </ButtonComponent>
      </div>
    </form>
  );
};
