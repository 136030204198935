import GoogleCalendarLogo from "@/assets/images/logo-google-calendar.svg?react";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { useCreateCalendarEvent } from "@/hooks/shared/useCreateCalendarEvent";
import { useTranslation } from "react-i18next";

export const AddCalendarAccountTab = ({ conversationId }: { conversationId: string }): JSX.Element => {
  const { onLinkGoogleCalendar } = useCreateCalendarEvent(conversationId);
  const { t } = useTranslation();

  return (
    <ModalTabComponent>
      <div className="h-full w-full">
        <ButtonComponent type="button" status="secondary" icon={<GoogleCalendarLogo />} onClick={onLinkGoogleCalendar}>
          {t("addCalendarAccountModal.google.button")}
        </ButtonComponent>
      </div>
    </ModalTabComponent>
  );
};
