import { TaskStatusPopinContentComponent } from "@/components/shared/tasks/TaskStatusPopinComponent/TaskStatusPopinContentComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { type TippyProps } from "@tippyjs/react";
import { type FunctionComponent } from "react";

interface ITaskStatusPopinComponentProps {
  children: TippyProps["children"];
  isVisible: boolean;
  onCloseTooltip: () => void;
  onStatusSelected: (status: string) => void;
  roomId?: string | null;
}

export const TaskStatusPopinComponent: FunctionComponent<ITaskStatusPopinComponentProps> = ({
  children,
  isVisible,
  onCloseTooltip,
  onStatusSelected,
  roomId,
}) => {
  return (
    <TooltipComponent
      placement="bottom-end"
      visible={isVisible}
      onClickOutside={onCloseTooltip}
      arrow={false}
      interactive
      noPadding
      content={<TaskStatusPopinContentComponent onStatusSelected={onStatusSelected} roomId={roomId} />}
    >
      {children}
    </TooltipComponent>
  );
};
