import { PlansTabComponent } from "@/components/billing/modals/selectPlanModal/tabs/PlansTabComponent";
import { ModalLayoutComponent } from "@/components/shared/modal/ModalLayoutComponent";
import { eventNames } from "@/constants/eventNames";
import { ModalNames } from "@/constants/modalNames";
import { useValidateFreePlan } from "@/hooks/billing/useValidateFreePlan";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const SelectPlanModalComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const { validateFreePlan } = useValidateFreePlan();

  const tab = useMemo(
    () => ({
      label: t("billing.modals.plans.title"),
      content: <PlansTabComponent />,
      id: ModalNames.PLANS,
      disabled: false,
    }),

    [],
  );

  useEffect(() => {
    window.addEventListener(eventNames.CLOSE_MODAL, validateFreePlan);
    return () => {
      window.removeEventListener(eventNames.CLOSE_MODAL, validateFreePlan);
    };
  }, []);

  return (
    <>
      <div className="fixed inset-0 z-[8000] flex items-center justify-center" onMouseDown={validateFreePlan}>
        <ModalLayoutComponent
          tabs={[tab]}
          title={t("billing.modals.plans.title")}
          size="xl"
          name={ModalNames.PLANS}
          defaultTab="plans"
        ></ModalLayoutComponent>
      </div>
    </>
  );
};
