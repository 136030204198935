import { ConversationMemberStatus, RoomMemberStatus } from "@/api/client";
import { RoomModal } from "@/components/homePage/modals/room/RoomModal";
import { MentionPopinContainerComponent } from "@/components/roomPage/shared/MentionPopinContainerComponent";
import { ConversationListsComponent } from "@/components/roomPage/tabs/conversation/ConversationLists/ConversationListsComponent";
import { DrawerWrapperComponent } from "@/components/shared/drawer/DrawerWrapperComponent";
import { SectionHeaderComponent } from "@/components/shared/layout/SectionHeaderComponent";
import { TabMenuComponent } from "@/components/shared/layout/TabMenuComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { RoomAvatarComponent } from "@/components/shared/room/RoomAvatarComponent";
import { eventNames } from "@/constants/eventNames";
import { ModalNames } from "@/constants/modalNames";
import { roomType } from "@/constants/room";
import { RoomContext } from "@/contexts/RoomContext";
import { useMyRoomsChannelsQuery } from "@/hooks/queries/channels/useMyRoomsChannelsQuery";
import { useMyRoomsDmsQuery } from "@/hooks/queries/dm/useMyRoomsDmsQuery";
import { useRoomActiveMemberQuery } from "@/hooks/queries/rooms/useRoomActiveMemberQuery";
import { useRoomQuery } from "@/hooks/queries/rooms/useRoomQuery";
import { useRoomPage } from "@/hooks/roomPage/useRoomPage";
import { PreferencesContext } from "@/hooks/shared/usePreferences";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiCog as SettingsIcon } from "react-icons/bi";
import { BsFillArchiveFill as ArchiveIcon } from "react-icons/bs";
import { Outlet, useParams } from "react-router-dom";

export const RoomPage = (): JSX.Element => {
  const { t } = useTranslation();
  const params = useParams<{ id: string; conversationId: string }>() as { id: string; conversationId: string };
  const { preferences } = useContext(PreferencesContext);

  const [isChannelListOpen, setIsChannelListOpen] = useState<boolean>(false);
  // queries
  const { data: roomResponse, isSuccess } = useRoomQuery(params.id);

  const [channelStatusSelectedState, setChannelStatusSelectedState] = useState<string | null>(null);

  const channelStatusSelected = useMemo<string>(() => {
    if (roomResponse?.member.status === RoomMemberStatus.Archived) return ConversationMemberStatus.Archived;

    return channelStatusSelectedState ?? ConversationMemberStatus.Confirmed;
  }, [channelStatusSelectedState, roomResponse?.member.status]);

  const { data: channels } = useMyRoomsChannelsQuery(params.id, channelStatusSelected);
  const { data: dms } = useMyRoomsDmsQuery(params.id);
  const { data: roomMembers } = useRoomActiveMemberQuery(params.id);

  // Memos
  const room = useMemo(() => roomResponse?.room, [roomResponse]);
  const customRoomName = useMemo(() => preferences?.[`room.${params.id}`]?.name ?? room?.name, [room, preferences]);
  const contextValue = useMemo(() => {
    return { roomResponse: roomResponse ?? null, channels: channels ?? null, channelStatusSelected: channelStatusSelected ?? "" };
  }, [roomResponse, channels, channelStatusSelected]);
  const { tabs } = useRoomPage(roomResponse);
  const isRoomArchived = useMemo(() => roomResponse?.member.status === RoomMemberStatus.Archived, [roomResponse]);

  const openEditRoomModal = useCallback(
    (defaultTab: "members" | "about"): void => {
      openModal(
        ModalNames.CREATE_ROOM,
        <RoomModal isOrganisationRoom={room?.type === roomType.ORGANISATION} roomId={params.id} defaultTab={defaultTab} />,
      );
    },
    [params.id, room],
  );

  const handleSelectChannelStatus = useCallback(
    (event: CustomEvent): void => {
      setChannelStatusSelectedState(event.detail);
    },
    [params.id],
  );

  useEffect(() => {
    // listen to event toggleChannelList emitted from ConversationTab.tsx
    const handleToggleChannelList = (event: CustomEvent): void => {
      setIsChannelListOpen(event.detail);
    };
    window.addEventListener(eventNames.SELECT_CHANNEL_STATUS, handleSelectChannelStatus as EventListener);
    window.addEventListener(eventNames.TOGGLE_CHANNEL_LIST, handleToggleChannelList as EventListener);
    return () => {
      window.removeEventListener(eventNames.SELECT_CHANNEL_STATUS, handleSelectChannelStatus as EventListener);
      window.removeEventListener(eventNames.TOGGLE_CHANNEL_LIST, handleToggleChannelList as EventListener);
    };
  }, []);
  return (
    <RoomContext.Provider value={contextValue}>
      <MentionPopinContainerComponent roomId={params.id} />
      <section className="flex w-full flex-1 overflow-hidden">
        <div className="flex w-full flex-col overflow-hidden">
          <section className="header h-14 w-full">
            <SectionHeaderComponent className="flex h-full bg-white px-layout dark:bg-dark-900" hasPadding={false}>
              {/* Todo: Arbitrary values are so bad, need to find a better solution */}
              <div
                className={`room-information flex h-14 w-[270px] items-center border-r dark:border-r-dark-700 max-md:w-[200px] ${
                  isRoomArchived ? "pointer-events-none" : ""
                }`}
              >
                <button
                  className="tab-menu-item group relative flex items-center rounded-md border border-transparent px-0.5 py-[0.3rem] text-zinc-500 transition-all duration-300 hover:border hover:border-neutral-200 hover:bg-zinc-100 dark:hover:bg-dark-600 dark:hover:text-dark-200"
                  onClick={() => {
                    openEditRoomModal("about");
                  }}
                >
                  {isSuccess && <RoomAvatarComponent roomId={params.id} size="xs" className="mr-2 h-5 w-5 rounded-md border border-gray-400" />}
                  <h1
                    className="max-w-[120px] truncate text-sm font-semibold text-zinc-800"
                    title={isSuccess ? customRoomName : <>{t("general.loading")}</>}
                  >
                    {isSuccess ? customRoomName : <>{t("general.loading")}</>}
                  </h1>
                  {!isRoomArchived ? (
                    <SettingsIcon className="gradient-icon fill ml-1 h-4 w-4 text-zinc-500" />
                  ) : (
                    <ArchiveIcon className="gradient-icon fill ml-2 h-3 w-3 text-zinc-500" />
                  )}
                </button>
                {!isRoomArchived && (
                  <>
                    <span className="mx-2 hidden h-5 w-[1px] bg-zinc-300 md:block"></span>
                    <p
                      className="hidden cursor-pointer text-xs text-zinc-500 hover:underline md:block"
                      onClick={() => {
                        openEditRoomModal("members");
                      }}
                    >
                      {t("general.roomMembersWithCount", { count: roomMembers?.length ?? 0 })}
                    </p>
                  </>
                )}
              </div>
              <div className="ml-4 hidden md:block">
                <TabMenuComponent tabs={tabs} />
              </div>
            </SectionHeaderComponent>
          </section>
          <section className="content flex w-full flex-1 overflow-auto max-sm:overflow-x-hidden">
            <ConversationListsComponent channels={channels ?? []} dms={dms ?? []} isChannelListOpen={isChannelListOpen} />
            <Outlet />
          </section>
        </div>
        <DrawerWrapperComponent />
      </section>
    </RoomContext.Provider>
  );
};
//
