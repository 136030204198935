import { type ITag, type ITagCreateBody } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateTagMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: ITagCreateBody) => {
      const response = await api.tags.createTag(params);
      return response.data.data;
    },
    onSuccess: (data, params) => {
      queryClient.setQueryData<ITag[]>([QueryKeys.ROOMS, params.roomId, QueryKeys.TAGS], (oldTags) => {
        if (oldTags == null) return [data];
        return [...oldTags, data];
      });
    },
  });
};
