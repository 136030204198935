import { type ITaskOne, type ITaskUpdateResponse } from "@/api/client";
import { ChannelPickerComponent } from "@/components/shared/channel/ChannelPickerComponent/ChannelPickerComponent";
import { ToggleComponent } from "@/components/shared/form/toggle/ToggleComponent";
import { RichTextEditorComponent } from "@/components/shared/richTextEditor/RichTextEditorComponent";
import { RoomPickerComponent } from "@/components/shared/room/RoomPickerComponent/RoomPickerComponent";
import { TagInputComponent } from "@/components/shared/tagInput/TagInputComponent";
import { TagInputTagComponent } from "@/components/shared/tagInput/TagInputTagComponent";
import { useCommentHeaderComponent } from "@/components/shared/tasks/EditTaskDrawerComponent/useCommentHeaderComponent";
import { TaskAssigneesComponent } from "@/components/shared/tasks/TaskAssigneesComponent/TaskAssigneesComponent";
import { TaskDeadlineComponent } from "@/components/shared/tasks/TaskDeadlineComponent/TaskDeadlineComponent";
import { TaskPriorityComponent } from "@/components/shared/tasks/TaskPriorityComponent/TaskPriorityComponent";
import { TaskStatusComponent } from "@/components/shared/tasks/TaskStatusComponent/TaskStatusComponent";
import { TaskTitleComponent } from "@/components/shared/tasks/TaskTitleComponent";
import { TaskTodosComponent } from "@/components/shared/tasks/TaskTodosComponent/TaskTodosComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { type IConversationContext } from "@/contexts/ConversationContext";
import { type TaskUpdateBody } from "@/hooks/mutations/tasks/useUpdateTaskMutation";
import type { IChannelIdNameRoom } from "@/interfaces/channel";
import { type UseMutateFunction } from "@tanstack/react-query";
import { format } from "date-fns";
import type { DeltaStatic } from "quill";
import { type FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FaTrash as DeleteIcon, FaLock as LockCloseIcon, FaLockOpen as LockOpenIcon } from "react-icons/fa";

interface ICommentHeaderComponentProps {
  isPending: boolean;
  task: ITaskOne;
  conversationContextValue: IConversationContext | null;
  conversationId: string | null;
  deleteTask: () => void;
  updateTask: UseMutateFunction<ITaskUpdateResponse, Error, TaskUpdateBody, void>;
}
export const CommentHeaderComponent: FunctionComponent<ICommentHeaderComponentProps> = ({
  isPending,
  task,
  conversationContextValue,
  conversationId,
  deleteTask,
  updateTask,
}) => {
  const {
    onPriorityChange,
    onIsPrivateChange,
    onStatusChange,
    onDeadlineChange,
    onDescriptionBlur,
    creatorFullName,
    descriptionEditorRef,
    canUpdateIsPrivate,
  } = useCommentHeaderComponent(task, updateTask);

  const { t, i18n } = useTranslation();

  return isPending || task == null || conversationContextValue == null || conversationId == null ? (
    <></>
  ) : (
    <>
      <div className="flex flex-col items-end justify-start px-6 pt-4">
        <div className="mb-4 flex w-full items-center justify-between">
          <div className="flex flex-row items-center">
            <span className="text-xs font-normal text-zinc-400">
              <Trans
                i18nKey="editTaskModal.createdAt"
                i18n={i18n}
                values={{ date: format(new Date(task.createdAt), t("date.shortFormat")) }}
                components={{ strong: <span className="font-semibold" /> }}
              />
            </span>
            <UserAvatarComponent userId={task.createdByUser.id} userName={creatorFullName} className="mx-1 h-4 w-4" />
            <span className="text-xs font-semibold text-zinc-400">{creatorFullName}</span>
          </div>
          <TooltipComponent content={t("editTaskModal.delete.tooltip")} placement="left">
            <button className="flex items-center justify-center" onClick={deleteTask}>
              <DeleteIcon className="mr-1 text-xs text-rogerRed-600" />
            </button>
          </TooltipComponent>
        </div>
        <div className="mb-4 h-px w-full bg-zinc-100" />
        <div className="flex w-full flex-col flex-wrap items-start justify-between">
          <div className="mb-4 w-full flex-1">
            <TaskTitleComponent taskId={task.id} />
          </div>
          <div className="mb-1 flex w-full items-center text-sm font-normal text-zinc-500 dark:text-dark-300">
            {t("editTaskModal.fields.description.label")}
          </div>
          <div className="mb-4 w-full">
            <RichTextEditorComponent
              key={task.id}
              ref={descriptionEditorRef}
              contextType="taskDescription"
              placeholder={t("editTaskModal.fields.description.placeholder")}
              contextId={task.id}
              onBlur={onDescriptionBlur}
              defaultValue={task.description as DeltaStatic}
            />
          </div>
          <div className="mb-1 flex w-full items-center text-sm font-normal text-zinc-500 dark:text-dark-300">
            {t("editTaskModal.fields.room.label")}
          </div>
          <div className="mb-4 flex w-full">
            <RoomPickerComponent
              size="lg"
              className="w-full rounded-md border border-zinc-200 bg-zinc-100 px-3 py-1.5"
              room={task.channel?.room ?? null}
            />
          </div>
          <div className="mb-1 flex w-full items-center text-sm font-normal text-zinc-500 dark:text-dark-300">
            {t("editTaskModal.fields.channel.label")}
          </div>
          <div className="mb-4 flex w-full">
            <ChannelPickerComponent
              size="lg"
              className="w-full rounded-md border border-zinc-200 bg-zinc-100 px-3 py-1.5"
              room={task.channel?.room ?? null}
              channel={task.channel as IChannelIdNameRoom}
            />
          </div>
          <div className="mb-2 h-px w-full bg-zinc-100" />
          <TaskTodosComponent taskId={task.id} todos={task.todos} />
          <div className="mb-4 flex w-full flex-row">
            {task.channel?.room?.id != null ? (
              <TaskStatusComponent onStatusChange={onStatusChange} status={task.taskStatus} roomId={task.channel?.room?.id} />
            ) : null}
            <div className="w-4" />
            <TaskPriorityComponent onPriorityChange={onPriorityChange} priority={task.priority ?? null} displayNoPriority />
            <div className="w-4" />
            <TaskDeadlineComponent
              className="rounded-md bg-zinc-200 px-3"
              deadline={task.deadline ?? null}
              onDeadlineChange={onDeadlineChange}
              displayNoDeadline
            />
          </div>
          <div className="mb-4 h-px w-full bg-zinc-100" />
          <div className="mb-4 flex flex-wrap gap-1">
            <div className="mb-1 flex w-full items-center text-sm font-normal text-zinc-500 dark:text-dark-300">
              {t("editTaskModal.fields.labels.label")}
            </div>
            {task.tags?.map((tag) => (
              <TagInputTagComponent roomId={task.channel?.room?.id ?? ""} taskId={task.id} key={tag.id} tag={tag} onClick={() => {}} />
            ))}
            <TagInputComponent taskId={task.id} roomId={task.channel?.room?.id ?? ""} />
          </div>
          <div className="mb-4 h-px w-full bg-zinc-100" />
          <div className="mb-4 flex w-full">
            <TaskAssigneesComponent
              key={task.assignedUsers?.map((user) => user.id).join("")}
              size="lg"
              assignedUsers={task.assignedUsers ?? []}
              taskId={task.id}
              roomId={task.channel?.room?.id ?? null}
              channelId={task.channel?.id ?? null}
            />
          </div>
          {canUpdateIsPrivate ? (
            <div className="mb-4 flex w-full items-center justify-start">
              <label className="relative flex w-full cursor-pointer select-none items-start justify-start text-sm text-zinc-500">
                <ToggleComponent
                  isEnabled={task.isPrivate}
                  onToggle={(e) => {
                    onIsPrivateChange(e);
                  }}
                  size="sm"
                  activeIcon={<LockCloseIcon className="h-2 w-2" />}
                  inactiveIcon={<LockOpenIcon className="h-2 w-2" />}
                />
                <span className="ml-2 text-xs text-zinc-400">
                  {t(`createTaskModal.fields.private.label.${task.isPrivate ? "private" : "public"}`)}
                </span>
              </label>
            </div>
          ) : null}
        </div>
      </div>
      <div className="px-6">
        <div className="mb-4 h-px w-full bg-zinc-100" />
        <div className="mb-1 flex w-full items-center text-sm font-normal text-zinc-500 dark:text-dark-300">
          {t("editTaskModal.fields.comments.label")} ({task.conversation?.messagesCount ?? 0})
        </div>
        <RichTextEditorComponent
          contextId={conversationId}
          contextType="taskComment"
          key={`editor-taskComment${conversationId}`}
          placeholder={t("editTaskModal.fields.comments.placeholder")}
        />
      </div>
    </>
  );
};
