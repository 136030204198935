import { NotificationCenterDrawer } from "@/components/homePage/drawers/notificationCenter/NotificationCenterDrawer";
import { NumberBadge } from "@/components/shared/badges/NumberBadge";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { useNotificationsQuery } from "@/hooks/queries/notifications/useNotificationsQuery";
import { useContext, useEffect, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { FaRegBell as BellIcon, FaBell as BellIconActive } from "react-icons/fa";

export const NotificationsToggleComponent: FunctionComponent = () => {
  const { t } = useTranslation();

  const { isOpened, openedDrawers, openDrawer, closeDrawer, closeAllDrawers } = useContext(DrawerContext);
  const { data: notificationsData } = useNotificationsQuery("UNREAD");

  useEffect(() => {
    const unreadNotificationsCount = notificationsData?.pages.flatMap((page) => page.data).length ?? 0;
    const envString = import.meta.env.VITE_APP_ENV !== "production" ? ` - ${import.meta.env.VITE_APP_ENV as string}` : "";

    const title: string = unreadNotificationsCount > 0 ? t("window.titleWithNotifications") : t("window.title");

    document.title = `${title}${envString}`;
  }, [notificationsData?.pages.flatMap((page) => page.data).length]);

  const isNotificationCenterOpened = openedDrawers.find((drawer) => drawer.drawerType === DrawerType.NOTIFICATIONS_CENTER) != null;

  const openNotificationsCenterDrawer = () => {
    closeAllDrawers();
    openDrawer({
      drawerType: DrawerType.NOTIFICATIONS_CENTER,
      component: <NotificationCenterDrawer />,
    });
  };
  const openNotificationsCenter = () => {
    if (isNotificationCenterOpened) {
      if (openedDrawers[openedDrawers.length - 1].drawerType === DrawerType.NOTIFICATIONS_CENTER && isOpened) {
        closeDrawer(DrawerType.NOTIFICATIONS_CENTER);
      } else {
        openNotificationsCenterDrawer();
      }
    } else {
      openNotificationsCenterDrawer();
    }
  };

  return (
    <button
      className="relative mr-4 flex h-6 w-7 cursor-pointer items-center justify-center opacity-100 transition-all hover:opacity-80"
      onClick={openNotificationsCenter}
    >
      <div className="mb-0 text-white">
        {isOpened && isNotificationCenterOpened ? <BellIconActive className="h-5 w-5" /> : <BellIcon className="h-5 w-5" />}
        {notificationsData?.pages[0].data != null && notificationsData.pages[0].data.length > 0 && (
          <NumberBadge count={notificationsData.pages[0].data.length} size="lg" />
        )}
      </div>
    </button>
  );
};
