import { type IMessage } from "@/api/client";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useIsMessageHighlighted = (message: IMessage) => {
  const [searchParams] = useSearchParams();
  const highlightedMessageId = searchParams.get("messageId");
  const [isHighlighted, setIsHighlighted] = useState(highlightedMessageId === message.id);
  const HIGHLIGHTED_TIMER = 6000;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsHighlighted(false);
    }, HIGHLIGHTED_TIMER);

    return () => {
      clearTimeout(timer);
    };
  }, [message]);
  return { isHighlighted };
};
