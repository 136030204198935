import { type IEventDatetime, type IProfile } from "@/api/client";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { QueryKeys } from "@/constants/queryKeys";
import { useGetGoogleCalendarEventsQuery } from "@/hooks/mutations/google/useGetGoogleCalendarEventsQuery";
import { useSendGoogleCalendarEventMutation } from "@/hooks/mutations/google/useSendGoogleCalendarEventMutation";
import { useConversationActiveMemberQuery } from "@/hooks/queries/conversations/useConversationActiveMemberQuery";
import { useTimezone } from "@/hooks/shared/useTimezone";
import { type UserWithEmail, type UserWithId } from "@/interfaces/user";
import { convertDateToRFC3339 } from "@/utils/date";
import { useQueryClient } from "@tanstack/react-query";
import { addHours, endOfDay, format, startOfDay, startOfHour } from "date-fns";
import { useContext, useMemo, type ChangeEventHandler } from "react";
import { useForm, type SubmitErrorHandler, type SubmitHandler } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export interface ICreateCalendarEventForm {
  summary: string;
  description: string;
  users: Array<UserWithId | UserWithEmail>;
  start: IEventDatetime;
  end: IEventDatetime | null;
}

export const useCreateCalendarEventForm = (conversationId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { closeDrawer } = useContext(DrawerContext);
  const timeZone = useTimezone();
  const { data: conversationActiveMembers } = useConversationActiveMemberQuery(conversationId);
  const { mutate: sendCalendarEvent, isPending: isLoading } = useSendGoogleCalendarEventMutation();

  const onSubmit: SubmitHandler<ICreateCalendarEventForm> = async (data) => {
    const participantsEmails: string[] = (
      await Promise.all(
        data.users.map(async (user) => {
          if (user.type === "email") {
            return user.email;
          }

          const userProfile = queryClient.getQueryData<IProfile>([QueryKeys.USERS, user.id]);

          return userProfile?.email ?? null;
        }),
      )
    ).filter((email): email is string => email !== null);

    sendCalendarEvent(
      {
        summary: data.summary,
        description: data.description,
        start: data.start,
        end: data.end ?? undefined,
        participantsEmails,
      },
      {
        onSuccess: () => {
          closeDrawer(DrawerType.CREATE_CALENDAR_EVENT);
          toast.success(t("createCalendarEventDrawer.success"));
        },
      },
    );
  };

  const onError: SubmitErrorHandler<ICreateCalendarEventForm> = (err) => {
    console.error(err);
  };

  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    getValues,
    setValue,
  } = useForm<ICreateCalendarEventForm>({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: {
      users: (conversationActiveMembers ?? []).map((member) => ({
        type: "userId",
        id: member.user.id,
      })),
      start: {
        dateTime: convertDateToRFC3339(addHours(startOfHour(new Date()), 2)),
        timeZone,
      },
      end: {
        dateTime: convertDateToRFC3339(addHours(startOfHour(new Date()), 3)),
        timeZone,
      },
    },
  });
  const onSubmitForm = handleSubmit(onSubmit, onError);

  const summary = getValues("summary");
  const start = getValues("start");
  const end = getValues("end");

  const googleCalendarMinDate = useMemo(() => convertDateToRFC3339(startOfDay(new Date(start.date ?? start.dateTime ?? new Date()))), [start]);
  const googleCalendarMaxDate = useMemo(() => convertDateToRFC3339(endOfDay(new Date(start.date ?? start.dateTime ?? new Date()))), [start]);

  const { data: googleCalendarEvents } = useGetGoogleCalendarEventsQuery(googleCalendarMinDate, googleCalendarMaxDate);

  const users = getValues("users");

  const onAddUser = (user: UserWithId | UserWithEmail) => {
    const newUsers = [...users, user];
    setValue("users", newUsers, { shouldValidate: true, shouldDirty: true });
  };

  const onRemoveUser = (user: UserWithId | UserWithEmail) => {
    const newUsers = users.filter(
      (u) =>
        u.type !== user.type ||
        (u.type === "userId" && user.type === "userId" && u.id !== user.id) ||
        (u.type === "email" && user.type === "email" && u.email !== user.email),
    );

    setValue("users", newUsers, { shouldValidate: true, shouldDirty: true });
  };

  const onSummaryChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue("summary", e.target.value, { shouldValidate: true, shouldDirty: true });
  };

  const onEventDateChange = (start: Date, end: Date | null, isAllDay?: boolean) => {
    if (isAllDay === true || end === null) {
      setValue("start", { date: format(start, "yyyy-MM-dd'T'HH:mm:ssXXX"), timeZone }, { shouldDirty: true, shouldValidate: true });
      setValue("end", { date: null, timeZone }, { shouldDirty: true, shouldValidate: true });
    } else {
      setValue("start", { dateTime: format(start, "yyyy-MM-dd'T'HH:mm:ssXXX"), timeZone }, { shouldDirty: true, shouldValidate: true });
      setValue("end", { dateTime: format(end, "yyyy-MM-dd'T'HH:mm:ssXXX"), timeZone }, { shouldDirty: true, shouldValidate: true });
    }
  };

  const onDayChange = (date: Date) => {
    onEventDateChange(date, addHours(date, 1), false);
  };

  const selectedDay = useMemo(() => new Date(start.date ?? start.dateTime ?? new Date()), [start]);

  return {
    onSubmitForm,
    register,
    errors,
    isLoading,
    isValid,
    users,
    onRemoveUser,
    onAddUser,
    summary,
    onSummaryChange,
    googleCalendarEvents,
    selectedDay,
    onDayChange,
    start,
    end,
    onEventDateChange,
  };
};
