import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";

export const useEditChannelOrderMutation = () => {
  return useMutation({
    mutationFn: async ({ channelId, to }: { channelId: string; to: number }) => {
      if (channelId == null) return;
      await api.my.reorderChannel(channelId, { to });
    },
  });
};
