import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useMyOrganisationQuery = (enabled?: boolean) => {
  const STALE_TIME = 15000;
  return useQuery({
    queryKey: [QueryKeys.MY_ORGANISATION],
    queryFn: async () => {
      const response = await api.my.getOrganisation();
      return response.data.data;
    },
    staleTime: STALE_TIME,
    refetchOnWindowFocus: true,
    enabled,
  });
};
