import { useCallback, useEffect, useRef, useState } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";

export const useAudioRecording = () => {
  const [recordingTime, setRecordingTime] = useState(0);

  const recorderControls = useAudioRecorder({
    noiseSuppression: true,
    echoCancellation: true,
  });

  const stopRecording = useCallback(() => {
    recorderControls.stopRecording();
  }, [recorderControls.isRecording]);

  const stopRecordingRef = useRef(stopRecording);
  const isRecordingRef = useRef(recorderControls.isRecording);

  useEffect(() => {
    stopRecordingRef.current = stopRecording;
  }, [stopRecording]);

  useEffect(() => {
    isRecordingRef.current = recorderControls.isRecording;
  }, [recorderControls.isRecording]);

  useEffect(() => {
    return () => {
      if (isRecordingRef.current) {
        stopRecordingRef.current();
      }
    };
  }, []);

  useEffect(() => {
    if (recorderControls.recordingTime === 0) return;
    setRecordingTime(recorderControls.recordingTime);
  }, [recorderControls.recordingTime]);

  return { recorderControls, recordingTime, setRecordingTime };
};
