import { TagComponent } from "@/components/shared/tag/TagComponent";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { useUserQuery } from "@/hooks/queries/shared/user/useUserQuery";
import { useCallback, useMemo, type FunctionComponent } from "react";

interface UserTagComponentProps {
  userId: string;
  onRemove: () => void;
}

export const UserTagComponent: FunctionComponent<UserTagComponentProps> = ({ userId, onRemove }) => {
  const { data: user, isLoading } = useUserQuery(userId);

  const fullName = useMemo(() => `${user?.firstName ?? ""} ${user?.lastName ?? ""}`, [user]);

  const IconComponent = useCallback(
    () => <UserAvatarComponent userId={userId} userName={fullName} size="xs" className="h-6 w-6" />,
    [userId, fullName],
  );

  if (isLoading || user == null) {
    return <TagComponent value="" onRemove={onRemove} />;
  }

  // @ts-expect-error back type is wrong
  const email: string = user.email;

  return <TagComponent IconComponent={IconComponent} value={fullName} onRemove={onRemove} description={email} />;
};
