import { DmModalMemberComponent } from "@/components/roomPage/modals/dm/DmModalMemberComponent";
import { useDmTab } from "@/components/roomPage/modals/dm/tabs/useDmTab";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { useTranslation } from "react-i18next";
export const DmTabComponent = ({ roomId }: { roomId: string | undefined }): JSX.Element => {
  const { actions, handleMemberCheck, filteredMembers, checkedMembers, MAX_MEMBERS, handleChange } = useDmTab(roomId);
  const { t } = useTranslation();

  return (
    <ModalTabComponent hasPadding={false} footer={<ModalFooterComponent actions={actions} info={t("dms.modal.footerInfo")} />}>
      <div className="h-full w-full">
        <div className="mb-4 py-4 pb-0">
          <TextInputComponent
            onChange={handleChange}
            placeholder={roomId != null ? t("dms.modal.inputPlaceholderInRoom") : t("dms.modal.inputPlaceholderInHome")}
            className="px-layout"
          />
          <div className="mb-4">
            {filteredMembers.map((member) => (
              <div key={member.id} className="flex">
                <DmModalMemberComponent
                  member={member}
                  checked={checkedMembers.includes(member.userId)}
                  onChange={() => {
                    handleMemberCheck(member.userId);
                  }}
                  disabled={checkedMembers.length >= MAX_MEMBERS}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </ModalTabComponent>
  );
};
