import { RoomTypeEnum, type IQuotaData, type IRoom } from "@/api/client";
import { DowngradeFinishedConfirm } from "@/components/billing/modals/downgrade/DowngradeFinishedConfirm";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { closeModal, openModal } from "@/components/shared/modal/ModalService";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { RoomAvatarComponent } from "@/components/shared/room/RoomAvatarComponent";
import { ModalNames } from "@/constants/modalNames";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useBulkRemoveOrganisationActiveRooms } from "@/hooks/mutations/organisations/activeRooms/useBulkRemoveOrganisationActiveRoomsMutation";
import { useOrganisationActiveRoomsQuery } from "@/hooks/queries/organisations/useOrganisationActiveRoomsQuery";
import { useSearchInput } from "@/hooks/shared/useSearchInput";
import { QuotaType } from "@/interfaces/billing";
import { type IModalAction } from "@/interfaces/modal";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface IRemoveRoomsTabComponentProps {
  quotas: IQuotaData[];
}
export const RemoveRoomsTabComponent = ({ quotas }: IRemoveRoomsTabComponentProps) => {
  const { t } = useTranslation();
  const me = useContext(AuthenticationContext);
  const { data: activeProjectRooms } = useOrganisationActiveRoomsQuery(me?.mainOrganisation?.organisationId);
  const { mutateAsync: removeRooms, isPending } = useBulkRemoveOrganisationActiveRooms();

  const [checkedRoomIds, setCheckedRoomIds] = useState<string[]>([]);
  const [filteredRooms, setFilteredRooms] = useState<IRoom[]>([]);

  const { search, updateSearch } = useSearchInput();

  useEffect(() => {
    if (activeProjectRooms == null) return;
    const roomWithoutOrganisationRooms = activeProjectRooms.filter((room) => room.type !== RoomTypeEnum.Organisation);
    setFilteredRooms(roomWithoutOrganisationRooms.filter((room) => room.name.includes(search)));
  }, [activeProjectRooms, search]);

  const roomQuota = useMemo(() => {
    return quotas.find((quota) => quota.quotaType === QuotaType.ROOM);
  }, [quotas]);

  const howManyShouldBeRemoved = useMemo(() => {
    // @ts-expect-error wrong typing
    return roomQuota?.currentNumber - roomQuota?.limit;
  }, [roomQuota]);

  const howManyAreSelected = useMemo(() => {
    return checkedRoomIds.length;
  }, [checkedRoomIds]);

  const action = async () => {
    if (me?.mainOrganisation?.organisationId == null) return;
    await removeRooms({ organisationId: me?.mainOrganisation?.organisationId, roomIds: checkedRoomIds });
    closeModal(ModalNames.DOWNGRADE);
    openModal(ModalNames.CONFIRM_DOWNGRADE_FINISHED, <DowngradeFinishedConfirm />);
  };

  const actions: IModalAction[] = useMemo(() => {
    return [
      {
        action,
        label: t("billing.modals.downgrade.tabs.room.action"),
        type: "secondary",
        disabled: howManyAreSelected < howManyShouldBeRemoved,
      },
    ];
  }, [howManyAreSelected, howManyShouldBeRemoved]);

  const handleRoomClick = (memberId: string) => {
    if (checkedRoomIds.includes(memberId)) {
      setCheckedRoomIds(checkedRoomIds.filter((id) => id !== memberId));
    } else {
      setCheckedRoomIds([...checkedRoomIds, memberId]);
    }
  };

  return (
    <ModalTabComponent
      hasPadding={false}
      footer={
        <ModalFooterComponent
          actions={actions}
          info={t("billing.modals.downgrade.tabs.room.info", {
            howManyAreSelected: Math.min(howManyAreSelected, howManyShouldBeRemoved),
            howManyShouldBeRemoved,
          })}
        ></ModalFooterComponent>
      }
    >
      <>
        {isPending ? <LoaderComponent /> : null}
        <TextInputComponent
          value={search}
          onChange={(e) => {
            updateSearch(e.target.value);
          }}
          placeholder={t("billing.modals.downgrade.tabs.room.searchPlaceholder")}
          className="p-6 pb-0 pt-4"
        />

        <div className="w-full">
          {filteredRooms?.map((room) => (
            <div
              className="relative flex w-full cursor-pointer items-center border-b px-6 py-2 last:mb-2 last:border-0 hover:bg-gray-100 dark:hover:bg-dark-800"
              key={room.id}
              onClick={() => {
                handleRoomClick(room.id);
              }}
            >
              <RoomAvatarComponent roomId={room?.id} size="xs" className="h-7 w-7 rounded-md" />
              <span className="ml-2 text-sm">{room.name}</span>
              <input type="checkbox" className="absolute right-6 top-3.5" checked={checkedRoomIds.includes(room.id)} onChange={() => null} />
            </div>
          ))}
        </div>
      </>
    </ModalTabComponent>
  );
};
