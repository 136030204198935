import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useMyRoomsDmsQuery = (roomId?: string) => {
  return useQuery({
    queryKey: [QueryKeys.ROOMS, QueryKeys.DMS, roomId],
    queryFn: async () => {
      if (roomId == null) return null;
      const response = await api.my.getDirectMessagesFromRoom(roomId);
      return response.data.data;
    },
    enabled: roomId != null,
    staleTime: 0,
  });
};
