import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useCancelRoomInvite = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (data: { invitationId: string; roomId: string }) => {
      await api.rooms.cancelInvitation(data.roomId, data.invitationId);
      toast.success(t("createRoomModal.tabs.members.toasts.cancelInvite"));
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, data.roomId, QueryKeys.PENDING] });
    },
  });

  return mutation;
};
