import { type ITask } from "@/api/client";
import { useTaskItemContextMenu } from "@/components/roomPage/tabs/tasks/useTaskItemContextMenu";

interface ITaskItemComponentProps {
  task: ITask;
}

export const TaskItemContextMenuComponent = ({ task }: ITaskItemComponentProps) => {
  const { menuItems } = useTaskItemContextMenu(task);

  return (
    <div className="flex flex-col overflow-hidden rounded-lg p-0">
      {menuItems.map((item) => (
        <div key={item.title} className="flex w-full items-center justify-between p-2 hover:bg-zinc-100" onClick={item.onClick}>
          <div className="mr-2">{item.title}</div>
          {item.icon}
        </div>
      ))}
    </div>
  );
};
