import { type ITodo, type ITodoWithCount } from "@/api/client";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { AddElementTagComponent } from "@/components/shared/tag/AddElementTagComponent";
import { TaskTodoComponent } from "@/components/shared/tasks/TaskTodosComponent/components/TaskTodoComponent/TaskTodoComponent";
import { useTaskTodos } from "@/components/shared/tasks/TaskTodosComponent/useTaskTodos";
import { useCallback, useMemo, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface TaskTodosComponentProps {
  taskId: string | null;
  todos?: ITodoWithCount;
}

export const TaskTodosComponent: FunctionComponent<TaskTodosComponentProps> = ({ taskId, todos: todosWithCount }) => {
  const { t } = useTranslation();
  const { onAddElement, isAddingTodo, onSubmitNewTodo, onTodoRemove, onToggleTodo, todosWithCount: localTodosWithCount } = useTaskTodos(taskId);
  const renderItem = useCallback((todo: ITodo) => {
    const onTodoRemoveClick = () => {
      onTodoRemove(todo);
    };

    const onToggleTodoClick = () => {
      onToggleTodo(todo);
    };

    return <TaskTodoComponent todo={todo} onTodoRemove={onTodoRemoveClick} onToggleTodo={onToggleTodoClick} />;
  }, []);

  const todoInfos = useMemo(() => (taskId != null ? todosWithCount : localTodosWithCount), [taskId, todosWithCount, localTodosWithCount]);

  return (
    <div className="mb-4 flex w-full flex-col">
      <div className="mb-1 flex w-full items-center text-sm font-normal text-zinc-500 dark:text-dark-300">
        {t("editTaskModal.fields.todos.label")}{" "}
        <span className="ml-1 text-sky-500">
          ({todoInfos?.checkedCount ?? 0}/{todoInfos?.totalCount ?? 0})
        </span>
      </div>
      {(todoInfos?.todos ?? []).map(renderItem)}
      {isAddingTodo ? (
        <TextInputComponent
          key={`task-todo-add-${taskId}`}
          className="mb-1"
          placeholder={t("tasks.todos.add.placeholder")}
          autoFocus
          onBlur={(event) => {
            onSubmitNewTodo(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              // @ts-expect-error target does not contain value in type, but has it in runtime
              onSubmitNewTodo(event.target.value);
            }
          }}
        />
      ) : (
        <AddElementTagComponent value={t("tasks.todos.add.button")} onAddElement={onAddElement} />
      )}
    </div>
  );
};
