import { useCallback } from "react";

export const useBadge = () => {
  const MAX_BADGE_NUMBER = 99;
  const getComputedBadgeNumber = useCallback((badgeNumber: number) => {
    return badgeNumber > MAX_BADGE_NUMBER ? `${MAX_BADGE_NUMBER}+` : badgeNumber;
  }, []);

  return { getComputedBadgeNumber };
};
