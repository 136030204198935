import { useTypingIndicator } from "@/components/roomPage/tabs/conversation/typingIndicator/useTypingIndicator";
import { Trans } from "react-i18next";

export const TypingIndicator = () => {
  const { typingMessage } = useTypingIndicator();
  if (typingMessage === "") return null;
  return (
    <div className="absolute bottom-0.5 left-9 text-2xs">
      <Trans>{typingMessage}</Trans>
    </div>
  );
};
