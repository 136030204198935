import { type IChannelRoomIdName, type INotification, type IRoomIdName, type ISentFromRoom, type MessageConversationChannel } from "@/api/client";
import { notificationType } from "@/constants/notification";
import { useTranslation } from "react-i18next";

export const useNotificationLocation = () => {
  const { t } = useTranslation();

  const getLocationFromNotification = (notification: INotification) => {
    let channel: MessageConversationChannel | IChannelRoomIdName | undefined;
    let room: IRoomIdName | ISentFromRoom | undefined;

    if (notification.type === notificationType.NEW_EMAIL) {
      return notification.room?.name;
    }

    if (notification.message?.conversation?.directMessage != null) {
      if (notification.message.sentFromRoom?.name == null) {
        return t("notifications.fromDm");
      } else {
        return `${notification.message.sentFromRoom.name}`;
      }
    }

    if (notification.task != null) {
      channel = notification.task.channel;
      room = channel?.room;
    }

    if (notification.message?.conversation?.channel != null) {
      channel = notification.message.conversation.channel;
      room = notification.message.sentFromRoom;
    }

    const roomName: string = room?.name ?? "";
    const channelName: string = channel?.name ?? "";
    return `${roomName} · #${channelName}`;
  };

  return getLocationFromNotification;
};
