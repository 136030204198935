import { ChannelModalComponent } from "@/components/roomPage/modals/channel/ChannelModalComponent";
import { ConversationTabComponent } from "@/components/roomPage/tabs/conversation/ConversationTabComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { ModalNames } from "@/constants/modalNames";
import { RoomContext } from "@/contexts/RoomContext";
import { useChannelQuery } from "@/hooks/queries/channels/useChannelQuery";
import { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

interface IChannelConversationProps {
  channelIdProp?: string | undefined;
}

export const ChannelConversationComponent = ({ channelIdProp }: IChannelConversationProps): JSX.Element => {
  const { channelId: channelIdParam } = useParams<{ id: string; channelId: string }>();
  const roomContextValue = useContext(RoomContext);
  const roomId = roomContextValue?.roomResponse?.room?.id;

  const channelId = useMemo(() => {
    return channelIdProp ?? channelIdParam;
  }, [channelIdParam, channelIdProp]);

  const { data: channel } = useChannelQuery(channelId);

  const openChannelModal = useCallback(
    (tab: "about" | "members" | undefined): void => {
      if (roomId == null || channel == null) return;
      openModal(
        ModalNames.CHANNEL_MODAL,
        <ChannelModalComponent
          title=""
          roomId={roomId}
          defaultTab={tab}
          channel={channel.channel}
          conversationId={channel?.channel.conversation.id}
        />,
      );
    },
    [roomId, channel],
  );

  return (
    <>
      {channel?.channel.conversation != null && (
        <ConversationTabComponent
          contextType="channel"
          participantTranslationKey="general.channelMembersWithCount"
          title={channel?.channel.name ?? ""}
          conversationId={channel.channel.conversation.id}
          namePrefix="#"
          actionOnParticipants={() => {
            openChannelModal("members");
          }}
          actionOnTitle={() => {
            openChannelModal("about");
          }}
        />
      )}
    </>
  );
};
