import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { useCreateTaskStatusPopinContent } from "@/components/shared/tasks/CreateTaskStatusPopinComponent/useCreateTaskStatusPopinContent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CgCheck, CgClose } from "react-icons/cg";

export const CreateTaskStatusPopinContentComponent: FunctionComponent = () => {
  const { t } = useTranslation();

  const { handleNameChange, handleNameKeyDown, handleCancel, addColumn } = useCreateTaskStatusPopinContent();

  return (
    <nav
      className="w-48 rounded-lg"
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="mb-1 flex w-full cursor-pointer flex-col items-start rounded-t-lg p-2">
        <label htmlFor="createColumn">{t("tasks.create.label")}</label>
        <div className="mt-1 flex w-full items-center">
          <TextInputComponent autoFocus id="createColumn" className="h-4.5 mb-[0]" onChange={handleNameChange} onKeyDown={handleNameKeyDown} />
          <button onClick={addColumn}>
            <CgCheck className="h-6 w-6" />
          </button>
          <button onClick={handleCancel}>
            <CgClose className="h-4 w-4" />
          </button>
        </div>
      </div>
    </nav>
  );
};
