import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect } from "react";

import { type IOrganisation, type IOrganisationMember } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";

export const useOrganisationSocket = () => {
  const socket = useContext(SocketContext);
  const queryClient = useQueryClient();

  const onOrganizationMemberUpdated = useCallback(
    (organizationMember: IOrganisationMember) => {
      queryClient.setQueryData<IOrganisationMember[]>([QueryKeys.ORGANISATIONS, organizationMember.organisationId, QueryKeys.MEMBERS], (oldData) => {
        if (oldData == null) {
          return oldData;
        }

        return oldData.map((currentOrganizationMember) =>
          currentOrganizationMember.id === organizationMember.id ? organizationMember : currentOrganizationMember,
        );
      });

      queryClient.setQueryData<IOrganisation>([QueryKeys.MY_ORGANISATION], (oldData) => {
        if (oldData == null) {
          return oldData;
        }

        const users = oldData.users?.map((user) => (user.id === organizationMember.userId ? { ...user, role: organizationMember.role } : user)) ?? [];

        return {
          ...oldData,
          users,
        };
      });
    },

    [queryClient],
  );

  useEffect(() => {
    if (socket == null) return;
    socket.on(SocketEvents.ORGANISATION_MEMBER_UPDATED, onOrganizationMemberUpdated);
    return () => {
      socket.off(SocketEvents.ORGANISATION_MEMBER_UPDATED, onOrganizationMemberUpdated);
    };
  }, [socket]);
};
