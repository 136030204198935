import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { useMultipleFileDownloadMutation } from "@/hooks/mutations/files/useMultipleFileDownloadMutation";
import { useDownload } from "@/hooks/shared/useDownload";
import { useCallback, useContext } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useFileTableHeaderContextActions = () => {
  const { t } = useTranslation();
  const LIMIT_FOR_SHARING = 10;
  const socket = useContext(SocketContext);
  const { download } = useDownload();
  const { mutate: requestDownload } = useMultipleFileDownloadMutation();

  const handleDownloadClick = useCallback(
    (selectedItems: string[]) => {
      if (socket == null) return;

      const downloadPromise = new Promise<void>((resolve, _reject) => {
        const onZipFinished = (data: { presignedUrl: string }) => {
          download(data.presignedUrl);
          socket.off(SocketEvents.ZIP_MULTIPLE_FINISHED, onZipFinished);
          resolve();
        };
        socket.on(SocketEvents.ZIP_MULTIPLE_FINISHED, onZipFinished);

        requestDownload(selectedItems);
      });

      void toast.promise(downloadPromise, {
        loading: t("files.download.toast.loading"),
        success: t("files.download.toast.success"),
        error: t("files.download.toast.error"),
      });
    },
    [socket],
  );

  return { handleDownloadClick, LIMIT_FOR_SHARING };
};
