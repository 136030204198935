import { type IGoogleCalendarEventsCreate } from "@/api/client";
import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";

export const useSendGoogleCalendarEventMutation = () => {
  return useMutation({
    mutationFn: async (data: IGoogleCalendarEventsCreate) => {
      await api.calendars.googleCalendarCreateEvent(data);
    },
  });
};
