import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useSearchChannelsQuery = (searchText: string, enabled?: boolean) => {
  return useQuery({
    queryKey: [QueryKeys.CHANNELS, QueryKeys.SEARCH, searchText],
    queryFn: async () => {
      const response = await api.channels.searchChannels({
        channelSearchFromId: "1",
        channelSearchQuery: searchText,
      });
      return response.data.data;
    },
    enabled,
  });
};
