import { type ISearchedMessage, type ISearchMessagesResponse } from "@/api/client";
import { SearchedMessageComponent } from "@/components/search/SearchedMessageComponent";
import { type InfiniteData, type InfiniteQueryObserverResult } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";

interface ISearchMessageResultProps {
  searchResult: ISearchedMessage[];
  fetchNextPage: () => Promise<InfiniteQueryObserverResult<InfiniteData<ISearchMessagesResponse, unknown>, Error>>;
}

export const SearchMessageResultComponent = ({ searchResult, fetchNextPage }: ISearchMessageResultProps) => {
  const { t } = useTranslation();

  return (
    <div className="grid h-96 grow grid-cols-1">
      {searchResult?.length > 0 ? (
        <Virtuoso
          className="min-h-0"
          style={{ gridColumn: "1 / span 1", gridRow: "1 / span 1" }}
          data={searchResult}
          itemContent={(index: number, message: ISearchedMessage) => <SearchedMessageComponent key={message.id} message={message} />}
          endReached={async () => await fetchNextPage()}
        />
      ) : (
        <div className="p-4 text-center text-gray-400">{t("header.search.messages.noResult")}</div>
      )}
    </div>
  );
};
