import { type IAuthUpdateMyPassword } from "@/api/client";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { PictureUploaderComponent } from "@/components/shared/uploader/pictureUploader/PictureUploaderComponent";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useMeMutations } from "@/hooks/mutations/me/useMeMutations";
import { type IModalAction } from "@/interfaces/modal";
import { nameValidation } from "@/utils/formValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { useContext, useMemo } from "react";
import { useForm, type SubmitErrorHandler, type SubmitHandler } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const EditProfileTab = (): JSX.Element => {
  const me = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const { updateMeMutation } = useMeMutations();

  const inputs = useMemo(
    () => [
      {
        name: "firstName",
        label: t("forms.fields.firstName"),
        type: "text",
        defaultValue: me?.firstName ?? "",
      },
      {
        name: "lastName",
        label: t("forms.fields.lastName"),
        type: "text",
        defaultValue: me?.lastName ?? "",
      },
      {
        name: "email",
        label: t("forms.fields.email"),
        type: "email",
        defaultValue: me?.email ?? "",
        info: t("forms.formValidation.errors.email_not_editable"),
        disabled: true,
      },
      {
        name: "position",
        label: t("forms.fields.position"),
        defaultValue: me?.position ?? "",
        type: "text",
      },
      {
        name: "phone",
        label: t("forms.fields.phone"),
        defaultValue: me?.phone ?? "",
        type: "tel",
      },
    ],
    [me],
  );

  const validationSchema = Yup.object().shape({
    firstName: nameValidation(t, t("forms.fields.firstName")),
    lastName: nameValidation(t, t("forms.fields.lastName")),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm<IAuthUpdateMyPassword>({
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: me?.firstName,
      lastName: me?.lastName,
      position: me?.position,
      phone: me?.phone,
    },
  });
  const onSubmit: SubmitHandler<IAuthUpdateMyPassword> = (data) => {
    if (updateMeMutation.isSuccess) return;
    updateMeMutation.mutate(data, {
      onSuccess: () => {
        toast.success(t("toasts.profileUpdated"));
      },
    });
  };
  const onError: SubmitErrorHandler<IAuthUpdateMyPassword> = (err) => {
    console.error(err);
  };

  const actions: IModalAction[] = useMemo(
    () => [
      {
        action: handleSubmit(onSubmit, onError),
        label: t("general.save"),
        type: "primary",
        disabled: !isValid || !isDirty,
      },
    ],
    [handleSubmit, t, isValid, isDirty],
  );

  return (
    <>
      {me != null && (
        <ModalTabComponent
          footer={
            <ModalFooterComponent
              actions={actions}
              info={t("header.editProfile.modal.tabs.profile.accountCreatedOn", { date: format(new Date(me.createdAt), t("date.shortFormat")) })}
            />
          }
        >
          <form onSubmit={handleSubmit(onSubmit, onError)} className="flex items-start justify-between">
            <div className="left-column flex flex-wrap items-start justify-between">
              {inputs.map((input) => (
                <div
                  key={`cti-${input.name}`}
                  className={`mb-4 h-full ${input.name === "email" ? "w-full" : "mr-2 flex-1 [&:nth-child(3n+2)]:mr-0"}`}
                >
                  {input.type === "tel" ? (
                    <TextInputComponent {...input} register={register} error={errors[input.name as keyof typeof errors]?.message} control={control} />
                  ) : (
                    <TextInputComponent {...input} register={register} error={errors[input.name as keyof typeof errors]?.message} />
                  )}
                </div>
              ))}
            </div>
            <div className="right-column ml-4 flex flex-col">
              <label className="mb-1 flex items-center text-sm font-bold text-zinc-500">
                {t("header.editProfile.modal.tabs.profile.profilePicture")}
              </label>
              <PictureUploaderComponent pictureUrl={me.profilePicture?.url} />
            </div>
          </form>
        </ModalTabComponent>
      )}
    </>
  );
};
