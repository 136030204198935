import { type IEmail } from "@/api/client";
import { EmailComponent } from "@/components/roomPage/tabs/emails/EmailComponent";
import { EmailListPopinComponent } from "@/components/roomPage/tabs/emails/emailListPopin/EmailListPopinComponent";
import { EmailReplyButtonComponent } from "@/components/roomPage/tabs/emails/emailReply/EmailReplyButtonComponent";
import { EmailReplyComponent } from "@/components/roomPage/tabs/emails/emailReply/EmailReplyComponent";
import { useEmailList } from "@/components/roomPage/tabs/emails/useEmailList";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { EmailThreadContext } from "@/contexts/EmailThreadContextProvider";
import { useContext, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { FaCog } from "react-icons/fa";
import { Virtuoso } from "react-virtuoso";

export const EmailListComponent: FunctionComponent = () => {
  const { t } = useTranslation();
  const { emails, thread, initialTopMostItemIndex, isPopinVisible, setIsPopinVisible, virtuosoRef } = useEmailList();
  const { isReplyOpened } = useContext(EmailThreadContext);

  return (
    <div className="relative flex h-full w-1/2 flex-col border-l">
      {emails == null || thread == null ? (
        <LoaderComponent></LoaderComponent>
      ) : (
        <>
          <header className="flex border-b bg-zinc-50 p-layout">
            <div className="font-semibold">{thread.lastEmail?.subject}</div>
            <div className="px-2 text-zinc-300">|</div>
            <div className="text-zinc-400">{t("roomPage.tabs.mails.threadPreview.emailCount", { count: thread.emailCount })}</div>
            <div className="ml-auto">
              <EmailListPopinComponent
                isVisible={isPopinVisible}
                onCloseTooltip={() => {
                  setIsPopinVisible(false);
                }}
              >
                <button
                  onClick={() => {
                    setIsPopinVisible(!isPopinVisible);
                  }}
                  className="text-zinc-500 transition-all hover:text-zinc-600"
                >
                  <FaCog className="h-3 w-3" />
                </button>
              </EmailListPopinComponent>
            </div>
          </header>
          <div className="mr-2 h-full w-full overflow-y-scroll pl-layout pr-1 transition-all">
            <Virtuoso
              key={thread.id}
              data={emails}
              itemContent={(_index: number, email: IEmail) => <EmailComponent email={email} key={email.id} />}
              initialTopMostItemIndex={initialTopMostItemIndex}
              ref={virtuosoRef}
            />
          </div>
          {thread.id != null ? (
            <footer className="text-editor relative pb-layout">
              {isReplyOpened ? (
                <EmailReplyComponent threadId={thread.id} />
              ) : (
                <div className="flex justify-end px-layout pt-2">
                  <EmailReplyButtonComponent email={emails?.[emails?.length - 1]} />
                </div>
              )}
            </footer>
          ) : null}
        </>
      )}
    </div>
  );
};
