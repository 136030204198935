import { DrawerContext, type DrawerContextType } from "@/components/shared/drawer/DrawerContext";
import { DrawerOverlayType, overlayByDrawerType, type DrawerType, type IDrawer, type OverlayCallback } from "@/components/shared/drawer/DrawerTypes";
import { useMemo, useState, type FunctionComponent, type PropsWithChildren } from "react";

export const DrawerProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [openedDrawers, setOpenedDrawers] = useState<IDrawer[]>([]);

  const [overlayCallback, setOverlayCallback] = useState<OverlayCallback | null>(null);

  const isOpened = useMemo(() => openedDrawers.length !== 0, [openedDrawers]);
  const [isAnimationDisabled, setIsAnimationDisabled] = useState<boolean>(false);

  const closeDrawer = (type: DrawerType, disableAnimation = false) => {
    setOverlayCallback(null);
    setIsAnimationDisabled(disableAnimation);
    setOpenedDrawers((currentOpenedDrawers) => {
      const filteredDrawers = currentOpenedDrawers.filter((openedDrawer) => openedDrawer.drawerType !== type);

      return filteredDrawers;
    });
  };

  const closeAllDrawers = () => {
    setOpenedDrawers([]);
  };

  const openDrawer = (drawer: IDrawer, disableAnimation = false): void => {
    setIsAnimationDisabled(disableAnimation);
    setOpenedDrawers((currentOpenedDrawers) => {
      const filteredDrawers = currentOpenedDrawers.filter((openedDrawer) => openedDrawer.drawerType !== drawer.drawerType);

      filteredDrawers.push(drawer);

      return filteredDrawers;
    });
  };

  const updateOverlayCallback = (callback: OverlayCallback | null) => {
    setOverlayCallback(() => callback);
  };

  const drawerContextValue: DrawerContextType = useMemo(() => {
    return {
      isOpened,
      isAnimationDisabled,
      closeDrawer,
      closeAllDrawers,
      openDrawer,
      openedDrawers,
      overlayCallback,
      setOverlayCallback: updateOverlayCallback,
    };
  }, [isOpened, isAnimationDisabled, openedDrawers]);

  const currentDrawer = openedDrawers.length > 0 ? openedDrawers[openedDrawers.length - 1] : null;

  const overlayType = currentDrawer != null ? overlayByDrawerType[currentDrawer.drawerType] : null;

  const onOverlayPress = () => {
    if (currentDrawer == null) {
      return;
    }

    if (overlayCallback != null) {
      overlayCallback(() => {
        closeDrawer(currentDrawer.drawerType);
      });
    } else {
      closeDrawer(currentDrawer.drawerType);
    }
  };

  return (
    <DrawerContext.Provider value={drawerContextValue}>
      {children}
      {overlayType === DrawerOverlayType.OPAQUE ? (
        <div className="absolute inset-0 z-30 cursor-pointer bg-dark-500/20" onClick={onOverlayPress} />
      ) : null}
      {overlayType === DrawerOverlayType.TRANSPARENT ? <div className="absolute inset-0 z-10 bg-dark-500/5" onClick={onOverlayPress} /> : null}
    </DrawerContext.Provider>
  );
};
