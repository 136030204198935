import { format, isThisYear, isToday } from "date-fns";
import { useTranslation } from "react-i18next";

export const useDateFormat = () => {
  const { t } = useTranslation();
  const formatDateForEmail = (dateString?: string): string => {
    if (dateString == null) return "";

    const date = new Date(dateString);
    const timeFormat = t("date.timeFormat");
    const dateFormatThisYear = t("date.thisYearFormat");
    const dateFormatOverThisYear = t("date.overThisYearFormat");

    if (isToday(date)) {
      return format(date, timeFormat);
    } else if (isThisYear(date)) {
      return `${format(date, dateFormatThisYear)}`;
    } else {
      return `${format(date, dateFormatOverThisYear)}`;
    }
  };
  return {
    formatDateForEmail,
  };
};
