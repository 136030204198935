import { type IMessageForward } from "@/api/client";
import { eventNames } from "@/constants/eventNames";
import { useCallback, useEffect, useState } from "react";

export const use1on1Reply = () => {
  const [forwardMessageState, setForwardMessageState] = useState<IMessageForward | undefined>(undefined);

  const handleReplyToMessage = useCallback((event: CustomEvent) => {
    const message = event.detail as IMessageForward;
    setForwardMessageState(message);
  }, []);

  useEffect(() => {
    window.addEventListener(eventNames.REPLY_TO_MESSAGE, (event) => {
      handleReplyToMessage(event as CustomEvent);
    });

    return () => {
      window.removeEventListener(eventNames.REPLY_TO_MESSAGE, (event) => {
        handleReplyToMessage(event as CustomEvent);
      });
    };
  }, []);

  return { forwardMessageState, setForwardMessageState };
};
