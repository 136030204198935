import { UserActivityStatus } from "@/api/client";
import { type FunctionComponent } from "react";

interface UserStatusBadgeComponentProps {
  status: UserActivityStatus | null;
  isAbsolute?: boolean;
}

export const UserStatusBadgeComponent: FunctionComponent<UserStatusBadgeComponentProps> = ({ status, isAbsolute = false }) => {
  if (status == null) {
    return null;
  }

  const backgroundColorByStatus: Record<UserActivityStatus, string> = {
    [UserActivityStatus.Active]: "bg-green-500",
    [UserActivityStatus.Inactive]: "bg-white",
    [UserActivityStatus.Offline]: "bg-red-400",
  };

  const borderColorByStatus: Record<UserActivityStatus, string> = {
    [UserActivityStatus.Active]: "border-green-500",
    [UserActivityStatus.Inactive]: "border-gray-400",
    [UserActivityStatus.Offline]: "border-red-400",
  };

  const absoluteClass = "absolute -bottom-0.5 -right-0.5 w-[40%] max-w-[14px] z-10";
  const relativeClass = "w-3";

  return (
    <div className={`${isAbsolute ? absoluteClass : relativeClass} aspect-square rounded-full border border-white `}>
      <div className={`aspect-square rounded-full border ${borderColorByStatus[status]} ${backgroundColorByStatus[status]}`}></div>
    </div>
  );
};
