import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";

export const useUnpinMutation = () => {
  return useMutation({
    mutationFn: async (messageId: string) => {
      return await api.my.unpinMessage(messageId);
    },
  });
};
