import { RoomMemberStatus, type IRoomMember } from "@/api/client";
import { RoomModal } from "@/components/homePage/modals/room/RoomModal";
import { NumberBadge } from "@/components/shared/badges/NumberBadge";
import { openModal } from "@/components/shared/modal/ModalService";
import { RoomAvatarComponent } from "@/components/shared/room/RoomAvatarComponent";
import { ModalNames } from "@/constants/modalNames";
import { roomType } from "@/constants/room";
import { useRoomNotificationSettings } from "@/hooks/shared/notificationSettings/useRoomNotificationSettings";
import { PreferencesContext, usePreferences } from "@/hooks/shared/usePreferences";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Menu } from "@headlessui/react";
import { Fragment, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AiFillStar as FillStarIcon, AiOutlineStar as OutlineStarIcon } from "react-icons/ai";
import { BiCog as SettingsIcon } from "react-icons/bi";
import { BsBellSlash, BsFillArchiveFill, BsThreeDotsVertical as ThreeDotsIcon } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

interface IListViewRoomCardProps {
  room: IRoomMember;
  isDragging?: boolean;
}

export const ListViewRoomCard = ({ room, isDragging = false }: IListViewRoomCardProps): JSX.Element => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: room.id });
  const style = { transform: CSS.Transform.toString(transform), transition };
  const navigate = useNavigate();
  const { preferences } = useContext(PreferencesContext);
  const { setPreference } = usePreferences();
  const { isNotificationEnabled } = useRoomNotificationSettings(room.roomId);
  const customRoomName = preferences?.[`room.${room.room.id}`]?.name ?? room.name;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (!isDragging) navigate(`/dashboard/rooms/${room.room.id}`);
    },
    [isDragging, navigate, room.room.id],
  );

  const isRoomArchived = room.status === RoomMemberStatus.Archived;

  const openEditRoomModal = useCallback(() => {
    openModal(ModalNames.CREATE_ROOM, <RoomModal isOrganisationRoom={room.room.type === roomType.ORGANISATION} roomId={room.room.id} />);
  }, [room.room.id, room.room.type]);

  const isStarred = preferences?.starredRooms?.includes(room.room.id) ?? false;

  const handleStarClick = useCallback(() => {
    const starredRooms = preferences?.starredRooms ?? [];
    setPreference("starredRooms", isStarred ? starredRooms.filter((id) => id !== room.room.id) : [...starredRooms, room.room.id]);
  }, [preferences?.starredRooms, isStarred, room.room.id, setPreference]);

  return (
    <article className="group/roomcard relative" ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="relative mb-[4px] flex w-full items-center justify-between rounded-[8px] px-[8px] py-[3px] hover:bg-[#F4F4F5] dark:hover:bg-[rgba(255,255,255,0.1)]">
        <div onClick={handleClick} className="flex w-full items-center">
          <RoomAvatarComponent roomId={room.roomId} size="md" className="h-[30px] w-[30px] rounded-md border-2 transition-all duration-200" />
          <div className="ml-[6px] truncate text-sm font-normal text-zinc-800 dark:text-dark-400">{customRoomName}</div>
          {isRoomArchived ? (
            <BsFillArchiveFill className="ml-1 h-3 w-3 dark:fill-dark-400" />
          ) : !isNotificationEnabled ? (
            <BsBellSlash className="ml-1 h-3 w-3 dark:fill-dark-400" />
          ) : null}
        </div>
        {!isRoomArchived && (
          <div className="relative flex items-center">
            <div className="relative -mt-1 mr-2">
              <NumberBadge count={room.badge} size="sm" />
            </div>
            <RoomDropDownMenu isStarred={isStarred} favOnClick={handleStarClick} editOnClick={openEditRoomModal} />
          </div>
        )}
      </div>
    </article>
  );
};

interface IRoomDropDownProps {
  isStarred: boolean;
  favOnClick: () => void;
  editOnClick: () => void;
  isDragging?: boolean;
}

const RoomDropDownMenu = ({ isStarred, favOnClick, editOnClick }: IRoomDropDownProps) => {
  const { t } = useTranslation();
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="flex items-center justify-center">
        <ThreeDotsIcon className="h-[20px] w-[30px] fill-zinc-600 dark:fill-dark-400" />
      </Menu.Button>
      <Menu.Items as={Fragment}>
        <div className="absolute right-0 z-[51] mt-2 rounded-md bg-white px-2 py-4 shadow-lg ring-1 ring-dark-100 focus:outline-none dark:bg-dark-700 dark:ring-transparent">
          <Menu.Item>
            {({ active, close }) => (
              <button
                onClick={() => {
                  favOnClick();
                  close();
                }}
                className={`flex items-center gap-[6px] p-2 ${active ? "rounded-md bg-dark-50 dark:bg-[rgba(255,255,255,0.1)]" : ""}`}
              >
                {isStarred ? (
                  <FillStarIcon className="h-5 w-5 text-yellow-400 opacity-80 transition-opacity" />
                ) : (
                  <OutlineStarIcon className="h-5 w-5 text-zinc-600 opacity-80 transition-opacity dark:text-dark-200" />
                )}
                <div className="whitespace-nowrap text-sm text-zinc-600 dark:text-dark-200">
                  {isStarred ? t("homePage.layout.roomDropDown.removeFav") : t("homePage.layout.roomDropDown.addToFav")}
                </div>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active, close }) => (
              <button
                className={`flex w-full items-center gap-[6px] p-2 ${active ? "rounded-md bg-dark-50 dark:bg-[rgba(255,255,255,0.1)]" : ""}`}
                onClick={() => {
                  editOnClick();
                  close();
                }}
              >
                <SettingsIcon className="h-5 w-5 text-zinc-600 opacity-80 transition-opacity hover:opacity-100 dark:text-dark-200" />
                <div className="whitespace-nowrap text-sm text-zinc-600 dark:text-dark-200">{t("homePage.layout.roomDropDown.settings")}</div>
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
};
