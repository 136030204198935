import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ThreadPreviewSkeletonComponent = (): JSX.Element => {
  return (
    <article className="absolute w-full pb-3 pr-4">
      <div className="overflow-hidden rounded-lg border">
        <header className="flex border-b bg-zinc-50 p-3">
          <div className="h-5 w-1/2 font-semibold">
            <Skeleton />
          </div>
        </header>
        <div className="p-3">
          <div className="flex w-full pb-1">
            <UserAvatarComponent userName="" size="md" className="mr-2 w-6 " />
            <Skeleton inline={true} width="200px" />
            <div className="ml-auto">
              <Skeleton inline={true} width="100px" />
            </div>
          </div>
          <div>
            <Skeleton count={2} />
          </div>
        </div>
      </div>
    </article>
  );
};
