import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useEmailsBadgeQuery = (roomId?: string) => {
  return useQuery<number>({
    queryKey: [QueryKeys.EMAIL_THREADS, QueryKeys.EMAILS_BADGE, roomId],
    queryFn: async () => {
      if (roomId == null) return 0;
      const response = await api.emails.getEmailBadge(
        { roomId },
        {
          defaultToastErrorMessage: null,
        },
      );
      return response.data.data.value;
    },
    retry: 0,
    enabled: roomId != null,
  });
};
