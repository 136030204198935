import { type ITask, type ITaskUpdateBody } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface TaskUpdateBody extends ITaskUpdateBody {
  taskId: string;
}

export const useUpdateTaskMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ taskId, ...params }: TaskUpdateBody) => {
      const { data } = await api.tasks.update(taskId, params);

      return data;
    },
    onMutate: ({ taskId, description, priority, isPrivate, deadline, ...params }: TaskUpdateBody) => {
      queryClient.setQueriesData<ITask>(
        {
          exact: true,
          queryKey: [QueryKeys.TASK, taskId],
        },
        (oldData) => {
          if (oldData == null) {
            return oldData;
          }
          return {
            ...oldData,
            ...params,
            description: description ?? oldData.description,
            priority: priority ?? oldData.priority,
            deadline: deadline ?? oldData.deadline,
            isPrivate: isPrivate ?? oldData.isPrivate,
          };
        },
      );
    },
    onSuccess: ({ data }) => {
      void queryClient.invalidateQueries({
        exact: false,
        queryKey: [QueryKeys.TASKS],
      });
      queryClient.setQueriesData<ITask>(
        {
          exact: true,
          queryKey: [QueryKeys.TASK, data.id],
        },
        data,
      );
    },
  });

  return mutation;
};
