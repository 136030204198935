import { type IRoomAdd } from "@/api/client";
import { eventNames } from "@/constants/eventNames";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useCreateRoomMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newRoom: IRoomAdd) => {
      const res = await api.rooms.add(newRoom, { defaultToastErrorMessage: t("createRoomModal.tabs.about.create.error") });
      const event = new CustomEvent(eventNames.ROOM_CREATED, {
        detail: res.data.room,
      });

      queryClient.setQueryData([QueryKeys.ROOMS, res.data.room.id], res.data.room);
      window.dispatchEvent(event);

      await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_ROOMS] });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS] });
      return toast.success(t("createRoomModal.tabs.about.create.success"));
    },
  });
};
