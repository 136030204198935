import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { requestPasswordReset } from "@/services/AuthService";
import { useSignInStore } from "@/stores/signInStore";
import { emailValidation } from "@/utils/formValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm, type SubmitErrorHandler, type SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
interface IForgetPasswordFormInputs {
  email: string;
}

export const ForgetPasswordForm = (): JSX.Element => {
  const { t } = useTranslation();
  const { email } = useSignInStore();
  const [isMailSent, setIsMailSent] = useState(false);
  const validationSchema = Yup.object().shape({
    email: emailValidation(t),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<IForgetPasswordFormInputs>({
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const inputs = [
    {
      name: "email",
      label: t("forms.fields.email"),
      autoComplete: "on",
      type: "email",
      requiredText: "",
      defaultValue: email,
    },
  ];

  const onSubmit: SubmitHandler<IForgetPasswordFormInputs> = async (data) => {
    await requestPasswordReset(data.email);
    setIsMailSent(true);
  };
  const onError: SubmitErrorHandler<IForgetPasswordFormInputs> = (err) => {
    // TODO use global error handler
    console.error(err);
  };
  return (
    <>
      {!isMailSent ? (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {inputs.map((input) => (
            <TextInputComponent key={`cti-${input.name}`} {...input} register={register} error={errors[input.name as keyof typeof errors]?.message} />
          ))}
          <ButtonComponent type="submit" status="primary" className="w-full" align="center" disabled={!isValid}>
            {t("authentication.forgetPassword.send")}
          </ButtonComponent>
        </form>
      ) : (
        <span className="block text-gray-400">{t("authentication.forgetPassword.success")}</span>
      )}
    </>
  );
};
