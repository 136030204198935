import type { IEmailIdentity } from "@/api/client";
import { type FunctionComponent } from "react";

interface IEmailRecipientsSectionComponentProps {
  recipients?: IEmailIdentity[];
  label: string;
}

export const EmailRecipientsSectionComponent: FunctionComponent<IEmailRecipientsSectionComponentProps> = ({ recipients, label }) => {
  return (
    <>
      {recipients != null && recipients.length > 0 ? (
        <div className="mb-2">
          <strong>{label}</strong>
          {recipients.map((recipient) => (
            <div className="whitespace-nowrap font-normal" key={recipient.email}>
              {recipient.raw}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};
