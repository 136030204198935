import { type IRoomIdName, type ITask } from "@/api/client";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { CreateTaskDrawerComponent } from "@/components/shared/tasks/CreateTaskDrawerComponent/CreateTaskDrawerComponent";
import { useCallback, useContext, useMemo } from "react";

export const useTaskRoomColumn = (tasks: ITask[], room: IRoomIdName) => {
  const tasksToDisplay = useMemo(() => tasks.filter((task) => task.room?.id === room.id), [tasks, room]);
  const { openDrawer } = useContext(DrawerContext);

  const handleCreateTaskClick = useCallback(() => {
    openDrawer({
      drawerType: DrawerType.CREATE_TASK,
      component: <CreateTaskDrawerComponent roomId={room.id} />,
    });
  }, []);
  return {
    tasksToDisplay,
    handleCreateTaskClick,
  };
};
