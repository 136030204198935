import { AiOutlineLoading3Quarters } from "react-icons/ai";

export const LoaderComponent = ({ ...props }: React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
  return (
    <>
      <div
        className={`absolute z-20 flex h-full w-full flex-col items-center justify-center bg-white/50 opacity-100 dark:bg-dark-900/50 dark:text-dark-300 ${
          props.className ?? ""
        }`}
      >
        <AiOutlineLoading3Quarters className="h-8 w-8 animate-spin" />
      </div>
    </>
  );
};
