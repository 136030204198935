import translationEn from "@/locales/en/translation.json";
import translationFr from "@/locales/fr/translation.json";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: translationEn,
  },
  fr: {
    translation: translationFr,
  },
};
void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    defaultNS: "translation",
    resources,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
