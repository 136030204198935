import { useUpdateConversationNotificationSettingsMutation } from "@/hooks/mutations/notificationSettings/useUpdateConversationNotificationSettingsMutation";
import { useRoomNotificationSettingsQuery } from "@/hooks/queries/notificationSettings/useRoomNotificationSettingsQuery";
import { useCallback, useMemo } from "react";

export const useChannelNotificationSettings = (roomId: string, conversationId: string | undefined) => {
  const { data: roomNotificationSettings, isLoading } = useRoomNotificationSettingsQuery(roomId);
  const { mutate } = useUpdateConversationNotificationSettingsMutation(roomId);

  const channelSetting = useMemo(() => {
    if (conversationId == null || roomId == null || roomId === "") return undefined;
    return roomNotificationSettings?.conversationNotificationSettings?.find((settings) => settings.id === conversationId);
  }, [roomNotificationSettings, conversationId, roomId]);

  const isNotificationEnabled = useMemo(() => {
    if (channelSetting?.notificationSettings == null) return true;
    return channelSetting.notificationSettings.enableNotifications;
  }, [channelSetting]);

  const onToggle = useCallback(
    (_isNotificationEnabled: boolean) => {
      if (conversationId == null) return;
      mutate({ conversationId, isNotificationEnabled: _isNotificationEnabled });
    },
    [conversationId],
  );

  return { onToggle, isNotificationEnabled, isLoading };
};
