import { ProfileDropdownContent } from "@/components/header/profileDropdown/ProfileDropdownContent";
import { ProfileDropdownFooter } from "@/components/header/profileDropdown/ProfileDropdownFooter";
import { ProfileModal } from "@/components/header/profileDropdown/profileModal/ProfileModal";
import { ThePopoverComponent } from "@/components/shared/layout/ThePopoverComponent";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useModal } from "@/hooks/shared/useModal";
import { Popover } from "@headlessui/react";
import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const ProfileDropdown = (): JSX.Element => {
  const { openModal, getModalURIParams } = useModal();
  const [searchParams] = useSearchParams();
  const me = useContext(AuthenticationContext);
  const availableModalsComponents = {
    editProfile: ProfileModal,
  };

  // To open a modal according to query params
  useEffect(() => {
    const modalParam = getModalURIParams().modal as keyof typeof availableModalsComponents;
    const activeTab = getModalURIParams().tab as string;
    if (modalParam == null) return;

    const ModalComponent = availableModalsComponents[modalParam];
    if (ModalComponent == null) return;
    openModal(modalParam, <ModalComponent activeTab={activeTab} />);
  }, [searchParams]);

  return (
    <>
      {me != null && (
        <ThePopoverComponent
          className="top-9"
          align="right"
          button={<UserAvatarComponent userId={me.id} userName={`${me.firstName ?? ""} ${me.lastName ?? ""}`} className="h-7" />}
          footer={<Popover.Panel>{({ close }) => <ProfileDropdownFooter closeDropdown={close} />}</Popover.Panel>}
        >
          <Popover.Panel>{({ close }) => <ProfileDropdownContent closeDropdown={close} />}</Popover.Panel>
        </ThePopoverComponent>
      )}
    </>
  );
};
