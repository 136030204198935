import React from "react";

const Progress: React.FC<
  React.PropsWithChildren<{
    percent?: number;
  }>
> = (props) => {
  const { percent = 0 } = props;
  return (
    <div className="my-4 flex w-full items-center">
      <div className="h-[10px] w-full overflow-hidden rounded-full border border-sky-600">
        <div className="h-full bg-sky-600" style={{ width: `${percent}%` }} />
      </div>
    </div>
  );
};

export default Progress;
