import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { getRandomColorFromString } from "@/utils/utilities";
import Hotjar from "@hotjar/browser";
import { useEffect } from "react";

export const useSetIdentity = () => {
  const { data: me } = useMeQuery();
  useEffect(() => {
    if (me == null) return;
    Hotjar.identify(me.id, {
      first_name: `${me.firstName?.[0] ?? ""}${me.lastName?.[0] ?? ""}`,
      color: getRandomColorFromString(`${me.firstName ?? ""} ${me.lastName ?? ""}`),
    });
  }, [me]);
};
