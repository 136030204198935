import { type IMessage } from "@/api/client";
import { useReaction } from "@/hooks/shared/useReaction";
import { type Emoji } from "@/types/emojiMartTypes";
import emojis from "@emoji-mart/data/sets/14/apple.json";
import Picker from "@emoji-mart/react";
import { Portal } from "react-portal";

export const EmojiPickerForReactionsComponent = ({
  message,
  position,
  isDark = false,
  closeEmojiPicker,
}: {
  message: IMessage;
  position: { top: number; left: number };
  isDark: boolean;
  closeEmojiPicker: () => void;
}) => {
  const PICKER_HEIGHT = 435;
  const PICKER_WIDTH = 352;
  const { selectEmoji } = useReaction();

  const adjustedTop = position.top + PICKER_HEIGHT > window.innerHeight ? window.innerHeight - PICKER_HEIGHT : position.top;
  const adjustedLeft = position.left + PICKER_WIDTH > window.innerWidth ? window.innerWidth - PICKER_WIDTH : position.left;

  const handleSelect = (emoji: Emoji) => {
    void selectEmoji(emoji, message);
    closeEmojiPicker();
  };

  return (
    <Portal>
      <div className="absolute left-0 top-0 z-[100]">
        <div
          className="absolute"
          style={{
            top: adjustedTop,
            left: adjustedLeft,
          }}
        >
          <Picker data={emojis} onEmojiSelect={handleSelect} theme={isDark ? "dark" : "light"} set="apple" maxFrequentRows={1} />
        </div>
        <div className="h-screen w-screen bg-white/0" onClick={closeEmojiPicker}></div>
      </div>
    </Portal>
  );
};
