import { type ITaskStatus } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface IRenameTaskStatusMutationParams {
  roomId?: string;
  taskStatusId: string;
  name: string;
}
export const useRenameTaskStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ roomId, taskStatusId, name }: IRenameTaskStatusMutationParams) => {
      if (roomId == null) return;

      await api.rooms.updateTaskStatus(roomId, taskStatusId, { name });
    },
    onMutate: async ({ roomId, taskStatusId, name }: IRenameTaskStatusMutationParams) => {
      queryClient.setQueryData<ITaskStatus[]>([QueryKeys.ROOMS, roomId, QueryKeys.TASKS, QueryKeys.TASKS_STATUSES], (old) => {
        if (old == null) return [];
        return old.map((taskStatus) => (taskStatus.id === taskStatusId ? { ...taskStatus, name } : taskStatus));
      });
    },
    onError: (_data, { roomId }) => {
      void queryClient.invalidateQueries({
        queryKey: [QueryKeys.ROOMS, roomId, QueryKeys.TASKS, QueryKeys.TASKS_STATUSES],
      });
    },
  });
};
