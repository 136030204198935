import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { SettingLineComponent } from "@/components/shared/settings/SettingLineComponent";
import { SliderComponent } from "@/components/shared/slider/SliderComponent";
import { DEFAULT_NOTIFICATION_SOUND_VOLUME } from "@/constants/magicNumbers";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useGeneralNotificationSettings } from "@/hooks/shared/notificationSettings/useGeneralNotificationSettings";
import { PreferencesContext, usePreferences } from "@/hooks/shared/usePreferences";
import { format } from "date-fns";
import "rc-slider/assets/index.css";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export const EditNotificationSettingsTab = (): JSX.Element => {
  const { setPreference } = usePreferences();
  const { preferences: preferencesContext } = useContext(PreferencesContext);
  const me = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const {
    isNotificationEnabled,
    isChannelMessagesEnabled,
    isMentionsEnabled,
    isThreadsEnabled,
    isReactionsEnabled,
    onToggleGeneralNotifications,
    onToggleChannelMessages,
    onToggleMentions,
    onToggleThreads,
    onToggleReactions,
  } = useGeneralNotificationSettings();

  const handleVolumeChange = (value: number | number[]) => {
    setPreference("notificationSoundVolume", value);
  };
  return (
    <>
      {me != null && preferencesContext != null && (
        <ModalTabComponent
          footer={
            <ModalFooterComponent
              info={t("header.editProfile.modal.tabs.profile.accountCreatedOn", { date: format(new Date(me.createdAt), t("date.shortFormat")) })}
            />
          }
        >
          <>
            <SettingLineComponent
              isEnabled={isNotificationEnabled}
              onToggle={onToggleGeneralNotifications}
              title={t("header.editProfile.modal.tabs.notification.enableNotifications")}
              subtitle={t("header.editProfile.modal.tabs.notification.enableNotificationsDescription")}
            />

            <div
              className={`my-3 border-t border-gray-200 pt-3 dark:border-dark-600 ${
                isNotificationEnabled ? "opacity-100" : "pointer-events-none opacity-50"
              }`}
            >
              <div className="w-full dark:text-dark-300 ">
                <div className="setting-element mb-3 hidden items-center border-b bg-green-500 pb-3">
                  <span className="flex items-center text-sm font-bold text-zinc-500 dark:text-dark-300">
                    {t("header.editProfile.modal.tabs.notification.notificationSoundVolume")}
                  </span>
                  <div className="ml-auto mr-4 w-20">
                    <SliderComponent
                      onChange={handleVolumeChange}
                      min={0}
                      max={1}
                      defaultValue={preferencesContext.notificationSoundVolume ?? DEFAULT_NOTIFICATION_SOUND_VOLUME}
                      step={0.1}
                    />
                  </div>
                  <span className="w-10 text-right">{(preferencesContext.notificationSoundVolume ?? DEFAULT_NOTIFICATION_SOUND_VOLUME) * 100}%</span>
                </div>
                <SettingLineComponent
                  className="mb-3"
                  title={t("header.editProfile.modal.tabs.notification.types.title")}
                  subtitle={t("header.editProfile.modal.tabs.notification.types.subtitle")}
                />
                <SettingLineComponent
                  className="mb-3"
                  isEnabled={isChannelMessagesEnabled}
                  onToggle={onToggleChannelMessages}
                  size="sm"
                  title={t("header.editProfile.modal.tabs.notification.types.channelMessages")}
                />
                <SettingLineComponent
                  className="mb-3"
                  isEnabled={isMentionsEnabled}
                  onToggle={onToggleMentions}
                  size="sm"
                  title={t("header.editProfile.modal.tabs.notification.types.mentions")}
                />
                <SettingLineComponent
                  className="mb-3"
                  isEnabled={isThreadsEnabled}
                  onToggle={onToggleThreads}
                  size="sm"
                  title={t("header.editProfile.modal.tabs.notification.types.threads")}
                />
                <SettingLineComponent
                  className="mb-3"
                  isEnabled={isReactionsEnabled}
                  onToggle={onToggleReactions}
                  size="sm"
                  title={t("header.editProfile.modal.tabs.notification.types.reactions")}
                />
              </div>
            </div>
          </>
        </ModalTabComponent>
      )}
    </>
  );
};
