import { type IEmail } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useGetEmail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: string) => {
      const dataInCache = queryClient.getQueryData<IEmail>([QueryKeys.EMAIL, params]);
      if (dataInCache != null) return dataInCache;
      const threadResponse = await api.emails.getOneEmail(params);
      return threadResponse.data.data;
    },
    onSuccess: (data) => {
      if (data.id == null) return;
      queryClient.setQueryData([QueryKeys.EMAIL, data.id], data);
    },
  });
};
