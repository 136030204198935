import { ToggleComponent } from "@/components/shared/form/toggle/ToggleComponent";
import { type HTMLAttributes } from "react";

interface ISettingLineComponentProps extends HTMLAttributes<HTMLDivElement> {
  isEnabled?: boolean;
  onToggle?: (isEnabled: boolean) => void;
  title: string;
  subtitle?: string;
  size?: "sm" | "md";
}

export const SettingLineComponent = ({
  size = "md",
  isEnabled = true,
  className,
  title,
  subtitle,
  onToggle,
}: ISettingLineComponentProps): JSX.Element => {
  return (
    <div className={`setting-element flex w-full items-center ${className ?? ""}`}>
      <div className={`mb-1 flex w-2/3 flex-col items-start text-sm text-zinc-500 dark:text-dark-300 ${isEnabled ? "" : "opacity-70"}`}>
        <span className="font-bold">{title}</span>
        <span className="text-xs">{subtitle != null && subtitle !== "" && <span>{subtitle}</span>}</span>
      </div>
      {onToggle != null && (
        <div className="ml-auto">
          <ToggleComponent isEnabled={isEnabled} onToggle={onToggle} size={size} />
        </div>
      )}
    </div>
  );
};
