import { useEffect, useMemo, useRef, useState } from "react";
import { Portal } from "react-portal";

interface IContextualMenuProps {
  links: IContextualMenuLink[];
  position: IContextualMenuPosition;
  onClose: () => void;
}
interface IContextualMenuPosition {
  x: number;
  y: number;
}

interface IContextualMenuLink {
  label?: string;
  action?: () => void;
  isDivider?: boolean;
  icon?: JSX.Element;
}

// use bottom position if there is not enough space

export const ContextualMenuComponent = ({ links, onClose, position }: IContextualMenuProps): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuHeight, setMenuHeight] = useState(0);

  useEffect(() => {
    setMenuHeight(menuRef.current?.clientHeight ?? 0);
  }, [menuRef]);

  const menuPosition = useMemo(() => {
    // know if there is enough space on the bottom
    const isEnoughSpaceOnBottom = window.innerHeight - (position.y + menuHeight) > 0;

    return {
      top: isEnoughSpaceOnBottom ? position.y : position.y - menuHeight,
      left: position.x,
    };
  }, [menuRef, position, menuHeight]);

  return (
    <>
      <Portal>
        <div className="absolute left-0 top-0 z-30 h-full w-full opacity-0" onMouseUp={onClose}></div>
        <div ref={menuRef} className="absolute z-40 rounded-md border bg-white dark:bg-dark-800" style={menuPosition}>
          {links.map((link, index) => {
            if (link.isDivider ?? false) {
              return <div key={index} className="my-4 border-b border-neutral-200 dark:border-dark-600" />;
            }
            return (
              <div key={index} className="my-4 flex items-center justify-start">
                <div className="mr-4">
                  <button onClick={link.action} className="flex items-center justify-center ">
                    {link.icon}
                    {link.label}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </Portal>
    </>
  );
};
