import GoogleLogo from "@/assets/images/logo-google.svg?react";
import { useSignUpForm } from "@/components/authentication/signUp/useSignUpForm";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { useTranslation } from "react-i18next";

export const SignUpFormComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    inputs,
    errors,
    isValid,
    isLoading,
    formRegister,
    handleSubmit,
    onSubmit,
    onError,
    invitation,
    invitationToken,
    signInWithGoogle,
    isGoogleLoginLoading,
  } = useSignUpForm();

  return (
    <>
      <ButtonComponent
        type="button"
        icon={<GoogleLogo />}
        status="secondary"
        className="mb-4 w-full"
        align="center"
        onClick={signInWithGoogle}
        loading={isGoogleLoginLoading}
      >
        {t("authentication.signUp.google")}
      </ButtonComponent>
      <div className="mb-2 flex flex-row items-center">
        <div className="h-[1px] flex-1 bg-zinc-500"></div>
        <span className="mx-4 text-sm font-normal text-zinc-500">{t("authentication.signIn.separator")}</span>
        <div className="h-[1px] flex-1 bg-zinc-500"></div>
      </div>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        {(invitation != null || invitationToken === undefined) &&
          inputs.map((input) => (
            <TextInputComponent
              key={`cti-${input.name}`}
              register={formRegister}
              error={errors[input.name as keyof typeof errors]?.message}
              {...input}
            />
          ))}
        <ButtonComponent
          type="submit"
          status="primary"
          className="w-full"
          align="center"
          loading={isLoading}
          disabled={!isValid}
          onClick={(e) => {
            !isValid && e.preventDefault();
          }}
        >
          {t("authentication.signUp.signUp")}
        </ButtonComponent>
        <div className="mt-3 flex flex-col items-center justify-center text-center text-xs text-zinc-300">{t("authentication.signUp.agreement")}</div>
      </form>
    </>
  );
};
