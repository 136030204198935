import { useEditMyChannelMutation } from "@/hooks/mutations/channel/useEditMyChannelMutation";
import { type Emoji } from "@/types/emojiMartTypes";
import { useCallback, useEffect, useRef, useState, type MouseEventHandler } from "react";

export const useConversationEmojiPicker = (channelId: string, canEdit: boolean) => {
  const PICKER_HEIGHT = 455;
  const PICKER_WIDTH = 352;
  const OFFSET_LEFT = 30;

  const [topPosition, setTopPosition] = useState(0);
  const [leftPosition, setLeftPosition] = useState(0);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const { mutate: updateChannel } = useEditMyChannelMutation();

  const handleEmojiButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    if (!canEdit) return;
    event.preventDefault();

    setIsEmojiPickerOpen(true);
  }, []);

  const handleEmojiSelect = useCallback((emoji: Emoji) => {
    updateChannel(
      { channelId, data: { emoji: emoji.shortcodes } },
      {
        onSettled: () => {
          setIsEmojiPickerOpen(false);
        },
      },
    );
  }, []);

  const handleRemoveEmoji = useCallback(() => {
    updateChannel(
      { channelId, data: { emoji: null } },
      {
        onSettled: () => {
          setIsEmojiPickerOpen(false);
        },
      },
    );
  }, []);

  const handleClosePicker = useCallback(() => {
    setIsEmojiPickerOpen(false);
  }, []);

  const containerRef = useRef<HTMLButtonElement>(null);

  const updatePickerPosition = useCallback(() => {
    if (containerRef.current == null) return;

    const buttonRect = containerRef.current.getBoundingClientRect();
    const newTopPosition = buttonRect.top + PICKER_HEIGHT > window.innerHeight ? window.innerHeight - PICKER_HEIGHT : buttonRect.top;
    const newLeftPosition = (buttonRect.left + PICKER_WIDTH > window.innerWidth ? window.innerWidth - PICKER_WIDTH : buttonRect.left) + OFFSET_LEFT;

    setTopPosition(newTopPosition);
    setLeftPosition(newLeftPosition);
  }, [containerRef.current]);

  useEffect(() => {
    updatePickerPosition();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updatePickerPosition);
    return () => {
      window.removeEventListener("resize", updatePickerPosition);
    };
  }, [updatePickerPosition, containerRef.current]);
  return {
    isEmojiPickerOpen,
    setIsEmojiPickerOpen,
    handleEmojiButtonClick,
    handleEmojiSelect,
    handleRemoveEmoji,
    handleClosePicker,
    containerRef,
    topPosition,
    leftPosition,
  };
};
