import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const usePortalUrlMutation = () => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async () => {
      const response = await api.pages.getPortalUrl({
        defaultToastErrorMessage: t("billing.errors.portalUrl"),
      });
      return response.data.data;
    },
  });
};
