import { FileIconComponent } from "@/components/shared/files/FileIconComponent";
import { type FunctionComponent } from "react";

interface IFileExtensionItemComponentProps {
  extension: string;
  onClick?: () => void;
}

export const FileExtensionItemComponent: FunctionComponent<IFileExtensionItemComponentProps> = ({ extension, onClick }) => {
  return (
    <div className="flex h-8 flex-row items-center px-2 py-1" onClick={onClick}>
      <FileIconComponent extension={extension} size="sm" />
      <div className="ml-1 truncate text-xs font-normal text-sky-600">.{extension}</div>
    </div>
  );
};
