import { endOfYesterday, format, isBefore } from "date-fns";
import { useMemo, useRef } from "react";
import { type ReactDatePicker } from "react-datepicker";
import { useTranslation } from "react-i18next";

export const useTaskDeadline = (onDeadlineChange: (deadline: string | null) => void, deadline: string | null) => {
  const { t } = useTranslation();
  const date = useMemo(() => (deadline != null ? new Date(deadline) : new Date()), [deadline]);

  const isInPast = useMemo(() => isBefore(date, endOfYesterday()), [date]);
  const formattedDeadline = useMemo(() => format(date, t("date.shortFormat")), [date]);
  const ref = useRef<ReactDatePicker>(null);

  const onOpenCalendarPicker = () => {
    ref.current?.setOpen(true);
  };

  const onCloseCalendarPicker = () => {
    ref.current?.setOpen(false);
  };

  const onDateChange = (date: Date | null) => {
    onCloseCalendarPicker();
    onDeadlineChange(date?.toISOString() ?? null);
  };

  return { isInPast, date, formattedDeadline, onOpenCalendarPicker, onCloseCalendarPicker, ref, onDateChange };
};
