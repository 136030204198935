import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { BulkInviteContext } from "@/contexts/BulkInviteContextProvider";
import { useBatchOrganisationInviteMutation } from "@/hooks/mutations/batchInvite/useBatchOrganisationInviteMutation";
import { useOrganisationActiveMemberQuery } from "@/hooks/queries/organisations/useOrganisationActiveMemberQuery";
import { useOrganisationInvitesQuery } from "@/hooks/queries/organisations/useOrganisationInvitesQuery";
import { useSearchInput } from "@/hooks/shared/useSearchInput";
import { filterMembersBySearch } from "@/utils/utilities";
import { useCallback, useContext, useMemo } from "react";

export const useAdminOrganisationRoomMemberTab = () => {
  const { search, updateSearch } = useSearchInput();
  const me = useContext(AuthenticationContext);
  const { setMails } = useContext(BulkInviteContext);
  const { data: members } = useOrganisationActiveMemberQuery(me?.mainOrganisation?.organisationId);
  const { data: invites } = useOrganisationInvitesQuery(me?.mainOrganisation?.organisationId);

  const mailsAlreadyInvited = useMemo(() => {
    const memberEmails = members?.map((member) => member.email.toLowerCase()) ?? [];
    const invitationEmails = invites?.invitations?.map((invitation) => invitation.email.toLowerCase()) ?? [];
    return [...memberEmails, ...invitationEmails];
  }, [members, invites]);

  const { mutate: inviteInBulk } = useBatchOrganisationInviteMutation(me?.mainOrganisation?.organisationId ?? "");

  const handleBatchInvite = useCallback(async (emails: string[]) => {
    inviteInBulk({ emails });
    setMails([]);
  }, []);

  const invitations = useMemo(() => {
    return (invites?.invitations ?? []).filter((invitation) => invitation.email.toLowerCase().includes(search.toLowerCase()));
  }, [invites, search]);

  const filteredMembers = useMemo(() => {
    return filterMembersBySearch(members, search);
  }, [members, search]);

  return {
    search,
    updateSearch,
    handleBatchInvite,
    invitations,
    filteredMembers,
    mailsAlreadyInvited,
  };
};
