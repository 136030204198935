export const loadGtagScript = () => {
  const headElement = document.getElementsByTagName("head")[0];
  const scriptElement = document.createElement("script");
  scriptElement.async = true;
  scriptElement.src = "https://www.googletagmanager.com/gtag/js?id=AW-11340497619";
  const gtmId: string = import.meta.env.VITE_APP_GTM_ID;
  const inlineScriptElement = document.createElement("script");
  inlineScriptElement.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag("js", new Date());
    gtag("config", "${gtmId}");
  `;

  headElement.appendChild(scriptElement);
  headElement.appendChild(inlineScriptElement);
};
