import { ConfirmModalComponent } from "@/components/shared/modal/ConfirmModalComponent";
import { ModalNames } from "@/constants/modalNames";
import { useDeleteTagMutation } from "@/hooks/mutations/tasks/tags/useDeleteTagMutation";
import { useCallback, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ITagDeletionConfirmProps {
  tagId: string;
  roomId: string;
  taskId: string;
}
export const TagDeletionConfirm: FunctionComponent<ITagDeletionConfirmProps> = ({ tagId, roomId, taskId }) => {
  const { t } = useTranslation();
  const { mutate: deleteTag } = useDeleteTagMutation(tagId, roomId, taskId);

  const action = useCallback(async () => {
    deleteTag();
  }, []);

  return (
    <ConfirmModalComponent
      name={ModalNames.CONFIRM_TAG_DELETION}
      title={t("editTaskModal.editTagModal.deleteTagModal.title")}
      description={t("editTaskModal.editTagModal.deleteTagModal.description")}
      action={action}
      actionType="danger"
      actionLabel={t("editTaskModal.editTagModal.deleteTag.button")}
      cancelLabel={t("general.cancel")}
    />
  );
};
