import { type IRoomIdName } from "@/api/client";
import { RoomItemComponent } from "@/components/shared/room/RoomItemComponent";
import { type FunctionComponent } from "react";

interface IChannelItemComponentProps {
  name: string;
  room: IRoomIdName | null;
  onClick?: () => void;
}

export const ChannelItemComponent: FunctionComponent<IChannelItemComponentProps> = ({ name, room, onClick }) => {
  return (
    <div className="flex h-8 flex-row items-center px-2 py-1" onClick={onClick}>
      <div className="ml-1 truncate text-xs font-normal text-sky-600">
        {"# "}
        {name}
      </div>
      <div className="ml-2 text-xs text-zinc-500">{room != null ? "·" : ""}</div>
      {room != null ? <RoomItemComponent room={room} /> : null}
    </div>
  );
};
