import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { useUserQuery } from "@/hooks/queries/shared/user/useUserQuery";
import { type FunctionComponent } from "react";

interface TaskAssigneeComponentProps {
  userId: string;
}

export const TaskAssigneeComponent: FunctionComponent<TaskAssigneeComponentProps> = ({ userId }) => {
  const { data: user, isLoading } = useUserQuery(userId);

  if (isLoading || user == null) {
    return null;
  }

  return (
    <div className="flex items-center">
      <UserAvatarComponent userId={userId} size="md" className="h-5 w-5" />
      <div className="ml-1 truncate text-xs font-normal text-zinc-500">
        {user?.firstName} {user?.lastName}
      </div>
    </div>
  );
};
