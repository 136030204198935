import { type INotification, type INotificationAllBody, type INotificationAllResponse } from "@/api/client";
import { notificationType } from "@/constants/notification";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useNotificationsQuery = (type: "READ" | "UNREAD") => {
  const STALE_TIME = 1000 * 60 * 15;
  return useInfiniteQuery({
    queryKey: [QueryKeys.ME, QueryKeys.NOTIFICATIONS, type],
    queryFn: async ({ pageParam = "" }) => {
      const body: INotificationAllBody = {
        previousCount: 20,
        isRead: type === "READ",
      };
      if (pageParam !== "") {
        body.cursor = pageParam;
      }

      const { data } = await api.my.getNotifications(body);

      const ALLOWED_NOTIFICATION_TYPES = [
        notificationType.THREAD_REPLY,
        notificationType.MENTION,
        notificationType.DIRECT_MESSAGE,
        notificationType.REACTION,
        notificationType.CHANNEL_MESSAGE,
        notificationType.TASK_ASSIGNED,
        notificationType.TASK_UNASSIGNED,
        notificationType.TASK_STATUS_UPDATED,
        notificationType.TASK_COMMENTED,
        notificationType.TASK_DEADLINE_TOMORROW,
        notificationType.NEW_EMAIL,
      ];

      const filterAllowedNotifications = (notifications: INotification[]) => {
        return notifications.filter((notification) => {
          if (notification.type === notificationType.REACTION && notification.reaction === null) return false;
          return ALLOWED_NOTIFICATION_TYPES.includes(notification.type as notificationType);
        });
      };

      return {
        data: filterAllowedNotifications(data.data),
        pagination: data.pagination,
      };
    },
    getPreviousPageParam: (firstPage: INotificationAllResponse) => {
      return firstPage?.pagination.previousCursor ?? undefined;
    },
    getNextPageParam: (page) => {
      return page?.pagination.nextCursor ?? undefined;
    },
    initialPageParam: "",
    staleTime: STALE_TIME,
  });
};
