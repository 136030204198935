import { type IQuotaData } from "@/api/client";
import { DowngradeModalComponent } from "@/components/billing/modals/downgrade/DowngradeModalComponent";
import { ConfirmModalComponent } from "@/components/shared/modal/ConfirmModalComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { ModalNames } from "@/constants/modalNames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const DowngradeConfirm = ({ quotas }: { quotas: IQuotaData[] }) => {
  const { t } = useTranslation();

  const action = useMemo(
    () => async () => {
      openModal(ModalNames.DOWNGRADE, <DowngradeModalComponent quotas={quotas} />);
    },
    [],
  );

  return (
    <ConfirmModalComponent
      name={ModalNames.CONFIRM_DOWNGRADE}
      title={t(`billing.modals.downgrade.confirm.title`)}
      description={t(`billing.modals.downgrade.confirm.description`)}
      action={action}
      actionType="danger"
      actionLabel={t(`billing.modals.downgrade.confirm.action`)}
      cancelLabel={t("general.cancel")}
    />
  );
};
