import { handleIncomingError } from "@/services/ErrorService";
import { api } from "@/services/HttpService";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

let currentInterceptor: number | null = null;

export const useErrorInterceptor = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (currentInterceptor !== null) {
      api.instance.interceptors.response.eject(currentInterceptor);
    }

    currentInterceptor = api.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        return await handleIncomingError(error, t);
      },
    );

    return () => {
      if (currentInterceptor !== null) {
        api.instance.interceptors.response.eject(currentInterceptor);
      }
    };
  }, [t, i18n]);
};
