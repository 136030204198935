import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useConversationQuery = (conversationId?: string) => {
  return useQuery({
    queryKey: [QueryKeys.CONVERSATIONS, conversationId],
    queryFn: async () => {
      if (conversationId == null) return;
      const response = await api.conversations.getOne(conversationId);
      return response.data;
    },
    enabled: conversationId != null,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};
