import { type IRoomIdName } from "@/api/client";
import { useRoomQuery } from "@/hooks/queries/rooms/useRoomQuery";
import { useState, type MouseEventHandler } from "react";

export const useRoomPicker = (room: IRoomIdName | null, onRoomChange?: (room: IRoomIdName | null) => void) => {
  const { data: roomData } = useRoomQuery(room?.id);
  const [isRoomTooltipVisible, setIsRoomTooltipVisible] = useState<boolean>(false);

  const name = roomData?.room.name ?? room?.name ?? "";

  const onRoomClick: MouseEventHandler<HTMLDivElement> | undefined =
    onRoomChange != null
      ? (event) => {
          event.stopPropagation();
          event.preventDefault();
          setIsRoomTooltipVisible((isVisible) => !isVisible);
        }
      : undefined;

  const onCloseTooltip = () => {
    setIsRoomTooltipVisible(false);
  };

  const onRoomSelected = (selectedRoom: IRoomIdName | null) => {
    if (onRoomChange == null) {
      return;
    }
    if (selectedRoom == null) {
      onRoomChange(null);
    } else if (selectedRoom.id !== room?.id) {
      onRoomChange(selectedRoom);
    }
    onCloseTooltip();
  };

  return { name, isRoomTooltipVisible, onRoomClick, onCloseTooltip, onRoomSelected };
};
