import { useState, type MouseEventHandler } from "react";

export const useFileExtensionPicker = (fileExtension: string | null, onFileExtensionChange?: (fileExtension: string | null) => void) => {
  const [isFileExtensionTooltipVisible, setIsFileExtensionTooltipVisible] = useState<boolean>(false);

  const onFileExtensionClick: MouseEventHandler<HTMLDivElement> | undefined =
    onFileExtensionChange != null
      ? (event) => {
          event.stopPropagation();
          event.preventDefault();
          setIsFileExtensionTooltipVisible((isVisible) => !isVisible);
        }
      : undefined;

  const onCloseTooltip = () => {
    setIsFileExtensionTooltipVisible(false);
  };

  const onFileExtensionSelected = (selectedFileExtension: string | null) => {
    if (onFileExtensionChange == null) {
      return;
    }
    if (selectedFileExtension == null) {
      onFileExtensionChange(null);
    } else if (selectedFileExtension !== fileExtension) {
      onFileExtensionChange(selectedFileExtension);
    }
    onCloseTooltip();
  };

  return { isFileExtensionTooltipVisible, onFileExtensionClick, onCloseTooltip, onFileExtensionSelected };
};
