interface IMessageHighlightComponentProps {
  highlights: string[];
}

export const MessageHighlightComponent = ({ highlights }: IMessageHighlightComponentProps) => {
  return (
    <div className="inline">
      {highlights.map((highlight, index, all) => {
        const parts = highlight
          .replace(/<em>/g, "<em><highlight>")
          .replace(/<\/em>/g, "</highlight></em>")
          .split(/<em>(.*?)<\/em>/g)
          .filter((part) => part !== "");
        return (
          <span key={index} className="text-[13px]">
            {parts.map((part, secondIndex) => {
              if (part.match(/<highlight>(.*?)<\/highlight>/g) != null) {
                const content = part.replace(/<\/?highlight>/g, "");
                return (
                  <span key={secondIndex} className="bg-blue-200">
                    {content}
                  </span>
                );
              }
              return part;
            })}
            <span key={index} className="text-gray-500">
              {index !== all.length - 1 ? " ... " : ""}
            </span>
          </span>
        );
      })}
    </div>
  );
};
