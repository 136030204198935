import { type INotification, type INotificationAllResponse } from "@/api/client";
import { notificationReadStatus } from "@/constants/notification";
import { QueryKeys } from "@/constants/queryKeys";
import { useQueryClient, type InfiniteData } from "@tanstack/react-query";

export const useEditNotificationsQueries = () => {
  const queryClient = useQueryClient();

  const removeNotificationsFromQuery = (readNotifications: INotification[], isReadStatus: notificationReadStatus) => {
    queryClient.setQueriesData<InfiniteData<INotificationAllResponse>>(
      {
        queryKey: [QueryKeys.ME, QueryKeys.NOTIFICATIONS, isReadStatus],
      },

      (oldNotifications) => {
        if (oldNotifications == null) return oldNotifications;
        const newPages = oldNotifications.pages.map((page: INotificationAllResponse) => {
          return {
            ...page,
            data: page.data
              .filter((notification) => {
                return (
                  readNotifications.findIndex((readNotification) => {
                    return readNotification.id === notification.id;
                  }) === -1
                );
              })
              .map((notification) => {
                return {
                  ...notification,
                  newNotificationCount: readNotifications[0].newNotificationCount,
                };
              }),
          };
        });
        return { ...oldNotifications, pages: newPages };
      },
    );
  };

  const addNotificationsToQuery = (readNotifications: INotification[], isReadStatus: notificationReadStatus) => {
    queryClient.setQueriesData<InfiniteData<INotificationAllResponse>>(
      { queryKey: [QueryKeys.ME, QueryKeys.NOTIFICATIONS, isReadStatus] },
      (oldNotifications) => {
        if (oldNotifications == null) return oldNotifications;

        const newPages = oldNotifications.pages.map((page: INotificationAllResponse) => {
          const existingNotificationsIds = page.data.map((notification) => notification.id);
          const newNotifications = readNotifications.filter((readNotification) => !existingNotificationsIds.includes(readNotification.id));

          return {
            ...page,
            data: [...page.data, ...newNotifications].map((notification) => {
              return {
                ...notification,
                newNotificationCount: readNotifications[0].newNotificationCount,
              };
            }),
          };
        });

        return { ...oldNotifications, pages: newPages };
      },
    );
  };

  const updateNotificationInQuery = (notification: INotification) => {
    queryClient.setQueriesData<InfiniteData<INotificationAllResponse>>(
      { queryKey: [QueryKeys.ME, QueryKeys.NOTIFICATIONS, notification.isRead ? notificationReadStatus.READ : notificationReadStatus.UNREAD] },
      (oldNotifications) => {
        if (oldNotifications == null) return oldNotifications;
        const newPages = oldNotifications.pages.map((page: INotificationAllResponse) => {
          return {
            ...page,
            data: page.data.map((oldNotification) => {
              if (oldNotification.id === notification.id) {
                return notification;
              }
              return oldNotification;
            }),
          };
        });
        return { ...oldNotifications, pages: newPages };
      },
    );
  };

  return {
    removeNotificationsFromQuery,
    addNotificationsToQuery,
    updateNotificationInQuery,
  };
};
