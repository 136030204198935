import { type ITaskStatus } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useTaskStatusesQuery = (roomId?: string | null) => {
  return useQuery<ITaskStatus[]>({
    queryKey: [QueryKeys.ROOMS, roomId, QueryKeys.TASKS, QueryKeys.TASKS_STATUSES],
    queryFn: async () => {
      if (roomId == null) return [];
      const response = await api.rooms.findManyTaskStatuses(roomId);

      return response.data.data;
    },
  });
};
