import { createContext, useMemo, useState, type Dispatch, type FunctionComponent, type PropsWithChildren, type SetStateAction } from "react";

interface IEmailConnectionContextData {
  setIsConnected: Dispatch<SetStateAction<boolean>>;
  isConnected: boolean;
}

interface IEmailConnectionContextProviderProps extends PropsWithChildren {
  threadId?: string;
}

export const EmailConnectionContext = createContext<IEmailConnectionContextData>({
  setIsConnected: () => {},
  isConnected: false,
});

export const EmailConnectionContextProvider: FunctionComponent<IEmailConnectionContextProviderProps> = ({ children }) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      setIsConnected,
      isConnected,
    }),
    [isConnected],
  );

  return <EmailConnectionContext.Provider value={contextValue}>{children}</EmailConnectionContext.Provider>;
};
