import { type IProfile } from "@/api/client";
import { useUsersQuery } from "@/hooks/queries/shared/user/useUserQuery";
import { addCommasSeparator } from "@/utils/utilities";
import { useMemo } from "react";

export const useUsersSystemMessage = (userIds: string[]) => {
  const results = useUsersQuery(userIds);

  const isLoading = useMemo(() => results.some((result) => result.isLoading), [results]);

  const commaSeparatedUsers = useMemo(() => {
    const users = results.map((result) => result.data ?? null).filter((user): user is IProfile => user != null);

    return addCommasSeparator(users.map((user) => `${user.firstName ?? ""} ${user.lastName ?? ""}`));
  }, [results]);

  return { isLoading, commaSeparatedUsers };
};
