import { type IAuthRegisterLogin, type IUser } from "@/api/client";

export type SimpleUser = Pick<IUser, "id" | "firstName" | "lastName" | "email">;

export interface UserWithId {
  type: "userId";
  id: IUser["id"];
}
export interface UserWithEmail {
  type: "email";
  email: IUser["email"];
}

export type UserProviderType = NonNullable<IAuthRegisterLogin["provider"]>;

export const EMAIL_PROVIDER: UserProviderType = "EMAIL";
export const GOOGLE_PROVIDER: UserProviderType = "GOOGLE";
export const APPLE_PROVIDER: UserProviderType = "APPLE";
