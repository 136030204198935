import { ProfilePopinComponent } from "@/components/shared/user/ProfilePopinComponent";
import { useMention } from "@/hooks/shared/useMention";
import { Portal } from "react-portal";

interface IMentionPopinContainerProps {
  roomId: string;
}

export const MentionPopinContainerComponent = ({ roomId }: IMentionPopinContainerProps): JSX.Element => {
  const { isMentionHovered, delayedSetIsMentionHovered, userOfMentionHovered, popinPosition } = useMention(roomId);
  return (
    <>
      {isMentionHovered && userOfMentionHovered != null && (
        <Portal>
          <ProfilePopinComponent
            className="absolute z-50 -translate-y-full transition-all duration-300 ease-in-out"
            onMouseLeave={() => {
              delayedSetIsMentionHovered(false);
            }}
            onMouseEnter={() => {
              delayedSetIsMentionHovered(true);
            }}
            style={{
              top: popinPosition.top,
              left: popinPosition.left,
            }}
            roomId={roomId}
            userId={userOfMentionHovered.userId}
          />
        </Portal>
      )}
    </>
  );
};
