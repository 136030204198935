import { PinBoardDrawer } from "@/components/homePage/drawers/pinBoard/PinBoardDrawer";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { useContext, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BsPinAngleFill as PinFillIcon, BsPinAngle as PinIcon } from "react-icons/bs";

export const PinBoardToggleComponent: FunctionComponent = () => {
  const { t } = useTranslation();

  const { isOpened, openedDrawers, openDrawer, closeDrawer, closeAllDrawers } = useContext(DrawerContext);

  const isPinBoardOpened = openedDrawers.find((drawer) => drawer.drawerType === DrawerType.PIN_BOARD) != null;

  const openPinBoardDrawer = () => {
    closeAllDrawers();
    openDrawer({
      drawerType: DrawerType.PIN_BOARD,
      component: <PinBoardDrawer />,
    });
  };

  const openNotificationsCenter = () => {
    if (isPinBoardOpened) {
      if (openedDrawers[openedDrawers.length - 1].drawerType === DrawerType.PIN_BOARD && isOpened) {
        closeDrawer(DrawerType.PIN_BOARD);
      } else {
        openPinBoardDrawer();
      }
    } else {
      openPinBoardDrawer();
    }
  };

  return (
    <TooltipComponent content={t("pinboard.toggle.tooltip")} placement="bottom">
      <button
        className="relative mr-4 flex h-6 w-7 cursor-pointer items-center justify-center opacity-100 transition-all hover:opacity-80"
        onClick={openNotificationsCenter}
      >
        <div className="mb-0 text-white">{isOpened && isPinBoardOpened ? <PinFillIcon className="h-6 w-6" /> : <PinIcon className="h-6 w-6" />}</div>
      </button>
    </TooltipComponent>
  );
};
