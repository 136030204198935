import { type IFile } from "@/api/client";
import { ImagePreviewComponent } from "@/components/roomPage/tabs/conversation/message/ImagePreviewComponent";
import { useDocumentViewer } from "@/components/shared/documentViewer/useDocumentViewer";
import { useMemo } from "react";
interface IFIleInMessageComponentProps {
  file: IFile;
  isInForward?: boolean;
  isOnlyImageInMessage: boolean;
}

export const ONLY_IMAGE_IN_MESSAGE_WIDTH = 375;
export const ONLY_IMAGE_IN_MESSAGE_HEIGHT = 250;
export const MULTIPLE_IMAGES_IN_MESSAGE_WIDTH = 225;
export const MULTIPLE_IMAGES_IN_MESSAGE_HEIGHT = 150;

export const ImageInMessageComponent = ({ file, isInForward = false, isOnlyImageInMessage }: IFIleInMessageComponentProps) => {
  const { isVisible: isImageViewerVisible, setIsVisible } = useDocumentViewer();

  const PREVIEW_WIDTH = isInForward ? 47 : 330;
  const PREVIEW_HEIGHT = isInForward ? 47 : 220;

  const isImageSizeSmallerThanPreview = file.image?.width != null && file.image.width < PREVIEW_WIDTH && file.image.height < PREVIEW_HEIGHT;

  const imageWidth = useMemo(() => {
    if (isImageSizeSmallerThanPreview) return "auto";
    return isOnlyImageInMessage ? `${ONLY_IMAGE_IN_MESSAGE_WIDTH}px` : `${MULTIPLE_IMAGES_IN_MESSAGE_WIDTH}px`;
  }, [isOnlyImageInMessage, isImageSizeSmallerThanPreview]);

  const imageHeight = useMemo(() => {
    if (isImageSizeSmallerThanPreview) return "auto";
    return isOnlyImageInMessage ? `${ONLY_IMAGE_IN_MESSAGE_HEIGHT}px` : `${MULTIPLE_IMAGES_IN_MESSAGE_HEIGHT}px`;
  }, [isOnlyImageInMessage, isImageSizeSmallerThanPreview]);

  return (
    <>
      {isImageViewerVisible ? <ImagePreviewComponent file={file} /> : null}

      <button
        role="button"
        className={`mb-1 overflow-hidden rounded-md border ${
          !isImageSizeSmallerThanPreview ? "bg-blur relative bg-cover bg-center  bg-no-repeat pb-2" : ""
        }`}
        style={{
          backgroundImage: `url(${file.url})`,
          width: imageWidth,
          height: imageHeight,
        }}
        onClick={() => {
          setIsVisible(true);
        }}
      >
        <img
          key={file.id}
          className={`${!isImageSizeSmallerThanPreview ? "absolute inset-0 h-full w-full object-contain" : "object-none"}`}
          src={file.url}
          alt={file.id}
        />
      </button>
    </>
  );
};
