import { FileExtensionItemComponent } from "@/components/shared/files/FileExtensionItemComponent";
import { useFileExtensionPickerPopinContent } from "@/components/shared/files/FileExtensionPickerPopinComponent/useFileExtensionPickerPopinContent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface IFileExtensionPickerPopinContentComponentProps {
  onFileExtensionSelected: (fileExtension: string | null) => void;
  hasRemoveFileExtensionOption?: boolean;
  isVisible: boolean;
  fileExtensions: string[];
}

export const FileExtensionPickerPopinContentComponent: FunctionComponent<IFileExtensionPickerPopinContentComponentProps> = ({
  onFileExtensionSelected,
  hasRemoveFileExtensionOption = false,
  isVisible,
  fileExtensions,
}) => {
  const { t } = useTranslation();
  const { search, onSearchChange, ref, extensions } = useFileExtensionPickerPopinContent(isVisible, fileExtensions);

  return (
    <div className="w-40 cursor-pointer" data-no-dnd>
      <div className="w-full p-2">
        <TextInputComponent
          ref={ref}
          value={search}
          onChange={(e) => {
            onSearchChange(e.target.value);
          }}
          placeholder={t("fileExtension.picker.search.placeholder")}
        />
      </div>
      <div className="max-h-32 w-full overflow-scroll">
        {hasRemoveFileExtensionOption ? (
          <div
            className="flex flex-row items-center px-2 py-1"
            onClick={() => {
              onFileExtensionSelected(null);
            }}
          >
            <div className="ml-1 text-xs font-normal text-zinc-400">{t("fileExtension.picker.remove")}</div>
          </div>
        ) : null}
        {extensions.map((extension) => (
          <FileExtensionItemComponent
            key={extension}
            extension={extension}
            onClick={() => {
              onFileExtensionSelected(extension);
            }}
          />
        ))}
        {extensions.length === 0 ? (
          <div className="px-2 py-1 text-xs font-normal text-zinc-400">{t("fileExtension.picker.search.noResult")}</div>
        ) : null}
      </div>
    </div>
  );
};
