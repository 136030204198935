import { type IConversationMessagesAllSearchResponse, type IMessage } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { useQueryClient, type InfiniteData } from "@tanstack/react-query";

export const useEditMessageQueries = () => {
  const queryClient = useQueryClient();

  const addMessageToQuery = (queryKeys: string[], message: IMessage) => {
    // Add the new message to the query
    queryClient.setQueriesData<InfiniteData<IConversationMessagesAllSearchResponse>>({ queryKey: queryKeys }, (oldMessages) => {
      if (oldMessages == null) return oldMessages;
      const newPages = oldMessages.pages.map((page: IConversationMessagesAllSearchResponse, index: number) => {
        if (index === oldMessages.pages.length - 1 && page.pagination.nextCursor == null) {
          return { ...page, data: [...page.data, message] };
        }
        return page;
      });
      return { ...oldMessages, pages: newPages };
    });
  };

  const replaceMessageIdInQuery = (queryKeys: string[], message: IMessage, optimisticId: string) => {
    queryClient.setQueriesData<InfiniteData<IConversationMessagesAllSearchResponse>>({ queryKey: queryKeys }, (oldMessages) => {
      if (oldMessages == null) return oldMessages;
      const newPages = oldMessages.pages.map((page: IConversationMessagesAllSearchResponse) => {
        const newPage = { ...page };
        newPage.data = page.data.map((messageItem: IMessage) => {
          if (messageItem.id === optimisticId) {
            return {
              ...messageItem,
              id: message.id,
            };
          }
          return messageItem;
        });
        return newPage;
      });
      return { ...oldMessages, pages: newPages };
    });
  };

  const replaceMessageInQuery = (queryKeys: string[], message: Partial<IMessage>) => {
    queryClient.setQueriesData<InfiniteData<IConversationMessagesAllSearchResponse>>({ queryKey: queryKeys }, (oldMessages) => {
      if (oldMessages == null) return oldMessages;
      const newPages = oldMessages.pages.map((page: IConversationMessagesAllSearchResponse) => {
        const newPage = { ...page };
        newPage.data = page.data.map((messageItem: IMessage) => {
          if (messageItem.id === message.id) {
            return {
              ...messageItem,
              ...message,
            };
          }
          return messageItem;
        });
        return newPage;
      });
      return { ...oldMessages, pages: newPages };
    });
  };

  const editMessageInQuery = (conversationId: string, message: Partial<IMessage>): void => {
    if (message?.id == null) return;
    if (message.nesting === "child") {
      replaceMessageInQuery([QueryKeys.MESSAGE, message.parent?.id ?? "", QueryKeys.MESSAGES], message);
      return;
    }
    const queryKey = ["conversation", conversationId, "messages"];
    replaceMessageInQuery(queryKey, message);
    replaceMessageInQuery([QueryKeys.MESSAGE, message.id, QueryKeys.MESSAGES], message);
  };

  const deleteMessageInQuery = (conversationId: string, message: IMessage): void => {
    const queryKeys =
      message.nesting === "child" ? ["message", message.parent?.id, QueryKeys.MESSAGES] : ["conversation", conversationId, QueryKeys.MESSAGES];
    queryClient.setQueriesData<InfiniteData<IConversationMessagesAllSearchResponse>>({ queryKey: queryKeys }, (oldMessages) => {
      if (oldMessages == null) return oldMessages;
      const newPages = oldMessages.pages.map((page: IConversationMessagesAllSearchResponse) => {
        return {
          ...page,
          data: page.data.map((messageItem: IMessage) => {
            if (messageItem.id === message.id) {
              return {
                ...messageItem,
                deletedAt: new Date().toISOString(),
              };
            }
            return messageItem;
          }),
        };
      });
      return { ...oldMessages, pages: newPages };
    });
  };

  return {
    addMessageToQuery,
    editMessageInQuery,
    deleteMessageInQuery,
    replaceMessageIdInQuery,
  };
};
