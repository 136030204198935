import { type IPin } from "@/api/client";
import { PinComponent } from "@/components/homePage/drawers/pinBoard/PinComponent";
import { RoundedContainerComponent } from "@/components/shared/layout/RoundedContainerComponent";
import { usePinsQuery } from "@/hooks/queries/pins/usePinsQuery";
import { useCallback, useMemo } from "react";
import { Trans } from "react-i18next";
import { BsPinAngle as PinIcon } from "react-icons/bs";
import { Virtuoso } from "react-virtuoso";

export const PinBoardDrawer = (): JSX.Element => {
  const { data: pinsData, fetchPreviousPage } = usePinsQuery();

  const sortPinsByCreatedAtDate = useCallback((a: IPin, b: IPin) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }, []);

  const pins = useMemo(() => {
    if (pinsData == null) return [];
    const pinsWithDeletedMessage = pinsData.pages.flatMap((page) => page.data).sort(sortPinsByCreatedAtDate);
    return pinsWithDeletedMessage.filter((pin) => {
      if (pin.message != null) {
        return pin;
      }
      return false;
    });
  }, [pinsData]);

  const tryToFetchPreviousPage = useCallback(async () => {
    await fetchPreviousPage();
  }, [pinsData]);

  return (
    <RoundedContainerComponent className="bg-dark-25 dark:bg-dark-800" hasPadding={false}>
      <section className="h-full overflow-y-auto">
        {pins != null && pins.length > 0 ? (
          <Virtuoso
            data={pins}
            itemContent={(index: number, pin: IPin) => <PinComponent key={pin.id} pin={pin} />}
            endReached={tryToFetchPreviousPage}
          />
        ) : (
          <div className="flex h-full w-full items-center justify-center text-center text-sm text-zinc-500">
            <div className="px-10">
              <Trans i18nKey="pinboard.empty" components={{ icon: <PinIcon className="m-1 inline h-6 w-6 text-zinc-500 dark:text-dark-300" /> }} />
            </div>
          </div>
        )}
      </section>
    </RoundedContainerComponent>
  );
};
