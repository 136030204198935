import { RoleType } from "@/api/client";
import { SelectPlanModalComponent } from "@/components/billing/modals/selectPlanModal/SelectPlanModalComponent";
import { ConfirmModalComponent } from "@/components/shared/modal/ConfirmModalComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { ModalNames } from "@/constants/modalNames";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { QuotaType } from "@/interfaces/billing";
import { useContext, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

interface ILimitationConfirmProps {
  limit: number;
  scope: QuotaType;
}

export const LimitationConfirm = ({ limit, scope }: ILimitationConfirmProps) => {
  const { t } = useTranslation();
  const me = useContext(AuthenticationContext);
  const myRoleType = useMemo(() => {
    const roleType = me?.mainOrganisation?.role?.type;
    if (roleType == null) return RoleType.Guest;
    return roleType === RoleType.Admin ? RoleType.User : roleType;
  }, [me]);
  const amIOwner = useMemo(() => myRoleType === RoleType.Owner, [myRoleType]);

  const organisationOwner = useMemo(() => {
    if (me?.mainOrganisation?.organisationOwner == null) return "";
    const firstName = me.mainOrganisation.organisationOwner.firstName ?? "";
    const lastName = me.mainOrganisation.organisationOwner.lastName ?? "";
    return `${firstName} ${lastName}`;
  }, [me]);

  const action = useMemo(() => {
    if (amIOwner)
      return async () => {
        openModal(ModalNames.PLANS, <SelectPlanModalComponent />);
      };
    return null;
  }, []);

  const modalTitle = useMemo(() => {
    if (myRoleType == null) return "";
    if (scope === QuotaType.STORAGE) return t(`billing.modals.limitations.storage.title.${myRoleType}`);
    return t(`billing.modals.limitations.${scope}.title.${amIOwner ? "owner" : "user"}`);
  }, [myRoleType]);

  const modalDescriptionKey = useMemo(() => {
    if (myRoleType == null) return "";
    if (scope === QuotaType.STORAGE) {
      return `billing.modals.limitations.storage.description.${myRoleType}`;
    }
    return `billing.modals.limitations.${scope}.description.${amIOwner ? "owner" : "user"}`;
  }, [myRoleType]);

  return (
    <ConfirmModalComponent
      name={ModalNames.CONFIRM_LIMITATIONS}
      title={modalTitle}
      description={<Trans i18nKey={modalDescriptionKey} values={{ limit, name: organisationOwner }}></Trans>}
      action={action}
      actionType="primary"
      actionLabel={t(`billing.modals.limitations.${scope}.action`)}
      cancelLabel={amIOwner ? t("general.cancel") : t("billing.modals.limitations.close")}
    />
  );
};
