import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useGetGoogleCalendarEventsQuery = (timeMin: string, timeMax?: string) => {
  return useQuery({
    queryKey: [QueryKeys.GOOGLE_CALENDAR_EVENTS, { timeMin, timeMax }],
    queryFn: async () => {
      const response = await api.calendars.searchCalendarEvents({ timeMin, timeMax }, { defaultToastErrorMessage: null });
      return response.data.data;
    },
    retry: 0,
  });
};
