import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { EditTaskDrawerComponent } from "@/components/shared/tasks/EditTaskDrawerComponent/EditTaskDrawerComponent";
import { TasksContentComponent } from "@/components/shared/tasksContent/TasksContentComponent";
import { RoomContext } from "@/contexts/RoomContext";
import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export const TasksTabComponent = (): JSX.Element => {
  const roomContext = useContext(RoomContext);

  const roomId = useMemo(() => {
    return roomContext?.roomResponse?.room?.id;
  }, [roomContext?.roomResponse?.room?.id]);
  const params = useParams();
  const { openDrawer } = useContext(DrawerContext);

  useEffect(() => {
    if (params.taskId == null) return;

    openDrawer({
      drawerType: DrawerType.EDIT_TASK,
      component: <EditTaskDrawerComponent taskId={params.taskId} />,
    });
  }, [params.taskId]);

  return (
    <section className="flex w-full flex-col overflow-x-scroll">
      <TasksContentComponent roomId={roomId} />
    </section>
  );
};
