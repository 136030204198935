import { type IEmailIdentity } from "@/api/client";
import { isMailValid } from "@/utils/utilities";
import { useCallback, useEffect, useMemo, useState, type ChangeEvent, type KeyboardEvent } from "react";

const VALIDATION_EVENT_KEY = ["Enter", " ", "Spacebar", "Tab"];

export const useRecipientInput = (onChange: (newTags: IEmailIdentity[]) => void, defaultValue?: IEmailIdentity[]) => {
  const [inputValue, setInputValue] = useState("");
  const [values, setValues] = useState<IEmailIdentity[]>(defaultValue ?? []);

  useEffect(() => {
    setValues(defaultValue ?? []);
  }, [defaultValue]);

  useEffect(() => {
    onChange(values);
  }, [values]);

  const addTag = useCallback(() => {
    const newTag = { email: inputValue, name: inputValue, raw: inputValue, id: inputValue };

    if (values.some((value) => value.email === newTag.email)) {
      setInputValue("");
      return;
    }

    setValues([...values, newTag]);
    setInputValue("");
  }, [values, inputValue]);

  const isTagAnEmail = useMemo(() => isMailValid(inputValue.trim()), [inputValue]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (VALIDATION_EVENT_KEY.includes(event.key) && inputValue != null) {
        if (!isTagAnEmail) return;
        event.preventDefault();
        addTag();
      } else if (event.key === "Backspace" && (inputValue === "" || inputValue == null) && values.length > 0) {
        handleRemoveTag(values[values.length - 1]);
      }
    },
    [inputValue, values],
  );

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    isTagAnEmail ? addTag() : setInputValue("");
  }, [inputValue]);

  const handleRemoveTag = useCallback(
    (tag: IEmailIdentity) => {
      const newTags = values.filter((value) => value.email !== tag.email);
      setValues(newTags);
    },
    [values],
  );

  return {
    values,
    inputValue,
    handleKeyDown,
    handleChange,
    handleRemoveTag,
    handleBlur,
  };
};
