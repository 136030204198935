import { RoleType, type IOrganisationMember, type IRole } from "@/api/client";
import { MemberComponent } from "@/components/shared/members/MemberComponent";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useOrganisationMutations } from "@/hooks/mutations/organisations/useOrganisationMutations";
import { api } from "@/services/HttpService";
import { useCallback, useContext } from "react";

export const OrganisationMemberComponent = ({ member, isDisabled = false }: { member: IOrganisationMember; isDisabled?: boolean }) => {
  const me = useContext(AuthenticationContext);
  const myMainOrganisationRole = me?.mainOrganisation?.role?.type;
  const { selfLeaveMutation, removeMemberMutation, changeRoleMutation } = useOrganisationMutations();

  const _leaveMemberFromOrganisation = useCallback(
    (transferToId?: string) => {
      if (me == null || member.role == null) return () => {};
      if (member.userId === me.id) {
        selfLeaveMutation.mutate({ organisationId: member.organisationId, transferToId });
      } else removeMemberMutation.mutate({ organisationId: member.organisationId, membershipId: member.id });
    },
    [me],
  );

  const changeRole = useCallback(
    (role: IRole) => {
      if (member.role == null) return;
      changeRoleMutation.mutate({ organisationId: member.organisationId, membershipId: member.id, role });
    },
    [member],
  );

  const getOrganisationMembersRoleOptions = useCallback(async () => {
    const response = await api.organisations.getRoleOptions(member.organisationId, member.id);
    return response.data;
  }, []);
  const isMemberOwner = member.role?.type === RoleType.Owner;
  const isMemberAdmin = member.role?.type === RoleType.Admin;
  const amIAdmin = myMainOrganisationRole === RoleType.Admin;
  const amIOwner = myMainOrganisationRole === RoleType.Owner;
  const isMe = member.userId === me?.id;

  const canChangeRole = (amIOwner && !isMe) || (amIAdmin && !isMemberOwner && !isMemberAdmin);
  const canRemove = amIOwner || (amIAdmin && !isMemberOwner && !isMemberAdmin) || isMe;

  return (
    <>
      <MemberComponent
        member={member}
        canChangeRole={canChangeRole && !isDisabled}
        canRemove={canRemove && !isDisabled}
        scope="organisation"
        leaveMember={_leaveMemberFromOrganisation}
        getMembersRoleOptions={getOrganisationMembersRoleOptions}
        setMemberRole={changeRole}
        shouldOpenConfirmationModal={true}
      />
    </>
  );
};
