import { type TaskPriorityEnum } from "@/api/client";
import { TaskPriorityPopinContentComponent } from "@/components/shared/tasks/TaskPriorityPopinComponent/TaskPriorityPopinContentComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { type TippyProps } from "@tippyjs/react";
import { type FunctionComponent } from "react";

interface ITaskPriorityPopinComponentProps {
  children: TippyProps["children"];
  isVisible: boolean;
  onCloseTooltip: () => void;
  onPrioritySelected: (priority: TaskPriorityEnum | null) => void;
  priority: TaskPriorityEnum | null;
}

export const TaskPriorityPopinComponent: FunctionComponent<ITaskPriorityPopinComponentProps> = ({
  children,
  isVisible,
  onCloseTooltip,
  onPrioritySelected,
  priority,
}) => {
  return (
    <TooltipComponent
      placement="bottom-end"
      visible={isVisible}
      onClickOutside={onCloseTooltip}
      arrow={false}
      interactive
      noPadding
      content={<TaskPriorityPopinContentComponent priority={priority} onPrioritySelected={onPrioritySelected} />}
    >
      {children}
    </TooltipComponent>
  );
};
