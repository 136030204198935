import { type ITask, type ITaskSearchBody } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";
import { isBefore } from "date-fns";

const sortTasksByCreatedAt = (a: ITask, b: ITask) => {
  return isBefore(new Date(a.createdAt), new Date(b.createdAt)) ? 1 : -1;
};

export const useTasksQuery = (params: ITaskSearchBody) => {
  return useQuery<ITask[]>({
    queryKey: [QueryKeys.TASKS, params],
    queryFn: async () => {
      const response = await api.tasks.searchTasks(params);

      return response.data.data.sort(sortTasksByCreatedAt);
    },
    staleTime: 0,
  });
};
