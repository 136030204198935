import { type IMyOrganisationLeave, type IRole, type IUpdateOrganisation } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const useOrganisationMutations = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const updateMutation = useMutation({
    mutationFn: async ({ organisationId, editedOrganisation }: { organisationId?: string; editedOrganisation?: IUpdateOrganisation }) => {
      if (organisationId == null || editedOrganisation == null) return;
      const response = await api.organisations.update(organisationId, editedOrganisation, {
        defaultToastErrorMessage: t("toasts.organisation.update.error"),
      });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ME] });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_ROOMS] });
      toast.success(t("toasts.organisation.update.success"));

      return response.data;
    },
  });

  const selfLeaveMutation = useMutation({
    mutationFn: async ({ organisationId, transferToId }: { organisationId: string; transferToId?: string }) => {
      const data: IMyOrganisationLeave = {};
      if (transferToId != null) {
        data.transferOwnershipToMember = { id: transferToId };
      }
      navigate("/dashboard");
      const response = await api.my.leaveOrganisation(organisationId, data, { defaultToastErrorMessage: t("toasts.organisation.leave.error") });

      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ME] });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ORGANISATIONS] });
      toast.success(t("toasts.organisation.leave.success"));

      return response.data;
    },
  });

  const removeMemberMutation = useMutation({
    mutationFn: async ({ organisationId, membershipId }: { organisationId: string; membershipId: string }) => {
      const response = await api.organisations.removeMember(organisationId, membershipId, {
        defaultToastErrorMessage: t("toasts.organisation.member.remove.error"),
      });

      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ME] });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ORGANISATIONS] });
      toast.success(t("toasts.organisation.member.remove.success"));

      return response.data;
    },
  });

  const changeRoleMutation = useMutation({
    mutationFn: async ({ organisationId, membershipId, role }: { organisationId: string; membershipId: string; role: IRole }) => {
      const response = await api.organisations.setRole(
        organisationId,
        membershipId,
        {
          role: {
            id: role.id,
          },
        },
        { defaultToastErrorMessage: t("toasts.organisation.role.update.error") },
      );
      toast.success(t("toasts.organisation.role.update.success"));
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ORGANISATIONS, organisationId, QueryKeys.MEMBERS] });
      return response.data;
    },
  });

  return { updateMutation, selfLeaveMutation, removeMemberMutation, changeRoleMutation };
};
