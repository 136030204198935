export enum SocketEvents {
  CHANNELS_UPDATED = "channels-updated",
  CONVERSATION_NOTIFICATION_SETTINGS_UPDATED = "conversation-notification-settings-updated",
  DIRECT_MESSAGE_UPDATED = "direct-message-updated",
  EMAIL_BADGE_UPDATED = "email-badge-updated",
  EMAIL_THREAD_UPDATED = "email-thread-updated",
  ENTER_CONTEXT = "enter-context",
  HOME_DM_BADGE_UPDATED = "home-dm-badge-updated",
  LEAVE_CONTEXT = "leave-context",
  MESSAGE_DELETED = "message-deleted",
  MESSAGE_PINNED = "message-pinned",
  MESSAGE_UNPINNED = "message-unpinned",
  MESSAGE_UPDATED = "message-updated",
  NEW_EMAIL = "new-email",
  NEW_MESSAGE_SENT = "new-message-sent",
  NEW_NOTIFICATION = "new-notification",
  ORGANISATION_MEMBER_UPDATED = "organisation-member-updated",
  ORGANISATIONAL_ROOM_HIDDEN = "organisational-room-hidden",
  ORGANISATIONAL_ROOM_UNHIDDEN = "organisational-room-unhidden",
  READ_NOTIFICATIONS = "read-notifications",
  ROOMS_UPDATED = "room-list-updated",
  ROOM_MEMBER_UPDATED = "room-member-updated",
  SUBSCRIPTION_UPDATED = "subscription-updated",
  TASK_CREATED = "task-created",
  TASK_DELETED = "task-deleted",
  TASK_STATUS_CREATED = "task-status-created",
  TASK_STATUS_DELETED = "task-status-deleted",
  TASK_STATUS_LIST_UPDATED = "task-status-list-updated",
  TASK_STATUS_UPDATED = "task-status-updated",
  TASK_UPDATED = "task-updated",
  TODO_CREATED = "todo-created",
  TODO_DELETED = "todo-deleted",
  TODO_UPDATED = "todo-updated",
  UPDATED_NOTIFICATION = "updated-notification",
  USER_IS_ACTIVE = "user-is-active",
  USER_IS_TYPING = "conversation-member-is-typing",
  USER_UPDATE = "user-updated",
  VOICE_NOTE_CONVERSION_FINISHED = "voice-note-conversion-finished",
  ZIP_MULTIPLE_FINISHED = "zip-multiple-finished",
}
