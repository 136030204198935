import { type IInvitation } from "@/api/client";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { useBulkRemoveOrganisationInvitesMutation } from "@/hooks/mutations/organisations/invites/useBulkRemoveOrganisationInvitesMutation";
import { useCancelRoomInvite } from "@/hooks/queries/rooms/useCancelRoomInvite";
import { useInviteMember } from "@/hooks/queries/shared/useInviteMember";
import { format } from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IoClose as DenyIcon } from "react-icons/io5";

export const PendingInvitationComponent = ({ invitation, scope = "organisation" }: { invitation: IInvitation; scope?: string }): JSX.Element => {
  const { inviteMemberToOrganisationMutation, inviteMemberToRoomMutation } = useInviteMember();
  const { mutate: cancelRoomInvite } = useCancelRoomInvite();
  const { mutate: cancelOrganisationInvites } = useBulkRemoveOrganisationInvitesMutation();
  const { t } = useTranslation();

  const invite = useCallback(() => {
    if (scope === "organisation") {
      inviteMemberToOrganisationMutation.mutate({ email: invitation.email });
    } else if (scope === "room" && invitation.room != null) {
      inviteMemberToRoomMutation.mutate({ email: invitation.email, roomId: invitation.room.id });
    }
  }, [invitation]);

  const cancel = useCallback(() => {
    if (scope === "room" && invitation.room != null) {
      cancelRoomInvite({ roomId: invitation.room.id, invitationId: invitation.id });
    }
    if (scope === "organisation" && invitation.organisation != null) {
      cancelOrganisationInvites({
        organisationId: invitation.organisation.id,
        invitationIds: [invitation.id],
      });
    }
  }, [invitation]);
  return (
    <>
      <div
        className="group flex items-center justify-between border-b px-6 py-2 last:border-0 hover:bg-gray-100 dark:text-dark-300 dark:hover:bg-dark-800"
        key={invitation.email}
      >
        <div className="flex shrink flex-row items-center overflow-hidden">
          <UserAvatarComponent userName={`${invitation.email}`} className="mr-2 h-7" />
          <span className="overflow-hidden text-ellipsis text-sm">{invitation.email}</span>
        </div>
        <div className="ml-2 flex shrink-0 items-center">
          {" "}
          <span className="cursor-pointer py-1 text-xs text-zinc-400 opacity-60 dark:bg-dark-900 dark:text-dark-300">
            {t("header.editOrg.modal.tabs.inviteAndRequests.inviteSentOn")} {format(new Date(invitation.updatedAt), t("date.shortFormat"))}
          </span>
          <ButtonComponent
            status="secondary"
            size="sm"
            className="mx-2 border-0 font-[600] dark:bg-dark-700 dark:text-dark-300 dark:hover:bg-dark-600"
            onClick={() => {
              invite();
            }}
          >
            {t("header.editOrg.modal.tabs.inviteAndRequests.resendInvite")}
          </ButtonComponent>
          <div className="h-5 w-5 cursor-pointer opacity-50 transition-opacity hover:opacity-100" onClick={cancel}>
            <DenyIcon />
          </div>
        </div>
      </div>
    </>
  );
};
