import { type IMessage } from "@/api/client";
import { MessageHeaderComponent } from "@/components/roomPage/tabs/conversation/message/MessageHeaderComponent";
import { MessageWrapperComponent } from "@/components/roomPage/tabs/conversation/message/MessageWrapperComponent";
import { UsersSystemMessage } from "@/components/roomPage/tabs/conversation/message/UsersSystemMessage/UsersSystemMessageComponent";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { useSystemMessage } from "@/components/shared/messages/useSystemMessage";
import { SystemMessageSubtypesEnum } from "@/constants/messages";
import { allowedSystemMessageSubtypes, usersSystemMessageSubtypes } from "@/interfaces/messages";
import { format } from "date-fns";
import { type FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";

interface ISystemMessageComponentProps {
  message: IMessage;
  previousMessage?: IMessage;
}

export const SystemMessageComponent: FunctionComponent<ISystemMessageComponentProps> = ({ message, previousMessage }) => {
  const { t } = useTranslation();
  const { handleJoinVideoCall } = useSystemMessage(message);

  if (message.systemData?.subtype == null || !allowedSystemMessageSubtypes.includes(message.systemData.subtype)) {
    return null;
  }

  if (message.systemData.subtype === SystemMessageSubtypesEnum.NEW_VIDEOCONFERENCE) {
    return (
      <MessageWrapperComponent message={message} previousMessage={previousMessage}>
        <div className="flex flex-row items-center">
          <MessageHeaderComponent message={message} isInPinboard={false} isComment={false} hideDatetime />
          <div className="w-0.5" />
          <Trans
            i18nKey="systemMessages.newVideoconference.content"
            components={{
              bold: <span className="mx-0.5 font-semibold" />,
              gray: <span className="mx-0.5  text-gray-400" />,
              normal: <span className="mx-0.5" />,
            }}
            values={{
              name: `${message.sender?.user.firstName} ${message.sender?.user.lastName}`,
              time: format(new Date(message.createdAt), t("date.timeFormat")),
            }}
          />
          <div className="w-0.5" />
          <ButtonComponent type="button" onClick={handleJoinVideoCall} status="primary" size="xxs">
            {t("systemMessages.newVideoconference.button.label")}
          </ButtonComponent>
        </div>
      </MessageWrapperComponent>
    );
  }

  if (usersSystemMessageSubtypes.includes(message.systemData.subtype)) {
    return (
      <MessageWrapperComponent isMessageCentered message={message} previousMessage={previousMessage}>
        <UsersSystemMessage userIds={message.systemData.userIds} type={message.systemData.subtype} />
      </MessageWrapperComponent>
    );
  }

  return null;
};
