import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { ModalLayoutComponent, type IModalTab } from "@/components/shared/modal/ModalLayoutComponent";
import { closeModal } from "@/components/shared/modal/ModalService";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { ModalNames } from "@/constants/modalNames";
import { type IModalAction } from "@/interfaces/modal";
import { useTranslation } from "react-i18next";
type TypeAction = "primary" | "secondary" | "danger";

interface IConfirmModal {
  title: string;
  description: string | JSX.Element;
  action?: null | (() => Promise<void>);
  actionLabel: string;
  actionType: TypeAction;
  name?: string;
  cancelLabel?: string;
  size?: "auto" | "sm" | "md" | "lg" | "xl";
}

export const ConfirmModalComponent = ({
  title,
  description,
  action,
  actionType,
  actionLabel,
  name = ModalNames.CONFIRM,
  cancelLabel,
  size = "auto",
}: IConfirmModal): JSX.Element => {
  const { t } = useTranslation();
  const actions: IModalAction[] = [
    {
      action: () => {
        closeModal(name);
      },
      label: cancelLabel ?? t("general.cancel"),
      type: "secondary" as TypeAction,
      disabled: false,
    },
    {
      action: () => {
        if (action == null) return null;
        void action().then(() => {
          closeModal(name);
        });
      },
      label: actionLabel,
      type: actionType,
      disabled: false,
      hidden: action == null,
    },
  ];
  const tabs: IModalTab[] = [
    {
      label: "Confirm",
      content: (
        <ModalTabComponent footer={<ModalFooterComponent actions={actions}></ModalFooterComponent>}>
          <span className="text-zinc-600">{description}</span>
        </ModalTabComponent>
      ),
      id: "confirm",
      disabled: false,
    },
  ];

  return <ModalLayoutComponent tabs={tabs} title={title} className="h-auto" name={name} size={size}></ModalLayoutComponent>;
};
