import { NotificationsToggleComponent } from "@/components/header/notificationToggle/NotificationsToggleComponent";
import { PinBoardToggleComponent } from "@/components/header/pinBoardToggle/PinBoardToggleComponent";
import { ProfileDropdown } from "@/components/header/profileDropdown/ProfileDropdown";
import { SearchContainerComponent } from "@/components/search/SearchContainerComponent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { StarredRoomsInHeaderComponent } from "@/components/shared/layout/StarredRoomsInHeaderComponent";
import { JitsiMinimizedComponent } from "@/components/videoCall/JitsiMinimizedComponent";
import { eventNames } from "@/constants/eventNames";
import { useIsFullScreen } from "@/hooks/shared/useIsFullScreen";
import { useSearchInput } from "@/hooks/shared/useSearchInput";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { HiHome as HomeIcon } from "react-icons/hi";
import { Portal } from "react-portal";
import { Link } from "react-router-dom";

export const GLOBAL_HEADER_HEIGHT = 44;

export const GlobalHeaderComponent = (): JSX.Element => {
  const { t } = useTranslation();

  const { isFullScreen } = useIsFullScreen();
  const { search, updateSearch } = useSearchInput();

  const closeSearchHandler = useCallback(() => {
    updateSearch("");
  }, []);

  useEffect(() => {
    window.addEventListener(eventNames.CLOSE_SEARCH, closeSearchHandler);
    return () => {
      window.removeEventListener(eventNames.CLOSE_SEARCH, closeSearchHandler);
    };
  }, [closeSearchHandler]);
  return (
    <header
      className="global-header bg-gradient-header mover relative w-full p-0 dark:border-b dark:border-b-dark-700 dark:bg-dark-900/100 dark:bg-none"
      style={{ height: GLOBAL_HEADER_HEIGHT }}
    >
      <div className="absolute inset-0 z-10 mx-layout flex h-full items-center justify-between">
        <Link
          to="/dashboard/"
          className={`relative flex h-full w-11 items-center justify-start text-white opacity-100 transition-all hover:opacity-80 focus:outline-0 ${
            window.IS_ELECTRON && !isFullScreen && !window.IS_WINDOWS ? "left-14 mr-14" : "left-0"
          }`}
        >
          <HomeIcon className="-mt-1 h-7 w-7" />
        </Link>
        <div className="starred-rooms mr-auto flex h-full flex-row items-center">
          <StarredRoomsInHeaderComponent />
        </div>
        <Portal>
          <div className="w-5/10 fixed left-1/2 top-0 z-[94] mt-2 -translate-x-0 md:w-1/3 md:-translate-x-1/2">
            <TextInputComponent
              inputSize="sm"
              placeholder={t("header.search.placeholder")}
              iconPosition="right"
              icon={<AiOutlineSearch className="h-6 w-6 text-black opacity-60 dark:text-dark-300" />}
              variant="globalSearch"
              value={search}
              className="no-mover"
              onChange={(e) => {
                updateSearch(e.target.value);
              }}
            />
          </div>
        </Portal>
        <JitsiMinimizedComponent />
        <PinBoardToggleComponent />
        <NotificationsToggleComponent />
        <ProfileDropdown />
      </div>
      {search.length > 0 && <SearchContainerComponent searchQuery={search} />}
    </header>
  );
};
