// TODO: THIS FILE MUST BE REFACTORED, IT IS FROM THE STARTER
import Modal from "@/components/electron/update/Modal";
import Progress from "@/components/electron/update/Progress";
import { useUpdate } from "@/components/electron/update/useUpdate";
import type { ProgressInfo } from "electron-updater";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./update.module.scss";

export const UpdateComponent = () => {
  const { t } = useTranslation();
  const {
    checkForUpdate,
    updateAvailable,
    versionInfo,
    updateError,
    progressInfo,
    isModalOpen,
    isButtonClickable,
    setUpdateAvailable,
    setVersionInfo,
    setUpdateError,
    setProgressInfo,
    setIsModalOpen,
    setIsButtonClickable,
  } = useUpdate();

  const [modalBtn, setModalBtn] = useState<{
    cancelText?: string;
    okText?: string;
    onCancel?: () => void;
    onOk?: () => void;
  }>({
    okText: t("update.close"),
    onCancel: () => {
      setIsModalOpen(false);
    },
    onOk: () => {
      setIsModalOpen(false);
    },
  });

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (!isModalOpen) {
          void checkForUpdate(false);
        }
      },
      1000 * 60 * 5,
    );

    return () => {
      clearInterval(interval);
    };
  }, [true, isModalOpen]);

  const onUpdateAvailable = useCallback((arg1: VersionInfo) => {
    setVersionInfo(arg1);
    setUpdateError(undefined);
    // Can be update
    if (arg1?.update) {
      setModalBtn((state) => ({
        ...state,
        cancelText: "Cancel",
        okText: t("update.updateButton"),
        onOk: async () => {
          setIsButtonClickable(false);
          return await window.electron.invoke("start-download");
        },
      }));
      setUpdateAvailable(true);
    } else {
      setUpdateAvailable(false);
    }
  }, []);

  const onUpdateError = useCallback((arg1: ErrorType) => {
    setUpdateAvailable(false);
    setUpdateError(arg1);
  }, []);

  const onDownloadProgress = useCallback((arg1: ProgressInfo) => {
    setProgressInfo(arg1);
  }, []);

  const onUpdateDownloaded = useCallback((_event: Electron.IpcRendererEvent) => {
    setProgressInfo({ percent: 100 });
    void window.electron.invoke("quit-and-install");
  }, []);

  useEffect(() => {
    void checkForUpdate(false);
    window.electron.on("update-available", onUpdateAvailable);
    window.electron.on("update-error", onUpdateError);
    window.electron.on("download-progress", onDownloadProgress);
    window.electron.on("update-downloaded", onUpdateDownloaded);
    return () => {
      window.electron.removeListener("update-available", onUpdateAvailable);
      window.electron.removeListener("update-error", onUpdateError);
      window.electron.removeListener("download-progress", onDownloadProgress);
      window.electron.removeListener("update-downloaded", onUpdateDownloaded);
    };
  }, []);

  return (
    <>
      <Modal
        open={isModalOpen}
        cancelText={modalBtn?.cancelText}
        okText={modalBtn?.okText}
        onCancel={modalBtn?.onCancel}
        onOk={modalBtn?.onOk}
        isButtonDisabled={!isButtonClickable}
        footer={true}
      >
        <div className={styles.modalslot}>
          {updateError != null ? (
            <div className="update-error">
              <p>{t("update.error")}</p>
              <p>{updateError.message}</p>
            </div>
          ) : updateAvailable ? (
            <div className="can-available">
              <div className="mt-2 text-center">
                {t("update.newVersionAvailable")}
                {versionInfo?.newVersion}
              </div>
              {!isButtonClickable && (
                <div className="update-progress flex">
                  <Progress percent={progressInfo?.percent}></Progress>
                </div>
              )}
            </div>
          ) : (
            <div className="can-not-available">{t("update.upToDate")}</div>
          )}
        </div>
      </Modal>
    </>
  );
};
