import { type IRole } from "@/api/client";
import { MemberComponent } from "@/components/shared/members/MemberComponent";
import { closeModal } from "@/components/shared/modal/ModalService";
import { QueryKeys } from "@/constants/queryKeys";
import { defaultRoleSlug } from "@/constants/roles";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useLeaveChannelMutation } from "@/hooks/mutations/channel/useLeaveChannelMutation";
import { type IFormattedChannel } from "@/interfaces/channel";
import { api } from "@/services/HttpService";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface IChannelMemberComponentProps {
  member: IFormattedChannel;
  isDisabled?: boolean;
  isLast?: boolean;
  hasGuests?: boolean;
  roomId: string;
  myRole?: IRole;
  conversationId?: string;
}

export const ChannelMemberComponent = ({
  member,
  isDisabled,
  isLast = false,
  hasGuests = false,
  roomId,
  myRole,
  conversationId,
}: IChannelMemberComponentProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const me = useContext(AuthenticationContext);
  const { mutate: leaveChannel } = useLeaveChannelMutation(roomId, member.channel.id);

  const leaveMemberFromChannel = useCallback(
    async (_transferToId?: string) => {
      if (member.userId === me?.id) {
        closeModal();

        leaveChannel({ archiveForEveryone: isLast });
      } else {
        // TODO use mutation instead of api call here
        await api.channels.removeChannelMember(member.id, { defaultToastErrorMessage: t("toasts.channel.remove.error") });
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.CHANNELS, member.channel.id, QueryKeys.MEMBERS] });
        void queryClient.invalidateQueries({ queryKey: [QueryKeys.CONVERSATIONS, conversationId, QueryKeys.MEMBERS] });
        toast.success(t("toasts.channel.remove.success"));
      }
    },
    [isLast, conversationId],
  );

  const getChannelMembersRoleOptions = useCallback(async () => {
    // TODO implement that
    console.error("GetChannelMembersRoleOptions");
    return [];
  }, []);

  const setChannelMemberRole = useCallback(async () => {
    alert("SetChannelMemberRole");
  }, []);

  const isMemberOwner = member.role?.slug === defaultRoleSlug.CHANNEL_OWNER;
  const isMemberAdmin = member.role?.slug === defaultRoleSlug.CHANNEL_ADMIN;
  const amIOwner = myRole?.slug === defaultRoleSlug.CHANNEL_OWNER;
  const amIAdmin = myRole?.slug === defaultRoleSlug.CHANNEL_ADMIN;
  const isMe = member.userId === me?.id;

  const canRemove = isDisabled !== true && (amIOwner || (amIAdmin && !isMemberOwner && !isMemberAdmin) || isMe);

  return (
    <MemberComponent
      member={member}
      canRemove={canRemove}
      canChangeRole={false}
      isDisabled={isDisabled}
      scope="channel"
      leaveMember={leaveMemberFromChannel}
      getMembersRoleOptions={getChannelMembersRoleOptions}
      setMemberRole={setChannelMemberRole}
      shouldOpenConfirmationModal={false}
      isLast={isLast}
      hasGuests={hasGuests}
    />
  );
};
