import { type IFile } from "@/api/client";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { useDownload } from "@/hooks/shared/useDownload";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCloudDownload as DownloadIcon } from "react-icons/ai";
interface IFileActionMenuComponentProps {
  file: IFile;
}

export const FileActionMenuComponent = ({ file }: IFileActionMenuComponentProps) => {
  const { t } = useTranslation();
  const { download } = useDownload();
  const handleClick = useCallback(() => {
    download(file.url);
  }, [file]);

  return (
    <TooltipComponent content={t("files.download.button", { count: 1 })} placement="bottom">
      <div
        className="absolute right-0 top-0 z-[102] m-2 hidden h-7 w-7 rounded-md border border-pigeon-post-200 bg-white p-1 group-hover/file:block"
        onClick={handleClick}
      >
        <DownloadIcon className="h-full w-full text-zinc-500 transition-all hover:text-zinc-700" />
      </div>
    </TooltipComponent>
  );
};
