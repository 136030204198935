import { useUpdateTodoInCache } from "@/hooks/mutations/tasks/todos/useUpdateTodoInCache";
import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";

export const useRemoveTodoMutation = (taskId: string) => {
  const { deleteTodoInCache } = useUpdateTodoInCache();

  const mutation = useMutation({
    mutationFn: async (todoId: string) => {
      await api.tasks.deleteTodo(taskId, todoId);
    },
    onMutate: (todoId) => {
      deleteTodoInCache(taskId, todoId);
    },
  });

  return mutation;
};
