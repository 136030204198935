import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const useValidateFreePlanMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async (planId: string) => {
      await api.subscriptions.validate(planId, {
        defaultToastErrorMessage: t("billing.errors.validateFreePlan"),
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.BILLING] });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ME] });
    },
  });
};
