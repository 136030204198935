import { type IOrganisationMembersPendingResponse } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface IBulkRemoveOrganisationInvitesMutation {
  organisationId: string;
  invitationIds: string[];
}

export const useBulkRemoveOrganisationInvitesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organisationId, invitationIds }: IBulkRemoveOrganisationInvitesMutation) => {
      const response = await api.organisations.cancelManyInvitation(organisationId, { invitationIds });
      return response.data;
    },
    onSuccess: (_, { organisationId, invitationIds }) => {
      queryClient.setQueryData<IOrganisationMembersPendingResponse>([QueryKeys.ORGANISATIONS, organisationId, QueryKeys.PENDING], (data) => {
        if (data == null) return data;

        return { ...data, invitations: data.invitations.filter((invite) => !invitationIds.includes(invite.id)) };
      });
    },
  });
};
