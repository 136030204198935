import { type IUtm } from "@/api/client";
import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";

export const useGoogleLoginMutation = () => {
  return useMutation({
    mutationFn: async ({ code, hasRedirectUri, hash, utm }: { code: string; hasRedirectUri: boolean; hash?: string; utm?: IUtm }) => {
      const { data } = await api.auth.ssoLogin({
        code,
        hash,
        hasRedirectUri,
        provider: "GOOGLE",
        isDev: import.meta.env.VITE_APP_ENV === "local",
        utm,
      });

      return data;
    },
  });
};
