import { type EmojiMartJson } from "@/types/emojiMartTypes";
import emojis from "@emoji-mart/data/sets/14/apple.json";
import { init } from "emoji-mart";
import emojiRegex from "emoji-regex";
import { type DeltaStatic } from "quill";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
export const useEmojiMart = () => {
  const { i18n } = useTranslation();
  const emojiMartInit = useCallback(() => {
    void init({ data: emojis, locale: i18n.language.substring(0, 2) });
  }, []);

  const getEmojiData = (nativeCode: string): { shortcodes: string } | null => {
    const emojisData = (emojis as EmojiMartJson).emojis;
    for (const emojiId in emojisData) {
      const emojiData = emojisData[emojiId];

      for (const skin of emojiData.skins) {
        if (skin.native === nativeCode) {
          // @ts-expect-error TODO fix in https://linear.app/getroger/issue/ROG-2084/fix-emojis-useemojimart-and-skin-tone
          return { shortcodes: skin.shortcodes };
        }
      }
    }
    return null;
  };

  const emojiMatcher = (node: HTMLElement, delta: DeltaStatic) => {
    const alt = node.getAttribute("alt");

    if (alt == null) return delta;

    if (delta.ops == null) {
      delta.ops = [];
    }
    // If the delta is empty, we are dealing with an empty line and we delete it
    if (delta.ops != null && delta.ops[0].insert === "") {
      delta.ops = [];
    }

    if (emojiRegex().test(alt)) {
      delta.ops = [];
      delta.insert({ emoji: getEmojiData(alt) }, "user");
    }

    return delta;
  };

  return { emojiMartInit, getEmojiData, emojiMatcher };
};
