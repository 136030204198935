import { RoomMemberStatus, type IChannelMember, type IDirectMessage } from "@/api/client";
import { ChannelListElementComponent } from "@/components/roomPage/tabs/conversation/ConversationLists/conversationListElement/ChannelListElementComponent";
import { DmListElementComponent } from "@/components/roomPage/tabs/conversation/ConversationLists/conversationListElement/DmListElementComponent";
import { useChannelListReorder } from "@/components/roomPage/tabs/conversation/ConversationLists/conversationListElement/useChannelListReorder";
import { useDmList } from "@/components/roomPage/tabs/conversation/ConversationLists/useDmList";
import { DropDownComponent } from "@/components/shared/dropDown/DropDownComponent";
import { RoomContext } from "@/contexts/RoomContext";
import { DndContext } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BiPlusCircle } from "react-icons/bi";
interface IChannelListProps {
  conversations: IChannelMember[] | IDirectMessage[];
  addConversation: (event: Event) => void;
  closeList: (event: Event) => void;
  title: string | JSX.Element;
  isOnHomePage: boolean;
  scope?: "channel" | "dm";
}

export const ConversationListComponent = ({
  conversations,
  addConversation,
  closeList,
  title,
  scope,
  isOnHomePage,
}: IChannelListProps): JSX.Element => {
  const { t } = useTranslation();
  const { getDmsWithOneMember, getDmsWithMoreThanOneMember, setDms, dms } = useDmList();

  const { handleDragStart, handleDragEnd, rectSortingStrategy, sensors, orderedConversations, setOrderedConversations, isDragging } =
    useChannelListReorder(conversations as IChannelMember[]);

  useEffect(() => {
    if (scope === "channel") {
      setOrderedConversations(conversations as IChannelMember[]);
      return;
    }

    setDms({
      oneOnOne: getDmsWithOneMember(conversations as IDirectMessage[]),
      group: getDmsWithMoreThanOneMember(conversations as IDirectMessage[]),
    });
  }, [conversations]);
  const roomContext = useContext(RoomContext);
  const shouldShowAddButton = useMemo(() => {
    if (isOnHomePage) return true;
    const selectedStatus = roomContext?.channelStatusSelected;
    const roomMemberStatus = roomContext?.roomResponse?.member.status;
    const isDmInActiveRoom = scope === "dm" && roomMemberStatus === RoomMemberStatus.Confirmed;
    const isChannelInActiveRoomWithActiveChannelStatusSelected =
      scope === "channel" && selectedStatus === RoomMemberStatus.Confirmed && roomMemberStatus === RoomMemberStatus.Confirmed;

    return isDmInActiveRoom || isChannelInActiveRoomWithActiveChannelStatusSelected;
  }, [isOnHomePage, roomContext?.channelStatusSelected, roomContext?.roomResponse?.member.status]);

  return (
    <>
      <DropDownComponent
        defaultOpen
        title={
          <span className="flex w-full items-center text-sm font-semibold text-zinc-800 transition-opacity hover:opacity-80 dark:text-dark-300">
            {title}
            {shouldShowAddButton && (
              <BiPlusCircle
                onClick={(e) => {
                  addConversation(e.nativeEvent);
                }}
                className="ml-auto h-5 w-5 cursor-pointer text-zinc-500 opacity-80 transition-all hover:text-sky-600 hover:opacity-100"
              />
            )}
          </span>
        }
      >
        <nav className={`x-full flex shrink-0 grow-0 flex-col ${scope === "channel" ? "mb-2 border-b border-zinc-100 pb-2" : ""}`}>
          {scope === "channel" ? (
            <>
              <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                {orderedConversations.length === 0 ? (
                  <div className="ml-12 text-xs text-black">{t("channels.list.noResult")}</div>
                ) : (
                  <SortableContext items={orderedConversations} strategy={rectSortingStrategy}>
                    {orderedConversations?.map((channel) => (
                      <ChannelListElementComponent
                        key={channel.id}
                        closeList={closeList}
                        conversation={channel}
                        isHighlighted={channel.highlightChannel}
                        badge={channel.badge ?? 0}
                        isDragging={isDragging}
                      />
                    ))}
                  </SortableContext>
                )}
              </DndContext>
            </>
          ) : (
            <>
              {dms.oneOnOne.length > 0 && (
                <>
                  <h3 className="mb-1 ml-layout mt-2 px-px text-xs text-zinc-400">{t("dms.list.1on1", { count: dms.oneOnOne.length })}</h3>
                  {dms.oneOnOne.map((conversation) => (
                    <DmListElementComponent
                      key={conversation.id}
                      closeList={closeList}
                      conversation={conversation}
                      badge={conversation.badge ?? 0}
                      isHighlighted={(conversation.badge ?? 0) > 0}
                    />
                  ))}
                </>
              )}
              {dms.group.length > 0 && (
                <>
                  <h3 className="mb-1 ml-layout mt-2 px-px text-xs text-zinc-400">{t("dms.list.group", { count: dms.group.length })}</h3>
                  {dms.group.map((conversation) => (
                    <DmListElementComponent
                      key={conversation.id}
                      closeList={closeList}
                      conversation={conversation}
                      badge={conversation.badge ?? 0}
                      isHighlighted={(conversation.badge ?? 0) > 0}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </nav>
      </DropDownComponent>
    </>
  );
};
