import { type IQuotaData } from "@/api/client";
import { LimitationConfirm } from "@/components/billing/modals/guestLimitation/LimitationConfirm";
import { SelectPlanModalComponent } from "@/components/billing/modals/selectPlanModal/SelectPlanModalComponent";
import { ConfirmModalComponent } from "@/components/shared/modal/ConfirmModalComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { LimitationResponseCodes } from "@/constants/limitationResponseCode";
import { ModalNames } from "@/constants/modalNames";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useConvertToRegularAccountMutation } from "@/hooks/mutations/rooms/useConvertToRegularAccountMutation";
import { QuotaType } from "@/interfaces/billing";
import { useCallback, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";

export const useBillingConfirm = () => {
  const me = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const { mutateAsync: convertAccount } = useConvertToRegularAccountMutation();
  const openBillingConfirmModal = useCallback((type: LimitationResponseCodes, quotaData?: IQuotaData) => {
    let Modal: JSX.Element = <LimitationConfirm limit={quotaData?.quotaLimit ?? 0} scope={(quotaData?.quotaType as QuotaType) ?? QuotaType.ROOM} />;
    let modalName = ModalNames.CONFIRM_LIMITATIONS;
    const guestTypes = [
      LimitationResponseCodes.EMAIL_SENT_TO_ADMIN,
      LimitationResponseCodes.ORGANISATION_ACCOUNT_REQUIRED,
      LimitationResponseCodes.PERSONAL_ACCOUNT_REQUIRED,
    ];

    if (guestTypes.includes(type)) {
      let action: (() => Promise<void>) | null;
      modalName = ModalNames.CONFIRM_GUEST_ROOM_CREATION;
      if (type === LimitationResponseCodes.EMAIL_SENT_TO_ADMIN) {
        action = null;
      } else {
        action = async () => {
          await convertAccount();
          openModal(ModalNames.PLANS, <SelectPlanModalComponent />);
        };
      }

      Modal = (
        <ConfirmModalComponent
          name={modalName}
          title={t(`billing.modals.guest.${type}.title`)}
          description={
            <Trans i18nKey={t(`billing.modals.guest.${type}.description`)} values={{ domain: `@${me?.email.split("@")[1] ?? ""}` }}></Trans>
          }
          action={action}
          actionType="primary"
          actionLabel={t(`billing.modals.guest.${type}.action`)}
          cancelLabel={type === LimitationResponseCodes.EMAIL_SENT_TO_ADMIN ? t("general.ok") : t("general.cancel")}
        />
      );
    }

    openModal(modalName, Modal);
  }, []);

  return { openBillingConfirmModal };
};
