import { type IExtendedFile } from "@/api/client";
import { useFileType } from "@/hooks/shared/files/useFileType";
import { useDownload } from "@/hooks/shared/useDownload";
import { useCallback, type Dispatch, type SetStateAction } from "react";

export const useFileTableItem = (file: IExtendedFile, isSelected: boolean, setSelectedItems: Dispatch<SetStateAction<string[]>>) => {
  const { getTypeByExtension } = useFileType();
  const { download } = useDownload();
  const downloadFile = useCallback((file: IExtendedFile) => {
    download(file.url);
  }, []);

  const handleCheckboxChange = () => {
    isSelected
      ? setSelectedItems((prevItems) => prevItems.filter((item) => item !== file.id))
      : setSelectedItems((prevItems) => [...prevItems, file.id]);
  };

  return { getTypeByExtension, handleCheckboxChange, downloadFile };
};
