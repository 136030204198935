export const AUTHENTICATION_ROUTES: Record<string, string> = {
  LOGIN: "/api/v1/auth/email/login",
  REGISTER: "/api/v1/auth/email/register",
  CONFIRM_EMAIL: "/api/v1/auth/email/confirm",
  REFRESH_TOKEN: "/api/v1/auth/refresh",
  ME: "/api/v1/me",
  FORGOT_PASSWORD: "/api/v1/auth/forgot/password",
  RESET_PASSWORD: "/api/v1/auth/reset/password",
  LOGOUT: "/api/v1/auth/email/logout",
  EDIT_PASSWORD: "/api/v1/my/password",
};

export const ROOM_ROUTES: Record<string, string> = {
  MY_ROOMS: "/api/v1/my/rooms",
};

export const CHANNEL_ROUTES: Record<string, string> = {
  MY_CHANNELS: "/api/v1/my/rooms/:roomId/channels",
  CHANNEL: "/api/v1/channels/:channelId",
  CREATE_CHANNEL: "/api/v1/rooms/:roomId/channels",
};

export const ORGANISATION_ROUTES: Record<string, string> = {
  MY_ORGANISATIONS: "/api/v1/my/organisations",
  ORGANISATIONS: "/api/v1/organisations",
  INVITATIONS: "/api/v1/invitations",
  ROOMS: "/api/v1/rooms",
};

export const CONVERSATION_ROUTES: Record<string, string> = {
  CONVERSATIONS: "/api/v1/conversations",
  MESSAGES: "/api/v1/messages",
};
