import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const useVerifyRoomCreationMutation = () => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async () => {
      const { data } = await api.rooms.verifyCreation({
        defaultToastErrorMessage: t("billing.errors.initializeRoomCreation"),
      });

      return data;
    },
  });
};
