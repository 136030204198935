import { type ITaskStatus } from "@/api/client";
import { useTaskStatus } from "@/components/shared/tasks/TaskStatusComponent/useTaskStatus";
import { TaskStatusItemComponent } from "@/components/shared/tasks/TaskStatusItemComponent";
import { TaskStatusPopinComponent } from "@/components/shared/tasks/TaskStatusPopinComponent/TaskStatusPopinComponent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ITaskStatusComponentProps {
  status?: ITaskStatus;
  onStatusChange: (status: string) => void;
  displayNoStatus?: boolean;
  roomId: string | null;
}

export const TaskStatusComponent: FunctionComponent<ITaskStatusComponentProps> = ({ status, onStatusChange, displayNoStatus, roomId }) => {
  const { isStatusTooltipVisible, onStatusClick, onCloseTooltip, onStatusSelected } = useTaskStatus(status, onStatusChange);

  const { t } = useTranslation();
  if (status == null) {
    return <div className="rounded-md bg-zinc-200 px-3 py-1 text-xs text-zinc-500">{t("tasks.status.default")}</div>;
  }

  return (
    <TaskStatusPopinComponent isVisible={isStatusTooltipVisible} onCloseTooltip={onCloseTooltip} onStatusSelected={onStatusSelected} roomId={roomId}>
      <div className="flex cursor-pointer flex-row items-center hover:opacity-75" onClick={onStatusClick}>
        <TaskStatusItemComponent status={status} displayNoStatus={displayNoStatus} />
      </div>
    </TaskStatusPopinComponent>
  );
};
