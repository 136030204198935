import { type ITask, type ITaskOne, type ITodo, type ITodoAddBody } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { useQueryClient } from "@tanstack/react-query";

export const useUpdateTodoInCache = () => {
  const queryClient = useQueryClient();

  const addOptimisticTodoInCache = (taskId: string, optimisticId: string, body: ITodoAddBody) => {
    const newTodo: ITodo = {
      ...body,
      id: optimisticId,
      isChecked: false,
      createdAt: new Date().toISOString(),
    };

    queryClient.setQueryData<ITaskOne>([QueryKeys.TASK, taskId], (oldData) => {
      if (oldData == null) {
        return oldData;
      }

      const newTodos = [...(oldData.todos?.todos ?? []), newTodo];
      const checkedCount = newTodos.reduce((accumulator, todo) => accumulator + (todo.isChecked ? 1 : 0), 0);
      const totalCount = newTodos.length;

      queryClient.setQueriesData<ITask[]>({ exact: false, queryKey: [QueryKeys.TASKS] }, (oldData) => {
        if (oldData == null) {
          return oldData;
        }

        if (oldData.some((task) => task.id === taskId)) {
          return oldData.map((task) => (task.id === taskId ? { ...task, todos: { checkedCount, totalCount } } : task));
        }

        return oldData;
      });

      return {
        ...oldData,
        todos: {
          todos: newTodos,
          checkedCount,
          totalCount,
        },
      };
    });
  };

  const addTodoInCache = (taskId: string, newTodo: ITodo, optimisticId?: string) => {
    queryClient.setQueryData<ITaskOne>([QueryKeys.TASK, taskId], (oldData) => {
      if (oldData == null) {
        return oldData;
      }

      const oldTodos = oldData.todos?.todos ?? [];

      const newTodos = [...oldTodos.filter((todo) => todo.id !== optimisticId && todo.id !== newTodo.id), newTodo];
      const checkedCount = newTodos.reduce((accumulator, todo) => accumulator + (todo.isChecked ? 1 : 0), 0);
      const totalCount = newTodos.length;

      queryClient.setQueriesData<ITask[]>({ exact: false, queryKey: [QueryKeys.TASKS] }, (oldData) => {
        if (oldData == null) {
          return oldData;
        }

        if (oldData.some((task) => task.id === taskId)) {
          return oldData.map((task) => (task.id === taskId ? { ...task, todos: { checkedCount, totalCount } } : task));
        }

        return oldData;
      });

      return {
        ...oldData,
        todos: {
          todos: newTodos,
          checkedCount,
          totalCount,
        },
      };
    });
  };

  const updateTodoInCache = (taskId: string, todoId: string, params: Partial<ITodo>) => {
    queryClient.setQueryData<ITaskOne>([QueryKeys.TASK, taskId], (oldData) => {
      if (oldData == null) {
        return oldData;
      }

      const newTodos = (oldData.todos?.todos ?? []).map((todo) => (todo.id === todoId ? { ...todo, ...params } : todo));

      const checkedCount = newTodos.reduce((accumulator, todo) => accumulator + (todo.isChecked ? 1 : 0), 0);
      const totalCount = newTodos.length;

      queryClient.setQueriesData<ITask[]>({ exact: false, queryKey: [QueryKeys.TASKS] }, (oldData) => {
        if (oldData == null) {
          return oldData;
        }

        if (oldData.some((task) => task.id === taskId)) {
          return oldData.map((task) => (task.id === taskId ? { ...task, todos: { checkedCount, totalCount } } : task));
        }

        return oldData;
      });

      return {
        ...oldData,
        todos: {
          todos: newTodos,
          checkedCount,
          totalCount,
        },
      };
    });
  };

  const deleteTodoInCache = (taskId: string, todoId: string) => {
    queryClient.setQueryData<ITaskOne>([QueryKeys.TASK, taskId], (oldData) => {
      if (oldData == null) {
        return oldData;
      }

      const newTodos = (oldData.todos?.todos ?? []).filter((todo) => todo.id !== todoId);
      const checkedCount = newTodos.reduce((accumulator, todo) => accumulator + (todo.isChecked ? 1 : 0), 0);
      const totalCount = newTodos.length;

      queryClient.setQueriesData<ITask[]>({ exact: false, queryKey: [QueryKeys.TASKS] }, (oldData) => {
        if (oldData == null) {
          return oldData;
        }

        if (oldData.some((task) => task.id === taskId)) {
          return oldData.map((task) => (task.id === taskId ? { ...task, todos: { checkedCount, totalCount } } : task));
        }

        return oldData;
      });

      return {
        ...oldData,
        todos: {
          todos: newTodos,
          checkedCount,
          totalCount,
        },
      };
    });
  };

  return {
    addOptimisticTodoInCache,
    addTodoInCache,
    updateTodoInCache,
    deleteTodoInCache,
  };
};
