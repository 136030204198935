import { type IMessage } from "@/api/client";
import { MessageDateComponent } from "@/components/roomPage/tabs/conversation/message/MessageDateComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { ProfilePopinComponent } from "@/components/shared/user/ProfilePopinComponent";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { RoomContext } from "@/contexts/RoomContext";
import { useUserQuery } from "@/hooks/queries/shared/user/useUserQuery";
import { useMessage } from "@/hooks/shared/useMessage";
import { debounce } from "@/utils/utilities";
import { format } from "date-fns";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosShareAlt as ArrowIcon } from "react-icons/io";

interface IMessageHeaderComponentProps {
  message: IMessage;
  hideProfilePicture?: boolean;
  isForwarded?: boolean;
  showForward?: boolean;
  isInPinboard?: boolean;
  isInSearch?: boolean;
  isComment?: boolean;
  hideDatetime?: boolean;
}
export const MessageHeaderComponent = ({
  message,
  hideProfilePicture = false,
  isForwarded = false,
  showForward = false,
  isInPinboard = false,
  isInSearch = false,
  isComment = false,
  hideDatetime = false,
}: IMessageHeaderComponentProps): JSX.Element => {
  const authorRef = useRef<HTMLDivElement>(null);
  const [isHoveringAuthor, setIsHoveringAuthor] = useState<boolean>(false);
  const [isHoveringPopin, setIsHoveringPopin] = useState<boolean>(false);
  const { isMine } = useMessage({ message, isComment });
  const { t } = useTranslation();
  const roomContext = useContext(RoomContext);
  const changeIsHoveringAuthor = useCallback(
    debounce((newValue: boolean) => {
      setIsHoveringAuthor(newValue);
    }, 500),
    [],
  );
  const changeIsHoveringPopin = useCallback(
    debounce((newValue: boolean) => {
      setIsHoveringPopin(newValue);
    }, 500),
    [],
  );

  const createdAt = useMemo(() => new Date(message.createdAt), [message]);
  const userId = useMemo(() => message.sender?.user?.id, [message]);
  const { data: author } = useUserQuery(userId);

  return (
    <div className={`message-header flex h-6 items-center ${isComment ? "mt-4" : ""}`} ref={authorRef}>
      {message.sender?.user != null && !hideProfilePicture && (
        <figure
          className={`${isComment ? "h-5 w-5" : "h-6 w-6"} mr-2 shrink-0 cursor-pointer`}
          onMouseLeave={() => {
            changeIsHoveringAuthor(false);
          }}
          onMouseEnter={() => {
            changeIsHoveringAuthor(true);
          }}
        >
          <UserAvatarComponent
            key={author?.profilePicture?.url}
            userId={message.sender?.user?.id}
            userName={`${message.sender?.user.firstName ?? ""} ${message.sender?.user.lastName ?? ""}`}
            hideStatusBadge
          />
        </figure>
      )}
      <div className="message-author flex w-full items-center">
        <h3 className="group cursor-pointer text-xs font-semibold dark:text-white">
          <span
            className="group-hover:underline"
            onMouseLeave={() => {
              changeIsHoveringAuthor(false);
            }}
            onMouseEnter={() => {
              changeIsHoveringAuthor(true);
            }}
          >
            {message.sender?.user?.firstName} {message.sender?.user?.lastName}
            {isMine && <span className="text-sky-600"> {t("general.you")}</span>}
          </span>
          {hideDatetime ? null : (
            <TooltipComponent
              disabled={isComment || isInPinboard || isInSearch}
              content={format(createdAt, t("date.shortFormatWithTime"))}
              placement="right"
            >
              <span className="pl-1 text-2xs font-normal text-gray-400">
                <MessageDateComponent
                  date={createdAt}
                  hasFullDate={isComment || isInPinboard || isInSearch}
                  hasRelativeDate={isComment}
                  nowDate={new Date()}
                />
              </span>
            </TooltipComponent>
          )}
        </h3>
        {isForwarded && showForward && (
          <span className="ml-auto flex items-center pl-2 text-sm font-normal text-zinc-300">
            <ArrowIcon className="m-1 h-3.5 w-3.5" />
            <span className="text-xs">{t("roomPage.tabs.room.conversation.forward.forwarded")}</span>
          </span>
        )}
      </div>
      {(isHoveringAuthor || isHoveringPopin) && authorRef.current != null && userId != null ? (
        <ProfilePopinComponent
          className="absolute z-50 -translate-y-full pb-3 transition-all duration-300 ease-in-out"
          style={{
            top: authorRef.current.getBoundingClientRect().top + 8,
            left: authorRef.current.getBoundingClientRect().left,
          }}
          roomId={roomContext?.roomResponse?.room?.id}
          userId={userId}
          onMouseLeave={() => {
            changeIsHoveringPopin(false);
          }}
          onMouseEnter={() => {
            changeIsHoveringPopin(true);
          }}
        />
      ) : null}
    </div>
  );
};
