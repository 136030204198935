export enum notificationType {
  CHANNEL_MESSAGE = "channel-message",
  DIRECT_MESSAGE = "direct-message",
  THREAD_REPLY = "thread-reply",
  MENTION = "mention",
  REACTION = "reaction",
  TASK_ASSIGNED = "task-assigned",
  TASK_UNASSIGNED = "task-unassigned",
  TASK_STATUS_UPDATED = "task-status-updated",
  TASK_COMMENTED = "task-comment",
  TASK_DEADLINE_TOMORROW = "task-deadline-tomorrow",
  MEETING = "meeting",
  NEW_EMAIL = "new-email",
}

export const TASK_NOTIFICATION_TYPES: string[] = [
  notificationType.TASK_ASSIGNED,
  notificationType.TASK_UNASSIGNED,
  notificationType.TASK_STATUS_UPDATED,
  notificationType.TASK_COMMENTED,
  notificationType.TASK_DEADLINE_TOMORROW,
];

export enum notificationReadStatus {
  READ = "READ",
  UNREAD = "UNREAD",
}
