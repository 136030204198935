import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { JitsiContext } from "@/contexts/JitsiContextProvider";
import { addCommasSeparator } from "@/utils/utilities";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FiVideo as VideoIcon } from "react-icons/fi";
import { MdCallEnd as EndCallIcon } from "react-icons/md";
export const JitsiMinimizedComponent = () => {
  const jitsiContext = useContext(JitsiContext);
  const { t } = useTranslation();
  const { isHidden, setIsHidden, setIsVideoShown, participants } = jitsiContext;

  const participantNames = useMemo(() => {
    const MAX_PARTICIPANTS = 5;

    return addCommasSeparator(
      participants.map((participant) => participant.displayName),
      t("general.and"),
      MAX_PARTICIPANTS,
      t("videoCalls.moreParticipant", { count: participants.length - MAX_PARTICIPANTS }),
    );
  }, [participants]);

  if (!isHidden) return null;

  return (
    <TooltipComponent disabled={participantNames.length === 0} content={<div className="w-48">{participantNames}</div>}>
      <button type="button" className="flex">
        <div className="mx-4 flex cursor-pointer  select-none rounded border border-white/40 bg-white/90 p-0.5 pl-2 pr-1 text-xs text-sky-600">
          <div
            className="flex animate-pulse items-center justify-center"
            onClick={() => {
              setIsHidden(false);
            }}
          >
            <VideoIcon className="mr-2 h-4 w-4 text-sky-600" />
            {participants.length} {t("videoCalls.participants", { count: participants.length })}
          </div>
          <TooltipComponent placement="bottom" content={t("videoCalls.frame.buttons.endCall")}>
            <div
              className="ml-3 rounded-md bg-red-600 p-1 transition hover:bg-red-700"
              onClick={() => {
                setIsVideoShown(false);
                setIsHidden(false);
              }}
            >
              <EndCallIcon className="h-4 w-4 text-white" />
            </div>
          </TooltipComponent>
        </div>
      </button>
    </TooltipComponent>
  );
};
