import { type IEmailSend } from "@/api/client";
import { api } from "@/services/HttpService";
import { useMutation } from "@tanstack/react-query";

export const useSendMailMutation = () => {
  return useMutation({
    mutationFn: async (params: IEmailSend) => {
      const sendMailResponse = await api.emails.sendEmail(params);
      return sendMailResponse.data;
    },
  });
};
