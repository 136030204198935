import { AddCalendarAccountTab } from "@/components/shared/calendar/modals/tabs/AddCalendarAccountTab";
import { ModalLayoutComponent } from "@/components/shared/modal/ModalLayoutComponent";
import { ModalNames } from "@/constants/modalNames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const AddCalendarAccountModal = ({ conversationId }: { conversationId: string }): JSX.Element => {
  const { t } = useTranslation();
  const tabs = useMemo(
    () => [
      {
        label: t("addCalendarAccountModal.title"),
        content: <AddCalendarAccountTab conversationId={conversationId} />,
        id: "addCalendarAccountTab",
        disabled: false,
      },
    ],
    [conversationId],
  );

  return (
    <ModalLayoutComponent
      tabs={tabs}
      title={t("addCalendarAccountModal.title")}
      size="sm"
      name={ModalNames.ADD_CALENDAR_ACCOUNT}
      defaultTab="addCalendarAccountTab"
    />
  );
};
