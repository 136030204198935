import { eventNames } from "@/constants/eventNames";
import { RoomContext } from "@/contexts/RoomContext";
import { useCreateTaskStatusMutation } from "@/hooks/mutations/tasks/useCreateTaskStatusMutation";
import { dispatchCustomWindowEvent } from "@/utils/utilities";
import { useCallback, useContext, useMemo, useState, type ChangeEvent, type KeyboardEventHandler } from "react";

export const useCreateTaskStatusPopinContent = () => {
  const roomContext = useContext(RoomContext);
  const roomId = useMemo(() => roomContext?.roomResponse?.room.id, [roomContext?.roomResponse?.room.id]);
  const [name, setName] = useState("");

  const { mutate: createTaskStatus } = useCreateTaskStatusMutation(roomId);

  const addColumn = useCallback(() => {
    if (roomId == null) return;
    createTaskStatus({
      name,
    });
    dispatchCustomWindowEvent(eventNames.CLOSE_TASK_STATUS_CREATION_POPIN);
  }, [roomId, name]);

  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    [setName],
  );

  const handleCancel = useCallback(() => {
    dispatchCustomWindowEvent(eventNames.CLOSE_TASK_STATUS_CREATION_POPIN);
  }, []);

  const handleNameKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === "Enter") {
        addColumn();
      } else if (event.key === "Escape") {
        handleCancel();
      }
    },
    [name],
  );

  return {
    name,
    handleNameChange,
    handleNameKeyDown,
    handleCancel,
    addColumn,
  };
};
