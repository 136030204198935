import { type IUserId } from "@/api/client";
import { AddElementTagComponent } from "@/components/shared/tag/AddElementTagComponent";
import { AssigneUserToTaskPopinComponent } from "@/components/shared/tasks/AssigneUserToTaskPopinComponent/AssigneUserToTaskPopinComponent";
import { TaskAssigneeComponent } from "@/components/shared/tasks/TaskAssigneesComponent/components/TaskAssigneeComponent";
import { useTaskAssignees } from "@/components/shared/tasks/TaskAssigneesComponent/useTaskAssignees";
import { PickUsersPopinComponent } from "@/components/shared/user/PickUsersPopinComponent/PickUsersPopinComponent";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { UserTagComponent } from "@/components/shared/user/UserTagList/UserTagComponent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { FaRegUser as UserIcon } from "react-icons/fa";

interface ITaskAssigneeComponentProps {
  onUserChange?: (assignee: string, isSelected: boolean) => void;
  assignedUsers: IUserId[];
  taskId: string | null;
  roomId: string | null;
  channelId: string | null;
  isDisabled?: boolean;
  size?: "lg" | "md";
  noAssigneeValue?: string;
}

export const TaskAssigneesComponent: FunctionComponent<ITaskAssigneeComponentProps> = ({
  onUserChange,
  assignedUsers,
  roomId,
  channelId,
  taskId,
  isDisabled = false,
  size = "md",
  noAssigneeValue,
}) => {
  const { t } = useTranslation();
  const { isAssigneeTooltipVisible, onAssigneeClick, onAddAssigneeClick, onCloseTooltip, onRemoveAssignee } = useTaskAssignees(taskId, onUserChange);

  if (size === "lg") {
    return (
      <div className="flex w-full flex-col">
        {taskId != null ? (
          <AssigneUserToTaskPopinComponent
            isVisible={isAssigneeTooltipVisible}
            taskId={taskId}
            roomId={roomId}
            channelId={channelId}
            onCloseTooltip={onCloseTooltip}
          >
            <div>
              <AddElementTagComponent value={t("tasks.assignees.add")} onAddElement={onAddAssigneeClick} />
            </div>
          </AssigneUserToTaskPopinComponent>
        ) : (
          <PickUsersPopinComponent
            isVisible={isAssigneeTooltipVisible}
            roomId={roomId}
            channelId={channelId}
            onCloseTooltip={onCloseTooltip}
            onUserSelectionChange={onUserChange ?? (() => {})}
            selectedUserIds={(assignedUsers ?? []).map((user) => user.id)}
          >
            <div>
              <AddElementTagComponent value={t("tasks.assignees.add")} onAddElement={onAddAssigneeClick} />
            </div>
          </PickUsersPopinComponent>
        )}
        {assignedUsers.map((assignedUser) => (
          <UserTagComponent
            key={assignedUser.id}
            userId={assignedUser.id}
            onRemove={() => {
              onRemoveAssignee(assignedUser.id);
            }}
          />
        ))}
      </div>
    );
  }

  const content = (
    <div className={`overflow-visible ${isDisabled ? "" : "cursor-pointer hover:opacity-75"}`} onClick={isDisabled ? undefined : onAssigneeClick}>
      {assignedUsers.length > 1 ? (
        <div className="flex items-center">
          {assignedUsers.slice(0, 3).map((assignedUser) => (
            <UserAvatarComponent key={assignedUser.id} userId={assignedUser.id} size="md" className="mr-0.5 h-5 w-5" />
          ))}
          {assignedUsers.length > 3 ? (
            <div className="ml-1 truncate text-xs font-normal text-zinc-500">
              {t("tasks.assignees.remaining", { count: assignedUsers.length - 3 })}
            </div>
          ) : null}
        </div>
      ) : null}
      {assignedUsers.length === 1 ? <TaskAssigneeComponent userId={assignedUsers[0].id} /> : null}
      {assignedUsers.length === 0 ? (
        <div className="flex flex-row items-center">
          <div className="flex h-5 w-5 items-center justify-center rounded-md border border-dashed border-zinc-500">
            <UserIcon className="text-zinc-500" style={{ fontSize: 10 }} />
          </div>
          <div className="ml-1 text-xs font-normal text-zinc-500">{noAssigneeValue ?? t("tasks.assignee.unassigned")}</div>
        </div>
      ) : null}
    </div>
  );

  if (taskId != null) {
    return (
      <AssigneUserToTaskPopinComponent
        isVisible={isAssigneeTooltipVisible}
        taskId={taskId}
        roomId={roomId}
        channelId={channelId}
        onCloseTooltip={onCloseTooltip}
      >
        {content}
      </AssigneUserToTaskPopinComponent>
    );
  } else {
    return (
      <PickUsersPopinComponent
        isVisible={isAssigneeTooltipVisible}
        roomId={roomId}
        channelId={channelId}
        onCloseTooltip={onCloseTooltip}
        onUserSelectionChange={onUserChange ?? (() => {})}
        selectedUserIds={(assignedUsers ?? []).map((user) => user.id)}
      >
        {content}
      </PickUsersPopinComponent>
    );
  }
};
