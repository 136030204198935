import { useTranslation } from "react-i18next";
import { FiInfo } from "react-icons/fi";

interface IInputLabelProps {
  label: string | JSX.Element;
  name?: string;
  icon?: JSX.Element;
  required?: boolean;
  info?: string;
  requiredText?: string;
  disabled?: boolean;
}

export const InputLabelComponent = ({ name, label, icon, required = false, info, requiredText, disabled = false }: IInputLabelProps): JSX.Element => {
  const { t } = useTranslation();
  const defaultRequiredText = t("general.required");

  return (
    <label
      htmlFor={name}
      className={`mb-1 flex w-full items-center text-[13px] font-normal text-zinc-500 dark:text-dark-300 ${disabled ? "opacity-50" : ""}`}
    >
      {icon != null && <span className="mr-1 h-full text-xs text-gray-500">{icon}</span>}
      {label} {required && <span className="ml-1 text-[13px] font-medium text-zinc-500 opacity-50">{requiredText ?? defaultRequiredText}</span>}
      {info !== undefined && (
        <span className="ml-auto flex items-center text-xs text-gray-300 dark:text-dark-300">
          <FiInfo className="mr-1 h-4 w-4" />
          <span>{info}</span>
        </span>
      )}
    </label>
  );
};
