import { LimitationConfirm } from "@/components/billing/modals/guestLimitation/LimitationConfirm";
import { openModal } from "@/components/shared/modal/ModalService";
import { eventNames } from "@/constants/eventNames";
import { ModalNames } from "@/constants/modalNames";
import { QueryKeys } from "@/constants/queryKeys";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { QuotaType, type ILimitationError } from "@/interfaces/billing";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { useContext } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useInviteMember = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const me = useContext(AuthenticationContext);
  const inviteMemberToOrganisationMutation = useMutation({
    mutationFn: async (data: { email: string }) => {
      if (me?.mainOrganisation == null) return;
      await api.organisations.inviteMember(me?.mainOrganisation?.organisationId, data, {
        defaultToastErrorMessage: t("toasts.organisation.member.add.error"),
      });
      toast.success(t("toasts.organisation.member.add.success"));
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ORGANISATIONS, me?.mainOrganisation?.organisationId, QueryKeys.PENDING] });

      window.dispatchEvent(new CustomEvent(eventNames.RESET_SEARCH));
    },
    onError: (error: AxiosError<ILimitationError>) => {
      if (error?.response?.data.statusCode !== 403) return;
      openModal(
        ModalNames.CONFIRM_LIMITATIONS,
        <LimitationConfirm limit={error?.response?.data.quotaData.quotaLimit ?? 0} scope={QuotaType.MEMBER} />,
      );
    },
  });

  const inviteMemberToRoomMutation = useMutation({
    mutationFn: async (data: { email: string; roomId: string }) => {
      if (me == null) return;
      await api.rooms.inviteMember(data.roomId, data, { defaultToastErrorMessage: t("toasts.room.member.add.error") });
      toast.success(t("toasts.room.member.add.success"));
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, data.roomId] });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, data.roomId, QueryKeys.MEMBERS] });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, data.roomId, QueryKeys.PENDING] });
    },
    onError: (error: AxiosError<ILimitationError>) => {
      if (error?.response?.data.statusCode !== 403) return;
      openModal(
        ModalNames.CONFIRM_LIMITATIONS,
        <LimitationConfirm limit={error?.response?.data.quotaData.quotaLimit ?? 0} scope={QuotaType.GUEST} />,
      );
    },
  });

  const inviteMemberToChannelMutation = useMutation({
    mutationFn: async (data: { roomMemberId: string; channelId: string; conversationId?: string }) => {
      return await api.channels.inviteMember(
        data.channelId,
        { roomMemberId: data.roomMemberId },
        { defaultToastErrorMessage: t("channelModal.tabs.members.toasts.addMember.error") },
      );
    },
    onSuccess: (_, data: { roomMemberId: string; channelId: string; conversationId?: string }) => {
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.CONVERSATIONS, data.conversationId, QueryKeys.MEMBERS] });
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.CHANNELS, data.channelId, QueryKeys.MEMBERS] });
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.CHANNELS, data.channelId] });
      toast.success(t("channelModal.tabs.members.toasts.addMember.success"));
    },
  });
  return { inviteMemberToOrganisationMutation, inviteMemberToRoomMutation, inviteMemberToChannelMutation };
};
