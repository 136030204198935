import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { useEffect, useMemo } from "react";
import { useForm, type SubmitErrorHandler, type SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
// import { canPerform } from "@/utils/utilities";
import { RoleType } from "@/api/client";
import { ConfirmRoleChangeOrDeletionModal } from "@/components/header/profileDropdown/organisationModal/tabs/orgMembers/ConfirmRoleChangeOrDeletionModal";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { closeModal, openModal } from "@/components/shared/modal/ModalService";
import { ModalNames } from "@/constants/modalNames";
import { useCreateChannelMutation } from "@/hooks/mutations/channel/useCreateChannelMutation";
import { useEditChannelMutation } from "@/hooks/mutations/channel/useEditChannelMutation";
import { useLeaveChannelMutation } from "@/hooks/mutations/channel/useLeaveChannelMutation";
import { useChannelQuery } from "@/hooks/queries/channels/useChannelQuery";
import { type IModalAction } from "@/interfaces/modal";
import { canPerform, formatChannelMember } from "@/utils/utilities";
import { format } from "date-fns";
interface ICreateChannelTabInputs {
  name: string;
}
interface IChannelInfoTabComponentProps {
  channelId?: string;
  roomId: string;
  isReadOnly?: boolean;
}
export const ChannelInfoTabComponent = ({ channelId = undefined, roomId, isReadOnly = false }: IChannelInfoTabComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { data: channelResponse, isSuccess } = useChannelQuery(channelId);

  const channel = useMemo(() => channelResponse?.channel, [channelResponse]);

  const { mutate: createChannel, isPending } = useCreateChannelMutation(roomId);
  const { mutate: editChannel } = useEditChannelMutation(roomId, channelId);
  const { mutate: leaveChannel } = useLeaveChannelMutation(roomId, channelId);

  const onLeavePress = async () => {
    closeModal();

    leaveChannel({ archiveForEveryone: true });
  };

  const canUpdateChannel = useMemo(() => {
    // TODO TEMP FIX! REMOVE channel?.name === "general" WHEN THE BACK REMOVES THE ABILITY TO UPDATE GENERAL CHANNEL
    return canPerform(channelResponse?.member.role.abilities, "channel.update") && !(channel?.name === "general");
  }, [channelResponse]);

  const onArchivePress = () => {
    if (channelResponse?.member == null) {
      return;
    }
    openModal(
      ModalNames.CONFIRM_ROLE_CHANGE_OR_DELETION,
      <ConfirmRoleChangeOrDeletionModal
        member={formatChannelMember(channelResponse.member)}
        isDeletion
        isArchive
        action={onLeavePress}
        scope="channel"
      />,
      0.2,
    );
  };

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    register,
    reset,
  } = useForm<ICreateChannelTabInputs>({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: {
      name: useMemo(() => channel?.name ?? "", [channel]),
    },
  });

  useEffect(() => {
    if (channel == null || channelId == null) return;
    reset({ ...channel });
  }, [channel, channelId]);

  const onSubmit: SubmitHandler<ICreateChannelTabInputs> = (data) => {
    if (channel?.id == null) {
      createChannel(data);
    } else {
      editChannel(data);
    }
  };

  const onError: SubmitErrorHandler<ICreateChannelTabInputs> = (err) => {
    console.error(err);
  };

  const actions: IModalAction[] = useMemo(() => {
    return [
      {
        action: handleSubmit(onSubmit, onError),
        label: channel?.id != null && isSuccess ? t("channelModal.tabs.about.buttons.update") : t("channelModal.tabs.about.buttons.create"),
        type: "primary",
        disabled: !isValid || !isDirty,
      },
    ];
  }, [isSuccess, isValid, isDirty, channelId]);

  return (
    <ModalTabComponent
      key={channelId}
      footer={
        <>
          {channelResponse?.member?.role?.type === RoleType.Owner ? (
            <div className="mx-6 my-4">
              <ButtonComponent type="button" status="danger" onClick={onArchivePress}>
                {t("channelModal.tabs.about.archive")}
              </ButtonComponent>
            </div>
          ) : null}
          <ModalFooterComponent
            actions={actions}
            info={
              channel != null
                ? isReadOnly
                  ? t("channelModal.tabs.about.footerInfoDefaultChannel")
                  : t("channelModal.tabs.about.footerInfo", {
                      date: format(new Date(channel?.createdAt ?? ""), t("date.shortFormat")),
                    })
                : t("channelModal.tabs.about.footerInfoNoChannel")
            }
          ></ModalFooterComponent>
        </>
      }
    >
      <form onSubmit={handleSubmit(onSubmit, onError)} className="relative flex w-full flex-col">
        {isPending && (
          <>
            <div className="-ml-6 w-full">
              <LoaderComponent />
            </div>
          </>
        )}
        <div className="flex w-full">
          <div className="flex w-full flex-col justify-start">
            <div>
              <TextInputComponent
                placeholder={t("channelModal.tabs.about.channelPlaceholder")}
                register={register}
                name="name"
                label={channel != null ? t("channelModal.tabs.about.nameLabelDefaultChannel") : t("channelModal.tabs.about.nameLabel")}
                error={errors.name?.message ?? ""}
                prefix="#"
                lowercase
                disabled={!canUpdateChannel && channel != null}
              />
            </div>
          </div>
        </div>
      </form>
    </ModalTabComponent>
  );
};
