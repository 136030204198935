import { type IOrganisation, type IUpdateOrganisation } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useOrganisationMutation = (organisationId: string) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (params: IUpdateOrganisation) => {
      const { data } = await api.organisations.update(organisationId, params);

      return data;
    },
    onSuccess: (newOrganisation) => {
      queryClient.setQueriesData<IOrganisation>(
        {
          exact: false,
          queryKey: [QueryKeys.MY_ORGANISATION],
        },
        (oldData) => {
          if (oldData == null) return;

          return {
            ...oldData,
            ...newOrganisation,
          };
        },
      );

      void queryClient.refetchQueries({
        exact: true,
        queryKey: [QueryKeys.ME],
      });
    },
  });

  return mutation;
};
