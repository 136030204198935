import { JitsiContext } from "@/contexts/JitsiContextProvider";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { useIsElectron } from "@/hooks/shared/useIsElectron";

import { type IJitsiMeetExternalApi } from "@jitsi/react-sdk/lib/types";
import { useContext, useMemo } from "react";
export const useJitsiFrame = () => {
  const jitsiContext = useContext(JitsiContext);
  const { data: me } = useMeQuery();
  const { isElectron } = useIsElectron();

  const { setIsVideoShown, setIsFullScreen, setParticipants } = jitsiContext;
  const onParticipantJoinedOrLeft = (externalApi: IJitsiMeetExternalApi) => {
    /* @ts-expect-error jitsi sdk is badly typed */
    externalApi.getRoomsInfo().then((rooms) => {
      /* @ts-expect-error jitsi sdk is badly typed */
      const mainRoom = rooms.rooms.find((room) => room.isMainRoom);
      setParticipants(mainRoom?.participants ?? []);
    });
  };

  const configOverwrite = {
    startWithAudioMuted: false,
    disableModeratorIndicator: true,
    startScreenSharing: true,
    enableEmailInStats: false,
    enableAutoOwner: true,
    prejoinPageEnabled: false,
  };

  const userInfo = useMemo(
    () => ({
      displayName: `${me?.firstName} ${me?.lastName}`,
      email: "user@getroger.io",
    }),
    [me],
  );

  const interfaceConfigOverwrite = {
    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
  };

  const getIFrameRef = (iFrameRef: HTMLDivElement) => {
    iFrameRef.style.height = "100%";
    iFrameRef.style.width = "100%";
  };

  const onApiReady = async (externalApi: IJitsiMeetExternalApi) => {
    if (isElectron) {
      window.electron.setupRenderer(externalApi);
    }

    externalApi.addListener("participantJoined", () => {
      onParticipantJoinedOrLeft(externalApi);
    });
    externalApi.addListener("participantLeft", () => {
      onParticipantJoinedOrLeft(externalApi);
    });
    externalApi.addListener("videoConferenceJoined", () => {
      onParticipantJoinedOrLeft(externalApi);
    });
    externalApi.addListener("videoConferenceLeft", () => {
      setIsVideoShown(false);
      setIsFullScreen(false);
    });
  };

  return { onApiReady, getIFrameRef, configOverwrite, interfaceConfigOverwrite, userInfo };
};
