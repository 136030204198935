import { ConfirmModalComponent } from "@/components/shared/modal/ConfirmModalComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { ModalNames } from "@/constants/modalNames";
import { useOrganisationMutation } from "@/hooks/mutations/organisations/useOrganisationMutation";
import { useRoomMutation } from "@/hooks/mutations/rooms/useRoomMutation";
import { useMyOrganisationQuery } from "@/hooks/queries/organisations/useMyOrganisationQuery";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useOrganisationSettingsTab = (organisationId: string) => {
  const { data: myOrganisation } = useMyOrganisationQuery();
  const { t } = useTranslation();
  const { mutate: mutateRoomCreation } = useOrganisationMutation(organisationId);
  const { mutate: mutateIsRoomHidden } = useRoomMutation(myOrganisation?.organisationalRoom?.id, myOrganisation?.organisationalRoom, true);

  const isRoomCreationEnabled = useMemo(() => {
    if (myOrganisation == null) return false;
    return myOrganisation.canCreateRoom;
  }, [myOrganisation]);

  const isOrgRoomHiddenEnabled = useMemo(() => {
    return !(myOrganisation?.organisationalRoom?.isHidden ?? false);
  }, [myOrganisation?.organisationalRoom?.isHidden]);

  const updateRoomCreation = useCallback(async (canCreateRoom: boolean) => {
    mutateRoomCreation({ canCreateRoom });
  }, []);

  const updateRoomHidden = useCallback(async (isHidden: boolean) => {
    mutateIsRoomHidden({ isHidden });
  }, []);

  const disableRoomCreation = useCallback(async () => {
    await updateRoomCreation(false);
  }, []);

  const hideOrgRoom = useCallback(async () => {
    await updateRoomHidden(true);
  }, []);

  const onToggleRoomCreationToggle = useCallback(
    (isSettingEnabled: boolean) => {
      if (isSettingEnabled) {
        void updateRoomCreation(true);
        return;
      }

      openModal(
        ModalNames.CONFIRM_ROOM_CREATION_DISABLING,
        <ConfirmModalComponent
          size="sm"
          name={ModalNames.CONFIRM_ROOM_CREATION_DISABLING}
          title={t("createRoomModal.tabs.organisationSettings.permissions.enableRoomCreation.confirm.title")}
          description={t("createRoomModal.tabs.organisationSettings.permissions.enableRoomCreation.confirm.description")}
          action={disableRoomCreation}
          actionType="primary"
          actionLabel={t("createRoomModal.tabs.organisationSettings.permissions.enableRoomCreation.confirm.button")}
        />,
      );
    },
    [organisationId],
  );

  const onOrgRoomHiddenToggle = useCallback(
    (isSettingEnabled: boolean) => {
      if (isSettingEnabled) {
        void updateRoomHidden(false);
        return;
      }

      openModal(
        ModalNames.CONFIRM_ORG_ROOM_DISABLING,
        <ConfirmModalComponent
          size="sm"
          name={ModalNames.CONFIRM_ORG_ROOM_DISABLING}
          title={t("createRoomModal.tabs.organisationSettings.permissions.orgRoomDisplay.confirm.title")}
          description={t("createRoomModal.tabs.organisationSettings.permissions.orgRoomDisplay.confirm.description")}
          action={hideOrgRoom}
          actionType="primary"
          actionLabel={t("createRoomModal.tabs.organisationSettings.permissions.orgRoomDisplay.confirm.button")}
        />,
      );
    },
    [organisationId],
  );

  return { onToggleRoomCreationToggle, isRoomCreationEnabled, onOrgRoomHiddenToggle, isOrgRoomHiddenEnabled };
};
