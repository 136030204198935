import { useCallback } from "react";

import { type Task } from "@/interfaces/task";
import { useNavigate } from "react-router-dom";

export const useNavigateToTask = () => {
  const navigate = useNavigate();
  const navigateToTask = useCallback((task: Task) => {
    const roomId = task.channel?.room?.id;
    if (roomId == null) return;
    navigate(`/dashboard/rooms/${roomId}/tasks/${task.id}`);
  }, []);
  return { navigateToTask };
};
