import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { useUpdateTaskMutation } from "@/hooks/mutations/tasks/useUpdateTaskMutation";
import { useTaskQuery } from "@/hooks/queries/tasks/useTaskQuery";
import { useEffect, useState, type ChangeEventHandler, type FocusEventHandler, type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface TaskTitleComponentProps {
  taskId: string;
}

export const TaskTitleComponent: FunctionComponent<TaskTitleComponentProps> = ({ taskId }) => {
  const { t } = useTranslation();
  const { data: task } = useTaskQuery(taskId);
  const [title, setTitle] = useState<string>(task?.title ?? "");
  const [errors, setErrors] = useState<Record<string, { message: string } | undefined>>({});
  const { mutate: updateTask } = useUpdateTaskMutation();

  useEffect(() => {
    if (task != null) {
      setTitle(task.title);
    }
  }, [task]);

  const onTitleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setTitle(event.target.value);
  };

  const onTitleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const title = event.target.value;

    if (task == null || title === task.title) {
      return;
    }

    if (title.length === 0) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        title: {
          message: t("editTaskModal.fields.title.required"),
        },
      }));
      return;
    } else {
      setErrors((previousErrors) => ({
        ...previousErrors,
        title: undefined,
      }));
    }

    updateTask({
      taskId,
      title,
    });
  };

  return (
    <TextInputComponent
      name="title"
      type="text"
      label={t("editTaskModal.fields.title.label")}
      error={errors.title?.message}
      onBlur={onTitleBlur}
      multiline
      maxLength={90}
      value={title}
      onChange={onTitleChange}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }}
    />
  );
};
