import { type IRoomIdName } from "@/api/client";
import { RoomPickerPopinContentComponent } from "@/components/shared/room/RoomPickerPopinComponent/RoomPickerPopinContentComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { type TippyProps } from "@tippyjs/react";
import { type FunctionComponent } from "react";

interface IRoomPickerPopinComponentProps {
  children: TippyProps["children"];
  isVisible: boolean;
  onCloseTooltip: () => void;
  onRoomSelected: (room: IRoomIdName | null) => void;
  hasRemoveRoomOption?: boolean;
}

export const RoomPickerPopinComponent: FunctionComponent<IRoomPickerPopinComponentProps> = ({
  children,
  isVisible,
  onCloseTooltip,
  onRoomSelected,
  hasRemoveRoomOption,
}) => {
  return (
    <TooltipComponent
      placement="bottom-start"
      visible={isVisible}
      onClickOutside={onCloseTooltip}
      arrow={false}
      interactive
      noPadding
      content={<RoomPickerPopinContentComponent onRoomSelected={onRoomSelected} hasRemoveRoomOption={hasRemoveRoomOption} isVisible={isVisible} />}
    >
      {children}
    </TooltipComponent>
  );
};
