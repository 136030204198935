import { useQueryClient, type InfiniteData } from "@tanstack/react-query";
import { useCallback, useContext, useEffect } from "react";

import { type IEmail, type IEmailBadge, type IEmailThread, type IEmailThreadAllResponse } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";

export const useEmailsSocket = () => {
  const socket = useContext(SocketContext);

  const queryClient = useQueryClient();

  const updateThreadsInCache = useCallback(
    (roomId: string, thread: IEmailThread) => {
      queryClient.setQueryData<InfiniteData<IEmailThreadAllResponse>>([QueryKeys.EMAIL_THREADS, roomId], (oldData) => {
        if (oldData == null) {
          return oldData;
        }

        const newPages = oldData.pages.map((page, index) => {
          if (index === 0) {
            if (page.data.some((oldThread) => oldThread.id === thread.id)) return page;
            return { ...page, data: [thread, ...page.data] };
          }
          return page;
        });
        return { ...oldData, pages: newPages };
      });
    },
    [queryClient],
  );

  const updateThreadInCache = useCallback((thread: IEmailThread) => {
    queryClient.setQueryData<IEmailThread>([QueryKeys.EMAIL_THREAD, thread.id], (oldData) => {
      if (oldData == null) {
        return thread;
      }

      return { ...oldData, ...thread };
    });
  }, []);

  const onNewEmail = useCallback(({ email, roomId, thread }: { email: IEmail; roomId: string; thread: IEmailThread }) => {
    updateThreadsInCache(roomId, thread);
    updateThreadInCache(thread);

    queryClient.setQueryData<IEmail[]>([QueryKeys.EMAIL_THREAD, QueryKeys.EMAILS, thread.id], (oldData) => {
      if (oldData == null) {
        return oldData;
      }

      if (oldData.some((oldEmail) => oldEmail.id === email.id)) {
        return oldData;
      }

      return [...oldData, email];
    });
  }, []);

  const onEmailBadgeUpdated = useCallback(({ roomId, badge }: { roomId: string; badge: IEmailBadge }) => {
    queryClient.setQueryData<number>([QueryKeys.EMAIL_THREADS, QueryKeys.EMAILS_BADGE, roomId], (oldData) => {
      if (oldData == null) {
        return oldData;
      }

      return badge.value;
    });
  }, []);

  const onEmailThreadUpdated = useCallback(({ roomId, thread }: { roomId: string; thread: IEmailThread }) => {
    updateThreadsInCache(roomId, thread);
    updateThreadInCache(thread);
  }, []);

  useEffect(() => {
    if (socket == null) return;
    socket.on(SocketEvents.NEW_EMAIL, onNewEmail);
    socket.on(SocketEvents.EMAIL_BADGE_UPDATED, onEmailBadgeUpdated);
    socket.on(SocketEvents.EMAIL_THREAD_UPDATED, onEmailThreadUpdated);

    return () => {
      if (socket == null) return;
      socket.off(SocketEvents.NEW_EMAIL, onNewEmail);
      socket.off(SocketEvents.EMAIL_BADGE_UPDATED, onEmailBadgeUpdated);
      socket.off(SocketEvents.EMAIL_THREAD_UPDATED, onEmailThreadUpdated);
    };
  }, [socket]);
};
