import { useConversation } from "@/components/roomPage/tabs/conversation/useConversation";
import { useEnterAndLeaveContext } from "@/hooks/shared/useEnterAndLeaveContext";
import { useCallback, type HTMLAttributes } from "react";
import { Virtuoso } from "react-virtuoso";

interface IConversationProps extends HTMLAttributes<HTMLDivElement> {
  contextType: string;
  contextId: string;
  Header?: JSX.Element;
  isComment?: boolean;
}

export const ConversationComponent = ({ contextType, contextId, Header, isComment = false }: IConversationProps): JSX.Element => {
  const { containerRef, firstItemIndex, messages, itemContent, virtuosoRef, VIEWPORT_INCREASE, tryToFetchPage, initialIndex, followOutput } =
    useConversation(contextType, contextId, isComment);
  useEnterAndLeaveContext(contextType, contextId);

  const HeaderComponent = useCallback(() => {
    return Header;
  }, [Header]);
  return (
    <div ref={containerRef} className={`conversation-${contextId} relative flex flex-1 opacity-0 transition-all duration-200`}>
      {messages != null && (
        <>
          <Virtuoso
            style={{ flex: "1 0 auto" }}
            increaseViewportBy={{ top: VIEWPORT_INCREASE, bottom: VIEWPORT_INCREASE }}
            firstItemIndex={firstItemIndex}
            initialTopMostItemIndex={contextType !== "taskComment" ? { index: initialIndex, align: "center" } : { index: 0, align: "end" }}
            data={messages}
            startReached={() => {
              void tryToFetchPage("previous");
            }}
            endReached={() => {
              void tryToFetchPage("next");
            }}
            className="message-list"
            itemContent={itemContent}
            ref={virtuosoRef}
            totalCount={messages.length}
            alignToBottom={contextType !== "message" && contextType !== "taskComment"}
            followOutput={followOutput}
            atBottomThreshold={12} // 12px for the bottom padding (pb-3 in the footer)
            components={{
              Footer: () => {
                return <div className="pb-3"></div>;
              },
              Header: HeaderComponent,
            }}
          />
        </>
      )}
    </div>
  );
};
