import { type ITask } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDeleteTaskMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (taskId: string) => {
      const { data } = await api.tasks.deleteOne(taskId);

      return data;
    },
    onSuccess: (_, taskId) => {
      queryClient.setQueriesData<ITask[]>(
        {
          exact: false,
          queryKey: [QueryKeys.TASKS],
        },
        (previousData) => previousData?.filter((task) => task.id !== taskId),
      );
    },
  });

  return mutation;
};
