import { CreateTaskStatusPopinContentComponent } from "@/components/shared/tasks/CreateTaskStatusPopinComponent/CreateTaskStatusPopinContentComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { type TippyProps } from "@tippyjs/react";
import { type FunctionComponent } from "react";
interface ICreateTaskStatusColumnPopinComponent {
  children: TippyProps["children"];
  isVisible: boolean;
  onCloseTooltip: () => void;
}

export const CreateTaskStatusPopinComponent: FunctionComponent<ICreateTaskStatusColumnPopinComponent> = ({ children, isVisible, onCloseTooltip }) => {
  return (
    <TooltipComponent
      placement="bottom-end"
      visible={isVisible}
      onClickOutside={onCloseTooltip}
      arrow={false}
      interactive
      noPadding
      content={<CreateTaskStatusPopinContentComponent />}
    >
      {children}
    </TooltipComponent>
  );
};
