import { type TFunction } from "i18next";
import * as Yup from "yup";
import type Reference from "yup/es/Reference";
import { type RequiredStringSchema } from "yup/lib/string";
import { type AnyObject } from "yup/lib/types";

export const basicPasswordValidation = (t: TFunction): RequiredStringSchema<string | undefined, AnyObject> =>
  Yup.string().required(
    t("forms.formValidation.errors.required", {
      field: t("forms.fields.password"),
    }),
  );

export const passwordValidation = (t: TFunction): RequiredStringSchema<string | undefined, AnyObject> =>
  Yup.string()
    .required(
      t("forms.formValidation.errors.required", {
        field: t("forms.fields.password"),
      }),
    )
    .matches(
      /([a-z])/,
      t("forms.formValidation.errors.not_contains_lowercase", {
        field: t("forms.fields.password"),
      }),
    )
    .matches(
      /(\d)/,
      t("forms.formValidation.errors.not_contains_integer", {
        field: t("forms.fields.password"),
      }),
    )
    .matches(
      /([A-Z])/,
      t("forms.formValidation.errors.not_contains_uppercase", {
        field: t("forms.fields.password"),
      }),
    )
    .matches(
      /[!#$%&'()*+,-./:;<=>?@[\]^_{|}~]/,
      t("forms.formValidation.errors.not_contains_special_character", {
        field: t("forms.fields.password"),
      }),
    )
    .min(
      9,
      t("forms.formValidation.errors.inferior_than_minimum_length", {
        field: t("forms.fields.password"),
        value: 9,
      }),
    )
    .max(
      200,
      t("forms.formValidation.errors.superior_than_maximum_length", {
        field: t("forms.fields.password"),
        value: 200,
      }),
    );

export const passwordConfirmationValidation = (t: TFunction, password: Reference<unknown>): RequiredStringSchema<string | undefined, AnyObject> =>
  Yup.string()
    .required(
      t("forms.formValidation.errors.required", {
        field: t("forms.fields.passwordConfirmation"),
      }),
    )
    .oneOf([password, null], t("forms.formValidation.errors.passwords_not_match"));

export const emailValidation = (t: TFunction, defaultEmail?: string): RequiredStringSchema<string | undefined, AnyObject> =>
  Yup.string()
    .email(t("forms.formValidation.errors.notAnEmail"))
    .required(
      t("forms.formValidation.errors.required", {
        field: t("forms.fields.email"),
      }),
    )
    .default(defaultEmail);

export const nameValidation = (t: TFunction, field: string): RequiredStringSchema<string | undefined, AnyObject> =>
  Yup.string().required(t("forms.formValidation.errors.required", { field }));
