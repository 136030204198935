import { QueryKeys } from "@/constants/queryKeys";
import { type Task } from "@/interfaces/task";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useRemoveAssigneeMutation = (taskId: string) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (userId: string) => {
      await api.tasks.deleteTaskAssignedUser(taskId, userId);
    },
    onMutate: (userId) => {
      queryClient.setQueriesData<Task[]>(
        {
          exact: false,
          queryKey: [QueryKeys.TASKS],
        },
        (oldData) =>
          oldData?.map((task) =>
            task.id !== taskId
              ? task
              : {
                  ...task,
                  assignedUsers: [...(task.assignedUsers ?? []).filter((user) => user.id !== userId)],
                },
          ),
      );
      queryClient.setQueryData<Task>([QueryKeys.TASK, taskId], (previousData) => {
        if (previousData == null) {
          return previousData;
        }

        return {
          ...previousData,
          assignedUsers: [...(previousData.assignedUsers ?? []).filter((user) => user.id !== userId)],
        };
      });
    },
  });

  return mutation;
};
