import React, { useState, type FunctionComponent } from "react";

interface SegmentItem {
  title: string;
  icon: JSX.Element;
}
interface ISegmentControlProps {
  items: SegmentItem[];
  onSelection: (index: number) => void;
  selectedIndex?: number;
}

export const SegmentControl: FunctionComponent<ISegmentControlProps> = ({ items, onSelection, selectedIndex = 0 }) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  return (
    <div className="flex flex-row items-start gap-[4px]">
      {items.map((tab, index) => {
        const isHovered = hoveredIndex === index || selectedIndex === index;
        const iconSize = index === 0 ? "h-[16px] w-[16px]" : " h-[14px] w-[14px]";
        const iconStrokeFill = isHovered ? (index === 0 ? "fill-sky-600" : "fill-sky-600") : index === 0 ? "fill-[#838282]" : "fill-[#838282]";
        const textColor = isHovered ? "text-sky-600" : "text-[#838282]";

        return (
          <div
            onClick={() => {
              onSelection(index);
            }}
            style={{ cursor: "pointer" }}
            key={index}
            className={`flex flex-row items-center gap-x-[4px] rounded-[5px] px-[10px] py-[7px] ${
              isHovered ? "bg-[#E4EDFF] dark:bg-[rgba(255,255,255,0.1)] " : ""
            }`}
            onMouseEnter={() => {
              setHoveredIndex(index);
            }}
            onMouseLeave={() => {
              setHoveredIndex(null);
            }}
          >
            {React.cloneElement(tab.icon, {
              className: `${iconSize} ${iconStrokeFill}`,
            })}
            <div className={`hidden text-sm font-semibold sm:block ${textColor}`}>{tab.title}</div>
          </div>
        );
      })}
    </div>
  );
};
