import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { type SimpleUser } from "@/interfaces/user";
import { type FunctionComponent, type MouseEventHandler } from "react";

interface IUserItemComponentProps {
  user: SimpleUser;
  onClick: MouseEventHandler<HTMLDivElement>;
  RightIcon?: JSX.Element | null;
  hasEmail?: boolean;
}

export const UserItemComponent: FunctionComponent<IUserItemComponentProps> = ({ user, onClick, RightIcon, hasEmail = false }) => {
  const fullName = `${user?.firstName ?? ""} ${user?.lastName ?? ""}`;
  return (
    <div
      className="flex cursor-pointer flex-row items-center justify-between border-b px-2 py-1 transition-all hover:bg-sky-500 hover:text-white"
      onClick={onClick}
    >
      <div className="flex w-60 items-center">
        <UserAvatarComponent userId={user.id} size="xs" userName={fullName} className="mr-1 h-5 w-5" />

        <div className="truncate text-xs dark:text-dark-300">
          {fullName}
          {hasEmail ? ` (${user.email})` : ""}
        </div>
      </div>

      {RightIcon ?? null}
    </div>
  );
};
