import { QueryKeys } from "@/constants/queryKeys";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

export const useConversationQueryKey = (contextType: string, contextId: string, cursorId: string | undefined) => {
  const [searchParams] = useSearchParams();

  const { parentMessageId } = useParams<{ parentMessageId: string }>();

  const messageId = useMemo(() => {
    if (contextType === "message") {
      return searchParams.get("messageId") ?? cursorId;
    }
    return parentMessageId ?? searchParams.get("messageId") ?? cursorId;
  }, [searchParams, parentMessageId, cursorId]);

  const queryKeys = useMemo(() => {
    if (contextType == null || contextId == null) return [];
    const context = contextType === "message" ? QueryKeys.MESSAGE : QueryKeys.CONVERSATION;
    if (messageId == null) return [context, contextId, QueryKeys.MESSAGES];
    return [context, contextId, QueryKeys.MESSAGES, messageId];
  }, [contextType, contextId, messageId]);

  return { queryKeys };
};
