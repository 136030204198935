import { type IFile } from "@/api/client";
import { PdfViewerControlPanelComponent } from "@/components/shared/documentViewer/pdfViewer/PdfViewerControlPanelComponent";
import { usePdfViewer } from "@/components/shared/documentViewer/pdfViewer/usePdfViewer";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Portal } from "react-portal";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();
interface IPdfPreviewInMessageComponent {
  file: IFile;
  isVisible: boolean;
  onClose: () => void;
}

export const PdfViewerComponent = ({ file, isVisible, onClose }: IPdfPreviewInMessageComponent) => {
  const { scale, setScale, totalPages, currentPage, setCurrentPage, onDocumentLoadSuccess, showEveryPages } = usePdfViewer();

  if (!isVisible || file.url == null) {
    return null;
  }

  return (
    <Portal>
      <div className="absolute inset-0 z-[100] h-full w-full " onClick={onClose}></div>
      <div className="absolute inset-20 z-[101] flex select-none flex-col items-center justify-start overflow-hidden rounded-lg bg-black/50 shadow-md backdrop-blur-lg ">
        <PdfViewerControlPanelComponent
          scale={scale}
          setScale={setScale}
          file={file}
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          hidePageNavigation={showEveryPages(file)}
        />
        <section className="relative mb-6 flex h-full w-full flex-col items-center justify-start overflow-scroll">
          <Document file={file.url} onLoadSuccess={onDocumentLoadSuccess} loading={<></>} className="relative">
            {!showEveryPages(file) ? (
              <Page pageNumber={currentPage} scale={scale} loading={<></>} className="mb-3" />
            ) : (
              Array.from(new Array(totalPages), (page, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} loading={<></>} className="mb-3" />
              ))
            )}
          </Document>
        </section>
        <p className="mb-2 text-center text-sm text-white">{file.name}</p>
      </div>
    </Portal>
  );
};
