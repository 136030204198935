import { type ITag } from "@/api/client";
import { TagInputComponent } from "@/components/shared/tagInput/TagInputComponent";
import { TagInputTagComponent } from "@/components/shared/tagInput/TagInputTagComponent";
import { type FunctionComponent } from "react";

interface ITaskTagFilterComponentProps {
  roomId?: string;
  filteredTags: ITag[];
  onFilterTagChange: (tags: ITag) => void;
}
export const TaskTagFilterComponent: FunctionComponent<ITaskTagFilterComponentProps> = ({ roomId, filteredTags, onFilterTagChange }) => {
  return (
    <>
      <TagInputComponent defaultTags={filteredTags} roomId={roomId ?? ""} isFilter onFilterChange={onFilterTagChange} />
      {filteredTags?.map((tag) => (
        <div key={tag.id} className="space-x-1">
          <TagInputTagComponent tag={tag} roomId={roomId ?? ""} onFilterChange={onFilterTagChange} />
        </div>
      ))}
    </>
  );
};
