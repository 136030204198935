import { type DeltaContent } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useEnhanceMessageQuery = (initialText: DeltaContent) => {
  return useQuery({
    queryKey: [QueryKeys.ENHANCE_BY_AI, initialText],
    queryFn: async () => {
      const { data } = await api.improveText.improveText({ content: initialText });

      return data.data;
    },
  });
};
