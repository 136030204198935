import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect } from "react";

import { type IApiRoomGetOne, type IRoomMember } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";

export const useRoomsSocket = () => {
  const socket = useContext(SocketContext);
  const queryClient = useQueryClient();

  const onRoomMemberUpdated = useCallback(
    async (roomMember: IRoomMember) => {
      queryClient.setQueryData<IRoomMember[]>([QueryKeys.ROOMS, roomMember.roomId, QueryKeys.MEMBERS], (oldData) => {
        if (oldData == null) {
          return oldData;
        }

        return oldData.map((currentRoomMember) => (currentRoomMember.id === roomMember.id ? roomMember : currentRoomMember));
      });
      queryClient.setQueryData<IApiRoomGetOne>([QueryKeys.ROOMS, roomMember.roomId], (oldData) => {
        if (oldData == null) {
          return oldData;
        }
        return {
          ...oldData,
          member: roomMember,
        };
      });
    },

    [queryClient],
  );

  useEffect(() => {
    if (socket == null) return;
    socket.on(SocketEvents.ROOM_MEMBER_UPDATED, onRoomMemberUpdated);
    return () => {
      socket.off(SocketEvents.ROOM_MEMBER_UPDATED, onRoomMemberUpdated);
    };
  }, [socket]);
};
