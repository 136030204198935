import { type IAuthBaseResponse, type IBadRequestError, type IUnauthorizedError } from "@/api/client";
import GoogleLogo from "@/assets/images/logo-google.svg?react";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { useGoogleSignIn } from "@/hooks/authentification/useGoogleSignIn";
import { useGoogleLoginMutation } from "@/hooks/mutations/google/useGoogleLoginMutation";
import { login, setTokens } from "@/services/AuthService";
import { handleIncomingError } from "@/services/ErrorService";
import { useSignInStore } from "@/stores/signInStore";
import { basicPasswordValidation, emailValidation } from "@/utils/formValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import { type AxiosError } from "axios";
import { useState } from "react";
import { useForm, type SubmitErrorHandler, type SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

interface ISignFormInputs {
  email: string;
  password: string;
  phone: string;
}

export const SignInForm = (): JSX.Element => {
  const { t } = useTranslation();
  const { setEmail } = useSignInStore();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { mutate: googleSignIn, isPending: isGoogleLoginLoading } = useGoogleLoginMutation();

  const handleLogin = (responseLogin: IAuthBaseResponse) => {
    setTokens(responseLogin);
    if (responseLogin.user?.mainOrganisation?.status === "pending_request") {
      navigate("/register/complete/pending");
      return;
    }
    navigate("/dashboard/");
  };

  const { signIn } = useGoogleSignIn({
    onSuccess: (code) => {
      googleSignIn(
        {
          code,
          hasRedirectUri: false,
        },
        { onSuccess: handleLogin },
      );
    },
    onAppOpenedWithScheme: (code) => {
      googleSignIn(
        {
          code,
          hasRedirectUri: true,
        },
        { onSuccess: handleLogin },
      );
    },
  });

  const inputs = [
    {
      name: "email",
      label: t("forms.fields.email"),
      type: "email",
      autoComplete: "on",
      requiredText: "",
      autoFocus: true,
    },
    {
      name: "password",
      label: t("forms.fields.password"),
      type: "password",
      autoComplete: "on",
      requiredText: "",
      shouldShowInfo: false,
    },
  ];
  const validationSchema = Yup.object().shape({
    email: emailValidation(t),
    password: basicPasswordValidation(t),
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm<ISignFormInputs>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const onSubmit: SubmitHandler<ISignFormInputs> = async (data) => {
    try {
      setIsLoading(true);
      const responseLogin = await login(data);

      handleLogin(responseLogin);
    } catch (error) {
      if (error == null || !(error as AxiosError<IBadRequestError | IUnauthorizedError>).isAxiosError) {
        return;
      }

      const axiosError = error as AxiosError<IBadRequestError | IUnauthorizedError>;

      const statusCode = axiosError?.response?.data?.statusCode ?? null;
      if (statusCode === 401) {
        setErrorMessage(t("authentication.signIn.error"));
      } else {
        await handleIncomingError(axiosError, t, true);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const onError: SubmitErrorHandler<ISignFormInputs> = (err) => {
    // TODO use global error handler
    console.error(err);
  };
  return (
    <>
      <ButtonComponent
        type="button"
        icon={<GoogleLogo />}
        status="secondary"
        className="mb-4 w-full"
        align="center"
        onClick={signIn}
        loading={isGoogleLoginLoading}
      >
        {t("authentication.signIn.google")}
      </ButtonComponent>
      <div className="mb-2 flex flex-row items-center">
        <div className="h-[1px] flex-1 bg-zinc-500"></div>
        <span className="mx-4 text-sm font-normal text-zinc-500">{t("authentication.signIn.separator")}</span>
        <div className="h-[1px] flex-1 bg-zinc-500"></div>
      </div>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        {/* <button */}
        {/*  icon={<FcGoogle />} */}
        {/*  onClick={openExampleModal} */}
        {/*  iconPosition={"left"} */}
        {/*  type={"button"} */}
        {/*  status={"neutral"} */}
        {/*  className={"w-full"} */}
        {/*  align={"center"} */}
        {/* > */}
        {/*  {t("authentication.signInWithGoogle")} */}
        {/* </button> */}
        {/* <div className="separator w-10/12"> */}
        {/*  <span className="text-gray-300 text-sm">{t("general.or")}</span> */}
        {/* </div> */}

        {inputs.map((input) => (
          <TextInputComponent
            key={`cti-${input.name}`}
            register={register}
            name={input.name}
            error={errors[input.name as keyof typeof errors]?.message}
            type={input.type}
            label={input.label}
            shouldShowInfo={input.shouldShowInfo}
          />
        ))}
        <div className="-mt-4 mb-4 ml-auto flex items-center justify-end text-xs">
          <Link
            to="/password/forgot"
            className="hover:opacity-80 dark:text-white"
            onClick={() => {
              setEmail(getValues("email"));
            }}
          >
            {t("authentication.signIn.forgotPassword")}
          </Link>
        </div>
        <ButtonComponent type="submit" status="primary" className="w-full" align="center" loading={isLoading} disabled={!isValid}>
          {t("authentication.signIn.signIn")}
        </ButtonComponent>
      </form>
      {errorMessage != null ? <div className="mt-5 text-center text-sm text-red-500">{errorMessage}</div> : null}
    </>
  );
};
