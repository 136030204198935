import { type ITag } from "@/api/client";
import { useTagInputTag } from "@/components/shared/tagInput/useTagInputTag";
import { TAGS_COLORS, TAGS_DEFAULT_COLOR } from "@/constants/task";
import { type FunctionComponent } from "react";
import { BiPencil } from "react-icons/bi";
import { CgClose } from "react-icons/cg";

interface ITagInputTagComponentProps {
  tag: ITag;
  onClick?: (tag: ITag) => void;
  inList?: boolean;
  isSelected?: boolean;
  taskId?: string;
  roomId: string;
  size?: "sm" | "md";
  onFilterChange?: (tag: ITag) => void;
  isFilter?: boolean;
}

export const TagInputTagComponent: FunctionComponent<ITagInputTagComponentProps> = ({
  taskId,
  tag,
  onClick,
  inList = false,
  isSelected,
  roomId,
  size = "md",
  onFilterChange,
  isFilter = false,
}) => {
  const { handleClickOnTag, handleClickOnDelete, handleEditClick, handleCheckboxChange, sizeClass } = useTagInputTag(
    tag,
    roomId,
    inList,
    size,
    onClick,
    taskId,
    onFilterChange,
  );

  return (
    <article className="flex items-center">
      <div className={`flex w-full items-center space-x-2 ${!inList ? "cursor-default" : "not:last:cursor-pointer mb-1"}`}>
        {inList ? <input id={tag.id} type="checkbox" checked={isSelected === true} onChange={handleCheckboxChange} /> : null}
        <div
          onClick={handleClickOnTag}
          className={`mr-1 flex items-center space-x-1 rounded hover:opacity-80 ${inList ? " w-auto" : "w-max"} ${sizeClass}`}
          key={tag.id}
          style={TAGS_COLORS[tag.color ?? TAGS_DEFAULT_COLOR]}
        >
          <span>{tag.name}</span>
          {!inList && size === "md" ? (
            <button type="button" onClick={handleClickOnDelete}>
              <CgClose className="h-3 w-3"></CgClose>
            </button>
          ) : null}
        </div>
      </div>
      {inList && !isFilter ? (
        <button type="button" className="ml-auto cursor-pointer" onClick={handleEditClick}>
          <BiPencil className="h-5 w-5 text-sky-800" />
        </button>
      ) : null}
    </article>
  );
};
