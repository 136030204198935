import { type IMyPinsResponse, type IMyPinsSearchBody } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useInfiniteQuery } from "@tanstack/react-query";

export const usePinsQuery = () => {
  return useInfiniteQuery<IMyPinsResponse>({
    queryKey: [QueryKeys.ME, QueryKeys.PINS],
    queryFn: async ({ pageParam = "" }) => {
      const body: IMyPinsSearchBody = {
        count: 20,
      };
      if (pageParam !== "") {
        body.cursor = pageParam as string;
      }

      const response = await api.my.getPins(body);

      return response.data;
    },
    getPreviousPageParam: (firstPage: IMyPinsResponse) => {
      return firstPage?.pagination.nextCursor ?? undefined;
    },
    getNextPageParam: () => {},
    staleTime: 0,
    initialPageParam: "",
  });
};
