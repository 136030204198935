import { EmailListComponent } from "@/components/roomPage/tabs/emails/EmailListComponent";
import { SigninButtonComponent } from "@/components/roomPage/tabs/emails/SigninButtonComponent";
import { ThreadListComponent } from "@/components/roomPage/tabs/emails/ThreadListComponent";
import { useEmailsTab } from "@/components/roomPage/tabs/emails/useEmailsTab";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { EmailThreadContextProvider } from "@/contexts/EmailThreadContextProvider";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineMarkEmailRead as EmailIcon } from "react-icons/md";

export const EmailsTabComponent: FunctionComponent = () => {
  const { t } = useTranslation();
  const { onGoogleLogin, flatThreads, isLoading, fetchPreviousPage, openedThreadId, isConnected, shouldShowEmails } = useEmailsTab();

  if (!shouldShowEmails) {
    return null;
  }

  return (
    <section className="relative flex w-full flex-col" key={isConnected ? "connected" : "disconnected"}>
      {isLoading ? (
        <LoaderComponent className="bg-white" />
      ) : !isConnected ? (
        <SigninButtonComponent onClick={onGoogleLogin} />
      ) : (
        <>
          <div className="relative flex h-full w-full flex-row">
            {openedThreadId != null ? (
              <>
                <ThreadListComponent threads={flatThreads} fetchPreviousPage={fetchPreviousPage} openedThreadId={openedThreadId} />
                <EmailThreadContextProvider threadId={openedThreadId} key={openedThreadId}>
                  <EmailListComponent />
                </EmailThreadContextProvider>
              </>
            ) : (
              <div className="flex w-full items-center justify-center">
                <div className="flex flex-col items-center text-sm text-zinc-400">
                  <EmailIcon className="h-10 w-10" />
                  <span>{t("roomPage.tabs.mails.noEmail")}</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
};
