export const eventNames = {
  RESET_SEARCH: "resetSearch",
  OPEN_MODAL: "openModal",
  CLOSE_MODAL: "closeModal",
  CHANNEL_CREATED: "channelCreated",
  ROOM_CREATED: "roomCreated",
  TOGGLE_CHANNEL_LIST: "toggleChannelList",
  OPEN_THREAD: "openThread",
  NEW_MESSAGE: "newMessage",
  CLOSE_EDITION: "closeEdition",
  CLOSE_THREAD: "closeThread",
  MENTION_HOVERED: "mention-hovered",
  SCROLL_TO_BOTTOM: "scrollToBottom",
  FILE_DATA_UPDATED: "fileIdsUpdated",
  FILE_UPLOAD_STATUS_UPDATED: "fileUploadStatusUpdated",
  RESET_MESSAGE_FILE: "resetMessageFile",
  OPEN_UPDATE_MODAL: "openUpdateModal",
  CLOSE_DOCUMENT_VIEWER: "closeDocumentViewer",
  ROOM_PICTURE_UPDATED: "roomPictureUpdated",
  REPLY_TO_MESSAGE: "replyToMessage",
  RESET_REPLY: "resetReply",
  SELECT_CHANNEL_STATUS: "selectChannelStatus",
  CHANGE_MODAL_TITLE: "changeModalTitle",
  GOTO_NEXT_TAB: "gotoNextTab",
  CLOSE_SEARCH: "closeSearch",
  CHECK_ALL_CHECKBOXES: "checkAllCheckboxes",
  REMOVE_INVITE: "removeInvite",
  CLOSE_TASK_STATUS_POPIN: "closeTaskStatusPopin",
  CLOSE_TASK_STATUS_CREATION_POPIN: "closeTaskStatusCreationPopin",
};
