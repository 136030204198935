import { useTranslation } from "react-i18next";
import { IoMail } from "react-icons/io5";
import { useLocation, useParams } from "react-router-dom";

export const SignUpComplete = (): JSX.Element => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const isPending = location.pathname.includes("pending");
  const email = params.email ?? t("authentication.signUpComplete.success.defaultEmail");
  return (
    <div className="flex w-full flex-col items-center">
      {isPending ? (
        <>{t("authentication.signUpComplete.pending")}</>
      ) : (
        <>
          <IoMail className="-mt-2 h-14 w-14 text-rogerBlue-500" />
          <div className="flex flex-col items-start">
            <p className="mb-4 mt-2 text-gray-500">
              {t("authentication.signUpComplete.success.weSentTo")} <span className="font-semibold text-rogerBlue-500">{email}</span>.
            </p>
            <p className="text-gray-500">{t("authentication.signUpComplete.success.followTheLink")}</p>
          </div>
        </>
      )}
    </div>
  );
};
