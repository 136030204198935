import { type IConversationMember } from "@/api/client";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { addCommasSeparator } from "@/utils/utilities";
import { useCallback, useContext } from "react";

export const useConversationUtilities = () => {
  const me = useContext(AuthenticationContext);

  const computeConversationTitleFromMemberList = useCallback(
    (members: IConversationMember[], hasAndSeparator = false) => {
      if (me == null || members == null) return "";
      const otherMembersFullName: string[] = members
        .filter((member) => member.user.id !== me.id)
        .map((member) => `${member.user.firstName as string} ${member.user.lastName as string}`);

      return addCommasSeparator(otherMembersFullName, hasAndSeparator ? "&" : undefined);
    },
    [me],
  );

  return { computeConversationTitleFromMemberList };
};
