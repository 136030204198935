import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { closeModal } from "@/components/shared/modal/ModalService";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { SliderComponent } from "@/components/shared/slider/SliderComponent";
import { ModalNames } from "@/constants/modalNames";
import getCroppedImg from "@/utils/imageUtils";
import "rc-slider/assets/index.css";
import { useState } from "react";
import Cropper, { type Area } from "react-easy-crop";
import { useTranslation } from "react-i18next";

interface CropImageTabProps {
  imageBase64: string;
  onSubmit: (canvas: HTMLCanvasElement) => void;
}

const MIN_ZOOM = 0.35;
const MAX_ZOOM = 4;
const DEFAULT_ZOOM = 1.1;

export const CropImageTab = ({ imageBase64, onSubmit }: CropImageTabProps): JSX.Element => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  };
  const onSubmitCrop = async () => {
    if (croppedArea != null) {
      const canvas = await getCroppedImg(imageBase64, croppedArea);
      onSubmit(canvas);
    }
    closeModal(ModalNames.CROP_IMAGE);
  };

  const handleZoomChange = (value: number | number[]) => {
    if (value instanceof Array) {
      return;
    }
    setZoom(value);
  };

  return (
    <ModalTabComponent
      hasPadding={false}
      footer={
        <div className="mx-6 my-2 flex flex-row items-center justify-between">
          <div className="w-40" />
          <div className="flex flex-col items-center">
            <div className="flex flex-row items-center">
              <span className="mb-0.5 mr-4 text-xl">-</span>
              <div className="w-60">
                <SliderComponent onChange={handleZoomChange} min={MIN_ZOOM} max={MAX_ZOOM} value={zoom} step={0.01} />
              </div>
              <span className="mb-0.5 ml-4 text-xl">+</span>
            </div>
            <span className="text-sm font-medium">{t("cropImageModal.zoom")}</span>
          </div>
          <div className="flex w-40 justify-end">
            <ButtonComponent status="primary" onClick={onSubmitCrop}>
              {t("cropImageModal.submit")}
            </ButtonComponent>
          </div>
        </div>
      }
    >
      <div className="relative flex h-full w-full items-end justify-center">
        <Cropper
          image={imageBase64}
          crop={crop}
          zoom={zoom}
          aspect={1}
          cropShape="rect"
          showGrid={false}
          maxZoom={MAX_ZOOM}
          minZoom={MIN_ZOOM}
          restrictPosition={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onCropAreaChange={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
    </ModalTabComponent>
  );
};
