import { type IApiRoomGetOne } from "@/api/client";
import FilesIcon from "@/assets/images/iconsax/AttachSquare-Linear-32px.svg?react";
import TasksIcon from "@/assets/images/iconsax/TaskSquare-Linear-32px.svg?react";
import { type ITab } from "@/components/shared/layout/TabMenuComponent";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useRoomPage = (roomResponse?: IApiRoomGetOne) => {
  const { t } = useTranslation();

  const tabs: ITab[] = useMemo(() => {
    if (roomResponse == null) return [];
    const room = roomResponse.room;
    const baseTabs: ITab[] = [
      {
        title: t("roomPage.tabs.files.title"),
        link: `/dashboard/rooms/${room.id}/files`,
        icon: <FilesIcon className="gradient-icon stroke h-[1.25rem] w-[1.25rem] stroke-2" />,
        align: "left",
      },
      {
        title: t("roomPage.tabs.tasks.title"),
        link: `/dashboard/rooms/${room.id}/tasks`,
        icon: <TasksIcon className="gradient-icon stroke h-[1.25rem] w-[1.25rem]" />,
        align: "left",
      },
    ];
    return baseTabs;
  }, [roomResponse]);
  return { tabs };
};
