export enum ProfileTabs {
  PROFILE = "profile",
  ACCOUNT = "account",
  NOTIFICATIONS_SETTINGS = "notificationSettings",
  PLANS = "plans",
}
export enum OrganisationTabs {
  ABOUT = "about",
  MEMBERS = "members",
  INVITES = "invites",
  PLANS = "org-plans",
}

export interface IModalAction {
  label: string;
  action: null | (() => void);
  type?: "primary" | "secondary" | "danger" | "dangerSecondary";
  disabled?: boolean;
  hidden?: boolean;
}
