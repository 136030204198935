import { type ITag, type ITagUpdateBody, type ITask, type ITaskOne } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateTagMutation = (tagId: string, roomId: string, taskId?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: ITagUpdateBody) => {
      const response = await api.tags.update(tagId, params);
      return response.data;
    },
    onMutate: async (params: ITagUpdateBody) => {
      queryClient.setQueryData<ITag[]>([QueryKeys.ROOMS, roomId, QueryKeys.TAGS], (oldTags) => {
        return oldTags?.map((tag) => {
          if (tag.id === tagId) {
            return { ...tag, ...params };
          }
          return tag;
        });
      });
      if (taskId == null) return;
      queryClient.setQueryData<ITaskOne>([QueryKeys.TASK, taskId], (oldTags) => {
        if (oldTags == null) return oldTags;
        return {
          ...oldTags,
          tags: oldTags.tags?.map((tag) => {
            if (tag.id === tagId) {
              return { ...tag, ...params };
            }
            return tag;
          }),
        };
      });
      queryClient.setQueriesData<ITask[]>(
        {
          exact: false,
          queryKey: [QueryKeys.TASKS],
        },
        (oldTags) => {
          if (oldTags == null) return oldTags;
          return oldTags.map((task) => {
            if (task.id !== taskId) return task;
            return {
              ...task,
              tags: task.tags?.map((tag) => {
                if (tag.id === tagId) {
                  return { ...tag, ...params };
                }
                return tag;
              }),
            };
          });
        },
      );
    },
  });
};
