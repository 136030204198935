import { type IChannelUpdateBody } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useEditChannelMutation = (roomId: string | undefined, channelId: string | undefined) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newChannel: IChannelUpdateBody) => {
      if (roomId == null || channelId == null) return;

      await api.rooms.updateChannel(channelId, roomId, newChannel, { defaultToastErrorMessage: t("createRoomModal.tabs.about.update.error") });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, roomId, QueryKeys.CHANNELS] });
      toast.success(t("channelModal.tabs.about.updateSuccess"));
    },
  });
};
