import { type IPin } from "@/api/client";
import { MessageComponent } from "@/components/roomPage/tabs/conversation/message/MessageComponent";
import { useMessage } from "@/hooks/shared/useMessage";
interface IPinComponent {
  pin: IPin;
}

export const PinComponent = ({ pin }: IPinComponent): JSX.Element => {
  const { message } = pin;
  const { handleSearchedMessageClick } = useMessage({
    message,
  });
  return (
    <div
      className="block cursor-pointer border-b border-b-zinc-100 pb-3 hover:bg-zinc-50/50"
      onClick={async () => {
        await handleSearchedMessageClick(message);
      }}
    >
      <MessageComponent key={pin.id} message={message} previousMessage={message} isInPinBoard />
    </div>
  );
};
