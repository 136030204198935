import { type IRoomMember } from "@/api/client";
import { closeModal } from "@/components/shared/modal/ModalService";
import { QueryKeys } from "@/constants/queryKeys";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useCreateOrOpenDmMutation } from "@/hooks/mutations/conversations/useCreateOrOpenDmMutation";
import { useMyCollaboratorsQuery } from "@/hooks/queries/collaborators/useMyCollaboratorsQuery";
import { useSearchInput } from "@/hooks/shared/useSearchInput";
import { type IModalAction } from "@/interfaces/modal";
import { filterMembersBySearch, mapUsersToMembers } from "@/utils/utilities";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useMemo, useState, type ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

export const useDmTab = (roomId: string | undefined) => {
  const me = useContext(AuthenticationContext);
  const queryClient = useQueryClient();
  const { mutateAsync: createOrOpenDmMutation } = useCreateOrOpenDmMutation();
  const { t } = useTranslation();
  const [filteredMembers, setFilteredMembers] = useState<IRoomMember[]>([]);
  const [checkedMembers, setCheckedMembers] = useState<string[]>([]);
  const { search, updateSearch } = useSearchInput();
  const MAX_MEMBERS = 5;

  const { data: collaborators, isSuccess } = useMyCollaboratorsQuery(search, roomId != null);

  let timeout = 0;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      window.clearTimeout(timeout);
      if (roomId == null) {
        timeout = window.setTimeout(() => {
          queryClient.removeQueries({ queryKey: [QueryKeys.MY_COLLABORATORS] });
          updateSearch(e.target.value);
        }, 500);
        return;
      }
      queryClient.removeQueries({ queryKey: [QueryKeys.MY_COLLABORATORS] });
      updateSearch(e.target.value);
    },
    [updateSearch],
  );

  const handleMemberCheck = (memberId: string) => {
    if (checkedMembers.includes(memberId)) {
      setCheckedMembers((prev) => prev.filter((id) => id !== memberId));
    } else {
      setCheckedMembers((prev) => [...prev, memberId]);
    }
  };
  const actions: IModalAction[] = useMemo(() => {
    return [
      {
        action: async () => {
          const recipients = checkedMembers;
          await createOrOpenDmMutation({ recipients, roomId });
          closeModal("dmModal");
        },
        label: t("dms.modal.button.open"),
        type: "primary",
        disabled: checkedMembers.length === 0 || checkedMembers.length > MAX_MEMBERS,
      },
    ];
  }, [checkedMembers]);

  useEffect(() => {
    let members: IRoomMember[];
    if (me == null) return;
    if (roomId !== undefined) {
      // if it is dm inside a room, get members from room
      members = queryClient.getQueryData([QueryKeys.ROOMS, roomId, QueryKeys.MEMBERS]) ?? [];
      const membersWithoutMe = members.filter((member: IRoomMember) => member.userId !== me.id);
      setFilteredMembers(filterMembersBySearch(membersWithoutMe, search));
    } else {
      // if it is dm outside a room, get members from collaborators
      setFilteredMembers(mapUsersToMembers(collaborators ?? []) as IRoomMember[]);
    }
  }, [search, me, isSuccess]);

  return { actions, handleMemberCheck, filteredMembers, updateSearch, checkedMembers, setCheckedMembers, MAX_MEMBERS, handleChange };
};
