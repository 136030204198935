import Tippy, { type TippyProps } from "@tippyjs/react";
import { useMemo, type PropsWithChildren } from "react";
import { v4 as uuidv4 } from "uuid";

interface ITooltipComponentProps extends TippyProps {
  noPadding?: boolean;
}

export const TooltipComponent = ({
  placement = "auto",
  delay = [150, 0],
  noPadding = false,
  ...props
}: PropsWithChildren<ITooltipComponentProps>) => {
  // TODO MANAGE THEME IN A BETTER WAY WITH PREFERENCES
  const selectedTheme = props.theme === "dark" ? undefined : "light-border";
  const key = useMemo(() => uuidv4(), []);

  return (
    <Tippy key={key} placement={placement} theme={selectedTheme} delay={delay} className={`roger-theme ${noPadding ? "no-padding" : ""}`} {...props}>
      {props.children}
    </Tippy>
  );
};
