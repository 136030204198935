import { type Emoji, type IConversationMember, type IConversationMessagesAllSearchResponse, type IMessage } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { ConversationContext } from "@/contexts/ConversationContext";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { api } from "@/services/HttpService";
import { useQueryClient, type InfiniteData } from "@tanstack/react-query";
import { useCallback, useContext, useRef, useState, type MouseEventHandler } from "react";
import { v4 as uuidv4 } from "uuid";

export const useReaction = () => {
  const emojiPickerButtonRef = useRef<HTMLButtonElement>(null);
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [pickerPosition, setPickerPosition] = useState({ top: 0, left: 0 });
  const { data: me } = useMeQuery();
  const queryClient = useQueryClient();
  const conversationContext = useContext(ConversationContext);
  const uuidForOptimistic = uuidv4();
  const EMOJI_MART_CONTAINER_WIDTH = 360;

  const optimisticAddReaction = useCallback(
    (emoji: Emoji, message: IMessage) => {
      if (me == null || conversationContext.conversation == null) return;

      const editQuery = (oldData: InfiniteData<IConversationMessagesAllSearchResponse> | undefined) => {
        if (oldData == null) {
          return oldData;
        }
        return {
          ...oldData,
          pages: oldData.pages.map((page) => {
            return {
              ...page,
              messages: page.data.map((oldMessage: IMessage) => {
                if (oldMessage.id === message.id) {
                  oldMessage.reactions = oldMessage.reactions ?? {};
                  oldMessage.reactions[emoji.shortcodes] = oldMessage.reactions[emoji.shortcodes] ?? { count: 0, senders: [] };
                  oldMessage.reactions[emoji.shortcodes].count += 1;

                  const newSender = {
                    id: `OPTIMISTIC_ID_${uuidForOptimistic}`,
                    user: me,
                    conversation: conversationContext.conversation,
                  };
                  oldMessage.reactions[emoji.shortcodes].senders.push(newSender as IConversationMember);
                }
                return oldMessage;
              }),
            };
          }),
        };
      };
      if (message.nesting === "parent") {
        queryClient.setQueriesData<InfiniteData<IConversationMessagesAllSearchResponse>>(
          { exact: false, queryKey: [QueryKeys.MESSAGE, message.id, QueryKeys.MESSAGES] },
          editQuery,
        );
      }
      queryClient.setQueriesData<InfiniteData<IConversationMessagesAllSearchResponse>>(
        { exact: false, queryKey: [QueryKeys.CONVERSATION, conversationContext.conversation?.id, QueryKeys.MESSAGES] },
        editQuery,
      );
    },
    [me, conversationContext],
  );

  const optimisticRemoveReaction = useCallback(
    (emoji: Emoji, message: IMessage) => {
      if (me == null || conversationContext.conversation == null) return;

      const editQuery = (oldData: InfiniteData<IConversationMessagesAllSearchResponse> | undefined) => {
        if (oldData == null) {
          return oldData;
        }

        return {
          ...oldData,
          pages: oldData.pages.map((page) => {
            return {
              ...page,
              messages: page.data.map((oldMessage: IMessage) => {
                if (oldMessage.id === message.id) {
                  oldMessage.reactions = oldMessage.reactions ?? {};
                  oldMessage.reactions[emoji.shortcodes] = oldMessage.reactions[emoji.shortcodes] ?? { count: 0, senders: [] };
                  oldMessage.reactions[emoji.shortcodes].count -= 1;
                  if (oldMessage.reactions[emoji.shortcodes].count <= 0) {
                    const { [emoji.shortcodes]: _, ...restReactions } = oldMessage.reactions;
                    oldMessage.reactions = restReactions;
                  } else {
                    oldMessage.reactions[emoji.shortcodes].senders = oldMessage.reactions[emoji.shortcodes].senders.filter(
                      (sender) => sender.user.id !== me?.id,
                    );
                  }
                }
                return oldMessage;
              }),
            };
          }),
        };
      };

      if (message.nesting === "parent") {
        queryClient.setQueriesData<InfiniteData<IConversationMessagesAllSearchResponse>>(
          { exact: false, queryKey: [QueryKeys.MESSAGE, message.id, QueryKeys.MESSAGES] },
          editQuery,
        );
      }
      queryClient.setQueriesData<InfiniteData<IConversationMessagesAllSearchResponse>>(
        { exact: false, queryKey: [QueryKeys.CONVERSATION, conversationContext.conversation.id, QueryKeys.MESSAGES] },
        editQuery,
      );
    },
    [me, conversationContext],
  );

  // Methods to add and remove reactions from the server
  const addReaction = useCallback(async (emoji: Emoji, message: IMessage) => {
    setIsEmojiPickerOpen(false);
    optimisticAddReaction(emoji, message);
    await api.messages.addReaction(message.id, { content: emoji.shortcodes });
  }, []);

  const removeReaction = useCallback(async (message: IMessage, reactionShortcodes: string) => {
    setIsEmojiPickerOpen(false);
    optimisticRemoveReaction({ shortcodes: reactionShortcodes }, message);
    await api.messages.removeReaction(message.id, reactionShortcodes);
  }, []);

  const openEmojiPicker = useCallback<MouseEventHandler>((event) => {
    if (event.clientX + EMOJI_MART_CONTAINER_WIDTH > window.innerWidth) {
      setPickerPosition({ top: event.clientY, left: event.clientX - EMOJI_MART_CONTAINER_WIDTH / 2 });
    } else {
      setPickerPosition({ top: event.clientY, left: event.clientX });
    }

    setIsEmojiPickerOpen(true);
  }, []);

  // check if in the reaction if the current user has reacted with the same emoji
  const hasReactedWithEmoji = useCallback((senders: IConversationMember[], userId: string | undefined) => {
    return senders.some((sender) => sender.user.id === userId) ?? false;
  }, []);

  // handle the selection of an emoji
  const selectEmoji = useCallback(async (emoji: Emoji, message: IMessage) => {
    const senders = message.reactions?.[emoji.shortcodes]?.senders;

    if (senders != null && hasReactedWithEmoji(senders, me?.id)) {
      await removeReaction(message, emoji.shortcodes);
      return;
    }

    await addReaction(emoji, message);
  }, []);

  return {
    isEmojiPickerOpen,
    setIsEmojiPickerOpen,
    addReaction,
    emojiPickerButtonRef,
    emojiPickerRef,
    removeReaction,
    pickerPosition,
    setPickerPosition,
    openEmojiPicker,
    hasReactedWithEmoji,
    selectEmoji,
  };
};
