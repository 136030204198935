import { ImageComponent } from "@/components/shared/image/ImageComponent";
import { useRoomQuery } from "@/hooks/queries/rooms/useRoomQuery";
import { type HTMLAttributes } from "react";

interface RoomAvatarProps extends HTMLAttributes<HTMLDivElement> {
  roomId?: string;
  size?: "xs" | "sm" | "md" | "lg";
}

export const RoomAvatarComponent = ({ size = "md", roomId, className }: RoomAvatarProps): JSX.Element => {
  const { data: roomData } = useRoomQuery(roomId);

  const room = roomData?.room;

  return (
    <ImageComponent
      src={room?.roomPicture?.url ?? ""}
      initial
      size={size}
      alt={room?.name ?? ""}
      key={room?.roomPicture?.url}
      className={className}
    />
  );
};
