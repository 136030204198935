import { RoleType, RoomTypeEnum } from "@/api/client";
import { RoomContext } from "@/contexts/RoomContext";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { useContext, useMemo } from "react";

export const useEmailAuthorization = () => {
  const { data: me } = useMeQuery();
  const { roomResponse: roomContext } = useContext(RoomContext);

  const shouldShowEmails = useMemo(() => {
    if (me?.mainOrganisation == null) return false;
    return (
      // TODO: Remove this when we want to show the mails for everyone
      me.mainOrganisation.organisationSlug === "getroger-io" &&
      roomContext?.room.type != null &&
      roomContext.room.type !== RoomTypeEnum.Organisation &&
      roomContext.member.role.type !== RoleType.Guest
    );
  }, [me, roomContext]);
  return { shouldShowEmails };
};
