import { RoomTypeEnum } from "@/api/client";
import { ProfileModal } from "@/components/header/profileDropdown/profileModal/ProfileModal";
import { RoomModal } from "@/components/homePage/modals/room/RoomModal";
import { closeModal } from "@/components/shared/modal/ModalService";
import { eventNames } from "@/constants/eventNames";
import { ModalNames } from "@/constants/modalNames";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useMyRoomsQuery } from "@/hooks/queries/rooms/useMyRoomsQuery";
import { useModal } from "@/hooks/shared/useModal";
import { ProfileTabs } from "@/interfaces/modal";
import { logout } from "@/services/AuthService";
import { useQueryClient } from "@tanstack/react-query";
import { Fragment, useCallback, useContext, useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { BsFillCreditCardFill as PlansAndBill } from "react-icons/bs";
import { GoSync as UpdateIcon } from "react-icons/go";
import { MdManageAccounts as ManageAccountsIcon } from "react-icons/md";
import { TbLogout as LogoutIcon, TbUsers as MembersIcon } from "react-icons/tb";
import { useNavigate, useSearchParams } from "react-router-dom";

interface IProfileDropdownFooterProps {
  closeDropdown: () => void;
}

export const ProfileDropdownFooter = ({ closeDropdown }: IProfileDropdownFooterProps): JSX.Element => {
  const { t } = useTranslation();
  const { openModal, getModalURIParams } = useModal();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const me = useContext(AuthenticationContext);
  const { data: rooms } = useMyRoomsQuery();
  const navigate = useNavigate();
  const availableModalsComponents = {
    editProfile: ProfileModal,
  };

  const checkForUpdates = useCallback(() => {
    const event = new CustomEvent(eventNames.OPEN_UPDATE_MODAL);

    window.dispatchEvent(event);
  }, []);

  // To open a modal according to query params
  useEffect(() => {
    const modalParam = getModalURIParams().modal as keyof typeof availableModalsComponents;
    const activeTab = getModalURIParams().tab as string;
    if (modalParam == null) return;

    const ModalComponent = availableModalsComponents[modalParam];
    if (ModalComponent == null) return;
    openModal(modalParam, <ModalComponent activeTab={activeTab} />);
  }, [searchParams]);

  const openEditProfileModal = useCallback(
    async (activeTab: string) => {
      closeModal("editProfile");
      openModal("editProfile", <ProfileModal activeTab={activeTab} />, true);
      closeDropdown();
    },
    [searchParams, closeDropdown],
  );

  const openRoomMembersModal = useCallback((): void => {
    const organisationRoomMember = rooms?.find((room) => room.room.type === RoomTypeEnum.Organisation);

    openModal(
      ModalNames.CREATE_ROOM,
      <RoomModal defaultTab="members" title={organisationRoomMember?.room?.name} isOrganisationRoom roomId={organisationRoomMember?.roomId} />,
    );
    closeDropdown();
  }, [rooms, closeDropdown]);

  const openPlansModal = useCallback(() => {
    if (me?.mainOrganisation?.organisationType !== "personal") {
      const organisationRoomMember = rooms?.find((room) => room.room.type === RoomTypeEnum.Organisation);
      if (organisationRoomMember == null) {
        toast.error("No organisation room found");
        return;
      }
      openModal(
        ModalNames.CREATE_ROOM,
        <RoomModal defaultTab="plans" title={organisationRoomMember.room.name} isOrganisationRoom roomId={organisationRoomMember.roomId} />,
      );
    } else {
      openModal("editProfile", <ProfileModal activeTab={ProfileTabs.PLANS} />, true);
    }
    closeDropdown();
  }, [rooms, me, closeDropdown]);

  const menuItems = useMemo<Array<{ label: string; action: () => void; isEnabled: boolean; icon: JSX.Element }>>(() => {
    const menu = [
      {
        label: t("header.profileDropdown.accountSettings"),
        action: async () => {
          await openEditProfileModal(ProfileTabs.ACCOUNT);
        },
        isEnabled: true,
        icon: <ManageAccountsIcon className="h-4 w-4" />,
      },
    ];

    if (
      me?.mainOrganisation?.role?.abilities != null &&
      me.mainOrganisation.organisationOwner?.id === me.id &&
      me.mainOrganisation.organisationSubscription != null
    ) {
      menu.push({
        label: t("header.profileDropdown.plansAndBilling"),
        action: async () => {
          openPlansModal();
        },
        isEnabled: true,
        icon: <PlansAndBill className="h-4 w-4" />,
      });
    }

    if (me?.mainOrganisation?.organisationType === "organisation") {
      menu.push({
        label: t("header.profileDropdown.organisationMembers"),
        action: async () => {
          openRoomMembersModal();
        },
        isEnabled: true,
        icon: <MembersIcon className="h-4 w-4" />,
      });
    }

    menu.push({
      label: t("general.logout"),
      action: async () => {
        queryClient.removeQueries();
        await logout().finally(() => {
          navigate("/signin", { replace: true });
        });
      },
      isEnabled: true,
      icon: <LogoutIcon className="h-4 w-4" />,
    });
    return menu;
  }, [me, rooms]);

  return (
    <>
      <div className="mb-8 flex w-72 flex-col items-start">
        {menuItems.map((item) => (
          <Fragment key={item.label}>
            {item.isEnabled && (
              <nav className="flex items-center text-sm text-zinc-700 opacity-70 transition-all hover:opacity-100 dark:text-zinc-300">
                {item.icon}
                <button className="ml-1 py-2 " onClick={item.action}>
                  {item.label}
                </button>
              </nav>
            )}
          </Fragment>
        ))}
        <div
          className={`semibold absolute bottom-0 -ml-4 mt-2 flex w-full items-center border-t border-slate-200 text-xs ${
            window.IS_ELECTRON ? "justify-between" : "justify-center"
          } px-4 py-2`}
        >
          <a target="_blank" href={t("general.releaseNoteUrl")} className="text-zinc-400 underline hover:text-sky-600" rel="noreferrer">
            {t("general.version")} {APP_VERSION}
          </a>

          {window.IS_ELECTRON ? (
            <a
              target="_blank"
              className="ml-0.5 flex cursor-pointer flex-row text-zinc-500 no-underline hover:text-sky-600"
              rel="noreferrer"
              onClick={() => {
                checkForUpdates();
              }}
            >
              <UpdateIcon className="mr-0.5 h-4 w-4" />
              {t("update.checkForUpdate")}
            </a>
          ) : null}
        </div>
      </div>
    </>
  );
};
