interface IHeadedBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  hasPadding?: boolean;
  overflow?: string;
}

export const RoundedContainerComponent = ({ hasPadding = true, overflow = "overflow-hidden", ...props }: IHeadedBoxProps): JSX.Element => {
  return (
    <div
      className={`headed-box flex h-full flex-col bg-white dark:bg-dark-900 
          ${props.className ?? ""}
          `}
      style={props.style}
    >
      {props.children != null && <section className={`${hasPadding ? "p-layout" : ""} h-full ${overflow} flex-1`}>{props.children}</section>}
    </div>
  );
};
