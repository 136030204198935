import { SocketEvents } from "@/constants/socketEvents";
import { TYPING_INDICATOR_CHECK_FREQUENCY, TYPING_INDICATOR_REMOVE_DELAY } from "@/constants/typingIndicatorConstants";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { type IIsTypingPayload } from "@/interfaces/isTyping";
import { addMilliseconds, isPast } from "date-fns";
import { useContext, useEffect, useState } from "react";

export interface ISocketUserIsTyping {
  memberId: string;
  memberFullName: string;
  removeDate: Date;
}

let interval: NodeJS.Timeout | null = null;

export type MembersTypingByConversations = Record<string, ISocketUserIsTyping[]>;

export const useTypingUsers = () => {
  const socket = useContext(SocketContext);
  const [membersTypingByConversations, setMembersTypingByConversations] = useState<MembersTypingByConversations>({});

  const handleUserIsTyping = (data: IIsTypingPayload) => {
    setMembersTypingByConversations((membersByConversation) => {
      let currentMembersTypingByConversations = { ...membersByConversation };
      if (currentMembersTypingByConversations[data.conversationId] == null) {
        currentMembersTypingByConversations = { ...currentMembersTypingByConversations, [data.conversationId]: [] };
      }
      currentMembersTypingByConversations[data.conversationId] = currentMembersTypingByConversations[data.conversationId].filter(
        (typingUser) => typingUser.memberId !== data.conversationMember.memberId,
      );

      currentMembersTypingByConversations[data.conversationId].push({
        memberFullName: data.conversationMember.memberFullName,
        memberId: data.conversationMember.memberId,
        removeDate: addMilliseconds(new Date(), TYPING_INDICATOR_REMOVE_DELAY),
      });

      return currentMembersTypingByConversations;
    });
  };

  useEffect(() => {
    if (socket == null) {
      return;
    }

    socket.on(SocketEvents.USER_IS_TYPING, handleUserIsTyping);
    return () => {
      socket.off(SocketEvents.USER_IS_TYPING, handleUserIsTyping);
    };
  }, [socket]);

  useEffect(() => {
    const totalCount = Object.values(membersTypingByConversations).reduce((acc, conversationTypingUsers) => acc + conversationTypingUsers.length, 0);

    if (totalCount > 0) {
      interval = setInterval(() => {
        const hasToRemoveAny = Object.values(membersTypingByConversations).some((typingUsers) => {
          return typingUsers.some((typingUser) => isPast(typingUser.removeDate));
        });

        if (hasToRemoveAny != null) {
          setMembersTypingByConversations((currentMembersTypingByConversations) => {
            const newMembersTypingByConversations = { ...currentMembersTypingByConversations };

            Object.entries(newMembersTypingByConversations).forEach(([conversationId, typingUsers]) => {
              typingUsers.forEach((typingUser) => {
                if (isPast(typingUser.removeDate)) {
                  newMembersTypingByConversations[conversationId] = newMembersTypingByConversations[conversationId].filter(
                    (t) => t.memberId !== typingUser.memberId,
                  );
                }
              });
            });

            return newMembersTypingByConversations;
          });
        }
      }, TYPING_INDICATOR_CHECK_FREQUENCY);
    }

    return () => {
      if (interval != null) {
        clearInterval(interval);
      }
    };
  }, [membersTypingByConversations]);

  return { membersTypingByConversations };
};
