import { type IMessage } from "@/api/client";
import { ConversationContext } from "@/contexts/ConversationContext";
import { useContext } from "react";

export const useNewMessageIndicator = (message: IMessage, previousMessage: IMessage | undefined, isMine: boolean) => {
  const conversationMember = useContext(ConversationContext);
  const shouldDisplayNewMessageIndicator = conversationMember.member?.lastMessageRead?.id === previousMessage?.id && !isMine;

  return { shouldDisplayNewMessageIndicator };
};
