import { type ITask, type ITaskStatus } from "@/api/client";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { CreateTaskDrawerComponent } from "@/components/shared/tasks/CreateTaskDrawerComponent/CreateTaskDrawerComponent";
import { eventNames } from "@/constants/eventNames";
import { DraggableType } from "@/constants/task";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const useTaskStatusColumn = (tasks: ITask[], status: ITaskStatus) => {
  const { openDrawer } = useContext(DrawerContext);
  const { isOver, active, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: status.id,
    data: { type: DraggableType.TASK_STATUS },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const shouldDelayOverlay = useMemo(
    () => tasks.find((task) => task.id === active?.id && task.taskStatus.id === status.id) != null,
    [status, tasks, active],
  );

  const shouldDisplayOverlay = useMemo(() => isOver && active?.data.current?.type === DraggableType.TASK, [isOver, active]);

  const tasksToDisplay = useMemo(() => tasks.filter((task) => task.taskStatus.id === status.id), [tasks, status]);

  const [isPopinVisible, setIsPopinVisible] = useState(false);
  const popinKey = useMemo(() => `taskStatusPopin-${uuidv4()}`, [isPopinVisible]);

  const handleCloseTaskStatusPopin = useCallback(() => {
    setIsPopinVisible(false);
  }, []);

  const handleCreateTaskClick = useCallback(() => {
    openDrawer({
      drawerType: DrawerType.CREATE_TASK,
      component: <CreateTaskDrawerComponent defaultValues={{ taskStatus: status }} />,
    });
  }, [status]);

  useEffect(() => {
    if (!isPopinVisible) return;

    window.addEventListener(eventNames.CLOSE_TASK_STATUS_POPIN, handleCloseTaskStatusPopin);
    return () => {
      window.removeEventListener(eventNames.CLOSE_TASK_STATUS_POPIN, handleCloseTaskStatusPopin);
    };
  }, [isPopinVisible]);

  return {
    isOver,
    active,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    style,
    shouldDelayOverlay,
    shouldDisplayOverlay,
    tasksToDisplay,
    isPopinVisible,
    setIsPopinVisible,
    popinKey,
    handleCreateTaskClick,
  };
};
