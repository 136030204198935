import { type INotFoundError } from "@/api/client";
import { HttpStatusCodesEnum } from "@/api/httpStatusCodes";
import { DrawerContext } from "@/components/shared/drawer/DrawerContext";
import { DrawerType } from "@/components/shared/drawer/DrawerTypes";
import { ConfirmModalComponent } from "@/components/shared/modal/ConfirmModalComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { ModalNames } from "@/constants/modalNames";
import { type IConversationContext } from "@/contexts/ConversationContext";
import { RoomContext } from "@/contexts/RoomContext";
import { useDeleteTaskMutation } from "@/hooks/mutations/tasks/useDeleteTaskMutation";
import { useUpdateTaskMutation } from "@/hooks/mutations/tasks/useUpdateTaskMutation";
import { useConversationActiveMemberQuery } from "@/hooks/queries/conversations/useConversationActiveMemberQuery";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { useTaskQuery } from "@/hooks/queries/tasks/useTaskQuery";
import { type IRichTextEditorRef } from "@/interfaces/richTextEditor";
import { isDeltaContentEmpty } from "@/utils/utilities";
import { type AxiosError } from "axios";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const useEditTaskDrawer = (taskId: string) => {
  const { t } = useTranslation();
  const descriptionEditorRef = useRef<IRichTextEditorRef>(null);
  const { data: task, isPending, isError, error } = useTaskQuery(taskId);
  const { data: me } = useMeQuery();
  const { mutate: updateTask, isPending: isSaving, isIdle: isFresh } = useUpdateTaskMutation();
  const { mutateAsync: deleteTask } = useDeleteTaskMutation();
  const { closeDrawer, setOverlayCallback } = useContext(DrawerContext);
  const roomContext = useContext(RoomContext);
  const { data: conversationMembers } = useConversationActiveMemberQuery(task?.conversation?.id);
  const navigate = useNavigate();
  const canUpdateIsPrivate = useMemo(() => {
    if (task == null || me == null) {
      return false;
    }
    return task.createdByUser?.id === me.id;
  }, [task, me]);

  const onCloseDrawer = useCallback(() => {
    closeDrawer(DrawerType.EDIT_TASK);
    if (roomContext?.roomResponse?.room.id != null) {
      navigate(`/dashboard/rooms/${roomContext?.roomResponse.room.id}/tasks`);
    }
  }, [roomContext]);

  const showDeleteWarning = () => {
    openModal(
      ModalNames.CONFIRM_TASK_DELETION,
      <ConfirmModalComponent
        name={ModalNames.CONFIRM_TASK_DELETION}
        title={t("editTaskModal.deleteWarning.title")}
        description={t("editTaskModal.deleteWarning.description")}
        action={async () => {
          await deleteTask(taskId);
          closeDrawer(DrawerType.EDIT_TASK);
        }}
        actionType="danger"
        actionLabel={t("editTaskModal.deleteWarning.button")}
        cancelLabel={t("editTaskModal.deleteWarning.cancel")}
      />,
    );
  };

  const onTaskDelete = () => {
    showDeleteWarning();
  };

  const onDescriptionBlur = () => {
    const description = descriptionEditorRef.current?.getEditorContents();
    if (description != null) {
      updateTask({
        taskId,
        description,
      });
    }
  };

  useEffect(() => {
    if (task?.description != null && descriptionEditorRef.current != null) {
      const currentDeltaContent = descriptionEditorRef.current.getEditorContents();
      if (currentDeltaContent != null && isDeltaContentEmpty(currentDeltaContent)) {
        descriptionEditorRef.current.setEditorContent(task.description);
      }
    }
  }, [task, descriptionEditorRef]);

  useEffect(() => {
    setOverlayCallback(() => {
      onCloseDrawer();
    });
  }, []);

  const creatorFullName = `${task?.createdByUser.firstName ?? ""} ${task?.createdByUser.lastName ?? ""}`;

  const conversationId = task?.conversation?.id ?? null;

  const conversationContextValue: IConversationContext = useMemo(() => {
    const members = conversationMembers ?? task?.conversation?.members ?? [];
    return {
      conversation:
        task?.conversation?.id != null
          ? {
              id: task.conversation.id,
              type: "task",
              members: task.conversation.members,
              messagesCount: task.conversation.messagesCount,
            }
          : undefined,
      members,
      member: members.find((member) => member.user.id === me?.id),
    };
  }, [task, conversationMembers, me]);

  const isNotFoundError = (error as AxiosError<INotFoundError>)?.response?.data?.statusCode === HttpStatusCodesEnum.NOT_FOUND;

  return {
    isError,
    isNotFoundError,
    descriptionEditorRef,
    task,
    isPending,
    isSaving,
    isFresh,
    canUpdateIsPrivate,
    onCloseDrawer,
    onTaskDelete,
    onDescriptionBlur,
    creatorFullName,
    conversationId,
    conversationContextValue,
    updateTask,
  };
};
