import { eventNames } from "@/constants/eventNames";
import { useEffect, useState } from "react";

export const useDocumentViewer = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleCloseDocumentViewer = () => {
    setIsVisible(false);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      handleCloseDocumentViewer();
    }
  };

  useEffect(() => {
    window.addEventListener(eventNames.CLOSE_DOCUMENT_VIEWER, handleCloseDocumentViewer);
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener(eventNames.CLOSE_DOCUMENT_VIEWER, handleCloseDocumentViewer);
    };
  }, []);

  return { isVisible, setIsVisible };
};
