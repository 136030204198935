import { RecipientInputComponent } from "@/components/roomPage/tabs/emails/emailReply/recipientInput/RecipientInputComponent";
import { useEmailReply } from "@/components/roomPage/tabs/emails/emailReply/useEmailReply";
import { RichTextEditorComponent } from "@/components/shared/richTextEditor/RichTextEditorComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IoClose as CloseIcon } from "react-icons/io5";

interface IMailReplyProps {
  threadId: string;
}
export const EmailReplyComponent: FunctionComponent<IMailReplyProps> = ({ threadId }) => {
  const { t } = useTranslation();
  const { to, setTo, cc, setCc, handleCloseReplyButtonClick } = useEmailReply();
  return (
    <div className="relative mt-4 flex flex-col justify-center border-t px-layout">
      <TooltipComponent content={t("roomPage.tabs.mails.buttons.closeReply")}>
        <button
          className="absolute right-5 top-0 z-0 -mt-8 rounded border bg-white p-1 text-zinc-700 opacity-80 transition-opacity hover:text-black hover:opacity-100"
          onClick={handleCloseReplyButtonClick}
        >
          <CloseIcon />
        </button>
      </TooltipComponent>
      {to != null ? (
        <RecipientInputComponent defaultValue={to} onChange={setTo} id="toInput" label={t("roomPage.tabs.mails.recipientsInfo.to")} />
      ) : null}
      {cc != null ? (
        <div className="border-t">
          <RecipientInputComponent defaultValue={cc} onChange={setCc} id="ccInput" label={t("roomPage.tabs.mails.recipientsInfo.cc")} />
        </div>
      ) : null}
      <RichTextEditorComponent contextType="email" contextId={threadId} key={threadId} />
    </div>
  );
};
