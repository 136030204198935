import { TagComponent } from "@/components/shared/tag/TagComponent";
import { useCallback, type FunctionComponent } from "react";
import { IoMdAdd as AddIcon } from "react-icons/io";

interface AddElementTagComponentProps {
  value: string;
  onAddElement: () => void;
}

export const AddElementTagComponent: FunctionComponent<AddElementTagComponentProps> = ({ value, onAddElement }) => {
  const IconComponent = useCallback(() => <AddIcon className="h-4 w-4" />, []);
  return <TagComponent IconComponent={IconComponent} value={value} type="secondary" onClick={onAddElement} />;
};
