import { PlanComponent } from "@/components/billing/plans/PlanComponent";
import { usePlansQuery } from "@/hooks/queries/billing/usePlanQuery";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";

export const PlansTable = (): JSX.Element => {
  const { data: plans, isLoading } = usePlansQuery();
  const { data: me } = useMeQuery();
  let whiteListedKeys = ["roomLimit", "guestLimit", "storageLimitGb", "usersInCallLimit", "taskLimit"];
  if (me?.mainOrganisation?.organisationType === "organisation") whiteListedKeys = ["memberLimit", ...whiteListedKeys];

  return (
    <div className="grid w-full grid-cols-4">
      {/* @ts-expect-error Back needs to add "usersInCallLimit", "taskLimit" to the plan type */}
      <PlanComponent planKeys={whiteListedKeys}></PlanComponent>
      {!isLoading &&
        plans?.map((plan) => {
          // @ts-expect-error Back needs to add "usersInCallLimit", "taskLimit" to the plan type
          return <PlanComponent plan={plan} key={plan.id} planKeys={whiteListedKeys}></PlanComponent>;
        })}
    </div>
  );
};
