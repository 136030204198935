import { useConversationActiveMemberQuery } from "@/hooks/queries/conversations/useConversationActiveMemberQuery";
import { type SimpleUser, type UserWithEmail, type UserWithId } from "@/interfaces/user";
import { isMailValid } from "@/utils/utilities";
import { useEffect, useMemo, useRef, useState } from "react";
import removeAccents from "remove-accents";

const sortMembersByName = (a: SimpleUser, b: SimpleUser) => {
  const aFullName = `${a.firstName ?? ""} ${a.lastName ?? ""}`;
  const bFullName = `${b.firstName ?? ""} ${b.lastName ?? ""}`;
  return aFullName.localeCompare(bFullName);
};

export const usePickUserPopinContent = (conversationId: string | null, isVisible: boolean, selectedUsers: Array<UserWithEmail | UserWithId>) => {
  const { data: conversationActiveMembers, isLoading: isConversationActiveMembersQueryLoading } = useConversationActiveMemberQuery(
    conversationId,
    isVisible,
  );

  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    setSearch("");
  }, [isVisible]);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isVisible) {
      ref.current?.focus();
    }
  }, [isVisible]);

  const onSearchChange = (value: string) => {
    setSearch(value.trim().toLowerCase());
  };

  const mailUsers: SimpleUser[] = useMemo(() => {
    const users = selectedUsers
      .filter((user): user is UserWithEmail => user.type === "email")
      .map<SimpleUser>((user) => ({
        id: user.email,
        email: user.email,
      }));

    return users.filter((user) => {
      return removeAccents(user.email.toLowerCase()).includes(removeAccents(search.toLowerCase()));
    });
  }, [selectedUsers, search]);

  const users: SimpleUser[] = useMemo(() => {
    let resultUsers: SimpleUser[] = [];

    if (conversationId != null) {
      resultUsers = (conversationActiveMembers ?? []).map<SimpleUser>((conversationMember) => conversationMember.user);
    } else {
      resultUsers = [];
    }

    const result = resultUsers.filter((user) => {
      const fullName = `${user.firstName ?? ""} ${user.lastName ?? ""} ${user.email}`;
      return removeAccents(fullName.toLowerCase()).includes(removeAccents(search.toLowerCase()));
    });

    return result.sort(sortMembersByName);
  }, [conversationActiveMembers, search, conversationId, selectedUsers]);

  const isLoading = conversationId != null && isConversationActiveMembersQueryLoading;

  const shouldDisplayNewEmail = search.length > 0 && mailUsers.every((user) => user.email !== search) && users.every((user) => user.email !== search);
  const isEmailButtonDisabled = !shouldDisplayNewEmail || !isMailValid(search);

  return {
    users,
    mailUsers,
    isLoading,
    shouldDisplayNewEmail,
    isEmailButtonDisabled,
    search,
    onSearchChange,
    ref,
  };
};
