import {
  FileType,
  type FilesUploadGetPresignedUrlFile,
  type IFilesUploadGetPresignedUrlController,
  type IQuotaData,
  type S3FileTypeAccepted,
} from "@/api/client";
import { api } from "@/services/HttpService";
import axios, { AxiosError } from "axios";
import { type FilePondErrorDescription, type FilePondFile, type ProcessServerConfigFunction } from "filepond";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { registerPlugin, type FilePond } from "react-filepond";
import { toast } from "react-hot-toast";

import { useBillingConfirm } from "@/components/billing/modals/useBillingConfirm";
import { eventNames } from "@/constants/eventNames";
import { LimitationResponseCodes } from "@/constants/limitationResponseCode";
import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { type InternalFile } from "@/types/fileTypes";
import { getDecodedImage } from "@/utils/utilities";
import { useTranslation } from "react-i18next";
import type ReactQuill from "react-quill";

interface ICustomError extends FilePondErrorDescription {
  main: string;
  sub: string;
}

interface IFilesUploadGetPresignedUrlFileWithMeta extends FilesUploadGetPresignedUrlFile {
  width?: number;
  height?: number;
  base64?: string;
  duration?: number;
}

interface IFilePresignedData extends IFilesUploadGetPresignedUrlController {
  file: IFilesUploadGetPresignedUrlFileWithMeta;
}

interface FilePondWarningError {
  body: string;
  type: "warning";
  code: number;
}

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileEncode);

export const useFileUploader = (quillEditor: ReactQuill | null, contextId: string) => {
  const maxFiles = 10;
  const filepondRef = useRef<FilePond>(null);
  const { t } = useTranslation();
  const { openBillingConfirmModal } = useBillingConfirm();
  const socket = useContext(SocketContext);
  const [files, setFiles] = useState<FilePondFile[]>([]);
  const [uploadStatus, setUploadStatus] = useState<Map<string, boolean>>(new Map());
  const [fileData, setFileData] = useState<InternalFile[]>([]);
  const isUploading = useMemo(() => files.some((file) => uploadStatus.get(file.filename) ?? true), [files, uploadStatus]);
  const shouldAllowPasteAndDrop = useMemo(() => {
    const filepondUploaders = document.querySelectorAll(".filepond-uploader");
    if (filepondUploaders == null) return false;
    if (filepondUploaders.length === 1) return true;

    return quillEditor?.getEditor().hasFocus();
  }, [document.querySelectorAll(".filepond-uploader"), quillEditor?.getEditor().hasFocus()]);

  const browseFile = useCallback(() => {
    if (filepondRef?.current == null) return;
    filepondRef.current.browse();
  }, [filepondRef]);

  const [counter, setCounter] = useState<number>(0);

  const handleDragEnter = (e: DragEvent) => {
    setCounter((prev) => prev + 1);
    e.stopPropagation();
  };
  const handleDragLeave = (e: DragEvent) => {
    setCounter((prev) => prev - 1);

    e.stopPropagation();
  };

  const handleDrop = () => {
    setCounter(0);
  };

  const isFilePondVisible = useMemo(() => counter === 1, [counter]);

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
  };
  const conversationElement = document.getElementById(`conversation-${contextId}`);

  useEffect(() => {
    const resetFilepond = (event: CustomEvent) => {
      if (event.detail !== contextId) return;
      filepondRef.current?.removeFiles();
      setFileData([]);
      setFiles([]);
    };
    window.addEventListener(eventNames.RESET_MESSAGE_FILE, resetFilepond as EventListener);
    return () => {
      window.removeEventListener(eventNames.RESET_MESSAGE_FILE, resetFilepond as EventListener);
    };
  }, []);

  useEffect(() => {
    const event = new CustomEvent(eventNames.FILE_DATA_UPDATED, { detail: { fileData, contextId } });
    window.dispatchEvent(event);
  }, [fileData]);

  useEffect(() => {
    const event = new CustomEvent(eventNames.FILE_UPLOAD_STATUS_UPDATED, { detail: { isUploading, contextId } });
    window.dispatchEvent(event);
  }, [isUploading]);

  useEffect(() => {
    if (conversationElement == null) return;

    window.addEventListener("drop", handleDragOver);
    window.addEventListener("dragover", handleDragOver);

    conversationElement.addEventListener("dragenter", handleDragEnter);
    conversationElement.addEventListener("dragleave", handleDragLeave);
    conversationElement.addEventListener("drop", handleDrop);

    return () => {
      window.removeEventListener("drop", handleDragOver);
      window.removeEventListener("dragover", handleDragOver);

      conversationElement.removeEventListener("dragenter", handleDragEnter);
      conversationElement.removeEventListener("dragleave", handleDragLeave);
      conversationElement.removeEventListener("drop", handleDrop);
    };
  }, [conversationElement]);

  const getFiles = useCallback(() => {
    return filepondRef.current?.getFiles();
  }, [filepondRef.current]);

  const onErrorHandler = useCallback((error: Partial<ICustomError>) => {
    console.error(error);
  }, []);

  const onAddFileHandler = useCallback(
    (error: FilePondErrorDescription | null, file: FilePondFile) => {
      if (error != null) {
        return filepondRef.current?.removeFile(file.id);
      }
      setUploadStatus((prevStatus) => new Map(prevStatus).set(file.id, true));
    },
    [filepondRef.current],
  );

  const onUploadWarningHandler = useCallback((error: FilePondWarningError | null) => {
    if (error != null && error.type === "warning" && error.body === "Max files") {
      toast.error(t("error.maxFilesReached"));
    }
  }, []);

  const onRemoveFileHandler = useCallback(
    (error: FilePondErrorDescription | null, file: FilePondFile) => {
      if (error != null) {
        console.error(t("general.unknownError"));
      }
      setFileData((prevData) => {
        return prevData.filter((fileData) => fileData.name !== file.filename);
      });
      setUploadStatus((prevData) => {
        const newData = new Map(prevData);
        newData.delete(file.filename);
        return newData;
      });
    },
    [setUploadStatus],
  );

  const filePondServerConfig = useMemo(() => {
    if (socket == null) return;

    const process: ProcessServerConfigFunction = async (fieldName, file, metadata, load, error) => {
      const fileExtension = file.name.split(".").pop() as S3FileTypeAccepted;
      if (fileExtension == null) {
        error(t("header.editProfile.modal.tabs.profile.uploadProfilePictureErrors.noExtension"));
        return;
      }
      // retrieve the image dimensions
      let img: HTMLImageElement | undefined;
      let base64 = "";
      if (file.type.includes("image") || file.type.includes("audio") || file.type.includes("pdf")) {
        const filesItem = filepondRef?.current?.getFiles();
        const fileItem = filesItem?.find((_file) => _file.filename === file.name);
        /* @ts-expect-error seems to be a filepond issue */
        base64 = fileItem?.getFileEncodeDataURL();

        if (file.type.includes("image")) {
          img = await getDecodedImage(base64);
        }
      }

      let mimeType = file.type;

      // type might be null in some case, for example .pages file on safari
      if (mimeType == null || mimeType === "") {
        mimeType = "application/octet-stream";
      }

      const presignedPayload: IFilePresignedData = {
        file: {
          name: file.name,
          extension: fileExtension,
          mimeType,
          width: img?.width,
          height: img?.height,
        },
      };
      if (metadata.mediaType === FileType.VoiceNote) {
        presignedPayload.file.type = FileType.VoiceNote;
      }

      if (metadata.duration != null) {
        presignedPayload.file.duration = metadata.duration;
      }
      setUploadStatus((prevStatus) => new Map(prevStatus).set(file.name, true));
      try {
        const presignedResponse = await api.files.getPresignedUrlToUpload(presignedPayload);

        const base64Prop = file.type.includes("image") ? img?.src : file.type.includes("audio") || file.type.includes("pdf") ? base64 : undefined;
        await axios.put(presignedResponse.data.url, file);
        const _fileData: InternalFile = {
          extension: fileExtension,
          id: presignedResponse.data.id,
          name: file.name,
          url: presignedResponse.data.url,
          width: img?.width,
          height: img?.height,
          base64: base64Prop,
        };

        if (metadata.mediaType === FileType.VoiceNote) {
          socket.on(SocketEvents.VOICE_NOTE_CONVERSION_FINISHED, (eventFile: { id: string }) => {
            if (eventFile.id === presignedResponse.data.id) {
              socket.off(SocketEvents.VOICE_NOTE_CONVERSION_FINISHED);
              setUploadStatus((prevStatus) => new Map(prevStatus).set(file.name, false));
              setFileData((prevData) => [...prevData, _fileData]);
              load(file.name);
            }
          });
        } else {
          setFileData((prevData) => [...prevData, _fileData]);
          setUploadStatus((prevStatus) => new Map(prevStatus).set(file.name, false));
          load(file.name);
        }

        quillEditor?.getEditor().focus();
      } catch (responseError: unknown) {
        if (responseError instanceof AxiosError) {
          error(responseError.message);
          const filepondFile = filepondRef.current?.getFiles().find((_file) => _file.filename === file.name);
          filepondRef.current?.removeFile(filepondFile);
          if (responseError.response?.status === 403) {
            const quotaData = responseError.response?.data.quotaData as IQuotaData;
            const type = LimitationResponseCodes.QUOTA_EXCEEDED;
            openBillingConfirmModal(type, quotaData);
          }
        }
        setUploadStatus((prevStatus) => new Map(prevStatus).set(file.name, false));
      }
    };
    return {
      process,
      error: async (error: FilePondErrorDescription) => {
        console.error("-> error", error);
      },
    };
  }, [files, fileData, filepondRef, socket]);
  return {
    filepondRef,
    isUploading,
    filePondServerConfig,
    onErrorHandler,
    getFiles,
    files,
    setFiles,
    uploadStatus,
    browseFile,
    onAddFileHandler,
    onRemoveFileHandler,
    onUploadWarningHandler,
    shouldAllowPasteAndDrop,
    conversationElement,
    isFilePondVisible,
    maxFiles,
  };
};
