import { type ITag, type ITask, type ITaskOne } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useAddTagOnTaskMutation = (taskId?: string, roomId?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (tag: ITag) => {
      const response = await api.tasks.addTag(taskId ?? "", tag.id);
      return response.data;
    },
    onMutate: (tag: ITag) => {
      queryClient.setQueryData<ITaskOne>([QueryKeys.TASK, taskId], (oldData) => {
        if (oldData == null) return oldData;

        if (oldData.tags == null) {
          return {
            ...oldData,
            tags: [tag],
          };
        }

        return {
          ...oldData,
          tags: [...oldData.tags, tag],
        };
      });

      queryClient.setQueriesData<ITask[]>(
        {
          exact: false,
          queryKey: [QueryKeys.TASKS],
        },
        (oldData) => {
          if (oldData == null) return oldData;

          return oldData.map((task) => {
            if (task.id !== taskId) return task;

            if (task.tags == null) {
              return {
                ...task,
                tags: [tag],
              };
            }

            return {
              ...task,
              tags: [...task.tags, tag],
            };
          });
        },
      );
    },
  });
};
