import { type IRoomIdName } from "@/api/client";
import { ChannelPickerPopinContentComponent } from "@/components/shared/channel/ChannelPickerPopinComponent/ChannelPickerPopinContentComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { type IChannelIdNameRoom } from "@/interfaces/channel";
import { type TippyProps } from "@tippyjs/react";
import { type FunctionComponent } from "react";

interface IChannelPickerPopinComponentProps {
  children: TippyProps["children"];
  isVisible: boolean;
  onCloseTooltip: () => void;
  onChannelSelected: (channel: IChannelIdNameRoom | null) => void;
  hasRemoveChannelOption?: boolean;
  room: IRoomIdName | null;
}

export const ChannelPickerPopinComponent: FunctionComponent<IChannelPickerPopinComponentProps> = ({
  children,
  isVisible,
  onCloseTooltip,
  onChannelSelected,
  hasRemoveChannelOption,
  room,
}) => {
  return (
    <TooltipComponent
      placement="bottom-start"
      visible={isVisible}
      onClickOutside={onCloseTooltip}
      arrow={false}
      interactive
      noPadding
      content={
        <ChannelPickerPopinContentComponent
          onChannelSelected={onChannelSelected}
          hasRemoveChannelOption={hasRemoveChannelOption}
          isVisible={isVisible}
          room={room}
        />
      }
    >
      {children}
    </TooltipComponent>
  );
};
