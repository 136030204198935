import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { FcGoogle } from "react-icons/fc";

interface ISigninButtonComponentProps {
  onClick: () => void;
}

export const SigninButtonComponent: FunctionComponent<ISigninButtonComponentProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <h3 className="mb-2  text-sm font-semibold">{t("roomPage.tabs.mails.loggedOut.signinButton.title")}</h3>
      <div>
        <ButtonComponent status="neutral" icon={<FcGoogle className="h-4 w-4" />} onClick={onClick}>
          {t("roomPage.tabs.mails.loggedOut.signinButton.label")}
        </ButtonComponent>
      </div>
    </div>
  );
};
