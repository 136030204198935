import { type ITaskStatus } from "@/api/client";
import { TaskStatusColumnPopinContentComponent } from "@/components/shared/tasks/TaskStatusColumnPopinComponent/TaskStatusColumnPopinContentComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { type TippyProps } from "@tippyjs/react";
import { type FunctionComponent } from "react";

interface ITaskStatusColumnPopinComponent {
  status: ITaskStatus;
  children: TippyProps["children"];
  isVisible: boolean;
  onCloseTooltip: () => void;
}

export const TaskStatusColumnPopinComponent: FunctionComponent<ITaskStatusColumnPopinComponent> = ({
  status,
  children,
  isVisible,
  onCloseTooltip,
}) => {
  return (
    <TooltipComponent
      placement="bottom-end"
      visible={isVisible}
      onClickOutside={onCloseTooltip}
      arrow={false}
      interactive
      noPadding
      content={<TaskStatusColumnPopinContentComponent status={status} />}
    >
      {children}
    </TooltipComponent>
  );
};
