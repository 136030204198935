import { type TaskPriorityEnum } from "@/api/client";
import { useState, type MouseEventHandler } from "react";

export const useTaskPriority = (priority: TaskPriorityEnum | null, onPriorityChange: (priority: TaskPriorityEnum | null) => void) => {
  const [isPriorityTooltipVisible, setIsPriorityTooltipVisible] = useState<boolean>(false);

  const onPriorityClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsPriorityTooltipVisible((isVisible) => !isVisible);
  };

  const onCloseTooltip = () => {
    setIsPriorityTooltipVisible(false);
  };

  const onPrioritySelected = (selectedPriority: TaskPriorityEnum | null) => {
    if (selectedPriority !== priority) {
      onPriorityChange(selectedPriority);
    }
    onCloseTooltip();
  };

  return { isPriorityTooltipVisible, onPriorityClick, onCloseTooltip, onPrioritySelected };
};
