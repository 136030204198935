import {
  type IAuthConfirmEmail,
  type IAuthForgotPassword,
  type IAuthRegisterLogin,
  type IAuthResetPassword,
  type IOrganisationMember,
} from "@/api/client";
import { api } from "@/services/HttpService";
interface ILoginPayload {
  email: string;
  password: string;
}

export interface IUser {
  avatarUrl?: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  slug: string;
  email: string;
  status: string;
  provider: string;
  socialId?: Record<string, unknown>;
  firstName: string;
  lastName: string;
  position: string;
  phone: string;
  organisations?: IOrganisationMember[];
  mainOrganisation: IOrganisationMember;
}

export interface IRegisterPayload {
  email: string;
  password: string;
  provider: string;
  firstName: string;
  lastName: string;
  hash?: string;
}

export interface ISignUpResponse {
  operation: string;
  user: IUser;
  accessToken: string;
  refreshToken: string;
}

export const setTokens = (data: { accessToken: string; refreshToken: string }): void => {
  localStorage.setItem("token", data.accessToken);
  localStorage.setItem("refreshToken", data.refreshToken);
  api.setSecurityData(`Bearer ${data.accessToken}`);
};

const removeTokens = (): void => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
};

export const login = async (data: ILoginPayload) => {
  const response = await api.auth.login(data, { secure: false });
  setTokens(response.data);
  return response.data;
};

export const refreshToken = async () => {
  try {
    const response = await api.auth.refreshToken({
      refreshToken: localStorage.getItem("refreshToken") ?? "",
    });
    setTokens(response.data);
  } catch (error) {
    await logout();
  }
};

export const register = async (data: IAuthRegisterLogin) => {
  const response = await api.auth.register(data);
  return response.data;
};

export const confirmEmail = async (token: string) => {
  const data: IAuthConfirmEmail = { hash: token };
  const response = await api.auth.confirmEmail(data);
  return response.data;
};

export const requestPasswordReset = async (email: string) => {
  const data: IAuthForgotPassword = { email };
  const response = await api.auth.forgotPassword(data);
  return response.data;
};

export const resetPassword = async (token: string, password: string) => {
  const data: IAuthResetPassword = { hash: token, password };
  const response = await api.auth.resetPassword(data);
  return response.data;
};

export const logout = async () => {
  removeTokens();
  return await api.auth.logout({ device: "web" });
};
