import { type TaskPriorityEnum } from "@/api/client";
import { useTaskPriority } from "@/components/shared/tasks/TaskPriorityComponent/useTaskPriority";
import { TaskPriorityItemComponent } from "@/components/shared/tasks/TaskPriorityItemComponent";
import { TaskPriorityPopinComponent } from "@/components/shared/tasks/TaskPriorityPopinComponent/TaskPriorityPopinComponent";
import { type FunctionComponent } from "react";

interface ITaskPriorityComponentProps {
  priority: TaskPriorityEnum | null;
  onPriorityChange: (priority: TaskPriorityEnum | null) => void;
  displayNoPriority?: boolean;
  isSelector?: boolean;
}

export const TaskPriorityComponent: FunctionComponent<ITaskPriorityComponentProps> = ({
  priority,
  onPriorityChange,
  displayNoPriority,
  isSelector,
}) => {
  const { isPriorityTooltipVisible, onPriorityClick, onCloseTooltip, onPrioritySelected } = useTaskPriority(priority, onPriorityChange);
  return (
    <TaskPriorityPopinComponent
      isVisible={isPriorityTooltipVisible}
      priority={priority}
      onCloseTooltip={onCloseTooltip}
      onPrioritySelected={onPrioritySelected}
    >
      <div className="flex cursor-pointer flex-row items-center" onClick={onPriorityClick}>
        <TaskPriorityItemComponent priority={priority} displayNoPriority={displayNoPriority} isSelector={isSelector} />
      </div>
    </TaskPriorityPopinComponent>
  );
};
