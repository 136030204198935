import { useUpdateConversationNotificationSettingsMutation } from "@/hooks/mutations/notificationSettings/useUpdateConversationNotificationSettingsMutation";
import { useGeneralNotificationSettingsQuery } from "@/hooks/queries/notificationSettings/useGeneralNotificationSettingsQuery";
import { useCallback, useMemo } from "react";

export const useDmNotificationSettings = (conversationId: string) => {
  const { data: generalNotificationSettings, isLoading } = useGeneralNotificationSettingsQuery();
  const { mutate } = useUpdateConversationNotificationSettingsMutation(null);

  const dmSetting = useMemo(() => {
    if (conversationId == null) return undefined;
    return generalNotificationSettings?.conversationNotificationSettings?.find((settings) => settings.id === conversationId);
  }, [generalNotificationSettings, conversationId]);

  const isNotificationEnabled = useMemo(() => {
    if (conversationId == null || dmSetting?.notificationSettings == null) return true;

    return dmSetting.notificationSettings.enableNotifications;
  }, [dmSetting, conversationId]);

  const onToggle = useCallback(
    (_isNotificationEnabled: boolean) => {
      if (conversationId == null) return;
      mutate({ conversationId, isNotificationEnabled: _isNotificationEnabled });
    },
    [conversationId],
  );

  return { onToggle, isNotificationEnabled, isLoading };
};
