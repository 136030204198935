import { type HTMLAttributes } from "react";

interface SimpleBadgeProps extends HTMLAttributes<HTMLSpanElement> {
  topClass?: string;
  rightClass?: string;
  sizeClass?: string;
}
export const SimpleBadge = ({ topClass = "top-0", rightClass = "right-0", sizeClass = "w-3" }: SimpleBadgeProps) => {
  const classes = `${topClass} ${rightClass} ${sizeClass}`;
  return <span className={`absolute z-50 aspect-square rounded-full bg-red-600 text-red-100 ${classes}`}></span>;
};
