import { PlansTable } from "@/components/billing/plans/PlansTable";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { closeModal } from "@/components/shared/modal/ModalService";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { ModalNames } from "@/constants/modalNames";
import { usePortalUrlMutation } from "@/hooks/mutations/billing/usePortalUrlMutation";
import { type IModalAction } from "@/interfaces/modal";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IPlansTabComponentProps {
  isAutoOpening?: boolean;
}

export const PlansTabComponent = ({ isAutoOpening = true }: IPlansTabComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { mutateAsync: getPortalUrl } = usePortalUrlMutation();

  const validateFreePlanAction = useCallback(() => {
    closeModal(ModalNames.PLANS);
  }, []);

  const actions: IModalAction[] = useMemo(() => {
    if (!isAutoOpening) return [];
    return [
      {
        action: validateFreePlanAction,
        label: t("billing.modals.plans.actions.continueFree"),
        type: "secondary",
        disabled: false,
      },
    ];
  }, []);

  const handleGoToBilling = useCallback(async () => {
    const { url } = await getPortalUrl();
    if (url == null) return;
    window.open(url, "_blank");
  }, []);

  const footerInfo = useMemo(
    () => (
      <div className="flex">
        <button type="button" className="text-sm text-sky-600 underline" onClick={handleGoToBilling}>
          {t("header.editOrg.modal.tabs.plans.invoices")}
        </button>
        {!isAutoOpening ? (
          <div className="ml-auto flex h-full items-center justify-center">
            <div className="ml-auto mt-0.5 text-[13px]">{t("header.editOrg.modal.tabs.plans.footerInfo")}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    ),
    [isAutoOpening],
  );

  return (
    <ModalTabComponent footer={<ModalFooterComponent actions={actions} info={footerInfo}></ModalFooterComponent>}>
      <PlansTable />
    </ModalTabComponent>
  );
};
