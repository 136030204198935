import { type DeltaContent, type IAbility, type IChannelMember, type IRoomMember, type IUser } from "@/api/client";
import { type IFormattedChannel } from "@/interfaces/channel";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <T extends (...args: any[]) => void>(func: T, wait: number) => {
  let timeout: number;
  return (...args: Parameters<T>): void => {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      func.apply(null, args);
    }, wait);
  };
};

export const isMailValid = (email: string): boolean => {
  return /^[\w-.+]+@([\w-]+\.)+[\w-]{2,64}$/g.test(email);
};

export const canPerform = (abilities: IAbility[] | undefined, action: string): boolean => {
  if (abilities == null) return false;
  return abilities.some((ability) => ability.slug === action);
};

export const filterMembersBySearch = <
  P extends {
    firstName?: string;
    lastName?: string;
    email: string;
  },
>(
  members: P[] | null | undefined,
  search: string | null | undefined,
) => {
  if (search == null || search === "") return members ?? [];
  return (members ?? []).filter(
    (member) =>
      (member.firstName?.toLowerCase().includes(search.toLowerCase()) ?? false) ||
      (member.lastName?.toLowerCase().includes(search.toLowerCase()) ?? false) ||
      (member.email.toLowerCase().includes(search.toLowerCase()) ?? false) ||
      `${member.firstName ?? ""} ${member.lastName ?? ""}`.toLowerCase().includes(search.toLowerCase()) ||
      `${member.lastName ?? ""} ${member.firstName ?? ""}`.toLowerCase().includes(search.toLowerCase()),
  );
};

export const mapUsersToMembers = (users: IUser[]): Array<Partial<IRoomMember>> => {
  return users.map((user) => ({
    id: user.id,
    userId: user.id,
    userSlug: user.slug,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    position: user.position,
  }));
};

export const getRandomColorFromString = (input: string): string => {
  const hash = input.split("").reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
  return `hsl(${hash % 360}, 80%, 40%)`;
};

// TODO: TO REMOVE WHEN THE API CHANGE THE RESPONSE

export const formatChannelMember = (member: IChannelMember): IFormattedChannel => {
  return {
    createdAt: member.createdAt,
    id: member.id,
    userId: member.user.id,
    userSlug: member.user.slug,
    email: member.user.email,
    firstName: member.user.firstName,
    lastName: member.user.lastName,
    phone: member.user.phone,
    position: member.user.position,
    channelId: member.channel.id,
    channel: member.channel,
    role: member.role,
    userProfilePicture: member.user.profilePicture,
  };
};

export const formatChannelMembers = (members: IChannelMember[]): IFormattedChannel[] => {
  return members.map((member) => formatChannelMember(member));
};

export const addCommasSeparator = (array?: string[] | undefined, andString?: string, limit?: number, moreString?: string) => {
  if (array == null || array.length === 0) return "";

  const arrayCopy = [...array];

  if (arrayCopy.length === 1) {
    return arrayCopy[0];
  }

  const and = andString == null ? ", " : ` ${andString} `;

  if (limit != null && limit >= 2 && arrayCopy.length > limit) {
    const firstElements = arrayCopy.slice(0, limit - 1);
    const remainingCount = arrayCopy.length - limit + 1;
    return `${firstElements.join(", ")}${and}${remainingCount} ${moreString}`;
  }

  const lastElement = arrayCopy.pop();
  return `${arrayCopy.join(", ")}${and}${lastElement}`;
};

export const getTypeOfFileFromExtension = (extension: string): string => {
  switch (extension.toLowerCase()) {
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
    case "heic":
    case "bmp":
      return "image";
    case "mp4":
    case "mov":
    case "avi":
    case "wmv":
    case "flv":
    case "webm":
      return "video";
    case "mp3":
    case "wav":
    case "ogg":
    case "flac":
    case "weba":
      return "audio";
    case "pdf":
      return "pdf";
    case "doc":
    case "docx":
    case "xls":
    case "xlsx":
    case "ppt":
    case "pptx":
    case "txt":
    case "rtf":
      return "document";
    default:
      return "file";
  }
};

export const getDecodedImage = async (base64: string) => {
  const img = new Image();
  img.src = base64;
  await img.decode();
  return img;
};

export const orderById = <T extends { id: string }>(items?: T[]): T[] => {
  if (items == null) return [];
  return items.sort((a, b) => {
    if (a.id === b.id) return 0;
    return a.id > b.id ? 1 : -1;
  });
};

export const isDeltaContentEmpty = (delta: DeltaContent): boolean => {
  if (delta?.ops == null) return true;
  const cleanedOps = delta.ops.filter((op) => typeof op.insert === "object" || op.insert?.trim() !== "");
  return cleanedOps.length === 0;
};

export const isDeltaContentChanged = (oldDelta: DeltaContent, newDelta: DeltaContent): boolean => {
  if (oldDelta.ops == null || newDelta.ops == null) return true;

  if (oldDelta.ops.length !== newDelta.ops.length) return true;

  return JSON.stringify(oldDelta) !== JSON.stringify(newDelta);
};

export const dispatchCustomWindowEvent = <T>(eventName: string, detail?: T) => {
  window.dispatchEvent(
    new CustomEvent<T>(eventName, {
      detail,
    }),
  );
};
