import { useEffect, useMemo, useRef, useState } from "react";
import removeAccents from "remove-accents";

export const useFileExtensionPickerPopinContent = (isVisible: boolean, fileExtensions: string[]) => {
  const [search, setSearch] = useState<string>("");
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isVisible) {
      ref.current?.focus();
    }
  }, [isVisible]);

  const extensions = useMemo(() => {
    return fileExtensions
      .filter((fileExtension) => removeAccents(fileExtension.toLowerCase()).includes(removeAccents(search.toLowerCase())))
      .sort((a: string, b: string) => a.localeCompare(b));
  }, [search, fileExtensions]);

  const onSearchChange = (value: string) => {
    setSearch(value);
  };

  return {
    extensions,
    search,
    onSearchChange,
    ref,
  };
};
