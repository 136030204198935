import { type INotificationSettingsSetByGeneralBody } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { captureException } from "@sentry/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateGeneralNotificationSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: INotificationSettingsSetByGeneralBody) => {
      const { data } = await api.my.setNotificationSettingsByGeneral(params);

      return data;
    },
    onError: (error: Error) => {
      captureException(error);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.ME, QueryKeys.NOTIFICATION_SETTINGS] });
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, QueryKeys.NOTIFICATION_SETTINGS] });
    },
  });
};
