import { type FunctionComponent } from "react";
import { RiCloseFill as CloseIcon } from "react-icons/ri";

type TagType = "primary" | "secondary";
interface TagComponentProps {
  IconComponent?: FunctionComponent;
  value: string;
  type?: TagType;
  onRemove?: () => void;
  onClick?: () => void;
  description?: string;
}

export const TagComponent: FunctionComponent<TagComponentProps> = ({ IconComponent, value, onRemove, type = "primary", onClick, description }) => {
  const primaryClassName = "bg-zinc-100 text-zinc-600 border";
  const secondaryClassName = "bg-zinc-50 text-zinc-500 border border-dashed";

  const typeClassByType: Record<TagType, string> = {
    primary: primaryClassName,
    secondary: secondaryClassName,
  };

  return (
    <div
      className={`${typeClassByType[type]} mb-1 flex h-10 flex-row items-center justify-between rounded px-2 ${
        onClick != null ? "cursor-pointer hover:opacity-80" : ""
      }`}
      onClick={onClick}
    >
      <div className="flex flex-row items-center">
        {IconComponent != null ? <IconComponent /> : null}
        <div className="mx-2 flex flex-col">
          <span className="text-xs font-normal text-zinc-600">{value}</span>
          {description != null ? <span className="text-xs font-normal text-zinc-400">{description}</span> : null}
        </div>
      </div>
      {onRemove != null ? <CloseIcon className="h-5 w-5 cursor-pointer hover:opacity-80" onClick={onRemove} /> : null}
    </div>
  );
};
