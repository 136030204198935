import { type IEmail, type IEmailThread, type INotification, type INotificationAllResponse } from "@/api/client";
import { useSystemMessage } from "@/components/shared/messages/useSystemMessage";
import { DEFAULT_NOTIFICATION_SOUND_VOLUME } from "@/constants/magicNumbers";
import { notificationReadStatus, notificationType } from "@/constants/notification";
import { QueryKeys } from "@/constants/queryKeys";
import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { useGetEmail } from "@/hooks/mutations/emails/useGetEmail";
import { useGetThread } from "@/hooks/mutations/emails/useGetThread";
import { useEditNotificationsQueries } from "@/hooks/shared/notifications/useEditNotificationsQueries";
import { useFormatNotificationContent } from "@/hooks/shared/useFormatNotificationContent";
import { useIsElectron } from "@/hooks/shared/useIsElectron";
import { useNotificationNavigation } from "@/hooks/shared/useNotificationNavigation";
import { PreferencesContext } from "@/hooks/shared/usePreferences";
import { useQueryClient, type InfiniteData } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, type PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
export const NotificationsSockets = ({ children }: PropsWithChildren): JSX.Element => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { navigateToContext } = useNotificationNavigation();
  const socket = useContext(SocketContext);
  const { preferences: preferencesContext } = useContext(PreferencesContext);
  const { isElectron } = useIsElectron();
  const { formatNotificationContent } = useFormatNotificationContent();
  const { removeNotificationsFromQuery, addNotificationsToQuery, updateNotificationInQuery } = useEditNotificationsQueries();
  const { joinVideoCall } = useSystemMessage();
  const { mutateAsync: getThread } = useGetThread();
  const { mutateAsync: getEmail } = useGetEmail();

  const checkNotificationPermission = () => {
    try {
      void Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  };
  const playAudio = useCallback(() => {
    if (isElectron) return;
    const audio = new Audio("/sounds/sebastodrop.mp3");
    audio.volume = preferencesContext?.notificationSoundVolume ?? DEFAULT_NOTIFICATION_SOUND_VOLUME;
    audio.play().catch((e) => {
      console.warn(e);
    });
  }, [preferencesContext]);

  useEffect(() => {
    checkNotificationPermission();
  }, []);

  const sendNotification = async (notification: INotification) => {
    playAudio();

    let thread: IEmailThread | undefined;
    let email: IEmail | undefined;
    if (notification.type === notificationType.NEW_EMAIL) {
      thread = await getThread(notification.emailThreadId ?? "");
      email = await getEmail(notification.emailId ?? "");
    }

    if (checkNotificationPermission()) {
      const notificationTitle = t("notifications.new");
      const notificationOptions = {
        body: `${formatNotificationContent(notification, email)}`,
        silent: !isElectron,
      };
      const notificationObject = new Notification(notificationTitle, notificationOptions);
      notificationObject.onclick = async () => {
        window.focus();
        void navigateToContext(notification, thread, email);

        const systemData = notification.message?.systemData;

        if (systemData?.subtype === "new-videoconference") {
          const { jitsiDomain, meetingRoomId } = systemData;

          joinVideoCall(jitsiDomain, meetingRoomId);
        }

        try {
          await window.electron.invoke("notification-click");
        } catch (e) {
          console.warn(e);
        }
        notificationObject.close();
      };
    }
  };

  const onUpdatedNotification = (notification: INotification) => {
    const queries = queryClient.getQueriesData<InfiniteData<INotificationAllResponse>>({
      queryKey: [QueryKeys.ME, QueryKeys.NOTIFICATIONS],
    });

    const allNotifications = queries.flatMap((query) => query[1]?.pages).flatMap((page) => page?.data);
    const foundNotification = allNotifications.find((searchedNotification) => searchedNotification?.id === notification.id);

    if (foundNotification == null) {
      addNotificationsToQuery([notification], notificationReadStatus.UNREAD);
      void sendNotification(notification);
      return;
    }

    if (foundNotification.isRead !== notification.isRead) {
      void readOrUnread([notification]);
      if (foundNotification.bundleCount !== notification.bundleCount) {
        void sendNotification(notification);
      }
      return;
    }
    updateNotificationInQuery(notification);
    if (foundNotification.bundleCount !== notification.bundleCount) {
      void sendNotification(notification);
    }
  };

  const onNewNotification = (notification: INotification) => {
    void sendNotification(notification);
    invalidateNotificationQueries();
  };

  const readOrUnread = async (notifications: INotification[]) => {
    removeNotificationsFromQuery(notifications, notifications[0].isRead ? notificationReadStatus.UNREAD : notificationReadStatus.READ);
    addNotificationsToQuery(notifications, notifications[0].isRead ? notificationReadStatus.READ : notificationReadStatus.UNREAD);
  };

  const invalidateNotificationQueries = () => {
    void queryClient.invalidateQueries({
      queryKey: [QueryKeys.ME, QueryKeys.NOTIFICATIONS],
    });
  };

  useEffect(() => {
    if (socket == null) return;
    socket.on(SocketEvents.UPDATED_NOTIFICATION, onUpdatedNotification);
    socket.on(SocketEvents.NEW_NOTIFICATION, onNewNotification);

    return () => {
      socket.off(SocketEvents.UPDATED_NOTIFICATION, onUpdatedNotification);
      socket.off(SocketEvents.NEW_NOTIFICATION, onNewNotification);
    };
  }, [socket, preferencesContext]);

  return <>{children}</>;
};
