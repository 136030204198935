import { type IChannelMember } from "@/api/client";
import { ConversationEmojiPickerComponent } from "@/components/roomPage/tabs/conversation/ConversationLists/ConversationEmojiPicker/ConversationEmojiPickerComponent";
import { NavLinkElementComponent } from "@/components/roomPage/tabs/conversation/ConversationLists/conversationListElement/NavLinkElementComponent";
import { useChannelListElement } from "@/components/roomPage/tabs/conversation/ConversationLists/conversationListElement/useChannelListElement";

interface IChannelListElementComponentProps {
  conversation: IChannelMember;
  closeList: (event: Event) => void;
  isHighlighted?: boolean;
  badge?: number;
  isDragging?: boolean;
}

export const ChannelListElementComponent = ({
  conversation,
  closeList,
  isHighlighted = false,
  badge = 0,
  isDragging = false,
}: IChannelListElementComponentProps): JSX.Element => {
  const { getConversationInfo, routeToDestination, shouldShowDisabledBell, key, isDefaultChannel, attributes, listeners, setNodeRef, style } =
    useChannelListElement(conversation);

  return (
    <div ref={!isDefaultChannel ? setNodeRef : null} style={style} {...attributes} {...listeners}>
      <NavLinkElementComponent
        isDragging={isDragging}
        to={routeToDestination}
        closeList={closeList}
        label={getConversationInfo(conversation).name}
        iconElement={<ConversationEmojiPickerComponent channelId={conversation.channel.id} canEdit={!isDefaultChannel} emoji={conversation.emoji} />}
        showDisabledBell={shouldShowDisabledBell}
        isHighlighted={isHighlighted}
        key={key}
        badge={badge}
        className="text-[13px]"
      />
    </div>
  );
};
