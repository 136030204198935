import { ModalLayoutComponent } from "@/components/shared/modal/ModalLayoutComponent";
import { CropImageTab } from "@/components/shared/uploader/cropImageModal/tabs/CropImageTab";
import { ModalNames } from "@/constants/modalNames";
import { type FilePondFile } from "filepond";
import { useMemo } from "react";

export interface CropImageModalProps {
  title: string;
  imageBase64: string;
  file: FilePondFile;
  onSubmit: (canvas: HTMLCanvasElement, file: FilePondFile) => void;
}

export const CropImageModal = ({ imageBase64, onSubmit, file, title }: CropImageModalProps): JSX.Element => {
  const tabs = useMemo(() => {
    return [
      {
        label: title,
        content: (
          <CropImageTab
            imageBase64={imageBase64}
            onSubmit={(canvas) => {
              onSubmit(canvas, file);
            }}
          />
        ),
        id: "default",
        disabled: false,
      },
    ];
  }, [imageBase64, onSubmit, file]);

  return <ModalLayoutComponent tabs={tabs} title={title} size="lg" name={ModalNames.CROP_IMAGE}></ModalLayoutComponent>;
};
