import { useOrganisationSettingsTab } from "@/components/homePage/modals/room/tabs/OrganisationSettingTab/useOrganisationSettingsTab";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { SettingLineComponent } from "@/components/shared/settings/SettingLineComponent";
import { useTranslation } from "react-i18next";

interface IOrganisationSettingsTabComponentProps {
  organisationId: string;
}
export const OrganisationSettingsTabComponent = ({ organisationId }: IOrganisationSettingsTabComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { onToggleRoomCreationToggle, isRoomCreationEnabled, isOrgRoomHiddenEnabled, onOrgRoomHiddenToggle } =
    useOrganisationSettingsTab(organisationId);

  return (
    <ModalTabComponent key={organisationId}>
      <>
        <h3 className="text-md mb-1 flex items-center font-bold text-zinc-800 dark:text-dark-300">
          {t("createRoomModal.tabs.organisationSettings.permissions.title")}
        </h3>
        <SettingLineComponent
          onToggle={onToggleRoomCreationToggle}
          isEnabled={isRoomCreationEnabled}
          className="font-normal"
          title={t("createRoomModal.tabs.organisationSettings.permissions.enableRoomCreation.title")}
        />
        <SettingLineComponent
          onToggle={onOrgRoomHiddenToggle}
          isEnabled={isOrgRoomHiddenEnabled}
          className="font-normal"
          title={t("createRoomModal.tabs.organisationSettings.permissions.orgRoomDisplay.title")}
        />
      </>
    </ModalTabComponent>
  );
};
