import { type IDirectMessage } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect } from "react";

export const useDMUpdate = (roomId?: string | undefined) => {
  const socket = useContext(SocketContext);
  const queryClient = useQueryClient();
  const onDMUpdated = useCallback(
    (updatedDM: { data: IDirectMessage; sentFromRoom: { id: string } }) => {
      const queryKey = roomId != null ? [QueryKeys.ROOMS, QueryKeys.DMS, roomId] : [QueryKeys.MY_DMS];
      queryClient.setQueryData<IDirectMessage[]>(queryKey, (oldData) => {
        if (oldData == null) return [updatedDM.data];
        const isExisting = oldData.some((directMessage) => directMessage.id === updatedDM.data.id);
        const isHomeDm = roomId == null;
        const isFromSameRoom = roomId === updatedDM.sentFromRoom?.id;
        if ((!isExisting && isFromSameRoom) || (!isExisting && isHomeDm)) {
          return [...oldData, updatedDM.data];
        }

        return (oldData ?? []).map((directMessage) => {
          if (directMessage.id === updatedDM.data.id) {
            return updatedDM.data;
          }

          return directMessage;
        });
      });
    },
    [roomId],
  );

  useEffect(() => {
    if (socket == null) return;
    socket.on(SocketEvents.DIRECT_MESSAGE_UPDATED, onDMUpdated);

    return () => {
      socket.off(SocketEvents.DIRECT_MESSAGE_UPDATED, onDMUpdated);
    };
  }, [socket, roomId]);
};
