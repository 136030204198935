import { type ISearchMessagesBody, type ISearchMessagesResponse } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useSearchMessagesQuery = (parameters: Omit<ISearchMessagesBody, "nextCount" | "cursor">) => {
  return useInfiniteQuery<ISearchMessagesResponse>({
    queryKey: [QueryKeys.GLOBAL_SEARCH, QueryKeys.MESSAGES, parameters],
    queryFn: async ({ pageParam = "" }) => {
      if (parameters.messageSearchContent === "") {
        return { data: [], pagination: { nextCursor: null, totalCount: 0 } };
      }
      const body: ISearchMessagesBody = {
        ...parameters,
        nextCount: 20,
      };
      if (pageParam !== "") {
        body.cursor = pageParam as string;
      }

      const response = await api.search.searchMessages(body);

      return response.data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.pagination.nextCursor ?? undefined;
    },
    staleTime: 0,
    initialPageParam: "",
  });
};
