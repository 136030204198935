import { format, formatDistanceToNowStrict, isAfter, subDays } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface MessageDateComponentProps {
  date: Date;
  hasRelativeDate: boolean;
  nowDate: Date;
  hasFullDate: boolean;
}

const dateLocales: Record<string, Locale> = { fr, en: enUS };

export const MessageDateComponent: FunctionComponent<MessageDateComponentProps> = ({ date, hasRelativeDate, nowDate, hasFullDate }) => {
  const { t, i18n } = useTranslation();
  const locale: Locale = dateLocales[i18n.language] ?? enUS;

  if (hasRelativeDate) {
    const date24HoursBefore = subDays(nowDate, 1);
    if (isAfter(date, date24HoursBefore)) {
      const relativeDate = formatDistanceToNowStrict(date, { locale });

      return t("date.relativeDate", {
        date: relativeDate,
      });
    }
  }

  if (hasFullDate) {
    return format(date, t("date.fullDateWithHourFormat"));
  }

  return format(date, t("date.timeFormat"));
};
