import { ConversationMemberStatus, RoleType } from "@/api/client";
import { ChannelModalComponent } from "@/components/roomPage/modals/channel/ChannelModalComponent";
import { type CustomSelectOption } from "@/components/shared/form/select/CustomSelectComponent";
import { openModal } from "@/components/shared/modal/ModalService";
import { eventNames } from "@/constants/eventNames";
import { ModalNames } from "@/constants/modalNames";
import { ConversationContext } from "@/contexts/ConversationContext";
import { RoomContext } from "@/contexts/RoomContext";
import { useCallback, useContext, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { type StylesConfig } from "react-select";
import { type OptionProps } from "react-select/dist/declarations/src/components/Option";
import colors from "tailwindcss/colors";

export const useChannelList = () => {
  const roomContext = useContext(RoomContext);
  const room = useMemo(() => roomContext?.roomResponse?.room, [roomContext]);
  const member = useMemo(() => roomContext?.roomResponse?.member, [roomContext]);
  const { t } = useTranslation();
  const conversationContext = useContext(ConversationContext);
  const conversationId = useMemo(() => conversationContext.conversation?.id, [conversationContext]);

  const closeChannelList = useCallback((): void => {
    const customEvent = new CustomEvent(eventNames.TOGGLE_CHANNEL_LIST, {
      detail: false,
    });

    window.dispatchEvent(customEvent);
  }, []);

  const addChannel = useCallback(
    (event: Event): void => {
      if (member?.role.type === RoleType.Guest) {
        toast.error(t("channelModal.error.guest"));
        return;
      }
      if (room == null) return;

      event.preventDefault();
      openModal(ModalNames.CHANNEL_MODAL, <ChannelModalComponent title={t("channelModal.title")} roomId={room.id} conversationId={conversationId} />);
    },
    [room, member],
  );

  const handleSelectChannelsStatus = useCallback((value: string) => {
    const event = new CustomEvent(eventNames.SELECT_CHANNEL_STATUS, { detail: value });
    window.dispatchEvent(event);
  }, []);

  const conversationStatusOptions = [
    { value: ConversationMemberStatus.Confirmed, label: t("general.channel_other") },
    { value: ConversationMemberStatus.Archived, label: t("general.archivedChannel_other") },
  ];

  const customSelectStyles: StylesConfig<CustomSelectOption<ConversationMemberStatus>> = {
    // @ts-expect-error todo
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%",
    }),
    indicatorSeparator: () => ({ display: "none" }),
    // @ts-expect-error todo
    dropdownIndicator: (previous) => ({ ...previous, padding: "0", color: colors.stone[600] }),
    // @ts-expect-error todo
    valueContainer: (previous, props) => ({
      ...previous,
      backgroundColor: "transparent",
      padding: "0",
    }),
    // @ts-expect-error todo
    placeholder: (previous) => ({
      ...previous,
      color: colors.stone[500],
    }),
    // @ts-expect-error todo
    control: (previous) => ({
      ...previous,
      boxShadow: "none",
      fontSize: "0.875rem",
      height: "42px",
      minHeight: 0,
      padding: "0",
      color: colors.stone[500],
      borderWidth: "0",
      backgroundColor: "transparent",
      cursor: "pointer",
    }),
    // @ts-expect-error todo
    option: (previous, state: OptionProps) => {
      return {
        ...previous,
        fontSize: "12px",
        textAlign: "left",
        color: colors.stone[500],
        backgroundColor: state.isSelected ? colors.zinc[100] : "white",
        cursor: "pointer",
      };
    },
    // @ts-expect-error todo
    singleValue: (previous) => ({
      ...previous,
      color: colors.zinc[800],
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
    }),
    // @ts-expect-error todo
    clearIndicator: (previous) => ({
      ...previous,
      color: colors.stone[500],
      cursor: "pointer",
      zIndex: 100000,
      padding: "0",
    }),
  };

  return {
    closeChannelList,
    addChannel,
    handleSelectChannelsStatus,
    customSelectStyles,
    conversationStatusOptions,
  };
};
