import { type IChannelMember } from "@/api/client";
import { useConversationListElement } from "@/components/roomPage/tabs/conversation/ConversationLists/conversationListElement/useConversationListElement";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMemo } from "react";

export const useChannelListElement = (conversation?: IChannelMember) => {
  const { getConversationInfo, routeToDestination, shouldShowDisabledBell, key } = useConversationListElement("channel", conversation);

  const isDefaultChannel = useMemo(() => {
    if (conversation == null) return false;
    return conversation.channel.type === "default";
  }, [conversation]);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: conversation?.id ?? "",
    data: { channelId: conversation?.channel.id },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return {
    getConversationInfo,
    routeToDestination,
    shouldShowDisabledBell,
    key,
    isDefaultChannel,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    style,
  };
};
