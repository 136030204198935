import { type ApiUnprocessableEntityException, type IAuthBaseResponse, type IAuthRegisterLogin, type IInvitation } from "@/api/client";
import { defaultRoleSlug } from "@/constants/roles";
import { useGoogleSignIn } from "@/hooks/authentification/useGoogleSignIn";
import { useGoogleLoginMutation } from "@/hooks/mutations/google/useGoogleLoginMutation";
import { useUtm } from "@/hooks/utm/useUtm";
import { setTokens } from "@/services/AuthService";
import { api } from "@/services/HttpService";
import { emailValidation, nameValidation, passwordValidation } from "@/utils/formValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useForm, type SubmitErrorHandler, type SubmitHandler } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

export const useSignUpForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { getUtmParamsFromStorage } = useUtm();
  const invitationToken = params.token;

  const [invitation, setInvitation] = useState<IInvitation | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const errorsToToast = ["feature_not_supported_cannot_register_with_public_domain"];
  useEffect(() => {
    if (invitationToken != null && invitationToken !== "") {
      api.invitations
        .getByHash(invitationToken)
        .then((response) => {
          setInvitation(response.data);
        })
        .catch((error) => {
          // TODO use global error handler
          console.error(error);
        });
    }
  }, [invitationToken]);

  const inputs = useMemo(
    () => [
      {
        name: "firstName",
        label: t("forms.fields.firstName"),
        type: "search",
        requiredText: "",
        autoFocus: true,
      },
      {
        name: "lastName",
        label: t("forms.fields.lastName"),
        type: "search",
        requiredText: "",
      },
      {
        name: "email",
        label: t("forms.fields.workEmail"),
        autoComplete: "on",
        type: "email",
        requiredText: "",
        disabled: invitation != null,
        defaultValue: invitation?.email,
      },
      {
        name: "password",
        label: t("forms.fields.password"),
        type: "password",
        autoComplete: "on",
        requiredText: "",
      },
    ],
    [invitation],
  );
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: emailValidation(t, invitation?.email),
        password: passwordValidation(t),
        firstName: nameValidation(t, t("forms.fields.firstName")),
        lastName: nameValidation(t, t("forms.fields.lastName")),
      }),
    [invitation],
  );

  const {
    handleSubmit,
    register: formRegister,
    formState: { errors, isValid },
  } = useForm<IAuthRegisterLogin>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const handleLogin = (responseLogin: IAuthBaseResponse) => {
    if (responseLogin.accessToken != null) {
      const { accessToken, refreshToken } = responseLogin;
      setTokens({ accessToken, refreshToken });
      navigate("/dashboard");
      return;
    }

    navigate(
      `/register/complete/${responseLogin.user.email}${
        responseLogin.user.mainOrganisation?.roleSlug === defaultRoleSlug.ORGANISATION_USER && invitation == null
          ? `/${responseLogin.user.mainOrganisation.organisationSlug}`
          : ""
      }`,
    );
  };

  const onSubmit: SubmitHandler<IAuthRegisterLogin> = async (data) => {
    setIsLoading(true);
    const payload: IAuthRegisterLogin = { provider: "EMAIL", ...data };
    payload.utm = getUtmParamsFromStorage();

    if (invitation != null) {
      payload.hash = invitationToken;
    }
    try {
      const { data } = await api.auth.register(payload);

      handleLogin(data);
    } catch (error) {
      setIsLoading(false);
      if (axios.isAxiosError<ApiUnprocessableEntityException>(error)) {
        const domainOrStatusCode = error?.response?.data.details?.organisation?.domain ?? error.response?.data.statusCode ?? "unhandled";
        const errorKey = error?.response?.data.message ?? "unknown";

        if (errorsToToast.includes(errorKey)) {
          return toast.error(t(`authentication.signUpError.${errorKey}`), {
            id: "error",
            duration: 8000,
          });
        }
        navigate(`/register/error/${domainOrStatusCode}/${errorKey}`);
        return;
      }
      return toast.error(t("general.unknownError", { id: "error" }));
    }
  };
  const onError: SubmitErrorHandler<IAuthRegisterLogin> = (err) => {
    // TODO use global error handler
    toast.error("An error occurred, please try again", { id: "error" });
    setIsLoading(false);
    console.error(err);
  };

  const { mutate: googleSignIn, isPending: isGoogleLoginLoading } = useGoogleLoginMutation();

  const { signIn: signInWithGoogle } = useGoogleSignIn({
    onSuccess: (code) => {
      googleSignIn(
        {
          code,
          hasRedirectUri: false,
          hash: invitationToken,
          utm: getUtmParamsFromStorage(),
        },
        { onSuccess: handleLogin },
      );
    },
    onAppOpenedWithScheme: (code) => {
      googleSignIn(
        {
          code,
          hasRedirectUri: true,
          hash: invitationToken,
          utm: getUtmParamsFromStorage(),
        },
        { onSuccess: handleLogin },
      );
    },
  });

  return {
    inputs,
    errors,
    isValid,
    isLoading,
    formRegister,
    handleSubmit,
    onSubmit,
    onError,
    invitation,
    invitationToken,
    signInWithGoogle,
    isGoogleLoginLoading,
  };
};
