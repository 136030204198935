import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useConversationActiveMemberQuery = (conversationId: string | null | undefined, enabled?: boolean) => {
  return useQuery({
    queryKey: [QueryKeys.CONVERSATIONS, conversationId, QueryKeys.MEMBERS],
    queryFn: async () => {
      if (conversationId == null) return [];
      const response = await api.conversations.getAllActiveMembers(conversationId);
      return response.data.data;
    },
    enabled,
  });
};
