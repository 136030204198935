export enum DrawerType {
  NOTIFICATIONS_CENTER = "NOTIFICATIONS_CENTER",
  CREATE_TASK = "CREATE_TASK",
  EDIT_TASK = "EDIT_TASK",
  PIN_BOARD = "PIN_BOARD",
  CREATE_CALENDAR_EVENT = "CREATE_CALENDAR_EVENT",
}

export enum DrawerOverlayType {
  NONE = "NONE",
  TRANSPARENT = "TRANSPARENT",
  OPAQUE = "OPAQUE",
}

export const overlayByDrawerType: Record<DrawerType, DrawerOverlayType> = {
  [DrawerType.NOTIFICATIONS_CENTER]: DrawerOverlayType.NONE,
  [DrawerType.CREATE_TASK]: DrawerOverlayType.OPAQUE,
  [DrawerType.EDIT_TASK]: DrawerOverlayType.TRANSPARENT,
  [DrawerType.PIN_BOARD]: DrawerOverlayType.NONE,
  [DrawerType.CREATE_CALENDAR_EVENT]: DrawerOverlayType.OPAQUE,
};

export interface IDrawer {
  drawerType: DrawerType;
  component: JSX.Element;
}

export type OverlayCallback = (closeDrawer: () => void) => void;
