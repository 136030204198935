import Slider, { type SliderProps } from "rc-slider";
import "rc-slider/assets/index.css";
import { type FunctionComponent } from "react";
import colors from "tailwindcss/colors";

export const SliderComponent: FunctionComponent<SliderProps> = (props) => {
  const sliderStyles = {
    trackStyle: {
      backgroundColor: colors.sky[600],
    },
    handleStyle: {
      borderColor: colors.sky[600],
      opacity: 1,
    },
    railStyle: {
      backgroundColor: colors.slate[300],
    },
  };
  return <Slider {...sliderStyles} {...props} />;
};
