import { type IEmail, type IEmailIdentity } from "@/api/client";
import { EmailThreadContext } from "@/contexts/EmailThreadContextProvider";
import { useMeQuery } from "@/hooks/queries/me/useMeQuery";
import { addCommasSeparator } from "@/utils/utilities";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const useEmail = (email?: IEmail) => {
  const { t } = useTranslation();
  const { data: me } = useMeQuery();
  const { emailId: emailIdParam } = useParams();
  const myEmail = useMemo((): string => {
    if (me?.linkedGmailEmail == null) return "";
    return me.linkedGmailEmail;
  }, [me]);

  const senderName = useMemo((): string => {
    if (email?.from == null) return "";
    return email?.from.name != null && email.from.name !== "" ? email.from.name : email.from.email;
  }, [email]);

  const { setTo, to, setCc, setReplyToMailId } = useContext(EmailThreadContext);

  const namesOrEmailsFromRecipients = useMemo((): string => {
    if (email?.to == null && email?.cc == null) return "";
    const recipients = email?.to ?? email?.cc;
    const namesAndEmails = recipients.map((recipient) => (recipient.name !== "" && recipient.name != null ? recipient.name : recipient.email));
    return addCommasSeparator(namesAndEmails, t("roomPage.tabs.mails.and"), 2, t("roomPage.tabs.mails.more"));
  }, [email]);

  const ccWithoutMe = useMemo<IEmailIdentity[]>(() => {
    if (email?.cc == null || myEmail == null) return [];
    return email.cc.filter((cc) => cc.email !== myEmail);
  }, [email, myEmail]);

  const toWithoutMe = useMemo<IEmailIdentity[]>(() => {
    if (email?.to == null || myEmail == null) return [];

    const recipients = email.to.filter((recipient) => recipient.email !== myEmail);

    if (email.from.email !== myEmail) {
      recipients.push(email.from);
    }
    return recipients;
  }, [email, myEmail]);

  const isHighlighted = useMemo((): boolean => {
    if (email == null || emailIdParam == null) return false;
    return emailIdParam === email.id;
  }, [email, emailIdParam]);

  const [isVisible, setIsVisible] = useState(false);
  const onTooltipClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  const replyToEmail = useCallback(() => {
    if (myEmail === "" || email == null) return;

    setReplyToMailId(email.id);

    if (email.from.email === myEmail) {
      setTo([email.to[0]]);
    } else {
      setTo([email.from]);
    }
  }, [email, myEmail]);

  const replyAllToEmail = useCallback(() => {
    if (myEmail === "" || email == null) return;

    setReplyToMailId(email.id);

    setTo(toWithoutMe);
    setCc(ccWithoutMe);
  }, [email, myEmail]);

  return {
    setTo,
    namesOrEmailsFromRecipients,
    isVisible,
    setIsVisible,
    onTooltipClose,
    replyToEmail,
    replyAllToEmail,
    to,
    senderName,
    isHighlighted,
    emailIdParam,
  };
};
