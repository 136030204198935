import type { ITag } from "@/api/client";
import { TaskTagsContext } from "@/contexts/TaskTagsContextProvider";
import { useAddTagOnTaskMutation } from "@/hooks/mutations/tasks/tags/useAddTagOnTaskMutation";
import { useDeleteTagOnTaskMutation } from "@/hooks/mutations/tasks/tags/useDeleteTagOnTaskMutation";
import { useTagsQuery } from "@/hooks/queries/tags/useTagsQuery";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

export const useTagInput = (roomId: string, selectedTags: ITag[], taskId?: string, onFilterChange?: (tags: ITag) => void) => {
  const { mutate: addTagMutation } = useAddTagOnTaskMutation(taskId, roomId);
  const { mutate: deleteTagMutation } = useDeleteTagOnTaskMutation(taskId, roomId);
  const { addTagToContext, removeTagFromContext } = useContext(TaskTagsContext);

  const addTag = useCallback(
    (tag: ITag) => {
      if (taskId == null) {
        addTagToContext(tag);
        return;
      }
      addTagMutation(tag);
    },
    [taskId],
  );

  const deleteTag = useCallback(
    (tagId: string) => {
      if (taskId == null) {
        removeTagFromContext(tagId);
        return;
      }
      deleteTagMutation(tagId);
    },
    [taskId],
  );

  const [searchTerm, setSearchTerm] = useState("");
  const { data: tags, isLoading } = useTagsQuery({ roomId: roomId ?? "" });
  const [isVisible, setIsVisible] = useState(false);

  const selectedTagIds = useMemo(() => new Set(selectedTags.map((tag) => tag.id)), [selectedTags]);

  const initializedSortedTags = useMemo(() => {
    if (tags == null) return [];

    const sorted = tags.toSorted((a, b) => a.name.localeCompare(b.name));

    const selectedTags = sorted.filter((tag) => selectedTagIds.has(tag.id));
    const nonSelectedTags = sorted.filter((tag) => !selectedTagIds.has(tag.id));

    return [...selectedTags, ...nonSelectedTags];
  }, [tags, selectedTagIds]);

  const filteredAndSortedTags = useMemo(() => {
    return initializedSortedTags.filter((tag) => tag.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [initializedSortedTags, searchTerm]);

  const toggleTagSelection = useCallback(
    (tag: ITag) => {
      if (onFilterChange != null) {
        onFilterChange(tag);
      }
      if (selectedTagIds.has(tag.id)) {
        selectedTagIds.delete(tag.id);
        deleteTag?.(tag.id);
      } else {
        selectedTagIds.add(tag.id);
        addTag?.(tag);
      }
    },
    [selectedTagIds],
  );

  useEffect(() => {
    setSearchTerm("");
  }, [isVisible]);

  return {
    searchTerm,
    setSearchTerm,
    isLoading,
    isVisible,
    setIsVisible,
    filteredAndSortedTags,
    toggleTagSelection,
    selectedTagIds,
  };
};
