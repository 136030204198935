import { type IMessage } from "@/api/client";
import { JitsiContext } from "@/contexts/JitsiContextProvider";
import { useContext } from "react";

export const useSystemMessage = (message?: IMessage | undefined) => {
  const { joinVideoCall } = useContext(JitsiContext);

  const handleJoinVideoCall = () => {
    if (message?.systemData?.subtype === "new-videoconference") {
      const { jitsiDomain, meetingRoomId } = message.systemData;

      joinVideoCall(jitsiDomain, meetingRoomId);
    }
  };

  return {
    handleJoinVideoCall,
    joinVideoCall,
  };
};
