import create from "zustand";

interface ISignInStore {
  email: string;
  setEmail: (payload: string) => void;
}

export const useSignInStore = create<ISignInStore>((set) => ({
  email: "",
  setEmail: (payload) => {
    set({ email: payload });
  },
}));
