import { type IConversationMember, type IMessage } from "@/api/client";
import { useContext } from "react";

import { EmojiComponent } from "@/components/shared/emojis/EmojiComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useReaction } from "@/hooks/shared/useReaction";
import { addCommasSeparator } from "@/utils/utilities";
export const ReactionComponent = ({
  reaction,
  count,
  message,
  readOnly = false,
}: {
  reaction: string;
  count: number;
  message: IMessage;
  readOnly?: boolean;
}) => {
  const me = useContext(AuthenticationContext);
  const { hasReactedWithEmoji, selectEmoji } = useReaction();

  const handleEmojiSelect = async () => {
    if (readOnly) return;
    await selectEmoji({ shortcodes: reaction }, message);
  };

  const senders: IConversationMember[] = message.reactions?.[reaction]?.senders ?? [];
  const senderNames = addCommasSeparator(senders.map((sender) => `${sender.user.firstName as string} ${sender.user.lastName as string}`));
  const content = `${senderNames}`;

  return (
    <TooltipComponent content={content} placement="bottom" theme={undefined}>
      <div
        key={reaction}
        className={`${
          hasReactedWithEmoji(senders, me?.id) ? "bg-sky-100 text-black" : "bg-white text-zinc-500 "
        } reaction m-0.5 flex h-6 w-9 cursor-pointer items-center rounded-xl border border-gray-300/80 px-1 text-black transition-all hover:bg-sky-100`}
        onClick={handleEmojiSelect}
      >
        <EmojiComponent shortcodes={reaction} id="emoji" set="apple" />
        <span className="ml-1 text-2xs">{count}</span>
      </div>
    </TooltipComponent>
  );
};
