import { Switch } from "@headlessui/react";

interface IToggleComponentProps {
  isEnabled: boolean;
  onToggle: (isEnabled: boolean) => void;
  size?: "sm" | "md";
  activeIcon?: JSX.Element;
  inactiveIcon?: JSX.Element;
}

export const ToggleComponent = ({ size = "md", activeIcon, inactiveIcon, ...props }: IToggleComponentProps): JSX.Element => {
  const sizeByPropSize = {
    container: {
      md: {
        height: 20,
        width: 35,
      },
      sm: {
        height: 17,
        width: 30,
      },
    },
  };

  return (
    <Switch
      checked={props.isEnabled}
      onChange={props.onToggle}
      style={{
        width: sizeByPropSize.container[size].width,
        height: sizeByPropSize.container[size].height,
      }}
      className={`${
        props.isEnabled ? "bg-sky-600" : "bg-gray-200"
      } relative inline-flex shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
    >
      <span
        aria-hidden="true"
        style={{
          height: sizeByPropSize.container[size].height - 4,
          aspectRatio: "1 / 1",
          marginLeft: props.isEnabled ? sizeByPropSize.container[size].width - sizeByPropSize.container[size].height : "0",
        }}
        className="pointer-events-none inline-block rounded-full bg-white shadow-lg ring-0 transition-all duration-200 ease-in-out"
      />
      <div className="absolute flex h-full w-full items-center">
        {props.isEnabled ? (
          activeIcon != null ? (
            <div className="absolute right-1/2 mr-[3px] text-white">{activeIcon}</div>
          ) : null
        ) : inactiveIcon != null ? (
          <div className="absolute left-1/2 ml-[3px] text-gray-400">{inactiveIcon}</div>
        ) : null}
      </div>
    </Switch>
  );
};
