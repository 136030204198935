import { type IOrganisationMember, type IRoomMember } from "@/api/client";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { type IFormattedChannel } from "@/interfaces/channel";
import { type InputHTMLAttributes } from "react";

interface DmModalMemberProps extends InputHTMLAttributes<HTMLInputElement> {
  member: IOrganisationMember | IFormattedChannel | IRoomMember;
  disabled?: boolean;
  checked?: boolean;
}

export const DmModalMemberComponent = ({ member, disabled = false, checked = false, ...props }: DmModalMemberProps): JSX.Element => {
  return (
    <label
      htmlFor={member.id}
      className={`group flex w-full cursor-pointer select-none items-center border-b px-layout py-2 transition-all last:border-0 hover:bg-zinc-50 dark:hover:bg-dark-800 ${
        disabled && !checked ? "pointer-events-none" : ""
      }`}
      key={member.userId}
    >
      <UserAvatarComponent userId={member.userId} userName={`${member.firstName ?? ""} ${member.lastName ?? ""}`} className="mr-2 h-7" />
      <div className="text-sm dark:text-dark-300">
        {member.firstName} {member.lastName}
        <span className="text-zinc-400 dark:text-zinc-500"> ({member.email})</span>
      </div>
      <div className="ml-auto flex items-center ">
        <input
          className="cursor-pointer"
          id={member.id}
          type="checkbox"
          checked={checked}
          onChange={props.onChange}
          disabled={disabled && !checked}
        />
      </div>
    </label>
  );
};
