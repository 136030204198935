import { type DeltaContent, type IChannelConversation, type IChannelMember, type IConversationMember } from "@/api/client";
import { CustomLinkSanitizer } from "@/components/shared/richTextEditor/CustomLinkSanitizer";
import { EmojiBlot } from "@/components/shared/richTextEditor/EmojiBlot";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { eventNames } from "@/constants/eventNames";
import { QueryKeys } from "@/constants/queryKeys";
import { TYPING_INDICATOR_DEBOUNCE } from "@/constants/typingIndicatorConstants";
import { ConversationContext } from "@/contexts/ConversationContext";
import { useSendMail } from "@/hooks/emails/useSendMail";
import { useSendMessageMutation } from "@/hooks/mutations/conversations/useSendMessageMutation";
import { use1on1Reply } from "@/hooks/shared/use1on1Reply";
import { useDraft } from "@/hooks/shared/useDraft";
import { useEditMessageQueries } from "@/hooks/shared/useEditMessageQueries";
import { useEmojiMart } from "@/hooks/shared/useEmojiMart";
import { useFileUpload } from "@/hooks/shared/useFileUpload";
import { useMessage } from "@/hooks/shared/useMessage";
import { PreferencesContext } from "@/hooks/shared/usePreferences";
import { useSendIsTyping } from "@/hooks/shared/useSendIsTyping";
import {
  type ICustomEventDetail,
  type IMentionItem,
  type IRichTextEditorRef,
  type IRichTextEditorToolbarRef,
  type ISendMutation,
} from "@/interfaces/richTextEditor";
import { api } from "@/services/HttpService";
import { type Emoji } from "@/types/emojiMartTypes";
import { debounce, isDeltaContentChanged, isDeltaContentEmpty } from "@/utils/utilities";
import { QueryClientProvider, useMutation, useQueryClient } from "@tanstack/react-query";
import { type DeltaStatic } from "quill";
import MagicUrl from "quill-magic-url";
import "quill-mention";
import { useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState, type ForwardedRef } from "react";
import { renderToString } from "react-dom/server";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { BsMegaphone } from "react-icons/bs";
import type ReactQuill from "react-quill";
import { Quill } from "react-quill";
import removeAccents from "remove-accents";
import { v4 as uuidv4 } from "uuid";

type MentionItem = IMentionItem | { id: string; value: string; member: null };

const allowedContextForDraft = ["conversation", "message", "taskComment"];

// THIS HOOK IS HELL
export const useRichTextEditor = (
  ref: ForwardedRef<IRichTextEditorRef>,
  contextType: string,
  contextId: string,
  isEditing: boolean,
  isMessagePreview: boolean,
  deltaBeforeEditing: DeltaContent | null,
  defaultValue?: DeltaStatic,
  messageId?: string,
  onBlur?: () => void,
) => {
  const toolbarRef = useRef<IRichTextEditorToolbarRef>(null);

  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [selectionPosition, setSelectionPosition] = useState<number | null>(null);
  const [isTextFormatOpen, setIsTextFormatOpen] = useState(false);
  const { forwardMessageState, setForwardMessageState } = use1on1Reply();

  const { sendMail } = useSendMail(contextType, contextId);
  const { preferences } = useContext(PreferencesContext);
  const conversationContext = useContext(ConversationContext);

  const conversation = useMemo<IChannelConversation | undefined>(() => conversationContext?.conversation, [conversationContext]);
  const conversationMember = useMemo<IConversationMember | undefined>(() => conversationContext?.member, [conversationContext]);
  const { saveDraft, getDraft } = useDraft();
  const defaultContent = useMemo(() => {
    return defaultValue ?? getDraft(contextId)?.content;
  }, [defaultValue, preferences, contextId]);

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { cancelEdition } = useMessage({});
  const { files, areFilesUploading } = useFileUpload(contextType, contextId);
  const { emojiMatcher } = useEmojiMart();
  const { editMessageInQuery } = useEditMessageQueries();
  const isMentionModalOpenRef = useRef<boolean>(false);

  // TODO: Move this in queries folder
  const editMutation = useMutation({
    mutationFn: async (data: { messageId: string; content: DeltaContent }) => {
      if (conversation == null) return;
      cancelEdition(false);
      editMessageInQuery(conversation.id, {
        id: data.messageId,
        content: data.content,
        contentEditedAt: new Date().toISOString(),
      });
      await api.messages.updateMessage(data.messageId, { content: data.content }, { defaultToastErrorMessage: t("toasts.message.edit.error") });
    },
  });

  const { mutate: sendMessage } = useSendMessageMutation(contextType, contextId, conversation, files, areFilesUploading);
  const { sendIsTyping } = useSendIsTyping(contextType, conversation, conversationMember);

  const quill = useRef<ReactQuill>(null);

  const quillInit = useCallback(() => {
    // @ts-expect-error quill issue
    Quill.register(
      {
        "modules/magicUrl": MagicUrl,
        "formats/emoji": EmojiBlot,
      },
      true,
    );
    // @ts-expect-error quill issue
    Quill.register(CustomLinkSanitizer, true);
  }, []);

  quillInit();

  const editorId: string = uuidv4();

  const addMessage = useCallback(() => {
    if (contextType === "email") {
      const htmlContent = quill?.current?.getEditor().root.innerHTML;
      const textContent = quill?.current?.getEditor().getText();
      if (htmlContent == null) return;
      sendMail(htmlContent, textContent);
      return;
    }
    if (areFilesUploading) return;
    const content = quill?.current?.getEditor().getContents() as DeltaContent;
    if (content?.ops == null || conversation == null) return;

    let parentMessageId = null;

    if (contextType === "message") {
      parentMessageId = contextId;
    }

    const data: ISendMutation = {
      conversationId: conversation.id,
      content,
    };

    if (parentMessageId != null) {
      data.parentId = parentMessageId;
    }

    if (forwardMessageState != null) {
      data.forwardMessage = forwardMessageState;
    }
    sendMessage(data);
  }, [quill, forwardMessageState, areFilesUploading]);

  const editMessage = useCallback(() => {
    const content = quill?.current?.getEditor().getContents();
    if (conversation == null || content == null || messageId == null) return;

    editMutation.mutate({ messageId, content });
    toast.success(t("roomPage.tabs.room.conversation.message.toasts.messageEdited"));
  }, [quill, messageId]);

  const resetEditor = useCallback(
    (event?: CustomEvent | ICustomEventDetail) => {
      if (event?.detail !== contextId) return;
      quill?.current?.getEditor().setContents(defaultValue as DeltaStatic);
      setForwardMessageState(undefined);
      setIsEmojiPickerOpen(false);
    },
    [defaultValue, contextId],
  );

  const setEditorContent = useCallback((content: DeltaContent) => {
    quill?.current?.getEditor().setContents(content as DeltaStatic);
  }, []);

  const handleChange = useCallback(async () => {
    const content = quill?.current?.getEditor().getContents();
    setIsDirty(deltaBeforeEditing != null ? isDeltaContentChanged(deltaBeforeEditing, content) : false);
    setIsEmpty(isDeltaContentEmpty(content));
    if (!allowedContextForDraft.includes(contextType)) {
      return;
    }
    saveDraft(contextId, quill?.current?.getEditor()?.getContents(), isDeltaContentEmpty(quill?.current?.getEditor().getContents()));
  }, [contextId, contextType, isEmpty, deltaBeforeEditing]);

  const handleKeyUp = useCallback(
    debounce(() => {
      sendIsTyping();
    }, TYPING_INDICATOR_DEBOUNCE),
    [quill, areFilesUploading],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const quillEditor = quill?.current?.getEditor();
      if (quillEditor == null) return;
      const { key, shiftKey } = event;
      if (key === "Enter" && isMentionModalOpenRef.current) {
        /* empty */
      } else if (key === "Enter" && !shiftKey) {
        pressEnterHandler(quillEditor.getSelection(true));
      } else if (key === "Enter" && shiftKey) {
        shiftEnterHandler(quillEditor.getSelection(true));
      }
    },
    [quill, areFilesUploading],
  );

  const handleBlur = useCallback(
    async (e: { index: number }) => {
      onBlur?.();
      setSelectionPosition(e.index);
    },
    [quill, setSelectionPosition, onBlur],
  );

  const handleEmojiSelect = useCallback(
    async (emoji: Emoji) => {
      const editor = quill?.current?.getEditor();
      if (editor == null) return;
      const currentSelectionPosition = selectionPosition ?? editor.getSelection()?.index ?? 0;

      editor.insertEmbed(currentSelectionPosition, "emoji", {
        shortcodes: emoji.shortcodes,
        native: emoji.native,
      });
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      editor.setSelection(currentSelectionPosition + 1, 0);
      setIsEmojiPickerOpen(false);
    },
    [quill, selectionPosition],
  );

  // Custom mention Item
  const renderItem = useCallback((item: IMentionItem) => {
    return renderToString(
      <QueryClientProvider client={queryClient}>
        <div className="group flex items-center border-b px-4 py-1 transition-all hover:bg-sky-500 hover:text-white">
          {item.id !== "all" ? (
            <UserAvatarComponent userId={item.id} userName={item.value} className="mr-2 h-7" />
          ) : (
            <figure className="mr-2 h-7 w-7 p-2">
              <BsMegaphone />
            </figure>
          )}
          <div className="text-sm dark:text-dark-300">{item.value}</div>
        </div>
      </QueryClientProvider>,
    );
  }, []);

  const pressEnterHandler = useCallback(
    (range: { index: number }) => {
      if (contextType === "forwardedMessage") {
        return quill?.current?.getEditor().insertText(range.index, "\n");
      }
      const text = quill?.current?.getEditor().getContents();
      if (text?.ops == null) return;
      if (defaultValue == null) {
        addMessage();
        return;
      }
      editMessage();
    },
    [quill, areFilesUploading],
  );

  const shiftEnterHandler = useCallback(
    (range: { index: number }) => {
      quill?.current?.getEditor().insertText(range.index, "\n");
    },
    [quill],
  );

  const fetchMentionList = useCallback((searchTerm: string, renderList: (mentionList: MentionItem[], searchTerm: string) => void) => {
    const data: IChannelMember[] | undefined = queryClient.getQueryData([QueryKeys.CONVERSATIONS, conversation?.id, QueryKeys.MEMBERS]);
    if (data == null) return [];
    const values: MentionItem[] = data.map((member: IChannelMember) => ({
      id: member.user.id,
      value: `${member.user.firstName ?? ""} ${member.user.lastName ?? ""}`,
      member,
    }));

    const allMentionList = [
      {
        id: "all",
        value: t("roomPage.tabs.room.conversation.message.mention.all"),
        member: null,
      },
      ...values,
    ];

    if (searchTerm.length === 0) {
      renderList(allMentionList, searchTerm);
    } else {
      const matches = [];
      for (const element of allMentionList) {
        if (~removeAccents(element.value).toLowerCase().indexOf(removeAccents(searchTerm).toLowerCase()) !== 0) {
          matches.push(element);
        }
      }
      renderList(matches, searchTerm);
    }
  }, []);

  const modules = useMemo(
    () => ({
      mention: {
        onOpen: () => {
          isMentionModalOpenRef.current = true;
        },
        onClose: () => {
          setTimeout(() => {
            isMentionModalOpenRef.current = false;
          }, 100);
        },
        allowedChars: /^[A-Za-z\sÅÄÖåäöéàèâêîôû]*$/,
        mentionDenotationChars: ["@"],
        positioningStrategy: "fixed",
        blotName: "mention",
        renderItem,
        dataAttributes: ["id", "value", "email"],
        source: fetchMentionList,
      },
      toolbar: {
        container: `#toolbar${editorId}`,
      },
      magicUrl: {
        normalizeProtocol: true,
      },
      keyboard: {
        bindings: {
          enter: {
            key: 13,
            handler: () => {},
          },
          shiftEnter: {
            key: 13,
            shiftKey: true,
            handler: () => {},
          },
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    [],
  );

  useEffect(() => {
    if (
      defaultContent != null &&
      !isEmpty &&
      contextType !== "editedMessage" &&
      contextType !== "notification" &&
      contextType !== "taskComment" &&
      contextType !== "taskDescription"
    ) {
      const quillEditor = quill?.current?.getEditor();
      if (quillEditor != null) {
        setTimeout(() => quillEditor.setSelection(quillEditor.getLength(), 0), 0);
      }
    }
  }, [defaultContent, quill?.current?.getEditor(), isEmpty]);

  const onInsertMentionCharacter = (): void => {
    const selection: { index: number } = quill?.current?.getEditor().getSelection(true);
    const { index } = selection;
    quill?.current?.getEditor().insertText(index, "@", "user");
    quill?.current?.getEditor().setSelection(index + 1, 0, "user");
  };

  useEffect(() => {
    window.addEventListener(eventNames.RESET_REPLY, resetEditor as EventListener);

    const mentionContainer = document.querySelector("div.ql-mention-list-container");

    if (mentionContainer != null) {
      (mentionContainer as HTMLDivElement).style.display = "none";
      mentionContainer.remove();
    }

    // to change the placeholder of the link input (actually the placeholder is https://quilljs.com)
    const input: HTMLInputElement | null = document.querySelector("input[data-link]");
    if (input != null) {
      input.dataset.link = "https://getroger.io";
      input.placeholder = "https://getroger.io";
    }

    return () => {
      window.removeEventListener(eventNames.RESET_REPLY, resetEditor as EventListener);
    };
  }, []);

  // use to focus the editor when edition is started
  useEffect(
    debounce(() => {
      if (quill?.current == null || contextType === "notification") return;
      if (isEditing || (!isMessagePreview && contextType !== "taskDescription" && contextType !== "taskComment")) {
        quill.current.getEditor().focus();
        quill.current.getEditor().setSelection(quill.current.getEditor().getLength() - 1, 0);
      }
    }, 500),
    [quill, isEditing, isMessagePreview, contextType, forwardMessageState],
  );

  // use to set the default value
  useEffect(() => {
    if (defaultContent == null || quill?.current == null) return;
    quill.current.getEditor().setContents(defaultContent);
    quill.current.getEditor().clipboard.addMatcher("*", emojiMatcher);
  }, [quill, defaultContent]);

  // To be able to reset the editor from the parent component
  useImperativeHandle(ref, () => ({
    resetEditor(messageId: string) {
      const payload: ICustomEventDetail = { detail: messageId };
      resetEditor(payload);
    },
    getEditorContents() {
      return quill.current?.getEditor().getContents() as DeltaContent;
    },
    setEditorContent(content: DeltaContent) {
      setEditorContent(content);
    },
  }));

  return {
    toolbarRef,
    editorId,
    modules,
    quill,
    addMessage,
    editMessage,
    isEmpty,
    isDirty,
    handleChange,
    handleBlur,
    handleEmojiSelect,
    isEmojiPickerOpen,
    setIsEmojiPickerOpen,
    setSelectionPosition,
    isTextFormatOpen,
    setIsTextFormatOpen,
    handleKeyUp,
    forwardMessageState,
    setForwardMessageState,
    onInsertMentionCharacter,
    sendMail,
    handleKeyDown,
  };
};
