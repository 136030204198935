import { type IChannel } from "@/api/client";
import { ChannelInfoTabComponent } from "@/components/roomPage/modals/channel/tabs/ChannelInfoTabComponent";
import { ChannelNotificationSettingsTabComponent } from "@/components/roomPage/modals/channel/tabs/ChannelNotificationSettingsTabComponent";
import { InviteMemberInChannelTabComponent } from "@/components/roomPage/modals/channel/tabs/InviteMemberInChannelTabComponent";
import { ModalLayoutComponent } from "@/components/shared/modal/ModalLayoutComponent";
import { eventNames } from "@/constants/eventNames";
import { ModalNames } from "@/constants/modalNames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
interface IChannelModalProps {
  title: string;
  channelId?: string;
  defaultTab?: "about" | "members" | "settings";
  roomId: string;
  channel?: IChannel;
  conversationId?: string;
}

export const ChannelModalComponent = ({
  title,
  channelId = undefined,
  defaultTab = "about",
  roomId,
  channel,
  conversationId,
}: IChannelModalProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<"about" | "members" | "settings">(defaultTab);
  useEffect(() => {
    window.addEventListener(eventNames.CHANNEL_CREATED, ActivateMembersStep as EventListener);
    return () => {
      window.removeEventListener(eventNames.CHANNEL_CREATED, ActivateMembersStep as EventListener);
    };
  }, []);
  useEffect(() => {
    window.addEventListener(eventNames.CHANNEL_CREATED, ActivateMembersStep as EventListener);
    return () => {
      window.removeEventListener(eventNames.CHANNEL_CREATED, ActivateMembersStep as EventListener);
    };
  }, [channelId]);
  const [channelFromCreation, setChannelFromCreation] = useState<IChannel | undefined>(undefined);
  const { t } = useTranslation();

  const channelMemo = useMemo(() => {
    return channelFromCreation ?? channel;
  }, [channel, channelFromCreation]);

  const tabs = useMemo(
    () => [
      {
        label: t("channelModal.tabs.about.label"),
        content: <ChannelInfoTabComponent channelId={channelMemo?.id} roomId={roomId} isReadOnly={channelMemo?.type === "default"} />,
        id: "about",
        disabled: false,
      },
      {
        label: t("channelModal.tabs.members.label"),
        content:
          channelMemo != null ? (
            <InviteMemberInChannelTabComponent
              roomId={roomId}
              channel={channelMemo}
              isReadOnly={channelMemo?.type === "default"}
              conversationId={conversationId}
            />
          ) : (
            <></>
          ),
        id: "members",
        disabled: channelMemo == null,
      },
      {
        label: t("channelModal.tabs.settings.label"),
        content: channelMemo != null ? <ChannelNotificationSettingsTabComponent roomId={roomId} channelId={channelMemo?.id} /> : <></>,
        id: "settings",
        disabled: channelMemo == null,
      },
    ],
    [channel, channelMemo, roomId],
  );

  const modalTitle = useMemo(() => {
    return channelFromCreation?.name ?? channel?.name ?? title;
  }, [channel, channelFromCreation]);

  const ActivateMembersStep = useCallback(
    (e: CustomEvent<IChannel>): void => {
      setChannelFromCreation(e.detail);
      setActiveTab("members");
    },
    [activeTab, channelFromCreation],
  );

  return (
    <>
      <ModalLayoutComponent
        key={channelMemo?.id ?? 0}
        tabs={tabs}
        title={modalTitle}
        size="md"
        name={ModalNames.CHANNEL_MODAL}
        defaultTab={activeTab}
      ></ModalLayoutComponent>
    </>
  );
};
