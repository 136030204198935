import { type FunctionComponent } from "react";
import { LuMailPlus as AddMailIcon } from "react-icons/lu";

interface AddEmailItemComponentProps {
  isDisabled: boolean;
  email: string;
  onAddEmail: () => void;
}

export const AddEmailItemComponent: FunctionComponent<AddEmailItemComponentProps> = ({ isDisabled, email, onAddEmail }) => {
  return (
    <div
      className={`flex flex-row items-center justify-between border-b px-2 py-1 transition-all ${
        isDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer hover:bg-sky-500 hover:text-white"
      }`}
      onClick={isDisabled ? undefined : onAddEmail}
      onKeyDown={isDisabled ? undefined : onAddEmail}
    >
      <div className="mr-2 flex items-center">
        <AddMailIcon className="ml-0.5 mr-1.5 h-4 w-4" />

        <div className="truncate text-xs dark:text-dark-300">{email}</div>
      </div>
    </div>
  );
};
