import { type IChannelIdNameRoom } from "@/interfaces/channel";
import { useState, type MouseEventHandler } from "react";

export const useChannelPicker = (channel: IChannelIdNameRoom | null, onChannelChange?: (channel: IChannelIdNameRoom | null) => void) => {
  const [isChannelTooltipVisible, setIsChannelTooltipVisible] = useState<boolean>(false);

  const onChannelClick: MouseEventHandler<HTMLDivElement> | undefined =
    onChannelChange != null
      ? (event) => {
          event.stopPropagation();
          event.preventDefault();
          setIsChannelTooltipVisible((isVisible) => !isVisible);
        }
      : undefined;

  const onCloseTooltip = () => {
    setIsChannelTooltipVisible(false);
  };

  const onChannelSelected = (selectedChannel: IChannelIdNameRoom | null) => {
    if (onChannelChange == null) {
      return;
    }
    if (selectedChannel == null) {
      onChannelChange(null);
    } else if (selectedChannel.id !== channel?.id) {
      onChannelChange(selectedChannel);
    }
    onCloseTooltip();
  };

  return { isChannelTooltipVisible, onChannelClick, onCloseTooltip, onChannelSelected };
};
