import { type IFile } from "@/api/client";
import { FileActionMenuComponent } from "@/components/roomPage/tabs/conversation/message/FileActionMenuComponent";
import { ImageInMessageComponent } from "@/components/roomPage/tabs/conversation/message/ImageInMessageComponent";
import { PdfInMessageComponent } from "@/components/roomPage/tabs/conversation/message/PdfInMessageComponent/PdfInMessageComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { useDownload } from "@/hooks/shared/useDownload";
import { getRandomColorFromString, getTypeOfFileFromExtension } from "@/utils/utilities";
import { useCallback, type MouseEventHandler } from "react";
import ReactH5AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

interface IFIleInMessageComponentProps {
  file: IFile;
  isInEditor?: boolean;
  isLoading?: boolean;
  isInForward?: boolean;
  isOnlyImageInMessage?: boolean;
}

export const FileInMessageComponent = ({
  file,
  isInEditor = false,
  isLoading = false,
  isInForward = false,
  isOnlyImageInMessage = false,
}: IFIleInMessageComponentProps) => {
  const { download } = useDownload();
  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      if (isInEditor) return;
      if (file.extension === "pdf") {
        return;
      }
      download(file.url);
    },
    [file],
  );

  return (
    <div className="group/file relative max-w-full cursor-pointer">
      {!isInForward && <FileActionMenuComponent file={file} />}
      {getTypeOfFileFromExtension(file.extension) === "audio" ? (
        <>
          {isLoading && <LoaderComponent />}
          <ReactH5AudioPlayer
            src={file.url}
            autoPlay={false}
            autoPlayAfterSrcChange={false}
            layout="horizontal-reverse"
            showJumpControls={false}
            loop={false}
            customVolumeControls={[]}
            customAdditionalControls={[]}
            showDownloadProgress={false}
          />

          {/* <ReactPlayer url={file.url} controls={true} /> */}
          {/* <audio controls={true} src={file.url} className={"rounded-none h-12 border"}></audio> */}
        </>
      ) : getTypeOfFileFromExtension(file.extension) === "image" ? (
        <ImageInMessageComponent file={file} isInForward={isInForward} isOnlyImageInMessage={isOnlyImageInMessage} />
      ) : file.extension === "pdf" ? (
        <PdfInMessageComponent file={file} isInForward={isInForward} isInEditor={isInEditor} />
      ) : (
        <div
          key={file.id}
          className={`flex h-12 items-center justify-center rounded border bg-white px-2  ${!isInEditor ? "cursor-pointer" : ""} ${
            !isInForward ? "mb-1 mr-1" : ""
          }`}
          onClick={handleClick}
        >
          {isLoading && <LoaderComponent />}
          <div
            className="mr-2 rounded bg-red-600 p-1 text-2xs font-bold text-white"
            style={{
              backgroundColor: getRandomColorFromString(file.extension ?? ""),
            }}
          >
            {file.extension}
          </div>
          <div className="max-w-xs truncate text-xs">{file.name}</div>
        </div>
      )}
    </div>
  );
};
