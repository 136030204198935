import { type DeltaContent } from "@/api/client";
import { EnhanceByAITab } from "@/components/roomPage/tabs/conversation/EnhanceByAIModal/tabs/EnhanceByAITab";
import { ModalLayoutComponent } from "@/components/shared/modal/ModalLayoutComponent";
import { ModalNames } from "@/constants/modalNames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface IEnhanceByAIModalProps {
  initialText: DeltaContent;
  onSubmit: (content: DeltaContent) => void;
}

export const EnhanceByAIModal = ({ initialText, onSubmit }: IEnhanceByAIModalProps): JSX.Element => {
  const { t } = useTranslation();
  const tabs = useMemo(() => {
    const profileTabs = [
      {
        label: t("enhanceByAIModal.tab.title"),
        content: <EnhanceByAITab initialText={initialText} onSubmit={onSubmit} />,
        id: "EnhanceByAITab",
        disabled: false,
      },
    ];

    return profileTabs;
  }, [initialText, onSubmit]);

  return (
    <ModalLayoutComponent
      tabs={tabs}
      title={t("enhanceByAIModal.title")}
      size="lg"
      defaultTab="EnhanceByAITab"
      name={ModalNames.ENHANCE_BY_AI}
    ></ModalLayoutComponent>
  );
};
