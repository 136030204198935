import { type ITag } from "@/api/client";
import { openModal } from "@/components/shared/modal/ModalService";
import { TagEditModal } from "@/components/shared/tagInput/tagEditModal/TagEditModal";
import { ModalNames } from "@/constants/modalNames";
import { TaskTagsContext } from "@/contexts/TaskTagsContextProvider";
import { useDeleteTagOnTaskMutation } from "@/hooks/mutations/tasks/tags/useDeleteTagOnTaskMutation";
import { useCallback, useContext, useMemo } from "react";

export const useTagInputTag = (
  tag: ITag,
  roomId: string,
  inList: boolean,
  size: string,
  onClick?: (tag: ITag) => void,
  taskId?: string,
  onFilterChange?: (tag: ITag) => void,
) => {
  const { mutate: deleteTagMutation } = useDeleteTagOnTaskMutation(taskId, roomId);
  const { removeTagFromContext } = useContext(TaskTagsContext);

  const deleteTag = useCallback(
    (tagId: string) => {
      if (taskId == null) {
        removeTagFromContext(tagId);
        return;
      }
      deleteTagMutation(tagId);
    },
    [taskId],
  );

  const handleCheckboxChange = useCallback(() => {
    if (onClick == null) return;
    onClick(tag);
  }, [tag]);

  const handleEditClick = useCallback(() => {
    openModal(ModalNames.TAG_EDIT, <TagEditModal tag={tag} roomId={roomId} taskId={taskId} />);
  }, [tag]);

  const handleClickOnTag = useCallback(() => {
    if (onClick == null) return;
    if (inList) {
      onClick(tag);
    }
  }, [tag]);

  const handleClickOnDelete = useCallback(() => {
    if (onFilterChange != null) onFilterChange(tag);
    deleteTag(tag.id);
  }, [tag]);

  const sizeClass = useMemo(() => (size === "sm" ? "px-1 py-0.5 text-2xs my-0" : "px-2 py-1 text-xs"), [size]);

  return {
    handleClickOnTag,
    handleClickOnDelete,
    handleEditClick,
    handleCheckboxChange,
    sizeClass,
  };
};
