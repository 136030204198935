import { getRandomColorFromString } from "@/utils/utilities";
import { useEffect, useState, type ImgHTMLAttributes } from "react";
interface IImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  fallbackSrc?: string;
  loremImage?: boolean;
  alt: string;
  initial?: boolean;
  isUserImage?: boolean;
  className?: string;
  size?: "xs" | "sm" | "md" | "lg";
}

export const ImageComponent = ({
  fallbackSrc = "data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
  src,
  loremImage = false,
  initial = false,
  alt = "",
  className = "",
  size = "md",
  isUserImage = false,
  ...props
}: IImageProps): JSX.Element => {
  const [imgSrc, setImgSrc] = useState<string>(src);
  const [error, setError] = useState<boolean>(false);

  const fontSizeMap = {
    xs: "text-2xs",
    sm: "text-sm",
    md: "text-sm",
    lg: "text-4xl max-md:text-xl",
  };

  const getInitialsFromString = (str: string): string => {
    if (str == null) return "";
    return str
      ?.split(" ")
      .map((word) => word?.[0])
      .join("")
      .toUpperCase();
  };

  useEffect(() => {
    if (loremImage) {
      // const [width, height] = src !== undefined ? src.split("x") : ["600", "600"];
      setImgSrc(`https://loremflickr.com/60/60`);
    }
  }, [src]);
  const onError = (): void => {
    if (error) return;
    setImgSrc(fallbackSrc);
    setError(true);
  };

  return (
    <div
      className={`relative overflow-hidden bg-gray-100 dark:bg-dark-800 ${className}`}
      style={
        isUserImage && src === ""
          ? {
              backgroundColor: getRandomColorFromString(alt),
            }
          : {}
      }
    >
      {imgSrc !== "" && <img src={imgSrc} onError={onError} {...props} alt="alt" className="absolute h-full w-full object-cover" />}
      <div
        className={`initial flex h-full w-full items-center justify-center font-normal dark:text-dark-300 ${fontSizeMap[size]} ${
          isUserImage ? "text-white" : ""
        }`}
      >
        {getInitialsFromString(alt)[0]}
      </div>
    </div>
  );
};
