import { type DrawerType, type IDrawer, type OverlayCallback } from "@/components/shared/drawer/DrawerTypes";
import { createContext } from "react";

export interface DrawerContextType {
  openedDrawers: IDrawer[];
  openDrawer: (drawer: IDrawer, disableAnimation?: boolean) => void;
  closeDrawer: (type: DrawerType, disableAnimation?: boolean) => void;
  closeAllDrawers: () => void;
  overlayCallback: OverlayCallback | null;
  setOverlayCallback: (callback: OverlayCallback | null) => void;
  isOpened: boolean;
  isAnimationDisabled: boolean;
}

export const defaultDrawerContext: Partial<DrawerContextType> = {};

export const DrawerContext = createContext<DrawerContextType>(defaultDrawerContext as DrawerContextType);
