import { FileType } from "@/api/client";
import { FileInMessageComponent } from "@/components/roomPage/tabs/conversation/message/FileInMessageComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { type FilePondFile } from "filepond";
import { type RefObject } from "react";
import { type FilePond } from "react-filepond";
import { TiDeleteOutline as DeleteIcon } from "react-icons/ti";
interface IEditorFileComponentProps {
  file: FilePondFile;
  isUploading: boolean;
  filepondRef: RefObject<FilePond>;
}
export const EditorFileComponent = ({ file, isUploading, filepondRef }: IEditorFileComponentProps) => {
  const isImage = file.fileType.startsWith("image");
  const isAudio = file.fileType.startsWith("audio");
  const isPdf = file.fileType.startsWith("application/pdf");

  const sizeClasses = {
    width: "w-12",
    height: "h-12",
  };

  const preview: string = file.getFileEncodeBase64String();
  return (
    <div key={file.id} className={`${sizeClasses.height} group/file relative mb-2 mr-2 ${isImage ? sizeClasses.width : ""}`}>
      <button
        className="absolute -right-1.5 -top-1.5 z-50 hidden h-5 w-5 items-center justify-center rounded-full border bg-white group-hover/file:flex"
        onClick={() => {
          filepondRef?.current?.removeFile(file.id);
        }}
      >
        <DeleteIcon className="h-5 w-5" />
      </button>
      {isImage ? (
        <>
          {isUploading && (
            <div className="absolute h-full w-full">
              <LoaderComponent />
            </div>
          )}
          {preview != null && (
            <img
              alt={file.filename}
              src={`data:${file.fileType};base64,${preview}`}
              className={`${sizeClasses.width} ${sizeClasses.height} rounded border object-cover`}
            />
          )}
        </>
      ) : (
        <FileInMessageComponent
          file={{
            id: file.id,
            name: file.filename,
            extension: file.fileExtension,
            url: isAudio || isPdf ? `data:${file.fileType};base64,${preview}` : "",
            createdAt: "",
            type: isAudio ? FileType.VoiceNote : FileType.Default,
          }}
          isInEditor={true}
          isLoading={isUploading}
        />
      )}
    </div>
  );
};
