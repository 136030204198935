import { useRemoveAssigneeMutation } from "@/hooks/mutations/tasks/useRemoveAssigneeMutation";
import { useState, type MouseEventHandler } from "react";

export const useTaskAssignees = (taskId: string | null, onUserChange?: (assignee: string, isSelected: boolean) => void) => {
  const [isAssigneeTooltipVisible, setIsAssigneeTooltipVisible] = useState<boolean>(false);

  const { mutate: removeAssignee } = useRemoveAssigneeMutation(taskId ?? "");
  const onAddAssigneeClick = () => {
    setIsAssigneeTooltipVisible((isVisible) => !isVisible);
  };
  const onAssigneeClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onAddAssigneeClick();
  };

  const onCloseTooltip = () => {
    setIsAssigneeTooltipVisible(false);
  };

  const onRemoveAssignee = (userId: string) => {
    onCloseTooltip();
    if (taskId != null) {
      removeAssignee(userId);
    } else {
      onUserChange?.(userId, false);
    }
  };

  return { isAssigneeTooltipVisible, onAssigneeClick, onCloseTooltip, onAddAssigneeClick, onRemoveAssignee };
};
