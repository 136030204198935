import { useCustomSelect } from "@/components/shared/form/select/useCustomSelect";
import { useMemo } from "react";
import Select, { type Props, type StylesConfig } from "react-select";

export interface CustomSelectOption<T> {
  value: T;
  label: string;
}
interface ICustomSelectComponentProps<T> extends Props<CustomSelectOption<T>, false> {
  customStyles?: StylesConfig<CustomSelectOption<T>>;
  dropdownIndicatorStyle?: string;
}

export const CustomSelectComponent = <T extends unknown>({
  isDisabled = false,
  customStyles,
  dropdownIndicatorStyle = "default",
  className,
  ...props
}: ICustomSelectComponentProps<T>): JSX.Element => {
  const { customStyles: _customStyles, DropdownIndicator, ClearIndicator } = useCustomSelect<CustomSelectOption<T>>();
  const components = useMemo(() => {
    const output = {
      ClearIndicator,
    };

    if (isDisabled)
      return {
        ...output,
        DropdownIndicator: () => null,
      };
    if (dropdownIndicatorStyle !== "default") return output;

    return {
      ...output,
      DropdownIndicator,
    };
  }, [isDisabled]);

  return (
    <div className={`${className ?? ""} ${isDisabled ? "opacity-50" : ""}`}>
      <Select<CustomSelectOption<T>, false>
        {...props}
        isDisabled={isDisabled}
        styles={customStyles ?? _customStyles}
        menuPortalTarget={document.getElementById("global-wrapper")}
        components={components}
        isMulti={false}
      />
    </div>
  );
};
