import { type ITodo } from "@/api/client";
import { TagComponent } from "@/components/shared/tag/TagComponent";
import { useCallback, type FunctionComponent } from "react";

interface TaskTodoComponentProps {
  todo: ITodo;
  onTodoRemove: () => void;
  onToggleTodo: () => void;
}

export const TaskTodoComponent: FunctionComponent<TaskTodoComponentProps> = ({ todo, onTodoRemove, onToggleTodo }) => {
  const IconComponent = useCallback(
    () => <input id={`task-todo-${todo.id}`} type="checkbox" checked={todo.isChecked} onChange={onToggleTodo} />,
    [todo],
  );

  return <TagComponent IconComponent={IconComponent} value={todo.value} onRemove={onTodoRemove} />;
};
