import { type IFile } from "@/api/client";
import { useCallback, useState } from "react";
import { type OnDocumentLoadSuccess } from "react-pdf/dist/cjs/shared/types";

const INITIAL_SCALE = 1.2;

export const usePdfViewer = () => {
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [scale, setScale] = useState(INITIAL_SCALE);

  const onDocumentLoadSuccess: OnDocumentLoadSuccess = useCallback(
    ({ numPages }) => {
      setTotalPages(numPages);
      setIsLoading(false);
    },
    [totalPages, isLoading],
  );

  const showEveryPages = useCallback((file: IFile) => {
    if (file == null) return false;
    const MAX_FILE_SIZE_IN_MB = 20;
    return (file.fileSizeInBytes ?? 0) / 1000 / 1000 < MAX_FILE_SIZE_IN_MB;
  }, []);

  return {
    scale,
    setTotalPages,
    setCurrentPage,
    setIsLoading,
    onDocumentLoadSuccess,
    totalPages,
    currentPage,
    isLoading,
    showEveryPages,
    setScale,
  };
};
