import { type ITag } from "@/api/client";
import React, { createContext, useCallback, useMemo, useState, type Dispatch, type PropsWithChildren, type SetStateAction } from "react";

interface TaskTagsContextData {
  tags: ITag[];
  setTags: Dispatch<SetStateAction<ITag[]>>;
  addTagToContext: (tag: ITag) => void;
  removeTagFromContext: (tagId: string) => void;
}

export const TaskTagsContext = createContext<TaskTagsContextData>({
  tags: [],
  setTags: () => {},
  addTagToContext: () => {},
  removeTagFromContext: () => {},
});

export const TaskTagsContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [tags, setTags] = useState<ITag[]>([]);

  const addTagToContext = useCallback((tag: ITag) => {
    setTags((oldTags) => {
      if (oldTags == null) return oldTags;
      return [...oldTags, tag];
    });
  }, []);

  const removeTagFromContext = useCallback((tagId: string) => {
    setTags((oldTags) => {
      if (oldTags == null) return oldTags;
      return oldTags.filter((oldTag) => oldTag.id !== tagId);
    });
  }, []);

  const contextValue: TaskTagsContextData = useMemo(
    () => ({
      tags,
      setTags,
      addTagToContext,
      removeTagFromContext,
    }),
    [tags],
  );

  return <TaskTagsContext.Provider value={contextValue}>{children}</TaskTagsContext.Provider>;
};
