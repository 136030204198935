import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineInfoCircle } from "react-icons/ai";

interface IPasswordPopoverProps {
  password: string;
  isInputFocused: boolean;
  isError: boolean;
  hasOffset?: boolean;
}

export const PasswordPopoverComponent = ({ password = "", isInputFocused, isError, hasOffset = false }: IPasswordPopoverProps): JSX.Element => {
  const { t } = useTranslation();
  const [forceOpenPopup, setForceOpenPopup] = useState<boolean>(false);
  const passwordRequirements = useMemo(
    () => [
      {
        isValid: password?.length >= 9,
      },
      {
        isValid: /[A-Z]/.test(password ?? ""),
      },
      {
        isValid: /[a-z]/.test(password ?? ""),
      },
      {
        isValid: /\d/.test(password ?? ""),
      },
      {
        isValid: /[!#$%&'()*+,-./:;<=>?@[\]^_{|}~]/.test(password ?? ""),
      },
    ],
    [password],
  );

  const basePasswordClasses = "flex flex-row items-center";
  const validPasswordClasses = `${basePasswordClasses} text-green-500`;
  const invalidPasswordClasses = `${basePasswordClasses} ${isError && !isInputFocused ? "text-red-400" : ""}`;

  const renderIcon = useCallback(
    (isValid: boolean) => {
      return isValid ? (
        <AiOutlineCheckCircle className="mr-1 h-3 w-3" />
      ) : isError && !isInputFocused ? (
        <AiOutlineCloseCircle className="mr-1 h-3 w-3" />
      ) : (
        <AiOutlineInfoCircle className="mr-1 h-3 w-3" />
      );
    },
    [isError, isInputFocused],
  );

  const areAllConditionsValid = useMemo(() => passwordRequirements.every((condition) => condition.isValid), [passwordRequirements]);

  useEffect(() => {
    if (areAllConditionsValid) {
      setForceOpenPopup(false);
    }
  }, [areAllConditionsValid]);

  return (
    <TooltipComponent
      placement="right-start"
      visible={forceOpenPopup || ((isInputFocused || isError) && !areAllConditionsValid)}
      onClickOutside={() => {
        setForceOpenPopup(false);
      }}
      arrow={false}
      interactive
      noPadding
      offset={hasOffset ? [70, -18] : undefined}
      content={
        <div className="w-80 px-4 py-2">
          <h3 className="text-sm font-semibold text-zinc-700 dark:text-dark-300">{t("forms.formValidation.passwordIndicatorTitle")}</h3>
          <ul className="text-xs text-zinc-500 ">
            <li className={passwordRequirements[0].isValid ? validPasswordClasses : invalidPasswordClasses}>
              {renderIcon(passwordRequirements[0].isValid)}
              {t("forms.formValidation.errors.inferior_than_minimum_length", { field: "", value: "9" })}
            </li>
            <li className={passwordRequirements[1].isValid ? validPasswordClasses : invalidPasswordClasses}>
              {renderIcon(passwordRequirements[1].isValid)}
              {t("forms.formValidation.errors.not_contains_uppercase", { field: "" })}
            </li>
            <li className={passwordRequirements[2].isValid ? validPasswordClasses : invalidPasswordClasses}>
              {renderIcon(passwordRequirements[2].isValid)}
              {t("forms.formValidation.errors.not_contains_lowercase", { field: "" })}
            </li>
            <li className={passwordRequirements[3].isValid ? validPasswordClasses : invalidPasswordClasses}>
              {renderIcon(passwordRequirements[3].isValid)}
              {t("forms.formValidation.errors.not_contains_integer", { field: "" })}
            </li>
            <li className={passwordRequirements[4].isValid ? validPasswordClasses : invalidPasswordClasses}>
              {renderIcon(passwordRequirements[4].isValid)}
              {t("forms.formValidation.errors.not_contains_special_character", { field: "" })}
            </li>
          </ul>
        </div>
      }
    >
      <span
        className="cursor-pointer text-black dark:text-dark-300"
        onClick={() => {
          setForceOpenPopup((force) => !force);
        }}
      >
        <AiOutlineInfoCircle className="h-4 w-4" />
      </span>
    </TooltipComponent>
  );
};
