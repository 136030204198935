import { useIsElectron } from "@/hooks/shared/useIsElectron";
import { useCallback } from "react";

export const useDownload = () => {
  const { isElectron } = useIsElectron();
  const download = useCallback(
    (url: string) => {
      if (isElectron) {
        window.electron.send("download", {
          payload: { url },
        });
        return;
      }
      window.open(url, "_blank");
    },
    [isElectron],
  );
  return { download };
};
