import { type ITag } from "@/api/client";
import { ModalFooterComponent } from "@/components/shared/modal/ModalFooterComponent";
import { ModalTabComponent } from "@/components/shared/modal/ModalTabComponent";
import { useTagInputModalContent } from "@/components/shared/tagInput/tagEditModal/useTagInputModalContent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ITagEditModalContentProps {
  tag?: ITag;
  taskId?: string;
  roomId: string;
  isCreation?: boolean;
}

export const TagEditModalContent: FunctionComponent<ITagEditModalContentProps> = ({ tag, taskId, roomId, isCreation = false }) => {
  const { t } = useTranslation();
  const { colors, selectedColor, setSelectedColor, labelName, setLabelName, modalActions } = useTagInputModalContent(isCreation, roomId, tag, taskId);
  return (
    <ModalTabComponent footer={<ModalFooterComponent actions={modalActions}></ModalFooterComponent>}>
      <>
        <div className="mb-4">
          <label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="labelName">
            {t("editTaskModal.editTagModal.labelName")}
          </label>
          <input
            className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
            id="labelName"
            type="text"
            value={labelName}
            onChange={(e) => {
              setLabelName(e.target.value);
            }}
          />
        </div>

        <div className="mb-4">
          <span className="mb-2 block text-sm font-bold text-gray-700"> {t("editTaskModal.editTagModal.labelColor")}</span>
          <div className="-m-1 flex flex-wrap">
            {colors.map((color) => (
              <button
                key={color.name}
                className={`m-1 h-12 w-12 rounded-xl focus:outline-none ${selectedColor === color.name ? "ring-2 ring-sky-600 ring-offset-2" : ""}`}
                style={{ backgroundColor: color.backgroundColor }}
                onClick={() => {
                  setSelectedColor(color.name);
                }}
              ></button>
            ))}
          </div>
        </div>
      </>
    </ModalTabComponent>
  );
};
