import { type IEmail } from "@/api/client";
import { useEmail } from "@/components/roomPage/tabs/emails/useEmail";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { MdReply, MdReplyAll } from "react-icons/md";

interface IEmailReplyButtonProps {
  email: IEmail;
  onlyIcons?: boolean;
}
export const EmailReplyButtonComponent: FunctionComponent<IEmailReplyButtonProps> = ({ email, onlyIcons = false }) => {
  const { t } = useTranslation();
  const { replyToEmail, replyAllToEmail } = useEmail(email);

  return (
    <div className="mr-4 flex">
      <TooltipComponent content={t("roomPage.tabs.mails.buttons.reply.tooltip")} placement="top-end" disabled={!onlyIcons}>
        <div>
          <ButtonComponent onClick={replyToEmail} status={onlyIcons ? "initial" : "secondary"} size={onlyIcons ? "auto" : "sm"}>
            {onlyIcons ? (
              <MdReply className="h-4 w-4 text-sky-600 transition-colors hover:text-zinc-600" />
            ) : (
              t("roomPage.tabs.mails.buttons.reply.tooltip")
            )}
          </ButtonComponent>
        </div>
      </TooltipComponent>
      <TooltipComponent content={t("roomPage.tabs.mails.buttons.replyAll.tooltip")} placement="top-end" disabled={!onlyIcons}>
        <div className={onlyIcons ? "" : "ml-2"}>
          <ButtonComponent onClick={replyAllToEmail} status={onlyIcons ? "initial" : "primary"} size={onlyIcons ? "auto" : "sm"}>
            {onlyIcons ? (
              <MdReplyAll className="h-4 w-4 text-sky-600 transition-colors hover:text-zinc-600" />
            ) : (
              t("roomPage.tabs.mails.buttons.replyAll.tooltip")
            )}
          </ButtonComponent>
        </div>
      </TooltipComponent>
    </div>
  );
};
