import { type IAuthUpdateMe, type IAuthUpdateMyPassword } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useMeMutations = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const updateMeMutation = useMutation({
    mutationFn: async (newUser: IAuthUpdateMe) => {
      const { data } = await api.me.update(newUser);
      return data;
    },
    onSuccess: (data) => {
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.ME] });
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.USERS, data.id] });
    },
    onError: () => {
      toast.error(t("toasts.profileUpdateError"));
    },
  });

  const updatePasswordMutation = useMutation({
    mutationFn: async (newUser: IAuthUpdateMyPassword) => {
      try {
        await api.my.updatePassword(newUser);
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.ME] });
        toast.success(t("toasts.profileUpdated"));
      } catch (error) {
        toast.error(t("toasts.profileUpdateError"));
      }
    },
  });

  return { updateMeMutation, updatePasswordMutation };
};
