import { RoomList } from "@/components/homePage/tabs/rooms/RoomList";
import { useRoomListTab } from "@/components/homePage/tabs/rooms/useRoomListTab";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { roomType } from "@/constants/room";
import { useTranslation } from "react-i18next";

export const RoomListTab = (): JSX.Element => {
  const { t } = useTranslation();
  const { isLoading, isFetching, isSuccess, organisationRooms, projectRooms, domain, canCreateRoom } = useRoomListTab();
  return (
    <div className="relative">
      {(isLoading || isFetching) && <LoaderComponent />}
      {isSuccess && (
        <>
          {organisationRooms.filter((roomItem) => roomItem.room.type === roomType.ORGANISATION).length > 0 && (
            <RoomList
              type={roomType.ORGANISATION}
              rooms={organisationRooms}
              title={t("homePage.tabs.rooms.organisations.title", {
                count: organisationRooms.length,
              })}
              info={t("homePage.tabs.rooms.organisations.info", { domain })}
            />
          )}

          <RoomList
            rooms={projectRooms}
            title={t("homePage.tabs.rooms.projects.title", {
              count: projectRooms.length,
            })}
            info={t("homePage.tabs.rooms.projects.info")}
            canAddNewRoom={canCreateRoom}
            enableSearch={true}
            enableGridListOption={true}
          />
        </>
      )}
    </div>
  );
};
