import { type IFile } from "@/api/client";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { eventNames } from "@/constants/eventNames";
import { useDownload } from "@/hooks/shared/useDownload";
import { useEffect, useRef } from "react";
import { AiOutlineDownload as DownloadIcon, AiOutlineZoomIn as ZoomInIcon, AiOutlineZoomOut as ZoomOutIcon } from "react-icons/ai";
import { IoClose as CloseIcon } from "react-icons/io5";
import { TbZoomReset as ResetIcon } from "react-icons/tb";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

interface ImageProps {
  file: IFile;
  imageAlt?: string;
}

export const ImageViewerComponent = ({ file }: ImageProps): JSX.Element => {
  const { download } = useDownload();

  const handleDownload = () => {
    download(file.url);
  };

  const handleClose = () => {
    const event = new CustomEvent(eventNames.CLOSE_DOCUMENT_VIEWER);
    window.dispatchEvent(event);
  };

  const imgRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (imgRef.current == null || containerRef.current == null) return;
    const img = imgRef.current;
    const container = containerRef.current;
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;
    const imgWidth = img.width;
    const imgHeight = img.height;

    if (imgWidth > containerWidth || imgHeight > containerHeight) {
      img.style.maxWidth = "100%";
      img.style.maxHeight = "100%";
    }
  }, [imgRef.current, containerRef.current]);

  const Controls = ({ zoomIn, zoomOut, resetTransform }: { zoomIn: () => void; zoomOut: () => void; resetTransform: () => void }) => {
    return (
      <>
        <nav className="absolute right-0 top-0 flex justify-end rounded-bl-xl bg-zinc-600 p-1">
          <ButtonComponent
            status="default"
            size="xs"
            className="bg-transparent text-white opacity-60 hover:opacity-100"
            onClick={() => {
              zoomIn();
            }}
          >
            <ZoomInIcon size={20} />
          </ButtonComponent>
          <ButtonComponent
            status="default"
            size="xs"
            className="bg-transparent text-white opacity-60 hover:opacity-100"
            onClick={() => {
              zoomOut();
            }}
          >
            <ZoomOutIcon size={20} />
          </ButtonComponent>
          <ButtonComponent
            status="default"
            size="xs"
            className="bg-transparent text-white opacity-60 hover:opacity-100"
            onClick={() => {
              resetTransform();
            }}
          >
            <ResetIcon size={20} />
          </ButtonComponent>
          <ButtonComponent status="default" size="xs" className="bg-transparent text-white opacity-80 hover:opacity-100" onClick={handleDownload}>
            <DownloadIcon size={20} />
          </ButtonComponent>
          <ButtonComponent status="default" size="xs" className="bg-transparent text-white opacity-80 hover:opacity-100" onClick={handleClose}>
            <CloseIcon size={20} />
          </ButtonComponent>
        </nav>
      </>
    );
  };

  return (
    <>
      <div className="absolute inset-0 z-[100] h-full w-full" onClick={handleClose}></div>
      <div
        className="absolute inset-20 z-[101] flex flex-col items-center justify-center overflow-hidden rounded-lg bg-black/50 shadow-md backdrop-blur-lg"
        ref={containerRef}
        onDoubleClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <TransformWrapper minScale={0.5} maxScale={2} pinch={{ step: 200 }} centerOnInit panning={{ disabled: true }}>
          {(utils) => (
            <>
              <TransformComponent
                wrapperStyle={{
                  height: "100%",
                  width: "100%",
                  margin: "0",
                }}
                contentStyle={{
                  height: "90%",
                  margin: "0",
                  cursor: "zoom-in",
                }}
              >
                <img src={file.url} alt={file.name} ref={imgRef} className="object-contain" />
              </TransformComponent>
              <Controls {...utils} />
              <p className="mb-2 text-center text-sm text-white">{file.name}</p>
            </>
          )}
        </TransformWrapper>
      </div>
    </>
  );
};
