import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect } from "react";

import { type ITask, type ITaskOne, type ITaskStatus, type ITodo } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { useUpdateTodoInCache } from "@/hooks/mutations/tasks/todos/useUpdateTodoInCache";

type Task = ITask | ITaskOne;

export const useTasksSocket = () => {
  const socket = useContext(SocketContext);

  const queryClient = useQueryClient();
  const { addTodoInCache, updateTodoInCache, deleteTodoInCache } = useUpdateTodoInCache();

  const onTaskCreated = useCallback(
    (createdTask: Task) => {
      void queryClient.invalidateQueries({ exact: false, queryKey: [QueryKeys.TASKS] });

      queryClient.setQueryData<ITaskOne>([QueryKeys.TASK, createdTask.id], (oldData) => {
        if (oldData == null) {
          return createdTask;
        }

        return { ...oldData, ...createdTask };
      });
    },
    [queryClient],
  );

  const onTaskUpdated = useCallback(
    (updatedTask: ITask) => {
      queryClient.setQueriesData<Task[]>(
        {
          exact: false,
          queryKey: [QueryKeys.TASKS],
        },
        (oldData) => oldData?.map((task) => (task.id !== updatedTask.id ? task : { ...task, ...updatedTask })),
      );
      void queryClient.invalidateQueries({ exact: false, queryKey: [QueryKeys.TASKS] });

      queryClient.setQueryData<ITaskOne>([QueryKeys.TASK, updatedTask.id], (oldData) => {
        if (oldData == null) {
          return oldData;
        }

        return { ...oldData, ...updatedTask };
      });
    },
    [queryClient],
  );

  const onTaskDeleted = useCallback(
    ({ id }: { id: string }) => {
      queryClient.setQueriesData<Task[]>(
        {
          exact: false,
          queryKey: [QueryKeys.TASKS],
        },
        (oldData) => oldData?.filter((task) => task.id !== id),
      );
    },
    [queryClient],
  );

  const onTodoCreated = ({ task, todo, optimisticId }: { task: ITask; todo: ITodo; optimisticId?: string }) => {
    addTodoInCache(task.id, todo, optimisticId);
  };

  const onTodoUpdated = ({ task, todo }: { task: ITask; todo: ITodo }) => {
    updateTodoInCache(task.id, todo.id, todo);
  };

  const onTodoDeleted = ({ task, todo }: { task: ITask; todo: ITodo }) => {
    deleteTodoInCache(task.id, todo.id);
  };

  const onTaskStatusCreated = useCallback(
    ({ taskStatus, room: { id } }: { taskStatus: ITaskStatus; room: { id: string } }) => {
      queryClient.setQueriesData<ITaskStatus[]>(
        {
          exact: true,
          queryKey: [QueryKeys.ROOMS, id, QueryKeys.TASKS, QueryKeys.TASKS_STATUSES],
        },
        (oldData) => {
          if (oldData == null) {
            return oldData;
          }

          const isTaskStatusAlreadyInList = oldData.some((status) => status.id === taskStatus.id);

          if (isTaskStatusAlreadyInList) {
            return oldData;
          }

          return [...oldData, taskStatus];
        },
      );
    },
    [queryClient],
  );

  const onTaskStatusUpdated = useCallback(
    ({ taskStatus, room: { id } }: { taskStatus: ITaskStatus; room: { id: string } }) => {
      queryClient.setQueriesData<ITaskStatus[]>(
        {
          exact: true,
          queryKey: [QueryKeys.ROOMS, id, QueryKeys.TASKS, QueryKeys.TASKS_STATUSES],
        },
        (oldData) => {
          if (oldData == null) {
            return oldData;
          }

          return oldData.map((status) => (status.id !== taskStatus.id ? status : { ...status, ...taskStatus }));
        },
      );
    },
    [queryClient],
  );

  const onTaskStatusListUpdated = useCallback(
    ({ taskStatuses, room: { id } }: { taskStatuses: ITaskStatus[]; room: { id: string } }) => {
      queryClient.setQueriesData<ITaskStatus[]>(
        {
          exact: true,
          queryKey: [QueryKeys.ROOMS, id, QueryKeys.TASKS, QueryKeys.TASKS_STATUSES],
        },
        taskStatuses,
      );
    },
    [queryClient],
  );

  const onTaskStatusDeleted = useCallback(
    ({ taskStatus, room: { id } }: { taskStatus: ITaskStatus; room: { id: string } }) => {
      queryClient.setQueriesData<ITaskStatus[]>(
        {
          exact: true,
          queryKey: [QueryKeys.ROOMS, id, QueryKeys.TASKS, QueryKeys.TASKS_STATUSES],
        },
        (oldData) => {
          if (oldData == null) {
            return oldData;
          }

          return oldData.filter((status) => status.id !== taskStatus.id);
        },
      );
    },
    [queryClient],
  );

  useEffect(() => {
    if (socket == null) return;
    socket.on(SocketEvents.TASK_CREATED, onTaskCreated);
    socket.on(SocketEvents.TASK_UPDATED, onTaskUpdated);
    socket.on(SocketEvents.TASK_DELETED, onTaskDeleted);

    socket.on(SocketEvents.TODO_CREATED, onTodoCreated);
    socket.on(SocketEvents.TODO_UPDATED, onTodoUpdated);
    socket.on(SocketEvents.TODO_DELETED, onTodoDeleted);

    socket.on(SocketEvents.TASK_STATUS_CREATED, onTaskStatusCreated);
    socket.on(SocketEvents.TASK_STATUS_UPDATED, onTaskStatusUpdated);
    socket.on(SocketEvents.TASK_STATUS_LIST_UPDATED, onTaskStatusListUpdated);
    socket.on(SocketEvents.TASK_STATUS_DELETED, onTaskStatusDeleted);

    return () => {
      if (socket == null) return;
      socket.off(SocketEvents.TASK_CREATED, onTaskCreated);
      socket.off(SocketEvents.TASK_UPDATED, onTaskUpdated);
      socket.off(SocketEvents.TASK_DELETED, onTaskDeleted);

      socket.off(SocketEvents.TODO_CREATED, onTodoCreated);
      socket.off(SocketEvents.TODO_UPDATED, onTodoUpdated);
      socket.off(SocketEvents.TODO_DELETED, onTodoDeleted);

      socket.off(SocketEvents.TASK_STATUS_CREATED, onTaskStatusCreated);
      socket.off(SocketEvents.TASK_STATUS_UPDATED, onTaskStatusUpdated);
      socket.off(SocketEvents.TASK_STATUS_LIST_UPDATED, onTaskStatusListUpdated);
      socket.off(SocketEvents.TASK_STATUS_DELETED, onTaskStatusDeleted);
    };
  }, [socket]);
};
