import { PlansTabComponent } from "@/components/billing/modals/selectPlanModal/tabs/PlansTabComponent";
import { EditAccountTab } from "@/components/header/profileDropdown/profileModal/tabs/EditAccountTab";
import { EditNotificationSettingsTab } from "@/components/header/profileDropdown/profileModal/tabs/EditNotificationSettingsTab";
import { EditProfileTab } from "@/components/header/profileDropdown/profileModal/tabs/EditProfileTab";
import { ModalLayoutComponent } from "@/components/shared/modal/ModalLayoutComponent";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { ProfileTabs } from "@/interfaces/modal";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface IProfileModalProps {
  activeTab?: string;
}

export const ProfileModal = ({ activeTab = ProfileTabs.PROFILE }: IProfileModalProps): JSX.Element => {
  const me = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const tabs = useMemo(() => {
    const profileTabs = [
      {
        label: t("header.editProfile.modal.tabs.profile.title"),
        content: <EditProfileTab />,
        id: ProfileTabs.PROFILE,
        disabled: false,
      },
      {
        label: t("header.editProfile.modal.tabs.account.title"),
        content: <EditAccountTab />,
        id: ProfileTabs.ACCOUNT,
        disabled: false,
      },
      {
        label: t("header.editProfile.modal.tabs.notification.title"),
        content: <EditNotificationSettingsTab />,
        id: ProfileTabs.NOTIFICATIONS_SETTINGS,
        disabled: false,
      },
    ];

    if (me?.mainOrganisation?.organisationType === "personal" && me.mainOrganisation.organisationSubscription != null) {
      profileTabs.push({
        label: t("header.editProfile.modal.tabs.plansAndBill.title"),
        content: <PlansTabComponent isAutoOpening={false} />,
        id: ProfileTabs.PLANS,
        disabled: false,
      });
    }

    return profileTabs;
  }, [me]);

  return (
    <ModalLayoutComponent
      tabs={tabs}
      title={t("header.editProfile.modal.title")}
      size="lg"
      defaultTab={activeTab}
      name="editProfile"
    ></ModalLayoutComponent>
  );
};
