import { type IChannelMember } from "@/api/client";
import { RoomContext } from "@/contexts/RoomContext";
import { useContext, useMemo } from "react";
import { Navigate } from "react-router-dom";

export const DefaultChannelConversationComponent = (): JSX.Element => {
  const roomContextValue = useContext(RoomContext);
  const roomId = roomContextValue?.roomResponse?.room?.id;
  const channels = useMemo<IChannelMember[]>(() => {
    return roomContextValue?.channels ?? [];
  }, [roomContextValue]);

  return <>{roomId != null && channels.length > 0 ? <Navigate to={`/dashboard/rooms/${roomId}/channel/${channels?.[0]?.channel.id}`} /> : null}</>;
};
