import { TaskPriorityEnum } from "@/api/client";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowUp as PriorityIcon } from "react-icons/ai";
import { BsFillCaretDownFill as SelectorIcon } from "react-icons/bs";

interface ITaskPriorityItemComponentProps {
  priority?: TaskPriorityEnum | "remove" | null;
  displayNoPriority?: boolean;
  onClick?: () => void;
  isSelector?: boolean;
}

export const TaskPriorityItemComponent: FunctionComponent<ITaskPriorityItemComponentProps> = ({
  priority,
  displayNoPriority = false,
  onClick,
  isSelector = false,
}) => {
  const { t } = useTranslation();
  if (priority == null) {
    return displayNoPriority ? (
      <div
        className={`flex flex-row items-center justify-center ${
          isSelector ? "border border-zinc-200 bg-zinc-100 px-2" : "bg-zinc-200 px-3"
        } rounded-md py-1 hover:opacity-75`}
        onClick={onClick}
      >
        {isSelector ? null : <PriorityIcon className={`${isSelector ? "h-4 w-4 text-zinc-700" : "h-3 w-3 text-zinc-500"} mr-0.5`} />}
        <div className={`${isSelector ? "text-sm text-zinc-700" : "text-xs text-zinc-500"}`}>{t("tasks.priority.none")}</div>
        {isSelector ? <SelectorIcon className="ml-1 h-3 w-3 text-zinc-700" /> : null}
      </div>
    ) : (
      <div />
    );
  }

  if (priority === "remove") {
    return (
      <div className={`${isSelector ? "px-2" : "px-3"} rounded-md border border-dashed border-zinc-200 py-1 hover:opacity-75`} onClick={onClick}>
        <div className={`${isSelector ? "text-sm" : "text-xs"} text-zinc-500`}>{t("tasks.priority.remove")}</div>
      </div>
    );
  }

  const priorityNameByPriority = {
    [TaskPriorityEnum.Low]: t("tasks.priority.low"),
    [TaskPriorityEnum.Medium]: t("tasks.priority.medium"),
    [TaskPriorityEnum.High]: t("tasks.priority.high"),
  };

  const priorityBackgroundColorByPriority = {
    [TaskPriorityEnum.Low]: "bg-rogerGreen-100",
    [TaskPriorityEnum.Medium]: "bg-rogerYellow-100",
    [TaskPriorityEnum.High]: "bg-rogerRed-100",
  };

  const priorityTextColorByPriority = {
    [TaskPriorityEnum.Low]: "text-rogerGreen-500",
    [TaskPriorityEnum.Medium]: "text-rogerYellow-500",
    [TaskPriorityEnum.High]: "text-rogerRed-500",
  };

  return (
    <div
      className={`flex flex-row items-center justify-center ${priorityBackgroundColorByPriority[priority]} ${
        isSelector ? "px-2" : "px-3"
      } rounded-md py-1 hover:opacity-75`}
      onClick={onClick}
    >
      <div className={`${isSelector ? "text-sm" : "text-xs"} ${priorityTextColorByPriority[priority]}`}>{priorityNameByPriority[priority]}</div>
      {isSelector ? <SelectorIcon className={`${priorityTextColorByPriority[priority]} ml-1 h-3 w-3`} /> : null}
    </div>
  );
};
