import { ThreadPreviewSkeletonComponent } from "@/components/roomPage/tabs/emails/ThreadPreviewSkeletonComponent";
import { useThreadPreview } from "@/components/roomPage/tabs/emails/useThreadPreview";
import { UserAvatarComponent } from "@/components/shared/user/UserAvatarComponent";
import { useThreadQuery } from "@/hooks/queries/emails/useThreadQuery";
import { useDateFormat } from "@/hooks/shared/date/useDateFormat";
import { useBadge } from "@/hooks/shared/useBadge";
import { useTranslation } from "react-i18next";
export const ThreadPreviewComponent = ({ threadId, isOpened }: { threadId: string; isOpened: boolean }): JSX.Element => {
  const { data: thread } = useThreadQuery(threadId);
  const { t } = useTranslation();
  const { onThreadClick, senderName } = useThreadPreview(thread);
  const { formatDateForEmail } = useDateFormat();
  const { getComputedBadgeNumber } = useBadge();
  if (thread?.lastEmail == null) return <ThreadPreviewSkeletonComponent />;

  return (
    <div className="pb-3 pr-4">
      <div
        className={`cursor-pointer overflow-hidden rounded-lg border text-sm transition-all ${isOpened ? "border-sky-500" : "hover:border-zinc-400"}`}
        onClick={onThreadClick}
      >
        <header className="flex border-b bg-zinc-50 p-3">
          <div className="font-medium text-zinc-600">{thread.lastEmail.subject}</div>
          <div className="px-2 text-zinc-300">|</div>
          <div className="text-zinc-400">{t("roomPage.tabs.mails.threadPreview.emailCount", { count: thread.emailCount })}</div>
          {thread.unreadEmailCount != null && thread.unreadEmailCount > 0 ? (
            <div className="ml-auto inline-flex items-center justify-center rounded-full bg-sky-600 px-2 py-1 text-xs font-bold leading-none text-white">
              {getComputedBadgeNumber(thread.unreadEmailCount)}
            </div>
          ) : null}
        </header>
        <div className="p-3">
          <div className="flex items-center justify-between pb-2">
            <div className="flex items-center font-semibold">
              <UserAvatarComponent userName={senderName} size="md" className="mr-2 w-6" />
              <div>{senderName}</div>
            </div>
            <div className="text-xs text-zinc-400">{formatDateForEmail(thread.lastEmail.createdAt)}</div>
          </div>
          <div className="line-clamp-2">{thread.lastEmail.bodyText.visible}</div>
        </div>
      </div>
    </div>
  );
};
