import { RoomMemberStatus } from "@/api/client";
import { RoomCard } from "@/components/homePage/tabs/rooms/RoomCard";
import { useRoomList } from "@/components/homePage/tabs/rooms/useRoomList";
import { TooltipComponent } from "@/components/shared/tooltip/TooltipComponent";
import { useMyRoomsQuery } from "@/hooks/queries/rooms/useMyRoomsQuery";
import { PreferencesContext, usePreferences } from "@/hooks/shared/usePreferences";
import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export const StarredRoomsInHeaderComponent = (): JSX.Element => {
  const { preferences } = useContext(PreferencesContext);
  const { setPreference } = usePreferences();
  const params = useParams();
  const { id: routeRoomId } = params;

  const starredRooms = useMemo<string[]>(() => {
    return preferences?.starredRooms ?? [];
  }, [preferences?.starredRooms]);

  const { data: rooms } = useMyRoomsQuery();

  useEffect(() => {
    const newStarredRooms = starredRooms.filter((starredRoom) => {
      const room = rooms?.find((room) => room.roomId === starredRoom);
      return room?.status !== RoomMemberStatus.Archived;
    });
    if (newStarredRooms.length !== starredRooms.length) {
      setPreference("starredRooms", newStarredRooms);
    }
  }, [rooms]);

  const starredRoomsData = useMemo(() => {
    return rooms?.filter((room) => starredRooms.includes(room.roomId) && room.status !== RoomMemberStatus.Archived).slice(0, 5);
  }, [rooms, starredRooms]);

  useRoomList();

  return (
    <>
      {starredRoomsData != null && starredRoomsData.length > 0 && <div className="mr-4 flex h-full w-px bg-white opacity-20"></div>}
      {starredRoomsData?.map((room) => (
        <TooltipComponent key={room.roomId} content={room.name} placement="bottom">
          <div className="relative mr-3 w-6" key={room.roomId}>
            <RoomCard size="sm" room={room} key={room.roomId} />
            {routeRoomId === room.roomId && <div className="absolute bottom-[-3px] left-1/2 h-1 w-1 -translate-x-1/2 rounded-full bg-white"></div>}
          </div>
        </TooltipComponent>
      ))}
    </>
  );
};
