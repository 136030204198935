import { BsTriangleFill } from "react-icons/bs";
import { IoClose as ResetIcon } from "react-icons/io5";
import { components, type ClearIndicatorProps, type StylesConfig } from "react-select";
import { type DropdownIndicatorProps } from "react-select/dist/declarations/src/components/indicators";
import { type OptionProps } from "react-select/dist/declarations/src/components/Option";
import colors from "tailwindcss/colors";

export const useCustomSelect = <T extends unknown>() => {
  const DropdownIndicator = (props: DropdownIndicatorProps<T>): JSX.Element => {
    return (
      <components.DropdownIndicator {...props}>
        <BsTriangleFill className="ml-[-8px] h-2 w-2 rotate-180" />
      </components.DropdownIndicator>
    );
  };

  const ClearIndicator = (props: ClearIndicatorProps<T>): JSX.Element => {
    return (
      <components.ClearIndicator {...props}>
        <ResetIcon className="h-4 w-4 rotate-180" />
      </components.ClearIndicator>
    );
  };
  const customStyles: StylesConfig<T> = {
    // @ts-expect-error todo
    menuPortal: (previous) => ({ ...previous, zIndex: 9999 }),
    indicatorSeparator: () => ({ display: "none" }),
    // @ts-expect-error todo
    dropdownIndicator: (previous) => ({ ...previous, color: colors.stone[500] }),
    container: (previous) => ({
      ...previous,
    }),
    // @ts-expect-error todo
    valueContainer: (previous) => ({
      ...previous,
      padding: "0 0.5rem 0 0.5rem",
    }),
    // @ts-expect-error todo
    placeholder: (previous) => ({
      ...previous,
      color: colors.stone[500],
    }),
    // @ts-expect-error todo
    control: (previous) => ({
      ...previous,
      border: "none",
      boxShadow: "none",
      backgroundColor: colors.zinc[100],
      fontSize: "0.875rem",
      height: "28px",
      minHeight: 0,
      padding: "0",
    }),
    // option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {\
    // @ts-expect-error todo
    option: (previous, state: OptionProps) => {
      return {
        ...previous,
        fontSize: "12px",
        textAlign: "left",
        width: "100%",
        color: colors.stone[500],
        backgroundColor: state.isSelected ? colors.zinc[100] : "white",
        cursor: "pointer",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      };
    },
    // @ts-expect-error todo
    menu: (previous) => ({
      ...previous,
      width: "150px",
    }),
    // @ts-expect-error todo
    clearIndicator: (previous) => ({
      ...previous,
      color: colors.stone[500],
      cursor: "pointer",
      zIndex: 100000,
      padding: "0",
    }),
  };

  return { customStyles, DropdownIndicator, ClearIndicator };
};
