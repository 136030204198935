import { ProfileModal } from "@/components/header/profileDropdown/profileModal/ProfileModal";
import { ButtonComponent } from "@/components/shared/form/button/ButtonComponent";
import { closeModal } from "@/components/shared/modal/ModalService";
import { UserInfoComponent } from "@/components/shared/user/UserInfoComponent";
import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useModal } from "@/hooks/shared/useModal";
import { ProfileTabs } from "@/interfaces/modal";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

interface IProfileDropdownContentProps {
  closeDropdown: () => void;
}

export const ProfileDropdownContent = ({ closeDropdown }: IProfileDropdownContentProps): JSX.Element => {
  const { t } = useTranslation();
  const { openModal, getModalURIParams } = useModal();
  const [searchParams] = useSearchParams();
  const me = useContext(AuthenticationContext);
  const availableModalsComponents = {
    editProfile: ProfileModal,
  };

  // To open a modal according to query params
  useEffect(() => {
    const modalParam = getModalURIParams().modal as keyof typeof availableModalsComponents;
    const activeTab = getModalURIParams().tab as string;
    if (modalParam == null) return;

    const ModalComponent = availableModalsComponents[modalParam];
    if (ModalComponent == null) return;
    openModal(modalParam, <ModalComponent activeTab={activeTab} />);
  }, [searchParams]);

  const openEditProfileModal = useCallback(
    async (activeTab: string) => {
      closeModal("editProfile");
      openModal("editProfile", <ProfileModal activeTab={activeTab} />, true);
      closeDropdown();
    },
    [searchParams],
  );
  if (me == null) return <></>;
  return (
    <>
      <div className="px-4 py-3">
        <UserInfoComponent user={me}></UserInfoComponent>
        <ButtonComponent
          status="secondary"
          size="sm"
          align="center"
          className="mt-4 w-full"
          onClick={async () => {
            await openEditProfileModal(ProfileTabs.PROFILE);
          }}
        >
          {" "}
          {t("header.profileDropdown.editProfile")}
        </ButtonComponent>
      </div>
    </>
  );
};
