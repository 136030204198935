import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect } from "react";

import { type IOrganisation } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { SocketEvents } from "@/constants/socketEvents";
import { SocketContext } from "@/contexts/SocketContextProvider";

interface IIsHiddenSocket {
  id: string;
}

export const useSettingsSocket = () => {
  const socket = useContext(SocketContext);

  const queryClient = useQueryClient();

  const updateOrganisationalRoomIsHiddenInCache = useCallback(
    (roomId: string, isVisible: boolean) => {
      queryClient.setQueryData<IOrganisation>([QueryKeys.MY_ORGANISATION], (oldData) => {
        if (oldData == null || oldData?.organisationalRoom?.id !== roomId) return;
        return {
          ...oldData,
          organisationalRoom: {
            ...oldData?.organisationalRoom,
            isHidden: isVisible,
          },
        };
      });
    },
    [queryClient],
  );

  const onOrganisationalRoomHidden = useCallback(({ id }: IIsHiddenSocket) => {
    updateOrganisationalRoomIsHiddenInCache(id, true);
  }, []);

  const onOrganisationalRoomUnhidden = useCallback(({ id }: IIsHiddenSocket) => {
    updateOrganisationalRoomIsHiddenInCache(id, false);
  }, []);

  useEffect(() => {
    if (socket == null) return;
    socket.on(SocketEvents.ORGANISATIONAL_ROOM_HIDDEN, onOrganisationalRoomHidden);
    socket.on(SocketEvents.ORGANISATIONAL_ROOM_UNHIDDEN, onOrganisationalRoomUnhidden);

    return () => {
      if (socket == null) return;
      socket.off(SocketEvents.ORGANISATIONAL_ROOM_HIDDEN, onOrganisationalRoomHidden);
      socket.off(SocketEvents.ORGANISATIONAL_ROOM_UNHIDDEN, onOrganisationalRoomUnhidden);
    };
  }, [socket]);
};
