import { type IMessage } from "@/api/client";
import { MessageDateSeparatorComponent } from "@/components/roomPage/tabs/conversation/message/MessageDateSeparatorComponent";
import { MessageSeparatorComponent } from "@/components/roomPage/tabs/conversation/message/MessageSeparatorComponent";
import { useIsMessageHighlighted } from "@/components/roomPage/tabs/conversation/message/useIsMessageHighlighted";
import { useNewMessageIndicator } from "@/components/roomPage/tabs/conversation/message/useNewMessageIndicator";
import { useMessage } from "@/hooks/shared/useMessage";
import { type FunctionComponent, type PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

interface MessageWrapperComponentProps {
  message: IMessage;
  previousMessage?: IMessage;
  isMessageCentered?: boolean;
}

export const MessageWrapperComponent: FunctionComponent<PropsWithChildren<MessageWrapperComponentProps>> = ({
  message,
  previousMessage,
  children,
  isMessageCentered = false,
}) => {
  const { t } = useTranslation();
  const { shouldDisplayDateSeparator, isMine } = useMessage({ message, previousMessage });
  const { shouldDisplayNewMessageIndicator } = useNewMessageIndicator(message, previousMessage, isMine);
  const { isHighlighted } = useIsMessageHighlighted(message);

  return (
    <div className={`px-layout py-4 transition-all duration-1000 ${isHighlighted ? "border-sky-200 bg-sky-100" : ""}`}>
      <div className={`${!shouldDisplayNewMessageIndicator ? "m-0 block h-0 overflow-hidden p-0 leading-[0] opacity-0" : "h-[32px] opacity-100"} `}>
        <MessageSeparatorComponent lineColor="#0284c7" textColor="#0284c7" label={t("roomPage.tabs.room.conversation.message.newMessages")} />
      </div>

      {shouldDisplayDateSeparator && <MessageDateSeparatorComponent date={message.createdAt} />}
      <div className={`flex items-center ${isMessageCentered ? "justify-center" : ""}`}>
        <div className="text-sm text-black">
          <div className="flex items-center space-x-1 text-xs">{children}</div>
        </div>
      </div>
    </div>
  );
};
