import type { ITaskStatus } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface IDeleteTaskStatusMutationParams {
  roomId?: string;
  taskStatusId: string;
}
export const useDeleteTaskStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ roomId, taskStatusId }: IDeleteTaskStatusMutationParams) => {
      if (roomId == null) throw new Error();

      const { data } = await api.rooms.deleteTaskStatus(roomId, taskStatusId);

      return data;
    },
    onMutate: async ({ roomId, taskStatusId }: IDeleteTaskStatusMutationParams) => {
      queryClient.setQueryData<ITaskStatus[]>([QueryKeys.ROOMS, roomId, QueryKeys.TASKS, QueryKeys.TASKS_STATUSES], (old) => {
        if (old == null) return [];
        return old.filter((taskStatus) => taskStatus.id !== taskStatusId);
      });
    },
    onError: (_data, { roomId }) => {
      void queryClient.invalidateQueries({
        queryKey: [QueryKeys.ROOMS, roomId, QueryKeys.TASKS, QueryKeys.TASKS_STATUSES],
      });
    },
  });
};
