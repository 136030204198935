import { TextInputComponent } from "@/components/shared/form/textInput/TextInputComponent";
import { LoaderComponent } from "@/components/shared/layout/LoaderComponent";
import { AddEmailItemComponent } from "@/components/shared/user/PickUserPopinComponent/AddEmailItemComponent";
import { EmailItemComponent } from "@/components/shared/user/PickUserPopinComponent/EmailItemComponent";
import { usePickUserPopinContent } from "@/components/shared/user/PickUserPopinComponent/usePickUserPopinContent";
import { UserItemComponent } from "@/components/shared/user/UserItemComponent";
import { type SimpleUser, type UserWithEmail, type UserWithId } from "@/interfaces/user";
import { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BsCheck as CheckIcon } from "react-icons/bs";

interface IPickUserPopinContentComponentProps {
  conversationId: string | null;
  onUserChange: (user: SimpleUser, isSelected: boolean) => void;
  onAddUserWithEmail: (email: string) => void;
  isVisible: boolean;
  selectedUsers: Array<UserWithEmail | UserWithId>;
}

export const PickUserPopinContentComponent: FunctionComponent<IPickUserPopinContentComponentProps> = ({
  conversationId,
  onUserChange,
  onAddUserWithEmail,
  isVisible,
  selectedUsers,
}) => {
  const { t } = useTranslation();
  const { users, mailUsers, isLoading, onSearchChange, ref, search, shouldDisplayNewEmail, isEmailButtonDisabled } = usePickUserPopinContent(
    conversationId,
    isVisible,
    selectedUsers,
  );

  return isLoading ? (
    <LoaderComponent />
  ) : (
    <div className="w-72" data-no-dnd>
      <div className="w-full p-2">
        <TextInputComponent
          ref={ref}
          value={search}
          onChange={(e) => {
            onSearchChange(e.target.value);
          }}
          placeholder={t("pick.user.search.placeholder")}
        />
      </div>
      <div className="max-h-48 w-full overflow-auto">
        {shouldDisplayNewEmail || mailUsers.length > 0 ? <h3 className="mx-2 font-semibold">{t("pick.user.email.title")}</h3> : null}
        {shouldDisplayNewEmail ? (
          <AddEmailItemComponent
            email={search}
            isDisabled={isEmailButtonDisabled}
            onAddEmail={() => {
              onAddUserWithEmail(search);
            }}
          />
        ) : null}
        {mailUsers.map((user) => (
          <EmailItemComponent
            key={user.id}
            email={user.email}
            onRemove={() => {
              onUserChange(user, false);
            }}
          />
        ))}
        {users.length > 0 ? <h3 className="mx-2 mt-4 font-semibold">{t("pick.user.channelMember.title")}</h3> : null}
        {users.map((user) => {
          const isUserSelected = selectedUsers.some((selectedUser) =>
            selectedUser.type === "email" ? selectedUser.email === user.email : selectedUser.id === user.id,
          );
          return (
            <UserItemComponent
              key={user.id}
              user={user}
              onClick={() => {
                onUserChange(user, !isUserSelected);
              }}
              RightIcon={isUserSelected ? <CheckIcon className="h-4 w-4" /> : null}
              hasEmail
            />
          );
        })}
      </div>
    </div>
  );
};
