import { type IUtm } from "@/api/client";
import { useSearchParams } from "react-router-dom";

export const useUtm = () => {
  enum UtmKeys {
    source = "utm_source",
    medium = "utm_medium",
    campaign = "utm_campaign",
    content = "utm_content",
    term = "utm_term",
  }
  const [searchParams] = useSearchParams();
  const getUtmParamsFromURL = (): void => {
    const utm: IUtm = {
      source: searchParams.get(UtmKeys.source) ?? undefined,
      medium: searchParams.get(UtmKeys.medium) ?? undefined,
      campaign: searchParams.get(UtmKeys.campaign) ?? undefined,
      content: searchParams.get(UtmKeys.content) ?? undefined,
      term: searchParams.get(UtmKeys.term) ?? undefined,
    };

    saveUtmParamsToStorage(utm);
  };

  const saveUtmParamsToStorage = (utm: IUtm): void => {
    Object.entries(utm).forEach(([key, value]) => {
      if (value != null) {
        const localStorageKey = `utm_${key}`;
        localStorage.setItem(localStorageKey, value);
      }
    });
  };

  const getUtmParamsFromStorage = (): IUtm => {
    const keys = Object.values(UtmKeys);

    return keys.reduce<IUtm>((accumulator, key) => {
      const value = localStorage.getItem(key);

      if (value !== null) {
        accumulator[key.replace("utm_", "") as keyof IUtm] = value;
      }

      return accumulator;
    }, {});
  };

  return { getUtmParamsFromURL, getUtmParamsFromStorage };
};
