import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useMyCollaboratorsQuery = (search: string | undefined, disabled: boolean) => {
  return useQuery({
    queryKey: [QueryKeys.MY_COLLABORATORS, search],
    queryFn: async () => {
      const response = await api.my.searchCollaborators({ collaboratorSearchQuery: search ?? "" });
      return response.data.data;
    },
    enabled: !disabled,
  });
};
