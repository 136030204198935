import { type IOrganisation, type IRoom, type IUpdateRoom } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { roomType } from "@/constants/room";
import { api } from "@/services/HttpService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useRoomMutation = (roomId: string | undefined, room: IRoom | undefined | null, canUpdateRoom: boolean) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newRoom: IUpdateRoom) => {
      if (roomId == null && room == null) return;
      if ((room?.type === roomType.ORGANISATION || canUpdateRoom) && roomId != null) {
        const response = await api.rooms.update(roomId, newRoom, { defaultToastErrorMessage: t("createRoomModal.tabs.about.update.error") });
        return response.data;
      }
    },
    onSuccess: async (newRoom) => {
      if (newRoom == null) return;
      if (newRoom.type === roomType.ORGANISATION) {
        queryClient.setQueryData<IOrganisation>([QueryKeys.MY_ORGANISATION], (oldData) => {
          if (oldData == null) return;
          return {
            ...oldData,
            organisationalRoom: {
              ...oldData.organisationalRoom,
              ...newRoom,
            },
          };
        });
      } else {
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.MY_ROOMS] });
        toast.success(t("createRoomModal.tabs.about.update.success"));
      }
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.ROOMS, roomId] });
    },
  });
};
