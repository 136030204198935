import { FileType, type IFile, type IFilesUploadGetPresignedUrlController, type IUser, type S3FileTypeAccepted } from "@/api/client";
import { openModal } from "@/components/shared/modal/ModalService";
import { CropImageModal } from "@/components/shared/uploader/cropImageModal/CropImageModal";
import { eventNames } from "@/constants/eventNames";
import { ModalNames } from "@/constants/modalNames";
import { QueryKeys } from "@/constants/queryKeys";
import { useRoomMutation } from "@/hooks/mutations/rooms/useRoomMutation";
import { api } from "@/services/HttpService";
import { canvasToBlob } from "@/utils/imageUtils";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { type FilePondErrorDescription, type FilePondFile } from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { useEffect, useMemo, useRef, useState } from "react";
import { registerPlugin, type FilePond } from "react-filepond";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface ICustomError extends FilePondErrorDescription {
  main: string;
  sub: string;
}

registerPlugin(FilePondPluginFileValidateType);

export const usePictureUploader = (context: string | undefined, contextId: string | undefined) => {
  const _context = useMemo(() => context ?? "profile", [context]);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const filepondRef = useRef<FilePond>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { mutate } = useRoomMutation(contextId ?? "", undefined, true);

  const onErrorHandler = (error: Partial<ICustomError>) => {
    const message = error.body ?? `${error.main as string} ${error.sub as string}` ?? t("toasts.error.unknown");
    toast.error(message, { id: "error" });
  };

  const onCropSubmit = async (canvas: HTMLCanvasElement, file: FilePondFile) => {
    const fileName = file.filename;
    const fileType = file.fileType;
    const fileExtension = fileName.split(".").pop() as S3FileTypeAccepted;

    if (fileExtension == null) {
      toast.error(t("header.editProfile.modal.tabs.profile.uploadProfilePictureErrors.noExtension"));
    }

    const presignedPayload: IFilesUploadGetPresignedUrlController = {
      file: {
        name: fileName,
        extension: fileExtension,
        mimeType: fileType,
      },
    };

    if (_context === "profile") {
      presignedPayload.file.type = FileType.ProfilePicture;
    }

    try {
      setIsUploading(true);

      const blob = await canvasToBlob(canvas, "image/jpeg");
      const base64Url = canvas.toDataURL("image/jpeg");

      const presignedResponse = await api.files.getPresignedUrlToUpload(presignedPayload);
      await axios.put(presignedResponse.data.url, blob);
      if (_context === "profile") {
        await api.me.update({
          profilePicture: { id: presignedResponse.data.id },
        });

        const optimisticProfilePicture: IFile = {
          id: presignedResponse.data.id,
          url: base64Url,
          extension: fileExtension,
          createdAt: "",
          name: fileName,
          type: FileType.ProfilePicture,
        };

        queryClient.setQueryData<IUser>([QueryKeys.ME], (oldMe) => {
          if (oldMe == null) return;
          const newMe: IUser = { ...oldMe, profilePicture: optimisticProfilePicture };
          queryClient.setQueryData<IUser>([QueryKeys.USERS, oldMe.id], (oldUser) => {
            if (oldUser == null) return;
            const newUser: IUser = { ...oldUser, profilePicture: optimisticProfilePicture };
            return newUser;
          });
          return newMe;
        });
      } else if (_context === "room" && contextId != null) {
        mutate({
          roomPicture: { id: presignedResponse.data.id },
        });
      } else {
        const event = new CustomEvent(eventNames.ROOM_PICTURE_UPDATED, {
          detail: {
            roomPicture: { id: presignedResponse.data.id },
            file: base64Url,
          },
          bubbles: true,
        });
        window.dispatchEvent(event);
      }

      setIsUploading(false);
    } catch (responseError: unknown) {
      onErrorHandler(responseError as Partial<ICustomError>);
      setIsUploading(false);
    }
  };

  const onAddFile = (error: FilePondErrorDescription | null, file: FilePondFile) => {
    if (error != null) {
      onErrorHandler(error);
      return;
    }
    const fileExtension = file.filename.split(".").pop() as S3FileTypeAccepted;
    if (fileExtension == null) {
      toast.error(t("header.editProfile.modal.tabs.profile.uploadProfilePictureErrors.noExtension"));
    }
    const filesItem = filepondRef?.current?.getFiles();
    const fileItem = filesItem?.find((_file) => _file.filename === file.filename);
    /* @ts-expect-error filepond error  */
    const imageBase64: string | null = fileItem?.getFileEncodeDataURL();
    if (imageBase64 == null) {
      return;
    }

    openModal(
      ModalNames.CROP_IMAGE,
      <CropImageModal
        imageBase64={imageBase64}
        file={file}
        onSubmit={onCropSubmit}
        title={_context === "profile" ? t("cropImageModal.profile.title") : t("cropImageModal.room.title")}
      />,
    );
  };

  useEffect(() => {
    filepondRef.current?.removeFiles();
  }, [_context]);

  return { filepondRef, isUploading, onAddFile, onErrorHandler };
};
