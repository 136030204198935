import { type IMessage, type ITask } from "@/api/client";
import { MessageComponent } from "@/components/roomPage/tabs/conversation/message/MessageComponent";
import { SystemMessageComponent } from "@/components/roomPage/tabs/conversation/message/SystemMessageComponent";
import { eventNames } from "@/constants/eventNames";
import { QueryKeys } from "@/constants/queryKeys";
import { SocketEvents } from "@/constants/socketEvents";
import { ConversationContext } from "@/contexts/ConversationContext";
import { SocketContext } from "@/contexts/SocketContextProvider";
import { useMessagesQuery } from "@/hooks/queries/conversations/useMessagesQuery";
import { useConversationQueryKey } from "@/hooks/shared/useConversationQueryKey";
import { useEditMessageQueries } from "@/hooks/shared/useEditMessageQueries";
import { allowedSystemMessageSubtypes } from "@/interfaces/messages";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { type VirtuosoHandle } from "react-virtuoso";

export const useConversation = (contextType: string, contextId: string, isComment: boolean) => {
  // Needed by virtuoso to avoid negative values. Number.MAX_SAFE_INTEGER is not working
  const START_INDEX = 99999999;
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const { parentMessageId } = useParams();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const previousInitialIndex = useRef<number>(START_INDEX);
  const conversationMember = useContext(ConversationContext);
  const { queryKeys } = useConversationQueryKey(contextType, contextId, conversationMember?.member?.lastMessageRead?.id);

  const socket = useContext(SocketContext);

  const [firstItemIndex, setFirstItemIndex] = useState(START_INDEX);
  const [followOutput, setFollowOutput] = useState<"auto" | false>("auto");
  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const messageId = useMemo(() => {
    if (contextType === "message") {
      return searchParams.get("messageId") ?? conversationMember.member?.lastMessageRead?.id;
    }
    return parentMessageId ?? searchParams.get("messageId") ?? conversationMember.member?.lastMessageRead?.id;
  }, [searchParams, parentMessageId, conversationMember.member?.lastMessageRead?.id]);

  const { data: messagesPages, fetchPreviousPage, fetchNextPage, isFetching } = useMessagesQuery(queryKeys, contextType, contextId, messageId);

  const { addMessageToQuery } = useEditMessageQueries();

  const messages = useMemo(() => {
    if (messagesPages == null) return [];
    const allMessages = messagesPages?.pages?.map((page) => page.data)?.flat();

    const filteredMessages = allMessages.filter((message) => {
      if (message.type === "system") {
        return message.systemData?.subtype != null ? allowedSystemMessageSubtypes.includes(message.systemData.subtype) : false;
      }
      return true;
    });

    if (isComment) {
      return filteredMessages.reverse();
    }
    return filteredMessages;
  }, [messagesPages?.pages, isComment]);

  const itemContent = useCallback(
    (index: number, message: IMessage) => {
      if (messages == null) return null;
      const messageIndexInMessagesArray = messages.findIndex((messageItem) => messageItem.id === message.id);
      const previousMessage = messages[messageIndexInMessagesArray - 1];
      return (
        <>
          {message.type === "system" ? (
            <SystemMessageComponent message={message} previousMessage={previousMessage ?? null} />
          ) : (
            <MessageComponent
              key={`${message?.id}-${JSON.stringify(message?.content)}`}
              message={message}
              previousMessage={previousMessage ?? null}
              isInThread={contextType === "message"}
              isComment={isComment}
            />
          )}
        </>
      );
    },
    [messages, contextType, firstItemIndex, isComment],
  );

  const tryToFetchPage = useCallback(
    async (pageType: string) => {
      if (messages == null || messages.length === 0 || messagesPages == null || isFetching) {
        return;
      }
      const pageToFetch =
        pageType === "previous"
          ? messagesPages.pages[0]?.pagination.previousCursor
          : messagesPages.pages[messagesPages.pages.length - 1]?.pagination.nextCursor;

      if (pageToFetch == null) {
        return;
      }
      setFollowOutput(false);

      const countBeforeFetch = messages.length;

      const { data } = pageType === "previous" ? await fetchPreviousPage() : await fetchNextPage();

      if (data == null) {
        return;
      }

      const { pages } = data;
      const countAfterFetch = pages.reduce((count, page) => count + page.data.length, 0);
      const countOfNewMessages = countAfterFetch - countBeforeFetch;

      setFirstItemIndex((prev) => (pageType === "previous" ? prev - countOfNewMessages : prev));
      setFollowOutput("auto");
    },
    [fetchPreviousPage, fetchNextPage, messagesPages, setFirstItemIndex, isFetching, messages, firstItemIndex],
  );

  const scrollToBottom = useCallback(() => {
    if (isComment) {
      return;
    }
    setTimeout(() => {
      if (virtuosoRef.current == null) return;
      virtuosoRef.current.scrollToIndex({ index: Number.MAX_SAFE_INTEGER });
    }, 0);
  }, [virtuosoRef?.current, isComment]);

  useEffect(() => {
    if (socket == null) return;
    const handleDeleteMessage = async () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys });
    };
    socket.on(SocketEvents.MESSAGE_DELETED, handleDeleteMessage);
    return () => {
      socket.off(SocketEvents.MESSAGE_DELETED);
    };
  }, [socket, queryClient, contextId, contextType, queryKeys]);

  const isLastPage = useMemo(() => {
    if (messagesPages == null) return false;
    return messagesPages.pages[messagesPages.pages.length - 1]?.pagination.nextCursor == null;
  }, [messagesPages]);

  const newMessageHandler = async (event: CustomEvent<{ message: IMessage }>) => {
    // if we are not on the right conversation, returns
    if ((contextType === "conversation" || contextType === "taskComment") && event.detail.message.conversation?.id !== contextId) return;
    if (contextType === "message" && event.detail.message.parent?.id !== contextId) return;
    const userMemberId = event.detail?.message?.sender?.id;
    const isMessageFromThread = event.detail.message.parent != null;
    // To avoid both thread and main conversation to scroll to the bottom, we need to check if the message is from thread or not
    if (contextType === "conversation" && isMessageFromThread) return;
    if (contextType === "message" && !isMessageFromThread) return;

    // If it's my message, I need to scroll to the bottom
    const isMyMessage = userMemberId === conversationMember?.member?.id;
    addMessageToQuery(queryKeys, event.detail.message);

    if (contextType === "taskComment") {
      queryClient.setQueriesData<ITask>({ queryKey: [QueryKeys.TASK], exact: false }, (oldData) => {
        if (oldData == null) return oldData;

        if (oldData.conversation.id === contextId) {
          return { ...oldData, conversation: { ...oldData.conversation, messagesCount: oldData.conversation.messagesCount + 1 } };
        }

        return oldData;
      });

      queryClient.setQueriesData<ITask[]>({ queryKey: [QueryKeys.TASKS], exact: false }, (oldData) => {
        if (oldData == null) return oldData;

        if (oldData.some((task) => task.conversation.id === contextId)) {
          return oldData.map((task) =>
            task.conversation.id === contextId
              ? { ...task, conversation: { ...task.conversation, messagesCount: task.conversation.messagesCount + 1 } }
              : task,
          );
        }

        return oldData;
      });
    }

    if (!isMyMessage) {
      return;
    }

    if (!isLastPage) {
      setFirstItemIndex(START_INDEX); // Reset the firstItemIndex to force a re-render
      navigate(".?messageId=last");
    }
    scrollToBottom();
  };

  useEffect(() => {
    window.addEventListener(eventNames.NEW_MESSAGE, newMessageHandler as unknown as EventListener);
    return () => {
      window.removeEventListener(eventNames.NEW_MESSAGE, newMessageHandler as unknown as EventListener);
    };
  }, [conversationMember?.member?.id, scrollToBottom, contextId, contextType, isLastPage]);

  // Workaround to avoid the flickering when list is loading
  useEffect(() => {
    let timeout: number = 0;
    if (!isFetching) {
      timeout = window.setTimeout(() => {
        if (containerRef.current == null) return;
        containerRef.current.classList?.remove("opacity-0");
        containerRef.current.classList?.add("opacity-100");
      }, 150);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isFetching, containerRef.current]);

  const initialIndex = useMemo(() => {
    if (searchParams.get("shouldRefresh") === "false") return previousInitialIndex.current;
    const messageIndex =
      searchParams.get("messageId") === "last" ? Number.MAX_SAFE_INTEGER : messages.findIndex((message) => message.id === messageId);
    if (messageIndex === -1) return Number.MAX_SAFE_INTEGER;

    previousInitialIndex.current = messageIndex;
    return messageIndex;
  }, [messages, messageId, firstItemIndex, searchParams]);

  const scrollToMessage = useCallback(async () => {
    if (virtuosoRef.current == null || messages == null || messages.length === 0 || messageId == null) {
      return;
    }
    const messageIndex = messages.findIndex((message) => message.id === messageId);

    virtuosoRef?.current?.scrollToIndex({ index: messageIndex, align: "center" });
  }, [messageId, virtuosoRef.current, messages, queryClient, queryKeys]);

  useEffect(() => {
    if (searchParams.get("shouldRefresh") === "false" || contextType === "taskComment") return;
    void scrollToMessage();
  }, [messageId, contextId, contextType]);

  return {
    containerRef,
    firstItemIndex,
    messages,
    tryToFetchPage,
    initialIndex,
    itemContent,
    virtuosoRef,
    setFirstItemIndex,
    VIEWPORT_INCREASE: 10,
    conversationMember,
    messageId,
    followOutput,
  };
};
