import { type IEmailThread } from "@/api/client";
import { QueryKeys } from "@/constants/queryKeys";
import { api } from "@/services/HttpService";
import { useQuery } from "@tanstack/react-query";

export const useThreadQuery = (threadId?: string) => {
  return useQuery<IEmailThread | undefined>({
    queryKey: [QueryKeys.EMAIL_THREAD, threadId],
    queryFn: async () => {
      if (threadId == null) return;
      const response = await api.emails.getOneThread(threadId, {
        defaultToastErrorMessage: null,
      });
      return response.data.data;
    },
    enabled: threadId != null,
    retry: 0,
    staleTime: 1000,
  });
};
